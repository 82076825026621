export const NEWS_LIST_LOADING = 'NEWS_LIST_LOADING';
export const NEWS_LIST_SUCCESSFUL = 'NEWS_LIST_SUCCESSFUL';
export const NEWS_LIST_FAILED = 'NEWS_LIST_FAILED';
export const NEWS_LIST_NOT_FOUND = 'NEWS_LIST_NOT_FOUND'

export const NEWS_DETAIL_LOADING = 'NEWS_DETAIL_LOADING';
export const NEWS_DETAIL_SUCCESSFUL = 'NEWS_DETAIL_SUCCESSFUL';
export const NEWS_DETAIL_NOT_FOUND = 'NEWS_DETAIL_NOT_FOUND';
export const NEWS_DETAIL_FAILED = 'NEWS_DETAIL_FAILED';

export const NEWS_CREATE_LOADING = 'NEWS_CREATE_LOADING';
export const NEWS_CREATE_SUCCESSFUL = 'NEWS_CREATE_SUCCESSFUL';
export const NEWS_CREATE_EXIST = 'NEWS_CREATE_EXIST'
export const NEWS_CREATE_FAILED = 'NEWS_CREATE_FAILED';

export const NEWS_UPDATE_LOADING = 'NEWS_UPDATE_LOADING'
export const NEWS_UPDATE_SUCCESSFUL = 'NEWS_UPDATE_SUCCESSFUL';
export const NEWS_UPDATE_NOT_FOUND = 'NEWS_UPDATE_NOT_FOUND';
export const NEWS_UPDATE_FAILED = 'NEWS_UPDATE_FAILED';

export const NEWS_REMOVE_LOADING = 'NEWS_REMOVE_LOADING';
export const NEWS_REMOVE_SUCCESSFUL = 'NEWS_REMOVE_SUCCESSFUL';
export const NEWS_REMOVE_NOT_FOUND = 'NEWS_REMOVE_NOT_FOUND';
export const NEWS_REMOVE_UNAUTHORIZED = 'NEWS_REMOVE_UNAUTHORIZED';
export const NEWS_REMOVE_FAILED = 'NEWS_REMOVE_FAILED';

export const NEWS_RESET_CRUD = 'NEWS_RESET_CRUD'