import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import 'react-toastify/dist/ReactToastify.css';
import { connect } from 'react-redux';
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from 'prop-types';
import { loginAdmin } from "actions/Auth/AuthService";
import AuthNavbar from "components/Navbars/AuthNavbar";
import AuthFooter from 'components/Footers/AuthFooter';
import SuberesLogo from '../../assets/img/brand/logo_suberes.png'
import '../../assets/css/utilities.css';
import { LOGIN_LOADING } from "actions/Auth/Types";
import { LOGIN_SUCCESSFUL } from "actions/Auth/Types";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { LOGIN_NOT_FOUND } from "actions/Auth/Types";
import { LOGIN_FAILED } from "actions/Auth/Types";
import { LOGIN_EXIST } from "actions/Auth/Types";
import setCredentialData from "setCredentialData";
const MySwal = withReactContent(Swal)

const Login = (props) => {
  const [ isLoading , setLoading ] = useState(false)
  const [ isEmailFilled , setEmailFilled ] = useState(true)
  const [ isPasswordFilled , setPasswordFilled ] = useState(true)
  const [ email , setEmail ] = useState('')
  const [ submit , setSubmit ] = useState(false)
  const [ password , setPassword ] = useState('')
  const [ isSuccess , setSuccess ] = useState('')
  const history = useHistory()
  function LoginAdmin() {
    setSubmit(true)
    if(email != '' && password != ''){
      props.loginAdmin({
        email : email,
        password : password
      })
    } else {
      setEmailFilled(email.length > 0)
      setPasswordFilled(password.length > 0)
    }
  }
  const onEmailHandler = event => {
    setEmail(event.target.value)
  }
  const onPasswordHandler = event => {
    setPassword(event.target.value)
  }
  useEffect(() => {
    if(submit){
      setEmailFilled(email.length > 0)
      setPasswordFilled(password.length > 0)
    }
  })
  useEffect(() => {
    switch(props.auth.payload.type){
      case LOGIN_LOADING:
        setLoading(true)
        break;
      case LOGIN_SUCCESSFUL:
        setLoading(false)
        break;
      case LOGIN_NOT_FOUND:
        setLoading(false)
        MySwal.fire(
          'Perhatian',
          'Akun tidak ditemukan',
          'error'
        )
        break;
      case LOGIN_EXIST:
        setLoading(false)
        MySwal.fire(
          'Perhatian',
          'Akun ini sudah masuk di perangkat lain',
          'warning'
        )
        break
      case LOGIN_FAILED:
        setLoading(false)
        MySwal.fire(
          'Perhatian',
          'Gagal Masuk',
          'error'
        )
        break
    }
    if(props.auth.isAuthenticated){
      history.push('/admin/index')
    }
  } , [props.auth])
  return (
    <>
      <div className="main-content" style={{backgroundColor : '#2880CE'}}>
        <AuthNavbar/>
        <div className="header bg-gradient-info py-7 py-lg-5">
          <Container>
            <div className="header-body text-center mb-7">
              <Row className="justify-content-center">
                <Col lg="5" md="6">
                  <img style={{ width : '100px' , height : '100px' , border : '1.5px solid #32325D' , borderRadius : '50%'}} alt="..."
                    src={SuberesLogo}/>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="bg-secondary shadow border-0">
              {
                submit ? isLoading ? 
                <div className="progress-bar">
                  <div className="progress-bar-value"></div>
                </div> : 
                <div style={{height : '4px'}}></div> : <div></div>
              }
              <CardBody className="px-lg-5 py-lg-4">
                {
                  submit && isSuccess === 'no' ? <div style={{ display : 'flex' , paddingLeft : '10px' , paddingTop : '12px'  , paddingRight : '10px' , borderRadius : '10px' , backgroundColor : '#e84855' }}>
                    <i style={{ marginTop : '4px' , color : 'white' }} className="fas fa-exclamation-circle"/>
                    <p style={{ marginTop : '1px' , marginLeft : '10px' , color : 'white' , fontSize : '13px' , fontFamily : 'Open Sans'}}>Kombinasi email dan password salah. Silahkan coba lagi</p>
                  </div> : <div></div>
                }
                <Form style={{ marginTop : '10px' }} role="form">
                  <FormGroup className="mb-3">
                    <InputGroup style={ isEmailFilled ? { border : 'none' } : { border : '0.5px solid red' }} className="input-group-alternative">
                      <InputGroupAddon addonType="prepend" >
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        onChange={onEmailHandler}
                        placeholder="Email"
                        type="email"
                        autoComplete="new-email"
                      />
                    </InputGroup>
                    { isEmailFilled ? <div></div> : <p style={{color : 'red' , fontSize : '11px' , marginTop : '10px'}}>Email belum terisi</p>}
                  </FormGroup>
                  <FormGroup>
                    <InputGroup style={isPasswordFilled ? { border : 'none' } : { border : '0.5px solid red' }} className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        onChange={onPasswordHandler}
                        placeholder="Password"
                        type="password"
                        autoComplete="new-password"
                      />
                    </InputGroup>
                    { isPasswordFilled ? <div></div> : <p style={{color : 'red' , fontSize : '11px' , marginTop : '10px'}}>Password belum terisi</p>}
                  </FormGroup>
                  <div className="text-center">
                    <Button style={ isLoading ? { backgroundColor : 'gray' ,  pointerEvents : 'none' } : { pointerEvents : 'auto' , backgroundColor : '#5e72e4' }} onClick={LoginAdmin} className="my-4" color="primary" type="button">
                      Sign in
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
          </Row>
        </Container>
      </div>
      <AuthFooter/>
    </>
  );
};

Login.propTypes = {
  auth : PropTypes.object.isRequired,
  loginAdmin : PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth
})

export default connect(mapStateToProps, { loginAdmin })(Login)