export const HELP_LIST_LOADING = 'HELP_LIST_LOADING';
export const HELP_LIST_SUCCESSFUL = 'HELP_LIST_SUCCESSFUL';
export const HELP_LIST_FAILED = 'HELP_LIST_FAILED';
export const HELP_LIST_NOT_FOUND = 'HELP_LIST_NOT_FOUND'

export const HELP_DETAIL_LOADING = 'HELP_DETAIL_LOADING';
export const HELP_DETAIL_SUCCESSFUL = 'HELP_DETAIL_SUCCESSFUL';
export const HELP_DETAIL_NOT_FOUND = 'HELP_DETAIL_NOT_FOUND';
export const HELP_DETAIL_FAILED = 'HELP_DETAIL_FAILED';

export const HELP_CREATE_LOADING = 'HELP_CREATE_LOADING';
export const HELP_CREATE_SUCCESSFUL = 'HELP_CREATE_SUCCESSFUL';
export const HELP_CREATE_FAILED = 'HELP_CREATE_FAILED';

export const HELP_UPDATE_LOADING = 'HELP_UPDATE_LOADING'
export const HELP_UPDATE_SUCCESSFUL = 'HELP_UPDATE_SUCCESSFUL';
export const HELP_UPDATE_NOT_FOUND = 'HELP_UPDATE_NOT_FOUND';
export const HELP_UPDATE_FAILED = 'HELP_UPDATE_FAILED';

export const HELP_REMOVE_LOADING = 'HELP_REMOVE_LOADING';
export const HELP_REMOVE_SUCCESSFUL = 'HELP_REMOVE_SUCCESSFUL';
export const HELP_REMOVE_NOT_FOUND = 'HELP_REMOVE_NOT_FOUND';
export const HELP_REMOVE_FAILED = 'HELP_REMOVE_FAILED';

export const HELP_RESET_CRUD = 'HELP_RESET_CRUD'