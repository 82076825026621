import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
    Container,
    Card,
    Row,
    Col,
    CardBody,
    Form,
    FormGroup,
    Input
} from 'reactstrap';
import { useEffect, useRef, useReducer, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { updateComplain, getDetailComplain, resetCrud } from 'actions/Complain/ComplainService';
import {
    COMPLAIN_DETAIL_LOADING,
    COMPLAIN_DETAIL_SUCCESSFUL,
    COMPLAIN_DETAIL_NOT_FOUND,
    COMPLAIN_DETAIL_FAILED,

    COMPLAIN_UPDATE_LOADING,
    COMPLAIN_UPDATE_SUCCESSFUL,
    COMPLAIN_UPDATE_NOT_FOUND,
    COMPLAIN_UPDATE_FAILED
} from 'actions/Complain/Types';
import '../../../assets/css/utilities.css';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import { MaterialGray1 } from 'components/Utilities/ResourceColors';
import IcNotFound from 'assets/img/ic_not_found.svg';
import IcError from 'assets/img/ic_error.svg';
import Skeleton from 'react-loading-skeleton';
import NotFoundPage from 'components/NotFound/NotFoundPage';
import ErrorPage from 'components/ErrorComponent/ErrorPage';
import 'assets/css/card.css';

const ComplainDetailPage = (props) => {
    const [token, setToken] = useState('')
    const mainContent = useRef(null)
    const location = useLocation()
    const history = useHistory()
    const [isLoading, setLoading] = useState(false)
    const [inputValues, setInputValues] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            title_problem: '',
            description: '',
            status : '',
            complain_images: [],
        }
    )
    useEffect(() => {
        setToken(localStorage.getItem('token'))
        if (token !== '') {
            props.getDetailComplain(props.location.state.complain_id, token)
        }
    }, [token])
    const onChangeHandler = event => {
        const { name, value } = event.target
        setInputValues({ [name]: value })
    }
    useEffect(() => {
        console.log(JSON.stringify(props.complain.payload.data, null, 2))
        if (props.complain.payload.data !== undefined) {
            for (const key in props.complain.payload.data) {
                if (props.complain.payload.data.hasOwnProperty(key)) {
                    setInputValues({
                        [key]: props.complain.payload.data[key]
                    })
                }
            }
        }
        buildComplainDetailPage()
    }, [props.complain.payload])
    useEffect(() => {
        if (!localStorage.getItem('token')) {
            history.push('/auth/login')
        }
    }, [location])
    useEffect(() => {
        switch (props.complain.payload_crud.type) {
            case COMPLAIN_UPDATE_LOADING:
                setLoading(true)
                break;
            case COMPLAIN_UPDATE_SUCCESSFUL:
                setLoading(false)
                Swal.fire(
                    'Status',
                    'Berhasil memperbarui data complain',
                    'success'
                )
                if (props.location.state !== undefined && token !== '')
                    props.getDetailComplain(props.location.state.complain_id, token)
                props.resetCrud()
                break;
            case COMPLAIN_UPDATE_NOT_FOUND:
                setLoading(false)
                Swal.fire(
                    'Perhatian',
                    'Tidak ada data complain',
                    'warning'
                )
                break;
            case COMPLAIN_UPDATE_FAILED:
                setLoading(false)
                Swal.fire(
                    'Perhatian',
                    'Gagal memperbarui complain',
                    'error'
                )
                break;
        }
    }, [props.complain.payload_crud])

    function updateComplain() {
        if(props.location.state !== undefined && props.location.state.complain_id !== null){
            props.updateComplain(props.location.state.complain_id , inputValues.status , token)
        }
    }

    function buildComplainDetailPage() {
        switch (props.complain.payload.type) {
            case COMPLAIN_DETAIL_LOADING:
                return (
                    <>
                        <Container className="mt--7" fluid>
                            <Row>
                                <Col className="order-xl-1" xl="12">
                                    <Card className="bg-secondary shadow">
                                        <CardBody>
                                            <Form>
                                                <div>
                                                    <Row>
                                                        <Col lg="12">
                                                            <FormGroup>
                                                                <Skeleton style={{ height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col lg="12">
                                                            <Skeleton style={{ height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <Skeleton style={{ marginTop: '30px', height: '200px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col lg="12">
                                                            <FormGroup>
                                                                <Skeleton style={{ marginTop: '30px', height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Form>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </>
                )
            case COMPLAIN_DETAIL_SUCCESSFUL:
                return (
                    <>
                        <Container className="mt--7" fluid>
                            <Row>
                                <Col className="order-xl-1" xl="12">
                                    <Card className="bg-secondary-shadow">
                                        {
                                            isLoading ?
                                                <div style={{
                                                    borderTopLeftRadius: '5px',
                                                    borderTopRightRadius: '5px',
                                                    height: '5px'
                                                }} className="progress-bar">
                                                    <div className="progress-bar-value"></div>
                                                </div>
                                                : <div style={{
                                                    height: '5px'
                                                }}></div>
                                        }
                                        <CardBody>
                                            <Form>
                                                <div>
                                                    <Row>
                                                        <Col lg="12">
                                                            <Row>
                                                                <Col lg="6">
                                                                    <FormGroup>
                                                                        <label
                                                                            className="form-control-label"
                                                                            htmlFor="input-title_problem">
                                                                            Judul Komplain
                                                                        </label>
                                                                        <Input
                                                                            className="form-control-alternative"
                                                                            id="input-title_problem"
                                                                            type="text"
                                                                            onChange={onChangeHandler}
                                                                            value={inputValues.title_problem}
                                                                            disabled={true} />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col lg="6">
                                                                    <FormGroup>
                                                                        <label
                                                                            className="form-control-label"
                                                                            htmlFor="input-"
                                                                        >
                                                                            Komplain Dari ?
                                                                        </label>
                                                                        <Input
                                                                            className="form-control-alternative"
                                                                            id="input-title_problem"
                                                                            type="text"
                                                                            onChange={onChangeHandler}
                                                                            value={'Customer'}
                                                                            disabled={true} />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col lg="12">
                                                            <FormGroup>
                                                                <label
                                                                    className="form-control-label"
                                                                    htmlFor="input-complain-description"
                                                                >
                                                                    Komplain Gambar
                                                                </label>
                                                                {
                                                                    inputValues.complain_images.length > 0 ?
                                                                        <div class="scrolling-wrapper row flex-row flex-nowrap mt-4 pb-4 pt-2">
                                                                            <div class="col-5">
                                                                                <div style={{ backgroundColor: 'black' }} class="card card-block"></div>
                                                                            </div>
                                                                            <div class="col-5">
                                                                                <div style={{ backgroundColor: 'green' }} class="card card-block"></div>
                                                                            </div>
                                                                            <div class="col-5">
                                                                                <div style={{ backgroundColor: 'yellow' }} class="card card-block"></div>
                                                                            </div>
                                                                            <div class="col-5">
                                                                                <div class="card card-block"></div>
                                                                            </div>
                                                                            <div class="col-5">
                                                                                <div class="card card-block"></div>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <p style={{ textAlign: 'center' }}>Tidak ada gambar komplain</p>
                                                                }
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col lg="12">
                                                            <Row style={{ marginTop: '-10px' }}>
                                                                <Col lg="12">
                                                                    <FormGroup>
                                                                        <label
                                                                            className="form-control-label"
                                                                            htmlFor="input-complain-description"
                                                                        >
                                                                            Isi Komplain
                                                                        </label>
                                                                        <Input
                                                                            type="textarea"
                                                                            id="input-complain-description"
                                                                            defaultValue={inputValues.problem}
                                                                            style={{
                                                                                height: '150px',
                                                                                padding: '0.4em',
                                                                                textAlign: 'left',
                                                                                color: 'black'
                                                                            }}
                                                                            disabled={true} />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md="3">
                                                            <input
                                                                id="is_broadcast"
                                                                type="radio"
                                                                style={{ marginRight: '5px' }}
                                                                checked={inputValues.status === 'SENT'}
                                                                onChange={e => {
                                                                    setInputValues({ ['status'] : 'SENT' })
                                                                }} />
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="is_broadcast" style={{ fontSize: '13px' }}>Terkirim</label>
                                                        </Col>
                                                        <Col md="3">
                                                            <input
                                                                id="is_broadcast_no"
                                                                type="radio"
                                                                style={{ marginRight: '5px' }}
                                                                checked={inputValues.status === 'ON_REVIEW'}
                                                                onChange={e => {
                                                                    setInputValues({ ['status'] : 'ON_REVIEW' })
                                                                }} />
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="is_broadcast_no" style={{ fontSize: '13px' }}>On Review</label>
                                                        </Col>
                                                        <Col md="3">
                                                            <input
                                                                id="is_broadcast_no"
                                                                type="radio"
                                                                style={{ marginRight: '5px' }}
                                                                checked={inputValues.status === 'SOLVED'}
                                                                onChange={e => {
                                                                    setInputValues({ ['status'] : 'SOLVED' })
                                                                }} />
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="is_broadcast_no" style={{ fontSize: '13px' }}>Solved</label>
                                                        </Col>
                                                        <Col md="3">
                                                            <input
                                                                id="is_broadcast_no"
                                                                type="radio"
                                                                style={{ marginRight: '5px' }}
                                                                checked={inputValues.status === 'CLOSED'}
                                                                onChange={e => {
                                                                    setInputValues({ ['status'] : 'CLOSED' })
                                                                }} />
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="is_broadcast_no" style={{ fontSize: '13px' }}>Closed</label>
                                                        </Col>
                                                    </Row>
                                                    <Row style={{ marginTop : '20px' }}>
                                                        <Col lg="12">
                                                            <a onClick={() => updateComplain()} style={{ width: '100%' }} className="btn btn-success btn-sm">
                                                                Simpan
                                                            </a>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Form>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </>
                )
            case COMPLAIN_DETAIL_NOT_FOUND:
                return (
                    <>
                        <Container className="mt--7" fluid>
                            <Row>
                                <Col className="order-xl-1" xl="12">
                                    <Card className="bg-secondary-shadow">
                                        <CardBody>
                                            <NotFoundPage
                                                imageSource={IcNotFound}
                                                imageText={props.complain.payload.message} />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </>
                )
            case COMPLAIN_DETAIL_FAILED:
                return (
                    <>
                        <Container className="mt--7" fluid>
                            <Row>
                                <Col className="order-xl-1" xl="12">
                                    <Card className="bg-secondary-shadow">
                                        <CardBody>
                                            <ErrorPage
                                                imageSource={IcError}
                                                imageText={props.complain.payload.message} />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </>
                )
        }
    }

    return (
        <>
            <div className="main-content" ref={mainContent}>
                <div className="header bg-gradient-info pb-8 pt-5 pt-lg-7 d-flex align-items-center">
                    <span className="mask bg-gradient-default opacity-8" />
                </div>
                {buildComplainDetailPage()}
            </div>
        </>
    )
}

ComplainDetailPage.propTypes = {
    complain: PropTypes.object.isRequired,
    updateComplain: PropTypes.func.isRequired,
    getDetailComplain: PropTypes.func.isRequired,
    resetCrud: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    complain: state.complain
})

export default connect(mapStateToProps, { updateComplain, getDetailComplain, resetCrud })(ComplainDetailPage)