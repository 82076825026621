import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useState, useReducer, useRef, useEffect } from 'react';
import '../../../assets/css/utilities.css';
import IcImage from '../../../assets/img/ic_image.svg';
import Swal from 'sweetalert2';
import { getParentDetail, updateParentDetail, updateParentImageDetail , resetCrud } from 'actions/ParentService/ParentService';
import { useHistory } from 'react-router-dom';
import { PARENT_DETAIL_LOADING, PARENT_DETAIL_SUCCESSFUL, PARENT_DETAIL_NOT_FOUND, PARENT_DETAIL_FAILED } from 'actions/ParentService/Types';
import { Card, CardBody, Container, Row, Col, Form, FormGroup, Input } from 'reactstrap';
import Skeleton from 'react-loading-skeleton';
import { MaterialGray1 , MaterialRed1 } from 'components/Utilities/ResourceColors';
import { MaterialGray2 } from 'components/Utilities/ResourceColors';
import { BASE_URL } from 'actions/config';
import IcEmptyBox from '../../../assets/img/ic_empty_box.svg';
import { MaterialBlue1 } from 'components/Utilities/ResourceColors';
import NotFoundPage from 'components/NotFound/NotFoundPage';
import ErrorPage from 'components/ErrorComponent/ErrorPage';
import { PARENT_UPDATE_LOADING } from 'actions/ParentService/Types';
import { PARENT_UPDATE_SUCCESSFUL } from 'actions/ParentService/Types';
import withReactContent from 'sweetalert2-react-content';
import { PARENT_UPDATE_NOT_FOUND } from 'actions/ParentService/Types';
import { PARENT_UPDATE_FAILED } from 'actions/ParentService/Types';
import { getJSONCredential } from 'actions/config';
import Select from 'react-select';
import { PARENT_REMOVE_LOADING } from 'actions/LayananService/Types';
import { PARENT_REMOVE_SUCCESSFUL } from 'actions/LayananService/Types';
import { PARENT_REMOVE_UNAUTHORIZED } from 'actions/LayananService/Types';
import { PARENT_REMOVE_NOT_FOUND } from 'actions/LayananService/Types';
import { PARENT_REMOVE_FAILED } from 'actions/LayananService/Types';
import { erorrMsgStyle } from 'components/Utilities/Utility';
import { RED_ERROR_MESSAGE } from 'components/Utilities/ResourceColors';
import { validateEmpty } from 'components/Utilities/Utility';

const MySwal = withReactContent(Swal)

const options = [
    {
        value : 'open' , label : 'Open'
    },
    {
        value : 'coming soon' , label : 'Coming Soon'
    },
    {
        value : 'problem' , label : 'Problem'
    },
    {
        value : 'closed' , label : 'Closed'
    }
]

const LayananUpdateParentService = (props) => {
    const history = useHistory()
    const fileRef = useRef()
    const [isSubmit, setSubmit] = useState(false)
    const [isImageHover , setImageHover] = useState(false)
    const [imgProper, setImgProper] = useState(false)
    const fileRefChange = useRef()
    const [selectedFile, setSelectedFile] = useState(null)
    const [parentImage, setParentImage] = useState(null)
    const [token , setToken] = useState('')
    useEffect(() => {
        setToken(localStorage.getItem('token'))
    } , [token])
    const [inputValues, setInputValues] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            title_parent_service: '',
            description_parent_service: '',
            tipe_paket: '',
            parent_service_status: {
                value : 'open',
                label : 'Open'
            }
        }
    )
    const [isUpdating, setUpdating] = useState(false)
    const onChangeHandler = event => {
        const { name, value } = event.target
        setInputValues({ [name]: value })
    }
    const onChangeImageHandler = event => {
        var img = new Image()
        setParentImage(URL.createObjectURL(event.target.files[0]))
        img.onload = function () {
            if (img.width == 450 && img.height == 450) {
                setSelectedFile(event.target.files[0])
                setImgProper(true)
            } else {
                setImgProper(false)
            }
        }
        img.src = URL.createObjectURL(event.target.files[0])
    }
    useEffect(() => {
        if(token !== ''){
            props.getParentDetail(props.location.state.parent_id , token)
        }
    }, [token])
    useEffect(() => {
        if (props.parent_payload.parent_detail_payload.data !== undefined) {
            for (const key in props.parent_payload.parent_detail_payload.data) {
                if (props.parent_payload.parent_detail_payload.data.hasOwnProperty(key)) {
                    if(key === 'parent_service_status'){
                        let parent_type_selected = props.parent_payload.parent_detail_payload.data[key]
                        if (parent_type_selected.split(" ").length > 1) {
                            const split_status = parent_type_selected.split(" ")
                            parent_type_selected = ""
                            for (let i = 0; i < split_status.length; i++) {
                                parent_type_selected += split_status[i].charAt(0).toUpperCase() + split_status[i].slice(1) + (i == split_status.length - 1 ? "" : " ")
                            }
                        } else {
                            parent_type_selected = parent_type_selected.charAt(0).toUpperCase() + parent_type_selected.slice(1)
                        }
                        setInputValues({ ['parent_service_status'] : {
                            value : props.parent_payload.parent_detail_payload.data[key],
                            label : parent_type_selected
                        }})
                    } else {
                        setInputValues({ [key]: props.parent_payload.parent_detail_payload.data[key] })
                    }
                }
            }
        }
        buildParentDetail()
    }, [props.parent_payload.parent_detail_payload])
    useEffect(() => {
        switch(props.parent_payload.parent_update.payload.type){
            case PARENT_UPDATE_LOADING:
                setUpdating(true)
                break;
            case PARENT_UPDATE_SUCCESSFUL:
                setUpdating(false)
                setParentImage(null)
                setSelectedFile(null)
                setSubmit(false)
                setImgProper(false)
                props.resetCrud()
                if(token !== ''){
                    props.getParentDetail(props.location.state.parent_id , token)
                }
                MySwal.fire(
                    'Perhatian',
                    'Berhasil Memperbarui Paket',
                    'success'
                )
                break;
            case PARENT_UPDATE_NOT_FOUND:
                setUpdating(false)
                MySwal.fire(
                    'Perhatian',
                    'Data Paket Tidak Ada',
                    'error'
                )
                break;
            case PARENT_UPDATE_FAILED:
                setUpdating(false)
                MySwal.fire(
                    'Perhatian',
                    'Gagal Memperbarui Paket',
                    'error'
                )
                break;
        }
    } , [props.parent_payload.parent_update])
    
    useEffect(() => {
        console.log(`Value Changed : ${JSON.stringify(inputValues.parent_service_status , null , 2)}`)
    } , [inputValues.parent_service_status])
    function editUpdate(){
        setSubmit(true)
        const {
            title_parent_service,
            description_parent_service,
            tipe_paket
        } = inputValues
        const parent_service_status = inputValues.parent_service_status.value
        const json_data = getJSONCredential()['data']
        const payload_json = {
            creator_id : json_data['id'],
            title_parent_service : title_parent_service,
            description_parent_service : description_parent_service,
            tipe_paket : tipe_paket,
            parent_service_status : parent_service_status
        }
        console.log(JSON.stringify(payload_json , null , 2))
        if(selectedFile !== null){
            const formData = new FormData()
            formData.append("json_data" , JSON.stringify(payload_json))
            formData.append("file" , selectedFile)
            console.log(formData.json_data)
            console.log(props.location.state.parent_id)
            if(token !== ''){
                props.updateParentImageDetail(props.location.state.parent_id , formData , token)
            }
        } else {
            if(token !== ''){
                props.updateParentDetail(props.location.state.parent_id , JSON.stringify(payload_json) , token)
            }
        }
    }
    function validateBorderImg() {
        if (isSubmit) {
            if (parentImage == null || (parentImage != null && !imgProper)) {
                return `1.5px dashed ${RED_ERROR_MESSAGE}`
            }
        } else {
            if (parentImage != null && !imgProper) {
                return `1.5px dashed ${RED_ERROR_MESSAGE}`
            }
        }
    }
    function validateTextImg() {
        if (isSubmit) {
            if (parentImage == null) {
                if (!validateEmpty(inputValues.image_banner_service)) {
                    return (
                        <p style={
                            erorrMsgStyle
                        }>Gambar Paket must be filled</p>
                    )
                }
            } else if (parentImage != null && !imgProper) {
                return (
                    <p style={
                        erorrMsgStyle
                    }>Gambar Paket must bes 450x X 450px</p>
                )
            } else {
                return (<></>)
            }
        } else {
            if (parentImage != null && !imgProper) {
                return (
                    <p style={
                        erorrMsgStyle
                    }>Gambar Paket must be 450px X 450px</p>
                )
            } else {
                return (<></>)
            }
        }
    }
    function buildParentDetail() {
        console.log(props.parent_payload.parent_detail_payload)
        switch (props.parent_payload.parent_detail_payload.type) {
            case PARENT_DETAIL_LOADING:
                return (
                    <Container className="mt--7" fluid>
                        <Row>
                            <Col className="order-xl-1" xl="12">
                                <Card className="shadow">
                                    <CardBody>
                                        <Form>
                                            <div>
                                                <Row>
                                                    <Col lg="12">
                                                        <FormGroup>
                                                            <Skeleton style={{ height: '40px', backgroundColor: MaterialGray1 }} />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg="12">
                                                        <Skeleton style={{ height: '40px', backgroundColor: MaterialGray1 }} />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Skeleton style={{ marginTop: '30px', height: '200px', backgroundColor: MaterialGray1 }} />
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                )
            case PARENT_DETAIL_SUCCESSFUL:
                return (
                    <Container className="mt--7" fluid>
                        <Row>
                            <Col className="order-xl-1" xl="12">
                                <Card className="bg-secondary-shadow">
                                    {
                                        isUpdating ?
                                            <div style={{
                                                borderTopLeftRadius: '5px',
                                                borderTopRightRadius: '5px',
                                                height: '5px'
                                            }} className="progress-bar">
                                                <div className="progress-bar-value"></div>
                                            </div>
                                            : <div style={{
                                                height: '5px'
                                            }}></div>
                                    }
                                    <CardBody>
                                        <Form>
                                            <div>
                                                <Row style={{ marginTop : '-10px' }}>
                                                    <Col md="6">
                                                        <Row>
                                                            <Col lg="12">
                                                                <FormGroup>
                                                                    <label
                                                                        className="form-control-label"
                                                                        htmlFor="input-title">
                                                                        Nama Paket
                                                                    </label>
                                                                    <Input
                                                                        className="form-control-alternative"
                                                                        id="input-title"
                                                                        type="text"
                                                                        name="title_parent_service"
                                                                        onChange={onChangeHandler}
                                                                        defaultValue={inputValues.title_parent_service}
                                                                        style={
                                                                            isSubmit && inputValues.title_parent_service === '' ?
                                                                                {
                                                                                    color: 'black',
                                                                                    border: '0.2px solid #d32f2f'
                                                                                }
                                                                                :
                                                                                {
                                                                                    color: 'black',
                                                                                    border: 'none'
                                                                                }
                                                                        }
                                                                        disabled={(isUpdating) ? "disabled" : ""} />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row style={{ marginTop : '-10px' }}>
                                                            <Col lg="6">
                                                                <FormGroup>
                                                                    <label
                                                                        className="form-control-label"
                                                                        htmlFor="input-select-type"
                                                                    >
                                                                        Status Paket
                                                                    </label>
                                                                    <Select
                                                                        id="input-select-type"
                                                                        options={options}
                                                                        value={
                                                                            inputValues.parent_service_status
                                                                        }
                                                                        name="parent_service_status"
                                                                        onChange={(selectedOption) => {
                                                                            setInputValues({ ['parent_service_status'] : selectedOption })
                                                                        }}/>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col lg="6">
                                                                <FormGroup>
                                                                <label
                                                                        className="form-control-label"
                                                                        htmlFor="input-tipe">
                                                                        Nama Paket
                                                                    </label>
                                                                    <Input
                                                                        className="form-control-alternative"
                                                                        id="input-tipe"
                                                                        type="text"
                                                                        name="tipe_paket"
                                                                        onChange={onChangeHandler}
                                                                        defaultValue={inputValues.tipe_paket}
                                                                        style={
                                                                            isSubmit && inputValues.tipe_paket === '' ?
                                                                                {
                                                                                    color: 'black',
                                                                                    border: '0.2px solid #d32f2f'
                                                                                }
                                                                                :
                                                                                {
                                                                                    color: 'black',
                                                                                    border: 'none'
                                                                                }
                                                                        }
                                                                        disabled={(isUpdating) ? "disabled" : ""} />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row style={{ marginTop : '-10px' }}>
                                                            <Col lg="12">
                                                                <FormGroup>
                                                                    <label
                                                                        className="form-control-label"
                                                                        htmlFor="input-banner-description"
                                                                    >
                                                                        Deskripsi Paket
                                                                    </label>
                                                                    <Input
                                                                        type="textarea"
                                                                        id="input-banner-description"
                                                                        name="description_parent_service"
                                                                        defaultValue={inputValues.description_parent_service}
                                                                        style={{
                                                                            height: '150px',
                                                                            padding: '0.4em',
                                                                            textAlign: 'left',
                                                                            color: 'black'
                                                                        }}
                                                                        disabled={(isUpdating) ? "disabled" : ""}
                                                                        onChange={onChangeHandler} />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col lg="6">
                                                    <div>
                                                                <label
                                                                    className="form-control-label">Gambar Paket</label>
                                                                {
                                                                    inputValues.image_banner_service === '' ?
                                                                        <div id="placeholder_image"
                                                                            style={{
                                                                                width: '100%',
                                                                                height: '260px',
                                                                                borderRadius: '10px',
                                                                                alignItems: 'center',
                                                                                display: 'flex',
                                                                                justifyContent: 'center',
                                                                                textAlign: 'center',
                                                                                backgroundColor: MaterialGray2,
                                                                                border: '1px dashed black',
                                                                                cursor: 'pointer'
                                                                            }}
                                                                            onClick={e => {
                                                                                fileRef.current.click()
                                                                            }}
                                                                        >
                                                                            <input type="file" name="image_picker" ref={fileRef} hidden />
                                                                        </div>
                                                                        :
                                                                        <div style={{
                                                                            alignItems: 'center',
                                                                            display: 'flex',
                                                                            justifyContent: 'center',
                                                                            border: parentImage === null ? '1px dashed black' : '',
                                                                            borderRadius: '10px'
                                                                        }}>
                                                                            {
                                                                                parentImage === null ?
                                                                                    <div style={{
                                                                                        alignItems: 'center',
                                                                                        display: 'flex',
                                                                                        justifyContent: 'center'
                                                                                    }}>
                                                                                        <img style={{
                                                                                            width: '100%',
                                                                                            height: '260px',
                                                                                            objectFit : 'cover',
                                                                                            borderRadius: '10px',
                                                                                            transition: '0.45s'
                                                                                        }} src={`${BASE_URL}/api/images/layanan_image/${inputValues.image_banner_service}`} />
                                                                                    </div>
                                                                                    :
                                                                                    <div style={{
                                                                                        width: '100%',
                                                                                        border: validateBorderImg(),
                                                                                        borderRadius: '10px',
                                                                                        alignItems: 'center',
                                                                                        justifyContent: 'center',
                                                                                        textAlign: 'center'
                                                                                    }}>
                                                                                        <div style={{
                                                                                            width: '25px',
                                                                                            height: '25px',
                                                                                            borderRadius: '50%',
                                                                                            backgroundColor: MaterialRed1,
                                                                                            position: 'absolute',
                                                                                            right: 8,
                                                                                            top: 22,
                                                                                            cursor: 'pointer'
                                                                                        }}
                                                                                            onClick={() => {
                                                                                                setSelectedFile(null)
                                                                                                setParentImage(null)
                                                                                            }}
                                                                                        >
                                                                                            <p style={{ color: 'white' }}>&times;</p>
                                                                                        </div>
                                                                                        <img src={parentImage} style={{
                                                                                            width: '100%',
                                                                                            objectFit: 'cover',
                                                                                            borderRadius: '10px',
                                                                                            height: '260px',
                                                                                            display: 'inline-block'
                                                                                        }} />
                                                                                    </div>
                                                                            }
                                                                        </div>
                                                                }
                                                                <Row>
                                                                    <Col sm="8">
                                                                        <p style={{ fontSize: '13px', marginTop: '10px' }}>
                                                                            Ukuran Gambar 450px X 450px
                                                                        </p>
                                                                    </Col>
                                                                    <Col sm="4">
                                                                        <div>
                                                                            <input type="file" onChange={onChangeImageHandler} name="image_picker_change" ref={fileRefChange} hidden />
                                                                            <button onClick={(e) => {
                                                                                e.preventDefault()
                                                                                if (!isUpdating) {
                                                                                    fileRefChange.current.click()
                                                                                }
                                                                            }} style={{
                                                                                marginTop: '10px',
                                                                                width: '100%'
                                                                            }} className="btn btn-sm btn-primary">Ganti gambar</button>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col xl="12">
                                                                        {validateTextImg()}
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg="12">
                                                        <a onClick={() => editUpdate()} style={{ width: '100%', marginTop: '10px' }} className="btn btn-success btn-sm">
                                                            Simpan
                                                        </a>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                )
            case PARENT_DETAIL_NOT_FOUND:
                return (
                    <Container className="mt--7" fluid>
                        <NotFoundPage
                            imageSource={IcEmptyBox}
                            imageText={"Tidak Ada Data Paket"}/>
                    </Container>
                )
            case PARENT_DETAIL_FAILED:
                return (
                    <Container className="mt--7" fluid>
                        <ErrorPage
                            imageSource={IcEmptyBox}
                            imageText={"Gagal Memuat Data Paket"}/>
                    </Container>
                )
        }
    }
    return (
        <>
            <div>
                <div className="header bg-gradient-info pb-8 pt-5 pt-lg-7 d-flex align-items-center">
                    <span className="mask bg-gradient-default opacity-8" />
                </div>
                {buildParentDetail()}
            </div>
        </>
    )
}

LayananUpdateParentService.propTypes = {
    parent_payload: PropTypes.object.isRequired,
    getParentDetail: PropTypes.func.isRequired,
    updateParentDetail: PropTypes.func.isRequired,
    updateParentImageDetail: PropTypes.func.isRequired,
    resetCrud : PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    parent_payload: state.parent_payload
})

export default connect(mapStateToProps, { getParentDetail, updateParentDetail, updateParentImageDetail , resetCrud })(LayananUpdateParentService)