import React from 'react';
import { useLocation , Route , Switch , Redirect } from 'react-router-dom';
import { Container } from 'reactstrap';

import AdminNavbar from 'components/Navbars/AdminNavbar';
import Sidebar from 'components/Sidebar/Sidebar';
import { useHistory } from 'react-router-dom';

import routes from '../../routes';

const Admin = (props) => {
    const mainContent = React.useRef(null)
    const location = useLocation()
    const history = useHistory()
    React.useEffect(() => {
        document.documentElement.scrollTop = 0
        document.scrollingElement.scrollTop = 0
        mainContent.current.scrollTop = 0
        if(!localStorage.getItem('token')){
            history.push('/auth/login')
        }
    } , [location])
    const getRoutes = (routes) => {
        return routes.map((prop , key) => {
            if(prop.layout === '/admin'){
                return (
                    <Route
                        path={prop.layout + prop.path}
                        component={prop.component}
                        key={key}/>
                )
            } else {
                return null;
            }
        })
    }
    const getBrandText = (path) => {
        for(let i = 0; i < routes.length; i++){
            if(props.location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1){
                return routes[i].title
            }
        }
        return "Brand";
    }
    return (
        <>
            <Sidebar
                {...props}
                routes={routes}
                logo={{
                    innerLink : '/admin/index'
                }}/>
            <div className="main-content" style={{height : '100vh' }} ref={mainContent}>
                <AdminNavbar
                    {...props}
                    brandText={getBrandText(props.location.pathname)}/>
                <Switch>
                    {getRoutes(routes)}
                    <Redirect from="*" to="/admin/index"/>
                </Switch>
            </div>
        </>
    )
}

export default Admin