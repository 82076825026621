import { BASE_URL } from 'actions/config';
import axios from 'axios';
import { 
    SERVICE_LIST_LOADING ,
    SERVICE_LIST_SUCCESSFUL ,
    SERVICE_LIST_NOT_FOUND ,
    SERVICE_LIST_FAILED ,
    SERVICE_DETAIL_LOADING ,
    SERVICE_DETAIL_SUCCESSFUL ,
    SERVICE_DETAIL_NOT_FOUND ,
    SERVICE_DETAIL_FAILED,
    SERVICE_CREATE_LOADING,
    SERVICE_CREATE_SUCCESSFUL,
    SERVICE_CREATE_FAILED,
    SERVICE_UPDATE_LOADING,
    SERVICE_UPDATE_SUCCESSFUL,
    SERVICE_UPDATE_NOT_FOUND,
    SERVICE_UPDATE_FAILED,
    SERVICE_REMOVE_LOADING,
    SERVICE_REMOVE_SUCCESSFUL,
    SERVICE_REMOVE_UNAUTHORIZED,
    SERVICE_REMOVE_NOT_FOUND,
    SERVICE_REMOVE_FAILED
} from './Types';
import { passingHeaderTokenFormData } from 'components/Utilities/Utility';
import { passingHeaderToken } from 'components/Utilities/Utility';

export const getServiceList = (parent_id , page , limit , token) => async dispatch => {
    try {
        dispatch({
            type : SERVICE_LIST_LOADING,
            message : 'Memuat Data Service'
        })
        const response = await axios.get(`${BASE_URL}/api/services/index/${parent_id}?page=${page}&limit=${limit}` , passingHeaderToken(token))
        if(response.data !== undefined && response.data.rows_data.docs.length === 0){
            setTimeout(() => {
                dispatch({
                    type : SERVICE_LIST_NOT_FOUND,
                    message : 'Tidak Ada Data Service'
                })
            } , 300)
        } else {
            setTimeout(() => {
                dispatch({
                    type : SERVICE_LIST_SUCCESSFUL,
                    data : response.data
                })
            } , 300)
        }
    } catch (err) {
        if (err.response.status === 404){
            setTimeout(() => {
                dispatch({
                    type : SERVICE_LIST_NOT_FOUND,
                    message : 'Tidak Ada Data Layanan'
                })
            } , 300)
        } else {
            setTimeout(() => {
                dispatch({
                    type : SERVICE_LIST_FAILED,
                    message : 'Gagal Memuat Data Layanan'
                })
            } , 300)
        }
    }
}

export const getServiceDetail = (service_id , token) => async dispatch => {
    try {
        dispatch({
            type : SERVICE_DETAIL_LOADING,
            message : 'Memuat Detail Service'
        })
        const response = await axios.get(`${BASE_URL}/api/services/detail/${service_id}` , passingHeaderToken(token))
        if(response.data != null){
            setTimeout(() => {
                dispatch({
                    type : SERVICE_DETAIL_SUCCESSFUL,
                    data : response.data
                })
            } , 300)
        } else {
            setTimeout(() => {
                dispatch({
                    type : SERVICE_DETAIL_NOT_FOUND,
                    message : 'data tidak ada'
                })
            } , 300)
        }
    } catch (err) {
        if(err.response.status === 404){
            dispatch({
                type : SERVICE_DETAIL_NOT_FOUND,
                message : 'Tidak Ada Data Layanan'
            })
        } else {
            dispatch({
                type : SERVICE_DETAIL_FAILED,
                message : 'Gagal Memuat Data Layanan'
            })
        }
    }
}

export const createService = (form_data , token) => async dispatch => {
    try {
        dispatch({
            type : SERVICE_CREATE_LOADING,
            message : 'Membuat service baru'
        })
        const response = await axios.post(`${BASE_URL}/api/services/create` , form_data , passingHeaderTokenFormData(token))
        dispatch({
            type : SERVICE_CREATE_SUCCESSFUL,
            message : response.data
        })
    } catch (err) {
        dispatch({
            type : SERVICE_CREATE_FAILED,
            message : 'Gagal membuat service baru'
        })
    }
}

export const updateServiceImage = (form_data , token) => async dispatch => {
    try {
        dispatch({
            type : SERVICE_UPDATE_LOADING,
            message : 'Memperbarui service'
        })
        await axios.put(`${BASE_URL}/api/services/update/image` , form_data , passingHeaderTokenFormData(token))
        dispatch({
            type : SERVICE_UPDATE_SUCCESSFUL,
            message : 'Berhasil memperbarui service'
        })
    } catch (err) {
        if(err.response !== undefined && err.response.status === 404){
            dispatch({
                type : SERVICE_UPDATE_NOT_FOUND,
                message : 'Tidak ada data service'
            })
        } else {
            dispatch({
                type : SERVICE_UPDATE_FAILED,
                message : 'Gagal memperbarui data service'
            })
        }
    }
}

export const updateService = (payload , token) => async dispatch => {
    try {
        dispatch({
            type : SERVICE_UPDATE_LOADING,
            message : 'Memperbarui service'
        })
        await axios.put(`${BASE_URL}/api/services/update` , payload , passingHeaderToken(token))
        dispatch({
            type : SERVICE_UPDATE_SUCCESSFUL,
            message : 'Berhasil memperbarui service'
        })
    } catch (err) {
        if(err.response !== undefined && err.response.status === 404){
            dispatch({
                type : SERVICE_UPDATE_NOT_FOUND,
                message : 'Tidak ada data service'
            })
        } else {
            dispatch({
                type : SERVICE_UPDATE_FAILED,
                message : 'Gagal memperbarui data service'
            })
        }
    }
}

export const removeService = (id , password , token) => async dispatch => {
    try {
        dispatch({
            type : SERVICE_REMOVE_LOADING,
            message : 'Menghapus data service'
        })
        await axios.delete(`${BASE_URL}/api/services/remove/${id}/${password}` , passingHeaderToken(token))
        dispatch({
            type : SERVICE_REMOVE_SUCCESSFUL,
            message : 'Berhasil menghapus data service'
        })
    } catch (err) {
        if(err.response !== undefined && err.response.status === 401){
            dispatch({
                type : SERVICE_REMOVE_UNAUTHORIZED,
                message : 'Password salah'
            })
        } else if(err.response !== undefined && err.response.status === 404){
            dispatch({
                type : SERVICE_REMOVE_NOT_FOUND,
                message : 'Data service tidak ada'
            })
        } else {
            dispatch({
                type : SERVICE_REMOVE_FAILED,
                message : 'Gagal memperbarui data service'
            })
        }
    }
}