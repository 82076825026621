import { BASE_URL } from "actions/config";
import axios from 'axios';
import {
    SUB_SERVICE_ADD_CREATE_LOADING,
    SUB_SERVICE_ADD_CREATE_SUCCESSFUL,
    SUB_SERVICE_ADD_CREATE_FAILED,
    SUB_SERVICE_ADD_UPDATE_LOADING,
    SUB_SERVICE_ADD_UPDATE_SUCCESSFUL,
    SUB_SERVICE_ADD_UPDATE_NOT_FOUND,
    SUB_SERVICE_ADD_UPDATE_FAILED,
    SUB_SERVICE_ADD_REMOVE_LOADING,
    SUB_SERVICE_ADD_REMOVE_SUCCESSFUL,
    SUB_SERVICE_ADD_REMOVE_UNAUTHORIZED,
    SUB_SERVICE_ADD_REMOVE_NOT_FOUND,
    SUB_SERVICE_ADD_REMOVE_FAILED,

    SUB_SERVICE_ADD_RESET_CRUD
} from './Types';
import { passingHeaderToken } from "components/Utilities/Utility";

export const createSubServiceAdditional = (json_data , token) => async dispatch => {
    try {
        dispatch({
            type : SUB_SERVICE_ADD_CREATE_LOADING,
            message : 'Sedang Membuat Layanan Tambahan'
        })
        const response = await axios.post(`${BASE_URL}/api/sub_service_additional/create` , json_data , passingHeaderToken(token))
        console.log(response)
        dispatch({
            type : SUB_SERVICE_ADD_CREATE_SUCCESSFUL,
            message : 'Berhasil Membuat Layanan Tambahan',
            returning_data : response.data.data
        })
    } catch (err) {
        dispatch({
            type : SUB_SERVICE_ADD_CREATE_FAILED,
            message : 'Gagal Membuat Layanan Tambahan'
        })
    }
}

export const updateSubServiceAdditional = (id , json_data , token) => async dispatch => {
    try {
        dispatch({
            type : SUB_SERVICE_ADD_UPDATE_LOADING,
            message : 'Sedang Memperbarui Layanan Tambahan'
        })
        const response = await axios.put(`${BASE_URL}/api/sub_service_additional/update/${id}` , json_data , passingHeaderToken(token))
        dispatch({
            type : SUB_SERVICE_ADD_UPDATE_SUCCESSFUL,
            message : 'Berhasil Memperbarui Layanan Tambahan',
            returning_data : response.data.data
        })
    } catch (err) {
        if(err.response.status === 404){
            dispatch({
                type : SUB_SERVICE_ADD_UPDATE_NOT_FOUND,
                message : 'Tidak Ada Layanan Tambahan'
            })
        } else {
            dispatch({
                type : SUB_SERVICE_ADD_UPDATE_FAILED,
                message : 'Gagal Memperbarui Layanan Tambahan'
            })
        }
    }
}

export const removeSubServiceAdditional = (id , password , token) => async dispatch => {
    try {
        dispatch({
            type : SUB_SERVICE_ADD_REMOVE_LOADING,
            message : 'Menghapus data tambahan layanan'
        })
        await axios.delete(`${BASE_URL}/api/sub_service_additional/remove/${id}/${password}` , passingHeaderToken(token))
        dispatch({
            type : SUB_SERVICE_ADD_REMOVE_SUCCESSFUL,
            message : 'Berhasil menghapus data tambahan layanan'
        })
    } catch (err) {
        if(err.response !== undefined && err.response.status === 401){
            dispatch({
                type : SUB_SERVICE_ADD_REMOVE_UNAUTHORIZED,
                message : 'Password salah'
            })
        } else if(err.response !== undefined && err.response.status === 404){
            dispatch({
                type : SUB_SERVICE_ADD_REMOVE_NOT_FOUND,
                message : 'Data tambahan layanan tidak ada'
            })
        } else {
            dispatch({
                type : SUB_SERVICE_ADD_REMOVE_FAILED,
                message : 'Gagal menghapus data tambahan layanan'
            })
        }
    }
}

export const resetSubServiceAddCrud = () => async dispatch => {
    dispatch({
        type : SUB_SERVICE_ADD_RESET_CRUD,
        message : ''
    })
}