import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter , Route , Switch , Redirect } from 'react-router-dom';
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";

import AdminLayout from "./components/Layouts/Admin";
import MitraDetailPage from 'components/Pages/Mitra/MitraDetailPage';
import BannerDetailPage from 'components/Pages/Banners/BannerDetailPage';
import BannerCreatePage from 'components/Pages/Banners/BannerCreatePage';
import MitraDetailCandidate from 'components/Pages/MitraCandidate/MitraDetailCandidate';
import Login from './components/Pages/Login';
import store from './store';

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Switch>
          <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
          <Route path="/auth/login" render={(props) => <Login/>}/>
          <Redirect from="/" to="/auth/login" />
      </Switch>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
