import { SOCKET_SET , SOCKET_REMOVE } from "./Types";

export const socketSet = (socket) => async dispatch => {
    dispatch({
        type : SOCKET_SET,
        payload : {
            socket : socket
        }
    })
}

export const socketRemove = () => async dispatch => {
    dispatch({
        type : SOCKET_REMOVE,
        payload : {}
    })
}