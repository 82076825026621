import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { Card, CardBody, Col, Container, Row, Table } from "reactstrap";
import React, { useEffect, useState } from "react";
import { getTOC, updateTocStatus, removeTOC, resetCrud, setTOCEmpty } from "actions/Toc/TocService";
import Skeleton from "react-loading-skeleton";
import Pagination from "components/Pagination/Pagination";
import NotFoundPage from "components/NotFound/NotFoundPage";
import ErrorPage from "components/ErrorComponent/ErrorPage";
import IcNotFound from 'assets/img/ic_not_found.svg';
import IcError from 'assets/img/ic_error.svg';
import { useHistory } from 'react-router-dom';

import {
    TOC_LIST_LOADING,
    TOC_LIST_SUCCESSFUL,
    TOC_LIST_NOT_FOUND,
    TOC_LIST_FAILED,

    TOC_UPDATE_STATUS_LOADING,
    TOC_UPDATE_STATUS_SUCCESSFUL,
    TOC_UPDATE_STATUS_WARNING,
    TOC_UPDATE_STATUS_FAILED,

    TOC_REMOVE_LOADING,
    TOC_REMOVE_SUCCESSFUL,
    TOC_REMOVE_NOT_FOUND,
    TOC_REMOVE_FAILED
} from 'actions/Toc/Types'
import 'assets/css/utilities.css';
import { MaterialGray1 } from "components/Utilities/ResourceColors";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { MaterialRed3 } from "components/Utilities/ResourceColors";
import { MaterialGreen2 } from "components/Utilities/ResourceColors";
import { validateEmpty } from "components/Utilities/Utility";
import { MaterialBlue3 } from "components/Utilities/ResourceColors";
const MySwal = withReactContent(Swal)

const TocPage = (props) => {
    const [token, setToken] = useState('')
    const [currentPage, setCurrentPage] = useState(1)
    const [updateLoading, setUpdateLoading] = useState(false)
    const mainContent = React.useRef(null)
    const history = useHistory()
    const limitPage = 5

    useEffect(() => {
        setToken(localStorage.getItem('token'))
        if (validateEmpty(token)) {
            props.getTOC(currentPage, limitPage, token)
        }
    }, [token])
    useEffect(() => {
        if (validateEmpty(token)) {
            props.getTOC(currentPage, limitPage, token)
        }
    }, [currentPage])

    useEffect(() => {
        console.log(`TOC Pay Type : ${props.toc.payload_crud.type}`)
        switch (props.toc.payload_crud.type) {
            case TOC_UPDATE_STATUS_LOADING:
                setUpdateLoading(true)
                console.log('loading')
                break;
            case TOC_UPDATE_STATUS_SUCCESSFUL:
                setUpdateLoading(false)
                if (validateEmpty(token)) {
                    props.getTOC(currentPage, limitPage, token)
                }
                break;
            case TOC_UPDATE_STATUS_WARNING:
                setUpdateLoading(false)
                MySwal.fire(
                    'Perhatian',
                    'Masih ada TOC yang masih aktif untuk sasaran user ini',
                    'warning'
                )
                break;
            case TOC_UPDATE_STATUS_FAILED:
                setUpdateLoading(false)
                MySwal.fire(
                    'Perhatian',
                    'Gagal memperbarui TOC',
                    'error'
                )
                break;

            case TOC_REMOVE_LOADING:
                setUpdateLoading(true)
                break;
            case TOC_REMOVE_SUCCESSFUL:
                setUpdateLoading(false)
                if (props.toc.payload.data.rows_data.docs.length === 1) {
                    if (props.toc.payload.data.previous_page_number !== null) {
                        setCurrentPage(props.toc.payload.data.previous_page_number)
                    }
                }
                if (validateEmpty(token)) {
                    props.getTOC(currentPage, limitPage, token)
                }
                MySwal.fire(
                    'Perhatian',
                    'Berhasil menghapus data toc',
                    'success'
                )
                break;
            case TOC_REMOVE_NOT_FOUND:
                MySwal.hideLoading()
                MySwal.fire(
                    'Perhatian',
                    'Data toc tidak ditemukan',
                    'warning'
                )
                break;
            case TOC_REMOVE_FAILED:
                MySwal.hideLoading()
                MySwal.fire(
                    'Perhatian',
                    'Gagal menghapus data toc',
                    'error'
                )
                break;
        }
    }, [props.toc.payload_crud])

    function buildTocType(data) {
        if (data.toc_type === 'terms_of_condition')
            return 'Terms of Condition'
        else if (data.toc_type === 'terms_of_service')
            return 'Terms of Service'
        else if (data.toc_type === 'privacy_policy')
            return 'Privacy Policy'
        else
            return '-'
    }

    function buildTocActiveButton(item) {
        console.log(`Item : ${JSON.stringify(item , null , 2)}`)
        if (item.can_select === '1') {
            if (item.is_active === '1') {
                return (
                    <button style={{
                        backgroundColor: updateLoading ? MaterialGray1 : MaterialRed3,
                        border: 'none'
                    }} onClick={() => {
                        props.updateTocStatus(
                            item.id,
                            item.toc_type,
                            item.toc_user_type,
                            '0',
                            token
                        )
                    }} className="btn btn-sm btn-danger">
                        Nonaktifkan
                    </button>
                )
            } else {
                return (
                    <button style={{
                        backgroundColor: updateLoading ? MaterialGray1 : MaterialGreen2,
                        border: 'none'
                    }} onClick={() => {
                        props.updateTocStatus(
                            item.id,
                            item.toc_type,
                            item.toc_user_type,
                            '1',
                            token
                        )
                    }} className="btn btn-sm btn-success">
                        Aktifkan
                    </button>
                )
            }
        } else {
            return (
                <p style={{
                    background: MaterialGray1, display: 'inline-block', paddingLeft: '10px', paddingRight: '10px', borderRadius: '8px', fontSize: '13px',
                    paddingTop: '5px', paddingBottom: '5px', color: 'white'
                }}>Tidak bisa diaktifkan</p>
            )
        }
    }

    function buildTocPage() {
        if (props.toc.payload.type === TOC_LIST_LOADING) {
            return (
                <>
                    <div style={{ marginTop: '15px' }}>
                        <Row>
                            <Col lg="12">
                                <Skeleton style={{ height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12">
                                <Skeleton style={{ marginTop: '25px', height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12">
                                <Skeleton style={{ marginTop: '25px', height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12">
                                <Skeleton style={{ marginTop: '25px', height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12">
                                <Skeleton style={{ marginTop: '25px', height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12">
                                <Skeleton style={{ marginTop: '25px', height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                            </Col>
                        </Row>
                    </div>
                </>
            )
        } else if (props.toc.payload.type === TOC_LIST_SUCCESSFUL) {
            if (props.toc.payload.data !== undefined) {
                return (
                    <>
                        <Row>
                            <Col xl="12">
                                <Row style={{ marginTop: '15px' }}>
                                    <Col xl="12">
                                        <Table className="table-bordered" responsive>
                                            <thead className="thead-dark">
                                                <tr>
                                                    <th style={{ color: 'white' }} scope="col">Judul</th>
                                                    <th style={{ color: 'white' }} scope="col">Tipe TOC</th>
                                                    <th style={{ color: 'white' }} scope="col">Sasaran TOC</th>
                                                    <th style={{ color: 'white' }} scope="col">Aktif ?</th>
                                                    <th style={{ color: 'white' }} scope="col">Aksi Pengaktifkan</th>
                                                    <th colSpan={2} style={{ color: 'white' }}>Aksi</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    props.toc.payload.data.rows_data.docs.map((data, index) => {
                                                        return (
                                                            <tr key={data.id}>
                                                                <td>{data.title}</td>
                                                                <td>{buildTocType(data)}</td>
                                                                <td>{data.toc_user_type != null ? data.toc_user_type.charAt(0).toUpperCase() + data.toc_user_type.slice(1) : ''}</td>
                                                                <td>{data.is_active != null ? data.is_active === '1' ? 'Aktif' : 'Tidak Aktif' : '-'}</td>
                                                                <td>{buildTocActiveButton(data)}</td>
                                                                <td style={{ float: 'left', marginRight: '5px' }}>
                                                                    <button onClick={() => {
                                                                        const tocId = data.id
                                                                        history.push({
                                                                            pathname: '/admin/toc_update',
                                                                            state: {
                                                                                toc_id: tocId,
                                                                                toc_type : data.toc_type,
                                                                                toc_user_type : data.toc_user_type
                                                                            }
                                                                        })
                                                                        console.log('ID : ' + tocId)
                                                                    }} className="btn btn-sm btn-primary"><i className="fas fa-eye"></i> Lihat</button>
                                                                    <button onClick={() => {
                                                                        MySwal.fire({
                                                                            title: 'Perhatian',
                                                                            text: 'Yakin ingin menghapus TOC ini ? TOC tidak dapat dikembalikan',
                                                                            showCancelButton: true,
                                                                            confirmButtonText: 'Ya',
                                                                            confirmButtonColor: MaterialBlue3,
                                                                            cancelButtonColor: MaterialGray1,
                                                                            cancelButtonText: 'Batal'
                                                                        }).then((result) => {
                                                                            if (result.isConfirmed) {
                                                                                if (validateEmpty(token))
                                                                                    props.removeTOC(data.id, token)
                                                                            }
                                                                        })
                                                                    }} className="btn btn-sm btn-danger"><i className="fas fa-remove"></i> Hapus</button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                                <Row>
                                    {
                                        props.toc.payload.data.count_total > limitPage ?
                                            <Card className="bg-default-shadow" style={{ width: 'max-content', paddingLeft: '20px', marginRight: '0', marginTop: '10px', paddingTop: '15px', marginLeft: 'auto' }}>
                                                <Pagination
                                                    className="pagination-bar"
                                                    currentPage={currentPage}
                                                    totalCount={props.toc.payload.data.count_total}
                                                    pageSize={limitPage}
                                                    onPageChange={page => {
                                                        setCurrentPage(page)
                                                    }} />
                                            </Card>
                                            : <div></div>
                                    }
                                </Row>
                            </Col>
                        </Row>
                    </>
                )
            }
        } else if (props.toc.payload.type === TOC_LIST_NOT_FOUND) {
            return (
                <NotFoundPage imageSource={IcNotFound} imageText={props.toc.payload.message} />
            )
        } else if (props.toc.payload.type === TOC_LIST_FAILED) {
            return (
                <ErrorPage imageSource={IcError} imageText={props.toc.payload.message} />
            )
        }
    }
    return (
        <>
            <div className="main-content" ref={mainContent}>
                <div className="header bg-gradient-info pb-8 pt-5 pt-lg-7 d-flex align-items-center">
                    <span className="mask bg-gradient-default opacity-8" />
                </div>
                <Container className="mt--7" fluid>
                    <Card className="bg-secondary shadow">
                        {
                            updateLoading ?
                                <div className="progress-bar">
                                    <div className="progress-bar-value"></div>
                                </div> : <div></div>
                        }
                        <CardBody>
                            {
                                props.toc.payload.type !== TOC_LIST_LOADING && props.toc.payload.type !== TOC_LIST_FAILED ?
                                    <Row>
                                        <Col xl="10"></Col>
                                        <Col xl="2">
                                            <button onClick={() => {
                                                history.push('/admin/toc_create')
                                            }} className="btn btn-sm btn-primary">+ Tambah TOC</button>
                                        </Col>
                                    </Row> : <div></div>
                            }
                            {buildTocPage()}
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </>
    )
}

TocPage.propTypes = {
    toc: PropTypes.object.isRequired,
    getTOC: PropTypes.func.isRequired,
    updateTocStatus: PropTypes.func.isRequired,
    removeTOC: PropTypes.func.isRequired,
    setTOCEmpty: PropTypes.func.isRequired,
    resetCrud: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    toc: state.toc
})

export default connect(mapStateToProps, { getTOC, updateTocStatus, removeTOC, resetCrud, setTOCEmpty })(TocPage)