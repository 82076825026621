import {
    GUIDE_LIST_LOADING,
    GUIDE_LIST_SUCCESSFUL,
    GUIDE_LIST_NOT_FOUND,
    GUIDE_LIST_FAILED,

    GUIDE_DETAIL_LOADING,
    GUIDE_DETAIL_SUCCESSFUL,
    GUIDE_DETAIL_NOT_FOUND,
    GUIDE_DETAIL_FAILED,

    GUIDE_CREATE_LOADING,
    GUIDE_CREATE_SUCCESSFUL,
    GUIDE_CREATE_FAILED,

    GUIDE_UPDATE_LOADING,
    GUIDE_UPDATE_SUCCESSFUL,
    GUIDE_UPDATE_NOT_FOUND,
    GUIDE_UPDATE_FAILED,

    GUIDE_REMOVE_LOADING,
    GUIDE_REMOVE_SUCCESSFUL,
    GUIDE_REMOVE_NOT_FOUND,
    GUIDE_REMOVE_FAILED,
    
    GUIDE_RESET_CRUD
} from 'actions/Guide/Types';

const initState = {
    payload : {
        type : null,
        message : ''
    },
    payload_crud : {
        type : null,
        message : ''
    }
}
export default function(state = initState , action){
    switch(action.type){
        case GUIDE_LIST_LOADING:
            return {
                ...state,
                type: action.type,
                payload: {
                    type: action.type,
                    message: action.message
                }
            }
        case GUIDE_LIST_SUCCESSFUL:
            return {
                ...state,
                type: action.type,
                payload: {
                    type: action.type,
                    data: action.data
                }
            }
        case GUIDE_LIST_NOT_FOUND:
            return {
                ...state,
                type: action.type,
                payload: {
                    type: action.type,
                    message: action.message
                }
            }
        case GUIDE_LIST_FAILED:
            return {
                ...state,
                type: action.type,
                payload: {
                    type: action.type,
                    message: action.message
                }
            }
        case GUIDE_DETAIL_LOADING:
            return {
                ...state,
                type: action.type,
                payload: {
                    type: action.type,
                    message: action.message
                }
            }
        case GUIDE_DETAIL_SUCCESSFUL:
            return {
                ...state,
                type: action.type,
                payload: {
                    type: action.type,
                    data: action.data
                }
            }
        case GUIDE_DETAIL_NOT_FOUND:
            return {
                ...state,
                type: action.type,
                payload: {
                    type: action.type,
                    message: action.message
                }
            }
        case GUIDE_DETAIL_FAILED:
            return {
                ...state,
                type: action.type,
                payload: {
                    type: action.type,
                    message: action.message
                }
            }

        case GUIDE_CREATE_LOADING:
            return {
                ...state,
                payload_crud: {
                    type : action.type,
                    message : action.message
                }
            }
        case GUIDE_CREATE_SUCCESSFUL:
            return {
                ...state,
                payload_crud: {
                    type : action.type,
                    message : action.message
                }
            }
        case GUIDE_CREATE_FAILED:
            return {
                ...state,
                payload_crud: {
                    type : action.type,
                    message : action.message
                }
            }

        case GUIDE_UPDATE_LOADING:
            return {
                ...state,
                payload_crud: {
                    type : action.type,
                    message : action.message
                }
            }
        case GUIDE_UPDATE_SUCCESSFUL:
            return {
                ...state,
                payload_crud: {
                    type : action.type,
                    message : action.message
                }
            }
        case GUIDE_UPDATE_NOT_FOUND:
            return {
                ...state,
                payload_crud: {
                    type : action.type,
                    message : action.message
                }
            }
        case GUIDE_UPDATE_FAILED:
            return {
                ...state,
                payload_crud: {
                    type : action.type,
                    message : action.message
                }
            }

        case GUIDE_REMOVE_LOADING:
            return {
                ...state,
                payload_crud: {
                    type : action.type,
                    message : action.message
                }
            }
        case GUIDE_REMOVE_SUCCESSFUL:
            return {
                ...state,
                payload_crud: {
                    type : action.type,
                    message : action.message
                }
            }
        case GUIDE_REMOVE_NOT_FOUND:
            return {
                ...state,
                payload_crud: {
                    type : action.type,
                    message : action.message
                }
            }
        case GUIDE_REMOVE_FAILED:
            return {
                ...state,
                payload_crud: {
                    type : action.type,
                    message : action.message
                }
            }
        case GUIDE_RESET_CRUD:
            return {
                ...state,
                payload_crud : {
                    type : null,
                    message : ''
                }
            }
        default:
            return state
    }
}