export const TOOLS_LIST_LOADING = 'TOOLS_LIST_LOADING';
export const TOOLS_LIST_SUCCESSFUL = 'TOOLS_LIST_SUCCESSFUL';
export const TOOLS_LIST_FAILED = 'TOOLS_LIST_FAILED';
export const TOOLS_LIST_NOT_FOUND = 'TOOLS_LIST_NOT_FOUND'

export const TOOLS_MITRA_LOADING = 'TOOLS_LIST_LOADING';
export const TOOLS_MITRA_SUCCESSFUL = 'TOOLS_LIST_SUCCESSFUL';
export const TOOLS_MITRA_FAILED = 'TOOLS_LIST_FAILED';
export const TOOLS_MITRA_NOT_FOUND = 'TOOLS_LIST_NOT_FOUND'

export const TOOLS_DETAIL_LOADING = 'TOOLS_DETAIL_LOADING';
export const TOOLS_DETAIL_SUCCESSFUL = 'TOOLS_DETAIL_SUCCESSFUL';
export const TOOLS_DETAIL_NOT_FOUND = 'TOOLS_DETAIL_NOT_FOUND';
export const TOOLS_DETAIL_FAILED = 'TOOLS_DETAIL_FAILED';

export const TOOLS_CREATE_LOADING = 'TOOLS_CREATE_LOADING';
export const TOOLS_CREATE_SUCCESSFUL = 'TOOLS_CREATE_SUCCESSFUL';
export const TOOLS_CREATE_FAILED = 'TOOLS_CREATE_FAILED';

export const TOOLS_UPDATE_LOADING = 'TOOLS_UPDATE_LOADING'
export const TOOLS_UPDATE_SUCCESSFUL = 'TOOLS_UPDATE_SUCCESSFUL';
export const TOOLS_UPDATE_NOT_FOUND = 'TOOLS_UPDATE_NOT_FOUND';
export const TOOLS_UPDATE_FAILED = 'TOOLS_UPDATE_FAILED';

export const TOOLS_REMOVE_LOADING = 'TOOLS_REMOVE_LOADING';
export const TOOLS_REMOVE_SUCCESSFUL = 'TOOLS_REMOVE_SUCCESSFUL';
export const TOOLS_REMOVE_NOT_FOUND = 'TOOLS_REMOVE_NOT_FOUND';
export const TOOLS_REMOVE_FAILED = 'TOOLS_REMOVE_FAILED';

export const SUBMIT_TOOLS_LOADING = 'SUBMIT_TOOLS_LOADING';
export const SUBMIT_TOOLS_SUCCESSFUL = 'SUBMIT_TOOLS_SUCCESSFUL';
export const SUBMIT_TOOLS_FAILED = 'SUBMIT_TOOLS_FAILED';

export const TOOLS_RESET_CRUD = 'TOOLS_RESET_CRUD'