export const DASHBOARD_LOADING = 'DASHBOARD_LOADING';
export const DASHBOARD_SUCCESSFUL = 'DASHBOARD_SUCCESSFUL';
export const DASHBOARD_FAILED = 'DASHBOARD_FAILED';

export const ORDER_LIST_LOADING = 'ORDER_LIST_LOADING';
export const ORDER_LIST_SUCCESSFUL = 'ORDER_LIST_SUCCESSFUL';
export const ORDER_LIST_NOT_FOUND = 'ORDER_LIST_NOT_FOUND';
export const ORDER_LIST_FAILED = 'ORDER_LIST_FAILED';

export const SELECTED_MITRA_LOADING = 'SELECTED_MITRA_LOADING';
export const SELECTED_MITRA_SUCCESSFUL = 'SELECTED_MITRA_SUCCESSFUL';
export const SELECTED_MITRA_NOT_FOUND = 'SELECTED_MITRA_NOT_FOUND';
export const SELECTED_MITRA_ORDER_EXPIRED = 'SELECTED_MITRA_ORDER_EXPIRED';
export const SELECTED_MITRA_FAILED = 'SELECTED_MITRA_FAILED';

export const ORDER_SELECTED_MITRA_LOADING = 'ORDER_SELECTED_MITRA_LOADING';
export const ORDER_SELECTED_MITRA_SUCCESSFUL = 'ORDER_SELECTED_MITRA_SUCCESSFUL';
export const ORDER_SELECTED_MITRA_NOT_FOUND = 'ORDER_SELECTED_MITRA_NOT_FOUND';
export const ORDER_SELECTED_MITRA_FAILED = 'ORDER_SELECTED_MITRA_FAILED'

export const ORDER_DETAIL_LOADING = 'ORDER_DETAIL_LOADING';
export const ORDER_DETAIL_SUCCESSFUL = 'ORDER_DETAIL_SUCCESSFUL';
export const ORDER_DETAIL_NOT_FOUND = 'ORDER_DETAIL_NOT_FOUND';
export const ORDER_DETAIL_FAILED = 'ORDER_DETAIL_FAILED';

export const ORDER_CANCEL_LOADING = 'ORDER_CANCEL_LOADING';
export const ORDER_CANCEL_SUCCESSFUL = 'ORDER_CANCEL_SUCCESSFUL';
export const ORDER_CANCEL_FAILED = 'ORDER_CANCEL_FAILED';