export const COMPLAIN_LIST_LOADING = 'COMPLAIN_LIST_LOADING';
export const COMPLAIN_LIST_SUCCESSFUL = 'COMPLAIN_LIST_SUCCESSFUL';
export const COMPLAIN_LIST_FAILED = 'COMPLAIN_LIST_FAILED';
export const COMPLAIN_LIST_NOT_FOUND = 'COMPLAIN_LIST_NOT_FOUND'

export const COMPLAIN_DETAIL_LOADING = 'COMPLAIN_DETAIL_LOADING';
export const COMPLAIN_DETAIL_SUCCESSFUL = 'COMPLAIN_DETAIL_SUCCESSFUL';
export const COMPLAIN_DETAIL_NOT_FOUND = 'COMPLAIN_DETAIL_NOT_FOUND';
export const COMPLAIN_DETAIL_FAILED = 'COMPLAIN_DETAIL_FAILED';

export const COMPLAIN_UPDATE_LOADING = 'COMPLAIN_UPDATE_LOADING'
export const COMPLAIN_UPDATE_SUCCESSFUL = 'COMPLAIN_UPDATE_SUCCESSFUL';
export const COMPLAIN_UPDATE_NOT_FOUND = 'COMPLAIN_UPDATE_NOT_FOUND';
export const COMPLAIN_UPDATE_FAILED = 'COMPLAIN_UPDATE_FAILED';

export const COMPLAIN_REMOVE_LOADING = 'COMPLAIN_REMOVE_LOADING';
export const COMPLAIN_REMOVE_SUCCESSFUL = 'COMPLAIN_REMOVE_SUCCESSFUL';
export const COMPLAIN_REMOVE_NOT_FOUND = 'COMPLAIN_REMOVE_NOT_FOUND';
export const COMPLAIN_REMOVE_FAILED = 'COMPLAIN_REMOVE_FAILED';

export const COMPLAIN_RESET_CRUD = 'COMPLAIN_RESET_CRUD'