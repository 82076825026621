import { connect } from "react-redux";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody
} from 'reactstrap';
import PropTypes from 'prop-types';
import Skeleton from "react-loading-skeleton";
import { getDashboardOrder } from "actions/Orders/OrderService";
import React, { useEffect, useReducer, useState } from 'react';
import { useHistory } from "react-router-dom";
import { DASHBOARD_LOADING } from "actions/Dashboard/Types";
import { DASHBOARD_SUCCESSFUL } from "actions/Dashboard/Types";
import { DASHBOARD_FAILED } from "actions/Dashboard/Types";
import { MaterialGray1 } from "components/Utilities/ResourceColors";
import { ReactComponent as IcRunningIcon } from '../../../assets/img/IcRunningIcon.svg';
import { ReactComponent as IcComingSoon } from '../../../assets/img/IcComingSoon.svg';
import { ReactComponent as IcRepeatIcon } from '../../../assets/img/IcRepeatIcon.svg';
import { ReactComponent as IcDoneIcon } from '../../../assets/img/IcDoneIcon.svg';
import { ReactComponent as IcFailedIcon } from '../../../assets/img/IcFailedIcon.svg';
import ErrorPage from "components/ErrorComponent/ErrorPage";
import IcError from 'assets/img/ic_error.svg';
import AdminNavbar from "components/Navbars/AdminNavbar";
//import { onMessageListener } from "../../../firebase";
import { MaterialBlue1 } from "components/Utilities/ResourceColors";
import { MESSAGE_SOCKET_ADMIN } from "actions/Socket/Types";
import { NOTIFICATION_ORDER_RUNNING } from "actions/Socket/Types";
import { NOTIFICATION_ORDER_FINISH } from "actions/Socket/Types";
import { NOTIFICATION_ORDER_WAITING_FOR_SELECTED } from "actions/Socket/Types";
import { NOTIFICATION_ORDER_CANCELED } from "actions/Socket/Types";

const OrderPage = (props) => {
    const [payload, setPayload] = useState({})
    const [currentPage, setCurrentPage] = useState(1)
    const history = useHistory()
    const [runningHover, setRunningHover] = useState(false)
    const [waitHover, setWaitHover] = useState(false)
    const [doneHover, setDoneHover] = useState(false)
    const [repeatHover, setRepeatHover] = useState(false)
    const [cancelHover, setCancelHover] = useState(false)
    const [findingHover, setFindingHover] = useState(false)
    const [runningCount, setRunningCount] = useState(0)
    const [waitCount, setWaitCount] = useState(0)
    const [doneCount, setDoneCount] = useState(0)
    const [repeatCount, setRepeatCount] = useState(0)
    const [cancelCount, setCancelCount] = useState(0)
    const [findingCount, setFindingCount] = useState(0)
    const mainContent = React.useRef(null)
    const [token , setToken] = useState('')
    useEffect(() => {
        setToken(localStorage.getItem('token'))
        if(token !== ''){
            props.getDashboardOrder(token)
        }
    }, [token])
    useEffect(() => {
        if (props.orders.payload.data != null) {
            console.log(`data : ${JSON.stringify(props.orders.payload.data , null , 2)}`)
            setRunningCount(props.orders.payload.data.order_running_count)
            setWaitCount(props.orders.payload.data.order_wait_schedule_count)
            setDoneCount(props.orders.payload.data.order_done_count)
            setRepeatCount(props.orders.payload.data.order_repeat_count)
            setCancelCount(props.orders.payload.data.order_canceled_count)
            setFindingCount(props.orders.payload.data.order_waiting_selected_mitra_count)
        }
    }, [props])
    // onMessageListener().then(payload => {
    //     console.log(`Payload Notification : ${JSON.stringify(payload , null , 2)}`)
    //     if (payload.data.notification_type === 'MITRA_ORDER_NOTIFICATION') {
    //         if (payload.data.order_status === 'OTW') {
    //             setRunningCount(runningCount + 1)
    //         } else if (payload.data.order_status === 'FINISH') {
    //             if (runningCount >= 0) {
    //                 setRunningCount(runningCount - 1)
    //             }
    //         }
    //     }
    // }).catch(err => console.log(`Failed : ${err}`))
    useEffect(() => {
        if(props.orders.payload.data !== undefined && props.orders.payload.data !== null){
            if(props.socket !== null && props.socket.socket !== null){
                props.socket.socket.on(MESSAGE_SOCKET_ADMIN , (payload) => {
                    console.log(`Message Socket Payload : ${JSON.stringify(payload , null , 2)}`)
                    switch(payload.notification_type){
                        case NOTIFICATION_ORDER_RUNNING:
                            if(payload.order_running_count !== undefined && payload.order_running_count !== null)
                                setRunningCount(payload.order_running_count)
                            if(payload.order_waiting_count !== undefined && payload.order_waiting_count !== null)
                                setFindingCount(payload.order_waiting_count)
                            break;
                        case NOTIFICATION_ORDER_FINISH:
                            if(payload.order_running_count !== undefined && payload.order_running_count !== null)
                                setRunningCount(payload.order_running_count)
                            if(payload.order_finish_count !== undefined && payload.order_finish_count !== null)
                                setDoneCount(payload.order_finish_count)
                            break;
                        case NOTIFICATION_ORDER_WAITING_FOR_SELECTED:
                            if(payload.order_waiting_count !== undefined && payload.order_waiting_count !== null)
                                setFindingCount(payload.order_waiting_count)
                            break;
                        case NOTIFICATION_ORDER_CANCELED:
                            if(payload.order_canceled_count !== undefined && payload.order_canceled_count !== null)
                                setCancelCount(payload.order_canceled_count)
                            if(payload.order_waiting_count !== undefined && payload.order_waiting_count !== null)
                                setFindingCount(payload.order_waiting_count)
                            if(payload.order_running_count !== undefined && payload.order_running_count !== null)
                                setRunningCount(payload.order_running_count)
                            break;
                    }
                })
            }
        }
    } , [props.orders])
    function buildOrderPage() {
        switch (props.orders.payload.type) {
            case DASHBOARD_LOADING:
                return (
                    <Container className="mt--7" fluid>
                        <Row>
                            <Col className="order-xl-1" xl="12">
                                <Card style={{ paddingLeft: '20px', paddingRight: '20px', paddingTop: '20px', paddingBottom: '20px', height: "100%" }} className="bg-secondary shadow">
                                    <Row className="justify-content-center">
                                        <Col md="4">
                                            <Skeleton style={{ width: '100%', height: '200px', color: MaterialGray1, backgroundColor: MaterialGray1, borderRadius: '10px' }} className="heading"></Skeleton>
                                        </Col>
                                        <Col md="4">
                                            <Skeleton style={{ width: '100%', height: '200px', color: MaterialGray1, backgroundColor: MaterialGray1, borderRadius: '10px' }} className="heading"></Skeleton>
                                        </Col>
                                        <Col md="4">
                                            <Skeleton style={{ width: '100%', height: '200px', color: MaterialGray1, backgroundColor: MaterialGray1, borderRadius: '10px' }} className="heading"></Skeleton>
                                        </Col>
                                    </Row>
                                    <Row style={{ marginTop: '20px', marginBottom: '12px' }} className="justify-content-center">
                                        <Col md="4">
                                            <Skeleton style={{ width: '100%', height: '200px', color: MaterialGray1, backgroundColor: MaterialGray1, borderRadius: '10px' }} className="heading"></Skeleton>
                                        </Col>
                                        <Col md="4">
                                            <Skeleton style={{ width: '100%', height: '200px', color: MaterialGray1, backgroundColor: MaterialGray1, borderRadius: '10px' }} className="heading"></Skeleton>
                                        </Col>
                                        <Col md="4">
                                            <Skeleton style={{ width: '100%', height: '200px', color: MaterialGray1, backgroundColor: MaterialGray1, borderRadius: '10px' }} className="heading"></Skeleton>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                )
            case DASHBOARD_SUCCESSFUL:
                return (
                    <Container className="mt--7" fluid>
                        <Card style={{ paddingLeft: '20px', paddingRight: '20px', paddingTop: '20px', paddingBottom: '20px', height: "100%" }} className="bg-secondary shadow">
                            <Row className="justify-content-center">
                                <Col style={{ textAlign: 'center' }} md="4">
                                    <Card onClick={() => {
                                        history.push({
                                            pathname: '/admin/order_list_page',
                                            state: {
                                                orderTypeText: 'RUNNING'
                                            }
                                        })
                                    }} style={{ cursor: runningHover ? 'pointer' : 'context-menu', transition: 'transform .2s', transform: runningHover ? 'scale(1.05)' : 'scale(1)' }} onMouseOver={() => setRunningHover(true)} onMouseOut={() => setRunningHover(false)}>
                                        <CardBody>
                                            <IcRunningIcon style={{ width: '60px', height: '60px' }} />
                                            <p style={{ marginTop: '15px' }}>Order Berjalan</p>
                                            <p>{runningCount}</p>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col style={{ textAlign: 'center' }} md="4">
                                    <Card onClick={() => {
                                        history.push({
                                            pathname: '/admin/order_list_page',
                                            state: {
                                                orderTypeText: 'WAIT_SCHEDULE'
                                            }
                                        })
                                    }} style={{ cursor: waitHover ? 'pointer' : 'context-menu', transition: 'transform .2s', transform: waitHover ? 'scale(1.05)' : 'scale(1)' }} onMouseOver={() => setWaitHover(true)} onMouseOut={() => setWaitHover(false)}>
                                        <CardBody>
                                            <IcComingSoon style={{ width: '60px', height: '60px' }} />
                                            <p style={{ marginTop: '15px' }}>Order Terjadwal</p>
                                            <p>{waitCount}</p>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col style={{ textAlign: 'center' }} md="4">
                                    <Card onClick={() => {
                                        history.push({
                                            pathname: '/admin/order_list_page',
                                            state: {
                                                orderTypeText: 'FINISH'
                                            }
                                        })
                                    }} style={{ cursor: doneHover ? 'pointer' : 'context-menu', transition: 'transform .2s', transform: doneHover ? 'scale(1.05)' : 'scale(1)' }} onMouseOver={() => setDoneHover(true)} onMouseOut={() => setDoneHover(false)}>
                                        <CardBody>
                                            <IcDoneIcon style={{ width: '60px', height: '60px' }} />
                                            <p style={{ marginTop: '15px' }}>Order Terselesaikan</p>
                                            <p>{doneCount}</p>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: '20px' }} className="justify-content-center">
                                <Col style={{ textAlign: 'center' }} md="4">
                                    <Card onClick={() => {
                                        history.push({
                                            pathname: '/admin/order_list_page',
                                            state: {
                                                orderTypeText: 'REPEAT'
                                            }
                                        })
                                    }} style={{ cursor: repeatHover ? 'pointer' : 'context-menu', transition: 'transform .2s', transform: repeatHover ? 'scale(1.05)' : 'scale(1)' }} onMouseOver={() => setRepeatHover(true)} onMouseOut={() => setRepeatHover(false)}>
                                        <CardBody>
                                            <IcRepeatIcon style={{ width: '60px', height: '60px' }} />
                                            <p style={{ marginTop: '15px' }}>Order Berulang</p>
                                            <p>{repeatCount}</p>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col style={{ textAlign: 'center' }} md="4">
                                    <Card onClick={() => {
                                        history.push({
                                            pathname: '/admin/order_list_page',
                                            state: {
                                                orderTypeText: 'CANCELED'
                                            }
                                        })
                                    }} style={{ cursor: cancelHover ? 'pointer' : 'context-menu', transition: 'transform .2s', transform: cancelHover ? 'scale(1.05)' : 'scale(1)' }} onMouseOver={() => setCancelHover(true)} onMouseOut={() => setCancelHover(false)}>
                                        <CardBody>
                                            <IcFailedIcon style={{ width: '60px', height: '60px' }} />
                                            <p style={{ marginTop: '15px' }}>Order Dibatalkan</p>
                                            <p>{cancelCount}</p>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col style={{ textAlign: 'center' }} md="4">
                                    <Card onClick={() => {
                                        history.push({
                                            pathname: '/admin/order_list_page',
                                            state: {
                                                orderTypeText: 'WAITING_FOR_SELECTED_MITRA'
                                            }
                                        })
                                    }} style={{ cursor: findingHover ? 'pointer' : 'context-menu', transition: 'transform .2s', transform: findingHover ? 'scale(1.05)' : 'scale(1)' }} onMouseOver={() => setFindingHover(true)} onMouseOut={() => setFindingHover(false)}>
                                        <CardBody>
                                            <div style={{ margin: '0 auto', width: '60px', height: '60px', backgroundColor: MaterialBlue1 }} className="blob"></div>
                                            <p style={{ marginTop: '15px' }}>Pencarian order</p>
                                            <p>{findingCount}</p>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Card>
                    </Container>
                )
            case DASHBOARD_FAILED:
                return (
                    <Container className="mt--7" fluid>
                        <Card>
                            <CardBody>
                                <ErrorPage imageSource={IcError} imageText={props.orders.payload.message} />
                            </CardBody>
                        </Card>
                    </Container>
                )
        }
    }
    return (
        <>
            <div className="main-content" ref={mainContent}>
                <div className="header bg-gradient-info pb-8 pt-5 pt-lg-7 d-flex align-items-center">
                    <span className="mask bg-gradient-default opacity-8" />
                </div>
                {buildOrderPage()}
            </div>
        </>
    )
}

OrderPage.propTypes = {
    orders: PropTypes.object.isRequired,
    socket : PropTypes.object.isRequired,
    getDashboardOrder: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    orders: state.orders,
    socket : state.socket
})

export default connect(mapStateToProps, { getDashboardOrder })(OrderPage)