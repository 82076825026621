import { BASE_URL } from 'actions/config';
import axios from 'axios';
import { passingHeaderTokenFormData , passingHeaderToken } from 'components/Utilities/Utility';
import { 
    LAYANAN_LIST_LOADING , 
    LAYANAN_LIST_SUCCESSFUL , 
    LAYANAN_LIST_FAILED , 
    LAYANAN_LIST_NOT_FOUND,
    LAYANAN_CREATE_LOADING,
    LAYANAN_CREATE_SUCCESSFUL,
    LAYANAN_CREATE_EXIST,
    LAYANAN_CREATE_FAILED,
    LAYANAN_UPDATE_LOADING,
    LAYANAN_UPDATE_SUCCESSFUL,
    LAYANAN_UPDATE_NOT_FOUND,
    LAYANAN_UPDATE_FAILED,
    LAYANAN_REMOVE_LOADING,
    LAYANAN_REMOVE_SUCCESSFUL,
    LAYANAN_REMOVE_NOT_FOUND,
    LAYANAN_REMOVE_UNAUTHORIZED,
    LAYANAN_REMOVE_FAILED,
    LAYANAN_DETAIL_LOADING, 
    LAYANAN_DETAIL_SUCCESSFUL, 
    LAYANAN_DETAIL_NOT_FOUND, 
    LAYANAN_DETAIL_FAILED, 
    PARENT_CREATE_LOADING, 
    PARENT_CREATE_SUCCESSFUL, 
    PARENT_CREATE_FAILED, 
    PARENT_DETAIL_LOADING,
    PARENT_DETAIL_SUCCESSFUL, 
    PARENT_DETAIL_NOT_FOUND, PARENT_DETAIL_FAILED, 
    PARENT_UPDATE_LOADING, 
    PARENT_UPDATE_SUCCESSFUL, 
    PARENT_UPDATE_NOT_FOUND, 
    PARENT_UPDATE_FAILED , 
    PARENT_REMOVE_LOADING,
    PARENT_REMOVE_SUCCESSFUL,
    PARENT_REMOVE_UNAUTHORIZED,
    PARENT_REMOVE_NOT_FOUND,
    PARENT_REMOVE_FAILED,

    LAYANAN_RESET_CRUD
} from './Types';

export const getLayananList = (page , limit , token) => async dispatch => {
    try {
        dispatch({
            type : LAYANAN_LIST_LOADING,
            payload : {
                type : LAYANAN_LIST_LOADING,
                message : 'Memuat Layanan'
            }
        })
        const response = await axios.get(`${BASE_URL}/api/layanan_service/index?page=${page}&limit=${limit}` , passingHeaderToken(token))
        setTimeout(() => {
            dispatch({
                type : LAYANAN_LIST_SUCCESSFUL,
                payload : {
                    type : LAYANAN_LIST_SUCCESSFUL,
                    data : response.data
                }
            })
        } , 300)
    } catch (err) {
        console.log(err)
        if(err.response.status == 404){
            dispatch({
                type : LAYANAN_LIST_NOT_FOUND,
                payload : {
                    type : LAYANAN_LIST_NOT_FOUND,
                    message : 'Layanan Tidak Ada'
                }
            })
        } else {
            dispatch({
                type : LAYANAN_LIST_FAILED,
                payload : {
                    type : LAYANAN_LIST_FAILED,
                    message : 'Gagal Memuat Layanan'
                }
            })
        }
    }
}

export const getLayananDetail = (layanan_id , token) => async dispatch => {
    try {
        dispatch({
            type : LAYANAN_DETAIL_LOADING,
            payload : {
                type : LAYANAN_DETAIL_LOADING,
                message : 'Memuat Detail Layanan'
            }
        })
        const response = await axios.get(`${BASE_URL}/api/layanan_service/detail/${layanan_id}` , passingHeaderToken(token))
        console.log(`Res : ${JSON.stringify(response.data , null , 2)}`)
        if(response.data !== undefined && (response.data.parent_services !== undefined && response.data.parent_services.length === 0)){
            setTimeout(() => {
                dispatch({
                    type : LAYANAN_DETAIL_NOT_FOUND,
                    payload : {
                        type : LAYANAN_DETAIL_NOT_FOUND,
                        message : 'Tidak Ada Data Layanan'
                    }
                })
            } , 300)
        } else {
            setTimeout(() => {
                dispatch({
                    type : LAYANAN_DETAIL_SUCCESSFUL,
                    payload : {
                        type : LAYANAN_DETAIL_SUCCESSFUL,
                        data : response.data
                    }
                })
            } , 300)
        }
    } catch (err) {
        if(err.response.status == 404){
            setTimeout(() => {
                dispatch({
                    type : LAYANAN_DETAIL_NOT_FOUND,
                    payload : {
                        type : LAYANAN_DETAIL_NOT_FOUND,
                        message : 'Tidak Ada Data Layanan'
                    }
                })
            } , 300)
        } else {
            setTimeout(() => {
                dispatch({
                    type : LAYANAN_DETAIL_FAILED,
                    payload : {
                        type : LAYANAN_DETAIL_FAILED,
                        message : 'Gagal Memuat Layanan'
                    }
                })
            } , 300)
        }
    }
}

export const createParentService = (form_data , token) => async dispatch => {
    try {
        dispatch({
            type : PARENT_CREATE_LOADING,
            payload : {
                type : PARENT_CREATE_LOADING ,
                message : 'Membuat Paket..'
            }
        })
        const response = await axios.post(`${BASE_URL}/api/parent_service/create` , form_data , passingHeaderTokenFormData(token))
        dispatch({
            type : PARENT_CREATE_SUCCESSFUL,
            payload : {
                type : PARENT_CREATE_SUCCESSFUL,
                message : 'Berhasil Membuat Paket'
            }
        })
    } catch (err) {
        dispatch({
            type : PARENT_CREATE_FAILED,
            payload : {
                type : PARENT_CREATE_FAILED,
                message : 'Gagal Membuat Paket'
            }
        })
    }
}

export const getParentDetail = (parent_id , token) => async dispatch => {
    try {
        dispatch({
            type : PARENT_DETAIL_LOADING,
            message : 'Memuat Data Paket'
        })
        const response = await axios.get(`${BASE_URL}/api/parent_service/detail/${parent_id}` , passingHeaderToken(token))
        setTimeout(() => {
            dispatch({
                type : PARENT_DETAIL_SUCCESSFUL,
                payload : {
                    data : response.data
                }
            })
        } , 250)
    } catch (err) {
        if(err.response.status == 404){
            dispatch({
                type : PARENT_DETAIL_NOT_FOUND,
                message : 'Paket Tidak Ada'
            })
        } else {
            dispatch({
                type : PARENT_DETAIL_FAILED,
                message : 'Gagal Memuat Paket'
            })
        }
    }
}

export const updateParentDetail = (parent_id , data , token) => async dispatch => {
    try {
        dispatch({
            type : PARENT_UPDATE_LOADING,
            message : 'Memperbarui Data Paket...'
        })
        const response = await axios.put(`${BASE_URL}/api/parent_service/update/${parent_id}` , data , passingHeaderToken(token))
        dispatch({
            type : PARENT_UPDATE_SUCCESSFUL,
            message : 'Berhasil Memperbarui Paket'
        })
    } catch (err) {
        if(err.response.status == 404){
            dispatch({
                type : PARENT_UPDATE_NOT_FOUND,
                message : 'Tidak Ada Data Paket'
            })
        } else {
            dispatch({
                type : PARENT_UPDATE_FAILED,
                message : 'Gagal Memperbarui Paket'
            })
        }
    }
}

export const updateParentImageDetail = (parent_id , form_data , token) => async dispatch => {
    try {
        dispatch({
            type : PARENT_UPDATE_LOADING,
            message : 'Memperbarui Data Paket...'
        })
        const response = await axios.put(`${BASE_URL}/api/parent_service/update/image/${parent_id}` , form_data , passingHeaderTokenFormData(token))
        dispatch({
            type : PARENT_UPDATE_SUCCESSFUL,
            message : 'Berhasil Memperbarui Paket'
        })
    } catch (err) {
        if(err.response.status == 404){
            dispatch({
                type : PARENT_UPDATE_NOT_FOUND,
                message : 'Tidak Ada Data Paket'
            })
        } else {
            dispatch({
                type : PARENT_UPDATE_FAILED,
                message : 'Gagal Memperbarui Paket'
            })
        }
    }
}

export const removeParent = (parent_id , password , token) => async dispatch => {
    try {
        dispatch({
            type : PARENT_REMOVE_LOADING,
            payload : {
                type : PARENT_REMOVE_LOADING,
                message : 'Menghapus data layanan...'
            }
        })
        await axios.delete(`${BASE_URL}/api/parent_service/delete/${parent_id}/${password}` , passingHeaderToken(token))
        dispatch({
            type : PARENT_REMOVE_SUCCESSFUL,
            payload : {
                type : PARENT_REMOVE_SUCCESSFUL,
                message : 'Berhasil menghapus data layanan'
            }
        })
    } catch (err) {
        if(err.response !== undefined && err.response.status === 401){
            dispatch({
                type : PARENT_REMOVE_UNAUTHORIZED,
                payload : {
                    type : PARENT_REMOVE_UNAUTHORIZED,
                    message : 'Password salah'
                }
            })
        } else if(err.response !== undefined && err.response.status === 404){
            dispatch({
                type : PARENT_REMOVE_NOT_FOUND,
                payload : {
                    type : PARENT_REMOVE_NOT_FOUND,
                    message : 'Data layanan tidak ada'
                }
            })
        } else {
            dispatch({
                type : PARENT_REMOVE_FAILED,
                payload : {
                    type : PARENT_REMOVE_FAILED,
                    message : 'Gagal menghapus data layanan'
                }
            })
        }
    }
}

export const createLayanan = (form_data , token) => async dispatch => {
    try {
        dispatch({
            type : LAYANAN_CREATE_LOADING,
            payload : {
                type : LAYANAN_CREATE_LOADING,
                message : 'Membuat layanan baru..'
            }
        })
        await axios.post(`${BASE_URL}/api/layanan_service/create` , form_data, passingHeaderTokenFormData(token))
        dispatch({
            type : LAYANAN_CREATE_SUCCESSFUL,
            payload : {
                type : LAYANAN_CREATE_SUCCESSFUL,
                message : 'Berhasil membuat layanan baru'
            }
        })
    } catch (err) {
        if(err.response !== undefined && err.response.code == 409){
            dispatch({
                type : LAYANAN_CREATE_EXIST,
                paylaod : {
                    type : LAYANAN_CREATE_EXIST,
                    message : 'Layanan sudah ada'
                }
            })
        } else {
            dispatch({
                type : LAYANAN_CREATE_FAILED,
                payload : {
                    type : LAYANAN_CREATE_FAILED,
                    message : 'Gagal membuat layanan'
                }
            })
        }
    }
}

export const updateLayananImage = (layanan_id , formData , token) => async dispatch => {
    try {
        dispatch({
            type : LAYANAN_UPDATE_LOADING,
            payload : {
                type : LAYANAN_UPDATE_SUCCESSFUL,
                message : 'Memperbarui layanan'
            }
        })
        await axios.put(`${BASE_URL}/api/layanan_service/update/image/${layanan_id}` , formData , passingHeaderTokenFormData(token))
        dispatch({
            type : LAYANAN_UPDATE_SUCCESSFUL,
            payload : {
                type : LAYANAN_UPDATE_SUCCESSFUL,
                message : 'Berhasil memperbarui layanan'
            }
        })
    } catch (err){
        if(err.response !== undefined && err.response.status == 404){
            dispatch({
                type : LAYANAN_UPDATE_NOT_FOUND,
                payload : {
                    type : LAYANAN_UPDATE_NOT_FOUND,
                    message : 'Layanan tidak ada'
                }
            })
        } else {
            dispatch({
                type : LAYANAN_UPDATE_FAILED,
                payload : {
                    type : LAYANAN_UPDATE_FAILED,
                    message : 'Gagal memperbarui layanan'
                }
            })
        }
    }
}

export const updateLayanan = (layanan_id , payload , token) => async dispatch => {
    try {
        dispatch({
            type : LAYANAN_UPDATE_LOADING,
            payload : {
                type : LAYANAN_UPDATE_SUCCESSFUL,
                message : 'Memperbarui layanan'
            }
        })
        await axios.put(`${BASE_URL}/api/layanan_service/update/${layanan_id}` , payload , passingHeaderToken(token))
        dispatch({
            type : LAYANAN_UPDATE_SUCCESSFUL,
            payload : {
                type : LAYANAN_UPDATE_SUCCESSFUL,
                message : 'Berhasil memperbarui layanan'
            }
        })
    } catch (err){
        if(err.response !== undefined && err.response.status == 404){
            dispatch({
                type : LAYANAN_UPDATE_NOT_FOUND,
                payload : {
                    type : LAYANAN_UPDATE_NOT_FOUND,
                    message : 'Layanan tidak ada'
                }
            })
        } else {
            dispatch({
                type : LAYANAN_UPDATE_FAILED,
                payload : {
                    type : LAYANAN_UPDATE_FAILED,
                    message : 'Gagal memperbarui layanan'
                }
            })
        }
    }
}

export const removeLayanan = (layanan_id , password , token) => async dispatch => {
    try {
        dispatch({
            type : LAYANAN_REMOVE_LOADING,
            payload : {
                type : LAYANAN_REMOVE_LOADING,
                message : 'Menghapus layanan'
            }
        })
        await axios.delete(`${BASE_URL}/api/layanan_service/remove/${layanan_id}/${password}` , passingHeaderToken(token))
        dispatch({
            type : LAYANAN_REMOVE_SUCCESSFUL,
            payload : {
                type : LAYANAN_REMOVE_SUCCESSFUL,
                message : 'Berhasil menghapus layanan'
            }
        })
    } catch (err){
        console.log(err)
        if(err.response !== undefined && err.response.status === 404){
            dispatch({
                type : LAYANAN_REMOVE_NOT_FOUND,
                payload : {
                    type : LAYANAN_REMOVE_NOT_FOUND,
                    message : 'Layanan tidak ada'
                }
            })
        } else if(err.response !== undefined && err.response.status === 401){
            dispatch({
                type : LAYANAN_REMOVE_UNAUTHORIZED,
                payload : {
                    type : LAYANAN_REMOVE_UNAUTHORIZED,
                    message : 'Kata Sandi salah'
                }
            })
        } else {
            dispatch({
                type : LAYANAN_REMOVE_FAILED,
                payload : {
                    type : LAYANAN_REMOVE_FAILED,
                    message : 'Gagal menghapus layanan'
                }
            })
        }
    }
}

export const resetCrud = () => async dispatch => {
    dispatch({
        type : LAYANAN_RESET_CRUD,
        payload : {
            type : LAYANAN_RESET_CRUD
        }
    })
}