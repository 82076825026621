export const TOC_LIST_LOADING = 'TOC_LIST_LOADING';
export const TOC_LIST_SUCCESSFUL = 'TOC_LIST_SUCCESSFUL';
export const TOC_LIST_FAILED = 'TOC_LIST_FAILED';
export const TOC_LIST_NOT_FOUND = 'TOC_LIST_NOT_FOUND'

export const TOC_DETAIL_LOADING = 'TOC_DETAIL_LOADING';
export const TOC_DETAIL_SUCCESSFUL = 'TOC_DETAIL_SUCCESSFUL';
export const TOC_DETAIL_NOT_FOUND = 'TOC_DETAIL_NOT_FOUND';
export const TOC_DETAIL_FAILED = 'TOC_DETAIL_FAILED';

export const TOC_CREATE_LOADING = 'TOC_CREATE_LOADING';
export const TOC_CREATE_SUCCESSFUL = 'TOC_CREATE_SUCCESSFUL';
export const TOC_CREATE_WARNING = 'TOC_CREATE_WARNING'
export const TOC_CREATE_FAILED = 'TOC_CREATE_FAILED';

export const TOC_UPDATE_STATUS_LOADING = 'TOC_UPDATE_STATUS_LOADING';
export const TOC_UPDATE_STATUS_SUCCESSFUL = 'TOC_UPDATE_STATUS_SUCCESSFUL';
export const TOC_UPDATE_STATUS_WARNING = 'TOC_UPDATE_STATUS_WARNING';
export const TOC_UPDATE_STATUS_FAILED = 'TOC_UPDATE_STATUS_FAILED';

export const TOC_UPDATE_LOADING = 'TOC_UPDATE_LOADING'
export const TOC_UPDATE_SUCCESSFUL = 'TOC_UPDATE_SUCCESSFUL';
export const TOC_UPDATE_NOT_FOUND = 'TOC_UPDATE_NOT_FOUND';
export const TOC_UPDATE_WARNING = 'TOC_UPDATE_WARNING';
export const TOC_UPDATE_FAILED = 'TOC_UPDATE_FAILED';

export const TOC_REMOVE_LOADING = 'TOC_REMOVE_LOADING';
export const TOC_REMOVE_SUCCESSFUL = 'TOC_REMOVE_SUCCESSFUL';
export const TOC_REMOVE_NOT_FOUND = 'TOC_REMOVE_NOT_FOUND';
export const TOC_REMOVE_UNAUTHORIZED = 'TOC_REMOVE_UNAUTHORIZED';
export const TOC_REMOVE_FAILED = 'TOC_REMOVE_FAILED';

export const TOC_RESET_CRUD = 'TOC_RESET_CRUD'