import { BASE_URL } from "actions/config";
import { passingHeaderToken } from "components/Utilities/Utility";
import axios from 'axios';
import {
    COMPLAIN_LIST_LOADING,
    COMPLAIN_LIST_SUCCESSFUL,
    COMPLAIN_LIST_NOT_FOUND,
    COMPLAIN_LIST_FAILED,

    COMPLAIN_DETAIL_LOADING,
    COMPLAIN_DETAIL_SUCCESSFUL,
    COMPLAIN_DETAIL_NOT_FOUND,
    COMPLAIN_DETAIL_FAILED,

    COMPLAIN_UPDATE_LOADING,
    COMPLAIN_UPDATE_SUCCESSFUL,
    COMPLAIN_UPDATE_NOT_FOUND,
    COMPLAIN_UPDATE_FAILED,

    COMPLAIN_REMOVE_LOADING,
    COMPLAIN_REMOVE_SUCCESSFUL,
    COMPLAIN_REMOVE_NOT_FOUND,
    COMPLAIN_REMOVE_FAILED,
    COMPLAIN_RESET_CRUD
} from './Types';

export const getComplain = (page , limit , token , search) => async dispatch => {
    try {
        dispatch({
            type : COMPLAIN_LIST_LOADING,
            message : 'Memuat data complain'
        })
        const response = await axios.get(`${BASE_URL}/api/complain/index?page=${page}&limit=${limit}&search=${search}` , passingHeaderToken(token))
        setTimeout(() => {
            if(response.data != null && response.data.rows_data.docs.length > 0){
                dispatch({
                    type : COMPLAIN_LIST_SUCCESSFUL,
                    data : response.data
                })
            } else {
                dispatch({
                    type : COMPLAIN_LIST_NOT_FOUND,
                    message : 'Data complain tidak ada'
                })
            }
        } , 300)
    } catch (err) {
        setTimeout(() => {
            if(err.response !== undefined && err.response.status == 404){
                dispatch({
                    type : COMPLAIN_LIST_NOT_FOUND,
                    message : 'Tidak ada data complain'
                })
            } else {
                dispatch({
                    type : COMPLAIN_LIST_FAILED,
                    message : 'Gagal memuat data complain'
                })
            }
        } , 300)
    }
}

export const getDetailComplain = (id , token) => async dispatch => {
    try {
        dispatch({
            type : COMPLAIN_DETAIL_LOADING,
            message : 'Memuat detail loading'
        })
        const response = await axios.get(`${BASE_URL}/api/complain/detail/${id}` , passingHeaderToken(token))
        setTimeout(() => {
            if(response.data !== null){
                dispatch({
                    type : COMPLAIN_DETAIL_SUCCESSFUL,
                    data : response.data
                })
            } else {
                dispatch({
                    type : COMPLAIN_DETAIL_NOT_FOUND,
                    message : 'Tidak ada data complain'
                })
            }
        } , 300)
    } catch (err) {
        if(err.response !== undefined && err.response.status == 404){
            dispatch({
                type : COMPLAIN_DETAIL_NOT_FOUND,
                message : 'Tidak ada data complain'
            })
        } else {
            dispatch({
                type : COMPLAIN_DETAIL_FAILED,
                message : 'Gagal memnuat data complain'
            })
        }
    }
}

export const updateComplain = (id , status , token) => async dispatch => {
    console.log(`ID : ${id} Status : ${status} Token : ${token}`)
    try {
        dispatch({
            type : COMPLAIN_UPDATE_LOADING,
            message : 'Memperbarui complain'
        })
        await axios.put(`${BASE_URL}/api/complain/update/${id}/${status}` , passingHeaderToken(token))
        dispatch({
            type : COMPLAIN_UPDATE_SUCCESSFUL,
            message : 'Berhasil memperbarui complain'
        })
    } catch (err) {
        if(err.response !== undefined && err.response.status == 404){
            dispatch({
                type : COMPLAIN_UPDATE_NOT_FOUND,
                message : 'Tidak ada data complain'
            })
        } else {
            dispatch({
                type : COMPLAIN_UPDATE_FAILED,
                message : 'Gagal memperbarui data complain'
            })
        }
    }
}

export const removeComplain = (id , token) => async dispatch => {
    try {
        dispatch({
            type : COMPLAIN_REMOVE_LOADING,
            message : 'Menghapus complain'
        })
        await axios.delete(`${BASE_URL}/api/complain/remove/${id}` , passingHeaderToken(token))
        dispatch({
            type : COMPLAIN_REMOVE_SUCCESSFUL,
            message : 'Berhasil menghapus complain'
        })
    } catch (err) {
        if(err.response !== undefined && err.response.status == 404){
            dispatch({
                type : COMPLAIN_REMOVE_NOT_FOUND,
                message : 'Data complain tidak ada'
            })
        } else {
            dispatch({
                type : COMPLAIN_REMOVE_FAILED,
                message : 'Gagal menghapus complain'
            })
        }
    }
}

export const resetCrud = () => async dispatch => {
    dispatch({
        type : COMPLAIN_RESET_CRUD,
        message : 'reset'
    })
}