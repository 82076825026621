import { BASE_URL } from 'actions/config';
import axios from 'axios';
import { CREATE_PARENT_LOADING, 
    CREATE_PARENT_SUCCESSFUL, CREATE_PARENT_FAILED, PARENT_DETAIL_LOADING,
     PARENT_DETAIL_SUCCESSFUL, PARENT_DETAIL_NOT_FOUND, PARENT_DETAIL_FAILED, 
     PARENT_UPDATE_LOADING, PARENT_UPDATE_SUCCESSFUL, PARENT_UPDATE_NOT_FOUND, PARENT_UPDATE_FAILED, PARENT_RESET_CRUD } from './Types';
import { passingHeaderTokenFormData } from 'components/Utilities/Utility';
import { passingHeaderToken } from 'components/Utilities/Utility';

export const createParentService = (form_data , token) => async dispatch => {
    try {
        dispatch({
            type : CREATE_PARENT_LOADING,
            payload : {
                type : CREATE_PARENT_LOADING ,
                message : 'Membuat Paket..'
            }
        })
        const response = await axios.post(`${BASE_URL}/api/parent_service/create` , form_data , passingHeaderTokenFormData(token))
        dispatch({
            type : CREATE_PARENT_SUCCESSFUL,
            payload : {
                type : CREATE_PARENT_SUCCESSFUL,
                message : 'Berhasil Membuat Paket'
            }
        })
    } catch (err) {
        dispatch({
            type : CREATE_PARENT_FAILED,
            payload : {
                type : CREATE_PARENT_FAILED,
                message : 'Gagal Membuat Paket'
            }
        })
    }
}

export const getParentDetail = (parent_id , token) => async dispatch => {
    try {
        dispatch({
            type : PARENT_DETAIL_LOADING,
            message : 'Memuat Data Paket'
        })
        const response = await axios.get(`${BASE_URL}/api/parent_service/detail/${parent_id}` , passingHeaderToken(token))
        setTimeout(() => {
            dispatch({
                type : PARENT_DETAIL_SUCCESSFUL,
                payload : {
                    data : response.data
                }
            })
        } , 250)
    } catch (err) {
        if(err.response.status == 404){
            dispatch({
                type : PARENT_DETAIL_NOT_FOUND,
                message : 'Paket Tidak Ada'
            })
        } else {
            dispatch({
                type : PARENT_DETAIL_FAILED,
                message : 'Gagal Memuat Paket'
            })
        }
    }
}

export const updateParentDetail = (parent_id , data , token) => async dispatch => {
    try {
        dispatch({
            type : PARENT_UPDATE_LOADING,
            message : 'Memperbarui Data Paket...'
        })
        const response = await axios.put(`${BASE_URL}/api/parent_service/update/${parent_id}` , data , passingHeaderToken(token))
        console.log(response)
        dispatch({
            type : PARENT_UPDATE_SUCCESSFUL,
            message : 'Berhasil Memperbarui Paket'
        })
    } catch (err) {
        if(err.response.status == 404){
            dispatch({
                type : PARENT_UPDATE_NOT_FOUND,
                message : 'Tidak Ada Data Paket'
            })
        } else {
            dispatch({
                type : PARENT_UPDATE_FAILED,
                message : 'Gagal Memperbarui Paket'
            })
        }
    }
}

export const updateParentImageDetail = (parent_id , form_data , token) => async dispatch => {
    try {
        dispatch({
            type : PARENT_UPDATE_LOADING,
            message : 'Memperbarui Data Paket...'
        })
        const response = await axios.put(`${BASE_URL}/api/parent_service/update/image/${parent_id}` , form_data , passingHeaderTokenFormData(token))
        dispatch({
            type : PARENT_UPDATE_SUCCESSFUL,
            message : 'Berhasil Memperbarui Paket'
        })
        console.log(response)
    } catch (err) {
        if(err.response.status == 404){
            dispatch({
                type : PARENT_UPDATE_NOT_FOUND,
                message : 'Tidak Ada Data Paket'
            })
        } else {
            dispatch({
                type : PARENT_UPDATE_FAILED,
                message : 'Gagal Memperbarui Paket'
            })
        }
    }
}

export const resetCrud = () => async dispatch => {
    dispatch({
        type : PARENT_RESET_CRUD,
        payload : {
            type : PARENT_RESET_CRUD
        }
    })
}