import { connect } from "react-redux";
import PropTypes from 'prop-types';
import Skeleton from "react-loading-skeleton";
import { MaterialGray1 } from "components/Utilities/ResourceColors";
import { getServiceList, createService , removeService } from "actions/Service/Service";
import { useHistory } from "react-router-dom";
import { useEffect, useReducer, useRef, useState } from "react";
import { SERVICE_LIST_LOADING } from "actions/Service/Types";
import { SERVICE_LIST_SUCCESSFUL } from "actions/Service/Types";
import { SERVICE_LIST_NOT_FOUND } from "actions/Service/Types";
import { SERVICE_LIST_FAILED } from "actions/Service/Types";
import {
    SERVICE_CREATE_LOADING,
    SERVICE_CREATE_SUCCESSFUL,
    SERVICE_CREATE_FAILED
} from 'actions/Service/Types';
import { Card, CardBody, Col, Container, Form, FormGroup, Input, Modal, ModalBody, ModalHeader, Row, Table } from "reactstrap";
import Pagination from "components/Pagination/Pagination";
import NotFoundPage from "components/NotFound/NotFoundPage";
import ErrorPage from "components/ErrorComponent/ErrorPage";
import IcBroom from 'assets/img/ic_broom.svg';
import IcError from 'assets/img/ic_error.svg';
import IcImage from 'assets/img/ic_image.svg';
import Select from "react-select";
import { erorrMsgStyle } from "components/Utilities/Utility";
import { RED_ERROR_MESSAGE } from "components/Utilities/ResourceColors";
import { validateEmpty } from "components/Utilities/Utility";
import { MaterialRed1 } from "components/Utilities/ResourceColors";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { MaterialGreen1 } from "components/Utilities/ResourceColors";
import { BASE_URL } from "actions/config";
import { SERVICE_REMOVE_LOADING } from "actions/Service/Types";
import { SERVICE_REMOVE_SUCCESSFUL } from "actions/Service/Types";
import { SERVICE_REMOVE_UNAUTHORIZED } from "actions/Service/Types";
import { SERVICE_REMOVE_NOT_FOUND } from "actions/Service/Types";
import { SERVICE_REMOVE_FAILED } from "actions/Service/Types";
const MySwal = withReactContent(Swal)

const LayananListServices = (props) => {
    const history = useHistory()
    const [token, setToken] = useState('')

    const [isLoading, setLoading] = useState(false)

    const [showModalCreate, setShowModalCreate] = useState(false)
    const handleOpenCloseModalCreate = () => setShowModalCreate(!showModalCreate)

    const fileRef = useRef()

    const options_type = [
        {
            value: 'durasi', label: 'Durasi'
        },
        {
            value: 'luas ruangan', label: 'Luas Ruangan'
        },
        {
            value: 'single', label: 'Single'
        }
    ]

    const options_category = [
        {
            value : 'Cleaning' , label : 'Cleaning'
        },
        {
            value : 'Disinfectant' , label : 'Disinfectant'
        },
        {
            value : 'Fogging' , label : 'Fogging'
        },
        {
            value : 'Borongan' , label : 'Borongan'
        },
        {
            value : 'Lainnya' , label : 'Lainnya'
        }
    ]
    const [imageSelected, setImageSelected] = useState(null)
    const [selectedFile, setSelectedFile] = useState(null)
    const [imgProper, setImgProper] = useState(false)
    const [isSubmit, setSubmit] = useState(false)
    const [isSubmitRemove , setSubmitRemove] = useState(false)

    const onChangeImageHandler = event => {
        var img = new Image()
        setImageSelected(URL.createObjectURL(event.target.files[0]))
        img.onload = function () {
            if (img.width == 450 && img.height == 450) {
                setSelectedFile(event.target.files[0])
                setImgProper(true)
            } else {
                setImgProper(false)
            }
        }
        img.src = URL.createObjectURL(event.target.files[0])
    }

    const [showModalRemove, setShowModalRemove] = useState(false)
    const handleOpenCloseModalRemove = () => setShowModalRemove(!showModalRemove)

    const [inputValues, setInputValues] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            service_name: '',
            service_description: '',
            service_type: {
                label: '',
                value: ''
            },
            service_category: {
                label: '',
                value: ''
            },
            remove_service_id : -1,
            remove_service_name : '',
            remove_service_name_confirm : '',
            remove_service_password_confirm : ''
        }
    )

    const onChangeHandler = event => {
        const { name, value } = event.target
        setInputValues({ [name]: value })
    }

    const [currentPage, setCurrentPage] = useState(1)
    useEffect(() => {
        setToken(localStorage.getItem('token'))
        if(token !== ''){
            props.getServiceList(props.location.state.parent_id, currentPage, 5, token)
        }
    }, [token])

    useEffect(() => {
        buildServiceList()
    }, [props.payload_service])

    function validateBorderImg() {
        if (isSubmit) {
            if (imageSelected == null || (imageSelected != null && !imgProper)) {
                return `1px dashed ${RED_ERROR_MESSAGE}`
            }
        } else {
            if (imageSelected != null && !imgProper) {
                return `1px dashed ${RED_ERROR_MESSAGE}`
            }
        }
    }
    function validateTextImg() {
        if (isSubmit) {
            if (imageSelected == null) {
                return (
                    <p style={
                        erorrMsgStyle
                    }>Gambar Paket must be filled</p>
                )
            } else if (imageSelected != null && !imgProper) {
                return (
                    <p style={
                        erorrMsgStyle
                    }>Gambar Paket must be 450x X 450px</p>
                )
            } else {
                return (<></>)
            }
        } else {
            if (imageSelected != null && !imgProper) {
                return (
                    <p style={
                        erorrMsgStyle
                    }>Gambar Paket must be 450px X 450px</p>
                )
            } else {
                return (<></>)
            }
        }
    }

    function createService() {
        setSubmit(true)
        if (token !== '' &&
            selectedFile != null &&
            validateEmpty(props.location.state.parent_id) &&
            validateEmpty(inputValues.service_name) &&
            validateEmpty(inputValues.service_type.label) &&
            validateEmpty(inputValues.service_category.label) &&
            validateEmpty(inputValues.service_description)) {
            const payload = {
                parent_id: props.location.state.parent_id,
                service_name: inputValues.service_name,
                service_type: inputValues.service_type.label,
                service_category: inputValues.service_category.label,
                service_description: inputValues.service_description
            }
            const formData = new FormData()
            formData.append('json_data', JSON.stringify(payload))
            formData.append('file', selectedFile)
            props.createService(formData, token)
        }
    }

    useEffect(() => {
        switch (props.payload_service.service_create.type) {
            case SERVICE_CREATE_LOADING: {
                setLoading(true)
                break;
            }
            case SERVICE_CREATE_SUCCESSFUL: {
                setLoading(false)
                setShowModalCreate(false)
                setInputValues({
                    service_name: '',
                    service_description: '',
                    service_type: {
                        label: '',
                        value: ''
                    },
                    service_category: {
                        label: '',
                        value: ''
                    }
                })
                setSubmit(false)
                setImageSelected(null)
                setSelectedFile(null)
                setImgProper(false)
                if(token !== ''){
                    props.getServiceList(props.location.state.parent_id, currentPage, 5, token)
                }
                break;
            }
            case SERVICE_CREATE_FAILED: {
                setLoading(false)
                MySwal.fire(
                    'Perhatian',
                    'Gagal membuat layanan',
                    'error'
                )
                break;
            }
        }
    }, [props.payload_service.service_create])

    useEffect(() => {
        switch(props.payload_service.service_remove.type){
            case SERVICE_REMOVE_LOADING: {
                setLoading(true)
                break;
            }
            case SERVICE_REMOVE_SUCCESSFUL: {
                setLoading(false)
                setShowModalRemove(false)
                if(token !== ''){
                    props.getServiceList(props.location.state.parent_id, currentPage, 5, token)
                }
                break;
            }
            case SERVICE_REMOVE_UNAUTHORIZED:{
                setLoading(false)
                MySwal.fire(
                    'Perhatian',
                    'Password salah',
                    'warning'
                )
                break;
            }
            case SERVICE_REMOVE_NOT_FOUND:{
                setLoading(false)
                MySwal.fire(
                    'Perhatian',
                    'Data service tidak ada',
                    'error'
                )
                break;
            }
            case SERVICE_REMOVE_FAILED:{
                setLoading(false)
                MySwal.fire(
                    'Perhatian',
                    'Gagal menghapus data service',
                    'error'
                )
                break;
            }
        }
    } , [props.payload_service.service_remove])

    function removeService(){
        if(token !== '' && 
            validateEmpty(inputValues.remove_service_id) && 
            validateEmpty(inputValues.remove_service_name) &&
            validateEmpty(inputValues.remove_service_name_confirm) && 
            validateEmpty(inputValues.remove_service_password_confirm) &&
            inputValues.remove_service_name === inputValues.remove_service_name_confirm){
            setSubmitRemove(true)
            props.removeService(
                inputValues.remove_service_id,
                inputValues.remove_service_password_confirm,
                token
            )
        }
    }

    function buildServiceList() {
        switch (props.payload_service.parent_service_payload.type) {
            case SERVICE_LIST_LOADING: {
                return (
                    <>
                        <div style={{ marginTop: '15px' }}>
                            <Row>
                                <Col lg="12">
                                    <Skeleton style={{ height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="12">
                                    <Skeleton style={{ marginTop: '25px', height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="12">
                                    <Skeleton style={{ marginTop: '25px', height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="12">
                                    <Skeleton style={{ marginTop: '25px', height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                                </Col>
                            </Row>
                        </div>
                    </>
                )
            }
            case SERVICE_LIST_SUCCESSFUL: {
                if (props.payload_service.parent_service_payload.data !== undefined) {
                    return (
                        <>
                            <Row style={{ marginTop: '15px' }}>
                                {
                                    props.payload_service.parent_service_payload.data.rows_data.docs.map((item) => {
                                        return (
                                            <Col style={{ marginTop: '20px' }} md="4">
                                                <Card className="shadow">
                                                    <div style={{
                                                        paddingTop: '25px',
                                                        paddingBottom: '25px'
                                                    }}>
                                                        <img src={`${BASE_URL}/api/images/${item.service_image_thumbnail}`} style={{ width: '100%', height: '150px', objectFit: 'cover' }} />
                                                        <p style={{
                                                            marginTop: '20px',
                                                            textAlign: 'center',
                                                            border: `0.5px solid ${MaterialGray1}`,
                                                            borderRadius: '5px',
                                                            marginLeft: '25px',
                                                            marginRight: '25px',
                                                            paddingTop: '5px',
                                                            paddingBottom: '5px'
                                                        }}>
                                                            {item.service_name}
                                                        </p>
                                                        <div style={{
                                                            paddingRight: '20px',
                                                            paddingLeft: '20px',
                                                            marginTop: '20px'
                                                        }}>
                                                            <div style={{ marginTop: '10px' }} className="row">
                                                                <div className="col-md-6 col-sm-6 text-left">
                                                                    <div className="col-xs-12 text-center">
                                                                        <button onClick={() => {
                                                                            history.push({
                                                                                pathname: '/admin/service_detail',
                                                                                state: {
                                                                                    service_id: item.id
                                                                                }
                                                                            })
                                                                        }} className="btn btn-md btn-primary" style={{ width: '100%', marginBottom: '10px' }}>
                                                                            Lihat
                                                                        </button>
                                                                    </div>
                                                                </div>

                                                                <div class="col-md-6 col-sm-6 text-right">
                                                                    <div className="col-xs-12 text-center">
                                                                        <button onClick={() => {
                                                                            setInputValues({
                                                                                remove_service_id : item.id,
                                                                                remove_service_name : item.service_name
                                                                            })
                                                                            setShowModalRemove(true)
                                                                        }} className="btn btn-md btn-danger" style={{ width: '100%', marginBottom: '10px' }}>
                                                                            Hapus
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Card>
                                            </Col>
                                        )
                                    })
                                }
                            </Row>
                            <Row>
                                {
                                    props.payload_service.parent_service_payload.data.count_total > 5 ?
                                        <Card className="bg-default-shadow" style={{ width: 'max-content', paddingLeft: '20px', marginRight: '0', marginTop: '10px', paddingTop: '15px', marginLeft: 'auto' }}>
                                            <Pagination
                                                className="pagination-bar"
                                                currentPage={currentPage}
                                                totalCount={props.banner.payload.data.count_total}
                                                pageSize={5}
                                                onPageChange={page => {
                                                    setCurrentPage(page)
                                                }} />
                                        </Card>
                                        : <div></div>
                                }
                            </Row>
                        </>
                    )
                }
            }
            case SERVICE_LIST_NOT_FOUND: {
                return (
                    <NotFoundPage imageSource={IcBroom} imageText={props.payload_service.parent_service_payload.message} />
                )
            }
            case SERVICE_LIST_FAILED: {
                return (
                    <ErrorPage imageSource={IcError} imageText={props.payload_service.parent_service_payload.message} />
                )
            }
        }
    }

    return (
        <>
            <div className="main-content">
                <div className="header bg-gradient-info pb-8 pt-5 pt-lg-7 d-flex align-items-center">
                    <span className="mask bg-gradient-default opacity-8" />
                </div>
                <Container className="mt--7" fluid>
                    <Card className="bg-secondary shadow">
                        <CardBody>
                            {
                                props.payload_service.parent_service_payload.type !== SERVICE_LIST_LOADING && props.payload_service.parent_service_payload.type !== SERVICE_LIST_FAILED ?
                                    <Row>
                                        <Col xl="10">
                                            <p>Daftar Layanan</p>
                                        </Col>
                                        <Col xl="2">
                                            <button style={{ fontSize: '11px' }} onClick={() => {
                                                setShowModalCreate(true)
                                            }} className="btn btn-sm btn-primary">+ Tambah Layanan</button>
                                        </Col>
                                    </Row> : <div></div>
                            }
                            {buildServiceList()}
                            <Modal isOpen={showModalCreate} closable={false} keyboard={false} centered>
                                {
                                    isLoading ?
                                        <div style={{
                                            borderTopLeftRadius: '5px',
                                            borderTopRightRadius: '5px',
                                            height: '5px'
                                        }} className="progress-bar">
                                            <div className="progress-bar-value"></div>
                                        </div>
                                        : <div style={{ height: '5px' }}></div>
                                }
                                <ModalHeader toggle={handleOpenCloseModalCreate}>
                                    <h4>Buat Layanan Baru</h4>
                                </ModalHeader>
                                <ModalBody>
                                    <Row>
                                        <Col className="order-xl-1" xl="12">
                                            <Form>
                                                <div>
                                                    <Row>
                                                        <Col lg="12">
                                                            <Row style={{ marginTop: '-30px' }}>
                                                                <Col lg="12">
                                                                    <label
                                                                        className="form-control-label"
                                                                        htmlFor="input-service-name"
                                                                    >
                                                                        Nama Service
                                                                    </label>
                                                                    <Input
                                                                        className="form-control-alternative"
                                                                        id="input-service-name"
                                                                        type="text"
                                                                        name="service_name"
                                                                        onChange={onChangeHandler}
                                                                        defaultValue={inputValues.service_name}
                                                                        style={
                                                                            isSubmit && inputValues.service_name === '' ?
                                                                                {
                                                                                    color: 'black',
                                                                                    border: '0.2px solid #d32f2f'
                                                                                }
                                                                                :
                                                                                {
                                                                                    color: 'black',
                                                                                    border: 'none'
                                                                                }
                                                                        }
                                                                        disabled={(isLoading) ? "disabled" : ""} />
                                                                    <p style={{
                                                                        fontSize: '11px',
                                                                        color: '#d32f2f',
                                                                        marginTop: '5px',
                                                                        visibility: isSubmit && inputValues.service_name === '' ? 'visible' : 'hidden'
                                                                    }}>Harap isi Nama Service</p>
                                                                </Col>
                                                            </Row>
                                                            <Row style={{ marginTop: '-20px' }}>
                                                                <Col lg="6">
                                                                    <label
                                                                        className="form-control-label"
                                                                        htmlFor="input-service-type"
                                                                    >
                                                                        Tipe Service
                                                                    </label>
                                                                    <div id="input-service-type" style={
                                                                        isSubmit && !validateEmpty(inputValues.service_type.value) ?
                                                                            {
                                                                                borderRadius: '5px',
                                                                                border: `1px solid ${RED_ERROR_MESSAGE}`
                                                                            } :
                                                                            {
                                                                                border: 'none'
                                                                            }
                                                                    }>
                                                                        <Select
                                                                            options={options_type}
                                                                            value={inputValues.service_type}
                                                                            name="service_type"
                                                                            onChange={(selectedOption) => {
                                                                                setInputValues({ ['service_type']: selectedOption })
                                                                            }} isDisabled={isLoading} />
                                                                    </div>
                                                                    <p style={{
                                                                        color: MaterialRed1,
                                                                        fontSize: '11px',
                                                                        visibility: isSubmit && !validateEmpty(inputValues.service_type.value) ? 'visible' : 'hidden'
                                                                    }}>Tipe Service must be selected</p>
                                                                </Col>
                                                                <Col lg="6">
                                                                    <label
                                                                        className="form-control-label"
                                                                        htmlFor="input-service-category"
                                                                    >
                                                                        Kategori Service
                                                                    </label>
                                                                    <div id="input-service-category" style={
                                                                        isSubmit && !validateEmpty(inputValues.service_category.value) ?
                                                                            {
                                                                                borderRadius: '5px',
                                                                                border: `1px solid ${RED_ERROR_MESSAGE}`
                                                                            } :
                                                                            {
                                                                                border: 'none'
                                                                            }
                                                                    }>
                                                                        <Select
                                                                            options={options_category}
                                                                            value={inputValues.service_category}
                                                                            name="service_category"
                                                                            onChange={(selectedOption) => {
                                                                                setInputValues({ ['service_category']: selectedOption })
                                                                            }} isDisabled={isLoading} />
                                                                    </div>
                                                                    <p style={{
                                                                        color: MaterialRed1,
                                                                        fontSize: '11px',
                                                                        visibility: isSubmit && !validateEmpty(inputValues.service_category.value) ? 'visible' : 'hidden'
                                                                    }}>Kategori Service must be selected</p>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col lg="12">
                                                            <div>
                                                                <label
                                                                    className="form-control-label">Gambar Layanan</label>
                                                                {
                                                                    <div style={{
                                                                        alignItems: 'center',
                                                                        display: 'flex',
                                                                        justifyContent: 'center',
                                                                        border: imageSelected === null ? '1px dashed black' : '1px solid transparent',
                                                                        borderRadius: '10px'
                                                                    }}>
                                                                        {
                                                                            imageSelected === null ?
                                                                                <div style={{
                                                                                    alignItems: 'center',
                                                                                    display: 'flex',
                                                                                    width: '100%',
                                                                                    height: '260px',
                                                                                    border: validateBorderImg(),
                                                                                    borderRadius: '10px',
                                                                                    justifyContent: 'center'
                                                                                }}>
                                                                                    <img src={IcImage} style={{ textAlign: 'center', width: '50px', height: '50px' }} />
                                                                                </div>
                                                                                :
                                                                                <div style={{
                                                                                    width: '100%',
                                                                                    height: '260px',
                                                                                    border: validateBorderImg(),
                                                                                    borderRadius: '10px',
                                                                                    alignItems: 'center',
                                                                                    justifyContent: 'center',
                                                                                    textAlign: 'center'
                                                                                }}>
                                                                                    <div style={{
                                                                                        width: '25px',
                                                                                        height: '25px',
                                                                                        borderRadius: '50%',
                                                                                        backgroundColor: MaterialRed1,
                                                                                        position: 'absolute',
                                                                                        right: 8,
                                                                                        top: 22,
                                                                                        cursor: 'pointer'
                                                                                    }}
                                                                                        onClick={() => {
                                                                                            setSelectedFile(null)
                                                                                            setImageSelected(null)
                                                                                        }}
                                                                                    >
                                                                                        <p style={{ color: 'white' }}>&times;</p>
                                                                                    </div>
                                                                                    <img src={imageSelected} style={{
                                                                                        width: '100%',
                                                                                        objectFit: 'cover',
                                                                                        borderRadius: '10px',
                                                                                        height: '260px',
                                                                                        display: 'inline-block'
                                                                                    }} />
                                                                                </div>
                                                                        }
                                                                    </div>
                                                                }
                                                                <Row>
                                                                    <Col sm="8">
                                                                        <p style={{ fontSize: '13px', marginTop: '10px' }}>
                                                                            Ukuran Gambar 450px X 450px
                                                                        </p>
                                                                    </Col>
                                                                    <Col sm="4">
                                                                        <div>
                                                                            <input type="file" onChange={onChangeImageHandler} name="image_picker_change" ref={fileRef} hidden />
                                                                            <button onClick={(e) => {
                                                                                e.preventDefault()
                                                                                if (!isLoading) {
                                                                                    fileRef.current.click()
                                                                                }
                                                                            }} style={{
                                                                                marginTop: '10px',
                                                                                width: '100%'
                                                                            }} className="btn btn-sm btn-primary">Ambil gambar</button>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col xl="12">
                                                                        {validateTextImg()}
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row style={{ marginTop: '10px' }}>
                                                        <Col lg="12">
                                                            <FormGroup>
                                                                <label
                                                                    className="form-control-label"
                                                                    htmlFor="input-service-description"
                                                                >
                                                                    Deskripsi Service
                                                                </label>
                                                                <Input
                                                                    type="textarea"
                                                                    id="input-service-description"
                                                                    name="service_description"
                                                                    className="form-control-alternative"
                                                                    defaultValue={inputValues.service_description}
                                                                    style={
                                                                        isSubmit && inputValues.service_description === '' ?
                                                                            {
                                                                                height: '100px',
                                                                                padding: '0.4em',
                                                                                textAlign: 'left',
                                                                                color: 'black',
                                                                                border: '0.2px solid #d32f2f'
                                                                            }
                                                                            :
                                                                            {
                                                                                height: '150px',
                                                                                padding: '0.4em',
                                                                                textAlign: 'left',
                                                                                color: 'black',
                                                                                border: 'none'
                                                                            }
                                                                    }
                                                                    disabled={(isLoading) ? "disabled" : ""}
                                                                    onChange={onChangeHandler} />
                                                                <p style={{
                                                                    fontSize: '11px',
                                                                    color: '#d32f2f',
                                                                    marginTop: '5px',
                                                                    visibility: isSubmit && inputValues.service_description === '' ? 'visible' : 'hidden'
                                                                }}>Harap isi Deskripsi Service</p>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col lg="12">
                                                            <a onClick={() => createService()} style={{ backgroundColor: isLoading ? MaterialGray1 : MaterialGreen1, width: '100%', marginTop: '10px' }} className="btn btn-success btn-sm" disabled={isLoading ? 'disabled' : ''}>
                                                                Simpan
                                                            </a>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Form>
                                        </Col>
                                    </Row>
                                </ModalBody>
                            </Modal>
                            {
                                props.payload_service.parent_service_payload.type !== SERVICE_LIST_LOADING && props.payload_service.parent_service_payload.type !== SERVICE_LIST_FAILED ?
                                    <Modal isOpen={showModalRemove} closable={false} keyboard={false} centered
                                    >
                                        {
                                            isSubmitRemove ? isLoading ?
                                                <div className="progress-bar">
                                                    <div className="progress-bar-value"></div>
                                                </div> :
                                                <div style={{ height: '4px' }}></div> : <div></div>
                                        }
                                        <ModalHeader toggle={handleOpenCloseModalRemove}>
                                            <h4>Hapus Layanan</h4>
                                        </ModalHeader>
                                        <ModalBody>
                                        <p>Kamu yakin ingin menghapus layanan <strong>{inputValues.remove_service_name}?</strong>, ketik nama layanan yang ingin di hapus dan masukan kata sandi untuk konfirmasi</p>
                                            <Form onSubmit={(e) => {
                                                e.preventDefault()
                                                removeService()
                                            }}>
                                                <Row>
                                                    <Col xl="12">
                                                        <Input
                                                            className="form-control-alternative"
                                                            type="text"
                                                            name="remove_service_name_confirm"
                                                            placeholder="Ketik nama layanan di sini"
                                                            onChange={onChangeHandler}
                                                            value={inputValues.remove_service_name_confirm}
                                                            style={{
                                                                border: inputValues.remove_service_name === inputValues.remove_service_name_confirm ? 'none' : `1px solid ${RED_ERROR_MESSAGE}`
                                                            }}
                                                            disabled={isLoading ? 'disabled' : ''} />
                                                    </Col>
                                                </Row>
                                                <Row style={{ marginTop: '8px' }}>
                                                    <Col xl="12">
                                                        <Input
                                                            className="form-control-alternative"
                                                            type="password"
                                                            name="remove_service_password_confirm"
                                                            onChange={onChangeHandler}
                                                            placeholder="Ketik kata sandi di sini"
                                                            value={inputValues.remove_service_password_confirm}
                                                            style={{
                                                                border: validateEmpty(inputValues.remove_service_password_confirm) ? 'none' : `1px solid ${RED_ERROR_MESSAGE}`
                                                            }}
                                                            disabled={isLoading ? 'disabled' : ''} />
                                                    </Col>
                                                </Row>
                                                <Row style={{ marginTop: '8px' }}>
                                                    <Col xl="12">
                                                        <button style={{
                                                            width: '100%',
                                                            backgroundColor: ((inputValues.remove_service_name === inputValues.remove_service_name_confirm) && validateEmpty(inputValues.remove_service_password_confirm)) ?
                                                                MaterialRed1 : MaterialGray1,
                                                            border: 'none'
                                                        }} className="btn btn-md btn-danger" disabled={(inputValues.remove_service_name === inputValues.remove_service_name_confirm) && validateEmpty(inputValues.remove_service_password_confirm) ? isLoading ? 'disabled' : '' : 'disabled'}
                                                        >Hapus Service</button>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </ModalBody>
                                    </Modal> : <div></div>
                            }
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </>
    )
}

LayananListServices.propTypes = {
    getServiceList: PropTypes.func.isRequired,
    createService: PropTypes.func.isRequired,
    removeService : PropTypes.func.isRequired,
    payload_service: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    payload_service: state.payload_service
})

export default connect(mapStateToProps, { getServiceList, createService , removeService })(LayananListServices)