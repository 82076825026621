import { createStore , applyMiddleware , compose } from "redux";
import thunk from "redux-thunk";
import RootReducer from './Reducers';

const initState = {}
const store = createStore(
    RootReducer,
    initState,
    compose(applyMiddleware(thunk),
        window.devToolsExtension ? window.devToolsExtension() : f => f)
)

export default store