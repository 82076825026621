import { BANNER_LIST_LOADING , BANNER_LIST_SUCCESSFUL , BANNER_LIST_NOT_FOUND , BANNER_LIST_FAILED,
        BANNER_DETAIL_LOADING , BANNER_DETAIL_SUCCESSFUL , BANNER_DETAIL_NOT_FOUND , BANNER_DETAIL_FAILED, UPDATE_BANNER_LOADING, UPDATE_BANNER_SUCCESSFUL, UPDATE_BANNER_NOT_FOUND, UPDATE_BANNER_FAILED, CLEAR_BANNER_CREATE_UPDATE_STATE, CREATE_BANNER_LOADING, CREATE_BANNER_SUCCESSFUL, CREATE_BANNER_FAILED, BANNER_RESET_CRUD, REMOVE_BANNER_LOADING, REMOVE_BANNER_SUCCESSFUL, REMOVE_BANNER_NOT_FOUND, REMOVE_BANNER_FAILED } from '../../actions/Banners/Types';

const initState = {
    payload : {
        type : null ,
        message : 'Memuat Data Banner'
    },
    payload_banner : {
        type : null,
        message : ''
    }
}

export default function(state = initState , action){
    switch(action.type){
        case BANNER_LIST_LOADING:
            return {
                ...state ,
                payload : action.payload
            }
        case BANNER_LIST_SUCCESSFUL:
            return {
                ...state ,
                payload : action.payload ,
                payload_banner : {
                    type : null,
                    message : ''
                }
            }
        case BANNER_LIST_NOT_FOUND:
            return {
                ...state ,
                payload : action.payload
            }
        case BANNER_LIST_FAILED:
            return {
                ...state ,
                payload : action.payload
            }
        case BANNER_DETAIL_LOADING:
            return {
                ...state ,
                payload : action.payload
            }
        case BANNER_DETAIL_SUCCESSFUL:
            return {
                ...state,
                payload : action.payload,
                payload_banner : {
                    type : null ,
                    message : ''
                }
            }
        case BANNER_DETAIL_NOT_FOUND:
            return {
                ...state,
                payload : action.payload
            }
        case BANNER_DETAIL_FAILED:
            return {
                ...state,
                payload : action.payload
            }
        case UPDATE_BANNER_LOADING:
            return {
                ...state,
                payload_banner : {
                    type : UPDATE_BANNER_LOADING,
                    message : 'Updating Banner Data...'
                }
            }
        case UPDATE_BANNER_SUCCESSFUL:
            return {
                ...state,
                payload_banner : {
                    type : UPDATE_BANNER_SUCCESSFUL,
                    message : 'Update Banner Successful'
                }
            }
        case UPDATE_BANNER_NOT_FOUND:
            return {
                ...state,
                payload_banner : {
                    type : UPDATE_BANNER_NOT_FOUND,
                    message : 'Update Banner Not Found'
                }
            }
        case UPDATE_BANNER_FAILED:
            return {
                ...state,
                payload_banner : {
                    type : UPDATE_BANNER_FAILED,
                    message : 'Update Banner Failed'
                }
            }
        case CREATE_BANNER_LOADING:
            return {
                ...state ,
                payload_banner : {
                    type : CREATE_BANNER_LOADING,
                    message : 'Membuat Banner...'
                }
            }
        case CREATE_BANNER_SUCCESSFUL:
            return {
                ...state ,
                payload_banner : {
                    type : CREATE_BANNER_SUCCESSFUL,
                    message : 'Berhasil Membuat Banner'
                }
            }
        case CREATE_BANNER_FAILED:
            return {
                ...state ,
                payload_banner : {
                    type : CREATE_BANNER_FAILED,
                    message : 'Gagal Membuat Banner'
                }
            }
        case REMOVE_BANNER_LOADING:{
            return {
                ...state,
                payload_banner : {
                    type : action.type,
                    message : action.message
                }
            }
        }
        case REMOVE_BANNER_SUCCESSFUL:
            return {
                ...state,
                payload_banner : {
                    type : action.type,
                    message : action.message
                }
            }
        case REMOVE_BANNER_NOT_FOUND:
            return {
                ...state,
                payload_banner : {
                    type : action.type,
                    message : action.message
                }
            }
        case REMOVE_BANNER_FAILED:
            return {
                ...state,
                payload_banner : {
                    type : action.type,
                    message : action.message
                }
            }
        case BANNER_RESET_CRUD:
            return {
                ...state,
                payload_banner : {
                    type : null,
                    message : ''
                }
            }
        default:
            return state
    }
}