export const LOGIN_LOADING = 'LOGIN_LOADING';
export const LOGIN_SUCCESSFUL = 'LOGIN_SUCCESSFUL';
export const LOGIN_NOT_FOUND = 'LOGIN_NOT_FOUND';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGIN_EXIST = 'LOGIN_EXIST'

export const LOGOUT_SUCCESSFUL = 'LOGOUT_SUCCESSFUL';
export const LOGOUT_FAILED = 'LOGOUT_FAILED';

export const UPDATE_TOKEN_LOADING = 'UPDATE_TOKEN_LOADING';
export const UPDATE_TOKEN_SUCCESSFUL = 'UPDATE_TOKEN_SUCCESSFUL';
export const UPDATE_TOKEN_FAILED = 'UPDATE_TOKEN_FAILEDs'