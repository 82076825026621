import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { Card, CardBody, CardTitle, Col, Container, Form, FormGroup, Row } from "reactstrap";
import { getTransactionDetail } from "actions/Transactions/TransactionService";
import {
    TRANSACTION_DETAIL_LOADING,
    TRANSACTION_DETAIL_SUCCESSFUL,
    TRANSACTION_DETAIL_NOT_FOUND,
    TRANSACTION_DETAIL_FAILED
} from 'actions/Transactions/Types';
import { useState } from "react";
import { useEffect } from "react";
import { BASE_URL } from "actions/config";
import { ReactComponent as IcOrder } from 'assets/img/ic_order.svg';
import { MaterialBlue1 } from "components/Utilities/ResourceColors";
import { formatRupiah } from "components/Utilities/Utility";
import { MaterialRed1 } from "components/Utilities/ResourceColors";
import { MaterialGreen1 } from "components/Utilities/ResourceColors";
import { MaterialGray3 } from "components/Utilities/ResourceColors";
import { MaterialYellow1 } from "components/Utilities/ResourceColors";
import { MaterialYellow2 } from "components/Utilities/ResourceColors";
import Skeleton from "react-loading-skeleton";
import { MaterialGray1 } from "components/Utilities/ResourceColors";
import ErrorPage from "components/ErrorComponent/ErrorPage";
import NotFoundPage from "components/NotFound/NotFoundPage";
import IcNotFound from 'assets/img/ic_not_found.svg';
import IcError from 'assets/img/ic_error.svg';

const TransactionDetailPage = (props) => {
    const [token, setToken] = useState('')
    useEffect(() => {
        setToken(localStorage.getItem('token'))
        if (token !== '') {
            if (props.location.state !== undefined && props.location.state.transaction_id !== undefined) {
                props.getTransactionDetail(props.location.state.transaction_id, token)
            }
        }
    }, [token])
    function buildUserProfile(data) {
        switch (data.transaction_for) {
            case 'topup':
                let urlImage = ''
                let userType = ''
                let userName = ''
                if (data.user_type === 'customer') {
                    userType = data.user_type.charAt(0).toUpperCase() + data.user_type.slice(1)
                    urlImage = data.customer_transaction_data.user_profile_image
                    userName = data.customer_transaction_data.complete_name
                } else if (data.user_type === 'mitra') {
                    userType = data.user_type.charAt(0).toUpperCase() + data.user_type.slice(1)
                    urlImage = data.mitra_transaction_data.user_profile_image
                    userName = data.mitra_transaction_data.complete_name
                }
                return (
                    <>
                        <Row>
                            <Col style={{ textAlign: 'center' }} md="12">
                                <h4>{userType}</h4>
                                <img src={`${BASE_URL}/api/images${urlImage}`} style={{
                                    width: '100px',
                                    objectFit: 'cover',
                                    borderRadius: '50%',
                                    height: '100px',
                                    display: 'inline-block'
                                }} />
                                <p style={{ marginTop: '10px' }}>{userName}</p>
                            </Col>
                        </Row>
                    </>
                )
            case 'disbursement':
                let urlImages = ''
                let userTypes = ''
                let completeName = ''
                if (data.user_type === 'customer') {
                    userTypes = data.user_type.charAt(0).toUpperCase() + data.user_type.slice(1)
                    urlImages = data.customer_transaction_data.user_profile_image
                    completeName = data.customer_transaction_data.complete_name
                } else if (data.user_type === 'mitra') {
                    userTypes = data.user_type.charAt(0).toUpperCase() + data.user_type.slice(1)
                    urlImages = data.mitra_transaction_data.user_profile_image
                    completeName = data.mitra_transaction_data.complete_name
                }
                return (
                    <>
                        <Row>
                            <Col style={{ textAlign: 'center' }} md="12">
                                <h4>{userTypes}</h4>
                                <img src={`${BASE_URL}/api/images${urlImages}`} style={{
                                    width: '100px',
                                    objectFit: 'cover',
                                    borderRadius: '50%',
                                    height: '100px',
                                    display: 'inline-block'
                                }} />
                                <p style={{ marginTop: '10px' }}>{completeName}</p>
                            </Col>
                        </Row>
                    </>
                )
            case 'order':
                return (
                    <>
                        <Row>
                            <Col style={{ textAlign: 'center' }} md="6">
                                <h4>Customer</h4>
                                <img src={`${BASE_URL}/api/images${data.customer_transaction_data.user_profile_image}`} style={{
                                    width: '100px',
                                    objectFit: 'cover',
                                    borderRadius: '50%',
                                    height: '100px',
                                    display: 'inline-block'
                                }} />
                                <p style={{ marginTop: '10px' }}>{data.customer_transaction_data.complete_name}</p>
                            </Col>
                            <Col style={{ textAlign: 'center' }} md="6">
                                <h4>Mitra</h4>
                                <img src={`${BASE_URL}/api/images${data.mitra_transaction_data.user_profile_image}`} style={{
                                    width: '100px',
                                    objectFit: 'cover',
                                    borderRadius: '50%',
                                    height: '100px',
                                    display: 'inline-block'
                                }} />
                                <p style={{ marginTop: '10px' }}>{data.mitra_transaction_data.complete_name}</p>
                            </Col>
                        </Row>
                    </>
                )
        }
    }
    function buildTransactionId(data) {
        switch (data.transaction_for) {
            case 'topup':
                return data.topup_id
            case 'disbursement':
                return data.disbursement_id
            case 'order':
                return data.order_id_transaction
        }
    }
    function buildTransactionType(data) {
        switch (data.transaction_for) {
            case 'topup':
                return (
                    <>
                        <i style={{ color: MaterialBlue1, fontSize: '65px' }} className="fa-solid fa-wallet" />
                        <p style={{ marginTop: '10px', fontWeight: 'bold', fontSize: '16px' }}>
                            {
                                data.transaction_for.charAt(0).toUpperCase() + data.transaction_for.slice(1)
                            }
                        </p>
                    </>
                )
            case 'disbursement':
                return (
                    <>
                        <i style={{ color: MaterialBlue1, fontSize: '65px' }} className="fa-solid fa-wallet" />
                        <p style={{ marginTop: '10px', fontWeight: 'bold', fontSize: '16px' }}>
                            {
                                data.transaction_for.charAt(0).toUpperCase() + data.transaction_for.slice(1)
                            }
                        </p>
                    </>
                )
            case 'order':
                return (
                    <>
                        <Row>
                            <Col style={{ textAlign: 'center' }} sm="6">
                                <IcOrder style={{ width: '65px', height: '65px', color: MaterialBlue1 }} />
                                <p style={{ marginTop: '10px', fontSize: '12px' }}>
                                    Order
                                </p>
                            </Col>
                            {
                                data.transaction_type === 'transaction_out' ?
                                    <Col style={{ textAlign: 'center' }} sm="6">
                                        <span className="text-danger mr-2">
                                            <i style={{ fontSize: '65px' }} className="fa fa-arrow-up" />
                                        </span>
                                        <p style={{ marginTop: '10px', fontSize: '12px' }}>
                                            Transaksi Keluar
                                        </p>
                                    </Col> :
                                    <Col style={{ textAlign: 'center' }} sm="6">
                                        <span className="text-success mr-2">
                                            <i style={{ fontSize: '65px' }} className="fa fa-arrow-down" />
                                        </span>
                                        <p style={{ marginTop: '10px', fontSize: '12px' }}>
                                            Transaksi Masuk
                                        </p>
                                    </Col>
                            }
                        </Row>
                    </>
                )
        }
    }
    function buildTransactionPaymentMethod(data) {
        switch (data.transaction_for) {
            case 'topup':
                return (
                    <>
                        <Row>
                            <Col style={{ textAlign: 'center' }} sm="12">
                                <img src={`${BASE_URL}/api/images${data.bank_list.bank_image}`} style={{
                                    width: '50%',
                                    objectFit: 'fill',
                                    height: '65px',
                                    display: 'inline-block'
                                }} />
                                <p style={{ marginTop: '10px', fontWeight: 'bold', fontSize: '16px' }}>
                                    {data.bank_list.name}
                                </p>
                            </Col>
                        </Row>
                    </>
                )
            case 'disbursement':
                return (
                    <>
                        <Row>
                            <Col style={{ textAlign: 'center' }} sm="12">
                                <img src={`${BASE_URL}/api/images${data.bank_list.bank_image}`} style={{
                                    width: '50%',
                                    objectFit: 'fill',
                                    height: '65px',
                                    display: 'inline-block'
                                }} />
                                <p style={{ marginTop: '10px', fontWeight: 'bold', fontSize: '16px' }}>
                                    {data.bank_list.name}
                                </p>
                            </Col>
                        </Row>
                    </>
                )
            case 'order':
                return (
                    <>
                        <Row>
                            <Col style={{ textAlign: 'center' }} sm="12">
                                <img src={`${BASE_URL}/api/images${data.order_transaction.sub_payment.icon}`} style={{
                                    width: '70px',
                                    objectFit: 'cover',
                                    borderRadius: '50%',
                                    height: '70px',
                                    display: 'inline-block'
                                }} />
                                <p style={{ marginTop: '10px', fontWeight: 'bold', fontSize: '16px' }}>
                                    {data.order_transaction.sub_payment.title}
                                </p>
                            </Col>
                        </Row>
                    </>
                )
        }
    }
    function buildTransactionInfo(data) {
        switch (data.transaction_for) {
            case 'topup':
                return (
                    <Row>
                        <Col style={{ textAlign: 'center' }} lg="6">
                            <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="customer-complete-name"
                                >
                                    Fee Transaksi
                                </label>
                                <p style={{
                                    color: data.transaction_fee > 0 ? MaterialRed1 : MaterialGray3
                                }} id="customer-complete-name">
                                    {
                                        data.transaction_fee != null && data.transaction_fee > 0 ?
                                            `- ${formatRupiah(data.transaction_fee.toString(), 'Rp. ')}`
                                            :
                                            'Rp. 0'
                                    }
                                </p>
                            </FormGroup>
                        </Col>
                        <Col style={{ textAlign: 'center' }} lg="6">
                            <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="customer-email"
                                >
                                    Saldo Suberes Terakhir
                                </label>
                                <p id="customer-email">
                                    {
                                        data.last_amount != null ?
                                            formatRupiah(data.last_amount.toString(), 'Rp. ')
                                            :
                                            'Rp. 0'
                                    }
                                </p>
                            </FormGroup>
                        </Col>
                    </Row>
                )
            case 'disbursement':
                return (
                    <Row>
                        <Col style={{ textAlign: 'center' }} lg="4">
                            <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="customer-complete-name"
                                >
                                    Fee Transaksi
                                </label>
                                <p style={{
                                    color: data.transaction_fee > 0 ? MaterialRed1 : MaterialGray3
                                }} id="customer-complete-name">
                                    {
                                        data.transaction_fee != null && data.transaction_fee > 0 ?
                                            `- ${formatRupiah(data.transaction_fee.toString(), 'Rp. ')}`
                                            :
                                            'Rp. 0'
                                    }
                                </p>
                            </FormGroup>
                        </Col>
                        <Col style={{ textAlign: 'center' }} lg="4">
                            <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="customer-email"
                                >
                                    Saldo Suberes Terakhir
                                </label>
                                <p id="customer-email">
                                    {
                                        data.last_amount != null ?
                                            formatRupiah(data.last_amount.toString(), 'Rp. ')
                                            :
                                            'Rp. 0'
                                    }
                                </p>
                            </FormGroup>
                        </Col>
                        <Col style={{ textAlign: 'center' }} lg="4">
                            <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="customer-phone-number"
                                >
                                    Nomor Rekening/E-Wallet
                                </label>
                                <p id="customer-phone-number">
                                    {
                                        data.account_number
                                    }
                                </p>
                            </FormGroup>
                        </Col>
                    </Row>
                )
            case 'order':
                return (
                    <Row>
                        <Col style={{ textAlign: 'center' }} lg="6">
                            <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="customer-complete-name"
                                >
                                    Fee Transaksi
                                </label>
                                <p style={{
                                    color: data.transaction_fee > 0 ? MaterialRed1 : MaterialGray3
                                }} id="customer-complete-name">
                                    {
                                        data.transaction_fee > 0 ?
                                            `- ${formatRupiah(data.transaction_fee.toString(), 'Rp. ')}`
                                            :
                                            'Rp. 0'
                                    }
                                </p>
                            </FormGroup>
                        </Col>
                        <Col style={{ textAlign: 'center' }} lg="6">
                            <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="customer-email"
                                >
                                    Saldo Suberes Terakhir
                                </label>
                                <p id="customer-email">
                                    {
                                        data.last_amount != null ?
                                            formatRupiah(data.last_amount.toString(), 'Rp. ')
                                            :
                                            'Rp. '
                                    }
                                </p>
                            </FormGroup>
                        </Col>
                    </Row>
                )
        }
    }
    function buildTransactionStatus(data) {
        switch (data.transaction_status) {
            case 'success':
                return (
                    <p style={{
                        color: MaterialGreen1
                    }}>Success</p>
                )
            case 'waiting':
                return (
                    <p style={{
                        color: MaterialYellow1
                    }}>Waiting</p>
                )
            case 'pending':
                return (
                    <p style={{
                        color: MaterialYellow2
                    }}>Pending</p>
                )
            case 'failed':
                return (
                    <p style={{
                        color: MaterialRed1
                    }}>Failed</p>
                )
        }
    }
    function buildTransactionDetail() {
        switch (props.transaction.payload.type) {
            case TRANSACTION_DETAIL_LOADING:
                return (
                    <>
                        <Container className="mt--9" fluid>
                            <Row>
                                <Col className="order-xl-1" xl="12">
                                    <Card style={{ paddingBottom: '30px' }} className="bg-secondary-shadow">
                                        <Row>
                                            <Col className="order-xl-1" xl="12">
                                                <div style={{ padding: '30px', marginTop: '10px' }}>
                                                    <Row>
                                                        <Col style={{ textAlign: 'center' }} md="6">
                                                            <div style={{ width: '100%', textAlign: 'center' }}>
                                                                <Skeleton style={{ width: '25%', backgroundColor: MaterialGray1, height: '20px ' }}></Skeleton>
                                                            </div>
                                                            <div style={{ width: '100%', marginTop: '10px', textAlign: 'center' }}>
                                                                <Skeleton style={{
                                                                    width: '100px',
                                                                    height: '100px',
                                                                    borderRadius: '50%',
                                                                    backgroundColor: MaterialGray1
                                                                }}></Skeleton>
                                                            </div>
                                                            <div style={{ width: '100%', marginTop: '10px', textAlign: 'center' }}>
                                                                <Skeleton style={{ width: '25%', backgroundColor: MaterialGray1, height: '20px' }}></Skeleton>
                                                            </div>
                                                            <div style={{ width: '100%', marginTop: '30px', textAlign: 'center' }}>
                                                                <Skeleton style={{ width: '25%', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                            <div style={{ width: '100%', marginTop: '20px', textAlign: 'center' }}>
                                                                <Skeleton style={{ width: '25%', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                        </Col>
                                                        <Col style={{ textAlign: 'center' }} md="6">
                                                            <div style={{ width: '100%', textAlign: 'center' }}>
                                                                <Skeleton style={{ width: '25%', backgroundColor: MaterialGray1, height: '20px ' }}></Skeleton>
                                                            </div>
                                                            <div style={{ width: '100%', marginTop: '10px', textAlign: 'center' }}>
                                                                <Skeleton style={{
                                                                    width: '100px',
                                                                    height: '100px',
                                                                    borderRadius: '50%',
                                                                    backgroundColor: MaterialGray1
                                                                }}></Skeleton>
                                                            </div>
                                                            <div style={{ width: '100%', marginTop: '10px', textAlign: 'center' }}>
                                                                <Skeleton style={{ width: '25%', backgroundColor: MaterialGray1, height: '20px' }}></Skeleton>
                                                            </div>
                                                            <div style={{ width: '100%', marginTop: '30px', textAlign: 'center' }}>
                                                                <Skeleton style={{ width: '25%', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                            <div style={{ width: '100%', marginTop: '20px', textAlign: 'center' }}>
                                                                <Skeleton style={{ width: '25%', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Skeleton style={{ width: '100%', height: '1px', backgroundColor: MaterialGray1 }}></Skeleton>
                                        <Row>
                                            <Col style={{ textAlign: 'center' }} md="4">
                                                <div style={{ width: '100%', textAlign: 'center' }}>
                                                    <Skeleton style={{ width: '35%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                </div>
                                                <div style={{ width: '100%', textAlign: 'center' }}>
                                                    <Skeleton style={{ width: '35%', height: '100px', marginTop: '25px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                </div>
                                            </Col>
                                            <Col style={{ textAlign: 'center' }} md="8">
                                                <div style={{ width: '100%', textAlign: 'center' }}>
                                                    <Skeleton style={{ width: '35%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                </div>
                                                <div style={{ width: '90%', marginTop: '20px', textAlign: 'center' }}>
                                                    <Skeleton style={{ width: '100%', height: '100px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card>
                                    <Card style={{ marginTop: '10px' }} className="bg-secondary-shadow">
                                        <CardBody>
                                            <Form>
                                                <div className="align-items-center row">
                                                    <div className="col-8">
                                                        <Skeleton style={{ width: '35%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                    </div>
                                                </div>
                                                <div style={{ marginTop: '20px' }}>
                                                    <Row>
                                                        <Col lg="4">
                                                            <div style={{ width: '55%' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                            <div style={{ width: '65%', marginTop: '15px' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                        </Col>

                                                        <Col lg="4">
                                                            <div style={{ width: '55%' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                            <div style={{ width: '65%', marginTop: '15px' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                        </Col>

                                                        <Col lg="4">
                                                            <div style={{ width: '55%' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                            <div style={{ width: '65%', marginTop: '15px' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row style={{ marginTop: "20px" }}>
                                                        <Col lg="4">
                                                            <div style={{ width: '55%' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                            <div style={{ width: '65%', marginTop: '15px' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                        </Col>

                                                        <Col lg="4">
                                                            <div style={{ width: '55%' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                            <div style={{ width: '65%', marginTop: '15px' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                        </Col>

                                                        <Col lg="4">
                                                            <div style={{ width: '55%' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                            <div style={{ width: '65%', marginTop: '15px' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row style={{ marginTop: "20px" }}>
                                                        <Col lg="4">
                                                            <div style={{ width: '55%' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                            <div style={{ width: '65%', marginTop: '15px' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                        </Col>

                                                        <Col lg="4">
                                                            <div style={{ width: '55%' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                            <div style={{ width: '65%', marginTop: '15px' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Form>
                                        </CardBody>
                                    </Card>
                                    <Card style={{ marginTop: '10px' }} className="bg-secondary-shadow">
                                        <CardBody>
                                            <Form>
                                                <div className="align-items-center row">
                                                    <div className="col-8">
                                                        <Skeleton style={{ width: '35%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                    </div>
                                                </div>
                                                <div style={{ marginTop: '20px' }}>
                                                    <Row>
                                                        <Col lg="4">
                                                            <div style={{ width: '55%' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                            <div style={{ width: '65%', marginTop: '15px' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                        </Col>

                                                        <Col lg="4">
                                                            <div style={{ width: '55%' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                            <div style={{ width: '65%', marginTop: '15px' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                        </Col>

                                                        <Col lg="4">
                                                            <div style={{ width: '55%' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                            <div style={{ width: '65%', marginTop: '15px' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row style={{ marginTop: "20px" }}>
                                                        <Col lg="4">
                                                            <div style={{ width: '55%' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                            <div style={{ width: '65%', marginTop: '15px' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                        </Col>

                                                        <Col lg="4">
                                                            <div style={{ width: '55%' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                            <div style={{ width: '65%', marginTop: '15px' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                        </Col>

                                                        <Col lg="4">
                                                            <div style={{ width: '55%' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                            <div style={{ width: '65%', marginTop: '15px' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row style={{ marginTop: "20px" }}>
                                                        <Col lg="4">
                                                            <div style={{ width: '55%' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                            <div style={{ width: '65%', marginTop: '15px' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                        </Col>

                                                        <Col lg="4">
                                                            <div style={{ width: '55%' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                            <div style={{ width: '65%', marginTop: '15px' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Form>
                                        </CardBody>
                                    </Card>
                                    <Card style={{ marginTop: '10px' }} className="bg-secondary-shadow">
                                        <CardBody>
                                            <Form>
                                                <div className="align-items-center row">
                                                    <div className="col-8">
                                                        <Skeleton style={{ width: '35%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                    </div>
                                                </div>
                                                <div style={{ marginTop: '20px' }}>
                                                    <Row>
                                                        <Col lg="4">
                                                            <div style={{ width: '55%' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                            <div style={{ width: '65%', marginTop: '15px' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                        </Col>

                                                        <Col lg="4">
                                                            <div style={{ width: '55%' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                            <div style={{ width: '65%', marginTop: '15px' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                        </Col>

                                                        <Col lg="4">
                                                            <div style={{ width: '55%' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                            <div style={{ width: '65%', marginTop: '15px' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row style={{ marginTop: "20px" }}>


                                                        <Col lg="4">
                                                            <div style={{ width: '55%' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                            <div style={{ width: '65%', marginTop: '15px' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row style={{ marginTop: "20px" }}>
                                                        <Col lg="4">
                                                            <div style={{ width: '55%' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                            <div style={{ width: '65%', marginTop: '15px' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                        </Col>

                                                        <Col lg="4">
                                                            <div style={{ width: '55%' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                            <div style={{ width: '65%', marginTop: '15px' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                        </Col>

                                                        <Col lg="4">
                                                            <div style={{ width: '55%' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                            <div style={{ width: '65%', marginTop: '15px' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row style={{ marginTop: "20px" }}>
                                                        <Col lg="4">
                                                            <div style={{ width: '55%' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                            <div style={{ width: '65%', marginTop: '15px' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                        </Col>

                                                        <Col lg="4">
                                                            <div style={{ width: '55%' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                            <div style={{ width: '65%', marginTop: '15px' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Form>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </>
                )
            case TRANSACTION_DETAIL_SUCCESSFUL:
                console.log(JSON.stringify(props.transaction.payload, null, 2))
                return (
                    <>
                        <Container className="mt--9" fluid>
                            <Row>
                                <Col className="order-xl-1" xl="12">
                                    <Card className="bg-secondary-shadow">
                                        <Row>
                                            <Col className="order-xl-1" xl="12">
                                                <div style={{ padding: '30px', marginTop: '10px' }}>
                                                    {buildUserProfile(props.transaction.payload.data)}
                                                    <Row style={{ marginTop: '20px' }}>
                                                        <Col style={{ height: '150px', position: 'relative' }} md="6">
                                                            <h4 style={{ textAlign: 'center' }}>Total Transaksi</h4>
                                                            <div style={{
                                                                alignItems: 'center',
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                width: '100%',
                                                                height: '80px'
                                                            }}>
                                                                <div style={{
                                                                    display: 'flex',
                                                                    position: 'absolute'
                                                                }}>
                                                                    <div
                                                                        style={{
                                                                            backgroundColor: 'transparent',
                                                                            borderRadius: '10px',
                                                                            border: '1px solid white',
                                                                            paddingLeft: '20px',
                                                                            paddingRight: '20px',
                                                                            cursor: 'pointer'
                                                                        }}
                                                                    >
                                                                        <p style={{
                                                                            fontSize: '22px',
                                                                            fontWeight: 'bold',
                                                                            position: 'relative',
                                                                            top: '50%',
                                                                            left: '50%',
                                                                            color: MaterialGreen1,
                                                                            transform: 'translate(-50% , -50%)'
                                                                        }}>
                                                                            {
                                                                                formatRupiah(
                                                                                    props.transaction.payload.data.transaction_amount !== null &&
                                                                                        props.transaction.payload.data.transaction_amount !== '' ?
                                                                                        props.transaction.payload.data.transaction_amount.toString() :
                                                                                        '0',
                                                                                    'Rp. '
                                                                                )
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md="6">
                                                            <FormGroup>
                                                                <h4 style={{ textAlign: 'center' }}>ID Transaksi</h4>
                                                                <div style={{
                                                                    alignItems: 'center',
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    width: '100%',
                                                                    height: '80px'
                                                                }}>
                                                                    <div style={{
                                                                        display: 'flex',
                                                                        position: 'absolute'
                                                                    }}>
                                                                        <div
                                                                            style={{
                                                                                backgroundColor: 'transparent',
                                                                                borderRadius: '10px',
                                                                                border: '1px solid white',
                                                                                paddingLeft: '20px',
                                                                                paddingRight: '20px',
                                                                                cursor: 'pointer'
                                                                            }}
                                                                        >
                                                                            <p style={{
                                                                                fontSize: '22px',
                                                                                fontWeight: 'bold',
                                                                                position: 'relative',
                                                                                top: '50%',
                                                                                left: '50%',
                                                                                color: MaterialGreen1,
                                                                                transform: 'translate(-50% , -50%)'
                                                                            }}>
                                                                                {
                                                                                    buildTransactionId(props.transaction.payload.data)
                                                                                }
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col style={{ textAlign: 'center' }} md="6">
                                                            <h5>Tipe Transaksi</h5>
                                                            <div style={{ marginTop: '20px' }}>
                                                                {buildTransactionType(props.transaction.payload.data)}
                                                            </div>
                                                        </Col>
                                                        <Col style={{ textAlign: 'center' }} md="6">
                                                            <h5>Metode Pembayaran</h5>
                                                            <div style={{ marginTop: '20px' }}>
                                                                {buildTransactionPaymentMethod(props.transaction.payload.data)}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card>
                                    <Card style={{ marginTop: '10px' }} className="bg-secondary-shadow">
                                        <CardBody>
                                            <Form>
                                                <div style={{ marginTop: '20px' }}>
                                                    {buildTransactionInfo(props.transaction.payload.data)}
                                                    <Row style={{ marginTop: '30px' }}>
                                                        <Col style={{ textAlign: 'center' }} lg="6">
                                                            <FormGroup>
                                                                <label
                                                                    className="form-control-label"
                                                                    htmlFor="customer-address"
                                                                >
                                                                    Status Transaksi
                                                                </label>
                                                                {buildTransactionStatus(props.transaction.payload.data)}
                                                            </FormGroup>
                                                        </Col>
                                                        <Col style={{ textAlign: 'center' }} lg="6">
                                                            <FormGroup>
                                                                <label
                                                                    className="form-control-label"
                                                                    htmlFor="customer-method-payment"
                                                                >
                                                                    Kode Gagal Transaksi
                                                                </label>
                                                                <p id="customer-method-payment">
                                                                    {
                                                                        props.transaction.payload.data.failure_code != null ?
                                                                            props.transaction.payload.data.failure_code :
                                                                            '-'
                                                                    }
                                                                </p>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Form>
                                        </CardBody>
                                    </Card>
                                    <Card style={{ marginTop: '10px' }} className="bg-secondary-shadow">
                                        <CardBody>
                                            <Form>
                                                <div style={{ marginTop: '20px' }}>
                                                    <Row>
                                                        <Col style={{ textAlign: 'center' }} lg="6">
                                                            <FormGroup>
                                                                <label
                                                                    className="form-control-label"
                                                                    htmlFor="order-timestamp"
                                                                >
                                                                    Tanggal Transaksi Dibuat
                                                                </label>
                                                                <p id="order-timestamp">
                                                                    {
                                                                        props.transaction.payload.data.createdAt
                                                                    }
                                                                </p>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col style={{ textAlign: 'center' }} lg="6">
                                                            <FormGroup>
                                                                <label
                                                                    className="form-control-label"
                                                                    htmlFor="order-type"
                                                                >
                                                                    Deskripsi Transaksi
                                                                </label>
                                                                <p id="order-type">
                                                                    {
                                                                        props.transaction.payload.data.transaction_description
                                                                    }
                                                                </p>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Form>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </>
                )
            case TRANSACTION_DETAIL_NOT_FOUND:
                return (
                    <>
                        <Container className="mt--7" fluid>
                            <Row>
                                <Col className="order-xl-1" xl="12">
                                    <Card className="bg-secondary-shadow">
                                        <CardBody>
                                            <NotFoundPage
                                                imageSource={IcNotFound}
                                                imageText={props.transaction.payload.message} />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </>
                )
            case TRANSACTION_DETAIL_FAILED:
                return (
                    <>
                        <Container className="mt--7" fluid>
                            <Row>
                                <Col className="order-xl-1" xl="12">
                                    <Card className="bg-secondary-shadow">
                                        <CardBody>
                                            <ErrorPage
                                                imageSource={IcError}
                                                imageText={props.transaction.payload.message} />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </>
                )
        }
    }
    return (
        <>
            <div className="main-content">
                <div className="header bg-gradient-info pb-9 pt-5 pt-lg-7 d-flex align-items-center">
                    <span className="mask bg-gradient-default opacity-8" />
                </div>
                {buildTransactionDetail()}
            </div>
        </>
    )
}

TransactionDetailPage.propTypes = {
    transaction: PropTypes.object.isRequired,
    getTransactionDetail: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    transaction: state.transaction
})

export default connect(mapStateToProps, { getTransactionDetail })(TransactionDetailPage)