import Index from './components/Pages/index';
import MitraPage from './components/Pages/Mitra/MitraPage';
import CandidateMitra from './components/Pages/MitraCandidate/MitraCandidate';
import MitraDetailCandidate from 'components/Pages/MitraCandidate/MitraDetailCandidate';
import MitraDetailPage from './components/Pages/Mitra/MitraDetailPage';
import OrderPage from './components/Pages/Orders/OrderPage';
import OrderListPage from 'components/Pages/Orders/OrderListPage';
import OrderDetailPage from 'components/Pages/Orders/OrderDetailPage';
import BannerPage from 'components/Pages/Banners/BannerPage';
import BannerDetailPage from 'components/Pages/Banners/BannerDetailPage';
import BannerCreatePage from 'components/Pages/Banners/BannerCreatePage';
import ComplainPage from 'components/Pages/Complain/ComplainPage';
import TransactionPage from 'components/Pages/Transaction/TransactionPage';
import LayananPage from 'components/Pages/Layanan/LayananPage';
import LayananDetailPage from 'components/Pages/Layanan/LayananDetailPage'
import LayananCreateParentPage from 'components/Pages/Layanan/LayananCreateParentPage'
import LayananListServices from 'components/Pages/Layanan/LayananListServices';
import LayananUpdateParentPage from 'components/Pages/Layanan/LayananUpdateParentPage'
import ServiceDetailPage from 'components/Pages/Layanan/ServiceDetailPage'
import SchedulePage from 'components/Pages/Schedule/SchedulePage';

import { faBook, faBroom, faDesktop, faExclamationCircle, faImages, faNewspaper, faMap, faMoneyBill1Wave, faReceipt, faCalendar, faUserGroup, faQuestionCircle, faMoneyBillWave, faShieldHalved } from '@fortawesome/free-solid-svg-icons';
import NewsPage from 'components/Pages/News/NewsPage';
import NewsCreatePage from 'components/Pages/News/NewsCreatePage';
import NewsDetailPage from 'components/Pages/News/NewsDetailPage';
import HelpPage from 'components/Pages/Help/HelpPage';
import GuidePage from 'components/Pages/Guide/GuidePage';
import HelpCreatePage from 'components/Pages/Help/HelpCreatePage';
import HelpDetailPage from 'components/Pages/Help/HelpDetailPage';
import ComplainDetailPage from 'components/Pages/Complain/ComplainDetailPage';
import TransactionDetailPage from 'components/Pages/Transaction/TransactionDetailPage';
import TocPage from 'components/Pages/Toc/TocPage';
import TocCreatePage from 'components/Pages/Toc/TocCreatePage';
import TocUpdatePage from 'components/Pages/Toc/TocUpdatePage';
import GuideCreatePage from 'components/Pages/Guide/GuideCreatePage';
import GuideDetailPage from 'components/Pages/Guide/GuideDetailPage';

let routes = [
    {
        path : '/index',
        name : 'Dashboard',
        title : 'Dashboard',
        icon : faDesktop,
        icon_color : '#42a5f5',
        component : Index,
        isSidebar : true,
        layout : '/admin'
    },
    {
      path : '/candidate_mitra_page',
      name : 'Calon Mitra',
      title : 'Daftar Calon Mitra',
      icon : faUserGroup,
      icon_color : '#ffca28',
      component : CandidateMitra,
      isSidebar : true,
      layout : '/admin'
    },
    {
      path : '/mitra_page',
      name : 'Daftar Mitra',
      title : 'Daftar Mitra',
      icon : faUserGroup,
      icon_color : '#66bb6a',
      component : MitraPage,
      isSidebar : true,
      layout : '/admin'
    },
    {
      path : '/layanan',
      name : 'Layanan',
      title : 'Daftar Layanan',
      icon : faBroom,
      icon_color : '#66bb6a',
      component : LayananPage ,
      isSidebar : true,
      layout : '/admin'
    },
    {
      path : '/order_page',
      name : 'Halaman Order',
      title : 'Halaman Order',
      icon : faBook,
      icon_color : '#66bb6a',
      component : OrderPage,
      isSidebar : true,
      layout : '/admin'
    },
    {
      path : '/transactions',
      name : 'Transaksi',
      title : 'Daftar Transaksi',
      icon : faMoneyBill1Wave,
      icon_color : '#43a047',
      component : TransactionPage,
      isSidebar : true,
      layout : '/admin'
    },
    {
      path : '/transaction_detail',
      name : 'Rincian Transaksi',
      title : 'Rincian Transaksi',
      icon : faMoneyBillWave,
      icon_color : '',
      component : TransactionDetailPage,
      isSidebar : false,
      layout : '/admin'
    },
    {
      path : '/schedule_list',
      name : 'Daftar Jadwal',
      title : 'Daftar Jadwal',
      icon : faCalendar,
      icon_color : '#66bb6a',
      component : SchedulePage,
      isSidebar : true,
      layout : '/admin'
    },
    {
      path : '/order_list_page',
      name : 'Daftar Orderan',
      title : 'Daftar Orderan',
      icon : faReceipt,
      icon_color : '#66bb6a',
      component : OrderListPage,
      isSidebar : false,
      layout : '/admin'
    },
    {
      path : '/order_detail_page',
      name : 'Rincian Order',
      title : 'Rincian Order',
      icon : faReceipt,
      icon_color : '#66bb6a',
      component : OrderDetailPage,
      isSidebar : false,
      layout : '/admin'
    },
    {
      path : '/mitra_detail_candidate_page',
      name : 'Mitra Detail Candidate',
      title : 'Rincian Kandidat Mitra',
      icon : 'fas fa-user',
      icon_color : '',
      component : MitraDetailCandidate,
      isSidebar : false,
      layout : '/admin'
    },
    {
      path : '/mitra_detail_page',
      name : 'Mitra Detail',
      title : 'Rincian Mitra',
      icon : 'fas fa-user',
      component : MitraDetailPage,
      isSidebar : false ,
      layout : '/admin'
    },
    {
      path : '/banners',
      name : 'Banner',
      title : 'Daftar Banner',
      icon : faImages,
      icon_color : '#26c6da',
      component : BannerPage ,
      isSidebar : true,
      layout : '/admin'
    },
    {
      path : '/banner_detail_page',
      name : 'Banner Detail',
      title : 'Rincian Banner',
      icon : faImages,
      icon_color : '',
      component : BannerDetailPage,
      isSidebar : false,
      layout : '/admin'
    },
    {
      path : '/banner_create_page',
      name : 'Banner Create',
      title : 'Buat Banner',
      icon : faImages,
      icon_color : '',
      component : BannerCreatePage,
      isSidebar : false,
      layout : '/admin'
    },
    {
      path : '/news',
      name : 'News',
      title : 'Daftar Berita',
      icon : faNewspaper,
      icon_color : '#26c6da',
      component : NewsPage,
      isSidebar : true,
      layout : '/admin'
    },
    {
      path : '/toc',
      name : 'Terms Condition',
      title : 'Terms of Condition',
      icon : faShieldHalved,
      icon_color : '#26c6da',
      component : TocPage,
      isSidebar : true,
      layout : '/admin'
    },
    {
      path : '/toc_create',
      name : 'Terms Condition',
      title : 'Buat TOC baru',
      icon : faShieldHalved,
      icon_color : '',
      component : TocCreatePage,
      isSidebar : false,
      layout : '/admin'
    },
    {
      path : '/toc_update',
      name : 'Terms Condition',
      title : 'Perbarui TOC',
      icon : faShieldHalved,
      icon_color : '',
      component : TocUpdatePage,
      isSidebar : false,
      layout : '/admin'
    },
    {
      path : '/news_detail',
      name : 'News Detail',
      title : 'Detail Berita',
      icon : faNewspaper,
      icon_color : '',
      component : NewsDetailPage,
      isSidebar : false,
      layout : '/admin'
    },
    {
      path : '/help_page',
      name : 'Bantuan',
      title : 'Halaman Bantuan',
      icon : faQuestionCircle,
      icon_color : '#66bb6a',
      component : HelpPage,
      isSidebar : true,
      layout : '/admin'
    },
    {
      path : '/guide_create_page',
      name : 'Panduan',
      title : 'Buat Panduan',
      icon : faBook,
      icon_color : '',
      component : GuideCreatePage,
      isSidebar : false,
      layout : '/admin'
    },
    {
      path : '/guide_detail_page',
      name : 'Panduan',
      title : 'Ubah Panduan',
      icon : faBook,
      icon_color : '',
      component : GuideDetailPage,
      isSidebar : false,
      layout : '/admin'
    },
    {
      path : '/guide_page',
      name : 'Panduan',
      title : 'Halaman Panduan',
      icon : faBook,
      icon_color : '#66bb6a',
      component : GuidePage,
      isSidebar : true,
      layout : '/admin'
    },
    {
      path : '/help_create_page',
      name : 'Halaman Bantuan',
      title : 'Buat Bantuan',
      icon : '',
      icon_color : '',
      component : HelpCreatePage,
      isSidebar : false,
      layout : '/admin'
    },
    {
      path : '/help_detail_page',
      name : 'Halaman Bantuan',
      title : 'Detail Bantuan',
      icon : '',
      icon_color : '',
      component : HelpDetailPage,
      isSidebar : false,
      layout : '/admin'
    },
    {
      path : '/complain_detail_page',
      name : 'Halaman Keluhan',
      title : 'Detail Keluhan',
      icon : '',
      icon_color : '',
      component : ComplainDetailPage,
      isSidebar : false,
      layout : '/admin'
    },
    {
      path : '/news_create',
      name : 'News Create',
      title : 'Buat Berita',
      icon : faNewspaper,
      icon_color : '',
      component : NewsCreatePage,
      isSidebar : false,
      layout : '/admin'
    },
    {
      path : '/complain',
      name : 'Keluhan Pengguna',
      title : 'Daftar Keluhan Pengguna',
      icon : faExclamationCircle,
      icon_color : '#f44336',
      component : ComplainPage,
      isSidebar : true,
      layout : '/admin'
    },
    {
      path : '/layanan_detail',
      name : 'Layanan Detail',
      title : 'Layanan Detail',
      icon : faBroom,
      icon_color : '#66bb6a',
      component : LayananDetailPage,
      isSidebar : false,
      layout : '/admin'
    },
    {
      path : '/layanan_create_parent',
      name : 'Layanan Create',
      title : 'Buat Layanan',
      icon : faBroom,
      icon_color : '',
      component : LayananCreateParentPage,
      isSidebar : false,
      layout : '/admin'
    },
    {
      path : '/layanan_update_parent',
      name : 'Layanan Update',
      title : 'Perbarui Layanan',
      icon : faBroom,
      icon_color : '',
      component : LayananUpdateParentPage,
      isSidebar : false,
      layout : '/admin'
    },
    {
      path : '/layanan_list_service',
      name : 'List Service',
      title : 'Daftar Layanan',
      icon : '',
      icon_color : '',
      component : LayananListServices,
      isSidebar : false,
      layout : '/admin'
    },
    {
      path : '/service_detail',
      name : 'Service Detail',
      title : 'Detail Service',
      icon : '',
      icon_color : '',
      component : ServiceDetailPage,
      isSidebar : false ,
      layout : '/admin'
    }
]

export default routes