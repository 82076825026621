export const MITRA_LIST_LOADING = 'MITRA_LIST_LOADING';
export const MITRA_LIST_SUCCESSFUL = 'MITRA_LIST_SUCCESSFUL';
export const MITRA_LIST_NOT_FOUND = 'MITRA_LIST_NOT_FOUND';
export const MITRA_LIST_FAILED = 'MITRA_LIST_FAILED';

export const MITRA_DETAIL_LOADING = 'MITRA_DETAIL_LOADING';
export const MITRA_DETAIL_SUCCESSFUL = 'MITRA_DETAIL_SUCCESSFUL';
export const MITRA_DETAIL_NOT_FOUND = 'MITRA_DETAIL_NOT_FOUND';
export const MITRA_DETAIL_FAILED = 'MITRA_DETAIL_FAILED';

export const MITRA_CANDIDATE_LOADING = 'MITRA_CANDIDATE_LOADING';
export const MITRA_CANDIDATE_SUCCESSFUL = 'MITRA_CANDIDATE_SUCCESSFUL';
export const MITRA_CANDIDATE_NOT_FOUND = 'MITRA_CANDIDATE_NOT_FOUND';
export const MITRA_CANDIDATE_FAILED = 'MITRA_CANDIDATE_FAILED';

export const UPDATE_MITRA_CANDIDATE_LOADING = 'UPDATE_MITRA_CANDIDATE_LOADING';
export const UPDATE_MITRA_CANDIDATE_SUCCESSFUL = 'UPDATE_MITRA_CANDIDATE_SUCCESSFUL';
export const UPDATE_MITRA_CANDIDATE_FAILED = 'UPDATE_MITRA_CANDIDATE_FAILED';

export const UPDATE_MITRA_LOADING = 'UPDATE_MITRA_LOADING';
export const UPDATE_MITRA_SUCCESSFUL = 'UPDATE_MITRA_SUCCESSFUL';
export const UPDATE_MITRA_NOT_FOUND = 'UPDATE_MITRA_NOT_FOUND';
export const UPDATE_MITRA_FAILED = 'UPDATE_MITRA_FAILED';

export const INVITE_TRAINING_LOADING = 'INVITE_TRAINING_LOADING';
export const INVITE_TRAINING_SUCCESSFUL = 'INVITE_TRAINING_SUCCESSFUL';
export const INVITE_TRAINING_FAILED = 'INVITE_TRAINING_FAILED';

export const UPDATE_TRAINING_LOADING = 'UPDATE_TRAINING_LOADING';
export const UPDATE_TRAINING_SUCCESSFUL = 'UPDATE_TRAINING_SUCCESSFUL';
export const UPDATE_TRAINING_FAILED = 'UPDATE_TRAINING_FAILED';

export const ACCEPT_MITRA_LOADING = 'ACCEPT_MITRA_LOADING';
export const ACCEPT_MITRA_SUCCESSFUL = 'ACCEPT_MITRA_SUCCESSFUL';
export const ACCEPT_MITRA_FAILED = 'ACCEPT_MITRA_FAILED';

export const SUSPEND_MITRA_LOADING = 'SUSPEND_MITRA_LOADING';
export const SUSPEND_MITRA_SUCCESSFUL = 'SUSPEND_MITRA_SUCCESSFUL';
export const SUSPEND_MITRA_FAILED = 'SUSPEND_MITRA_FAILED';

export const ACTIVE_MITRA_LOADING = 'ACTIVE_MITRA_LOADING';
export const ACTIVE_MITRA_SUCCESSFUL = 'ACTIVE_MITRA_SUCCESSFUL';
export const ACTIVE_MITRA_FAILED = 'ACTIVE_MITRA_FAILED';

export const RESET_UPDATE = 'RESET_UPDATE'