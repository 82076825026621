import { connect } from "react-redux";
import PropTypes from 'prop-types';
import {
    Container,
    Card,
    Row,
    Col,
    CardBody,
    Modal,
    ModalBody,
    Form,
    Input,
    ModalHeader
} from 'reactstrap';
import Skeleton from "react-loading-skeleton";
import { MaterialGray1 } from "components/Utilities/ResourceColors";
import { getLayananDetail, removeParent } from "actions/LayananService/LayananService";
import { useEffect, useReducer, useState } from "react";
import { LAYANAN_DETAIL_LOADING } from "actions/LayananService/Types";
import { LAYANAN_DETAIL_SUCCESSFUL } from "actions/LayananService/Types";
import { LAYANAN_DETAIL_NOT_FOUND } from "actions/LayananService/Types";
import { LAYANAN_DETAIL_FAILED } from "actions/LayananService/Types";
import {
    PARENT_REMOVE_LOADING,
    PARENT_REMOVE_SUCCESSFUL,
    PARENT_REMOVE_UNAUTHORIZED,
    PARENT_REMOVE_NOT_FOUND,
    PARENT_REMOVE_FAILED
} from 'actions/LayananService/Types'
import { BASE_URL } from "actions/config";
import { useHistory } from "react-router-dom";
import NotFoundPage from "components/NotFound/NotFoundPage";
import IcBroom from '../../../assets/img/ic_broom.svg';
import ErrorPage from "components/ErrorComponent/ErrorPage";
import IcError from 'assets/img/ic_error.svg';
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { RED_ERROR_MESSAGE } from "components/Utilities/ResourceColors";
import { validateEmpty } from "components/Utilities/Utility";
import { MaterialRed1 } from "components/Utilities/ResourceColors";

const MySwal = withReactContent(Swal)

const LayananDetailPage = (props) => {
    const history = useHistory()
    const [token, setToken] = useState('')
    const [isSubmit, setSubmit] = useState(false)
    const [showModalRemove, setShowModalRemove] = useState(false)
    const handleOpenCloseModalRemove = () => setShowModalRemove(!showModalRemove)
    const [isLoading, setLoading] = useState(false)

    const [inputValues, setInputValues] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            remove_parent_id: -1,
            remove_parent_name: '',
            remove_parent_name_confirm: '',
            remove_parent_password_confirm: ''
        }
    )

    const onChangeHandler = event => {
        const { name, value } = event.target
        setInputValues({ [name]: value })
    }

    function removeParent() {
        if (token !== '' &&
            validateEmpty(inputValues.remove_parent_id) &&
            validateEmpty(inputValues.remove_parent_name) &&
            validateEmpty(inputValues.remove_parent_name_confirm) &&
            validateEmpty(inputValues.remove_parent_password_confirm) &&
            inputValues.remove_parent_name === inputValues.remove_parent_name_confirm) {
            props.removeParent(inputValues.remove_parent_id, inputValues.remove_parent_password_confirm, token)
        }
    }
    useEffect(() => {
        setToken(localStorage.getItem('token'))
        if(token !== ''){
            props.getLayananDetail(props.location.state.layanan_id , token)
        }
    }, [token])
    useEffect(() => {
        buildLayananDetailPage()
    }, [props.layanan])

    useEffect(() => {
        switch (props.layanan.payload_crud.payload.type) {
            case PARENT_REMOVE_LOADING:
                setLoading(true)
                break;
            case PARENT_REMOVE_SUCCESSFUL:
                setInputValues({
                    remove_parent_id: -1,
                    remove_parent_name: '',
                    remove_parent_name_confirm: '',
                    remove_parent_password_confirm: ''
                })
                setShowModalRemove(false)
                setLoading(false)
                if(token !== ''){
                    props.getLayananDetail(props.location.state.layanan_id , token)
                }
                break;
            case PARENT_REMOVE_UNAUTHORIZED:
                setLoading(false)
                MySwal.fire(
                    'Perhatian',
                    'Password salah',
                    'warning'
                )
                break;
            case PARENT_REMOVE_NOT_FOUND:
                setLoading(false)
                MySwal.fire(
                    'Perhatian',
                    'Data layanan tidak ada',
                    'error'
                )
                break;
            case PARENT_REMOVE_FAILED:
                setLoading(false)
                MySwal.fire(
                    'Perhatian',
                    'Gagal menghapus data layanan',
                    'error'
                )
                break;
        }
        console.log(`Reducer : ${JSON.stringify(props.layanan.payload_crud, null, 2)}`)
    }, [props.layanan.payload_crud])

    function buildLayananDetailPage() {
        switch (props.layanan.payload.type) {
            case LAYANAN_DETAIL_LOADING: {
                return (
                    <div style={{ marginTop: '15px' }}>
                        <Row>
                            <Col md="4">
                                <Skeleton style={{ width: '100%', height: '100px', backgroundColor: MaterialGray1 }} />
                            </Col>
                            <Col md="4">
                                <Skeleton style={{ width: '100%', height: '100px', backgroundColor: MaterialGray1 }} />
                            </Col>
                            <Col md="4">
                                <Skeleton style={{ width: '100%', height: '100px', backgroundColor: MaterialGray1 }} />
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '20px' }}>
                            <Col md="4">
                                <Skeleton style={{ width: '100%', height: '100px', backgroundColor: MaterialGray1 }} />
                            </Col>
                            <Col md="4">
                                <Skeleton style={{ width: '100%', height: '100px', backgroundColor: MaterialGray1 }} />
                            </Col>
                            <Col md="4">
                                <Skeleton style={{ width: '100%', height: '100px', backgroundColor: MaterialGray1 }} />
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '20px' }}>
                            <Col md="4">
                                <Skeleton style={{ width: '100%', height: '100px', backgroundColor: MaterialGray1 }} />
                            </Col>
                            <Col md="4">
                                <Skeleton style={{ width: '100%', height: '100px', backgroundColor: MaterialGray1 }} />
                            </Col>
                            <Col md="4">
                                <Skeleton style={{ width: '100%', height: '100px', backgroundColor: MaterialGray1 }} />
                            </Col>
                        </Row>
                    </div>
                )
            }
            case LAYANAN_DETAIL_SUCCESSFUL: {
                return (
                    <>
                        <div>
                            <Row>
                                {
                                    props.layanan.payload.data.parent_services.map((item) => {
                                        return (
                                            <Col style={{ marginTop: '20px' }} md="4">
                                                <Card className="shadow">
                                                    <div style={{
                                                        paddingTop: '25px',
                                                        paddingBottom: '25px'
                                                    }}>
                                                        <img src={`${BASE_URL}/api/images/layanan_image/${item.image_banner_service}`} style={{ width: '100%', height: '150px', objectFit: 'cover' }} />
                                                        <p style={{
                                                            marginTop: '20px',
                                                            textAlign: 'center',
                                                            border: `0.5px solid ${MaterialGray1}`,
                                                            borderRadius: '5px',
                                                            marginLeft: '25px',
                                                            marginRight: '25px',
                                                            paddingTop: '5px',
                                                            paddingBottom: '5px'
                                                        }}>
                                                            {item.title_parent_service}
                                                        </p>
                                                        <div style={{
                                                            paddingRight: '20px',
                                                            paddingLeft: '20px',
                                                            marginTop: '20px'
                                                        }}>
                                                            <Row>
                                                                <Col lg="12">
                                                                    <button onClick={() => {
                                                                        history.push({
                                                                            pathname: '/admin/layanan_list_service',
                                                                            state: {
                                                                                layanan_id: props.location.state.layanan_id,
                                                                                parent_id: item.id
                                                                            }
                                                                        })
                                                                    }} style={{ width: '100%' }} className="btn btn-md btn-success">
                                                                        Lihat Layanan
                                                                    </button>
                                                                </Col>
                                                            </Row>
                                                            <div style={{ marginTop: '10px' }} className="row">
                                                                <div className="col-md-6 col-sm-6 text-left">
                                                                    <div className="col-xs-12 text-center">
                                                                        <button onClick={() => {
                                                                            history.push({
                                                                                pathname: '/admin/layanan_update_parent',
                                                                                state: {
                                                                                    parent_id: item.id,
                                                                                    layanan_id: props.location.state.layanan_id
                                                                                }
                                                                            })
                                                                        }} className="btn btn-md btn-primary" style={{ width: '100%', marginBottom: '10px' }}>
                                                                            Perbarui
                                                                        </button>
                                                                    </div>
                                                                </div>

                                                                <div class="col-md-6 col-sm-6 text-right">
                                                                    <div className="col-xs-12 text-center">
                                                                        <button onClick={() => {
                                                                            setInputValues({
                                                                                remove_parent_id: item.id,
                                                                                remove_parent_name: item.title_parent_service
                                                                            })
                                                                            setShowModalRemove(true)
                                                                        }} className="btn btn-md btn-danger" style={{ width: '100%', marginBottom: '10px' }}>
                                                                            Hapus
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Card>
                                            </Col>
                                        )
                                    })
                                }
                            </Row>
                        </div>
                    </>
                )
            }
            case LAYANAN_DETAIL_NOT_FOUND: {
                return (
                    <NotFoundPage imageSource={IcBroom} imageText={props.layanan.payload.message} />
                )
            }
            case LAYANAN_DETAIL_FAILED: {
                return (
                    <ErrorPage imageSource={IcError} imageText={props.layanan.payload.message} />
                )
            }
        }
    }

    return (
        <>
            <div className="main-content">
                <div className="header bg-gradient-info pb-8 pt-5 pt-lg-7 d-flex align-items-center">
                    <span className="mask bg-gradient-default opacity-8" />
                </div>
                <Container className="mt--7" fluid>
                    <Card className="bg-secondary shadow">
                        <CardBody>
                            {
                                props.layanan.payload.type !== LAYANAN_DETAIL_FAILED && props.layanan.payload.type !== LAYANAN_DETAIL_LOADING ?
                                    <Row>
                                        <Col md="10">
                                            <div style={{
                                                display: 'flex',
                                                alignItems: 'center'
                                            }}>
                                                <p style={{ color: 'black', fontSize: '18px' }}>
                                                    Nama Layanan : <strong>{props.location.state.layanan_title}</strong>
                                                </p>
                                            </div>
                                        </Col>
                                        <Col md="2">
                                            <button style={{ fontSize: '11px' }} onClick={() => {
                                                history.push({
                                                    pathname: '/admin/layanan_create_parent',
                                                    state: {
                                                        layanan_id: props.location.state.layanan_id
                                                    }
                                                })
                                            }} className="btn btn-sm btn-primary">+ Tambah Paket</button>
                                        </Col>
                                    </Row> : <div></div>
                            }
                            {buildLayananDetailPage()}
                            {
                                props.layanan.payload.type !== LAYANAN_DETAIL_FAILED && props.layanan.payload.type !== LAYANAN_DETAIL_LOADING ?
                                    <Modal isOpen={showModalRemove} closable={false} keyboard={false} centered
                                    >
                                        {
                                            isSubmit ? isLoading ?
                                                <div className="progress-bar">
                                                    <div className="progress-bar-value"></div>
                                                </div> :
                                                <div style={{ height: '4px' }}></div> : <div></div>
                                        }
                                        <ModalHeader toggle={handleOpenCloseModalRemove}>
                                            <h4>Hapus Layanan</h4>
                                        </ModalHeader>
                                        <ModalBody>
                                            <p>Kamu yakin ingin menghapus layanan <strong>{inputValues.remove_parent_name}?</strong>, ketik nama layanan yang ingin di hapus dan masukan kata sandi untuk konfirmasi</p>
                                            <Form onSubmit={(e) => {
                                                e.preventDefault()
                                                removeParent()
                                            }}>
                                                <Row>
                                                    <Col xl="12">
                                                        <Input
                                                            className="form-control-alternative"
                                                            type="text"
                                                            name="remove_parent_name_confirm"
                                                            placeholder="Ketik nama layanan di sini"
                                                            onChange={onChangeHandler}
                                                            value={inputValues.remove_parent_name_confirm}
                                                            style={{
                                                                border: inputValues.remove_parent_name === inputValues.remove_parent_name_confirm ? 'none' : `1px solid ${RED_ERROR_MESSAGE}`
                                                            }}
                                                            disabled={isLoading ? 'disabled' : ''} />
                                                    </Col>
                                                </Row>
                                                <Row style={{ marginTop: '8px' }}>
                                                    <Col xl="12">
                                                        <Input
                                                            className="form-control-alternative"
                                                            type="password"
                                                            name="remove_parent_password_confirm"
                                                            onChange={onChangeHandler}
                                                            placeholder="Ketik kata sandi di sini"
                                                            value={inputValues.remove_parent_password_confirm}
                                                            style={{
                                                                border: validateEmpty(inputValues.remove_parent_password_confirm) ? 'none' : `1px solid ${RED_ERROR_MESSAGE}`
                                                            }}
                                                            disabled={isLoading ? 'disabled' : ''} />
                                                    </Col>
                                                </Row>
                                                <Row style={{ marginTop: '8px' }}>
                                                    <Col xl="12">
                                                        <button style={{
                                                            width: '100%',
                                                            backgroundColor: ((inputValues.remove_parent_name === inputValues.remove_parent_name_confirm) && validateEmpty(inputValues.remove_parent_password_confirm)) ?
                                                                MaterialRed1 : MaterialGray1,
                                                            border: 'none'
                                                        }} className="btn btn-md btn-danger" disabled={(inputValues.remove_parent_name === inputValues.remove_parent_name_confirm) && validateEmpty(inputValues.remove_parent_password_confirm) ? isLoading ? 'disabled' : '' : 'disabled'}
                                                        >Hapus Layanan</button>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </ModalBody>
                                    </Modal> : <div></div>
                            }
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </>
    )
}

LayananDetailPage.propTypes = {
    layanan: PropTypes.object.isRequired,
    getLayananDetail: PropTypes.func.isRequired,
    removeParent: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    layanan: state.layanan
})

export default connect(mapStateToProps, { getLayananDetail, removeParent })(LayananDetailPage)