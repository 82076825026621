export const CREATE_PARENT_LOADING = 'CREATE_PARENT_LOADING';
export const CREATE_PARENT_SUCCESSFUL = 'CREATE_PARENT_SUCCESSFUL';
export const CREATE_PARENT_FAILED = 'CREATE_PARENT_FAILED';

export const PARENT_UPDATE_LOADING = 'PARENT_UPDATE_LOADING';
export const PARENT_UPDATE_SUCCESSFUL = 'PARENT_UPDATE_SUCCESSFUL';
export const PARENT_UPDATE_NOT_FOUND = 'PARENT_UPDATE_NOT_FOUND';
export const PARENT_UPDATE_FAILED = 'PARENT_UPDATE_FAILED';

export const PARENT_DETAIL_LOADING = 'PARENT_DETAIL_LOADING';
export const PARENT_DETAIL_SUCCESSFUL = 'PARENT_DETAIL_SUCCESSFUL';
export const PARENT_DETAIL_NOT_FOUND = 'PARENT_DETAIL_NOT_FOUND';
export const PARENT_DETAIL_FAILED = 'PARENT_DETAIL_FAILED';

export const PARENT_RESET_CRUD = 'PARENT_RESET_CRUD';