import { 
    SUB_SERVICE_ADD_CREATE_LOADING ,
    SUB_SERVICE_ADD_CREATE_SUCCESSFUL ,
    SUB_SERVICE_ADD_CREATE_FAILED ,
    SUB_SERVICE_ADD_UPDATE_LOADING ,
    SUB_SERVICE_ADD_UPDATE_SUCCESSFUL , 
    SUB_SERVICE_ADD_UPDATE_NOT_FOUND ,
    SUB_SERVICE_ADD_UPDATE_FAILED,
    SUB_SERVICE_ADD_REMOVE_LOADING,
    SUB_SERVICE_ADD_REMOVE_SUCCESSFUL,
    SUB_SERVICE_ADD_REMOVE_UNAUTHORIZED,
    SUB_SERVICE_ADD_REMOVE_NOT_FOUND,
    SUB_SERVICE_ADD_REMOVE_FAILED,

    SUB_SERVICE_ADD_RESET_CRUD
} from "actions/SubServiceAdditional/Types";

const initState = {
    sub_service_add_create : {
        type : null,
        message : ''
    },
    sub_service_add_update : {
        type : null,
        message : ''
    },
    sub_service_add_remove : {
        type : null,
        message : ''
    }
}

export default function(state = initState , action){
    switch(action.type){
        case SUB_SERVICE_ADD_CREATE_LOADING : {
            console.log({
                ...state ,
                sub_service_add_create : {
                    type : action.type,
                    message : action.message
                }
            })
            return {
                ...state ,
                sub_service_add_create : {
                    type : action.type,
                    message : action.message
                }
            }
        }
        case SUB_SERVICE_ADD_CREATE_SUCCESSFUL : {
            return {
                ...state ,
                sub_service_add_create : {
                    type : action.type ,
                    message : action.message,
                    returning_data : action.returning_data
                }
            }
        }
        case SUB_SERVICE_ADD_CREATE_FAILED : {
            return {
                ...state ,
                sub_service_add_create : {
                    type : action.type,
                    message : action.message
                }
            }
        }
        case SUB_SERVICE_ADD_UPDATE_LOADING : {
            return {
                ...state ,
                sub_service_add_update : {
                    type : action.type ,
                    message : action.message
                }
            }
        }
        case SUB_SERVICE_ADD_UPDATE_SUCCESSFUL : {
            return {
                ...state ,
                sub_service_add_update : {
                    type : action.type ,
                    message : action.message,
                    returning_data : action.returning_data
                }
            }
        }
        case SUB_SERVICE_ADD_UPDATE_NOT_FOUND : {
            return {
                ...state ,
                sub_service_add_update : {
                    type : action.type,
                    message : action.message
                }
            }
        }
        case SUB_SERVICE_ADD_UPDATE_FAILED : {
            return {
                ...state ,
                sub_service_add_update : {
                    type : action.type ,
                    message : action.message
                }
            }
        }
        case SUB_SERVICE_ADD_REMOVE_LOADING: {
            return {
                ...state,
                sub_service_add_remove : {
                    type : action.type,
                    message : action.message
                }
            }
        }
        case SUB_SERVICE_ADD_REMOVE_SUCCESSFUL : {
            return {
                ...state,
                sub_service_add_remove : {
                    type : action.type,
                    message : action.message
                }
            }
        }
        case SUB_SERVICE_ADD_REMOVE_UNAUTHORIZED : {
            return {
                ...state,
                sub_service_add_remove : {
                    type : action.type,
                    message : action.message
                }
            }
        }
        case SUB_SERVICE_ADD_REMOVE_NOT_FOUND : {
            return {
                ...state,
                sub_service_add_remove : {
                    type : action.type,
                    message : action.message
                }
            }
        }
        case SUB_SERVICE_ADD_REMOVE_FAILED : {
            return {
                ...state,
                sub_service_add_remove : {
                    type : action.type,
                    message : action.message
                }
            }
        }
        case SUB_SERVICE_ADD_RESET_CRUD:
            return {
                ...state,
                sub_service_add_create : {
                    type : null,
                    message : ''
                },
                sub_service_add_update : {
                    type : null,
                    message : ''
                },
                sub_service_add_remove : {
                    type : null,
                    message : ''
                }
            }
        default:
            return state
    }
}