import { BASE_URL } from 'actions/config';
import axios from 'axios';
import setAuthToken from 'setAuthToken';
import setCredentialData from 'setCredentialData';
import { LOGIN_EXIST, LOGIN_FAILED, LOGIN_LOADING, LOGIN_NOT_FOUND, LOGIN_SUCCESSFUL } from './Types';
import { UPDATE_TOKEN_LOADING , UPDATE_TOKEN_SUCCESSFUL , UPDATE_TOKEN_FAILED } from './Types';
import { LOGOUT_SUCCESSFUL , LOGOUT_FAILED } from './Types';
import { passingHeaderToken } from 'components/Utilities/Utility';

export const loginAdmin = (user) => async dispatch => {
    try {
        dispatch({
            type : LOGIN_LOADING,
            payload : {
                type : LOGIN_LOADING,
                message : 'Sedang mencoba masuk'
            }
        })
        const response = await axios.post(`${BASE_URL}/api/admin/login` , user , {
            headers : {
                'Content-Type' : 'application/json'
            }
        })
        setAuthToken(response.data.token)
        setCredentialData(response.data)
        dispatch({
            type : LOGIN_SUCCESSFUL,
            payload : {
                type : LOGIN_SUCCESSFUL,
                data : response
            }
        })
    } catch (err) {
        console.log(err)
        if(err.response !== undefined && err.response.status == 404){
            dispatch({
                type : LOGIN_NOT_FOUND,
                payload : {
                    type : LOGIN_NOT_FOUND,
                    message : 'Pengguna Tak Ditemukan'
                }
            })
        } else if(err.response !== undefined && err.response.status == 401){
            dispatch({
                type : LOGIN_EXIST,
                payload : {
                    type : LOGIN_EXIST,
                    message : 'Pengguna sudah login di perangkat lain'
                }
            })
        } else {
            dispatch({
                type : LOGIN_FAILED,
                payload : {
                    type : LOGIN_FAILED,
                    message : 'Ada Yang Salah Dengan Server'
                }
            })
        }
    }
}

export const logoutAdmin = (admin_id , token) => async dispatch => {
    try {
        dispatch({
            type : LOGOUT_SUCCESSFUL,
            payload : {
                type : LOGOUT_SUCCESSFUL,
                message : 'Berhasil keluar'
            }
        })
        await axios.delete(`${BASE_URL}/api/admin/logout/${admin_id}` , passingHeaderToken(token))
    } catch (err) {
        dispatch({
            type : LOGOUT_FAILED,
            payload : {
                type : LOGOUT_FAILED,
                message : 'Gagal keluar'
            }
        })
    }
}

export const updateFirebaseToken = (admin_id , firebase_token , token) => async dispatch => {
    try {
        dispatch({
            type : UPDATE_TOKEN_LOADING,
            payload : {
                message : 'Memperbarui token'
            }
        })
        await axios.post(`${BASE_URL}/api/admin/update_firebase_token/${admin_id}/${firebase_token}` , passingHeaderToken(token))
        dispatch({
            type : UPDATE_TOKEN_SUCCESSFUL,
            payload : {
                message : 'Berhasil Memperbarui token'
            }
        })
    } catch (err) {
        console.log(err)
        dispatch({
            type : UPDATE_TOKEN_FAILED,
            payload : {
                message : 'Gagal memperbarui token'
            }
        })
    }
}