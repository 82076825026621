export const SOCKET_SET = 'SOCKET_SET'
export const SOCKET_REMOVE = 'SOCKET_REMOVE'

export const INITIATE_SOCKET_ADMIN = "INITIATE_SOCKET_ADMIN"
export const REGISTER_SOCKET_ADMIN = 'REGISTER_SOCKET_ADMIN';
export const RESPONSE_SOCKET_ADMIN = 'RESPONSE_SOCKET_ADMIN';
export const MESSAGE_SOCKET_ADMIN = 'MESSAGE_SOCKET_ADMIN';

export const REJECTED_MITRA = 'REJECTED_MITRA';
export const ACCEPTED_MITRA = 'ACCEPTED_MITRA';
export const ORDER_TIMEOUT = 'ORDER_TIMEOUT';

export const NOTIFICATION_ORDER_RUNNING = 'NOTIFICATION_ORDER_RUNNING';
export const NOTIFICATION_ORDER_FINISH = 'NOTIFICATION_ORDER_FINISH';
export const NOTIFICATION_ORDER_CANCELED = 'NOTIFICATION_ORDER_CANCELED';
export const NOTIFICATION_ORDER_WAITING_FOR_SELECTED = 'NOTIFICATION_ORDER_WAITING_FOR_SELECTED';