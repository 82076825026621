import { BASE_URL } from 'actions/config';
import axios from 'axios';
import { passingHeaderToken } from 'components/Utilities/Utility';
import {
    SCHEDULE_LOADING,
    SCHEDULE_SUCCESSFUL,
    SCHEDULE_FAILED,
    SCHEDULE_NOT_FOUND,
    SCHEDULE_CREATE_LOADING,
    SCHEDULE_CREATE_SUCCESSFUL,
    SCHEDULE_CREATE_EXIST,
    SCHEDULE_CREATE_DATE_WARNING,
    SCHEDULE_CREATE_FAILED,
    SCHEDULE_UPDATE_LOADING,
    SCHEDULE_UPDATE_SUCCESSFUL,
    SCHEDULE_UPDATE_NOT_FOUND,
    SCHEDULE_UPDATE_DATE_WARNING,
    SCHEDULE_UPDATE_FAILED,
    SCHEDULE_REMOVE_LOADING,
    SCHEDULE_REMOVE_SUCCESSFUL,
    SCHEDULE_REMOVE_NOT_FOUND,
    SCHEDULE_REMOVE_UNAUTHORIZED,
    SCHEDULE_REMOVE_FAILED,
    SCHEDULE_DETAIL_LOADING,
    SCHEDULE_DETAIL_SUCCESSFUL,
    SCHEDULE_DETAIL_NOT_FOUND,
    SCHEDULE_DETAIL_FAILED,

    SCHEDULE_RESET_CRUD
} from './Types';

export const getSchedule = (page , limit , search , token) => async dispatch => {
    try {
        dispatch({
            type : SCHEDULE_LOADING,
            payload : {
                type : SCHEDULE_LOADING,
                message : 'Memuat data schedule'
            }
        })
        const response = await axios.get(`${BASE_URL}/api/schedules/index?page=${page}&limit=${limit}&search=${search}` , passingHeaderToken(token))
        if(response.data != null && response.data.rows_data.docs.length > 0){
            setTimeout(() => {
                dispatch({
                    type : SCHEDULE_SUCCESSFUL,
                    payload : {
                        type : SCHEDULE_SUCCESSFUL,
                        data : response.data
                    }
                })
            } , 300)
        } else {
            setTimeout(() => {
                dispatch({
                    type : SCHEDULE_NOT_FOUND,
                    payload : {
                        type : SCHEDULE_NOT_FOUND,
                        message : 'Data jadwal tidak ada.'
                    }
                })
            } , 300)
        }
    } catch (err) {
        if(err.response !== undefined && err.response.status == 404){
            setTimeout(() => {
                dispatch({
                    type : SCHEDULE_NOT_FOUND,
                    payload : {
                        type : SCHEDULE_NOT_FOUND,
                        message : 'Data jadwal tidak ada.'
                    }
                })
            } , 300)
        } else {
            setTimeout(() => {
                dispatch({
                    type : SCHEDULE_FAILED,
                    payload : {
                        type : SCHEDULE_FAILED,
                        message : 'Gagal memuat data jadwal'
                    }
                })
            } , 300)
        }
    }
}

export const getScheduleForMitra = (page , limit , search , token) => async dispatch => {
    try {
        dispatch({
            type : SCHEDULE_LOADING,
            payload : {
                type : SCHEDULE_LOADING,
                message : 'Memuat data schedule'
            }
        })
        const response = await axios.get(`${BASE_URL}/api/schedules/index/mitra?page=${page}&limit=${limit}&search=${search}` , passingHeaderToken(token))
        if(response.data != null && response.data.rows_data.docs.length > 0){
            setTimeout(() => {
                dispatch({
                    type : SCHEDULE_SUCCESSFUL,
                    payload : {
                        type : SCHEDULE_SUCCESSFUL,
                        data : response.data
                    }
                })
            } , 300)
        } else {
            setTimeout(() => {
                dispatch({
                    type : SCHEDULE_NOT_FOUND,
                    payload : {
                        type : SCHEDULE_NOT_FOUND,
                        message : 'Data jadwal tidak ada. Harap buat jadwal terlebih dahulu dengan Tipe Mitra Level'
                    }
                })
            } , 300)
        }
    } catch (err) {
        if(err.response !== undefined && err.response.status == 404){
            setTimeout(() => {
                dispatch({
                    type : SCHEDULE_NOT_FOUND,
                    payload : {
                        type : SCHEDULE_NOT_FOUND,
                        message : 'Data jadwal tidak ada. Harap buat jadwal terlebih dahulu dengan Tipe Mitra Level'
                    }
                })
            } , 300)
        } else {
            setTimeout(() => {
                dispatch({
                    type : SCHEDULE_FAILED,
                    payload : {
                        type : SCHEDULE_FAILED,
                        message : 'Gagal memuat data jadwal'
                    }
                })
            } , 300)
        }
    }
}

export const getScheduleDetail = (id , token) => async dispatch => {
    try {
        dispatch({
            type : SCHEDULE_DETAIL_LOADING,
            payload : {
                type : SCHEDULE_DETAIL_LOADING,
                message : 'Memuat data schedule'
            }
        })
        const response = await axios.get(`${BASE_URL}/api/schedules/detail/${id}` , passingHeaderToken(token))
        dispatch({
            type : SCHEDULE_DETAIL_SUCCESSFUL,
            payload : {
                type : SCHEDULE_DETAIL_SUCCESSFUL,
                data : response.data
            }
        })
    } catch (err) {
        if(err.response !== undefined && err.response.status == 404){
            dispatch({
                type : SCHEDULE_DETAIL_NOT_FOUND,
                payload : {
                    type : SCHEDULE_DETAIL_NOT_FOUND,
                    message : 'Data schedule tidak ada'
                }
            })
        } else {
            dispatch({
                type : SCHEDULE_DETAIL_FAILED,
                payload : {
                    type : SCHEDULE_DETAIL_FAILED,
                    message : 'Gagal memuat data schedule'
                }
            })
        }
    }
}

export const createSchedule = (payload , token) => async dispatch => {
    try {
        dispatch({
            type : SCHEDULE_CREATE_LOADING,
            payload : {
                type : SCHEDULE_CREATE_LOADING,
                message : 'Membuat data jadwal'
            }
        })
        await axios.post(`${BASE_URL}/api/schedules/create` , payload , passingHeaderToken(token))
        dispatch({
            type : SCHEDULE_CREATE_SUCCESSFUL,
            payload : {
                type : SCHEDULE_CREATE_SUCCESSFUL,
                message : 'Berhasil membuat data jadwal'
            }
        })
    } catch (err) {
        if(err.response !== undefined && err.response.status == 409){
            dispatch({
                type : SCHEDULE_CREATE_EXIST,
                payload : {
                    type : SCHEDULE_CREATE_EXIST,
                    message : 'Data jadwal sudah ada'
                }
            })
        } else if(err.response !== undefined && err.response.status == 403){
            dispatch({
                type : SCHEDULE_CREATE_DATE_WARNING,
                payload : {
                    type : SCHEDULE_CREATE_DATE_WARNING,
                    message : 'Tanggal jadwal harus 1 hari dari sekarang'
                }
            })
        } else {
            dispatch({
                type : SCHEDULE_CREATE_FAILED,
                payload : {
                    type : SCHEDULE_CREATE_FAILED,
                    message : 'Gagal membuat data jadwal'
                }
            })
        }
    }
}

export const updateSchedule = (id , payload , token) => async dispatch => {
    try {
        dispatch({
            type : SCHEDULE_UPDATE_LOADING,
            payload : {
                type : SCHEDULE_UPDATE_LOADING,
                message : 'Memperbarui data schedule'
            }
        })
        await axios.put(`${BASE_URL}/api/schedules/update/${id}` , payload , passingHeaderToken(token))
        dispatch({
            type : SCHEDULE_UPDATE_SUCCESSFUL,
            payload : {
                type : SCHEDULE_UPDATE_SUCCESSFUL,
                message : 'Berhasil memperbarui schedule'
            }
        })
    } catch (err) {
        console.log(err)
        if(err.response !== undefined && err.response.status == 404){
            dispatch({
                type : SCHEDULE_UPDATE_NOT_FOUND,
                payload : {
                    type : SCHEDULE_UPDATE_NOT_FOUND,
                    message : 'Data schedule tidak ada'
                }
            })
        } else if(err.response !== undefined && err.response.status == 403){
            dispatch({
                type : SCHEDULE_UPDATE_DATE_WARNING,
                payload : {
                    type : SCHEDULE_UPDATE_DATE_WARNING,
                    message : 'Tanggal jadwal harus 1 hari dari sekarang'
                }
            })
        } else {
            dispatch({
                type : SCHEDULE_UPDATE_FAILED,
                payload : {
                    type : SCHEDULE_UPDATE_FAILED,
                    message : 'Gagal memperbarui data schedule'
                }
            })
        }
    }
}

export const removeSchedule = (id , password , token) => async dispatch => {
    try {
        dispatch({
            type : SCHEDULE_REMOVE_LOADING,
            payload : {
                type : SCHEDULE_REMOVE_LOADING,
                message : 'Menghapus data schedule'
            }
        })
        await axios.delete(`${BASE_URL}/api/schedules/remove/${id}/${password}` , passingHeaderToken(token))
        dispatch({
            type : SCHEDULE_REMOVE_SUCCESSFUL,
            payload : {
                type : SCHEDULE_REMOVE_SUCCESSFUL,
                message : 'Berhasil menghapus data schedule'
            }
        })
    } catch (err) {
        if(err.response !== undefined && err.response.status == 401){
            dispatch({
                type : SCHEDULE_REMOVE_UNAUTHORIZED,
                payload : {
                    type : SCHEDULE_REMOVE_UNAUTHORIZED,
                    message : 'Tidak diizinkan'
                }
            })
        } else if(err.response !== undefined && err.response.status == 404){
            dispatch({
                type : SCHEDULE_REMOVE_NOT_FOUND,
                payload : {
                    type : SCHEDULE_REMOVE_NOT_FOUND,
                    message : 'Data schedule tidak ada'
                }
            })
        } else {
            dispatch({
                type : SCHEDULE_REMOVE_FAILED,
                payload : {
                    type : SCHEDULE_REMOVE_FAILED,
                    message : 'Gagal menghapus data schedule'
                }
            })
        }
    }
}

export const resetScheduleCrud = () => async dispatch => {
    dispatch({
        type : SCHEDULE_RESET_CRUD,
        payload : {
            type : SCHEDULE_RESET_CRUD,
            message : 'reset'
        }
    })
}