import { BASE_URL } from "actions/config";
import axios from 'axios';
import { DASHBOARD_LOADING , DASHBOARD_SUCCESSFUL , DASHBOARD_FAILED } from "./Types";
import { passingHeaderToken } from "components/Utilities/Utility";

export const getDashboardData = (token) => async dispatch => {
    try {
        dispatch({
            type : DASHBOARD_LOADING,
            payload : {
                type : DASHBOARD_LOADING,
                message : 'Loading dashboard data'
            }
        })
        const response = await axios.get(`${BASE_URL}/api/admin/dashboard/` , passingHeaderToken(token))
        setTimeout(() => {
            dispatch({
                type : DASHBOARD_SUCCESSFUL,
                payload : {
                    type : DASHBOARD_SUCCESSFUL,
                    data : response.data
                }
            })
        } , 250)
    } catch (err) {
        dispatch({
            type : DASHBOARD_FAILED,
            payload : {
                type : DASHBOARD_FAILED,
                message : 'Failed to get dashboard data'
            }
        })
    }
}