import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
    Container,
    Card,
    Row,
    Col,
    CardBody,
    Form,
    FormGroup,
    Input
} from 'reactstrap';
import { useEffect, useRef, useReducer, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { updateHelp, getDetailHelp, resetCrud } from 'actions/Help/HelpService';
import {
    HELP_DETAIL_LOADING,
    HELP_DETAIL_SUCCESSFUL,
    HELP_DETAIL_NOT_FOUND,
    HELP_DETAIL_FAILED,

    HELP_UPDATE_LOADING,
    HELP_UPDATE_SUCCESSFUL,
    HELP_UPDATE_NOT_FOUND,
    HELP_UPDATE_FAILED
} from 'actions/Help/Types';
import '../../../assets/css/utilities.css';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import withReactContent from 'sweetalert2-react-content';
import { MaterialGray1 } from 'components/Utilities/ResourceColors';
import { MaterialRed1 } from 'components/Utilities/ResourceColors';
import Select from 'react-select';
import IcNotFound from 'assets/img/ic_not_found.svg';
import IcError from 'assets/img/ic_error.svg';
import { RED_ERROR_MESSAGE } from 'components/Utilities/ResourceColors';
import Skeleton from 'react-loading-skeleton';
import NotFoundPage from 'components/NotFound/NotFoundPage';
import ErrorPage from 'components/ErrorComponent/ErrorPage';
import { validateEmpty } from 'components/Utilities/Utility';
import CKEditor from "react-ckeditor-component";

const MySwal = withReactContent(Swal)

const HelpDetailPage = (props) => {
    const [token, setToken] = useState('')
    const options = [
        {
            value: 'customer', label: 'Customer'
        },
        {
            value: 'mitra', label: 'Mitra'
        }
    ]
    const mainContent = useRef(null)
    const location = useLocation()
    const history = useHistory()
    const [isSubmit, setSubmit] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const [inputValues, setInputValues] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            title: '',
            description: '',
            help_type: {
                label: 'Customer',
                value: 'customer'
            },
            narasumber: '',
            help_image: '',
        }
    )
    useEffect(() => {
        setToken(localStorage.getItem('token'))
        if (token !== '') {
            props.getDetailHelp(props.location.state.help_id, token)
        }
    }, [token])
    const onChangeHandler = event => {
        const { name, value } = event.target
        setInputValues({ [name]: value })
    }
    useEffect(() => {
        console.log(JSON.stringify(props.help.payload.data, null, 2))
        if (props.help.payload.data !== undefined) {
            for (const key in props.help.payload.data) {
                if (props.help.payload.data.hasOwnProperty(key)) {
                    if (key === 'help_type') {
                        setInputValues({
                            ['help_type']: {
                                value: props.help.payload.data[key],
                                label: `${props.help.payload.data[key][0].toUpperCase()}${props.help.payload.data[key].slice(1)}`
                            }
                        })
                    } else {
                        setInputValues({
                            [key]: props.help.payload.data[key]
                        })
                    }
                }
            }
        }
        buildHelpDetailPage()
    }, [props.help.payload])
    const onBlur = (evt) => {
        console.log('onBlur')
    }
    const afterPaste = (evt) => {
        console.log('after paste')
    }
    const onChange = (evt) => {
        setInputValues({ ['description']: evt.editor.getData() })
    }
    useEffect(() => {
        if (!localStorage.getItem('token')) {
            history.push('/auth/login')
        }
    }, [location])
    useEffect(() => {
        switch (props.help.payload_crud.type) {
            case HELP_UPDATE_LOADING:
                setLoading(true)
                break;
            case HELP_UPDATE_SUCCESSFUL:
                setLoading(false)
                Swal.fire(
                    'Status',
                    'Berhasil memperbarui data bantuan',
                    'success'
                )
                if (props.location.state !== undefined && token !== '')
                    props.getDetailHelp(props.location.state.help_id, token)
                props.resetCrud()
                break;
            case HELP_UPDATE_NOT_FOUND:
                setLoading(false)
                Swal.fire(
                    'Perhatian',
                    'Tidak ada data bantuan',
                    'warning'
                )
                break;
            case HELP_UPDATE_FAILED:
                setLoading(false)
                Swal.fire(
                    'Perhatian',
                    'Gagal memperbarui bantuan',
                    'error'
                )
                break;
        }
    }, [props.help.payload_crud])

    function updateHelp() {
        setSubmit(true)
        const {
            title,
            description
        } = inputValues
        const help_type = inputValues.help_type.value
        if (token !== '' && props.location.state !== undefined &&
            props.location.state.help_id !== undefined &&
            title !== '' && description !== '' && help_type !== '') {
            let help_type_selected = inputValues.help_type.value
            const payload_json = {
                title: title,
                description: description,
                help_type: help_type_selected
            }
            props.updateHelp(props.location.state.help_id, payload_json, token)
        }
    }

    function buildHelpDetailPage() {
        switch (props.help.payload.type) {
            case HELP_DETAIL_LOADING:
                return (
                    <>
                        <Container className="mt--7" fluid>
                            <Row>
                                <Col className="order-xl-1" xl="12">
                                    <Card className="bg-secondary shadow">
                                        <CardBody>
                                            <Form>
                                                <div>
                                                    <Row>
                                                        <Col lg="12">
                                                            <FormGroup>
                                                                <Skeleton style={{ height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col lg="12">
                                                            <Skeleton style={{ height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <Skeleton style={{ marginTop: '30px', height: '200px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col lg="12">
                                                            <FormGroup>
                                                                <Skeleton style={{ marginTop: '30px', height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Form>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </>
                )
            case HELP_DETAIL_SUCCESSFUL:
                return (
                    <>
                        <Container className="mt--7" fluid>
                            <Row>
                                <Col className="order-xl-1" xl="12">
                                    <Card className="bg-secondary-shadow">
                                        {
                                            isLoading ?
                                                <div style={{
                                                    borderTopLeftRadius: '5px',
                                                    borderTopRightRadius: '5px',
                                                    height: '5px'
                                                }} className="progress-bar">
                                                    <div className="progress-bar-value"></div>
                                                </div>
                                                : <div style={{
                                                    height: '5px'
                                                }}></div>
                                        }
                                        <CardBody>
                                            <Form>
                                                <div>
                                                    <Row>
                                                        <Col lg="12">
                                                            <Row>
                                                                <Col lg="6">
                                                                    <FormGroup>
                                                                        <label
                                                                            className="form-control-label"
                                                                            htmlFor="input-title">
                                                                            Judul Bantuan
                                                                        </label>
                                                                        <Input
                                                                            className="form-control-alternative"
                                                                            id="input-title"
                                                                            type="text"
                                                                            name="title"
                                                                            onChange={onChangeHandler}
                                                                            value={inputValues.title}
                                                                            style={
                                                                                isSubmit && inputValues.title === '' ?
                                                                                    {
                                                                                        color: 'black',
                                                                                        border: '0.2px solid #d32f2f'
                                                                                    }
                                                                                    :
                                                                                    {
                                                                                        color: 'black',
                                                                                        border: 'none'
                                                                                    }
                                                                            }
                                                                            disabled={isLoading && validateEmpty(inputValues.title) ? true : false} />
                                                                        <p style={{
                                                                            color: MaterialRed1,
                                                                            fontSize: '11px',
                                                                            marginTop: '5px',
                                                                            visibility: isSubmit && inputValues.title === '' ? 'visible' : 'hidden'
                                                                        }}>Judul Bantuan must be filled</p>
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col lg="6">
                                                                    <FormGroup>
                                                                        <label
                                                                            className="form-control-label"
                                                                            htmlFor="input-select-type"
                                                                        >
                                                                            Tipe Bantuan
                                                                        </label>
                                                                        <Select
                                                                            id="input-select-type"
                                                                            options={options}
                                                                            value={
                                                                                inputValues.help_type
                                                                            }
                                                                            name="help_type"
                                                                            onChange={(selectedOption) => {
                                                                                setInputValues({ ['help_type']: selectedOption })
                                                                            }}
                                                                            disabled={isLoading && validateEmpty(inputValues.help_type.value) ? true : false} />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col lg="12">
                                                            <Row style={{ marginTop: '-20px' }}>
                                                                <Col style={{ height: '400px' }} lg="12">
                                                                    <FormGroup>
                                                                        <label
                                                                            className="form-control-label"
                                                                            htmlFor="input-help-description"
                                                                        >
                                                                            Isi Bantuan
                                                                        </label>
                                                                        <div style={{
                                                                            height: '328px',
                                                                            border: isSubmit && !validateEmpty(inputValues.description) ? `1px solid ${RED_ERROR_MESSAGE}` : '1px solid black',
                                                                            borderRadius: '2px',
                                                                        }}>
                                                                            <CKEditor
                                                                                content={inputValues.description}
                                                                                events={{
                                                                                    blur: onBlur,
                                                                                    afterPaste: afterPaste,
                                                                                    change: onChange
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <p style={{
                                                                            color: MaterialRed1,
                                                                            fontSize: '11px',
                                                                            marginTop: '5px',
                                                                            visibility: isSubmit && !validateEmpty(inputValues.description) ? 'visible' : 'hidden'
                                                                        }}>Isi Bantuan must be filled</p>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col lg="12">
                                                            <a onClick={() => updateHelp()} style={{ width: '100%' }} className="btn btn-success btn-sm">
                                                                Simpan
                                                            </a>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Form>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </>
                )
            case HELP_DETAIL_NOT_FOUND:
                return (
                    <>
                        <Container className="mt--7" fluid>
                            <Row>
                                <Col className="order-xl-1" xl="12">
                                    <Card className="bg-secondary-shadow">
                                        <CardBody>
                                            <NotFoundPage
                                                imageSource={IcNotFound}
                                                imageText={props.help.payload.message} />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </>
                )
            case HELP_DETAIL_FAILED:
                return (
                    <>
                        <Container className="mt--7" fluid>
                            <Row>
                                <Col className="order-xl-1" xl="12">
                                    <Card className="bg-secondary-shadow">
                                        <CardBody>
                                            <ErrorPage
                                                imageSource={IcError}
                                                imageText={props.help.payload.message} />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </>
                )
        }
    }

    return (
        <>
            <div className="main-content" ref={mainContent}>
                <div className="header bg-gradient-info pb-8 pt-5 pt-lg-7 d-flex align-items-center">
                    <span className="mask bg-gradient-default opacity-8" />
                </div>
                {buildHelpDetailPage()}
            </div>
        </>
    )
}

HelpDetailPage.propTypes = {
    help: PropTypes.object.isRequired,
    updateHelp: PropTypes.func.isRequired,
    getDetailHelp: PropTypes.func.isRequired,
    resetCrud: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    help: state.help
})

export default connect(mapStateToProps, { updateHelp, getDetailHelp, resetCrud })(HelpDetailPage)