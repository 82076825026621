import axios from 'axios'

const setAuthToken = token => {
    if(token){
        axios.defaults.headers.common = { 'Authorization' : `${token}`}
        localStorage.setItem('token' , token)
    }
    else {
        axios.defaults.headers.common = { 'Authorization' : ''}
        localStorage.removeItem('token')
    }
}

export default setAuthToken