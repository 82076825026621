import { combineReducers } from "redux";
import AuthReducer from "./Auth/AuthReducer";
import ComplainReducer from "./Complain/ComplainReducer";
import MitraReducer from "./Mitra/MitraReducer";
import OrderReducer from "./Orders/OrderReducer";
import BannerReducer from "./Banners/BannerReducer";
import NewsReducer from "./News/NewsReducer";
import GuideReducer from "./Guide/GuideReducer";
import HelpReducer from "./Help/HelpReducer";
import LayananReducer from "./Layanan/LayananReducer";
import ParentReducer from "./Parent/ParentReducer";
import ServiceReducer from "./Service/ServiceReducer";
import SubServiceReducer from "./SubService/SubServiceReducer";
import SubServiceAddReducer from "./SubServiceAdditional/SubServiceAddReducer"
import SocketReducer from "./Socket/SocketReducer";
import ScheduleReducer from "./Schedules/ScheduleReducer";
import ToolsReducer from "./Tools/ToolsReducer";
import TocReducer from "./Toc/TocReducer";
import TransactionReducer from "./Transactions/TransactionReducer";
import DashboardReducer from "./Dashboard/DashboardReducer";

export default combineReducers({
    auth : AuthReducer,
    complain : ComplainReducer,
    mitra : MitraReducer,
    orders : OrderReducer,
    banner : BannerReducer,
    news : NewsReducer,
    dashboard : DashboardReducer,
    guide : GuideReducer,
    help : HelpReducer,
    layanan : LayananReducer,
    parent_payload : ParentReducer,
    payload_service : ServiceReducer,
    payload_sub_service : SubServiceReducer,
    payload_sub_service_add : SubServiceAddReducer,
    schedule : ScheduleReducer,
    socket : SocketReducer,
    transaction : TransactionReducer,
    toc : TocReducer,
    tools : ToolsReducer
})