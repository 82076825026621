import { connect } from 'react-redux';
import {
    Container,
    Row,
    Col,
    Input,
    Card,
    CardHeader,
    CardBody,
    Form,
    FormGroup,
    Label,
    Modal,
    ModalHeader,
    ModalBody,
} from 'reactstrap';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import React, { useEffect, useReducer, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { MaterialGray1 } from 'components/Utilities/ResourceColors';
import IcCleaning from '../../../assets/img/ic_cleaning.svg';
import IcRevenue from '../../../assets/img/ic_revenue.svg';
import { getMitraDetail, updateMitraDetail, updateMitraSuspend, updateMitraActive, resetUpdate } from 'actions/Mitra/MitraService';
import { MITRA_DETAIL_LOADING } from 'actions/Mitra/Types';
import { MITRA_DETAIL_SUCCESSFUL } from 'actions/Mitra/Types';
import { BASE_URL } from 'actions/config';
import { formatRupiah } from 'components/Utilities/Utility';
import { useHistory } from 'react-router-dom';
import MapContainer from 'components/Maps/MapContainer';
import IcGoogleMap from '../../../assets/img/ic_google_map.png'
import 'react-phone-input-2/lib/style.css';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import '../../../assets/css/utilities.css';
import { DatePicker } from 'reactstrap-date-picker'
import { onMessageListener } from '../../../firebase';
import { MaterialGreen1 } from 'components/Utilities/ResourceColors';
import { MaterialRed1 } from 'components/Utilities/ResourceColors';
import { MaterialRed2 } from 'components/Utilities/ResourceColors';
import { SUSPEND_MITRA_LOADING } from 'actions/Mitra/Types';
import { SUSPEND_MITRA_SUCCESSFUL } from 'actions/Mitra/Types';
import { SUSPEND_MITRA_FAILED } from 'actions/Mitra/Types';
import { ACTIVE_MITRA_LOADING } from 'actions/Mitra/Types';
import { ACTIVE_MITRA_SUCCESSFUL } from 'actions/Mitra/Types';
import { ACTIVE_MITRA_FAILED } from 'actions/Mitra/Types';
import withReactContent from 'sweetalert2-react-content';
import Swal from "sweetalert2";
import { validateEmpty } from 'components/Utilities/Utility';

const MySwal = withReactContent(Swal)

const MitraDetailPage = (props) => {
    const mainContent = React.useRef(null)
    const location = useLocation()
    const history = useHistory()
    const [token, setToken] = useState('')
    const [isEditing, setEditing] = useState(false)
    const [isSubmit, setSubmit] = useState(false)
    const [modal, setModal] = useState(false)
    const toggle = () => setModal(!modal)
    const [dialogSuspend, setDialogSuspend] = useState(false)
    const toggleSuspend = () => setDialogSuspend(!dialogSuspend)
    const [dialogActive, setDialogActive] = useState(false)
    const toggleActive = () => setDialogActive(!dialogActive)
    const [modalImageUrl, setModalImageUrl] = useState(null)
    const [updateSuspend, setUpdateSuspend] = useState(false)
    const [updateActive, setUpdateActive] = useState(false)
    const [suspendReason , setSuspendReason] = useState('')

    useEffect(() => {
        setToken(localStorage.getItem('token'))
    }, [token])

    // onMessageListener().then(payload => {
    //     console.log(payload.data.mitra_id)
    //     if(props.location.state.mitra_id.toString() === payload.data.mitra_id.toString()){
    //         props.getMitraDetail(props.location.state.mitra_id)
    //     }
    //   }).catch(err => console.log('failed: ', err));
    const [inputValues, setInputValues] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            email: '',
            country_code: '',
            phone_number: '',
            user_gender: '',
            domisili_address: '',
            ktp_number: '',
            rt_rw: '',
            province: '',
            address: '',
            district: '',
            sub_district: '',
            kind_of_mitra: '',
            city: '',
            place_of_birth: '',
            date_of_birth: '',
            postal_code: '',
            is_ex_golife: '',
            work_experience_duration: '',
            emergency_contact_name: '',
            emergency_contact_relation: '',
            emergency_contact_country_code: '',
            emergency_contact_phone: ''
        }
    )
    React.useEffect(() => {
        if (!localStorage.getItem('token')) {
            history.push('/auth/login')
        }
    }, [location])
    useEffect(() => {
        props.getMitraDetail(props.location.state.mitra_id, Intl.DateTimeFormat().resolvedOptions().timeZone)
    }, [])

    useEffect(() => {
        buildMitraDetail()
        if (props.mitra.payload.data !== undefined) {
            console.log(props.mitra.payload.data.phone_number)
            for (var key in props.mitra.payload.data) {
                if (props.mitra.payload.data.hasOwnProperty(key)) {
                    setInputValues({ [key]: props.mitra.payload.data[key] })
                }
            }
        }
    }, [props.mitra])

    useEffect(() => {
        switch (props.mitra.payload_update.type) {
            case SUSPEND_MITRA_LOADING:
                setUpdateSuspend(true)
                break;
            case SUSPEND_MITRA_SUCCESSFUL:
                setUpdateSuspend(false)
                setDialogSuspend(false)
                MySwal.fire(
                    'Perhatian',
                    'Berhasil menonaktifkan mitra',
                    'success'
                )
                props.resetUpdate()
                props.getMitraDetail(props.location.state.mitra_id)
                break;
            case SUSPEND_MITRA_FAILED:
                setUpdateSuspend(false)
                MySwal.fire(
                    'Perhatian',
                    'Gagal menonaktifkan mitra',
                    'error'
                )
                props.resetUpdate()
                break;

            case ACTIVE_MITRA_LOADING:
                setUpdateActive(true)
                break;
            case ACTIVE_MITRA_SUCCESSFUL:
                setUpdateActive(false)
                setDialogActive(false)
                MySwal.fire(
                    'Perhatian',
                    'Berhasil mengaktifkan kembali mitra',
                    'success'
                )
                props.resetUpdate()
                props.getMitraDetail(props.location.state.mitra_id)
                break;
            case ACTIVE_MITRA_FAILED:
                setUpdateActive(false)
                MySwal.fire(
                    'Perhatian',
                    'Gagal mengaktifkan kembali mitra',
                    'error'
                )
                props.resetUpdate()
                break;
        }
    }, [props.mitra.payload_update])

    function updateMitraStatus(status) {
        if (validateEmpty(token)) {
            console.log(`Token : ${token}`)
            if (status === 'suspend') {
                props.updateMitraSuspend(props.location.state.mitra_id , suspendReason, token)
            } else if (status === 'active') {
                props.updateMitraActive(props.location.state.mitra_id, token)
            }
        }
    }

    function buildMitraDetail() {
        switch (props.mitra.payload.type) {
            case MITRA_DETAIL_LOADING:
                return (
                    <Container className="mt--7" fluid>
                        <Row>
                            <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
                                <Card style={{ marginTop: '20px' }} className="card-profile-shadow">
                                    <Row className="justify-content-center">
                                        <Col className="order-lg-2" lg="3">
                                            <div className="card-profile-image">
                                                <Skeleton
                                                    className="shimmer_image"
                                                    style={{
                                                        transform: 'translate(-50% , -40%)',
                                                        position: 'absolute',
                                                        left: '50%',
                                                        borderRadius: '50%',
                                                        backgroundColor: MaterialGray1,
                                                        width: '150px',
                                                        height: '150px'
                                                    }} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <CardHeader className="text-center border-0 pt-8 pt-md-0 pb-0 pb-md-0">
                                    </CardHeader>
                                    <CardBody className="pt-0 pt-md-4">
                                        <Row>
                                            <div className="col">
                                                <div className="card-profile-stats d-flex justify-content-center mt-md-6">
                                                    <div>
                                                        <Skeleton style={{ color: MaterialGray1, backgroundColor: MaterialGray1, borderRadius: '10px' }} className="heading"></Skeleton>
                                                        <span style={{ color: 'transparent' }} className="description">-------</span>
                                                    </div>
                                                    <div>
                                                        <Skeleton style={{ color: MaterialGray1, backgroundColor: MaterialGray1, borderRadius: '10px' }} className="heading"></Skeleton>
                                                        <span style={{ color: 'transparent' }} className="description">-------</span>
                                                    </div>
                                                    <div>
                                                        <Skeleton style={{ color: MaterialGray1, backgroundColor: MaterialGray1, borderRadius: '10px' }} className="heading"></Skeleton>
                                                        <span style={{ color: 'transparent' }} className="description">-------</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Row>
                                        <div style={{ textAlign: 'center' }} className="text-center">
                                            <Skeleton style={{ color: MaterialGray1, backgroundColor: MaterialGray1 }}>
                                            </Skeleton>
                                            <Skeleton style={{ color: MaterialGray1, backgroundColor: MaterialGray1 }}>
                                            </Skeleton>
                                            <Skeleton style={{ color: MaterialGray1, backgroundColor: MaterialGray1 }}>
                                            </Skeleton>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col style={{ marginTop: '20px' }} className="order-xl-1" xl="8">
                                <Card className="bg-secondary shadow">
                                    <CardBody>
                                        <Form>
                                            <Skeleton style={{ width: '150px', backgroundColor: MaterialGray1 }} className="heading-small text-muted mb-4">
                                            </Skeleton>
                                            <div>
                                                <Row>
                                                    <Col lg="6">
                                                        <FormGroup>
                                                            <Skeleton style={{ height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="6">
                                                        <Skeleton style={{ height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg="6">
                                                        <Skeleton style={{ height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                    </Col>
                                                    <Col lg="6">
                                                        <Skeleton style={{ height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                    </Col>
                                                </Row>
                                                <hr className="my-4" />
                                                <Skeleton style={{ width: '150px', backgroundColor: MaterialGray1 }} className="heading-small text-muted mb-4">
                                                </Skeleton>
                                                <Row>
                                                    <Col>
                                                        <Skeleton style={{ height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                    </Col>
                                                </Row>
                                                <Row style={{ marginTop: '20px' }}>
                                                    <Col lg="4">
                                                        <Skeleton style={{ height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                    </Col>
                                                    <Col lg="4">
                                                        <Skeleton style={{ height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                    </Col>
                                                    <Col lg="4">
                                                        <Skeleton style={{ height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                )
            case MITRA_DETAIL_SUCCESSFUL:
                const data = props.mitra.payload.data
                return (
                    <Container className="mt--7" fluid>
                        <Row>
                            <Col className="order-xl-2 mb-6 mb-xl-0" xl="4">
                                <Card className="card-profile shadow">
                                    <Row className="justify-content-center">
                                        <Col className="order-lg-2" lg="3">
                                            <div className="card-profile-image">
                                                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                                    <div>
                                                        <div style={{
                                                            alignItems: 'center',
                                                            display: 'flex',
                                                            justifyContent: 'center'
                                                        }}>
                                                            <img src={`${BASE_URL}/api/images${data.user_profile_image}`} style={{
                                                                width: '150px',
                                                                objectFit: 'cover',
                                                                borderRadius: '50%',
                                                                height: '150px',
                                                                transform: 'translate(-50% , 0%)',
                                                                display: 'inline-block'
                                                            }} />
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </Col>
                                    </Row>
                                    <CardHeader style={{ borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }} className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-0">
                                    </CardHeader>
                                    <CardBody style={{ marginTop: '50px' }} className="pt-0 pt-md-4">
                                        {
                                            data !== null ?
                                                <div>
                                                    <Row style={{ marginTop: '10px' }}>
                                                        <Col md="12">
                                                            {
                                                                data.is_mitra_activated === '1' && data.is_suspended === '0' ?
                                                                    <p style={{ width: '100%', borderRadius: '10px', textAlign: 'center', fontWeight: 'bold', backgroundColor: MaterialGreen1, color: 'white', fontSize: '14px', paddingTop: '10px', paddingBottom: '10px', paddingLeft: '15px', paddingRight: '15px' }}>
                                                                        Mitra Aktif
                                                                    </p>
                                                                    :
                                                                    <p style={{ width: '100%', borderRadius: '10px', textAlign: 'center', fontWeight: 'bold', backgroundColor: MaterialRed1, color: 'white', fontSize: '14px', paddingTop: '10px', paddingBottom: '10px', paddingLeft: '15px', paddingRight: '15px' }}>
                                                                        Mitra Dinonaktifkan
                                                                    </p>
                                                            }
                                                        </Col>
                                                    </Row>
                                                    <Row style={{ marginTop: '10px' }}>
                                                        <Col md="12">
                                                            {
                                                                data.is_mitra_activated === '0' && data.is_suspended === '1' ?
                                                                    <button
                                                                        onClick={() => {
                                                                            setDialogActive(true)
                                                                        }}
                                                                        style={{ backgroundColor: 'transparent', color: MaterialGreen1, width: '100%' }} className="btn btn-success btn-md">
                                                                        Aktifkan Kembali Mitra
                                                                    </button>
                                                                    :
                                                                    <button
                                                                        onClick={() => {
                                                                            setDialogSuspend(true)
                                                                        }}
                                                                        style={{ backgroundColor: 'transparent', color: MaterialRed2, width: '100%' }} className="btn btn-danger btn-md">
                                                                        Nonaktifkan Mitra
                                                                    </button>
                                                            }
                                                        </Col>
                                                    </Row>
                                                </div>
                                                :
                                                <div></div>
                                        }
                                        <Row style={{ marginTop: '-30px' }}>
                                            <div className="col">
                                                <div className="card-profile-stats d-flex justify-content-center mt-md-4">
                                                    <div>
                                                        <span className="heading">{data !== null ? data.total_order : 0}</span>
                                                        <span className="description">Orderan</span>
                                                    </div>
                                                    <div>
                                                        <span className="heading">{data !== null ? parseFloat(data.user_rating).toFixed(2) : 0.0}</span>
                                                        <span className="description">Rating</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Row>
                                        <div className="text-center">
                                            <h3>
                                                {data !== null ? data.complete_name : ''}
                                            </h3>
                                            <div className="h5 font-weight-300">
                                                <i className="ni location_pin mr-2" />
                                                {`${data.age !== null ? data.age : '0'}`}
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col className="order-xl-1" xl="8">
                                {
                                    data.is_suspended === '1' ?
                                        <Card className="bg-secondary shadow">
                                            <div style={{ padding: '30px', marginTop: '10px' }}>
                                                <Row>
                                                    <Col style={{ textAlign: 'center' }} md="12">
                                                        <h4>Alasan Dinonaktifkan</h4>
                                                        <div style={{ marginTop: '20px' }}>
                                                            <h5 style={{ marginTop: '20px', color: '#d32f2f' , fontStyle : 'italic' }}>"{data.suspended_reason}"</h5>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Card>
                                        :
                                        <Card className="bg-secondary-shadow">
                                            <div style={{ padding: '30px', marginTop: '10px' }}>
                                                <Row>
                                                    <Col style={{ textAlign: 'center' }} md="4">
                                                        <h4>Status Mitra</h4>
                                                        <div style={{ marginTop: '20px' }}>
                                                            {data.is_active === 'yes' ? <div style={{ margin: '0 auto', backgroundColor: '#66bb6a' }} className="blob"></div>
                                                                : <div style={{ margin: '0 auto', width: '20px', height: '20px', borderRadius: '50%', backgroundColor: '#d32f2f' }}></div>
                                                            }
                                                        </div>
                                                        {data.is_active === 'yes' ? <h5 style={{ marginTop: '20px', color: '#66bb6a' }}>Aktif</h5>
                                                            : <h5 style={{ marginTop: '20px', color: '#d32f2f' }}>Tidak Aktif</h5>
                                                        }
                                                    </Col>
                                                    <Col style={{ textAlign: 'center' }} md="4">
                                                        <h4>Order Hari Ini</h4>
                                                        <div style={{ marginTop: '10px' }}>
                                                            <img
                                                                style={{
                                                                    width: '35px',
                                                                    height: '35px'
                                                                }}
                                                                src={IcCleaning} />
                                                        </div>
                                                        <h5 style={{ marginTop: '15px' }}>{data.order_count} Order</h5>
                                                    </Col>
                                                    <Col style={{ textAlign: 'center' }} md="4">
                                                        <h4>Pendapatan Hari Ini</h4>
                                                        <div style={{ marginTop: '10px' }}>
                                                            <img
                                                                style={{
                                                                    width: '35px',
                                                                    height: '35px'
                                                                }}
                                                                src={IcRevenue} />
                                                        </div>
                                                        <h5 style={{ marginTop: '15px' }}>{data.pendapatan_order === null ? '0' : formatRupiah(data.account_balance.toString(), "Rp. ")}</h5>
                                                    </Col>
                                                </Row>
                                                <hr className="my-4" />
                                                <Row>
                                                    <Col style={{ textAlign: 'center' }} sm="4">
                                                        <h5>Lagi Jalanin Order?</h5>
                                                        <div style={{ marginTop: '20px' }}>
                                                            {data.is_busy === 'yes' ? <div style={{ margin: '0 auto', backgroundColor: '#42a5f5' }} className="blob"></div>
                                                                : <div style={{ margin: '0 auto', width: '20px', height: '20px', borderRadius: '50%', backgroundColor: '#d32f2f' }}></div>
                                                            }
                                                        </div>
                                                        {data.is_busy === 'yes' ? <h5 style={{ marginTop: '20px', color: '#42a5f5' }}>Ya</h5>
                                                            : <h5 style={{ marginTop: '20px', color: '#d32f2f' }}>Tidak</h5>
                                                        }
                                                    </Col>
                                                    <Col md="8">
                                                        {
                                                            data.is_busy === 'yes' ?
                                                                <div style={{ height: '170px', width: '60%' }}>
                                                                    <MapContainer />
                                                                </div> :
                                                                <div style={{ backgroundColor: '#bdbdbd', borderRadius: '10px', height: '170px' }}>
                                                                    <img
                                                                        style={{
                                                                            width: '40px',
                                                                            height: '40px',
                                                                            padding: '5px',
                                                                            borderRadius: '10px',
                                                                            backgroundColor: 'white',
                                                                            position: 'absolute',
                                                                            bottom: 10,
                                                                            right: 25
                                                                        }}
                                                                        src={IcGoogleMap} />
                                                                    <p style={{ position: 'absolute', margin: '0 auto', top: '50%', left: '50%', transform: 'translate(-50% , -50%)', textAlign: 'center', color: 'white' }}>
                                                                        Preview lokasi akan muncul disini jika mitra menjalankan order
                                                                    </p>
                                                                </div>
                                                        }
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Card>
                                }
                                <Card style={{ marginTop: '10px' }} className="bg-secondary shadow">
                                    <CardBody>
                                        <Form>
                                            <div className="align-items-center row">
                                                <div className="col-8">
                                                    <h3 className="mb-0">Informasi Mitra</h3>
                                                </div>
                                            </div>
                                            <div style={{ marginTop: '20px' }}>
                                                <Row>
                                                    <Col lg="6">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-email"
                                                            >
                                                                Email address
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id="input-email"
                                                                placeholder="Email"
                                                                type="email"
                                                                name="email"

                                                                style={
                                                                    isSubmit && inputValues.email === '' ?
                                                                        {
                                                                            border: '0.2px solid #d32f2f'
                                                                        }
                                                                        :
                                                                        {
                                                                            border: 'none'
                                                                        }
                                                                }
                                                                value={inputValues.email}
                                                                disabled={(isEditing) ? "" : "disabled"}
                                                            />
                                                            {
                                                                isSubmit && inputValues.email === '' ?
                                                                    <p style={{
                                                                        color: '#d32f2f',
                                                                        fontSize: '11px',
                                                                        marginTop: '5px',
                                                                        marginLeft: '1px'
                                                                    }}>Email belum terisi</p>
                                                                    : <div></div>
                                                            }
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="6">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-email"
                                                            >
                                                                Nomor Telepon
                                                            </label>
                                                            <IntlTelInput
                                                                preferredCountries={['id']}
                                                                value={inputValues.phone_number}
                                                                containerClassName="intl-tel-input"
                                                                name="phone_number"
                                                                inputClassName="form-control"
                                                                style={
                                                                    isSubmit && inputValues.phone_number === '' ?
                                                                        {
                                                                            width: '100%',
                                                                            borderRadius: '6px',
                                                                            border: '0.2px solid #d32f2f'
                                                                        } :
                                                                        {
                                                                            width: '100%',
                                                                            borderRadius: '6px',
                                                                            border: 'none'
                                                                        }
                                                                }
                                                                onPhoneNumberChange={(status, value, countryData, number, id) => {

                                                                }}
                                                                onSelectFlag={(num, country) => {

                                                                }}
                                                                disabled={(isEditing) ? "" : "disabled"} />
                                                            {
                                                                isSubmit && inputValues.phone_number === '' ?
                                                                    <p style={{
                                                                        color: '#d32f2f',
                                                                        fontSize: '11px',
                                                                        marginTop: '5px',
                                                                        marginLeft: '1px'
                                                                    }}>Nomor Telepon belum terisi</p>
                                                                    : <div></div>
                                                            }
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg="6">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-first-name"
                                                            >
                                                                Jenis Kelamin
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id="input-gender"
                                                                placeholder="Jenis Kelamin"
                                                                type="select"

                                                                name="user_gender"
                                                                value={inputValues.user_gender}
                                                                disabled={(isEditing) ? "" : "disabled"}
                                                            >
                                                                <option>Pria</option>
                                                                <option>Wanita</option>
                                                            </Input>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="6">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-last-name"
                                                            >
                                                                Dengan Alat / Tanpa Alat
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"

                                                                id="input-region"
                                                                placeholder="Tipe Mitra"
                                                                name="kind_of_mitra"

                                                                value={inputValues.kind_of_mitra}
                                                                type="select"
                                                                disabled={(isEditing) ? "" : "disabled"}
                                                            >
                                                                <option>Dengan Alat</option>
                                                                <option>Tanpa Alat</option>
                                                            </Input>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg="6">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="place_of_birth"
                                                            >
                                                                Tempat Lahir
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                value={inputValues.place_of_birth}
                                                                id="place_of_birth"
                                                                name="place_of_birth"

                                                                type="text"
                                                                disabled={(isEditing) ? "" : "disabled"}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="6">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="date_of_birth"
                                                            >
                                                                Tanggal Lahir
                                                            </label>
                                                            <DatePicker
                                                                id="date_of_birth"
                                                                value={inputValues.date_of_birth}
                                                                name="date_of_birth"
                                                                dateFormat="DD/MM/YYYY"
                                                                onChange={(v, f) => {

                                                                }}
                                                                disabled={(isEditing) ? "" : "disabled"} />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg="6">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="ktp_number"
                                                            >
                                                                Nomor KTP
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                value={inputValues.ktp_number}
                                                                id="ktp_number"
                                                                name="ktp_number"

                                                                type="text"
                                                                disabled={(isEditing) ? "" : "disabled"}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <hr className="my-4" />
                                            {/* Address */}
                                            <h3 className="mb-0">Alamat Calon Mitra</h3>
                                            <div style={{ marginTop: '20px' }}>
                                                <Row>
                                                    <Col md="12">
                                                        <div>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-domisili-address"
                                                            >
                                                                Alamat Domisili
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id="input-domisili-address"
                                                                placeholder="Alamat Domisili"
                                                                name="domisili_address"
                                                                value={inputValues.domisili_address}
                                                                type="text"
                                                                disabled={isEditing ? "" : "disabled"} />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="12">
                                                        <div style={{ marginTop: '10px' }}>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-address"
                                                            >
                                                                Alamat Jalan
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id="input-address"
                                                                placeholder="Alamat"
                                                                name="address"
                                                                value={inputValues.address}

                                                                type="text"
                                                                disabled={(isEditing) ? "" : "disabled"}
                                                            />
                                                            <Row style={{ marginTop: '10px' }}>
                                                                <Col>
                                                                    <label
                                                                        className="form-control-label"
                                                                        htmlFor="input-kelurahan"
                                                                    >
                                                                        Nama Kelurahan
                                                                    </label>
                                                                    <Input
                                                                        className="form-control-alternative"
                                                                        id="input-kelurahan"
                                                                        placeholder="Kelurahan"
                                                                        name="sub_district"

                                                                        value={inputValues.sub_district}
                                                                        type="text"
                                                                        disabled={(isEditing) ? "" : "disabled"} />
                                                                </Col>
                                                                <Col>
                                                                    <label
                                                                        className="form-control-label"
                                                                        htmlFor="input-kecamatan"
                                                                    >
                                                                        Nama Kecamatan
                                                                    </label>
                                                                    <Input
                                                                        className="form-control-alternative"
                                                                        id="input-kecamatan"
                                                                        placeholder="Kecamatan"
                                                                        name="district"

                                                                        value={inputValues.district}
                                                                        type="text"
                                                                        disabled={(isEditing) ? "" : "disabled"} />
                                                                </Col>
                                                            </Row>
                                                            <Row style={{ marginTop: '10px' }}>
                                                                <Col>
                                                                    <label
                                                                        className="form-control-label"
                                                                        htmlFor="input-postal-code"
                                                                    >
                                                                        Kode POS
                                                                    </label>
                                                                    <Input
                                                                        className="form-control-alternative"
                                                                        id="input-postal-code"
                                                                        placeholder="Kode Pos"
                                                                        name="postal_code"

                                                                        value={inputValues.postal_code}
                                                                        type="text"
                                                                        disabled={(isEditing) ? "" : "disabled"} />
                                                                </Col>
                                                                <Col>
                                                                    <label
                                                                        className="form-control-label"
                                                                        htmlFor="input-rt-rw"
                                                                    >
                                                                        RT/RW
                                                                    </label>
                                                                    <Input
                                                                        className="form-control-alternative"
                                                                        id="input-rt-rw"
                                                                        placeholder="RT/RW"
                                                                        name="rt_rw"

                                                                        value={inputValues.rt_rw}
                                                                        type="text"
                                                                        disabled={(isEditing) ? "" : "disabled"} />
                                                                </Col>
                                                            </Row>
                                                            <Row style={{ marginTop: '10px' }}>
                                                                <Col>
                                                                    <label
                                                                        className="form-control-label"
                                                                        htmlFor="input-city"
                                                                    >
                                                                        Kota
                                                                    </label>
                                                                    <Input
                                                                        className="form-control-alternative"
                                                                        id="input-city"
                                                                        placeholder="Kota/Kabupaten"
                                                                        name="city"

                                                                        value={inputValues.city}
                                                                        type="text"
                                                                        disabled={(isEditing) ? "" : "disabled"} />
                                                                </Col>
                                                                <Col>
                                                                    <label
                                                                        className="form-control-label"
                                                                        htmlFor="input-province"
                                                                    >
                                                                        Provinsi
                                                                    </label>
                                                                    <Input
                                                                        className="form-control-alternative"
                                                                        id="input-province"
                                                                        placeholder="RT/RW"
                                                                        name="province"

                                                                        value={inputValues.province}
                                                                        type="text"
                                                                        disabled={(isEditing) ? "" : "disabled"} />
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <hr className="my-4" />
                                            <div style={{
                                                marginTop: '20px'
                                            }}>
                                                <Row>
                                                    <Col lg="6">
                                                        <label
                                                            className="form-control-label">Foto KK</label>
                                                        <div style={{
                                                            alignItems: 'center',
                                                            display: 'flex',
                                                            justifyContent: 'center'
                                                        }}>
                                                            <img style={{
                                                                width: '100%',
                                                                height: '160px',
                                                                objectFit: 'cover',
                                                                borderRadius: '10px',
                                                                transition: '0.45s'
                                                            }} src={`${BASE_URL}/api/images${inputValues.kk_image}`} />
                                                            <div style={{
                                                                display: 'flex',
                                                                position: 'absolute'
                                                            }}>
                                                                <div
                                                                    onClick={() => {
                                                                        setModalImageUrl(inputValues.kk_image)
                                                                        setModal(true)
                                                                    }}
                                                                    style={{
                                                                        backgroundColor: 'transparent',
                                                                        borderRadius: '10px',
                                                                        border: '1px solid white',
                                                                        paddingLeft: '20px',
                                                                        paddingRight: '20px',
                                                                        cursor: 'pointer'
                                                                    }}
                                                                >
                                                                    <p style={{
                                                                        fontSize: '11px',
                                                                        position: 'relative',
                                                                        top: '50%',
                                                                        left: '50%',
                                                                        color: 'white',
                                                                        transform: 'translate(-50% , -50%)'
                                                                    }}>
                                                                        See Image
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col lg="6">
                                                        <label
                                                            className="form-control-label">Foto KTP</label>
                                                        <div style={{
                                                            alignItems: 'center',
                                                            display: 'flex',
                                                            justifyContent: 'center'
                                                        }}>
                                                            <img style={{
                                                                width: '100%',
                                                                height: '160px',
                                                                objectFit: 'cover',
                                                                borderRadius: '10px',
                                                                transition: '0.45s'
                                                            }} src={`${BASE_URL}/api/images${inputValues.ktp_image}`} />
                                                            <div style={{
                                                                display: 'flex',
                                                                position: 'absolute'
                                                            }}>
                                                                <div
                                                                    onClick={() => {
                                                                        setModalImageUrl(inputValues.ktp_image)
                                                                        setModal(true)
                                                                    }}
                                                                    style={{
                                                                        backgroundColor: 'transparent',
                                                                        borderRadius: '10px',
                                                                        border: '1px solid white',
                                                                        paddingLeft: '20px',
                                                                        paddingRight: '20px',
                                                                        cursor: 'pointer'
                                                                    }}
                                                                >
                                                                    <p style={{
                                                                        fontSize: '11px',
                                                                        position: 'relative',
                                                                        top: '50%',
                                                                        left: '50%',
                                                                        color: 'white',
                                                                        transform: 'translate(-50% , -50%)'
                                                                    }}>
                                                                        See Image
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <hr className="my-4" />
                                            <h3 className="mb-0">Pengalaman Kerja &#38; Kontak Darurat</h3>
                                            <div style={{
                                                marginTop: '20px'
                                            }}>
                                                <Row>
                                                    <Col md="6">
                                                        <fieldset className="border p-2">
                                                            <legend style={{ fontSize: '13px' }} className="w-auto">Pengalaman Kerja</legend>
                                                            <div>
                                                                <FormGroup>
                                                                    <label
                                                                        className="form-control-label"
                                                                        htmlFor="is_ex_golife">Mantan GoLife?</label>
                                                                    <Input
                                                                        className="form-control-alternative"
                                                                        id="is_ex_golife"
                                                                        placeholder="Mantan GoLife?"
                                                                        name="is_ex_golife"

                                                                        value={inputValues.is_ex_golife}
                                                                        type="select"
                                                                        disabled={(isEditing) ? "" : "disabled"}
                                                                    >
                                                                        <option>Ya</option>
                                                                        <option>Tidak</option>
                                                                    </Input>
                                                                </FormGroup>
                                                            </div>
                                                            <div>
                                                                <FormGroup>
                                                                    <label
                                                                        className="form-control-label"
                                                                        htmlFor="work_experience">Lama Pengalaman</label>
                                                                    <Input
                                                                        className="form-control-alternative"
                                                                        id="work_experience"
                                                                        placeholder="Lama Pengalaman"
                                                                        name="work_experience_duration"

                                                                        value={inputValues.work_experience_duration}
                                                                        type="text"
                                                                        disabled={(isEditing) ? "" : "disabled"} />
                                                                </FormGroup>
                                                            </div>
                                                        </fieldset>
                                                    </Col>
                                                    <Col md="6">
                                                        <fieldset className="border p-2">
                                                            <legend style={{ fontSize: '13px' }} className="w-auto">Kontak Darurat</legend>
                                                            <div>
                                                                <FormGroup>
                                                                    <label
                                                                        className="form-control-label"
                                                                        htmlFor="emergency_contact_name">Nama Kontak</label>
                                                                    <Input
                                                                        className="form-control-alternative"
                                                                        id="emergency_contact_name"
                                                                        placeholder="Nama Kontak"
                                                                        value={inputValues.emergency_contact_name}

                                                                        name="emergency_contact_name"
                                                                        type="text"
                                                                        disabled={(isEditing) ? "" : "disabled"} />
                                                                </FormGroup>
                                                            </div>
                                                            <div>
                                                                <FormGroup>
                                                                    <label
                                                                        className="form-control-label"
                                                                        htmlFor="emergency_contact_relation">Hubungan Dengan Kontak</label>
                                                                    <Input
                                                                        className="form-control-alternative"
                                                                        id="emergency_contact_relation"
                                                                        placeholder="Hubungan Kontak"
                                                                        name="emergency_contact_relation"

                                                                        value={inputValues.emergency_contact_relation}
                                                                        type="text"
                                                                        disabled={(isEditing) ? "" : "disabled"} />
                                                                </FormGroup>
                                                            </div>
                                                            <div>
                                                                <FormGroup>
                                                                    <label
                                                                        className="form-control-label"
                                                                        htmlFor="emergency_contact_phone">No Telepon Darurat</label>
                                                                    <IntlTelInput
                                                                        preferredCountries={['id']}
                                                                        value={inputValues.emergency_contact_phone}
                                                                        containerClassName="intl-tel-input"
                                                                        name="emergency_contact_phone"
                                                                        inputClassName="form-control"
                                                                        style={
                                                                            isSubmit && inputValues.emergency_contact_phone === '' ?
                                                                                {
                                                                                    width: '100%',
                                                                                    borderRadius: '6px',
                                                                                    border: '0.2px solid #d32f2f'
                                                                                } :
                                                                                {
                                                                                    width: '100%',
                                                                                    borderRadius: '6px',
                                                                                    border: 'none'
                                                                                }
                                                                        }
                                                                        onPhoneNumberChange={(status, value, countryData, number, id) => {

                                                                        }}
                                                                        onSelectFlag={(num, country) => {

                                                                        }}
                                                                        disabled={(isEditing) ? "" : "disabled"} />
                                                                    {
                                                                        isSubmit && inputValues.emergency_contact_phone === '' ?
                                                                            <p style={{
                                                                                color: '#d32f2f',
                                                                                fontSize: '11px',
                                                                                marginTop: '5px',
                                                                                marginLeft: '1px'
                                                                            }}>Nomor Telepon Darurat belum terisi</p>
                                                                            : <div></div>
                                                                    }
                                                                </FormGroup>
                                                            </div>
                                                        </fieldset>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Modal isOpen={modal} toggle={toggle}>
                            <ModalHeader className="border-0" toggle={toggle} >
                                Foto Buku Tabungan
                            </ModalHeader>
                            <ModalBody style={{ marginTop: '-20px' }} className="text-left border-0">
                                <Row>
                                    <Col lg="12">
                                        <div>
                                            <img
                                                style={{ width: '450px', height: '300px' }}
                                                src={`${BASE_URL}/api/images${modalImageUrl}`} />
                                        </div>
                                    </Col>
                                </Row>
                            </ModalBody>
                        </Modal>
                        <Modal isOpen={dialogSuspend} toggle={toggleSuspend}>
                            {
                                updateSuspend ?
                                    <div style={{
                                        borderTopLeftRadius: '5px',
                                        borderTopRightRadius: '5px',
                                        height: '5px'
                                    }} className="progress-bar">
                                        <div className="progress-bar-value"></div>
                                    </div>
                                    : <div style={{
                                        height: '5px'
                                    }}></div>
                            }
                            <ModalHeader className="border-0" toggle={toggleSuspend}>
                                Yakin ingin menonaktifkan mitra ?
                            </ModalHeader>
                            <ModalBody style={{ marginTop: '-20px' }} className="text-left border-0">
                            <Row>
                                    <Col lg="12">
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="input-reasong-cancel"
                                            >
                                                Alasan ingin menonaktifkan mitra
                                            </label>
                                            <Input
                                                type="textarea"
                                                id="input-reason-suspend"
                                                defaultValue={suspendReason}
                                                style={{
                                                    height: '150px',
                                                    padding: '0.4em',
                                                    textAlign: 'left',
                                                    color: 'black'
                                                }}
                                                onChange={(e) => {
                                                    setSuspendReason(e.target.value)
                                                }}
                                                disabled={(updateSuspend) ? "disabled" : ""} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="12">
                                        <button onClick={() => updateMitraStatus('suspend')} style={{ width: '100%', marginTop: '10px' }} className="btn btn-danger btn-sm">
                                            Nonaktifkan Mitra
                                        </button>
                                    </Col>
                                </Row>
                            </ModalBody>
                        </Modal>
                        <Modal isOpen={dialogActive} toggle={toggleActive}>
                            {
                                updateActive ?
                                    <div style={{
                                        borderTopLeftRadius: '5px',
                                        borderTopRightRadius: '5px',
                                        height: '5px'
                                    }} className="progress-bar">
                                        <div className="progress-bar-value"></div>
                                    </div>
                                    : <div style={{
                                        height: '5px'
                                    }}></div>
                            }
                            <ModalHeader className="border-0" toggle={toggleActive}>
                                Ingin mengaktifkan mitra kembali ?
                            </ModalHeader>
                            <ModalBody style={{ marginTop: '-20px' }} className="text-left border-0">
                                <Row>
                                    <Col md="12">
                                        <button onClick={() => updateMitraStatus('active')} style={{ width: '100%', marginTop: '10px' }} className="btn btn-success btn-sm">
                                            Ya
                                        </button>
                                    </Col>
                                </Row>
                            </ModalBody>
                        </Modal>
                    </Container >
                )
            default:
                return (
                    <div></div>
                )
        }
    }
    return (
        <>
            <div className="main-content" ref={mainContent}>
                <div className="header bg-gradient-info pb-8 pt-5 pt-lg-8 d-flex align-items-center">
                    <span className="mask bg-gradient-default opacity-8" />
                </div>
                {buildMitraDetail()}
            </div>
        </>
    )
}

MitraDetailPage.propTypes = {
    mitra: PropTypes.object.isRequired,
    getMitraDetail: PropTypes.func.isRequired,
    updateMitraDetail: PropTypes.func.isRequired,
    resetUpdate: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    mitra: state.mitra
})

export default connect(mapStateToProps, { getMitraDetail, updateMitraDetail, updateMitraSuspend, updateMitraActive, resetUpdate })(MitraDetailPage)