import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { useEffect } from "react";
import { getDashboardData } from "actions/Dashboard/DashboardService";
import { DASHBOARD_LOADING } from "actions/Dashboard/Types";
import { DASHBOARD_SUCCESSFUL } from "actions/Dashboard/Types";
import { DASHBOARD_FAILED } from "actions/Dashboard/Types";
import Skeleton from "react-loading-skeleton";
import { MaterialGray1 } from "components/Utilities/ResourceColors";
import { MaterialRed1 } from "components/Utilities/ResourceColors";
import { formatRupiah } from "components/Utilities/Utility";

const Header = (props) => {
  useEffect(() => {
    if (props.dashboard.payload.data !== undefined) {
      buildDashboardPage()
    }
  }, [props.dashboard.payload])

  function buildDashboardPage() {
    switch (props.dashboard.payload.type) {
      case DASHBOARD_LOADING:
        return (
          <>
            <Row>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody style={{ paddingBottom: '30px' }}>
                    <Row>
                      <div style={{ paddingTop: '10px', width: '100%', display: 'flex' }} className="col">
                        <div style={{
                          flex: 1
                        }}>
                          <Skeleton style={{ height: '25px', backgroundColor: MaterialGray1 }} />
                        </div>
                        <div style={{
                          width: '50px',
                          marginLeft: '20px'
                        }}>
                          <Skeleton style={{ height: '25px', backgroundColor: MaterialGray1 }} />
                        </div>
                      </div>
                    </Row>
                    <Skeleton style={{ marginTop: '15px', width: '100%', height: '25px', backgroundColor: MaterialGray1 }} />
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody style={{ paddingBottom: '30px' }}>
                    <Row>
                      <div style={{ paddingTop: '10px', width: '100%', display: 'flex' }} className="col">
                        <div style={{
                          flex: 1
                        }}>
                          <Skeleton style={{ height: '25px', backgroundColor: MaterialGray1 }} />
                        </div>
                        <div style={{
                          width: '50px',
                          marginLeft: '20px'
                        }}>
                          <Skeleton style={{ height: '25px', backgroundColor: MaterialGray1 }} />
                        </div>
                      </div>
                    </Row>
                    <Skeleton style={{ marginTop: '15px', width: '100%', height: '25px', backgroundColor: MaterialGray1 }} />
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody style={{ paddingBottom: '30px' }}>
                    <Row>
                      <div style={{ paddingTop: '10px', width: '100%', display: 'flex' }} className="col">
                        <div style={{
                          flex: 1
                        }}>
                          <Skeleton style={{ height: '25px', backgroundColor: MaterialGray1 }} />
                        </div>
                        <div style={{
                          width: '50px',
                          marginLeft: '20px'
                        }}>
                          <Skeleton style={{ height: '25px', backgroundColor: MaterialGray1 }} />
                        </div>
                      </div>
                    </Row>
                    <Skeleton style={{ marginTop: '15px', width: '100%', height: '25px', backgroundColor: MaterialGray1 }} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </>
        )
      case DASHBOARD_SUCCESSFUL:
        return (
          <>
            <Row>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0 shadow">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Order Baru
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {
                            props.dashboard.payload.data.today_order_data !== undefined &&
                              props.dashboard.payload.data.today_order_data !== null ?
                              props.dashboard.payload.data.today_order_data.today_count : '0'
                          }
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                          <i className="fas fa-chart-bar" />
                        </div>
                      </Col>
                    </Row>
                    {
                      props.dashboard.payload.data.today_order_data !== undefined && props.dashboard.payload.data.today_order_data.percentage < 0 ?
                        <p className="mt-3 mb-0 text-muted text-sm">
                          <span className="text-danger mr-2">
                            <i className="fas fa-arrow-down" />
                            {
                              `${props.dashboard.payload.data.today_order_data.percentage !== null ?
                                props.dashboard.payload.data.today_order_data.percentage : '0'}%`
                            }
                          </span>{" "}
                          <span className="text-nowrap">Since yesterday</span>
                        </p>
                        :
                        <p className="mt-3 mb-0 text-muted text-sm">
                          {
                            props.dashboard.payload.data.today_order_data !== undefined &&
                            props.dashboard.payload.data.today_order_data.percentage !== null &&
                            props.dashboard.payload.data.today_order_data.percentage > 0 ? 
                            <span className="text-success mr-2">
                              <i className="fa fa-arrow-up"/>
                              {` ${props.dashboard.payload.data.today_order_data.percentage}%`}
                            </span> : 
                            <span className="mr-2">
                              <i className="fa fa-minus"/>
                              {' 0%'}
                            </span>
                          }
                          {" "}
                          <span className="text-nowrap">Since yesterday</span>
                        </p>
                    }
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0 shadow">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Mitra Baru
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">{props.dashboard.payload.data.today_order_data !== undefined ? props.dashboard.payload.data.today_mitra_data.percentage : '0%'}</span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                          <i className="fas fa-users" />
                        </div>
                      </Col>
                    </Row>
                    {
                      props.dashboard.payload.data.today_order_data !== undefined && props.dashboard.payload.data.today_mitra_data.percentage < 0 ?
                        <p className="mt-3 mb-0 text-muted text-sm">
                          <span className="text-danger mr-2">
                            <i className="fas fa-arrow-down" />
                            {
                              props.dashboard.payload.data.today_order_data !== undefined ? `${props.dashboard.payload.data.today_mitra_data.percentage}%` : '0%'
                            }
                          </span>{" "}
                          <span className="text-nowrap">Since yesterday</span>
                        </p>
                        :
                        <p className="mt-3 mb-0 text-muted text-sm">
                          {
                            props.dashboard.payload.data.today_mitra_data !== undefined &&
                            props.dashboard.payload.data.today_mitra_data.percentage !== null &&
                            props.dashboard.payload.data.today_mitra_data.percentage > 0 ? 
                            <span className="text-success mr-2">
                              <i className="fa fa-arrow-up"/>
                              {` ${props.dashboard.payload.data.today_mitra_data.percentage}%`}
                            </span> : 
                            <span className="mr-2">
                              <i className="fa fa-minus"/>
                              {' 0%'}
                            </span>
                          }
                          <span className="text-nowrap">Since yesterday</span>
                        </p>
                    }
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0 shadow">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Transaksi Hari Ini
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {
                            props.dashboard.payload.data.today_transaction_data !== undefined ? `Rp ${formatRupiah(props.dashboard.payload.data.today_transaction_data.today_count.toString())}` : 'Rp 0'
                          }
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                          <i className="fas fa-users" />
                        </div>
                      </Col>
                    </Row>
                    {
                      props.dashboard.payload.data.today_transaction_data !== undefined && props.dashboard.payload.data.today_transaction_data.percentage < 0 ?
                        <p className="mt-3 mb-0 text-muted text-sm">
                          <span className="text-danger mr-2">
                            <i className="fas fa-arrow-down" />
                            {
                              `${props.dashboard.payload.data.today_transaction_data.percentage}%`
                            }
                          </span>{" "}
                          <span className="text-nowrap">Since yesterday</span>
                        </p>
                        :
                        <p className="mt-3 mb-0 text-muted text-sm">
                          {
                            props.dashboard.payload.data.order_trasanction_data !== undefined &&
                            props.dashboard.payload.data.order_trasanction_data.percentage !== null &&
                            props.dashboard.payload.data.order_trasanction_data.percentage > 0 ? 
                            <span className="text-success mr-2">
                              <i className="fa fa-arrow-up"/>
                              {` ${props.dashboard.payload.data.order_trasanction_data.percentage}%`}
                            </span> : 
                            <span className="mr-2">
                              <i className="fa fa-minus"/>
                              {' 0%'}
                            </span>
                          }
                          <span className="text-nowrap">Since yesterday</span>
                        </p>
                    }
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </>
        )
      case DASHBOARD_FAILED:
        return (
          <>
            <Row>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div style={{
                        width: '100%',
                        textAlign: 'center',
                        marginTop: '10px'
                      }}>
                        <i style={{ color: MaterialRed1, fontSize: '30px' }} className="fas fa-exclamation-circle"></i>
                        <p>Tidak dapat memuat data</p>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div style={{
                        width: '100%',
                        textAlign: 'center',
                        marginTop: '10px'
                      }}>
                        <i style={{ color: MaterialRed1, fontSize: '30px' }} className="fas fa-exclamation-circle"></i>
                        <p>Tidak dapat memuat data</p>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div style={{
                        width: '100%',
                        textAlign: 'center',
                        marginTop: '10px'
                      }}>
                        <i style={{ color: MaterialRed1, fontSize: '30px' }} className="fas fa-exclamation-circle"></i>
                        <p>Tidak dapat memuat data</p>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </>
        )
      default:
    }
  }

  return (
    <>
      {buildDashboardPage()}
    </>
  );
};

Header.propTypes = {
  dashboard: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  dashboard: state.dashboard
})

export default connect(mapStateToProps, { getDashboardData })(Header)
