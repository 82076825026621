import { BASE_URL } from 'actions/config';
import axios from 'axios';
import { passingHeaderTokenFormData , passingHeaderToken } from 'components/Utilities/Utility';
import {
    TOC_LIST_LOADING,
    TOC_LIST_SUCCESSFUL,
    TOC_LIST_NOT_FOUND,
    TOC_LIST_FAILED,

    TOC_DETAIL_LOADING,
    TOC_DETAIL_SUCCESSFUL,
    TOC_DETAIL_NOT_FOUND,
    TOC_DETAIL_FAILED,

    TOC_CREATE_LOADING,
    TOC_CREATE_SUCCESSFUL,
    TOC_CREATE_WARNING,
    TOC_CREATE_FAILED,

    TOC_UPDATE_STATUS_LOADING,
    TOC_UPDATE_STATUS_SUCCESSFUL,
    TOC_UPDATE_STATUS_WARNING,
    TOC_UPDATE_STATUS_FAILED,

    TOC_UPDATE_LOADING,
    TOC_UPDATE_SUCCESSFUL,
    TOC_UPDATE_NOT_FOUND,
    TOC_UPDATE_WARNING,
    TOC_UPDATE_FAILED,

    TOC_REMOVE_LOADING,
    TOC_REMOVE_SUCCESSFUL,
    TOC_REMOVE_NOT_FOUND,
    TOC_REMOVE_UNAUTHORIZED,
    TOC_REMOVE_FAILED,

    TOC_RESET_CRUD
} from './Types';

export const getTOC = (page , limit , token) => async dispatch => {
    try {
        dispatch({
            type : TOC_LIST_LOADING,
            message : 'Memuat data toc'
        })
        const response = await axios.get(`${BASE_URL}/api/toc/index?page=${page}&limit=${limit}` , passingHeaderToken(token))
        console.log(`Data : ${JSON.stringify(response.data , null , 2)}`)
        setTimeout(() => {
            if(response.data != null && response.data.rows_data.docs.length > 0){
                dispatch({
                    type : TOC_LIST_SUCCESSFUL,
                    data : response.data
                })
            } else {
                dispatch({
                    type : TOC_LIST_NOT_FOUND,
                    message : 'Tidak ada data toc'
                })
            }
        } , 300)
    } catch (err) {
        setTimeout(() => {
            if(err.response !== undefined && err.response.status === 404){
                dispatch({
                    type : TOC_LIST_NOT_FOUND,
                    message : 'Tidak ada data toc'
                })
            } else {
                dispatch({
                    type : TOC_LIST_FAILED,
                    message : 'Gagal memuat data toc'
                })
            }
        } , 300)
    }
}

export const getDetailTOC = (id , toc_type , toc_user_type , token) => async dispatch => {
    try {
        dispatch({
            type : TOC_DETAIL_LOADING,
            message : 'Memuat data toc'
        })
        console.log('URL : ' + `${BASE_URL}/api/toc/detail/${id}/${toc_type}/${toc_user_type}`)
        const response = await axios.get(`${BASE_URL}/api/toc/detail/${id}/${toc_type}/${toc_user_type}` , passingHeaderToken(token))
        console.log(`Response : ${JSON.stringify(response.data , null , 2)}`)
        setTimeout(() => {
            dispatch({
                type : TOC_DETAIL_SUCCESSFUL,
                data : response.data
            })
        } , 300)
    } catch (err) {
        if(err.response !== undefined && err.response.status === 404){
            setTimeout(() => {
                dispatch({
                    type : TOC_DETAIL_NOT_FOUND,
                    message : 'Tidak ada data toc'
                })
            } , 300)
        } else {
            setTimeout(() => {
                dispatch({
                    type : TOC_DETAIL_FAILED,
                    message : 'Gagal memuat data toc'
                })
            } , 300)
        }
    }
}

export const createTOC = (payload , force , token) => async dispatch => {
    try {
        dispatch({
            type : TOC_CREATE_LOADING,
            message : 'Membuat data toc'
        })
        await axios.post(`${BASE_URL}/api/toc/create/${force}` , payload , passingHeaderToken(token))
        dispatch({
            type : TOC_CREATE_SUCCESSFUL,
            message : 'Data toc terbuat'
        })
    } catch (err) {
        console.log(err)
        if(err.response !== undefined && err.response.status == 409){
            dispatch({
                type : TOC_CREATE_WARNING,
                message : 'Masih ada TOC aktif dengan kategori untuk user ini'
            })
        } else {
            dispatch({
                type : TOC_CREATE_FAILED,
                message : 'Gagal membuat data toc'
            })
        }
    }
}
export const updateTocStatus = (id  , toc_type, toc_user_type , is_active , token) => async dispatch => {
    try {
        dispatch({
            type : TOC_UPDATE_STATUS_LOADING,
            message : 'Memperbarui status TOC'
        })
        const resp = await axios.put(`${BASE_URL}/api/toc/update/status/${id}/${toc_type}/${toc_user_type}/${is_active}` , {} , passingHeaderToken(token))
        console.log(`Response : ${resp}`)
        setTimeout(() => {
            dispatch({
                type : TOC_UPDATE_STATUS_SUCCESSFUL,
                message : 'Berhasil memperbarui status TOC'
            })
        } , 300)
    } catch (err) {
        console.log(`Err : ${JSON.stringify(err.response , null , 2)}`)
        if(err.response !== null && err.response.status == 409){
            dispatch({
                type : TOC_UPDATE_STATUS_WARNING,
                message : err.response
            })
        } else {
            dispatch({
                type : TOC_UPDATE_STATUS_FAILED,
                message : 'Gagal memperbarui status TOC'
            })
        }
    }
}
export const updateTOC = (id , payload , force , token) => async dispatch => {
    try {
        dispatch({
            type : TOC_UPDATE_LOADING,
            message : 'Memperbarui data toc'
        })
        console.log(`Payload : ${JSON.stringify(payload , null , 2)}`)
        await axios.put(`${BASE_URL}/api/toc/update/${id}/${force}` , payload , passingHeaderToken(token))
        setTimeout(() => {
            dispatch({
                type : TOC_UPDATE_SUCCESSFUL,
                message : 'Berhasil memperbarui data toc'
            })
        } , 300)
    } catch (err) {
        setTimeout(() => {
            if(err.response !== undefined && err.response.status === 404){
                dispatch({
                    type : TOC_UPDATE_NOT_FOUND,
                    message : 'Tidak ada data toc'
                })
            } else if(err.response !== undefined && err.response.status === 409){
                dispatch({
                    type : TOC_UPDATE_WARNING,
                    message : 'Masih ada TOC yang aktif'
                })
            } else {
                dispatch({
                    type : TOC_UPDATE_FAILED,
                    message : 'Gagal memperbarui data toc'
                })
            }
        } , 300)
    }
}

export const removeTOC = (id , token) => async dispatch => {
    try {
        dispatch({
            type : TOC_REMOVE_LOADING,
            message : 'Menghapus data toc'
        })
        axios.delete(`${BASE_URL}/api/toc/remove/${id}` , passingHeaderToken(token))
        setTimeout(() => {
            dispatch({
                type : TOC_REMOVE_SUCCESSFUL,
                message : 'Berhasil menghapus data toc'
            })
        } , 300)
    } catch (err) {
        console.log(err)
        if(err.response !== undefined && err.response.status === 404){
            dispatch({
                type : TOC_REMOVE_NOT_FOUND,
                message : 'Tidak ada data toc'
            })
        } else if(err.response !== undefined && err.response.status === 401){
            dispatch({
                type : TOC_REMOVE_UNAUTHORIZED,
                message : 'Password salah'
            })
        } else {
            dispatch({
                type : TOC_REMOVE_FAILED,
                message : 'Gagal menghapus data toc'
            })
        }
    }
}

export const setTOCEmpty = () => async dispatch => {
    dispatch({
        type : TOC_LIST_NOT_FOUND,
        message : 'Data toc tidak ada'
    })
}

export const resetCrud = () => async dispatch => {
    dispatch({
        type : TOC_RESET_CRUD,
        message : ''
    })
}