import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
    Container,
    Card,
    Row,
    Col,
    CardBody,
    Form,
    FormGroup,
    Input
} from 'reactstrap';
import 'assets/css/editor.css';
import { useEffect, useRef, useReducer, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getDetailTOC, updateTOC, resetCrud } from 'actions/Toc/TocService';
import {
    TOC_DETAIL_LOADING,
    TOC_DETAIL_SUCCESSFUL,
    TOC_DETAIL_NOT_FOUND,
    TOC_DETAIL_FAILED,

    TOC_UPDATE_LOADING,
    TOC_UPDATE_SUCCESSFUL,
    TOC_UPDATE_EXIST,
    TOC_UPDATE_FAILED
} from 'actions/Toc/Types';
import '../../../assets/css/utilities.css';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import withReactContent from 'sweetalert2-react-content';
import { MaterialRed1 } from 'components/Utilities/ResourceColors';
import Select from 'react-select';
import { RED_ERROR_MESSAGE } from 'components/Utilities/ResourceColors';
import CKEditor from "react-ckeditor-component";
import { MaterialBlue2 } from 'components/Utilities/ResourceColors';
import { validateEmpty } from 'components/Utilities/Utility';
import { JSON_CREDENTIALS } from 'actions/config';
import IcNotFound from 'assets/img/ic_not_found.svg';
import IcError from 'assets/img/ic_error.svg';
import Skeleton from 'react-loading-skeleton';
import NotFoundPage from 'components/NotFound/NotFoundPage';
import { MaterialGray1 } from 'components/Utilities/ResourceColors';
import ErrorPage from 'components/ErrorComponent/ErrorPage';
import { TOC_UPDATE_WARNING } from 'actions/Toc/Types';
import { MaterialBlue3 } from 'components/Utilities/ResourceColors';

const MySwal = withReactContent(Swal)

const TocCreatePage = (props) => {
    const [token, setToken] = useState('')
    const [isLoading, setLoading] = useState(false)
    const options = [
        {
            value: 'terms_of_condition', label: 'Terms of Condition'
        },
        {
            value: 'terms_of_service', label: 'Terms of Service'
        },
        {
            value: 'privacy_policy', label: 'Privacy Policy'
        }
    ]
    const optsUserType = [
        {
            value: 'customer', label: 'Customer'
        },
        {
            value: 'mitra', label: 'Mitra'
        }
    ]
    const mainContent = useRef(null)
    const location = useLocation()
    const history = useHistory()
    const fileRef = useRef()
    const [isTocActive, setTocActive] = useState(false)
    const [isSubmit, setSubmit] = useState(false)
    const [inputValues, setInputValues] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            title: '',
            body: '',
            toc_type: {
                label: 'Terms of Condition',
                value: 'terms_of_condition'
            },
            toc_user_type: {
                label: 'Customer',
                value: 'customer'
            }
        }
    )
    const onBlur = (evt) => {
        console.log('onBlur')
    }
    const afterPaste = (evt) => {
        console.log('after paste')
    }
    const onChange = (evt) => {
        setInputValues({ ['body']: evt.editor.getData() })
    }
    useEffect(() => {
        if (props.toc.payload.data !== undefined) {
            console.log(`Payload : ${JSON.stringify(props.toc.payload.data , null , 2)}`)
            for (const key in props.toc.payload.data) {
                if (props.toc.payload.data.hasOwnProperty(key)) {
                    if (key === 'toc_type') {
                        let typeValue = props.toc.payload.data[key]
                        if(typeValue === 'terms_of_service'){
                            typeValue = 'Terms of Service'
                        } else if(typeValue === 'terms_of_condition'){
                            typeValue = 'Terms of Condition'
                        } else if(typeValue === 'privacy_policy'){
                            typeValue = 'Privacy Policy'
                        }
                        setInputValues({
                            ['toc_type']: {
                                value: props.toc.payload.data[key],
                                label: typeValue
                            }
                        })
                    } else if(key === 'toc_user_type'){
                        setInputValues({
                            ['toc_user_type'] : {
                                value : props.toc.payload.data[key],
                                label : props.toc.payload.data[key].charAt(0).toUpperCase() + props.toc.payload.data[key].slice(1)
                            }
                        })
                    } else if(key === 'is_active'){
                        if(props.toc.payload.data[key] != null){
                            setTocActive(props.toc.payload.data[key] === '1')
                        }
                    } else {
                        setInputValues({ [key]: props.toc.payload.data[key] })
                    }
                }
            }
        }
    }, [props.toc.payload])
    useEffect(() => {
        setToken(localStorage.getItem('token'))
        if (validateEmpty(token) && props.location.state.toc_id !== undefined) {
            props.getDetailTOC(props.location.state.toc_id , props.location.state.toc_type, props.location.state.toc_user_type, token)
        }
    }, [token])
    const onChangeHandler = event => {
        const { name, value } = event.target
        setInputValues({ [name]: value })
    }
    useEffect(() => {
        if (!localStorage.getItem('token')) {
            history.push('/auth/login')
        }
    }, [location])
    useEffect(() => {
        switch (props.toc.payload_crud.type) {
            case TOC_UPDATE_LOADING:
                setLoading(true)
                break;
            case TOC_UPDATE_SUCCESSFUL:
                setLoading(false)
                history.goBack()
                MySwal.fire(
                    'Perhatian',
                    'Berhasil Memperbarui TOC',
                    'success'
                )
                props.resetCrud()
                break;
            case TOC_UPDATE_WARNING:
                setLoading(false)
                MySwal.fire({
                    title : 'Perhatian',
                    text : 'Masih ada TOC Aktif dengan kategori untuk user ini, Yakin ingin mengganti nya dengan TOC ini ?',
                    showCancelButton : true,
                    confirmButtonText : 'Ya',
                    confirmButtonColor : MaterialBlue3,
                    cancelButtonColor : MaterialGray1,
                    cancelButtonText : 'Batal'
                }).then((result) => {
                    if(result.isConfirmed){
                        updateTOC(true)
                    }
                })
                break;
            case TOC_UPDATE_FAILED:
                setLoading(false)
                MySwal.fire(
                    'Perhatian',
                    'Gagal Memperbarui TOC',
                    'error'
                )
                break;
        }
    }, [props.toc.payload_crud])

    function updateTOC(force) {
        setSubmit(true)
        const {
            title,
            body
        } = inputValues
        const toc_type = inputValues.toc_type.value
        const toc_user_type = inputValues.toc_user_type.value

        if (props.location.state.toc_id !== undefined &&
            localStorage.getItem(JSON_CREDENTIALS) != null && validateEmpty(token) &&
            validateEmpty(title) && validateEmpty(body) && validateEmpty(toc_type) && validateEmpty(toc_user_type)) {
            const data = JSON.parse(localStorage.getItem(JSON_CREDENTIALS))
            const payload = {
                creator_id: data.data.id,
                title: title,
                body: body,
                is_active: isTocActive ? '1' : '0',
                toc_type: toc_type,
                toc_user_type: toc_user_type
            }
            props.updateTOC(props.location.state.toc_id , payload , force , token)
        }
    }

    function buildTocDetail() {
        switch (props.toc.payload.type) {
            case TOC_DETAIL_LOADING:
                return (
                    <Container className="mt--7" fluid>
                        <Row>
                            <Col className="order-xl-1" xl="12">
                                <Card className="bg-secondary shadow">
                                    <CardBody>
                                        <Form>
                                            <div>
                                                <Row>
                                                    <Col lg="12">
                                                        <FormGroup>
                                                            <Skeleton style={{ height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg="12">
                                                        <Skeleton style={{ height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Skeleton style={{ marginTop: '30px', height: '200px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg="12">
                                                        <FormGroup>
                                                            <Skeleton style={{ marginTop: '30px', height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                )
            case TOC_DETAIL_SUCCESSFUL:
                return (
                    <Container className="mt--7" fluid>
                        <Row>
                            <Col className="order-xl-1" xl="12">
                                <Card className="bg-secondary-shadow">
                                    {
                                        isLoading ?
                                            <div style={{
                                                borderTopLeftRadius: '5px',
                                                borderTopRightRadius: '5px',
                                                height: '5px'
                                            }} className="progress-bar">
                                                <div className="progress-bar-value"></div>
                                            </div>
                                            : <div style={{
                                                height: '5px'
                                            }}></div>
                                    }
                                    <CardBody>
                                        <Form>
                                            <div>
                                                <Row>
                                                    <Col md="6">
                                                        <Row>
                                                            <Col lg="12">
                                                                <FormGroup>
                                                                    <label
                                                                        className="form-control-label"
                                                                        htmlFor="input-title">
                                                                        Judul TOC
                                                                    </label>
                                                                    <Input
                                                                        className="form-control-alternative"
                                                                        id="input-title"
                                                                        type="text"
                                                                        name="title"
                                                                        onChange={onChangeHandler}
                                                                        value={inputValues.title}
                                                                        style={
                                                                            isSubmit && inputValues.title === '' ?
                                                                                {
                                                                                    color: 'black',
                                                                                    border: '0.2px solid #d32f2f'
                                                                                }
                                                                                :
                                                                                {
                                                                                    color: 'black',
                                                                                    border: 'none'
                                                                                }
                                                                        } />
                                                                    <p style={{
                                                                        color: MaterialRed1,
                                                                        fontSize: '11px',
                                                                        marginTop: '5px',
                                                                        visibility: isSubmit && inputValues.title === '' ? 'visible' : 'hidden'
                                                                    }}>TOC Title must be filled</p>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row style={{ marginTop: '-30px' }}>
                                                            <Col lg="6">
                                                                <FormGroup>
                                                                    <label
                                                                        className="form-control-label"
                                                                        htmlFor="input-select-type"
                                                                    >
                                                                        Tipe TOC
                                                                    </label>
                                                                    <Select
                                                                        id="input-select-type"
                                                                        options={options}
                                                                        value={
                                                                            inputValues.toc_type
                                                                        }
                                                                        name="toc_type"
                                                                        onChange={(selectedOption) => {
                                                                            setInputValues({ ['toc_type']: selectedOption })
                                                                        }} />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col lg="6">
                                                                <FormGroup>
                                                                    <label
                                                                        className="form-control-label"
                                                                        htmlFor="input-toc-user-type"
                                                                    >
                                                                        Tipe User TOC
                                                                    </label>
                                                                    <Select
                                                                        id="input-toc-user-type"
                                                                        options={optsUserType}
                                                                        value={
                                                                            inputValues.toc_user_type
                                                                        }
                                                                        name="toc_user_type"
                                                                        onChange={(selectedOption) => {
                                                                            setInputValues({ ['toc_user_type']: selectedOption })
                                                                        }} />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg="6">
                                                                <label
                                                                    className="form-control-label"
                                                                >
                                                                    Aktifkan TOC?
                                                                </label>
                                                                <Row>
                                                                    <Col md="5">
                                                                        <input
                                                                            id="is_broadcast"
                                                                            type="radio"
                                                                            name="is_broadcast_yes"
                                                                            style={{ marginRight: '5px' }}
                                                                            checked={isTocActive === true}
                                                                            onChange={e => {
                                                                                setTocActive(true)
                                                                            }} />
                                                                        <label
                                                                            className="form-control-label"
                                                                            htmlFor="is_broadcast" style={{ fontSize: '13px' }}>Yes</label>
                                                                    </Col>
                                                                    <Col md="5">
                                                                        <input
                                                                            id="is_broadcast_no"
                                                                            type="radio"
                                                                            name="is_broadcast_no"
                                                                            style={{ marginRight: '5px' }}
                                                                            checked={isTocActive === false}
                                                                            onChange={e => {
                                                                                setTocActive(false)
                                                                            }} />
                                                                        <label
                                                                            className="form-control-label"
                                                                            htmlFor="is_broadcast_no" style={{ fontSize: '13px' }}>No</label>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col md="6">
                                                        <div style={{
                                                            alignItems: 'center',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            borderRadius: '10px'
                                                        }}>
                                                            <div style={{
                                                                alignItems: 'center',
                                                                display: 'flex',
                                                                width: '100%',
                                                                height: '100%',
                                                                borderRadius: '10px',
                                                                justifyContent: 'center'
                                                            }}>
                                                                <div style={{
                                                                    display: 'table-cell',
                                                                    textAlign: 'center',
                                                                    verticalAlign: 'middle'
                                                                }}>
                                                                    <i className="fa-solid fa-shield-halved" style={{ color: MaterialBlue2, fontSize: '130px', textAlign: 'center' }} />
                                                                    <br />
                                                                    <p style={{
                                                                        marginTop: '25px',
                                                                        fontSize: '14px'
                                                                    }}>
                                                                        Terms of Service , Terms and Condition dan Privacy Policy dibuat untuk mengatur kebijakan customer atau mitra tentang pemakaian aplikasi Suberes dan SuberesMitra serta tanggung jawab kita sebagai aplikator dalam memegang data pengguna
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg="12">
                                                        <Row style={{ marginTop: '15px' }}>
                                                            <Col style={{ height: '400px' }} lg="12">
                                                                <FormGroup>
                                                                    <label
                                                                        className="form-control-label"
                                                                        htmlFor="input-toc-description"
                                                                    >
                                                                        Isi TOC
                                                                    </label>
                                                                    <div style={{
                                                                        height: '328px',
                                                                        border: isSubmit && (inputValues.body === null || inputValues.body === '<p></p>') ? `1px solid ${RED_ERROR_MESSAGE}` : '1px solid black',
                                                                        borderRadius: '10px'
                                                                    }}>
                                                                        <CKEditor
                                                                            content={inputValues.body}
                                                                            events={{
                                                                                blur: onBlur,
                                                                                afterPaste: afterPaste,
                                                                                change: onChange
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <p style={{
                                                                        color: MaterialRed1,
                                                                        fontSize: '11px',
                                                                        marginTop: '5px',
                                                                        visibility: isSubmit && (inputValues.body === null || inputValues.body === '<p></p>') ? 'visible' : 'hidden'
                                                                    }}>Isi TOC must be filled</p>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg="12">
                                                        <a onClick={() => updateTOC(false)} style={{ width: '100%' }} className="btn btn-success btn-sm">
                                                            Simpan
                                                        </a>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                )
            case TOC_DETAIL_NOT_FOUND:
                return (
                    <Container className="mt--7" fluid>
                        <Row>
                            <Col className="order-xl-1" xl="12">
                                <Card className="bg-secondary-shadow">
                                    <CardBody>
                                        <NotFoundPage
                                            imageSource={IcNotFound}
                                            imageText={props.toc.payload.message} />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                )
            case TOC_DETAIL_FAILED:
                return (
                    <Container className="mt--7" fluid>
                        <Row>
                            <Col className="order-xl-1" xl="12">
                                <Card className="bg-secondary-shadow">
                                    <CardBody>
                                        <ErrorPage
                                            imageSource={IcError}
                                            imageText={props.toc.payload.message} />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                )
        }
    }

    return (
        <>
            <div className="main-content" ref={mainContent}>
                <div className="header bg-gradient-info pb-8 pt-5 pt-lg-7 d-flex align-items-center">
                    <span className="mask bg-gradient-default opacity-8" />
                </div>
                {buildTocDetail()}
            </div>
        </>
    )
}

TocCreatePage.propTypes = {
    toc: PropTypes.object.isRequired,
    getDetailTOC: PropTypes.func.isRequired,
    updateTOC: PropTypes.func.isRequired,
    resetCrud: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    toc: state.toc
})

export default connect(mapStateToProps, { getDetailTOC, updateTOC, resetCrud })(TocCreatePage)