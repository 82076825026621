import {
    LAYANAN_LIST_LOADING,
    LAYANAN_LIST_SUCCESSFUL,
    LAYANAN_LIST_FAILED,
    LAYANAN_LIST_NOT_FOUND,
    LAYANAN_CREATE_LOADING,
    LAYANAN_CREATE_SUCCESSFUL,
    LAYANAN_CREATE_EXIST,
    LAYANAN_CREATE_FAILED,
    LAYANAN_UPDATE_LOADING,
    LAYANAN_UPDATE_SUCCESSFUL,
    LAYANAN_UPDATE_NOT_FOUND,
    LAYANAN_UPDATE_FAILED,
    LAYANAN_REMOVE_LOADING,
    LAYANAN_REMOVE_SUCCESSFUL,
    LAYANAN_REMOVE_NOT_FOUND,
    LAYANAN_REMOVE_UNAUTHORIZED,
    LAYANAN_REMOVE_FAILED,
    LAYANAN_DETAIL_LOADING,
    LAYANAN_DETAIL_SUCCESSFUL,
    LAYANAN_DETAIL_NOT_FOUND,
    LAYANAN_DETAIL_FAILED,
    PARENT_CREATE_LOADING,
    PARENT_CREATE_SUCCESSFUL,
    PARENT_CREATE_FAILED,
    PARENT_DETAIL_LOADING,
    PARENT_DETAIL_SUCCESSFUL,
    PARENT_DETAIL_NOT_FOUND, PARENT_DETAIL_FAILED,
    PARENT_UPDATE_LOADING,
    PARENT_UPDATE_SUCCESSFUL,
    PARENT_UPDATE_NOT_FOUND,
    PARENT_UPDATE_FAILED,
    PARENT_REMOVE_LOADING,
    PARENT_REMOVE_SUCCESSFUL,
    PARENT_REMOVE_UNAUTHORIZED,
    PARENT_REMOVE_NOT_FOUND,
    PARENT_REMOVE_FAILED,

    LAYANAN_RESET_CRUD
} from 'actions/LayananService/Types';

const initState = {
    type: null,
    payload: {},
    parent_detail_payload: {
        type: null
    },
    payload_crud: {
        payload: {
            type: null,
            message: ''
        }
    },
    parent_create: {
        payload: {
            type: null,
            message: ''
        }
    },
    parent_update: {
        payload: {
            type: null,
            message: ''
        }
    }
}
export default function (state = initState, action) {
    switch (action.type) {
        case LAYANAN_LIST_LOADING:
            return {
                ...state,
                type: action.type,
                payload: {
                    type: action.type,
                    message: action.payload.message
                }
            }
        case LAYANAN_LIST_SUCCESSFUL:
            return {
                ...state,
                type: action.type,
                payload: {
                    type: action.type,
                    data: action.payload.data
                }
            }
        case LAYANAN_LIST_NOT_FOUND:
            return {
                ...state,
                type: action.type,
                payload: {
                    type: action.type,
                    message: action.payload.message
                }
            }
        case LAYANAN_LIST_FAILED:
            return {
                ...state,
                type: action.type,
                payload: {
                    type: action.type,
                    message: action.payload.message
                }
            }
        case LAYANAN_DETAIL_LOADING:
            return {
                ...state,
                type: action.type,
                payload: {
                    type: action.type,
                    message: action.payload.message
                }
            }
        case LAYANAN_DETAIL_SUCCESSFUL:
            return {
                ...state,
                type: action.type,
                payload: {
                    type: action.type,
                    data: action.payload.data
                }
            }
        case LAYANAN_DETAIL_NOT_FOUND:
            return {
                ...state,
                type: action.type,
                payload: {
                    type: action.type,
                    message: action.payload.message
                }
            }
        case LAYANAN_DETAIL_FAILED:
            return {
                ...state,
                type: action.type,
                payload: {
                    type: action.type,
                    message: action.payload.message
                }
            }

        case LAYANAN_CREATE_LOADING:
            return {
                ...state,
                payload_crud: action.payload
            }
        case LAYANAN_CREATE_SUCCESSFUL:
            return {
                ...state,
                payload_crud: action.payload
            }
        case LAYANAN_CREATE_EXIST:
            return {
                ...state,
                payload_crud: action.payload
            }
        case LAYANAN_CREATE_FAILED:
            return {
                ...state,
                payload_crud: action.payload
            }

        case LAYANAN_UPDATE_LOADING:
            return {
                ...state,
                payload_crud: action.payload
            }
        case LAYANAN_UPDATE_SUCCESSFUL:
            return {
                ...state,
                payload_crud: action.payload
            }
        case LAYANAN_UPDATE_NOT_FOUND:
            return {
                ...state,
                payload_crud: action.payload
            }
        case LAYANAN_UPDATE_FAILED:
            return {
                ...state,
                payload_crud: action.payload
            }

        case LAYANAN_REMOVE_LOADING:
            return {
                ...state,
                payload_crud: action.payload
            }
        case LAYANAN_REMOVE_SUCCESSFUL:
            return {
                ...state,
                payload_crud: action.payload
            }
        case LAYANAN_REMOVE_NOT_FOUND:
            return {
                ...state,
                payload_crud: action.payload
            }
        case LAYANAN_REMOVE_UNAUTHORIZED:
            return {
                ...state,
                payload_crud: action.payload
            }
        case LAYANAN_REMOVE_FAILED:
            return {
                ...state,
                payload_crud: action.payload
            }
        case LAYANAN_RESET_CRUD:
            return {
                ...state,
                payload_crud: {
                    payload: {
                        type: null,
                        message: ''
                    }
                }
            }

        case PARENT_CREATE_LOADING:
            return {
                ...state,
                parent_create: {
                    payload: {
                        type: action.type,
                        message: action.payload.message
                    }
                }
            }
        case PARENT_CREATE_SUCCESSFUL:
            return {
                ...state,
                parent_create: {
                    payload: {
                        type: action.type,
                        message: action.payload.message
                    }
                }
            }
        case PARENT_CREATE_FAILED:
            return {
                ...state,
                parent_create: {
                    payload: {
                        type: action.type,
                        message: action.payload.message
                    }
                }
            }
        case PARENT_DETAIL_LOADING:
            return {
                ...state,
                parent_detail_payload: {
                    type: action.type,
                    message: 'Memuat Data Paket'
                }
            }
        case PARENT_DETAIL_SUCCESSFUL:
            return {
                ...state,
                parent_detail_payload: {
                    type: action.type,
                    data: action.payload.data
                }
            }
        case PARENT_DETAIL_NOT_FOUND:
            return {
                ...state,
                parent_detail_payload: {
                    type: action.type,
                    message: 'Tidak Ada Data Paket'
                }
            }
        case PARENT_DETAIL_FAILED:
            return {
                ...state,
                parent_detail_payload: {
                    type: action.type,
                    message: 'Gagal Memuat Data Paket'
                }
            }
        case PARENT_UPDATE_LOADING:
            return {
                ...state,
                parent_update: {
                    payload: {
                        type: action.type,
                        message: action.message
                    }
                }
            }
        case PARENT_UPDATE_SUCCESSFUL:
            return {
                ...state,
                parent_update: {
                    payload: {
                        type: action.type,
                        message: action.message
                    }
                }
            }
        case PARENT_UPDATE_NOT_FOUND:
            return {
                ...state,
                parent_update: {
                    payload: {
                        type: action.type,
                        message: action.message
                    }
                }
            }
        case PARENT_UPDATE_FAILED:
            return {
                ...state,
                parent_update: {
                    payload: {
                        type: action.type,
                        message: action.message
                    }
                }
            }
        case PARENT_REMOVE_LOADING:
            return {
                ...state,
                payload_crud: {
                    payload: action.payload
                }
            }
        case PARENT_REMOVE_SUCCESSFUL:
            return {
                ...state,
                payload_crud: {
                    payload: action.payload
                }
            }
        case PARENT_REMOVE_UNAUTHORIZED:
            return {
                ...state,
                payload_crud: {
                    payload: action.payload
                }
            }
        case PARENT_REMOVE_NOT_FOUND:
            return {
                ...state,
                payload_crud: {
                    payload: action.payload
                }
            }
        case PARENT_REMOVE_FAILED:
            return {
                ...state,
                payload_crud: {
                    payload: action.payload
                }
            }
        default:
            return state
    }
}