import { SERVICE_REMOVE_UNAUTHORIZED } from 'actions/Service/Types';
import {
    SERVICE_LIST_LOADING,
    SERVICE_LIST_SUCCESSFUL,
    SERVICE_LIST_NOT_FOUND,
    SERVICE_LIST_FAILED,
    SERVICE_DETAIL_LOADING,
    SERVICE_DETAIL_SUCCESSFUL,
    SERVICE_DETAIL_NOT_FOUND,
    SERVICE_DETAIL_FAILED,
    SERVICE_CREATE_LOADING,
    SERVICE_CREATE_SUCCESSFUL,
    SERVICE_CREATE_FAILED,
    SERVICE_UPDATE_LOADING,
    SERVICE_UPDATE_SUCCESSFUL,
    SERVICE_UPDATE_NOT_FOUND,
    SERVICE_UPDATE_FAILED,
    SERVICE_REMOVE_LOADING,
    SERVICE_REMOVE_SUCCESSFUL,
    SERVICE_REMOVE_NOT_FOUND,
    SERVICE_REMOVE_FAILED
} from 'actions/Service/Types';

const initState = {
    parent_service_payload: {
        type: SERVICE_LIST_LOADING,
        message: ''
    },
    service_detail_payload: {
        type: null,
        message: '',
    },
    service_create: {
        type: null,
        message: ''
    },
    service_update: {
        type: null,
        message: ''
    },
    service_remove: {
        type: null,
        message: ''
    }
}

export default function (state = initState, action) {
    switch (action.type) {
        case SERVICE_LIST_LOADING: {
            return {
                ...state,
                parent_service_payload: {
                    type: action.type,
                    message: action.message
                }
            }
        }
        case SERVICE_LIST_SUCCESSFUL: {
            return {
                ...state,
                parent_service_payload: {
                    type: action.type,
                    data: action.data
                }
            }
        }
        case SERVICE_LIST_NOT_FOUND: {
            return {
                ...state,
                parent_service_payload: {
                    type: action.type,
                    message: action.message
                }
            }
        }
        case SERVICE_LIST_FAILED: {
            return {
                ...state,
                parent_service_payload: {
                    type: action.type,
                    message: action.message
                }
            }
        }
        case SERVICE_DETAIL_LOADING: {
            return {
                ...state,
                service_detail_payload: {
                    type: action.type,
                    message: 'Memuat Data Layanan'
                }
            }
        }
        case SERVICE_DETAIL_SUCCESSFUL: {
            return {
                ...state,
                service_detail_payload: {
                    type: action.type,
                    data: action.data
                }
            }
        }
        case SERVICE_DETAIL_NOT_FOUND: {
            return {
                ...state,
                service_detail_payload: {
                    type: action.type,
                    message: 'Tidak Ada Data Layanan'
                }
            }
        }
        case SERVICE_DETAIL_FAILED: {
            return {
                ...state,
                service_detail_payload: {
                    type: action.type,
                    message: 'Gagal Memuat Data Layanan'
                }
            }
        }
        case SERVICE_CREATE_LOADING: {
            return {
                ...state,
                service_create: {
                    type: action.type,
                    message: action.message
                }
            }
        }
        case SERVICE_CREATE_SUCCESSFUL: {
            return {
                ...state,
                service_create: {
                    type: action.type,
                    data : action.message
                }
            }
        }
        case SERVICE_CREATE_FAILED: {
            return {
                ...state,
                service_create: {
                    type: action.type,
                    message: action.message
                }
            }
        }
        case SERVICE_UPDATE_LOADING: {
            return {
                ...state,
                service_update: {
                    type: action.type,
                    message: action.message
                }
            }
        }
        case SERVICE_UPDATE_SUCCESSFUL: {
            return {
                ...state,
                service_update: {
                    type: action.type,
                    message: action.message
                }
            }
        }
        case SERVICE_UPDATE_NOT_FOUND: {
            return {
                ...state,
                service_update: {
                    type: action.type,
                    message: action.message
                }
            }
        }
        case SERVICE_UPDATE_FAILED: {
            return {
                ...state,
                service_update: {
                    type: action.type,
                    message: action.message
                }
            }
        }
        case SERVICE_REMOVE_LOADING: {
            return {
                ...state,
                service_remove: {
                    type: action.type,
                    message: action.message
                }
            }
        }
        case SERVICE_REMOVE_SUCCESSFUL: {
            return {
                ...state,
                service_remove: {
                    type: action.type,
                    message: action.message
                }
            }
        }
        case SERVICE_REMOVE_UNAUTHORIZED:{
            return {
                ...state,
                service_remove: {
                    type: action.type,
                    message: action.message
                }
            }
        }
        case SERVICE_REMOVE_NOT_FOUND: {
            return {
                ...state,
                service_remove: {
                    type: action.type,
                    message: action.message
                }
            }
        }
        case SERVICE_REMOVE_FAILED: {
            return {
                ...state,
                service_remove: {
                    type: action.type,
                    message: action.message
                }
            }
        }
        default:
            return state
    }
}