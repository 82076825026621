import { BASE_URL } from "actions/config";
import axios from 'axios';
import { passingHeaderToken } from "components/Utilities/Utility";

import {
    TRANSACTION_LIST_LOADING,
    TRANSACTION_LIST_SUCCESSFUL,
    TRANSACTION_LIST_NOT_FOUND,
    TRANSACTION_LIST_FAILED,

    TRANSACTION_DETAIL_LOADING,
    TRANSACTION_DETAIL_SUCCESSFUL,
    TRANSACTION_DETAIL_NOT_FOUND,
    TRANSACTION_DETAIL_FAILED
} from './Types';

export const getTransactions = (page , limit , search , token) => async dispatch => {
    try {
        dispatch({
            type : TRANSACTION_LIST_LOADING,
            message : 'Memuat data transaksi'
        })
        const response = await axios.get(`${BASE_URL}/api/transactions/index/admin?page=${page}&limit=${limit}&search=${search}` , passingHeaderToken(token))
        console.log(`Response : ${JSON.stringify(response.data , null , 2)}`)
        setTimeout(() => {
            if(response.data != null && response.data.rows_data.docs.length > 0){
                dispatch({
                    type : TRANSACTION_LIST_SUCCESSFUL,
                    data : response.data
                })
            } else {
                dispatch({
                    type : TRANSACTION_LIST_NOT_FOUND,
                    message : 'Tidak ada data transaksi'
                })
            }
        } , 300)
    } catch (err) {
        setTimeout(() => {
            if(err.response !== undefined && err.response.status == 404){
                dispatch({
                    type : TRANSACTION_LIST_NOT_FOUND,
                    message : 'Tidak ada data transaksi'
                })
            } else {
                dispatch({
                    type : TRANSACTION_LIST_FAILED,
                    message : 'Gagal memuat data transaksi'
                })
            }
        } , 300)
    }
}

export const getTransactionDetail = (id , token) => async dispatch => {
    try {
        dispatch({
            type : TRANSACTION_DETAIL_LOADING,
            message : 'Memuat data transaksi'
        })
        const response = await axios.get(`${BASE_URL}/api/transactions/admin/detail/${id}` , passingHeaderToken(token))
        setTimeout(() => {
            if(response.data != null){
                dispatch({
                    type : TRANSACTION_DETAIL_SUCCESSFUL,
                    data : response.data
                })
            } else {
                dispatch({
                    type : TRANSACTION_DETAIL_NOT_FOUND,
                    message : 'Data transaksi tidak ditemukan'
                })
            }
        } , 300)
    } catch (err) {
        setTimeout(() => {
            if(err.response !== undefined && err.response.status === 404){
                dispatch({
                    type : TRANSACTION_DETAIL_NOT_FOUND,
                    message : 'Data transakdi tidak ditemukan'
                })
            } else {
                dispatch({
                    type : TRANSACTION_DETAIL_FAILED,
                    message : 'Gagal memuat data transaksi'
                })
            }
        } , 300)
    }
}