import { ORDER_DETAIL_NOT_FOUND } from "actions/Orders/Types";
import { 
    ORDER_LIST_LOADING,
    ORDER_LIST_SUCCESSFUL,
    ORDER_LIST_NOT_FOUND,
    ORDER_LIST_FAILED,

    DASHBOARD_LOADING,
    DASHBOARD_SUCCESSFUL,
    DASHBOARD_FAILED,

    ORDER_DETAIL_LOADING,
    ORDER_DETAIL_SUCCESSFUL,
    ORDER_DETAIL_FAILED,

    ORDER_SELECTED_MITRA_LOADING,
    ORDER_SELECTED_MITRA_SUCCESSFUL,
    ORDER_SELECTED_MITRA_NOT_FOUND,
    ORDER_SELECTED_MITRA_FAILED,

    SELECTED_MITRA_LOADING,
    SELECTED_MITRA_SUCCESSFUL,
    SELECTED_MITRA_ORDER_EXPIRED,
    SELECTED_MITRA_NOT_FOUND,
    SELECTED_MITRA_FAILED,

    ORDER_CANCEL_LOADING,
    ORDER_CANCEL_SUCCESSFUL,
    ORDER_CANCEL_FAILED
} from "actions/Orders/Types";

const initState = {
    payload : {
        type : null,
        message : ''
    },
    payload_selected_mitra : {
        type : null,
        message : ''
    },
    payload_request_mitra : {
        type : null,
        message : ''
    },
    payload_mitra : {
        type : null,
        messag : ''
    },
    payload_update : {
        type : null,
        message : ''
    }
}

export default function (state = initState , action){
    switch(action.type){
        case DASHBOARD_LOADING:
            return {
                ...state,
                payload : action.payload
            }
        case DASHBOARD_SUCCESSFUL:
            return {
                ...state,
                payload : action.payload
            }
        case DASHBOARD_FAILED:
            return {
                ...state,
                payload : action.payload
            }
        case ORDER_LIST_LOADING:
            return {
                ...state,
                payload : action.payload
            }
        case ORDER_LIST_SUCCESSFUL:
            return {
                ...state,
                payload : action.payload
            }
        case ORDER_LIST_NOT_FOUND:
            return {
                ...state,
                payload : action.payload
            }
        case ORDER_LIST_FAILED:
            return {
                ...state,
                payload : action.payload
            }
        case ORDER_DETAIL_LOADING:
            return {
                ...state,
                payload : action.payload
            }
        case ORDER_DETAIL_NOT_FOUND:
            return {
                ...state,
                payload : action.payload
            }
        case ORDER_DETAIL_SUCCESSFUL:
            return {
                ...state,
                payload : action.payload
            }
        case ORDER_DETAIL_FAILED:
            return {
                ...state,
                payload : action.payload
            }
        case ORDER_SELECTED_MITRA_LOADING:
            return {
                ...state,
                payload_selected_mitra : action.payload
            }
        case ORDER_SELECTED_MITRA_SUCCESSFUL:
            return {
                ...state,
                payload_selected_mitra : action.payload
            }
        case ORDER_SELECTED_MITRA_NOT_FOUND:
            return {
                ...state,
                payload_selected_mitra : action.payload
            }
        case ORDER_SELECTED_MITRA_FAILED:
            return {
                ...state,
                payload_selected_mitra : action.payload
            }

        case SELECTED_MITRA_LOADING:
            return {
                ...state,
                payload_request_mitra : action.payload
            }
        case SELECTED_MITRA_SUCCESSFUL:
            return {
                ...state,
                payload_request_mitra : action.payload
            }
        case SELECTED_MITRA_ORDER_EXPIRED:
            return {
                ...state,
                payload_request_mitra : action.payload
            }
        case SELECTED_MITRA_NOT_FOUND:
            return {
                ...state,
                payload_request_mitra : action.payload
            }
        case SELECTED_MITRA_FAILED:
            return {
                ...state,
                payload_request_mitra : action.payload
            }

        case ORDER_CANCEL_LOADING:
            return {
                ...state,
                payload_update: {
                    type: ORDER_CANCEL_LOADING,
                    message: action.payload.message
                }
            }
        case ORDER_CANCEL_SUCCESSFUL:
            return {
                ...state,
                payload_update: {
                    type: ORDER_CANCEL_SUCCESSFUL,
                    message: action.payload.message
                }
            }
        case ORDER_CANCEL_FAILED:
            return {
                ...state,
                payload_update: {
                    type: ORDER_CANCEL_FAILED,
                    message: action.payload.message
                }
            }
        default:
            return state
    }
}