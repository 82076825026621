import { BASE_URL } from "actions/config";
import axios from 'axios';
import { passingHeaderToken } from "components/Utilities/Utility";

import {
    GUIDE_LIST_LOADING,
    GUIDE_LIST_SUCCESSFUL,
    GUIDE_LIST_NOT_FOUND,
    GUIDE_LIST_FAILED,

    GUIDE_DETAIL_LOADING,
    GUIDE_DETAIL_SUCCESSFUL,
    GUIDE_DETAIL_NOT_FOUND,
    GUIDE_DETAIL_FAILED,

    GUIDE_CREATE_LOADING,
    GUIDE_CREATE_SUCCESSFUL,
    GUIDE_CREATE_FAILED,

    GUIDE_UPDATE_LOADING,
    GUIDE_UPDATE_SUCCESSFUL,
    GUIDE_UPDATE_NOT_FOUND,
    GUIDE_UPDATE_FAILED,

    GUIDE_REMOVE_LOADING,
    GUIDE_REMOVE_SUCCESSFUL,
    GUIDE_REMOVE_NOT_FOUND,
    GUIDE_REMOVE_FAILED,
    GUIDE_RESET_CRUD
} from './Types';

export const getGuide = (page , limit , token) => async dispatch => {
    try {
        dispatch({
            type : GUIDE_LIST_LOADING,
            message : 'memuat data guide'
        })
        const response = await axios.get(`${BASE_URL}/api/guide/index/admin?page=${page}&limit=${limit}` , passingHeaderToken(token))
        setTimeout(() => {
            if(response.data != null && response.data.rows_data.docs.length > 0){
                dispatch({
                    type : GUIDE_LIST_SUCCESSFUL,
                    data : response.data
                })
            } else {
                dispatch({
                    type : GUIDE_LIST_NOT_FOUND,
                    message : 'Tidak ada data guide'
                })
            }
        } , 300)
    } catch (err) {
        setTimeout(() => {
            if(err.response !== undefined && err.response.status == 404){
                dispatch({
                    type : GUIDE_LIST_NOT_FOUND,
                    message : 'Tidak ada data guide'
                })
            } else {
                dispatch({
                    type : GUIDE_LIST_FAILED,
                    message : 'Gagal memuat data guide'
                })
            }
        } , 300)
    }
}

export const getDetailGuide = (id, token) => async dispatch => {
    try {
        dispatch({
            type : GUIDE_DETAIL_LOADING,
            message : 'Memuat data guide'
        })
        const response = await axios.get(`${BASE_URL}/api/guide/index/detail/${id}` , passingHeaderToken(token))
        setTimeout(() => {
            dispatch({
                type : GUIDE_DETAIL_SUCCESSFUL,
                data : response.data
            })
        } , 300)
    } catch (err) {
        setTimeout(() => {
            if(err.response !== undefined && err.response.status == 404){
                dispatch({
                    type : GUIDE_DETAIL_NOT_FOUND,
                    message : 'Tidak ada data guide'
                })
            } else {
                dispatch({
                    type : GUIDE_DETAIL_FAILED,
                    message : 'Gagal memuat data guide'
                })
            }
        } , 300)
    }
}

export const createGuide = (payload , token) => async dispatch => {
    try {
        dispatch({
            type : GUIDE_CREATE_LOADING,
            message : 'Membuat data guide'
        })
        await axios.post(`${BASE_URL}/api/guide/create` , payload , passingHeaderToken(token))
        dispatch({
            type : GUIDE_CREATE_SUCCESSFUL,
            message : 'Berhasil membuat data guide'
        })
    } catch (err) {
        dispatch({
            type : GUIDE_CREATE_FAILED,
            message : 'Gagal membuat data guide'
        })
    }
}

export const updateGuide = (id , payload , token) => async dispatch => {
    try {
        dispatch({
            type : GUIDE_UPDATE_LOADING,
            message : 'Memperbarui data guide'
        })
        await axios.put(`${BASE_URL}/api/guide/update/${id}` , payload , passingHeaderToken(token))
        dispatch({
            type : GUIDE_UPDATE_SUCCESSFUL,
            message : 'Berhasil memperbarui data guide'
        })
    } catch (err) {
        if(err.response !== undefined && err.response.status == 404){
            dispatch({
                type : GUIDE_UPDATE_NOT_FOUND,
                message : 'Data guide tidak ada'
            })
        } else {
            dispatch({
                type : GUIDE_UPDATE_FAILED,
                message : 'Gagal memperbarui data guide'
            })
        }
    }
}

export const removeGuide = (id , token) => async dispatch => {
    try {
        dispatch({
            type : GUIDE_REMOVE_LOADING,
            message : 'Menghapus data guide'
        })
        await axios.delete(`${BASE_URL}/api/guide/remove/${id}` , passingHeaderToken(token))
        dispatch({
            type : GUIDE_REMOVE_SUCCESSFUL,
            message : 'Berhasil menghapus data guide'
        })
    } catch (err) {
        if(err.response !== undefined && err.response.status == 404){
            dispatch({
                type : GUIDE_REMOVE_NOT_FOUND , 
                message : 'Tidak ada data guide'
            })
        } else {
            dispatch({
                type : GUIDE_REMOVE_FAILED,
                message : 'Gagal menghapus data guide'
            })
        }
    }
}

export const resetCrud = () => async dispatch => {
    dispatch({
        type : GUIDE_RESET_CRUD,
        message : ''
    })
}