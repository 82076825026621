import { BASE_URL } from 'actions/config';
import axios from 'axios';
import { MITRA_CANDIDATE_FAILED, MITRA_CANDIDATE_LOADING, MITRA_CANDIDATE_NOT_FOUND, MITRA_CANDIDATE_SUCCESSFUL, MITRA_LIST_FAILED, MITRA_LIST_LOADING, MITRA_LIST_NOT_FOUND, MITRA_LIST_SUCCESSFUL, UPDATE_MITRA_CANDIDATE_LOADING , UPDATE_MITRA_CANDIDATE_SUCCESSFUL , UPDATE_MITRA_CANDIDATE_FAILED, UPDATE_MITRA_FAILED, UPDATE_MITRA_LOADING, UPDATE_MITRA_NOT_FOUND, UPDATE_MITRA_SUCCESSFUL } from './Types';
import { MITRA_DETAIL_LOADING , MITRA_DETAIL_SUCCESSFUL , MITRA_DETAIL_NOT_FOUND , MITRA_DETAIL_FAILED } from './Types';
import { INVITE_TRAINING_LOADING , INVITE_TRAINING_SUCCESSFUL , INVITE_TRAINING_FAILED } from './Types';
import { UPDATE_TRAINING_LOADING , UPDATE_TRAINING_SUCCESSFUL , UPDATE_TRAINING_FAILED } from './Types';
import { ACCEPT_MITRA_LOADING , ACCEPT_MITRA_SUCCESSFUL , ACCEPT_MITRA_FAILED } from './Types';
import { SUSPEND_MITRA_LOADING , SUSPEND_MITRA_SUCCESSFUL , SUSPEND_MITRA_FAILED } from './Types';
import { ACTIVE_MITRA_LOADING , ACTIVE_MITRA_SUCCESSFUL , ACTIVE_MITRA_FAILED } from './Types';
import { passingHeaderToken } from 'components/Utilities/Utility';
import { RESET_UPDATE } from './Types';
import { passingHeaderTokenFormData } from 'components/Utilities/Utility';

export const getMitraList = (page , limit , search , token) => async dispatch => {
    try {
        dispatch({
            type : MITRA_LIST_LOADING,
            payload : {
                type : MITRA_LIST_LOADING,
                message : 'Memuat Daftar Mitra'
            }
        })
        const response = await axios.get(`${BASE_URL}/api/mitra/admin/index?page=${page}&limit=${limit}&search=${search}` , passingHeaderToken(token))
        if(response.data !== null){
            setTimeout(() => {
                dispatch({
                    type : MITRA_LIST_SUCCESSFUL,
                    payload : {
                        type : MITRA_LIST_SUCCESSFUL,
                        data : response.data
                    }
                })
            } , 300)
        } else {
            setTimeout(() => {
                dispatch({
                    type : MITRA_LIST_NOT_FOUND,
                    payload : {
                        type : MITRA_LIST_NOT_FOUND,
                        message : 'Tidak Ada Data Mitra'
                    }
                })
            } , 300)
        }
    } catch (err) {
        console.log(err)
        setTimeout(() => {
            if(err.response.status == 404){
                dispatch({
                    type : MITRA_LIST_NOT_FOUND,
                    payload : {
                        type : MITRA_LIST_NOT_FOUND,
                        message : 'Tidak Ada Data Mitra'
                    }
                })
            } else {
                dispatch({
                    type : MITRA_LIST_FAILED,
                    payload : {
                        type : MITRA_LIST_FAILED ,
                        message : 'Gagal Memuat Data Mitra'
                    }
                })
            }
        } , 300)
    }
}

export const getMitraCandidate = (token , page , limit , search, filter) => async dispatch => {
    try {
        dispatch({
            type : MITRA_CANDIDATE_LOADING,
            payload : {
                type : MITRA_CANDIDATE_LOADING,
                message : 'Memuat Data Calon Mitra'
            }
        })
        let baseUrlCandidate = `${BASE_URL}/api/mitra/admin/candidate?page=${page}&limit=${limit}&search=${search}`
        console.log(`BASE URL CANDIDATE : ${baseUrlCandidate}`)
        // if(filter.is_ex_golife && filter.is_ex_golife === '1')
        //     baseUrlCandidate += `&is_ex_golife=1`
        // if(filter.kind_of_mitra && filter.kind_of_mitra === '1')
        //     baseUrlCandidate += `&kind_of_mitra=with tools`
        const response = await axios.get(baseUrlCandidate , passingHeaderToken(token))
        setTimeout(() => {
            dispatch({
                type : MITRA_CANDIDATE_SUCCESSFUL,
                payload : {
                    type : MITRA_CANDIDATE_SUCCESSFUL,
                    data : response.data
                }
            })
        } , 300)
    } catch (err) {
        if(err.response !== undefined && err.response.status === 404){
            dispatch({
                type : MITRA_CANDIDATE_NOT_FOUND,
                payload : {
                    type : MITRA_CANDIDATE_NOT_FOUND,
                    message : 'Calon Mitra Tidak Ada'
                }
            })
        } else {
            dispatch({
                type : MITRA_CANDIDATE_FAILED,
                payload : {
                    type : MITRA_CANDIDATE_FAILED ,
                    message : 'Gagal Memuat Calon Mitra'
                }
            })
        }
    }
}

export const getMitraDetail = (mitra_id , timezone_code) => async dispatch => {
    try {
        dispatch({
            type : MITRA_DETAIL_LOADING,
            payload : {
                type : MITRA_DETAIL_LOADING,
                message : 'Memuat Data Mitra'
            }
        })
        console.log(`Mitra ID : ${mitra_id}`)
        const response = await axios.get(`${BASE_URL}/api/mitra/admin/detail/${mitra_id}` , {
            params : {
                timezone_code : timezone_code
            },
            headers : {
                'Content-Type' : 'application/json',
                'Authorization' : localStorage.getItem('token')
            }
        })
        dispatch({
            type : MITRA_DETAIL_SUCCESSFUL,
            payload : {
                type : MITRA_DETAIL_SUCCESSFUL,
                data : response.data
            }
        })
    } catch (err) {
        if(err.response !== undefined && err.response.status == 404){
            dispatch({
                type : MITRA_DETAIL_NOT_FOUND,
                payload : {
                    type : MITRA_DETAIL_NOT_FOUND,
                    message : 'Data Mitra Tak Ditemukan'
                }
            })
        } else {
            dispatch({
                type : MITRA_DETAIL_FAILED,
                payload : {
                    type : MITRA_DETAIL_FAILED,
                    message : 'Ada Yang Salah Dengan Server'
                }
            })
        }
    }
}

export const updateMitraCandidateDetail = (mitra_candidate_id , form_data , token) => async dispatch => {
    try {
        dispatch({
            type : UPDATE_MITRA_CANDIDATE_LOADING,
            payload : {
                type : UPDATE_MITRA_CANDIDATE_LOADING,
                message : 'Memperbarui Data Calon Mitra....'
            }
        })
        const response = await axios.put(`${BASE_URL}/api/mitra/admin/update/mitra_candidate/${mitra_candidate_id}` , form_data , passingHeaderTokenFormData(token))
        dispatch({
            type : UPDATE_MITRA_CANDIDATE_SUCCESSFUL,
            payload : {
                type : UPDATE_MITRA_CANDIDATE_SUCCESSFUL,
                message : 'Berhasil memperbarui data calon mitra'
            }
        })
    } catch (err) {
        console.error(err)
        dispatch({
            type : UPDATE_MITRA_CANDIDATE_FAILED,
            payload : {
                type : UPDATE_MITRA_CANDIDATE_FAILED,
                message : 'Gagal memperbarui data calon mitra'
            }
        })
    }
}

export const updateMitraDetail = (payload , token) => async dispatch => {
    try {
        dispatch({
            type : UPDATE_MITRA_LOADING,
            payload : {
                type : UPDATE_MITRA_LOADING,
                message : 'Memperbarui Data Mitra'
            }
        })
        const response = await axios.put(`${BASE_URL}/api/mitra/admin/update` , payload , passingHeaderToken(token))
        dispatch({
            type : UPDATE_MITRA_SUCCESSFUL,
            payload : {
                type : UPDATE_MITRA_SUCCESSFUL,
                response : response.data
            }
        })
    } catch (err) {
        if(err.response.status == 404){
            dispatch({
                type : UPDATE_MITRA_NOT_FOUND,
                payload : {
                    type : UPDATE_MITRA_NOT_FOUND,
                    message : 'Mitra Tidak Ditemukan'
                }
            })
        } else {
            dispatch({
                type : UPDATE_MITRA_FAILED,
                payload : {
                    type : UPDATE_MITRA_FAILED , 
                    message : 'Gagal Memperbarui Data Mitra'
                }
            })
        }
    }
}

export const updateTrainingStatus = (mitra_candidate_id , status , token) => async dispatch => {
    try {
        dispatch({
            type : UPDATE_TRAINING_LOADING,
            payload : {
                type : UPDATE_TRAINING_LOADING,
                message : 'Sedang memperbarui status training'
            }
        })
        await axios.put(`${BASE_URL}/api/mitra/training_status/${mitra_candidate_id}/${status}` , {} , passingHeaderToken(token))
        dispatch({
            type : UPDATE_TRAINING_SUCCESSFUL,
            payload : {
                type : UPDATE_TRAINING_SUCCESSFUL,
                message : 'Berhasil memperbarui status training'
            }
        })
    } catch (err) {
        dispatch({
            type : UPDATE_TRAINING_FAILED,
            payload : {
                type : UPDATE_TRAINING_FAILED,
                message : 'Gagal memperbarui status training'
            }
        })
    }
}

export const acceptMitra = (mitra_candidate_id , status , token) => async dispatch => {
    try {
        dispatch({
            type : ACCEPT_MITRA_LOADING,
            payload : {
                type : ACCEPT_MITRA_LOADING,
                message : 'Sedang memperbarui status mitra'
            }
        })
        await axios.put(`${BASE_URL}/api/mitra/activate_mitra_status/${mitra_candidate_id}/${status}` , {} , passingHeaderToken(token))
        dispatch({
            type : ACCEPT_MITRA_SUCCESSFUL,
            payload : {
                type : ACCEPT_MITRA_SUCCESSFUL,
                message : 'Berhasil memperbarui status mitra'
            }
        })
    } catch (err) {
        dispatch({
            type : ACCEPT_MITRA_FAILED,
            payload : {
                type : ACCEPT_MITRA_FAILED,
                message : 'Gagal memparbarui status mitra'
            }
        })
    }
}

export const updateMitraSuspend = (mitra_id , reason, token) => async dispatch => {
    try {
        dispatch({
            type : SUSPEND_MITRA_LOADING,
            payload : {
                type : SUSPEND_MITRA_LOADING,
                message : 'Sedang mensuspend mitra'
            }
        })
        await axios.put(`${BASE_URL}/api/mitra/update_mitra_status/${mitra_id}/suspend` , {
            suspended_reason : reason
        } , passingHeaderToken(token))
        dispatch({
            type : SUSPEND_MITRA_SUCCESSFUL,
            payload : {
                type : SUSPEND_MITRA_SUCCESSFUL,
                message : 'Berhasil menonaktifkan mitra'
            }
        })
    } catch (err) {
        console.log(err)
        dispatch({
            type : SUSPEND_MITRA_FAILED,
            payload : {
                type : SUSPEND_MITRA_FAILED,
                message : 'Gagal menonaktifkan mitra'
            }
        })
    }
}

export const updateMitraActive = (mitra_id, token) => async dispatch => {
    try {
        dispatch({
            type : ACTIVE_MITRA_LOADING,
            payload : {
                type : ACTIVE_MITRA_LOADING,
                message : 'Sedang mengaktifkan mitra'
            }
        })
        await axios.put(`${BASE_URL}/api/mitra/update_mitra_status/${mitra_id}/active` , {} , passingHeaderToken(token))
        dispatch({
            type : ACTIVE_MITRA_SUCCESSFUL,
            payload : {
                type : ACTIVE_MITRA_SUCCESSFUL,
                message : 'Berhasil mengaktifkan kembali mitra'
            }
        })
    } catch (err) {
        console.log(err)
        dispatch({
            type : ACTIVE_MITRA_FAILED,
            payload : {
                type : ACTIVE_MITRA_FAILED,
                message : 'Gagal mengaktifkan mitra'
            }
        })
    }
}

export const inviteMitra = (mitra_candidate_id , payload , token) => async dispatch => {
    try {
        dispatch({
            type : INVITE_TRAINING_LOADING,
            payload : {
                type : INVITE_TRAINING_LOADING,
                message : 'Sedang mengundang mitra training'
            }
        })
        await axios.put(`${BASE_URL}/api/mitra/invite_mitra/${mitra_candidate_id}` , payload , passingHeaderToken(token))
        dispatch({
            type : INVITE_TRAINING_SUCCESSFUL,
            payload : {
                type : INVITE_TRAINING_SUCCESSFUL,
                message : 'Berhasil mengundang mitra training'
            }
        })
    } catch (err) {
        console.log(err)
        dispatch({
            type : INVITE_TRAINING_FAILED,
            payload : {
                type : INVITE_TRAINING_FAILED,
                message : 'Gagal mengundang mitra training'
            }
        })
    }
}

export const resetUpdate = () => async dispatch => {
    dispatch({
        type : RESET_UPDATE,
        payload : {
            type : null,
            messag : ''
        }
    })
}