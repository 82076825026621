import { Table, Card, CardBody, Container, Row, Col, FormGroup, Input, Form, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { MITRA_LIST_LOADING, MITRA_LIST_SUCCESSFUL, MITRA_LIST_NOT_FOUND } from 'actions/Mitra/Types';
import { useState } from 'react';
import NotFoundPage from 'components/NotFound/NotFoundPage';
import ErrorPage from 'components/ErrorComponent/ErrorPage';
import IcNotFound from 'assets/img/ic_not_found.svg';
import IcError from 'assets/img/ic_error.svg';
import { getMitraList } from 'actions/Mitra/MitraService';
import Pagination from 'components/Utilities/Pagination';
import { useHistory } from 'react-router-dom';
import '../../../assets/css/table_animation.css';
import Skeleton from 'react-loading-skeleton';
import { MaterialGray1 } from 'components/Utilities/ResourceColors';
import { MaterialBlue1 } from 'components/Utilities/ResourceColors';
import { validateEmpty } from 'components/Utilities/Utility';
import { MITRA_LIST_FAILED } from 'actions/Mitra/Types';

const MitraPage = ({ mitra, getMitraList }) => {
    const [currentPage, setCurrentPage] = useState(1)
    const [currentPageSearch, setCurrentPageSearch] = useState(1)
    const [isFirst, setFirst] = useState(true)
    const [token, setToken] = useState('')

    const [searchText, setSearchText] = useState('')
    const onChangeSearch = (e) => {
        setSearchText(e.target.value)
    }
    const history = useHistory()

    useEffect(() => {
        setToken(localStorage.getItem('token'))
        if(token !== ''){
            getMitraList(currentPage, 5, "" , token)
        }
    }, [token])

    useEffect(() => {
        if(token !== ''){
            getMitraList(currentPage, 5, "" , token)
        }
    }, [currentPage])

    useEffect(() => {
        if (!isFirst) {
            if(token !== ''){
                if (validateEmpty(searchText)) {
                    getMitraList(currentPageSearch, 5, searchText , token)
                }
            }
        } else {
            setFirst(false)
        }
    }, [currentPageSearch])

    useEffect(() => {
        if (!isFirst) {
            const delayDebounceFn = setTimeout(() => {
                if(token !== ''){
                    if (validateEmpty(searchText)) {
                        setCurrentPageSearch(1)
                        getMitraList(currentPageSearch, 5, searchText , token)
                    } else {
                        setCurrentPageSearch(1)
                        getMitraList(currentPage, 5, "" , token)
                    }
                }
            }, 800)
            return () => clearTimeout(delayDebounceFn)
        } else {
            setFirst(false)
        }
    }, [searchText])

    function buildMitraList() {
        if (mitra.payload.type === MITRA_LIST_LOADING) {
            return (
                <>
                    <Row>
                        <Col lg="12">
                            <Skeleton style={{ height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12">
                            <Skeleton style={{ marginTop: '25px', height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12">
                            <Skeleton style={{ marginTop: '25px', height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12">
                            <Skeleton style={{ marginTop: '25px', height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                        </Col>
                    </Row>
                </>
            )
        } else if (mitra.payload.type === MITRA_LIST_SUCCESSFUL) {
            if (mitra.payload.data !== undefined) {
                return (
                    <>
                        <Row>
                            <Col xl="12">
                                <Table className="align-items-center table-bordered" responsive>
                                    <thead className="thead-dark">
                                        <tr>
                                            <th style={{ color: 'white' }} scope="col">Nama Lengkap</th>
                                            <th style={{ color: 'white' }} scope="col">Email</th>
                                            <th style={{ color: 'white' }} scope="col">Status Mitra</th>
                                            <th style={{ color: 'white' }} scope="col">Nomor Telepon</th>
                                            <th style={{ color: 'white' }} scope="col">Aksi</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            mitra.payload.data.rows_data.docs.map((data) => {
                                                return (
                                                    <tr key={data.id}>
                                                        <td>{data.complete_name}</td>
                                                        <td>{data.email}</td>
                                                        <td>{data.is_active === 'yes' ? 'Aktif' : 'Tidak Aktif'}</td>
                                                        <td>{data.phone_number}</td>
                                                        <td>
                                                            <button onClick={() => {
                                                                history.push({
                                                                    pathname: '/admin/mitra_detail_page',
                                                                    state: {
                                                                        mitra_id: data.id
                                                                    }
                                                                })
                                                            }} className="btn btn-sm btn-primary"><i className="fas fa-eye"></i> Lihat</button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                        {
                            !validateEmpty(searchText) ?
                                <Row>
                                    {
                                        mitra.payload.data.rows_data !== undefined &&
                                            mitra.payload.data.count_total > 5 ?
                                            <div className="card bg-default-shadow" style={{ width: 'max-content', paddingLeft: '20px', marginRight: '0', marginTop: '10px', paddingTop: '15px', marginLeft: 'auto' }}>
                                                <Pagination
                                                    className="pagination-bar"
                                                    currentPage={currentPage}
                                                    totalCount={mitra.payload.data.count_total}
                                                    pageSize={5}
                                                    onPageChange={page => {
                                                        setCurrentPage(page)
                                                    }} />
                                            </div>
                                            : <div></div>
                                    }
                                </Row>
                                :
                                <Row>
                                    {
                                        mitra.payload.data.rows_data !== undefined &&
                                            mitra.payload.data.count_total > 5 ?
                                            <div className="card bg-default-shadow" style={{ width: 'max-content', paddingLeft: '20px', marginRight: '0', marginTop: '10px', paddingTop: '15px', marginLeft: 'auto' }}>
                                                <Pagination
                                                    className="pagination-bar"
                                                    currentPage={currentPageSearch}
                                                    totalCount={mitra.payload.data.count_total}
                                                    pageSize={5}
                                                    onPageChange={page => {
                                                        setCurrentPageSearch(page)
                                                    }} />
                                            </div>
                                            : <div></div>
                                    }
                                </Row>
                        }
                    </>
                )
            }
        } else if (mitra.payload.type === MITRA_LIST_NOT_FOUND) {
            return (
                <NotFoundPage
                    imageSource={IcNotFound}
                    imageText={mitra.payload.message} />
            )
        } else if(mitra.payload.type === MITRA_LIST_FAILED) {
            return (
                <ErrorPage
                    imageSource={IcError}
                    imageText={mitra.payload.message} />
            )
        } else {
            return (
                <>
                    <Row>
                        <Col lg="12">
                            <Skeleton style={{ height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12">
                            <Skeleton style={{ marginTop: '25px', height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12">
                            <Skeleton style={{ marginTop: '25px', height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12">
                            <Skeleton style={{ marginTop: '25px', height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                        </Col>
                    </Row>
                </>
            )
        }
    }
    return (
        <>
            <div className="main-content">
                <div className="header bg-gradient-info pb-8 pt-5 pt-lg-7 d-flex align-items-center">
                    <span className="mask bg-gradient-default opacity-8" />
                </div>
                <Container className="mt--7" fluid>
                    <Row>
                        <Col xl="12">
                            <Form onSubmit={(e) => {
                                e.preventDefault()
                            }}>
                                <FormGroup>
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i class="fa fa-search" aria-hidden="true" style={{ color: MaterialBlue1, fontSize: 15 }}>
                                                </i>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            id="input-search"
                                            onChange={onChangeSearch}
                                            style={{
                                                border: 0,
                                                boxShadow: 'none'
                                            }}
                                            defaultValue={searchText}
                                            placeholder="Ketika disini untuk mencari nama berdasarkan Nama, Email dan Nomor Telepon Mitra" />
                                    </InputGroup>
                                </FormGroup>
                            </Form>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl="12">
                            <Card className="bg-secondary shadow">
                                <CardBody>
                                    {buildMitraList()}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

MitraPage.propTypes = {
    mitra: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    mitra: state.mitra
})

export default connect(mapStateToProps, { getMitraList })(MitraPage)