export const MaterialGray1 = '#cfd8dc';
export const MaterialGray2 = '#fafafa';
export const MaterialGray3 = '#37474F'
export const MaterialBlue1 = '#6cc4ec';
export const MaterialBlue2 = '#64b5f6'
export const MaterialBlue3 = '#5E72E4';
export const MaterialRed1 = '#e53935';
export const MaterialRed2 = '#ef5350'
export const MaterialRed3 = '#F5365C'
export const MaterialBlackTransparent1 = '#212121'
export const MaterialGreen1 = '#5EC27E'
export const MaterialGreen2 = '#2DCE89'
export const MaterialYellow1 = '#F0AB35'
export const MaterialYellow2 = '#FFD834'
export const MaterialYellow3 = '#ffca28'
export const RED_ERROR_MESSAGE = '#d32f2f'
export const RED_COLOR_UNTAR = '#970126'
export const GRAY_1 = '#989898'
export const GRAY_2 = '#5A6268'
export const GRAY_3 = '#F0F0F0'