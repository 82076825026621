import { BASE_URL } from "actions/config";
import axios from 'axios';
import { DASHBOARD_LOADING , DASHBOARD_SUCCESSFUL , DASHBOARD_FAILED } from "actions/Dashboard/Types";
import { 
    ORDER_LIST_LOADING , 
    ORDER_LIST_SUCCESSFUL , 
    ORDER_LIST_FAILED, 
    ORDER_LIST_NOT_FOUND,
    
    ORDER_DETAIL_LOADING,
    ORDER_DETAIL_SUCCESSFUL,
    ORDER_DETAIL_NOT_FOUND,
    ORDER_DETAIL_FAILED,

    ORDER_SELECTED_MITRA_LOADING,
    ORDER_SELECTED_MITRA_SUCCESSFUL,
    ORDER_SELECTED_MITRA_NOT_FOUND,
    ORDER_SELECTED_MITRA_FAILED,

    ORDER_CANCEL_LOADING,
    ORDER_CANCEL_SUCCESSFUL,
    ORDER_CANCEL_FAILED,
    SELECTED_MITRA_LOADING,
    SELECTED_MITRA_SUCCESSFUL,
    SELECTED_MITRA_NOT_FOUND,
    SELECTED_MITRA_ORDER_EXPIRED,
    SELECTED_MITRA_FAILED
} from "./Types";
import { passingHeaderToken } from "components/Utilities/Utility";
import { validateEmpty } from "components/Utilities/Utility";
export const getDashboardOrder = (token) => async dispatch => {
    try {
        dispatch({
            type : DASHBOARD_LOADING,
            payload : {
                type : DASHBOARD_LOADING,
                message : 'Sedang memuat summary order'
            }
        })
        const response = await axios.get(`${BASE_URL}/api/orders/index/admin/dashboard` , passingHeaderToken(token))
        setTimeout(() => {
            dispatch({
                type : DASHBOARD_SUCCESSFUL,
                payload : {
                    type : DASHBOARD_SUCCESSFUL,
                    data : response.data
                }
            })
        } , 300)
    } catch (err) {
        console.log(err)
        dispatch({
            type : DASHBOARD_FAILED,
            payload : {
                type : DASHBOARD_FAILED,
                message : 'Gagal mengambil data order'
            }
        })
    }
}

export const getOrderList = (page , limit , status , search , token) => async dispatch => {
    try {
        dispatch({
            type : ORDER_LIST_LOADING,
            payload : {
                type : ORDER_LIST_LOADING,
                message : 'Sedang memuat data order'
            }
        })
        const response = await axios.get(`${BASE_URL}/api/orders/index/admin/${status}?page=${page}&limit=${limit}&search=${search}` , passingHeaderToken(token))
        let type = null;
        let payload = {}
        if(response.data.rows_data.docs.length > 0){
            type = ORDER_LIST_SUCCESSFUL
            payload = {
                type : ORDER_LIST_SUCCESSFUL,
                data : response.data
            }
        } else {
            type = ORDER_LIST_NOT_FOUND
            payload = {
                type : ORDER_LIST_NOT_FOUND,
                message : 'Tidak ada orderan'
            }
        }
        setTimeout(() => {
            dispatch({
                type : type,
                payload : payload
            })
        } , 250)
    } catch (err) {
        dispatch({
            type : ORDER_LIST_FAILED,
            payload : {
                type : ORDER_LIST_FAILED,
                message : 'Gagal memuat data orderan'
            }
        })
    }
}

export const getOrderSelectedMitra = (order_id , limit , page, search, token) => async dispatch => {
    try {
        dispatch({
            type : ORDER_SELECTED_MITRA_LOADING,
            payload : {
                type : ORDER_SELECTED_MITRA_LOADING,
                message : 'Sedang memuat data mitra'
            }
        })
        const response = await axios.get(`${BASE_URL}/api/orders/index/selected_mitra/${order_id}?page=${page}&limit=${limit}&search=${search}` , passingHeaderToken(token))
        console.log(JSON.stringify(response.data , null , 2))
        setTimeout(() => {
            if(response.data.rows_data.docs.length > 0){
                dispatch({
                    type : ORDER_SELECTED_MITRA_SUCCESSFUL,
                    payload : {
                        type : ORDER_SELECTED_MITRA_SUCCESSFUL,
                        data : response.data
                    }
                })
            } else {
                dispatch({
                    type : ORDER_SELECTED_MITRA_NOT_FOUND,
                    payload : {
                        type : ORDER_SELECTED_MITRA_NOT_FOUND,
                        message : 'Data mitra tidak ada'
                    }
                })
            }
        } , 500)
    } catch (err) {
        if(err.response !== undefined && err.response.status === 404){
            dispatch({
                type : ORDER_SELECTED_MITRA_NOT_FOUND,
                payload : {
                    type : ORDER_SELECTED_MITRA_NOT_FOUND,
                    message : 'Data mitra tidak ada'
                }
            })
        } else {
            dispatch({
                type : ORDER_SELECTED_MITRA_FAILED,
                payload : {
                    type : ORDER_SELECTED_MITRA_FAILED,
                    message : 'Gagal memuat data mitra'
                }
            })
        }
    }
}

export const getOrderDetail = (order_id , token) => async dispatch => {
    try {
        dispatch({
            type : ORDER_DETAIL_LOADING,
            payload : {
                type : ORDER_DETAIL_LOADING,
                message : 'Sedang memuat data order'
            }
        })
        const response = await axios.get(`${BASE_URL}/api/orders/order_detail_admin/${order_id}` , passingHeaderToken(token))
        console.log(`Resp Data : ${JSON.stringify(response.data , null , 2)}`)
        setTimeout(() => {
            if(validateEmpty(response.data)){
                dispatch({
                    type : ORDER_DETAIL_SUCCESSFUL,
                    payload : {
                        type : ORDER_DETAIL_SUCCESSFUL,
                        data : response.data
                    }
                })
            } else {
                dispatch({
                    type : ORDER_DETAIL_NOT_FOUND,
                    payload : {
                        type : ORDER_DETAIL_NOT_FOUND,
                        message : 'Order tidak ditemukan atau sudah kadaluarsa'
                    }
                })
            }
        } , 300)
    } catch (err) {
        setTimeout(() => {
            if(err.response !== undefined && err.response.status === 404){
                dispatch({
                    type : ORDER_DETAIL_NOT_FOUND,
                    payload : {
                        type : ORDER_DETAIL_NOT_FOUND,
                        message : 'Order tidak ditemukan atau sudah kadaluarsa'
                    }
                })
            } else {
                dispatch({
                    type : ORDER_DETAIL_FAILED,
                    payload : {
                        type : ORDER_DETAIL_FAILED,
                        message : 'Gagal memuat data order'
                    }
                })
            }
        } , 300)
    }
}

export const requestSelectedMitra = (order_id , data , token) => async dispatch => {
    try {
        console.log(`Order ID : ${order_id}`)
        console.log(`Data : ${JSON.stringify(data , null , 2)}`)
        dispatch({
            type : SELECTED_MITRA_LOADING,
            payload : {
                type : SELECTED_MITRA_LOADING,
                message : 'Sedang memilih mitra'
            }
        })
        const response = await axios.post(`${BASE_URL}/api/orders/select_mitra/${order_id}` , data , passingHeaderToken(token))
        console.log(`Response Rquest : ${JSON.stringify(response.status , null , 2)}`)
        dispatch({
            type : SELECTED_MITRA_SUCCESSFUL,
            payload : {
                type : SELECTED_MITRA_SUCCESSFUL,
                message : 'Berhasil memilih mitra'
            }
        })
    } catch (err) {
        if(err.response !== undefined && err.response.status === 404){
            dispatch({
                type : SELECTED_MITRA_NOT_FOUND,
                payload : {
                    type : SELECTED_MITRA_NOT_FOUND,
                    message : 'Order or mitra not found'
                }
            })
        } else if(err.response !== undefined && err.response.status === 410){
            dispatch({
                type : SELECTED_MITRA_ORDER_EXPIRED,
                payload : {
                    type : SELECTED_MITRA_ORDER_EXPIRED,
                    message : 'Order expired'
                }
            })
        } else {
            dispatch({
                type : SELECTED_MITRA_FAILED,
                payload : {
                    type : SELECTED_MITRA_FAILED,
                    message : 'Select mitra failed'
                }
            })
        }
    }
}

export const requestOrderCancel = (id , data , token) => async dispatch => {
    try {
        dispatch({
            type : ORDER_CANCEL_LOADING,
            payload : {
                type : ORDER_CANCEL_LOADING,
                message : 'Sedang meng cancel order'
            }
        })
        const response = await axios.post(`${BASE_URL}/api/orders/cancel/admin/${id}` , data , passingHeaderToken(token))
        console.log(`Response : ${JSON.stringify(response.data , null , 2)}`)
        dispatch({
            type : ORDER_CANCEL_SUCCESSFUL,
            payload : {
                type : ORDER_CANCEL_SUCCESSFUL,
                message : 'Berhasil cancel order'
            }
        })
    } catch (err) {
        console.log(err)
        dispatch({
            type : ORDER_CANCEL_FAILED,
            payload : {
                type : ORDER_CANCEL_FAILED,
                message : 'Gagal meng cancel order'
            }
        })
    }
}