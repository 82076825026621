import { BASE_URL } from "actions/config";
import axios from 'axios';
import { 
    SUB_SERVICE_CREATE_LOADING ,
    SUB_SERVICE_CREATE_SUCCESSFUL,
    SUB_SERVICE_CREATE_FAILED,
    SUB_SERVICE_UPDATE_LOADING,
    SUB_SERVICE_UPDATE_SUCCESSFUL,
    SUB_SERVICE_UPDATE_NOT_FOUND,
    SUB_SERVICE_UPDATE_FAILED,
    SUB_SERVICE_REMOVE_LOADING,
    SUB_SERVICE_REMOVE_SUCCESSFUL,
    SUB_SERVICE_REMOVE_UNAUTHORIZED,
    SUB_SERVICE_REMOVE_NOT_FOUND,
    SUB_SERVICE_REMOVE_FAILED,

    SUB_SERVICE_RESET_CRUD
} from "./Types";
import { passingHeaderToken } from "components/Utilities/Utility";

export const createSubService = (data , token) => async dispatch => {
    try {
        dispatch({
            type : SUB_SERVICE_CREATE_LOADING,
            message : 'Membuat Data Harga'
        })
        const response = await axios.post(`${BASE_URL}/api/sub_service/create` , JSON.stringify(data) , passingHeaderToken(token))
        dispatch({
            type : SUB_SERVICE_CREATE_SUCCESSFUL,
            message : response.data
        })
    } catch (err) { 
        dispatch({
            type : SUB_SERVICE_CREATE_FAILED,
            message : 'Gagal Membuat Data Harga'
        })
    }
}

export const updateSubService = (id , data , token) => async dispatch => {
    try {
        dispatch({
            type : SUB_SERVICE_UPDATE_LOADING,
            message : 'Memperbarui Data Harga'
        })
        const response = await axios.put(`${BASE_URL}/api/sub_service/update/${id}` , JSON.stringify(data) , passingHeaderToken(token))
        dispatch({
            type : SUB_SERVICE_UPDATE_SUCCESSFUL,
            message : 'Berhasil Memperbarui Data Harga',
            returning_data : response.data.data
        })
    } catch (err) {
        if(err.response.status == 404){
            dispatch({
                type : SUB_SERVICE_UPDATE_NOT_FOUND ,
                message : 'Tidak Ada Data Harga'
            })
        } else {
            dispatch({
                type : SUB_SERVICE_UPDATE_FAILED,
                message : 'Gagal Memperbarui Data Harga'
            })
        }
    }
}

export const removeSubService = (id , password , token) => async dispatch => {
    try {
        dispatch({
            type : SUB_SERVICE_REMOVE_LOADING,
            message : 'Menghapus data unit layanan'
        })
        await axios.delete(`${BASE_URL}/api/sub_service/remove/${id}/${password}` , passingHeaderToken(token))
        dispatch({
            type : SUB_SERVICE_REMOVE_SUCCESSFUL,
            message : 'Berhasil menghapus data unit layanan'
        })
    } catch (err) {
        if(err.response !== undefined && err.response.status === 401){
            dispatch({
                type : SUB_SERVICE_REMOVE_UNAUTHORIZED,
                message : 'Password salah'
            })
        } else if(err.response !== undefined && err.response.status === 404){
            dispatch({
                type : SUB_SERVICE_REMOVE_NOT_FOUND,
                message : 'Data unit layanan tidak ada'
            })
        } else {
            dispatch({
                type : SUB_SERVICE_REMOVE_FAILED,
                message : 'Gagal menghapus data unit layanan'
            })
        }
    }
}

export const resetCrud = () => async dispatch => {
    dispatch({
        type : SUB_SERVICE_RESET_CRUD,
        message : ''
    })
}