import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
    Container,
    Card,
    Row,
    Col,
    CardBody,
    Form,
    FormGroup,
    Input
} from 'reactstrap';
import { useEffect, useRef, useReducer, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { updateGuide, getDetailGuide, resetCrud } from 'actions/Guide/GuideService';
import {
    GUIDE_DETAIL_LOADING,
    GUIDE_DETAIL_SUCCESSFUL,
    GUIDE_DETAIL_NOT_FOUND,
    GUIDE_DETAIL_FAILED,

    GUIDE_UPDATE_LOADING,
    GUIDE_UPDATE_SUCCESSFUL,
    GUIDE_UPDATE_NOT_FOUND,
    GUIDE_UPDATE_FAILED
} from 'actions/Guide/Types';
import '../../../assets/css/utilities.css';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import withReactContent from 'sweetalert2-react-content';
import { MaterialGray1 } from 'components/Utilities/ResourceColors';
import { MaterialRed1 } from 'components/Utilities/ResourceColors';
import Select from 'react-select';
import IcNotFound from 'assets/img/ic_not_found.svg';
import IcError from 'assets/img/ic_error.svg';
import { RED_ERROR_MESSAGE } from 'components/Utilities/ResourceColors';
import Skeleton from 'react-loading-skeleton';
import NotFoundPage from 'components/NotFound/NotFoundPage';
import ErrorPage from 'components/ErrorComponent/ErrorPage';
import { validateEmpty } from 'components/Utilities/Utility';
import CKEditor from "react-ckeditor-component";

const HelpDetailPage = (props) => {
    const [token, setToken] = useState('')
    const options = [
        {
            value: 'customer', label: 'Customer'
        },
        {
            value: 'mitra', label: 'Mitra'
        }
    ]
    const mainContent = useRef(null)
    const location = useLocation()
    const history = useHistory()
    const [isSubmit, setSubmit] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const [inputValues, setInputValues] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            guide_title: '',
            guide_description: '',
            guide_type: {
                label: 'Customer',
                value: 'customer'
            }
        }
    )
    useEffect(() => {
        setToken(localStorage.getItem('token'))
        if (token !== '') {
            props.getDetailGuide(props.location.state.guide_id, token)
        }
    }, [token])
    const onChangeHandler = event => {
        const { name, value } = event.target
        setInputValues({ [name]: value })
    }
    useEffect(() => {
        console.log(JSON.stringify(props.guide.payload.data, null, 2))
        if (props.guide.payload.data !== undefined) {
            for (const key in props.guide.payload.data) {
                if (props.guide.payload.data.hasOwnProperty(key)) {
                    if (key === 'guide_type') {
                        if (validateEmpty(props.guide.payload.data[key])) {
                            setInputValues({
                                ['guide_type']: {
                                    value: props.guide.payload.data[key],
                                    label: `${props.guide.payload.data[key][0].toUpperCase()}${props.guide.payload.data[key].slice(1)}`
                                }
                            })
                        } else {
                            setInputValues({
                                ['guide_type']: {
                                    value : 'customer',
                                    label : 'Customer'
                                }
                            })
                        }
                    } else {
                        setInputValues({
                            [key]: props.guide.payload.data[key]
                        })
                    }
                }
            }
        }
        buildHelpDetailPage()
    }, [props.guide.payload])
    const onBlur = (evt) => {
        console.log('onBlur')
    }
    const afterPaste = (evt) => {
        console.log('after paste')
    }
    const onChange = (evt) => {
        setInputValues({ ['guide_description']: evt.editor.getData() })
    }
    useEffect(() => {
        if (!localStorage.getItem('token')) {
            history.push('/auth/login')
        }
    }, [location])
    useEffect(() => {
        switch (props.guide.payload_crud.type) {
            case GUIDE_UPDATE_LOADING:
                setLoading(true)
                break;
            case GUIDE_UPDATE_SUCCESSFUL:
                setLoading(false)
                Swal.fire(
                    'Status',
                    'Berhasil memperbarui data panduan',
                    'success'
                )
                if (props.location.state !== undefined && token !== '')
                    props.getDetailGuide(props.location.state.guide_id, token)
                props.resetCrud()
                history.goBack()
                break;
            case GUIDE_UPDATE_NOT_FOUND:
                setLoading(false)
                Swal.fire(
                    'Perhatian',
                    'Tidak ada data panduan',
                    'warning'
                )
                break;
            case GUIDE_UPDATE_FAILED:
                setLoading(false)
                Swal.fire(
                    'Perhatian',
                    'Gagal memperbarui panduan',
                    'error'
                )
                break;
        }
    }, [props.guide.payload_crud])

    function updateGuide() {
        setSubmit(true)
        const {
            guide_title,
            guide_description
        } = inputValues
        const guide_type = inputValues.guide_type.value
        if (token !== '' && props.location.state !== undefined &&
            props.location.state.guide_id !== undefined &&
            guide_title !== '' && guide_description !== '' && guide_type !== '') {
            let guide_type_selected = inputValues.guide_type.value
            const payload_json = {
                guide_title: guide_title,
                guide_description: guide_description,
                guide_type: guide_type_selected
            }
            console.log(`Req : ${JSON.stringify(payload_json, null, 2)}`)
            props.updateGuide(props.location.state.guide_id, payload_json, token)
        }
    }

    function buildHelpDetailPage() {
        switch (props.guide.payload.type) {
            case GUIDE_DETAIL_LOADING:
                return (
                    <>
                        <Container className="mt--7" fluid>
                            <Row>
                                <Col className="order-xl-1" xl="12">
                                    <Card className="bg-secondary shadow">
                                        <CardBody>
                                            <Form>
                                                <div>
                                                    <Row>
                                                        <Col lg="12">
                                                            <FormGroup>
                                                                <Skeleton style={{ height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col lg="12">
                                                            <Skeleton style={{ height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <Skeleton style={{ marginTop: '30px', height: '200px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col lg="12">
                                                            <FormGroup>
                                                                <Skeleton style={{ marginTop: '30px', height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Form>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </>
                )
            case GUIDE_DETAIL_SUCCESSFUL:
                return (
                    <>
                        <Container className="mt--7" fluid>
                            <Row>
                                <Col className="order-xl-1" xl="12">
                                    <Card className="bg-secondary-shadow">
                                        {
                                            isLoading ?
                                                <div style={{
                                                    borderTopLeftRadius: '5px',
                                                    borderTopRightRadius: '5px',
                                                    height: '5px'
                                                }} className="progress-bar">
                                                    <div className="progress-bar-value"></div>
                                                </div>
                                                : <div style={{
                                                    height: '5px'
                                                }}></div>
                                        }
                                        <CardBody>
                                            <Form>
                                                <div>
                                                    <Row>
                                                        <Col lg="12">
                                                            <Row>
                                                                <Col lg="6">
                                                                    <FormGroup>
                                                                        <label
                                                                            className="form-control-label"
                                                                            htmlFor="input-title">
                                                                            Judul Panduan
                                                                        </label>
                                                                        <Input
                                                                            className="form-control-alternative"
                                                                            id="input-title"
                                                                            type="text"
                                                                            name="guide_title"
                                                                            onChange={onChangeHandler}
                                                                            value={inputValues.guide_title}
                                                                            style={
                                                                                isSubmit && inputValues.guide_title === '' ?
                                                                                    {
                                                                                        color: 'black',
                                                                                        border: '0.2px solid #d32f2f'
                                                                                    }
                                                                                    :
                                                                                    {
                                                                                        color: 'black',
                                                                                        border: 'none'
                                                                                    }
                                                                            }
                                                                            disabled={isLoading && validateEmpty(inputValues.guide_title) ? true : false} />
                                                                        <p style={{
                                                                            color: MaterialRed1,
                                                                            fontSize: '11px',
                                                                            marginTop: '5px',
                                                                            visibility: isSubmit && inputValues.guide_title === '' ? 'visible' : 'hidden'
                                                                        }}>Judul Panduan must be filled</p>
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col lg="6">
                                                                    <FormGroup>
                                                                        <label
                                                                            className="form-control-label"
                                                                            htmlFor="input-select-type"
                                                                        >
                                                                            Tipe Panduan
                                                                        </label>
                                                                        <Select
                                                                            id="input-select-type"
                                                                            options={options}
                                                                            value={
                                                                                inputValues.guide_type
                                                                            }
                                                                            name="guide_type"
                                                                            onChange={(selectedOption) => {
                                                                                setInputValues({ ['guide_type']: selectedOption })
                                                                            }}
                                                                            disabled={isLoading && validateEmpty(inputValues.guide_type.value) ? true : false} />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col lg="12">
                                                            <Row style={{ marginTop: '-20px' }}>
                                                                <Col style={{ height: '400px' }} lg="12">
                                                                    <FormGroup>
                                                                        <label
                                                                            className="form-control-label"
                                                                            htmlFor="input-guide-description"
                                                                        >
                                                                            Isi Panduan
                                                                        </label>
                                                                        <div style={{
                                                                            height: '328px',
                                                                            border: isSubmit && !validateEmpty(inputValues.guide_description) ? `1px solid ${RED_ERROR_MESSAGE}` : '1px solid black',
                                                                            borderRadius: '2px',
                                                                        }}>
                                                                            <CKEditor
                                                                                content={inputValues.guide_description}
                                                                                events={{
                                                                                    blur: onBlur,
                                                                                    afterPaste: afterPaste,
                                                                                    change: onChange
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <p style={{
                                                                            color: MaterialRed1,
                                                                            fontSize: '11px',
                                                                            marginTop: '5px',
                                                                            visibility: isSubmit && !validateEmpty(inputValues.guide_description) ? 'visible' : 'hidden'
                                                                        }}>Isi Panduan must be filled</p>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col lg="12">
                                                            <a onClick={() => updateGuide()} style={{ width: '100%' }} className="btn btn-success btn-sm">
                                                                Simpan
                                                            </a>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Form>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </>
                )
            case GUIDE_DETAIL_NOT_FOUND:
                return (
                    <>
                        <Container className="mt--7" fluid>
                            <Row>
                                <Col className="order-xl-1" xl="12">
                                    <Card className="bg-secondary-shadow">
                                        <CardBody>
                                            <NotFoundPage
                                                imageSource={IcNotFound}
                                                imageText={props.guide.payload.message} />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </>
                )
            case GUIDE_DETAIL_FAILED:
                return (
                    <>
                        <Container className="mt--7" fluid>
                            <Row>
                                <Col className="order-xl-1" xl="12">
                                    <Card className="bg-secondary-shadow">
                                        <CardBody>
                                            <ErrorPage
                                                imageSource={IcError}
                                                imageText={props.guide.payload.message} />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </>
                )
        }
    }

    return (
        <>
            <div className="main-content" ref={mainContent}>
                <div className="header bg-gradient-info pb-8 pt-5 pt-lg-7 d-flex align-items-center">
                    <span className="mask bg-gradient-default opacity-8" />
                </div>
                {buildHelpDetailPage()}
            </div>
        </>
    )
}

HelpDetailPage.propTypes = {
    guide: PropTypes.object.isRequired,
    updateGuide: PropTypes.func.isRequired,
    getDetailGuide: PropTypes.func.isRequired,
    resetCrud: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    guide: state.guide
})

export default connect(mapStateToProps, { updateGuide, getDetailGuide, resetCrud })(HelpDetailPage)