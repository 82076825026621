import { useEffect, useState } from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";
import SuberesLogo from '../../assets/img/brand/logo_suberes.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAnchor, faDesktop, faHome } from '@fortawesome/free-solid-svg-icons'

// reactstrap components
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";
import setAuthToken from "setAuthToken";
import { useHistory } from "react-router-dom";
import socketIO from 'socket.io-client';
import { JSON_CREDENTIALS } from "actions/config";
import { socketSet , socketRemove } from "actions/Socket/SocketService";
import { connect } from "react-redux";
import { INITIATE_SOCKET_ADMIN } from "actions/Socket/Types";
import { REGISTER_SOCKET_ADMIN } from "actions/Socket/Types";
import { RESPONSE_SOCKET_ADMIN } from "actions/Socket/Types";
import setCredentialData from "setCredentialData";
let socket = null;

var ps;

const Sidebar = (props) => {
  const history = useHistory()
  const [ bigScreen , setBigScreen ] = useState(false)
  const windowChangeHandler = (e) => {
    setBigScreen(window.matchMedia('(min-width: 768px)').matches)
  }
  function getBrowserName() {
    let browName = ''
    if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1) {
      browName = 'opera'
    } else if (navigator.userAgent.indexOf("Edg") != -1) {
      browName = 'edge'
    } else if (navigator.userAgent.indexOf("Chrome") != -1) {
      browName = 'edge'
    } else if (navigator.userAgent.indexOf("Safari") != -1) {
      browName = 'safari'
    } else if (navigator.userAgent.indexOf("Firefox") != -1) {
      browName = 'firefox'
    } else if ((navigator.userAgent.indexOf("MSIE") != -1) || (!!document.documentMode == true)) //IF IE > 10
    {
      browName = 'ie'
    } else {
      browName = 'unidentified'
    }
    return browName
  }
  useEffect(() => {
    window.matchMedia('(min-width: 768px)').addEventListener('change' , windowChangeHandler)
    const data = JSON.parse(localStorage.getItem(JSON_CREDENTIALS))
    if(data !== null && data !== undefined){
      socket = socketIO.connect('http://103.163.139.69:5000');

      if(socket != null){
        socket.on(INITIATE_SOCKET_ADMIN , (payload) => {
          const credential = JSON.parse(localStorage.getItem(JSON_CREDENTIALS))
          socket.emit(REGISTER_SOCKET_ADMIN , {
            admin_id : credential.data.id,
            socket_id : payload.socket_id,
            browser_name : getBrowserName()
          })
        })
        socket.on(RESPONSE_SOCKET_ADMIN , (payload) => {
          if(payload.register_status){
            props.socketSet(socket)
          } else {
            if(payload.status_code === 409){
              logoutClick()
            }
          }
        })
      }
    }
  } , [])
  useEffect(() => {
    
  } , [bigScreen])
  const [collapseOpen, setCollapseOpen] = useState();
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  // toggles collapse between opened and closed (true/false)
  const toggleCollapse = () => {
    setCollapseOpen((data) => !data);
  };
  // closes the collapse
  const closeCollapse = () => {
    setCollapseOpen(false);
  };
  // creates the links that appear in the left menu / Sidebar
  const createLinks = (routes) => {
    return routes.map((prop, key) => {
      if(prop.isSidebar){
        return (
          <NavItem key={key}>
            <NavLink
              to={prop.layout + prop.path}
              tag={NavLinkRRD}
              onClick={closeCollapse}
              activeClassName="active"
            >
              {/* <i style={{ color : prop.icon_color }} className={prop.icon} /> */}
              <FontAwesomeIcon icon={prop.icon} style={{ marginRight : '10px' , color : prop.icon_color}} />
              {prop.name}
            </NavLink>
          </NavItem>
        );
      }
    });
  };

  const { bgColor, routes, logo } = props;
  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: "_blank",
    };
  }

  function logoutClick(){
    setAuthToken(false)
    history.push('/auth/login')
  }

  return (
    <Navbar
      className="navbar-vertical fixed-left navbar-light bg-white"
      expand="md"
      id="sidenav-main"
    >
      <Container fluid>
        {/* Toggler */}
        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleCollapse}
        >
          <span className="navbar-toggler-icon" />
        </button>
        {logo ? (
          <NavbarBrand style={{ marginBottom : '-50px', height : '120px' }} className="pt-0" {...navbarBrandProps}>
            <div style={{
              width : '100%',
              height : '100%'
            }}>
              <img
                style={{
                  width : '80%',
                  height : '80%',
                  objectFit : 'contain'
                }}
                src={SuberesLogo}/>
            </div>
          </NavbarBrand>
        ) : null}
        <Nav className="align-items-center d-md-none">
          <UncontrolledDropdown nav>
            <DropdownToggle nav className="nav-link-icon">
              <i className="ni ni-bell-55" />
            </DropdownToggle>
            <DropdownMenu
              aria-labelledby="navbar-default_dropdown_1"
              className="dropdown-menu-arrow"
              right
            >
              <DropdownItem>Action</DropdownItem>
              <DropdownItem>Another action</DropdownItem>
              <DropdownItem divider />
              <DropdownItem>Something else here</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          <UncontrolledDropdown nav>
            <DropdownToggle nav>
              <Media className="align-items-center">
                <span className="avatar avatar-sm rounded-circle">
                  <img
                    alt="..."
                    src={
                      require("../../assets/img/theme/team-1-800x800.jpg")
                        .default
                    }
                  />
                </span>
              </Media>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-arrow" right>
              <DropdownItem className="noti-title" header tag="div">
                <h6 className="text-overflow m-0">Welcome!</h6>
              </DropdownItem>
              <DropdownItem to="/admin/user-profile" tag={Link}>
                <i className="ni ni-settings-gear-65" />
                <span>Settings</span>
              </DropdownItem>
              <DropdownItem to="/admin/user-profile" tag={Link}>
                <i className="ni ni-calendar-grid-58" />
                <span>Activity</span>
              </DropdownItem>
              <DropdownItem to="/admin/user-profile" tag={Link}>
                <i className="ni ni-support-16" />
                <span>Support</span>
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem href="#pablo" onClick={logoutClick}>
                <i className="ni ni-user-run" />
                <span>Logout</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
        {/* Collapse */}
        <Collapse navbar isOpen={collapseOpen}>
          {/* Collapse header */}
          <div className="navbar-collapse-header d-md-none">
            <Row>
              {logo ? (
                <Col className="collapse-brand" xs="6">
                  {logo.innerLink ? (
                    <Link to={logo.innerLink}>
                      <img alt={logo.imgAlt} src={logo.imgSrc} />
                    </Link>
                  ) : (
                    <a href={logo.outterLink}>
                      <img alt={logo.imgAlt} src={logo.imgSrc} />
                    </a>
                  )}
                </Col>
              ) : null}
              <Col className="collapse-close" xs="6">
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={toggleCollapse}
                >
                  <span />
                  <span />
                </button>
              </Col>
            </Row>
          </div>
          {/* Form */}
          
          {/* Navigation */}
          <Nav navbar>{createLinks(routes)}</Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
};

Sidebar.defaultProps = {
  routes: [{}],
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  socket : PropTypes.object.isRequired,
  socketSet : PropTypes.func.isRequired,
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
};

const mapStateToProps = state => ({
  socket : state.socket
})

export default connect(mapStateToProps , { socketSet })(Sidebar)
