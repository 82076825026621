import { useHistory } from "react-router-dom"
import { connect } from "react-redux";
import { Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Modal, ModalBody, ModalHeader, Row, Table } from "reactstrap"
import {
    SCHEDULE_LOADING,
    SCHEDULE_SUCCESSFUL,
    SCHEDULE_FAILED,
    SCHEDULE_NOT_FOUND,
    SCHEDULE_CREATE_LOADING,
    SCHEDULE_CREATE_SUCCESSFUL,
    SCHEDULE_CREATE_EXIST,
    SCHEDULE_CREATE_DATE_WARNING,
    SCHEDULE_CREATE_FAILED,
    SCHEDULE_UPDATE_LOADING,
    SCHEDULE_UPDATE_SUCCESSFUL,
    SCHEDULE_UPDATE_NOT_FOUND,
    SCHEDULE_UPDATE_DATE_WARNING,
    SCHEDULE_UPDATE_FAILED,
    SCHEDULE_REMOVE_LOADING,
    SCHEDULE_REMOVE_SUCCESSFUL,
    SCHEDULE_REMOVE_NOT_FOUND,
    SCHEDULE_REMOVE_UNAUTHORIZED,
    SCHEDULE_REMOVE_FAILED,

    SCHEDULE_RESET_CRUD
} from 'actions/Schedule/Types';
import PropTypes from 'prop-types';
import { DatePicker } from 'reactstrap-date-picker'
import Select from 'react-select';
import {
    getSchedule,
    createSchedule,
    updateSchedule,
    removeSchedule,
    resetScheduleCrud
} from 'actions/Schedule/ScheduleService';
import Skeleton from "react-loading-skeleton";
import { validateEmpty } from "components/Utilities/Utility";
import Pagination from 'components/Utilities/Pagination';
import NotFoundPage from "components/NotFound/NotFoundPage";
import ErrorPage from "components/ErrorComponent/ErrorPage";
import IcNotFound from 'assets/img/ic_not_found.svg';
import IcError from 'assets/img/ic_error.svg';
import { useEffect, useReducer, useState } from "react";
import { MaterialGray1 } from "components/Utilities/ResourceColors";
import { MaterialBlue1 } from "components/Utilities/ResourceColors";
import { validateErrorMessage } from "components/Utilities/Utility";
import { MaterialRed1 } from "components/Utilities/ResourceColors";
import { timeFormat } from "components/Utilities/Utility";
import { JSON_CREDENTIALS } from "actions/config";
import { MaterialGreen1 } from "components/Utilities/ResourceColors";
import { MaterialGreen2 } from "components/Utilities/ResourceColors";
import { validateYMDFormat } from "components/Utilities/Utility";
import Swal from "sweetalert2";
import { RED_ERROR_MESSAGE } from "components/Utilities/ResourceColors";
import { MaterialGray3 } from "components/Utilities/ResourceColors";

const SchedulePage = (props) => {
    const [token, setToken] = useState('')

    const [currentPage, setCurrentPage] = useState(1)
    const [currentPageSearch, setCurrentPageSearch] = useState(1)
    const [isFirst, setFirst] = useState(true)

    const [searchText, setSearchText] = useState('')
    const history = useHistory()

    const [showModalCreate, setShowModalCreate] = useState(false)
    const [showModalUpdate, setShowModalUpdate] = useState(false)
    const [showModalRemove, setShowModalRemove] = useState(false)

    const handleOpenCloseModalCreate = () => {
        if (!isLoading) {
            setShowModalCreate(!showModalCreate)
            if (showModalCreate == false) {
                setSubmit(false)
            }
        }
    }
    const handleOpenCloseModalUpdate = () => {
        if (!isLoading)
            setShowModalUpdate(!showModalUpdate)
    }
    const handleOpenCloseModalRemove = () => {
        if (!isLoading)
            setShowModalRemove(!showModalRemove)
    }

    const scheduleTypeOptions = [
        {
            value: 'executive_level', label: 'Executive Level'
        },
        {
            value: 'c_level', label: 'C Level'
        },
        {
            value: 'employee_level', label: 'Employee Level'
        },
        {
            value: 'mitra_level', label: 'Mitra Level'
        },
        {
            value: 'customer_level', label: 'Customer Level'
        },
        {
            value: 'all_level', label: 'All Level'
        }
    ]

    const [isSubmit, setSubmit] = useState(false)
    const [isLoading, setLoading] = useState(false)

    const [inputValues, setInputValues] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            create_schedule_name: '',
            create_schedule_level: '',
            create_schedule_place: '',
            create_schedule_message: '',
            create_schedule_date: '',
            create_schedule_time: '',
            create_schedule_is_active: '',

            update_schedule_id: -1,
            update_schedule_name: '',
            update_schedule_level: '',
            update_schedule_place: '',
            update_schedule_date: '',
            update_schedule_time: '',
            update_schedule_message: '',
            update_schedule_is_active: '',

            remove_schedule_id: -1,
            remove_schedule_name: '',
            remove_schedule_name_confirm: '',
            remove_schedule_password_confirm: ''
        }
    )

    const onChangeHandler = event => {
        const { name, value } = event.target
        setInputValues({ [name]: value })
    }

    useEffect(() => {
        setToken(localStorage.getItem('token'))
        if (token !== '') {
            props.getSchedule(currentPage, 5, "", token)
        }
    }, [token])

    useEffect(() => {
        if (token !== '') {
            props.getSchedule(currentPage, 5, "", token)
        }
    }, [currentPage])

    const onChangeSearch = (e) => {
        setSearchText(e.target.value)
    }

    useEffect(() => {
        if (!isFirst) {
            const delayDebounceFn = setTimeout(() => {
                if (validateEmpty(searchText)) {
                    setCurrentPageSearch(1)
                    props.getSchedule(currentPage, 5, searchText, token)
                } else {
                    setCurrentPageSearch(1)
                    props.getSchedule(currentPage, 5, "", token)
                }
            }, 800)
            return () => clearTimeout(delayDebounceFn)
        } else {
            setFirst(false)
        }
    }, [searchText])

    useEffect(() => {
        switch (props.schedule.payload_crud.type) {
            case SCHEDULE_CREATE_LOADING:
                setLoading(true)
                break;
            case SCHEDULE_CREATE_SUCCESSFUL:
                setLoading(false)
                setCurrentPage(1)
                setShowModalCreate(false)
                props.resetScheduleCrud()
                setInputValues({
                    create_schedule_name: '',
                    create_schedule_level: '',
                    create_schedule_place: '',
                    create_schedule_message: '',
                    create_schedule_date: '',
                    create_schedule_time: '',
                    create_schedule_is_active: ''
                })
                setSubmit(false)
                if (token !== '') {
                    if (validateEmpty(searchText)) {
                        setSearchText('')
                        setCurrentPageSearch(1)
                        setCurrentPage(1)
                        props.getSchedule(currentPage, 5, "", token)
                    }
                    props.getSchedule(currentPage, 5, "", token)
                }
                props.resetScheduleCrud()
                break;
            case SCHEDULE_CREATE_EXIST:
                setLoading(false)
                Swal.fire(
                    'Perhatian',
                    'Jadwal sudah ada',
                    'warning'
                )
                props.resetScheduleCrud()
                break;
            case SCHEDULE_CREATE_DATE_WARNING:
                setLoading(false)
                Swal.fire(
                    'Perhatian',
                    props.schedule.payload_crud.message,
                    'warning'
                )
                props.resetScheduleCrud()
                break;
            case SCHEDULE_CREATE_FAILED:
                setLoading(false)
                Swal.fire(
                    'Perhatian',
                    'Gagal membuat jadwal',
                    'error'
                )
                props.resetScheduleCrud()
                break;

            case SCHEDULE_UPDATE_LOADING:
                setLoading(true)
                break;
            case SCHEDULE_UPDATE_SUCCESSFUL:
                setLoading(false)
                setShowModalUpdate(false)
                if (token !== '') {
                    if (validateEmpty(searchText)) {
                        props.getSchedule(currentPageSearch, 5, searchText, token)
                    } else {
                        props.getSchedule(currentPage, 5, "", token)
                    }
                }
                props.resetScheduleCrud()
                break;
            case SCHEDULE_UPDATE_NOT_FOUND:
                setLoading(false)
                Swal.fire(
                    'Perhatian',
                    'Jadwal tidak ada',
                    'error'
                )
                props.resetScheduleCrud()
                break;
            case SCHEDULE_UPDATE_DATE_WARNING:
                setLoading(false)
                Swal.fire(
                    'Perhatian',
                    props.schedule.payload_crud.message,
                    'warning'
                )
                props.resetScheduleCrud()
                break;
            case SCHEDULE_UPDATE_FAILED:
                setLoading(false)
                Swal.fire(
                    'Perhatian',
                    'Gagal memperbarui jadwal',
                    'error'
                )
                props.resetScheduleCrud()
                break;
            case SCHEDULE_REMOVE_LOADING:
                setLoading(true)
                break;
            case SCHEDULE_REMOVE_SUCCESSFUL:
                setLoading(false)
                setShowModalRemove(false)
                setInputValues({
                    remove_schedule_id: -1,
                    remove_schedule_name: '',
                    remove_schedule_name_confirm: '',
                    remove_schedule_password_confirm: ''
                })
                if (token !== '') {
                    if (props.schedule.payload.data !== undefined && props.schedule.payload.data.rows_data.docs.length == 1) {
                        if (validateEmpty(searchText)) {
                            if (props.schedule.payload.data.previous_page_number !== undefined && props.schedule.payload.data.previous_page_number != null) {
                                setCurrentPageSearch(props.schedule.payload.data.previous_page_number)
                                props.getSchedule(currentPageSearch, 5, searchText, token)
                            } else {
                                setCurrentPageSearch(1)
                                props.getSchedule(currentPageSearch, 5, searchText, token)
                            }
                        } else {
                            if (props.schedule.payload.data.previous_page_number !== undefined && props.schedule.payload.data.previous_page_number != null) {
                                setCurrentPage(props.schedule.payload.data.previous_page_number)
                            } else {
                                setCurrentPage(1)
                            }
                            props.getSchedule(currentPage, 5, "", token)
                        }
                    } else {
                        if (validateEmpty(searchText)) {
                            props.getSchedule(currentPageSearch, 5, searchText, token)
                        } else {
                            props.getSchedule(currentPage, 5, "", token)
                        }
                    }
                }
                props.resetScheduleCrud()
                break;
            case SCHEDULE_REMOVE_UNAUTHORIZED:
                setLoading(false)
                Swal.fire(
                    'Perhatian',
                    'Password salah',
                    'warning'
                )
                props.resetScheduleCrud()
                break;
            case SCHEDULE_REMOVE_NOT_FOUND:
                setLoading(false)
                Swal.fire(
                    'Perhatian',
                    'Jadwal tidak ada',
                    'warning'
                )
                props.resetScheduleCrud()
            case SCHEDULE_REMOVE_FAILED:
                setLoading(false)
                Swal.fire(
                    'Perhatian',
                    'Gagal menghapus jadwal',
                    'error'
                )
                props.resetScheduleCrud()
                break;
        }
    }, [props.schedule])

    function createSchedule() {
        const data = JSON.parse(localStorage.getItem(JSON_CREDENTIALS))
        if (
            token !== '' &&
            data !== undefined &&
            data !== {} &
            validateEmpty(inputValues.create_schedule_name) &&
            validateEmpty(inputValues.create_schedule_level) &&
            validateEmpty(inputValues.create_schedule_title) &&
            validateEmpty(inputValues.create_schedule_place) &&
            validateEmpty(inputValues.create_schedule_date) &&
            validateEmpty(inputValues.create_schedule_time) &&
            validateTimeFormat(inputValues.create_schedule_time) &&
            validateEmpty(inputValues.create_schedule_message)
        ) {
            const payload = {
                creator_id: data.data.id,
                creator_name: data.data.complete_name,
                schedule_name: inputValues.create_schedule_name,
                schedule_level: inputValues.create_schedule_level.value,
                schedule_title: inputValues.create_schedule_title,
                schedule_place: inputValues.create_schedule_place,
                schedule_date_time: `${inputValues.create_schedule_date} ${inputValues.create_schedule_time}`,
                schedule_message: inputValues.create_schedule_message,
                schedule_is_active: '0',
                timezone_code: Intl.DateTimeFormat().resolvedOptions().timeZone
            }
            console.log(`Create Payload : ${JSON.stringify(payload, null, 2)}`)
            props.createSchedule(payload, token)
        }
    }

    function validateTimeFormat(time){
        if(time === "17:00"){
            return true
        } else {
            return time.match(timeFormat)
        }
    }

    function updateSchedule() {
        const data = JSON.parse(localStorage.getItem(JSON_CREDENTIALS))
        if (
            token !== '' &&
            data !== undefined &&
            data !== {} &
            validateEmpty(inputValues.update_schedule_id) &&
            validateEmpty(inputValues.update_schedule_name) &&
            validateEmpty(inputValues.update_schedule_level) &&
            validateEmpty(inputValues.update_schedule_title) &&
            validateEmpty(inputValues.update_schedule_place) &&
            validateEmpty(inputValues.update_schedule_date) &&
            validateEmpty(inputValues.update_schedule_time) &&
            validateTimeFormat(inputValues.update_schedule_time) &&
            validateEmpty(inputValues.update_schedule_message)
        ) {
            const payload = {
                creator_id: data.data.id,
                creator_name: data.data.complete_name,
                schedule_name: inputValues.update_schedule_name,
                schedule_level: inputValues.update_schedule_level.value,
                schedule_title: inputValues.update_schedule_title,
                schedule_place: inputValues.update_schedule_place,
                schedule_date_time: `${inputValues.update_schedule_date} ${inputValues.update_schedule_time}`,
                schedule_message: inputValues.update_schedule_message,
                schedule_is_active: '0',
                timezone_code: Intl.DateTimeFormat().resolvedOptions().timeZone
            }
            props.updateSchedule(inputValues.update_schedule_id, payload, token)
        }
    }

    function removeSchedule() {
        if (token !== '') {
            if (validateEmpty(inputValues.remove_schedule_id) &&
                validateEmpty(inputValues.remove_schedule_name) &&
                validateEmpty(inputValues.remove_schedule_name_confirm) &&
                validateEmpty(inputValues.remove_schedule_password_confirm) &&
                inputValues.remove_schedule_name === inputValues.remove_schedule_name_confirm) {
                props.removeSchedule(inputValues.remove_schedule_id, inputValues.remove_schedule_password_confirm, token)
            }
        }
    }

    function buildScheduleList() {
        switch (props.schedule.payload.type) {
            case SCHEDULE_LOADING:
                return (
                    <>
                        <Row>
                            <Col lg="12">
                                <Skeleton style={{ height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12">
                                <Skeleton style={{ marginTop: '25px', height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12">
                                <Skeleton style={{ marginTop: '25px', height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12">
                                <Skeleton style={{ marginTop: '25px', height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                            </Col>
                        </Row>
                    </>
                )
            case SCHEDULE_SUCCESSFUL:
                console.log(`props : ${JSON.stringify(props.schedule.payload, null, 2)}`)
                return (
                    <>
                        <Row>
                            <Col xl="12">
                                <Table className="align-items-center table-bordered" responsive>
                                    <thead className="thead-dark">
                                        <tr>
                                            <th style={{ color: 'white' }} scope="col">Nama Jadwal</th>
                                            <th style={{ color: 'white' }} scope="col">Judul Jadwal</th>
                                            <th style={{ color: 'white' }} scope="col">Tempat Pelaksanaan</th>
                                            <th style={{ color: 'white' }} scope="col">Tanggal Pelaksanaan</th>
                                            <th colSpan={3} style={{ color: 'white' }} scope="col">Aksi</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            props.schedule.payload.data.rows_data.docs.map((data) => {
                                                return (
                                                    <tr key={data.id}>
                                                        <td>{data.schedule_name}</td>
                                                        <td>{data.schedule_title}</td>
                                                        <td>{data.schedule_place}</td>
                                                        <td>{data.schedule_date_time}</td>
                                                        <td style={{ float: 'left', marginRight: '5px' }}>
                                                            <button onClick={() => {
                                                                let updateScheduleDate = ''
                                                                let updateScheduleTime = ''
                                                                if (validateEmpty(data.schedule_date_time)) {
                                                                    const splitTime = data.schedule_date_time.split(" ")
                                                                    if (splitTime.length == 2) {
                                                                        updateScheduleDate = validateYMDFormat(splitTime[0]) ? splitTime[0] : ''
                                                                        updateScheduleTime = validateTimeFormat(timeFormat) ? splitTime[1] : ''
                                                                    } else {
                                                                        updateScheduleDate = validateYMDFormat(splitTime[0]) ? splitTime[0] : ''
                                                                        updateScheduleTime = validateTimeFormat(timeFormat) ? splitTime[0] : ''
                                                                    }
                                                                }
                                                                let typeValue = data.schedule_level
                                                                if (typeValue.split("_").length > 1) {
                                                                    const split_status = typeValue.split("_")
                                                                    typeValue = ""
                                                                    for (let i = 0; i < split_status.length; i++) {
                                                                        typeValue += split_status[i].charAt(0).toUpperCase() + split_status[i].slice(1) + (i == split_status.length - 1 ? "" : " ")
                                                                    }
                                                                } else {
                                                                    typeValue = typeValue.charAt(0).toUpperCase() + typeValue.slice(1)
                                                                }
                                                                setInputValues({
                                                                    update_schedule_id: data.id,
                                                                    update_schedule_name: data.schedule_name,
                                                                    update_schedule_level: {
                                                                        value: data.schedule_level,
                                                                        label: typeValue
                                                                    },
                                                                    update_schedule_title: data.schedule_title,
                                                                    update_schedule_place: data.schedule_place,
                                                                    update_schedule_date: updateScheduleDate,
                                                                    update_schedule_time: updateScheduleTime,
                                                                    update_schedule_message: data.schedule_message,
                                                                    update_schedule_is_active: data.schedule_is_active
                                                                })
                                                                setShowModalUpdate(true)
                                                            }} className="btn btn-sm btn-primary"><i className="fas fa-edit"></i> Perbarui</button>
                                                            <button onClick={() => {
                                                                setInputValues({
                                                                    remove_schedule_id: data.id,
                                                                    remove_schedule_name: data.schedule_name
                                                                })
                                                                setShowModalRemove(true)
                                                            }} className="btn btn-sm btn-danger"><i className="fas fa-trash"></i> Hapus</button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                        {
                            !validateEmpty(searchText) ?
                                <Row>
                                    {
                                        props.schedule.payload.data.rows_data !== undefined &&
                                            props.schedule.payload.data.count_total > 5 ?
                                            <div className="card bg-default-shadow" style={{ width: 'max-content', paddingLeft: '20px', marginRight: '0', marginTop: '10px', paddingTop: '15px', marginLeft: 'auto' }}>
                                                <Pagination
                                                    className="pagination-bar"
                                                    currentPage={currentPage}
                                                    totalCount={props.schedule.payload.data.count_total}
                                                    pageSize={5}
                                                    onPageChange={page => {
                                                        setCurrentPage(page)
                                                    }} />
                                            </div>
                                            : <div></div>
                                    }
                                </Row>
                                :
                                <Row>
                                    {
                                        props.schedule.payload.data.rows_data !== undefined &&
                                            props.schedule.payload.data.count_total > 5 ?
                                            <div className="card bg-default-shadow" style={{ width: 'max-content', paddingLeft: '20px', marginRight: '0', marginTop: '10px', paddingTop: '15px', marginLeft: 'auto' }}>
                                                <Pagination
                                                    className="pagination-bar"
                                                    currentPage={currentPageSearch}
                                                    totalCount={props.schedule.payload.data.count_total}
                                                    pageSize={5}
                                                    onPageChange={page => {
                                                        setCurrentPageSearch(page)
                                                    }} />
                                            </div>
                                            : <div></div>
                                    }
                                </Row>
                        }
                    </>
                )
            case SCHEDULE_NOT_FOUND:
                return (
                    <NotFoundPage
                        imageSource={IcNotFound}
                        imageText={props.schedule.payload.message} />
                )
            case SCHEDULE_FAILED:
                return (
                    <ErrorPage
                        imageSource={IcError}
                        imageText={props.schedule.payload.message} />
                )
        }
    }

    return (
        <>
            <div className="main-content">
                <div className="header bg-gradient-info pb-8 pt-5 pt-lg-7 d-flex align-items-center">
                    <span className="mask bg-gradient-default opacity-8" />
                </div>
                <Container className="mt--7" fluid>
                    <Row>
                        <Col xl="12">
                            <Form onSubmit={(e) => {
                                e.preventDefault()
                            }}>
                                <FormGroup>
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i class="fa fa-search" aria-hidden="true" style={{ color: MaterialBlue1, fontSize: 15 }}>
                                                </i>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            id="input-search"
                                            style={{
                                                border: 0,
                                                boxShadow: 'none'
                                            }}
                                            onChange={onChangeSearch}
                                            defaultValue={searchText}
                                            placeholder="Ketik di sini untuk mencari jadwal berdasarkan nama, judul, tempat & deskripsi" />
                                    </InputGroup>
                                </FormGroup>
                            </Form>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl="12">
                            <Card className="bg-secondary shadow">
                                <CardBody>
                                    {
                                        props.schedule.payload.type !== SCHEDULE_LOADING && props.schedule.payload.type !== SCHEDULE_FAILED ?
                                            <Row>
                                                <Col xl="10"></Col>
                                                <Col xl="2">
                                                    <button style={{ fontSize: '11px' }} onClick={() => {
                                                        setShowModalCreate(true)
                                                    }} className="btn btn-sm btn-primary">+ Tambah Jadwal</button>
                                                </Col>
                                            </Row> : <div></div>
                                    }
                                    <div style={{ marginTop: '20px' }}>
                                        {buildScheduleList()}
                                    </div>
                                </CardBody>
                            </Card>
                            {
                                props.schedule.payload.type !== SCHEDULE_LOADING && props.schedule.payload.type !== SCHEDULE_FAILED ?
                                    <Modal isOpen={showModalCreate}
                                        closable={false} keyboard={false} centered>
                                        {
                                            isLoading ?
                                                <div style={{
                                                    height: '5px',
                                                    borderTopLeftRadius: '5px',
                                                    borderTopRightRadius: '5px'
                                                }} className="progress-bar">
                                                    <div className="progress-bar-value"></div>
                                                </div>
                                                : <div style={{ height: '5px' }}></div>
                                        }
                                        <ModalHeader toggle={handleOpenCloseModalCreate}>
                                            <h5>Buat Jadwal Baru</h5>
                                        </ModalHeader>
                                        <ModalBody>
                                            <Form onSubmit={(e) => {
                                                e.preventDefault()
                                                setSubmit(true)
                                                createSchedule()
                                            }}>
                                                <Row style={{ marginTop: "-25px" }}>
                                                    <Col sm="12">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-create-schedule-name"
                                                            >
                                                                Nama Jadwal
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id="input-create-schedule-name"
                                                                type="text"
                                                                name="create_schedule_name"
                                                                onChange={onChangeHandler}
                                                                value={inputValues.create_schedule_name}
                                                                disabled={isLoading ? 'disabled' : ''}
                                                                style={
                                                                    validateErrorMessage(isSubmit && !validateEmpty(inputValues.create_schedule_name))
                                                                } />
                                                            <p style={{
                                                                color: MaterialRed1,
                                                                fontSize: '11px',
                                                                visibility: isSubmit && !validateEmpty(inputValues.create_schedule_name) ? 'visible' : 'hidden'
                                                            }}>Nama Jadwal must be filled</p>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row style={{ marginTop: "-25px" }}>
                                                    <Col sm="12">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-schedule-name"
                                                            >
                                                                Judul Jadwal
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id="input-schedule-name"
                                                                type="text"
                                                                name="create_schedule_title"
                                                                onChange={onChangeHandler}
                                                                value={inputValues.create_schedule_title}
                                                                disabled={isLoading ? 'disabled' : ''}
                                                                style={
                                                                    validateErrorMessage(isSubmit && !validateEmpty(inputValues.create_schedule_title))
                                                                } />
                                                            <p style={{
                                                                color: MaterialRed1,
                                                                fontSize: '11px',
                                                                visibility: isSubmit && !validateEmpty(inputValues.create_schedule_title) ? 'visible' : 'hidden'
                                                            }}>Judul Jadwal must be filled</p>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row style={{ marginTop: "-25px" }}>
                                                    <Col lg="6">
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-schedule-type"
                                                        >
                                                            Tipe Jadwal
                                                        </label>
                                                        <div
                                                            id="input-schedule-type"
                                                            style={{
                                                                borderRadius: '5px',
                                                                border: isSubmit && !validateEmpty(inputValues.create_schedule_level) ? `0.2px solid ${RED_ERROR_MESSAGE}` : 'none'
                                                            }}
                                                        >
                                                            <Select
                                                                options={scheduleTypeOptions}
                                                                value={
                                                                    inputValues.create_schedule_level
                                                                }
                                                                placeholder="Pilih tipe"
                                                                name="create_schedule_level"
                                                                onChange={(selectedOption) => {
                                                                    setInputValues({ ['create_schedule_level']: selectedOption })
                                                                }} />
                                                        </div>
                                                        <p style={{
                                                            color: MaterialRed1,
                                                            fontSize: '11px',
                                                            visibility: isSubmit && !validateEmpty(inputValues.create_schedule_level) ? 'visible' : 'hidden'
                                                        }}>Tipe Jadwal must be selected</p>
                                                    </Col>
                                                    <Col sm="6">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-schedule-place"
                                                            >
                                                                Tempat
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id="input-schedule-place"
                                                                type="text"
                                                                name="create_schedule_place"
                                                                onChange={onChangeHandler}
                                                                value={inputValues.create_schedule_place}
                                                                disabled={isLoading ? 'disabled' : ''}
                                                                style={
                                                                    validateErrorMessage(isSubmit && !validateEmpty(inputValues.create_schedule_place))
                                                                } />
                                                            <p style={{
                                                                color: MaterialRed1,
                                                                fontSize: '11px',
                                                                visibility: isSubmit && !validateEmpty(inputValues.create_schedule_place) ? 'visible' : 'hidden'
                                                            }}>Tempat must be filled</p>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row style={{ marginTop: '-25px' }}>
                                                    <Col lg="6">
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-schedule-date"
                                                        >
                                                            Tanggal
                                                        </label>
                                                        <DatePicker
                                                            id="input-schedule-date"
                                                            style={
                                                                validateErrorMessage(isSubmit && !validateEmpty(inputValues.create_schedule_date))
                                                            }
                                                            value={inputValues.create_schedule_date}
                                                            name="create_schedule_date"
                                                            dateFormat="YYYY-MM-dd"
                                                            disabled={isLoading ? 'disabled' : ''}
                                                            onChange={(v, f) => {
                                                                console.log(`Format : ${f}`)
                                                                setInputValues({
                                                                    create_schedule_date: f
                                                                })
                                                            }} />
                                                        <p style={{
                                                            color: MaterialRed1,
                                                            fontSize: '11px',
                                                            visibility: isSubmit && !validateEmpty(inputValues.create_schedule_date) ? 'visible' : 'hidden'
                                                        }}>Tanggal must be filled</p>
                                                    </Col>
                                                    <Col lg="6">
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-schedule-time"
                                                        >
                                                            Jam
                                                        </label>
                                                        <Input
                                                            id="input-schedule-time"
                                                            style={
                                                                validateErrorMessage(isSubmit && (!validateEmpty(inputValues.create_schedule_time && validateTimeFormat(inputValues.create_schedule_time))))
                                                            }
                                                            name="create_schedule_time"
                                                            placeholder="00:00"
                                                            className="form-control-alternative"
                                                            value={inputValues.create_schedule_time}
                                                            disabled={isLoading ? 'disabled' : ''}
                                                            onChange={onChangeHandler}
                                                        />
                                                        <p style={{
                                                            marginTop: '10px',
                                                            color: MaterialGray3,
                                                            fontSize: '10px'
                                                        }}>Min jam 09:00 dan max jam 16:59</p>
                                                        {
                                                            isSubmit && (!validateEmpty(inputValues.create_schedule_time && validateTimeFormat(inputValues.create_schedule_time))) ?
                                                            <p style={{
                                                                color: MaterialRed1,
                                                                fontSize: '11px',
                                                            }}>Jam must be filled</p> : <></>
                                                        }
                                                        <p style={{
                                                            color: MaterialGray3,
                                                            fontSize: '10px',
                                                            visibility: validateEmpty(inputValues.create_schedule_time) && validateTimeFormat(inputValues.create_schedule_time) ? 'visible' : 'hidden'
                                                        }}>* Jadwal akan ditutup 2 jam sebelum jam yang ditentukan</p>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm="12">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-schedule-message"
                                                            >
                                                                Pesan
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id="input-schedule-message"
                                                                type="textarea"
                                                                name="create_schedule_message"
                                                                onChange={onChangeHandler}
                                                                value={inputValues.create_schedule_message}
                                                                disabled={isLoading ? 'disabled' : ''}
                                                                style={
                                                                    validateErrorMessage(isSubmit && !validateEmpty(inputValues.create_schedule_message))
                                                                } />
                                                            <p style={{
                                                                color: MaterialRed1,
                                                                fontSize: '11px',
                                                                visibility: isSubmit && !validateEmpty(inputValues.create_schedule_message) ? 'visible' : 'hidden'
                                                            }}>Pesan must be filled</p>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg="12">
                                                        <button style={{ backgroundColor: isLoading ? MaterialGray1 : MaterialGreen2, width: '100%' }} type="submit" className="btn btn-sm btn-success" disabled={isLoading ? 'disabled' : ''}>Simpan</button>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </ModalBody>
                                    </Modal> : <div></div>
                            }


                            {
                                props.schedule.payload.type !== SCHEDULE_LOADING && props.schedule.payload.type !== SCHEDULE_FAILED ?
                                    <Modal isOpen={showModalUpdate}
                                        closable={false} keyboard={false} centered>
                                        {
                                            isLoading ?
                                                <div style={{
                                                    height: '5px',
                                                    borderTopLeftRadius: '5px',
                                                    borderTopRightRadius: '5px'
                                                }} className="progress-bar">
                                                    <div className="progress-bar-value"></div>
                                                </div>
                                                : <div style={{ height: '5px' }}></div>
                                        }
                                        <ModalHeader toggle={handleOpenCloseModalUpdate}>
                                            <h5>Perbarui Jadwal</h5>
                                        </ModalHeader>
                                        <ModalBody>
                                            <Form onSubmit={(e) => {
                                                e.preventDefault()
                                                setSubmit(true)
                                                updateSchedule()
                                            }}>
                                                <Row style={{ marginTop: "-25px" }}>
                                                    <Col sm="12">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-labele"
                                                                htmlFor="input-schedule-update-name"
                                                            >
                                                                Nama Jadwal
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id="input-schedule-name"
                                                                type="text"
                                                                name="update_schedule_name"
                                                                onChange={onChangeHandler}
                                                                value={inputValues.update_schedule_name}
                                                                disabled={isLoading ? 'disabled' : ''}
                                                                style={
                                                                    validateErrorMessage(isSubmit && !validateEmpty(inputValues.update_schedule_name))
                                                                } />
                                                            <p style={{
                                                                color: MaterialRed1,
                                                                fontSize: '11px',
                                                                visibility: isSubmit && !validateEmpty(inputValues.update_schedule_name) ? 'visible' : 'hidden'
                                                            }}>Nama Jadwal must be filled</p>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row style={{ marginTop: "-25px" }}>
                                                    <Col sm="12">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-labele"
                                                                htmlFor="input-schedule-name"
                                                            >
                                                                Judul Jadwal
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id="input-schedule-name"
                                                                type="text"
                                                                name="update_schedule_title"
                                                                onChange={onChangeHandler}
                                                                value={inputValues.update_schedule_title}
                                                                disabled={isLoading ? 'disabled' : ''}
                                                                style={
                                                                    validateErrorMessage(isSubmit && !validateEmpty(inputValues.update_schedule_title))
                                                                } />
                                                            <p style={{
                                                                color: MaterialRed1,
                                                                fontSize: '11px',
                                                                visibility: isSubmit && !validateEmpty(inputValues.update_schedule_title) ? 'visible' : 'hidden'
                                                            }}>Judul Jadwal must be filled</p>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row style={{ marginTop: "-25px" }}>
                                                    <Col lg="6">
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-schedule-type"
                                                        >
                                                            Tipe Jadwal
                                                        </label>
                                                        <div
                                                            id="input-schedule-type"
                                                            style={{
                                                                borderRadius: '5px',
                                                                border: isSubmit && !validateEmpty(inputValues.update_schedule_level) ? `0.2px solid ${RED_ERROR_MESSAGE}` : 'none'
                                                            }}
                                                        >
                                                            <Select
                                                                options={scheduleTypeOptions}
                                                                value={
                                                                    inputValues.update_schedule_level
                                                                }
                                                                placeholder="Pilih tipe"
                                                                name="update_schedule_level"
                                                                onChange={(selectedOption) => {
                                                                    setInputValues({ ['update_schedule_level']: selectedOption })
                                                                }} />
                                                        </div>
                                                        <p style={{
                                                            color: MaterialRed1,
                                                            fontSize: '11px',
                                                            visibility: isSubmit && !validateEmpty(inputValues.update_schedule_level) ? 'visible' : 'hidden'
                                                        }}>Tipe Jadwal must be selected</p>
                                                    </Col>
                                                    <Col sm="6">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-schedule-place"
                                                            >
                                                                Tempat
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id="input-schedule-place"
                                                                type="text"
                                                                name="update_schedule_place"
                                                                onChange={onChangeHandler}
                                                                value={inputValues.update_schedule_place}
                                                                disabled={isLoading ? 'disabled' : ''}
                                                                style={
                                                                    validateErrorMessage(isSubmit && !validateEmpty(inputValues.update_schedule_place))
                                                                } />
                                                            <p style={{
                                                                color: MaterialRed1,
                                                                fontSize: '11px',
                                                                visibility: isSubmit && !validateEmpty(inputValues.update_schedule_place) ? 'visible' : 'hidden'
                                                            }}>Tempat must be filled</p>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row style={{ marginTop: '-25px' }}>
                                                    <Col lg="6">
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-schedule-date"
                                                        >
                                                            Tanggal
                                                        </label>
                                                        <DatePicker
                                                            id="input-schedule-date"
                                                            style={
                                                                validateErrorMessage(isSubmit && !validateEmpty(inputValues.update_schedule_date))
                                                            }
                                                            value={inputValues.update_schedule_date}
                                                            name="update_schedule_date"
                                                            dateFormat="YYYY-MM-dd"
                                                            disabled={isLoading ? 'disabled' : ''}
                                                            onChange={(v, f) => {
                                                                console.log(`Format : ${f}`)
                                                                setInputValues({
                                                                    update_schedule_date: f
                                                                })
                                                            }} />
                                                        <p style={{
                                                            color: MaterialRed1,
                                                            fontSize: '11px',
                                                            visibility: isSubmit && !validateEmpty(inputValues.update_schedule_date) ? 'visible' : 'hidden'
                                                        }}>Tanggal must be filled</p>
                                                    </Col>
                                                    <Col lg="6">
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-schedule-time"
                                                        >
                                                            Jam
                                                        </label>
                                                        <Input
                                                            id="input-schedule-time"
                                                            style={
                                                                validateErrorMessage(isSubmit && (!validateEmpty(inputValues.update_schedule_time && validateTimeFormat(inputValues.update_schedule_time))))
                                                            }
                                                            name="update_schedule_time"
                                                            placeholder="00:00"
                                                            className="form-control-alternative"
                                                            value={inputValues.update_schedule_time}
                                                            disabled={isLoading ? 'disabled' : ''}
                                                            onChange={onChangeHandler}
                                                        />
                                                        <p style={{
                                                            marginTop: '10px',
                                                            color: MaterialGray3,
                                                            fontSize: '10px'
                                                        }}>Min jam 09:00 dan max jam 16:59</p>
                                                        {
                                                            isSubmit && (!validateEmpty(inputValues.update_schedule_time && validateTimeFormat(inputValues.update_schedule_time))) ?
                                                            <p style={{
                                                                color: MaterialRed1,
                                                                fontSize: '11px',
                                                            }}>Jam must be filled</p> : <></>
                                                        }
                                                        <p style={{
                                                            color: MaterialGray3,
                                                            fontSize: '10px',
                                                            visibility: validateEmpty(inputValues.update_schedule_time) && validateTimeFormat(inputValues.update_schedule_time) ? 'visible' : 'hidden'
                                                        }}>* Jadwal akan ditutup 2 jam sebelum jam yang ditentukan</p>
                                                    </Col>
                                                </Row>
                                                <Row style={{ marginTop: "-25px" }}>
                                                    <Col sm="12">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-labele"
                                                                htmlFor="input-schedule-message"
                                                            >
                                                                Pesan
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id="input-schedule-message"
                                                                type="textarea"
                                                                name="update_schedule_message"
                                                                onChange={onChangeHandler}
                                                                value={inputValues.update_schedule_message}
                                                                disabled={isLoading ? 'disabled' : ''}
                                                                style={
                                                                    validateErrorMessage(isSubmit && !validateEmpty(inputValues.update_schedule_message))
                                                                } />
                                                            <p style={{
                                                                color: MaterialRed1,
                                                                fontSize: '11px',
                                                                visibility: isSubmit && !validateEmpty(inputValues.update_schedule_message) ? 'visible' : 'hidden'
                                                            }}>Pesan must be filled</p>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg="12">
                                                        <button style={{ backgroundColor: isLoading ? MaterialGray1 : MaterialGreen2, width: '100%' }} type="submit" className="btn btn-sm btn-success" disabled={isLoading ? 'disabled' : ''}>Perbarui</button>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </ModalBody>
                                    </Modal> : <div></div>
                            }
                            {
                                props.schedule.payload.type !== SCHEDULE_LOADING && props.schedule.payload.type !== SCHEDULE_FAILED ?
                                    <Modal isOpen={showModalRemove}
                                        toggle={handleOpenCloseModalRemove} closable={false} keyboard={false} centered
                                    >
                                        {
                                            isSubmit ? isLoading ?
                                                <div className="progress-bar">
                                                    <div className="progress-bar-value"></div>
                                                </div> :
                                                <div style={{ height: '4px' }}></div> : <div></div>
                                        }
                                        <ModalHeader
                                            toggle={handleOpenCloseModalRemove}
                                        >
                                            <h5>Menghapus Jadwal</h5>
                                        </ModalHeader>
                                        <ModalBody>
                                            <p>Kamu yakin ingin menghapus Jadwal <strong>{inputValues.remove_schedule_name}?</strong>, ketik nama jadwal yang ingin di hapus dan masukan kata sandi untuk konfirmasi</p>
                                            <Form onSubmit={(e) => {
                                                e.preventDefault()
                                                removeSchedule()
                                            }}>
                                                <Row>
                                                    <Col xl="12">
                                                        <Input
                                                            className="form-control-alternative"
                                                            type="text"
                                                            name="remove_schedule_name_confirm"
                                                            placeholder="Ketik nama jadwal di sini"
                                                            onChange={onChangeHandler}
                                                            value={inputValues.remove_schedule_name_confirm}
                                                            style={{
                                                                border: inputValues.remove_schedule_name === inputValues.remove_schedule_name_confirm ? 'none' : `1px solid ${RED_ERROR_MESSAGE}`
                                                            }}
                                                            disabled={isLoading ? 'disabled' : ''} />
                                                    </Col>
                                                </Row>
                                                <Row style={{ marginTop: '8px' }}>
                                                    <Col xl="12">
                                                        <Input
                                                            className="form-control-alternative"
                                                            type="password"
                                                            name="remove_schedule_password_confirm"
                                                            onChange={onChangeHandler}
                                                            placeholder="Ketik kata sandi di sini"
                                                            value={inputValues.remove_schedule_password_confirm}
                                                            style={{
                                                                border: validateEmpty(inputValues.remove_schedule_password_confirm) ? 'none' : `1px solid ${RED_ERROR_MESSAGE}`
                                                            }}
                                                            disabled={isLoading ? 'disabled' : ''} />
                                                    </Col>
                                                </Row>
                                                <Row style={{ marginTop: '8px' }}>
                                                    <Col xl="12">
                                                        <button style={{
                                                            width: '100%',
                                                            backgroundColor: ((inputValues.remove_schedule_name === inputValues.remove_schedule_name_confirm) && validateEmpty(inputValues.remove_schedule_password_confirm)) ?
                                                                MaterialRed1 : MaterialGray1,
                                                            border: 'none'
                                                        }} className="btn btn-md btn-danger" disabled={(inputValues.remove_schedule_name === inputValues.remove_schedule_name_confirm) && validateEmpty(inputValues.remove_schedule_password_confirm) ? isLoading ? 'disabled' : '' : 'disabled'}
                                                        >Hapus Layanan</button>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </ModalBody>
                                    </Modal> : <div></div>
                            }
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

SchedulePage.propTypes = {
    schedule: PropTypes.object.isRequired,
    getSchedule: PropTypes.func.isRequired,
    createSchedule: PropTypes.func.isRequired,
    updateSchedule: PropTypes.func.isRequired,
    removeSchedule: PropTypes.func.isRequired,
    resetScheduleCrud: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    schedule: state.schedule
})

export default connect(mapStateToProps, { getSchedule, createSchedule, updateSchedule, removeSchedule, resetScheduleCrud })(SchedulePage)