import { Card, Container, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';

const NotFoundPage = ({ imageSource, imageText, tryClick }) => {
    return (
        <>
            <div className="row justify-content-center align-self-center">
                <div style={{ textAlign: 'center', marginTop: '100px', marginBottom: '100px' }}>
                    <img style={{
                        width: '100px',
                        height: '100px',
                        color: 'gray'
                    }} src={imageSource} alt="MitraEmpty" />
                    <p style={{ marginTop: '10px', fontSize: '15px', fontFamily: 'Open Sans', color: 'gray' }}>{imageText}</p>
                </div>
            </div>
        </>
    )
}

NotFoundPage.propTypes = {
    imageSource: PropTypes.string.isRequired,
    imageText: PropTypes.string.isRequired
}

export default NotFoundPage