import { DASHBOARD_LOADING , DASHBOARD_SUCCESSFUL , DASHBOARD_FAILED } from "actions/Dashboard/Types";

const initState = {
    payload : {
        type : null ,
        message : ''
    }
}

export default function(state = initState , action){
    switch(action.type){
        case DASHBOARD_LOADING:
            return {
                ...state,
                payload : {
                    type : action.payload.type,
                    message : action.payload.message
                }
            }
        case DASHBOARD_SUCCESSFUL:
            return {
                ...state ,
                payload : {
                    type : action.payload.type ,
                    data : action.payload.data
                }
            }
        case DASHBOARD_FAILED:
            return {
                ...state ,
                payload : {
                    type : action.payload.type ,
                    message : action.payload.message
                }
            }
        default:
            return state
    }
}