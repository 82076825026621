import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { Card, CardBody, Col, Container, Row, Table } from "reactstrap";
import React, { useEffect, useState } from "react";
import { getGuide, removeGuide , resetCrud } from "actions/Guide/GuideService";
import Skeleton from "react-loading-skeleton";
import Pagination from "components/Pagination/Pagination";
import NotFoundPage from "components/NotFound/NotFoundPage";
import ErrorPage from "components/ErrorComponent/ErrorPage";
import IcNotFound from 'assets/img/ic_not_found.svg';
import IcError from 'assets/img/ic_error.svg';
import { useHistory } from 'react-router-dom';

import {
    GUIDE_LIST_LOADING,
    GUIDE_LIST_SUCCESSFUL,
    GUIDE_LIST_NOT_FOUND,
    GUIDE_LIST_FAILED,

    GUIDE_REMOVE_LOADING,
    GUIDE_REMOVE_SUCCESSFUL,
    GUIDE_REMOVE_NOT_FOUND,
    GUIDE_REMOVE_FAILED
} from 'actions/Guide/Types'
import { MaterialGray1 } from "components/Utilities/ResourceColors";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { MaterialBlue3 } from "components/Utilities/ResourceColors";
import { validateEmpty } from "components/Utilities/Utility";
const MySwal = withReactContent(Swal)

const NewsPage = (props) => {
    const [token, setToken] = useState('')
    const [currentPage, setCurrentPage] = useState(1)
    const mainContent = React.useRef(null)
    const history = useHistory()

    useEffect(() => {
        setToken(localStorage.getItem('token'))
        if (token !== '') {
            props.getGuide(currentPage, 5, token)
        }
    }, [token])
    useEffect(() => {
        buildNewsPage()
    }, [props.guide])
    useEffect(() => {
        if (token !== '') {
            props.getGuide(currentPage, 5, token)
        }
    }, [currentPage])

    useEffect(() => {
        switch(props.guide.payload_crud.type){
            case GUIDE_REMOVE_LOADING:
                MySwal.hideLoading()
                break;
            case GUIDE_REMOVE_SUCCESSFUL:
                MySwal.hideLoading();
                if(props.guide.payload.data.rows_data.docs.length === 1){
                    if(props.guide.payload.data.previous_page_number !== null){
                        setCurrentPage(props.guide.payload.data.previous_page_number)
                    }
                }
                if (token !== '') {
                    props.getGuide(currentPage , 5 , token)
                }
                props.resetCrud()
                MySwal.fire(
                    'Perhatian',
                    'Berhasil menghapus data berita',
                    'success'
                )
                break;
            case GUIDE_REMOVE_NOT_FOUND:
                MySwal.hideLoading()
                MySwal.fire(
                    'Perhatian',
                    'Data berita tidak ditemukan',
                    'warning'
                )
                break;
            case GUIDE_REMOVE_FAILED:
                MySwal.hideLoading()
                MySwal.fire(
                    'Perhatian',
                    'Gagal menghapus data berita',
                    'error'
                )
                break;
        }
    } , [props.guide.payload_crud])

    function buildNewsPage() {
        if (props.guide.payload.type === GUIDE_LIST_LOADING) {
            return (
                <>
                    <div style={{ marginTop: '15px' }}>
                        <Row>
                            <Col lg="12">
                                <Skeleton style={{ height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12">
                                <Skeleton style={{ marginTop: '25px', height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12">
                                <Skeleton style={{ marginTop: '25px', height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12">
                                <Skeleton style={{ marginTop: '25px', height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12">
                                <Skeleton style={{ marginTop: '25px', height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12">
                                <Skeleton style={{ marginTop: '25px', height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                            </Col>
                        </Row>
                    </div>
                </>
            )
        } else if (props.guide.payload.type === GUIDE_LIST_SUCCESSFUL) {
            if (props.guide.payload.data !== undefined) {
                return (
                    <>
                        <Row>
                            <Col xl="12">
                                <Row style={{ marginTop: '15px' }}>
                                    <Col xl="12">
                                        <Table className="table-bordered" responsive>
                                            <thead className="thead-dark">
                                                <tr>
                                                    <th style={{ color: 'white' }} scope="col">Judul</th>
                                                    <th style={{ color: 'white' }} scope="col">Tipe Panduan</th>
                                                    <th colSpan={2} style={{ color: 'white' }}>Aksi</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    props.guide.payload.data.rows_data.docs.map((data , index) => {
                                                        return (
                                                            <tr key={data.id}>
                                                                <td>{data.guide_title}</td>
                                                                <td>{validateEmpty(data.guide_type) ? `${data.guide_type.charAt(0).toUpperCase()}${data.guide_type.slice(1)}` : '-'}</td>
                                                                <td style={{ float: 'left', marginRight: '5px' }}>
                                                                    <button onClick={() => {
                                                                        history.push({
                                                                            pathname: '/admin/guide_detail_page',
                                                                            state : {
                                                                                guide_id : data.id
                                                                            }
                                                                        })
                                                                    }} className="btn btn-sm btn-primary"><i className="fas fa-eye"></i> Lihat</button>
                                                                    <button onClick={() => {
                                                                        MySwal.fire({
                                                                            title: 'Perhatian',
                                                                            text: `Yakin ingin menghapus bantuan ${data.title}`,
                                                                            showCancelButton: true,
                                                                            confirmButtonText: 'Ya',
                                                                            confirmButtonColor: MaterialBlue3,
                                                                            cancelButtonColor: MaterialGray1,
                                                                            cancelButtonText: 'Batal'
                                                                        }).then(result => {
                                                                            if (result.isConfirmed) {
                                                                                MySwal.fire({
                                                                                    title: 'Harap Tunggu',
                                                                                    html : `Sedang menghapus data <strong>${data.title}</strong>`,
                                                                                    allowOutsideClick : false,
                                                                                    showConfirmButton : false,
                                                                                    didOpen: () => {
                                                                                        MySwal.showLoading()
                                                                                    }
                                                                                })
                                                                                if(token !== '')
                                                                                    props.removeGuide(data.id , token)
                                                                            }
                                                                        })
                                                                    }} className="btn btn-sm btn-danger"><i className="fas fa-remove"></i> Hapus</button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                                <Row>
                                    {
                                        props.guide.payload.data.count_total > 5 ?
                                            <Card className="bg-default-shadow" style={{ width: 'max-content', paddingLeft: '20px', marginRight: '0', marginTop: '10px', paddingTop: '15px', marginLeft: 'auto' }}>
                                                <Pagination
                                                    className="pagination-bar"
                                                    currentPage={currentPage}
                                                    totalCount={props.guide.payload.data.count_total}
                                                    pageSize={5}
                                                    onPageChange={page => {
                                                        setCurrentPage(page)
                                                    }} />
                                            </Card>
                                            : <div></div>
                                    }
                                </Row>
                            </Col>
                        </Row>
                    </>
                )
            }
        } else if (props.guide.payload.type === GUIDE_LIST_NOT_FOUND) {
            return (
                <NotFoundPage imageSource={IcNotFound} imageText={props.guide.payload.message} />
            )
        } else if (props.guide.payload.type === GUIDE_LIST_FAILED) {
            return (
                <ErrorPage imageSource={IcError} imageText={props.guide.payload.message} />
            )
        }
    }
    return (
        <>
            <div className="main-content" ref={mainContent}>
                <div className="header bg-gradient-info pb-8 pt-5 pt-lg-7 d-flex align-items-center">
                    <span className="mask bg-gradient-default opacity-8" />
                </div>
                <Container className="mt--7" fluid>
                    <Card className="bg-secondary shadow">
                        <CardBody>
                            {
                                props.guide.payload.type !== GUIDE_LIST_LOADING && props.guide.payload.type !== GUIDE_LIST_FAILED ?
                                    <Row>
                                        <Col xl="10"></Col>
                                        <Col xl="2">
                                            <button style={{ fontSize : '11px' }} onClick={() => {
                                                history.push({
                                                    pathname: '/admin/guide_create_page'
                                                })
                                            }} className="btn btn-sm btn-primary">+ Tambah Panduan</button>
                                        </Col>
                                    </Row> : <div></div>
                            }
                            {buildNewsPage()}
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </>
    )
}

NewsPage.propTypes = {
    guide: PropTypes.object.isRequired,
    getGuide: PropTypes.func.isRequired,
    removeGuide: PropTypes.func.isRequired,
    resetCrud : PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    guide: state.guide
})

export default connect(mapStateToProps, { getGuide, removeGuide , resetCrud })(NewsPage)