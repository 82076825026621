import {
    TOC_LIST_LOADING,
    TOC_LIST_SUCCESSFUL,
    TOC_LIST_NOT_FOUND,
    TOC_LIST_FAILED,

    TOC_DETAIL_LOADING,
    TOC_DETAIL_SUCCESSFUL,
    TOC_DETAIL_NOT_FOUND,
    TOC_DETAIL_FAILED,

    TOC_CREATE_LOADING,
    TOC_CREATE_SUCCESSFUL,
    TOC_CREATE_WARNING,
    TOC_CREATE_FAILED,

    TOC_UPDATE_STATUS_LOADING,
    TOC_UPDATE_STATUS_SUCCESSFUL,
    TOC_UPDATE_STATUS_WARNING,
    TOC_UPDATE_STATUS_FAILED,

    TOC_UPDATE_LOADING,
    TOC_UPDATE_SUCCESSFUL,
    TOC_UPDATE_NOT_FOUND,
    TOC_UPDATE_WARNING,
    TOC_UPDATE_FAILED,

    TOC_REMOVE_LOADING,
    TOC_REMOVE_SUCCESSFUL,
    TOC_REMOVE_NOT_FOUND,
    TOC_REMOVE_UNAUTHORIZED,
    TOC_REMOVE_FAILED,

    TOC_RESET_CRUD
} from 'actions/Toc/Types';

const initState = {
    payload : {
        type : null,
        message : ''
    },
    payload_crud : {
        type : null,
        message : ''
    }
}

export default function(state = initState , action){
    console.log(`Type : ${action.type}`)
    switch(action.type){
        case TOC_LIST_LOADING:
            return {
                ...state,
                type: action.type,
                payload: {
                    type: action.type,
                    message: action.message
                }
            }
        case TOC_LIST_SUCCESSFUL:
            return {
                ...state,
                type: action.type,
                payload: {
                    type: action.type,
                    data: action.data
                }
            }
        case TOC_LIST_NOT_FOUND:
            return {
                ...state,
                type: action.type,
                payload: {
                    type: action.type,
                    message: action.message
                }
            }
        case TOC_LIST_FAILED:
            return {
                ...state,
                type: action.type,
                payload: {
                    type: action.type,
                    message: action.message
                }
            }
        case TOC_DETAIL_LOADING:
            return {
                ...state,
                type: action.type,
                payload: {
                    type: action.type,
                    message: action.message
                }
            }
        case TOC_DETAIL_SUCCESSFUL:
            return {
                ...state,
                type: action.type,
                payload: {
                    type: action.type,
                    data: action.data
                }
            }
        case TOC_DETAIL_NOT_FOUND:
            return {
                ...state,
                type: action.type,
                payload: {
                    type: action.type,
                    message: action.message
                }
            }
        case TOC_DETAIL_FAILED:
            return {
                ...state,
                type: action.type,
                payload: {
                    type: action.type,
                    message: action.message
                }
            }

        case TOC_CREATE_LOADING:
            return {
                ...state,
                payload_crud: {
                    type : action.type,
                    message : action.message
                }
            }
        case TOC_CREATE_SUCCESSFUL:
            return {
                ...state,
                payload_crud: {
                    type : action.type,
                    message : action.message
                }
            }
        case TOC_CREATE_WARNING:
            return {
                ...state,
                payload_crud: {
                    type : action.type,
                    message : action.message
                }
            }
        case TOC_CREATE_FAILED:
            return {
                ...state,
                payload_crud: {
                    type : action.type,
                    message : action.message
                }
            }

        case TOC_UPDATE_STATUS_LOADING:
            return {
                ...state,
                payload_crud: {
                    type : action.type,
                    message : action.message
                }
            }
        case TOC_UPDATE_STATUS_SUCCESSFUL:
            return {
                ...state,
                payload_crud: {
                    type : action.type,
                    message : action.message
                }
            }
        case TOC_UPDATE_STATUS_WARNING:
            return {
                ...state,
                payload_crud: {
                    type : action.type,
                    message : action.message
                }
            }
        case TOC_UPDATE_STATUS_FAILED:
            return {
                ...state,
                payload_crud: {
                    type : action.type,
                    message : action.message
                }
            }

        case TOC_UPDATE_LOADING:
            return {
                ...state,
                payload_crud: {
                    type : action.type,
                    message : action.message
                }
            }
        case TOC_UPDATE_SUCCESSFUL:
            return {
                ...state,
                payload_crud: {
                    type : action.type,
                    message : action.message
                }
            }
        case TOC_UPDATE_NOT_FOUND:
            return {
                ...state,
                payload_crud: {
                    type : action.type,
                    message : action.message
                }
            }
        case TOC_UPDATE_WARNING:
            return {
                ...state,
                payload_crud: {
                    type : action.type,
                    message : action.message
                }
            }
        case TOC_UPDATE_FAILED:
            return {
                ...state,
                payload_crud: {
                    type : action.type,
                    message : action.message
                }
            }

        case TOC_REMOVE_LOADING:
            return {
                ...state,
                payload_crud: {
                    type : action.type,
                    message : action.message
                }
            }
        case TOC_REMOVE_SUCCESSFUL:
            return {
                ...state,
                payload_crud: {
                    type : action.type,
                    message : action.message
                }
            }
        case TOC_REMOVE_NOT_FOUND:
            return {
                ...state,
                payload_crud: {
                    type : action.type,
                    message : action.message
                }
            }
        case TOC_REMOVE_UNAUTHORIZED:
            return {
                ...state,
                payload_crud: {
                    type : action.type,
                    message : action.message
                }
            }
        case TOC_REMOVE_FAILED:
            return {
                ...state,
                payload_crud: {
                    type : action.type,
                    message : action.message
                }
            }
        case TOC_RESET_CRUD:
            return {
                ...state,
                payload_crud : {
                    type : null,
                    message : ''
                }
            }
        default:
            return state
    }
}