import { MITRA_CANDIDATE_LOADING } from "actions/Mitra/Types";
import { MITRA_CANDIDATE_NOT_FOUND } from "actions/Mitra/Types";
import { MITRA_CANDIDATE_FAILED } from "actions/Mitra/Types";
import { MITRA_CANDIDATE_SUCCESSFUL } from "actions/Mitra/Types";
import { MITRA_LIST_LOADING, MITRA_LIST_SUCCESSFUL, MITRA_LIST_NOT_FOUND, MITRA_LIST_FAILED } from "actions/Mitra/Types";
import { MITRA_DETAIL_LOADING, MITRA_DETAIL_SUCCESSFUL, MITRA_DETAIL_NOT_FOUND, MITRA_DETAIL_FAILED } from "actions/Mitra/Types";
import { UPDATE_MITRA_LOADING, UPDATE_MITRA_SUCCESSFUL, UPDATE_MITRA_NOT_FOUND, UPDATE_MITRA_FAILED } from "actions/Mitra/Types";
import { UPDATE_MITRA_CANDIDATE_LOADING, UPDATE_MITRA_CANDIDATE_SUCCESSFUL, UPDATE_MITRA_CANDIDATE_FAILED } from "actions/Mitra/Types";
import { INVITE_TRAINING_LOADING, INVITE_TRAINING_SUCCESSFUL, INVITE_TRAINING_FAILED } from "actions/Mitra/Types";
import { UPDATE_TRAINING_LOADING, UPDATE_TRAINING_SUCCESSFUL, UPDATE_TRAINING_FAILED } from "actions/Mitra/Types";
import { ACCEPT_MITRA_LOADING, ACCEPT_MITRA_SUCCESSFUL, ACCEPT_MITRA_FAILED } from "actions/Mitra/Types";
import { SUSPEND_MITRA_LOADING, SUSPEND_MITRA_SUCCESSFUL, SUSPEND_MITRA_FAILED } from 'actions/Mitra/Types';
import { ACTIVE_MITRA_LOADING, ACTIVE_MITRA_SUCCESSFUL, ACTIVE_MITRA_FAILED } from 'actions/Mitra/Types';
import { RESET_UPDATE } from "actions/Mitra/Types";

const initState = {
    payload: {
        type: null,
        message: 'Memuat Data Mitra'
    },
    payload_update: {
        type: null,
        message: ''
    },
}

export default function (state = initState, action) {
    console.log(`Mitra Type : ${action.type}`)
    switch (action.type) {
        case MITRA_LIST_LOADING:
            return {
                ...state,
                payload: action.payload
            }
        case MITRA_LIST_SUCCESSFUL:
            return {
                ...state,
                payload: action.payload
            }
        case MITRA_LIST_NOT_FOUND:
            return {
                ...state,
                payload: action.payload
            }
        case MITRA_LIST_FAILED:
            return {
                ...state,
                payload: action.payload
            }
        case MITRA_DETAIL_LOADING:
            return {
                ...state,
                payload: action.payload
            }
        case MITRA_DETAIL_SUCCESSFUL:
            return {
                ...state,
                payload: action.payload
            }
        case MITRA_DETAIL_NOT_FOUND:
            return {
                ...state,
                payload: action.payload
            }
        case MITRA_DETAIL_FAILED:
            return {
                ...state,
                payload: action.payload
            }

        case UPDATE_MITRA_CANDIDATE_LOADING:
            return {
                ...state,
                payload_update: {
                    type: UPDATE_MITRA_CANDIDATE_LOADING,
                    message: action.payload.message
                }
            }

        case UPDATE_MITRA_CANDIDATE_SUCCESSFUL:
            return {
                ...state,
                payload_update: {
                    type: UPDATE_MITRA_CANDIDATE_SUCCESSFUL,
                    message: action.payload.message
                }
            }

        case UPDATE_MITRA_CANDIDATE_FAILED:
            return {
                ...state,
                payload_update: {
                    type: UPDATE_MITRA_CANDIDATE_FAILED,
                    message: action.payload.message
                }
            }

        case MITRA_CANDIDATE_LOADING:
            return {
                ...state,
                payload: action.payload
            }
        case MITRA_CANDIDATE_SUCCESSFUL:
            return {
                ...state,
                payload: action.payload
            }
        case MITRA_CANDIDATE_NOT_FOUND:
            return {
                ...state,
                payload: action.payload
            }
        case MITRA_CANDIDATE_FAILED:
            return {
                ...state,
                payload: action.payload
            }
        case UPDATE_MITRA_LOADING:
            return {
                ...state,
                payload: action.payload
            }
        case UPDATE_MITRA_SUCCESSFUL:
            return {
                ...state,
                payload: action.payload
            }
        case UPDATE_MITRA_NOT_FOUND:
            return {
                ...state,
                payload: action.payload
            }
        case UPDATE_MITRA_FAILED:
            return {
                ...state,
                payload: action.payload
            }
        case INVITE_TRAINING_LOADING:
            return {
                ...state,
                payload_update: {
                    type: INVITE_TRAINING_LOADING,
                    message: action.payload.message
                }
            }
        case INVITE_TRAINING_SUCCESSFUL:
            return {
                ...state,
                payload_update: {
                    type: INVITE_TRAINING_SUCCESSFUL,
                    message: action.payload.message
                }
            }
        case INVITE_TRAINING_FAILED:
            return {
                ...state,
                payload_update: {
                    type: INVITE_TRAINING_FAILED,
                    message: action.payload.message
                }
            }

        case UPDATE_TRAINING_LOADING:
            return {
                ...state,
                payload_update: {
                    type: UPDATE_TRAINING_LOADING,
                    message: action.payload.message
                }
            }
        case UPDATE_TRAINING_SUCCESSFUL:
            return {
                ...state,
                payload_update: {
                    type: UPDATE_TRAINING_SUCCESSFUL,
                    message: action.payload.message
                }
            }
        case UPDATE_TRAINING_FAILED:
            return {
                ...state,
                payload_update: {
                    type: UPDATE_TRAINING_FAILED,
                    message: action.payload.message
                }
            }

        case ACCEPT_MITRA_LOADING:
            return {
                ...state,
                payload_update: {
                    type: ACCEPT_MITRA_LOADING,
                    message: action.payload.message
                }
            }
        case ACCEPT_MITRA_SUCCESSFUL:
            return {
                ...state,
                payload_update: {
                    type: ACCEPT_MITRA_SUCCESSFUL,
                    message: action.payload.message
                }
            }
        case ACCEPT_MITRA_FAILED:
            return {
                ...state,
                payload_update: {
                    type: ACCEPT_MITRA_FAILED,
                    message: action.payload.message
                }
            }

        case SUSPEND_MITRA_LOADING:
            return {
                ...state,
                payload_update: {
                    type: SUSPEND_MITRA_LOADING,
                    message: action.payload.message
                }
            }
        case SUSPEND_MITRA_SUCCESSFUL:
            return {
                ...state,
                payload_update: {
                    type: SUSPEND_MITRA_SUCCESSFUL,
                    message: action.payload.message
                }
            }
        case SUSPEND_MITRA_FAILED:
            return {
                ...state,
                payload_update: {
                    type: SUSPEND_MITRA_FAILED,
                    message: action.payload.message
                }
            }

        case ACTIVE_MITRA_LOADING:
            return {
                ...state,
                payload_update: {
                    type: ACTIVE_MITRA_LOADING,
                    message: action.payload.message
                }
            }
        case ACTIVE_MITRA_SUCCESSFUL:
            return {
                ...state,
                payload_update: {
                    type: ACTIVE_MITRA_SUCCESSFUL,
                    message: action.payload.message
                }
            }
        case ACTIVE_MITRA_FAILED:
            return {
                ...state,
                payload_update: {
                    type: ACTIVE_MITRA_FAILED,
                    message: action.payload.message
                }
            }
        case RESET_UPDATE:
            return {
                ...state,
                payload_update : {
                    type : null,
                    message : ''
                }
            }
        default:
            return state
    }
}