export const SCHEDULE_LOADING = 'SCHEDULE_LIST_LOADING';
export const SCHEDULE_SUCCESSFUL = 'SCHEDULE_LIST_SUCCESSFUL';
export const SCHEDULE_FAILED = 'SCHEDULE_LIST_FAILED';
export const SCHEDULE_NOT_FOUND = 'SCHEDULE_LIST_NOT_FOUND'

export const SCHEDULE_DETAIL_LOADING = 'SCHEDULE_DETAIL_LOADING';
export const SCHEDULE_DETAIL_SUCCESSFUL = 'SCHEDULE_DETAIL_SUCCESSFUL';
export const SCHEDULE_DETAIL_NOT_FOUND = 'SCHEDULE_DETAIL_NOT_FOUND';
export const SCHEDULE_DETAIL_FAILED = 'SCHEDULE_DETAIL_FAILED';

export const SCHEDULE_CREATE_LOADING = 'SCHEDULE_CREATE_LOADING';
export const SCHEDULE_CREATE_SUCCESSFUL = 'SCHEDULE_CREATE_SUCCESSFUL';
export const SCHEDULE_CREATE_EXIST = 'SCHEDULE_CREATE_EXIST'
export const SCHEDULE_CREATE_DATE_WARNING = 'SCHEDULE_CREATE_DATE_WARNING'
export const SCHEDULE_CREATE_FAILED = 'SCHEDULE_CREATE_FAILED';

export const SCHEDULE_UPDATE_LOADING = 'SCHEDULE_UPDATE_LOADING'
export const SCHEDULE_UPDATE_SUCCESSFUL = 'SCHEDULE_UPDATE_SUCCESSFUL';
export const SCHEDULE_UPDATE_NOT_FOUND = 'SCHEDULE_UPDATE_NOT_FOUND';
export const SCHEDULE_UPDATE_DATE_WARNING = 'SCHEDULE_UPDATE_DATE_WARNING'
export const SCHEDULE_UPDATE_FAILED = 'SCHEDULE_UPDATE_FAILED';

export const SCHEDULE_REMOVE_LOADING = 'SCHEDULE_REMOVE_LOADING';
export const SCHEDULE_REMOVE_SUCCESSFUL = 'SCHEDULE_REMOVE_SUCCESSFUL';
export const SCHEDULE_REMOVE_NOT_FOUND = 'SCHEDULE_REMOVE_NOT_FOUND';
export const SCHEDULE_REMOVE_UNAUTHORIZED = 'SCHEDULE_REMOVE_UNAUTHORIZED';
export const SCHEDULE_REMOVE_FAILED = 'SCHEDULE_REMOVE_FAILED';

export const SCHEDULE_RESET_CRUD = 'SCHEDULE_RESET_CRUD'