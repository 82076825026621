import {
    SCHEDULE_LOADING,
    SCHEDULE_SUCCESSFUL,
    SCHEDULE_FAILED,
    SCHEDULE_NOT_FOUND,
    SCHEDULE_CREATE_LOADING,
    SCHEDULE_CREATE_SUCCESSFUL,
    SCHEDULE_CREATE_EXIST,
    SCHEDULE_CREATE_DATE_WARNING,
    SCHEDULE_CREATE_FAILED,
    SCHEDULE_UPDATE_LOADING,
    SCHEDULE_UPDATE_SUCCESSFUL,
    SCHEDULE_UPDATE_NOT_FOUND,
    SCHEDULE_UPDATE_DATE_WARNING,
    SCHEDULE_UPDATE_FAILED,
    SCHEDULE_REMOVE_LOADING,
    SCHEDULE_REMOVE_SUCCESSFUL,
    SCHEDULE_REMOVE_NOT_FOUND,
    SCHEDULE_REMOVE_UNAUTHORIZED,
    SCHEDULE_REMOVE_FAILED,
    SCHEDULE_DETAIL_LOADING,
    SCHEDULE_DETAIL_SUCCESSFUL,
    SCHEDULE_DETAIL_NOT_FOUND,
    SCHEDULE_DETAIL_FAILED,

    SCHEDULE_RESET_CRUD
} from 'actions/Schedule/Types';

const initState = {
    payload: {
        type: null,
        message: ''
    },
    payload_crud: {
        type: null,
        message: ''
    }
}

export default function (state = initState, action) {
    switch (action.type) {
        case SCHEDULE_LOADING:
            return {
                ...state,
                type: action.type,
                payload: action.payload
            }
        case SCHEDULE_SUCCESSFUL:
            return {
                ...state,
                type: action.type,
                payload: action.payload
            }
        case SCHEDULE_NOT_FOUND:
            return {
                ...state,
                type: action.type,
                payload: action.payload
            }
        case SCHEDULE_FAILED:
            return {
                ...state,
                type: action.type,
                payload: action.payload
            }
        case SCHEDULE_DETAIL_LOADING:
            return {
                ...state,
                type: action.type,
                payload: action.payload
            }
        case SCHEDULE_DETAIL_SUCCESSFUL:
            return {
                ...state,
                type: action.type,
                payload: action.payload
            }
        case SCHEDULE_DETAIL_NOT_FOUND:
            return {
                ...state,
                type: action.type,
                payload: action.payload
            }
        case SCHEDULE_DETAIL_FAILED:
            return {
                ...state,
                type: action.type,
                payload: action.payload
            }

        case SCHEDULE_CREATE_LOADING:
            return {
                ...state,
                payload_crud: action.payload
            }
        case SCHEDULE_CREATE_SUCCESSFUL:
            return {
                ...state,
                payload_crud: action.payload
            }
        case SCHEDULE_CREATE_EXIST:
            return {
                ...state,
                payload_crud: action.payload
            }
        case SCHEDULE_CREATE_DATE_WARNING:
            return {
                ...state,
                payload_crud : action.payload
            }
        case SCHEDULE_CREATE_FAILED:
            return {
                ...state,
                payload_crud: action.payload
            }

        case SCHEDULE_UPDATE_LOADING:
            return {
                ...state,
                payload_crud: action.payload
            }
        case SCHEDULE_UPDATE_SUCCESSFUL:
            return {
                ...state,
                payload_crud: action.payload
            }
        case SCHEDULE_UPDATE_NOT_FOUND:
            return {
                ...state,
                payload_crud: action.payload
            }
        case SCHEDULE_UPDATE_DATE_WARNING:
            return {
                ...state,
                payload_crud : action.payload
            }
        case SCHEDULE_UPDATE_FAILED:
            return {
                ...state,
                payload_crud: action.payload
            }

        case SCHEDULE_REMOVE_LOADING:
            return {
                ...state,
                payload_crud: action.payload
            }
        case SCHEDULE_REMOVE_SUCCESSFUL:
            return {
                ...state,
                payload_crud: action.payload
            }
        case SCHEDULE_REMOVE_NOT_FOUND:
            return {
                ...state,
                payload_crud: action.payload
            }
        case SCHEDULE_REMOVE_UNAUTHORIZED:
            return {
                ...state,
                payload_crud: action.payload
            }
        case SCHEDULE_REMOVE_FAILED:
            return {
                ...state,
                payload_crud: action.payload
            }
        case SCHEDULE_RESET_CRUD:
            return {
                ...state,
                payload_crud: {
                    type: null,
                    message: ''
                }
            }
        default:
            return state
    }
}