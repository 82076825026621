import { BASE_URL } from 'actions/config';
import { JSON_CREDENTIALS } from 'actions/config';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import setAuthToken from '../../setAuthToken'
import { useEffect , useState } from 'react';
import { useHistory } from 'react-router-dom';
import SuberesBrokenImage from '../../assets/img/ic_suberes_broken_image.png'
import { logoutAdmin } from 'actions/Auth/AuthService';
import PropTypes from 'prop-types';

import {
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Navbar,
    Nav,
    Container,
    Media
} from 'reactstrap';
import { LOGOUT_SUCCESSFUL } from 'actions/Auth/Types';
import { LOGOUT_FAILED } from 'actions/Auth/Types';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
const MySwal = withReactContent(Swal)

const AdminNavbar = (props) => {
    const history = useHistory()
    const [token , setToken] = useState('')
    useEffect(() => {
        setToken(localStorage.getItem('token'))
    } , [token])
    function loadUserProfileImage(){
        if(localStorage.getItem(JSON_CREDENTIALS) !== null){
            const data = JSON.parse(localStorage.getItem(JSON_CREDENTIALS))
            return data.data.user_profile_image
        }
        return ""
    }
    function loadCompleteName(){
        if(localStorage.getItem(JSON_CREDENTIALS) !== null){
            const data = JSON.parse(localStorage.getItem(JSON_CREDENTIALS))
            return data.data.complete_name
        }
        return "Unknown"
    }
    useEffect(() => {
        switch(props.auth.payload.type){
            case LOGOUT_SUCCESSFUL:
                setAuthToken(false)
                history.push('/auth/login')
                break;
            case LOGOUT_FAILED:
                MySwal.fire(
                    'Perhatian',
                    'Gagal Logout',
                    'error'
                )
                break;
        }
    } , [props.auth])
    return (
        <>
        <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
            <Container fluid>
                <Link
                    className="h4 mb-0 d-none d-lg-inline-block"
                    to="/"
                    style={{ color : 'white' }}
                >
                    {props.brandText}
                </Link>
                <Nav className="align-items-center d-none d-md-flex" navbar>
                    <UncontrolledDropdown nav>
                        <DropdownToggle className="pr-0" nav>
                            <Media className="align-items-center">
                                <span>
                                    <img
                                        style={{ width : '50px' , height : '50px' , borderRadius : '20%'}}
                                        src={`${BASE_URL}/api/images/${loadUserProfileImage()}`}
                                        onError={(e) => {
                                            e.target.src = SuberesBrokenImage
                                        }}/>
                                </span>
                                <Media className="ml-2 d-none d-lg-block">
                                    <span style={{ color : 'white' }} className="mb-0 text-sm font-weight-bold">
                                        {loadCompleteName()}
                                    </span>
                                </Media>
                            </Media>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-arrow" right>
                            <DropdownItem className="noti-title" header tag="div">
                                <h6 className="text-overflow m-0">Welcome!</h6>
                            </DropdownItem>
                            <DropdownItem divider />
                            <DropdownItem onClick={(e) => {
                                if(localStorage.getItem(JSON_CREDENTIALS) !== null){
                                    const data = JSON.parse(localStorage.getItem(JSON_CREDENTIALS))
                                    props.logoutAdmin(data.data.id , token)
                                } else {
                                    MySwal.fire(
                                        'Perhatian',
                                        'Gagal Logout',
                                        'error'
                                    )
                                }
                            }}>
                                <i className="ni ni-user-run" />
                                <span>Logout</span>
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </Nav>
            </Container>
        </Navbar>
        </>
    )
}

AdminNavbar.propTypes = {
    auth : PropTypes.object.isRequired,
    logoutAdmin : PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    auth : state.auth
})

export default connect(mapStateToProps , { logoutAdmin })(AdminNavbar)