import { useEffect, useState } from "react";
import classnames from "classnames";
import Chart from "chart.js";
import { Line, Bar } from "react-chartjs-2";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from "../Variables/chart";

import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { getDashboardData } from "actions/Dashboard/DashboardService";

import Header from "components/Headers/Header.js";
import Skeleton from "react-loading-skeleton";
import { MaterialGray1 } from "components/Utilities/ResourceColors";
import { DASHBOARD_LOADING } from "actions/Dashboard/Types";
import { DASHBOARD_SUCCESSFUL } from "actions/Dashboard/Types";
import { DASHBOARD_FAILED } from "actions/Dashboard/Types";
import { MaterialRed1 } from "components/Utilities/ResourceColors";
//import { fetchToken, onMessageListener } from "../../firebase";
import AdminNavbar from "components/Navbars/AdminNavbar";

const Index = (props) => {
  const [activeNav, setActiveNav] = useState(1);
  const [isMonthData, setMonthData] = useState(true)
  const [token , setToken] = useState('')

  useEffect(() => {
    setToken(localStorage.getItem('token'))
    if(token !== ''){
      props.getDashboardData(token)
    }
  }, [token])

  useEffect(() => {
    buildOrderChart()
    buildOverviewChart()
    buildServiceCountList()
  }, [props.dashboard])

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }
  function buildOrderChart() {
    const responseType = props.dashboard.payload.type
    if (responseType === DASHBOARD_LOADING) {
      return (
        <Col xl="6">
          <Card className="shadow">
            <CardHeader className="bg-transparent">
              <Row className="align-items-center">
                <div className="col">
                  <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }} />
                  <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }} />
                </div>
              </Row>
            </CardHeader>
            <CardBody>
              {/* Chart */}
              <div style={{
                height: '350px',
                display: 'flex',
                flexWrap: 'wrap'
              }}>
                <div style={{
                  flex: '1 1 20%',
                }}>
                  <Skeleton style={{ height: '100%', width: '100%', backgroundColor: MaterialGray1 }} />
                </div>
                <div style={{
                  flex: '1 1 20%',
                  marginLeft: '20px'
                }}>
                  <Skeleton style={{ height: '100%', width: '100%', backgroundColor: MaterialGray1 }} />
                </div>
                <div style={{
                  flex: '1 1 20%',
                  marginLeft: '20px'
                }}>
                  <Skeleton style={{ height: '100%', width: '100%', backgroundColor: MaterialGray1 }} />
                </div>
                <div style={{
                  flex: '1 1 20%',
                  marginLeft: '20px'
                }}>
                  <Skeleton style={{ height: '100%', width: '100%', backgroundColor: MaterialGray1 }} />
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      )
    } else if (responseType === DASHBOARD_SUCCESSFUL) {
      if (props.dashboard.payload.data !== undefined && props.dashboard.payload.data.total_orders_by_month !== undefined) {
        const labelsArray = []
        const dataArray = []
        for (let i = 0; i < props.dashboard.payload.data.total_orders_by_month.length; i++) {
          labelsArray.push(props.dashboard.payload.data.total_orders_by_month[i].bulan.substring(0, 3))
          dataArray.push(props.dashboard.payload.data.total_orders_by_month[i].order_count)
        }
        const payload_chart = {
          labels: labelsArray,
          datasets: [
            {
              label: "Order",
              data: dataArray,
              maxBarThickness: 5
            }
          ]
        }
        return (
          <Col xl="6">
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase text-muted ls-1 mb-1">
                      Performance
                    </h6>
                    <h2 className="mb-0">Total Orders</h2>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                {/* Chart */}
                <div className="chart">
                  <Bar
                    data={payload_chart}
                    options={chartExample2.options}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        )
      }
    } else if (responseType == DASHBOARD_FAILED) {
      return (
        <Col lg="6">
          <Card className="card-stats mb-4 mb-xl-0">
            <CardBody>
              <Row>
                <div style={{
                  width: '100%',
                  height: '450px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  display: 'flex',
                  textAlign: 'center',
                  marginTop: '10px'
                }}>
                  <i style={{ color: MaterialRed1, fontSize: '30px' }} className="fas fa-exclamation-circle"></i>
                  <p>Tidak dapat memuat data</p>
                </div>
              </Row>
            </CardBody>
          </Card>
        </Col>
      )
    } else {
      return (
        <Col xl="6">
          <Card className="shadow">
            <CardHeader className="bg-transparent">
              <Row className="align-items-center">
                <div className="col">
                  <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }} />
                  <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }} />
                </div>
              </Row>
            </CardHeader>
            <CardBody>
              {/* Chart */}
              <div style={{
                height: '350px',
                display: 'flex',
                flexWrap: 'wrap'
              }}>
                <div style={{
                  flex: '1 1 20%',
                }}>
                  <Skeleton style={{ height: '100%', width: '100%', backgroundColor: MaterialGray1 }} />
                </div>
                <div style={{
                  flex: '1 1 20%',
                  marginLeft: '20px'
                }}>
                  <Skeleton style={{ height: '100%', width: '100%', backgroundColor: MaterialGray1 }} />
                </div>
                <div style={{
                  flex: '1 1 20%',
                  marginLeft: '20px'
                }}>
                  <Skeleton style={{ height: '100%', width: '100%', backgroundColor: MaterialGray1 }} />
                </div>
                <div style={{
                  flex: '1 1 20%',
                  marginLeft: '20px'
                }}>
                  <Skeleton style={{ height: '100%', width: '100%', backgroundColor: MaterialGray1 }} />
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      )
    }
  }
  function buildOverviewChart() {
    switch (props.dashboard.payload.type) {
      case DASHBOARD_LOADING:
        return (
          <Col className="mb-5 mb-xl-0" xl="6">
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }} />
                    <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }} />
                  </div>
                  <div className="col">
                    <Skeleton style={{ width: '45%', height: '45px', backgroundColor: MaterialGray1 }} />
                    <Skeleton style={{ width: '45%', marginLeft: '10px', height: '45px', backgroundColor: MaterialGray1 }} />
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                <div style={{
                  width: '100%',
                  height: '350px'
                }}>
                  <Skeleton style={{ width: '100%', height: '350px', backgroundColor: MaterialGray1 }} />
                </div>
              </CardBody>
            </Card>
          </Col>
        )
      case DASHBOARD_SUCCESSFUL:
        if (props.dashboard.payload.data !== undefined && props.dashboard.payload.data.overview_month !== undefined &&
          props.dashboard.payload.data.overview_week !== undefined) {
          const labelMonthArray = []
          const dataMonthArray = []

          const labelWeekArray = []
          const dataWeekArray = []

          const overview_month = props.dashboard.payload.data.overview_month
          for (let i = 0; i < overview_month.length; i++) {
            labelMonthArray.push(overview_month[i].bulan.substring(0, 3))
            dataMonthArray.push(parseInt(overview_month[i].total_revenue))
          }

          const overview_week = props.dashboard.payload.data.overview_week
          for (let i = 0; i < overview_week.length; i++) {
            labelWeekArray.push(`Minggu Ke ${overview_week[i].month_week.split(" ")[2]}`)
            dataWeekArray.push(parseInt(overview_week[i].total_transaction))
          }

          const payload_month = {
            labels: labelMonthArray,
            datasets: [
              {
                label: "Revenue",
                data: dataMonthArray
              }
            ]
          }

          const payload_week = {
            labels: labelWeekArray,
            datasets: [
              {
                label: "Revenue",
                data: dataWeekArray
              }
            ]
          }

          return (
            <Col className="mb-5 mb-xl-0" xl="6">
              <Card className="bg-gradient-default shadow">
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <div className="col">
                      <h6 className="text-uppercase text-light ls-1 mb-1">
                        Revenue
                      </h6>
                      <h2 className="text-white mb-0">Revenue Overview</h2>
                    </div>
                    <div className="col">
                      <Nav className="justify-content-end" pills>
                        <NavItem>
                          <NavLink
                            className={classnames("py-2 px-3", {
                              active: activeNav === 1,
                            })}
                            href="#pablo"
                            onClick={(e) => {
                              setMonthData(true)
                              setActiveNav(1)
                            }}
                          >
                            <span className="d-none d-md-block">Month</span>
                            <span className="d-md-none">M</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames("py-2 px-3", {
                              active: activeNav === 2,
                            })}
                            data-toggle="tab"
                            href="#pablo"
                            onClick={(e) => {
                              setMonthData(false)
                              setActiveNav(2)
                            }}
                          >
                            <span className="d-none d-md-block">Week</span>
                            <span className="d-md-none">W</span>
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  {/* Chart */}
                  <div className="chart">
                    <Line
                      data={isMonthData ? payload_month : payload_week}
                      options={chartExample1.options}
                      getDatasetAtEvent={(e) => console.log(e)}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          )
        }
      case DASHBOARD_FAILED:
        return (
          <Col lg="6">
            <Card className="card-stats mb-4 mb-xl-0">
              <CardBody>
                <Row>
                  <div style={{
                    width: '100%',
                    height: '450px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    display: 'flex',
                    textAlign: 'center',
                    marginTop: '10px'
                  }}>
                    <i style={{ color: MaterialRed1, fontSize: '30px' }} className="fas fa-exclamation-circle"></i>
                    <p>Tidak dapat memuat data</p>
                  </div>
                </Row>
              </CardBody>
            </Card>
          </Col>
        )
      default:
        return (
          <Col className="mb-5 mb-xl-0" xl="6">
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }} />
                    <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }} />
                  </div>
                  <div className="col">
                    <Skeleton style={{ width: '45%', height: '45px', backgroundColor: MaterialGray1 }} />
                    <Skeleton style={{ width: '45%', marginLeft: '10px', height: '45px', backgroundColor: MaterialGray1 }} />
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                <div style={{
                  width: '100%',
                  height: '350px'
                }}>
                  <Skeleton style={{ width: '100%', height: '350px', backgroundColor: MaterialGray1 }} />
                </div>
              </CardBody>
            </Card>
          </Col>
        )
    }
  }
  function buildServiceCountList() {
    switch (props.dashboard.payload.type) {
      case DASHBOARD_LOADING:
        return (
          <Col className="mb-5 mb-xl-0" xl="8">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <Skeleton style={{ width: '100%', height: '30px', backgroundColor: MaterialGray1 }} />
                  </div>
                  <div className="col text-right">
                    <Skeleton style={{ width: '100%', height: '30px', backgroundColor: MaterialGray1 }} />
                  </div>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead>
                  <tr>
                    <th scope="col">
                      <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }} />
                    </th>
                    <th scope="col">
                      <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }} />
                    </th>
                    <th scope="col">
                      <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }} />
                    </th>
                    <th scope="col">
                      <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }} />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">
                      <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }} />
                    </th>
                    <td>
                      <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }} />
                    </td>
                    <td>
                      <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }} />
                    </td>
                    <td>
                      <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }} />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }} />
                    </th>
                    <td>
                      <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }} />
                    </td>
                    <td>
                      <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }} />
                    </td>
                    <td>
                      <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }} />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }} />
                    </th>
                    <td>
                      <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }} />
                    </td>
                    <td>
                      <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }} />
                    </td>
                    <td>
                      <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }} />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }} />
                    </th>
                    <td>
                      <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }} />
                    </td>
                    <td>
                      <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }} />
                    </td>
                    <td>
                      <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }} />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Card>
          </Col>
        )
      case DASHBOARD_SUCCESSFUL:
        return (
          <Col className="mb-5 mb-xl-0" xl="8">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col-md-8">
                    <h3 className="mb-0">Layanan paling sering digunakan</h3>
                  </div>
                  {/* <div className="col text-right">
                    <Button
                      color="primary"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                      size="sm"
                    >
                      See all
                    </Button>
                  </div> */}
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Layanan</th>
                    <th scope="col">Digunakan</th>
                    <th scope="col">Tipe Layanan</th>
                    <th scope="col">Kategori</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    props.dashboard.payload.data.fus_service !== undefined ? 
                    props.dashboard.payload.data.fus_service.map((item) => {
                      return (
                        <tr>
                          <th scope="row">
                            {item.service_name}
                          </th>
                          <td>{`${item.service_count} kali`}</td>
                          <td>{item.service_type}</td>
                          <td>
                            {item.service_category}
                          </td>
                        </tr>
                      )
                    })
                    :
                    <div></div>
                  }
                </tbody>
              </Table>
            </Card>
          </Col>
        )
      case DASHBOARD_FAILED:
        return (
          <Col className="mb-5 mb-xl-0" xl="8">
            <Card className="card-stats mb-4 mb-xl-0">
              <CardBody>
                <Row>
                  <div style={{
                    width: '100%',
                    height: '345px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    display: 'flex',
                    textAlign: 'center',
                    backgroundColor: 'white',
                    marginTop: '10px'
                  }}>
                    <i style={{ color: MaterialRed1, fontSize: '30px' }} className="fas fa-exclamation-circle"></i>
                    <p>Tidak dapat memuat data</p>
                  </div>
                </Row>
              </CardBody>
            </Card>
          </Col>
        )
      default:

    }
  }
  function buildMitraOrderList() {
    switch (props.dashboard.payload.type) {
      case DASHBOARD_LOADING:
        return (
          <Col xl="4">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <Skeleton style={{ width: '100%', height: '30px', backgroundColor: MaterialGray1 }} />
                  </div>
                  <div className="col text-right">
                    <Skeleton style={{ width: '100%', height: '30px', backgroundColor: MaterialGray1 }} />
                  </div>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">
                      <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }} />
                    </th>
                    <th scope="col">
                      <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }} />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">
                      <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }} />
                    </th>
                    <td>
                      <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }} />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }} />
                    </th>
                    <td>
                      <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }} />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }} />
                    </th>
                    <td>
                      <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }} />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }} />
                    </th>
                    <td>
                      <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }} />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Card>
          </Col>
        )
      case DASHBOARD_SUCCESSFUL:
        return (
          <Col xl="4">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Top 5 Mitra Hari Ini</h3>
                  </div>
                  {/* <div className="col text-right">
                    <Button
                      color="primary"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                      size="sm"
                    >
                      See all
                    </Button>
                  </div> */}
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Nama</th>
                    <th scope="col">Orderan</th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {
                    props.dashboard.payload.data.mitra_order_today !== undefined ? 
                    props.dashboard.payload.data.mitra_order_today.map((item) => {
                      return (
                        <tr>
                          <th scope="row">
                            {item.mitra.complete_name}
                          </th>
                          <td>{`${item.order_count} Kali`}</td>
                        </tr>
                      )
                    })
                    :
                    <div></div>
                  }
                </tbody>
              </Table>
            </Card>
          </Col>
        )
      case DASHBOARD_FAILED:
        return (
          <Col xl="4">
            <Card className="card-stats mb-4 mb-xl-0">
              <CardBody>
                <Row>
                  <div style={{
                    width: '100%',
                    height: '345px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    display: 'flex',
                    textAlign: 'center',
                    marginTop: '10px'
                  }}>
                    <i style={{ color: MaterialRed1, fontSize: '30px' }} className="fas fa-exclamation-circle"></i>
                    <p>Tidak dapat memuat data</p>
                  </div>
                </Row>
              </CardBody>
            </Card>
          </Col>
        )
      default:
    }
  }
  return (
    <>
      <div className="main-content">
        <div className="header bg-gradient-info pb-8 pt-5 pt-lg-6 d-flex align-items-center">
          <span className="mask bg-gradient-default opacity-8" />
        </div>
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Header dashboard={props} />
          <Row style={{ marginTop: '35px' }}>
            {buildOverviewChart()}
            {buildOrderChart()}
          </Row>
          <Row className="mt-5">
            {buildServiceCountList()}
            {buildMitraOrderList()}
          </Row>
        </Container>
      </div>
    </>
  );
};

Index.propTypes = {
  dashboard: PropTypes.object.isRequired,
  getDashboardData: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  dashboard: state.dashboard
})

export default connect(mapStateToProps, { getDashboardData })(Index)