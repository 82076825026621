import {
    COMPLAIN_LIST_LOADING,
    COMPLAIN_LIST_SUCCESSFUL,
    COMPLAIN_LIST_NOT_FOUND,
    COMPLAIN_LIST_FAILED,

    COMPLAIN_DETAIL_LOADING,
    COMPLAIN_DETAIL_SUCCESSFUL,
    COMPLAIN_DETAIL_NOT_FOUND,
    COMPLAIN_DETAIL_FAILED,

    COMPLAIN_UPDATE_LOADING,
    COMPLAIN_UPDATE_SUCCESSFUL,
    COMPLAIN_UPDATE_NOT_FOUND,
    COMPLAIN_UPDATE_FAILED,

    COMPLAIN_REMOVE_LOADING,
    COMPLAIN_REMOVE_SUCCESSFUL,
    COMPLAIN_REMOVE_NOT_FOUND,
    COMPLAIN_REMOVE_FAILED,

    COMPLAIN_RESET_CRUD
} from 'actions/Complain/Types';

const initState = {
    payload : {
        type : null,
        message : ''
    },
    payload_crud : {
        type : null,
        message : ''
    }
}

export default function(state = initState , action){
    switch(action.type){
        case COMPLAIN_LIST_LOADING:
            return {
                ...state,
                type: action.type,
                payload: {
                    type: action.type,
                    message: action.message
                }
            }
        case COMPLAIN_LIST_SUCCESSFUL:
            return {
                ...state,
                type: action.type,
                payload: {
                    type: action.type,
                    data: action.data
                }
            }
        case COMPLAIN_LIST_NOT_FOUND:
            return {
                ...state,
                type: action.type,
                payload: {
                    type: action.type,
                    message: action.message
                }
            }
        case COMPLAIN_LIST_FAILED:
            return {
                ...state,
                type: action.type,
                payload: {
                    type: action.type,
                    message: action.message
                }
            }
        case COMPLAIN_DETAIL_LOADING:
            return {
                ...state,
                type: action.type,
                payload: {
                    type: action.type,
                    message: action.message
                }
            }
        case COMPLAIN_DETAIL_SUCCESSFUL:
            return {
                ...state,
                type: action.type,
                payload: {
                    type: action.type,
                    data: action.data
                }
            }
        case COMPLAIN_DETAIL_NOT_FOUND:
            return {
                ...state,
                type: action.type,
                payload: {
                    type: action.type,
                    message: action.message
                }
            }
        case COMPLAIN_DETAIL_FAILED:
            return {
                ...state,
                type: action.type,
                payload: {
                    type: action.type,
                    message: action.message
                }
            }
        case COMPLAIN_UPDATE_LOADING:
            return {
                ...state,
                payload_crud: {
                    type : action.type,
                    message : action.message
                }
            }
        case COMPLAIN_UPDATE_SUCCESSFUL:
            return {
                ...state,
                payload_crud: {
                    type : action.type,
                    message : action.message
                }
            }
        case COMPLAIN_UPDATE_NOT_FOUND:
            return {
                ...state,
                payload_crud: {
                    type : action.type,
                    message : action.message
                }
            }
        case COMPLAIN_UPDATE_FAILED:
            return {
                ...state,
                payload_crud: {
                    type : action.type,
                    message : action.message
                }
            }

        case COMPLAIN_REMOVE_LOADING:
            return {
                ...state,
                payload_crud: {
                    type : action.type,
                    message : action.message
                }
            }
        case COMPLAIN_REMOVE_SUCCESSFUL:
            return {
                ...state,
                payload_crud: {
                    type : action.type,
                    message : action.message
                }
            }
        case COMPLAIN_REMOVE_NOT_FOUND:
            return {
                ...state,
                payload_crud: {
                    type : action.type,
                    message : action.message
                }
            }
        case COMPLAIN_REMOVE_FAILED:
            return {
                ...state,
                payload_crud: {
                    type : action.type,
                    message : action.message
                }
            }
        case COMPLAIN_RESET_CRUD:
            return {
                ...state,
                payload_crud : {
                    type : null,
                    message : ''
                }
            }
        default:
            return state
    }
}