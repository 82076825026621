import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { useEffect, useReducer, useRef, useState } from "react";
import '../../../assets/css/utilities.css';
import { Card, CardBody, Col, Container, Form, FormGroup, Input, Row } from "reactstrap";
import { MaterialRed1 } from "components/Utilities/ResourceColors";
import { MaterialGray2 } from "components/Utilities/ResourceColors";
import { MaterialGray1 } from "components/Utilities/ResourceColors";
import IcImage from '../../../assets/img/ic_image.svg';
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { getJSONCredential } from "actions/config";
import { createParentService , resetCrud } from "actions/ParentService/ParentService";
import { CREATE_PARENT_LOADING } from "actions/ParentService/Types";
import { CREATE_PARENT_SUCCESSFUL } from "actions/ParentService/Types";
import { CREATE_PARENT_FAILED } from "actions/ParentService/Types";
import { useHistory } from "react-router-dom";
import { erorrMsgStyle } from "components/Utilities/Utility";
import { RED_ERROR_MESSAGE } from "components/Utilities/ResourceColors";

const MySwal = withReactContent(Swal)

const LayananCreateParentService = (props) => {
    const [isLoading, setLoading] = useState(false)
    const [isSubmit, setSubmit] = useState(false)
    const [selectedFile, setSelectedFile] = useState(null)
    const [parentImage, setParentImage] = useState(null)
    const fileRef = useRef()
    const [token , setToken] = useState('')
    const [inputValues, setInputValues] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            title_parent_service: '',
            description_parent_service: '',
            tipe_paket: '',
            parent_service_status: 'Open'
        }
    )
    const history = useHistory()
    const [imgProper, setImgProper] = useState(false)
    useEffect(() => {
        setToken(localStorage.getItem('token'))
    } , [token])
    const onChangeHandler = event => {
        const { name, value } = event.target
        setInputValues({ [name]: value })
    }
    const onChangeImageHandler = event => {
        var img = new Image()
        setParentImage(URL.createObjectURL(event.target.files[0]))
        img.onload = function () {
            if (img.width == 450 && img.height == 450) {
                setSelectedFile(event.target.files[0])
                setImgProper(true)
            } else {
                setImgProper(false)
            }
        }
        img.src = URL.createObjectURL(event.target.files[0])
    }
    function validateBorderImg() {
        if (isSubmit) {
            if (parentImage == null || (parentImage != null && !imgProper)) {
                return `1px dashed ${RED_ERROR_MESSAGE}`
            }
        } else {
            if (parentImage != null && !imgProper) {
                return `1px dashed ${RED_ERROR_MESSAGE}`
            }
        }
    }
    function validateTextImg() {
        if (isSubmit) {
            if (parentImage == null) {
                return (
                    <p style={
                        erorrMsgStyle
                    }>Gambar Paket must be filled</p>
                )
            } else if (parentImage != null && !imgProper) {
                return (
                    <p style={
                        erorrMsgStyle
                    }>Gambar Paket must be 450px x 450px</p>
                )
            } else {
                return (<></>)
            }
        }
    }
    useEffect(() => {
        switch (props.parent_payload.parent_create.payload.type) {
            case CREATE_PARENT_LOADING:
                setLoading(true)
                break;
            case CREATE_PARENT_SUCCESSFUL:
                setLoading(false)
                history.goBack()
                MySwal.fire(
                    'Perhatian',
                    'Berhasil Membuat Paket',
                    'success'
                )
                props.resetCrud()
                break;
            case CREATE_PARENT_FAILED:
                setLoading(false)
                MySwal.fire(
                    'Perhatian',
                    'Gagal Membuat Paket',
                    'error'
                )
                props.resetCrud()
                break;
        }
    }, [props.parent_payload.parent_create])

    function submitCreateParentService() {
        setSubmit(true)
        const {
            title_parent_service,
            tipe_paket,
            parent_service_status,
            description_parent_service
        } = inputValues
        if (selectedFile != null && title_parent_service !== '' && tipe_paket !== '' &&
            parent_service_status !== '' && description_parent_service !== '' &&
            parentImage != null) {
            const formData = new FormData()
            const credential = getJSONCredential()['data']
            let status_selected = inputValues.parent_service_status
            if (status_selected.split(" ").length > 1) {
                const split_status_selected = status_selected.split(" ")
                status_selected = ""
                for (let i = 0; i < split_status_selected.length; i++) {
                    status_selected += split_status_selected[i].charAt(0).toLowerCase() + split_status_selected[i].slice(1) + (i == split_status_selected.length - 1 ? "" : " ")
                }
            } else {
                status_selected = status_selected.charAt(0).toLowerCase() + status_selected.slice(1)
            }
            const payload_json = {
                layanan_id: props.location.state.layanan_id,
                creator_id: credential['id'],
                title_parent_service: title_parent_service,
                description_parent_service: description_parent_service,
                tipe_paket: tipe_paket,
                parent_service_status: status_selected
            }
            formData.append('json_data', JSON.stringify(payload_json))
            formData.append('file', selectedFile)
            props.createParentService(formData , token)
        }
    }

    return (
        <>
            <div className="main-content">
                <div className="header bg-gradient-info pb-8 pt-5 pt-lg-7 d-flex align-items-center">
                    <span className="mask bg-gradient-default opacity-8" />
                </div>
                <Container className="mt--7" fluid>
                    <Card className="bg-secondary shadow">
                        {
                            isLoading ?
                                <div style={{
                                    borderTopLeftRadius: '5px',
                                    borderTopRightRadius: '5px'
                                }} className="progress-bar">
                                    <div className="progress-bar-value"></div>
                                </div>
                                : <div></div>
                        }
                        <div style={{
                            paddingTop: '20px',
                            paddingLeft: '20px',
                            paddingRight: '20px',
                            paddingBottom: '20px'
                        }}>
                            <h4>Buat Paket</h4>
                            <CardBody>
                                <Form>
                                    <div>
                                        <Row>
                                            <Col md="6">
                                                <Row>
                                                    <Col lg="12">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-title"
                                                            >
                                                                Nama Paket
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id="input-title"
                                                                type="text"
                                                                name="title_parent_service"
                                                                onChange={onChangeHandler}
                                                                defaultValue={inputValues.title_parent_service}
                                                                style={
                                                                    isSubmit && inputValues.title_parent_service === '' ?
                                                                        {
                                                                            color: 'black',
                                                                            border: '0.2px solid #d32f2f'
                                                                        }
                                                                        :
                                                                        {
                                                                            color: 'black',
                                                                            border: 'none'
                                                                        }
                                                                } />
                                                            <p style={{
                                                                color: MaterialRed1,
                                                                fontSize: '11px',
                                                                marginTop: '5px',
                                                                visibility: isSubmit && inputValues.title_parent_service === '' ? 'visible' : 'hidden'
                                                            }}>Nama Paket must be filled</p>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg="6">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-parent-service-status"
                                                            >
                                                                Status Paket
                                                            </label>
                                                            <Input
                                                                style={{ paddingRight: '10px', color: 'black' }}
                                                                type="select"
                                                                name="parent_service_status"
                                                                onChange={onChangeHandler}
                                                                defaultValue={inputValues.parent_service_status}
                                                                id="input-parent-service-status"
                                                            >
                                                                <option>Open</option>
                                                                <option>Coming Soon</option>
                                                                <option>Problem</option>
                                                                <option>Closed</option>
                                                            </Input>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="6">
                                                        <label
                                                            className="form-control-label"
                                                        >
                                                            Tipe Paket
                                                        </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            id="input-tipe-paket"
                                                            type="text"
                                                            name="tipe_paket"
                                                            onChange={onChangeHandler}
                                                            defaultValue={inputValues.tipe_paket}
                                                            style={
                                                                isSubmit && inputValues.tipe_paket === '' ?
                                                                    {
                                                                        color: 'black',
                                                                        border: '0.2px solid #d32f2f'
                                                                    }
                                                                    :
                                                                    {
                                                                        color: 'black',
                                                                        border: 'none'
                                                                    }
                                                            } />
                                                        <p style={{
                                                            color: MaterialRed1,
                                                            fontSize: '11px',
                                                            marginTop: '5px',
                                                            visibility: isSubmit && inputValues.tipe_paket === '' ? 'visible' : 'hidden'
                                                        }}>Tipe Paket must be filled</p>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg="12">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-banner-description-parent-service"
                                                            >
                                                                Deskripsi Paket
                                                            </label>
                                                            <Input
                                                                type="textarea"
                                                                id="input-banner-description-parent-service"
                                                                name="description_parent_service"
                                                                defaultValue={inputValues.description_parent_service}
                                                                onChange={onChangeHandler}
                                                                style={
                                                                    isSubmit && inputValues.description_parent_service === '' ?
                                                                        {
                                                                            height: '150px',
                                                                            padding: '0.4em',
                                                                            textAlign: 'left',
                                                                            color: 'black',
                                                                            border: '0.2px solid #d32f2f'
                                                                        }
                                                                        :
                                                                        {
                                                                            height: '150px',
                                                                            padding: '0.4em',
                                                                            textAlign: 'left',
                                                                            color: 'black',
                                                                            border: 'none'
                                                                        }
                                                                } />
                                                            <p style={{
                                                                color: MaterialRed1,
                                                                fontSize: '11px',
                                                                marginTop: '5px',
                                                                visibility: isSubmit && inputValues.description_parent_service === '' ? 'visible' : 'hidden'
                                                            }}>Deskripsi Paket must be filled</p>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col lg="6">
                                                <div>
                                                    <label
                                                        className="form-control-label">Gambar Paket</label>
                                                    {
                                                        <div style={{
                                                            alignItems: 'center',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            border: parentImage === null ? '1px dashed black' : '1px solid transparent',
                                                            borderRadius: '10px'
                                                        }}>
                                                            {
                                                                parentImage === null ?
                                                                    <div style={{
                                                                        alignItems: 'center',
                                                                        display: 'flex',
                                                                        width: '100%',
                                                                        height: '260px',
                                                                        border: validateBorderImg(),
                                                                        borderRadius: '10px',
                                                                        justifyContent: 'center'
                                                                    }}>
                                                                        <img src={IcImage} style={{ textAlign: 'center', width: '50px', height: '50px' }} />
                                                                    </div>
                                                                    :
                                                                    <div style={{
                                                                        width: '100%',
                                                                        height: '260px',
                                                                        border: validateBorderImg(),
                                                                        borderRadius: '10px',
                                                                        alignItems: 'center',
                                                                        justifyContent: 'center',
                                                                        textAlign: 'center'
                                                                    }}>
                                                                        <div style={{
                                                                            width: '25px',
                                                                            height: '25px',
                                                                            borderRadius: '50%',
                                                                            backgroundColor: MaterialRed1,
                                                                            position: 'absolute',
                                                                            right: 8,
                                                                            top: 22,
                                                                            cursor: 'pointer'
                                                                        }}
                                                                            onClick={() => {
                                                                                setSelectedFile(null)
                                                                                setParentImage(null)
                                                                            }}
                                                                        >
                                                                            <p style={{ color: 'white' }}>&times;</p>
                                                                        </div>
                                                                        <img src={parentImage} style={{
                                                                            width: '100%',
                                                                            objectFit: 'cover',
                                                                            borderRadius: '10px',
                                                                            height: '260px',
                                                                            display: 'inline-block'
                                                                        }} />
                                                                    </div>
                                                            }
                                                        </div>
                                                    }
                                                    <Row>
                                                        <Col sm="8">
                                                            <p style={{ fontSize: '13px', marginTop: '10px' }}>
                                                                Ukuran Gambar 450px X 450px
                                                            </p>
                                                        </Col>
                                                        <Col sm="4">
                                                            <div>
                                                                <input type="file" onChange={onChangeImageHandler} name="image_picker_change" ref={fileRef} hidden />
                                                                <button onClick={(e) => {
                                                                    e.preventDefault()
                                                                    if (!isLoading) {
                                                                        fileRef.current.click()
                                                                    }
                                                                }} style={{
                                                                    marginTop: '10px',
                                                                    width: '100%'
                                                                }} className="btn btn-sm btn-primary">Ambil gambar</button>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xl="12">
                                                            {validateTextImg()}
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="12">
                                                <a onClick={() => submitCreateParentService()} style={{ width: '100%' }} className="btn btn-success btn-sm">
                                                    Simpan
                                                </a>
                                            </Col>
                                        </Row>
                                    </div>
                                </Form>
                            </CardBody>
                        </div>
                    </Card>
                </Container>
            </div>
        </>
    )
}

LayananCreateParentService.propTypes = {
    parent_payload: PropTypes.object.isRequired,
    createParentService: PropTypes.func.isRequired,
    resetCrud : PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    parent_payload: state.parent_payload
})

export default connect(mapStateToProps, { createParentService , resetCrud })(LayananCreateParentService)