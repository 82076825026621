export const BANNER_LIST_LOADING = 'BANNER_LIST_LOADING';
export const BANNER_LIST_SUCCESSFUL = 'BANNER_LIST_SUCCESSFUL';
export const BANNER_LIST_NOT_FOUND = 'BANNER_LIST_NOT_FOUND';
export const BANNER_LIST_FAILED = 'BANNER_LIST_FAILED';

export const BANNER_DETAIL_LOADING = 'BANNER_DETAIL_LOADING';
export const BANNER_DETAIL_SUCCESSFUL = 'BANNER_DETAIL_SUCCESSFUL';
export const BANNER_DETAIL_NOT_FOUND = 'BANNER_DETAIL_NOT_FOUND';
export const BANNER_DETAIL_FAILED = 'BANNER_DETAIL_FAILED';

export const CREATE_BANNER_LOADING = 'CREATE_BANNER_LOADING';
export const CREATE_BANNER_SUCCESSFUL = 'CREATE_BANNER_SUCCESSFUL';
export const CREATE_BANNER_FAILED = 'CREATE_BANNER_FAILED';

export const UPDATE_BANNER_LOADING = 'UPDATE_BANNER_LOADING';
export const UPDATE_BANNER_SUCCESSFUL = 'UPDATE_BANNER_SUCCESSFUL';
export const UPDATE_BANNER_NOT_FOUND = 'UPDATE_BANNER_NOT_FOUND';
export const UPDATE_BANNER_FAILED = 'UPDATE_BANNER_FAILED';

export const REMOVE_BANNER_LOADING = 'REMOVE_BANNER_LOADING';
export const REMOVE_BANNER_SUCCESSFUL = 'REMOVE_BANNER_SUCCESSFUL';
export const REMOVE_BANNER_NOT_FOUND = 'REMOVE_BANNER_NOT_FOUND';
export const REMOVE_BANNER_FAILED = 'REMOVE_BANNER_FAILED'

export const BANNER_RESET_CRUD = 'BANNER_RESET_CRUD'

export const CLEAR_BANNER_CREATE_UPDATE_STATE = 'CLEAR_BANNER_CREATE_UPDATE_STATE';