import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { getOrderDetail, getOrderSelectedMitra, requestOrderCancel, requestSelectedMitra } from "actions/Orders/OrderService";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Modal, ModalBody, ModalHeader, Row, Table } from "reactstrap";
import IcGoogleMap from '../../../assets/img/ic_google_map.png'
import AdminNavbar from 'components/Navbars/AdminNavbar';
import { useHistory } from 'react-router-dom';
//import { onMessageListener } from "../../../firebase";
import MapContainer from "components/Maps/MapContainer";
import { BASE_URL } from "actions/config";
import { MaterialGreen1 } from "components/Utilities/ResourceColors";
import { formatRupiah } from "components/Utilities/Utility";
import { MaterialBlue1 } from "components/Utilities/ResourceColors";
import { MaterialRed1 } from "components/Utilities/ResourceColors";
import { MaterialYellow1 } from "components/Utilities/ResourceColors";
import { convertNumberToMonthString } from "components/Utilities/Utility";
import { MaterialBlue2 } from "components/Utilities/ResourceColors";
import IcNotFound from 'assets/img/ic_not_found.svg';
import NotFoundPage from "components/NotFound/NotFoundPage";
import Skeleton from "react-loading-skeleton";
import { MaterialGray1 } from "components/Utilities/ResourceColors";
import { ORDER_CANCEL_LOADING } from "actions/Orders/Types";
import { ORDER_CANCEL_SUCCESSFUL } from "actions/Orders/Types";
import { ORDER_CANCEL_FAILED } from "actions/Orders/Types";
import { ORDER_DETAIL_LOADING } from "actions/Orders/Types";
import { ORDER_DETAIL_SUCCESSFUL } from "actions/Orders/Types";
import { ORDER_DETAIL_FAILED } from "actions/Orders/Types";
import { ORDER_SELECTED_MITRA_LOADING } from "actions/Orders/Types";
import { ORDER_SELECTED_MITRA_SUCCESSFUL } from "actions/Orders/Types";
import { ORDER_SELECTED_MITRA_NOT_FOUND } from "actions/Orders/Types";
import { ORDER_SELECTED_MITRA_FAILED } from "actions/Orders/Types";
import {
    SELECTED_MITRA_LOADING,
    SELECTED_MITRA_SUCCESSFUL,
    SELECTED_MITRA_ORDER_EXPIRED,
    SELECTED_MITRA_NOT_FOUND,
    SELECTED_MITRA_FAILED
} from 'actions/Orders/Types';
import { validateEmpty } from "components/Utilities/Utility";
import Pagination from "components/Pagination/Pagination";
import 'assets/css/loading.css';
import { MaterialGray3 } from "components/Utilities/ResourceColors";
import { useRef } from "react";
import { MESSAGE_SOCKET_ADMIN } from "actions/Socket/Types";
import { REJECTED_MITRA } from "actions/Socket/Types";
import { ACCEPTED_MITRA } from "actions/Socket/Types";
import { ORDER_TIMEOUT } from "actions/Socket/Types";
import { ORDER_DETAIL_NOT_FOUND } from "actions/Orders/Types";
import { NOTIFICATION_ORDER_CANCELED } from "actions/Socket/Types";
import ErrorPage from "components/ErrorComponent/ErrorPage";
import IcError from 'assets/img/ic_error.svg';
import { MaterialBlue3 } from "components/Utilities/ResourceColors";
import { NOTIFICATION_ORDER_RUNNING } from "actions/Socket/Types";
import { NOTIFICATION_ORDER_FINISH } from "actions/Socket/Types";

const MySwal = withReactContent(Swal)
const OrderDetailPage = (props) => {
    const mainContent = React.useRef(null)
    const [modal, setModal] = useState(false)
    const [modalMitra, setModalMitra] = useState(false)
    const [isUpdating, setUpdating] = useState(false)
    const history = useHistory()
    const toggle = () => setModal(!modal)
    const [autoBid, setAutoBid] = useState(false)
    const [updateCancel, setUpdateCancel] = useState(false)
    const [reasonCancel, setReasonCancel] = useState('')
    const [orderStatus, setOrderStatus] = useState('')
    const [isFirst, setFirst] = useState(false)
    const [mitraSelected, setMitraSelected] = useState([])
    const [searchMitraText, setSearchMitraText] = useState('')
    const [currentPageSelectedMitra, setCurrentPageSelectedMitra] = useState(1)
    const [currentPageSelectedMitraSearch, setCurrentPageSelectedMitraSearch] = useState(1)
    const [token, setToken] = useState('')
    const [canSend, setCanSend] = useState(false)
    const [sendText, setSendText] = useState('Harap Pilih Mitra Untuk Dikirim Order')
    const [sendLoading, setSendLoading] = useState(false)
    const [orderTime, setOrderTime] = useState(0)
    const [countObject, setCountObject] = useState({})

    useEffect(() => {
        setToken(localStorage.getItem('token'))
        if (token !== '') {
            props.getOrderDetail(props.location.state.order_id, token)
        }
    }, [token])

    const handleOpenCloseModalMitra = () => {
        setModalMitra(!modalMitra)
    }
    useEffect(() => {
        buildOrderDetailPage()
        if (props.orders.payload.data !== undefined && props.orders.payload.data !== null) {
            setOrderStatus(props.orders.payload.data.order_status)
            if (props.orders.payload.data.order_status === 'WAITING_FOR_SELECTED_MITRA') {
                if (props.socket != null && props.socket.socket != null) {
                    console.log('COME HERE')
                    props.socket.socket.on(MESSAGE_SOCKET_ADMIN, (payload) => {
                        console.log(`Message Socket Payload : ${JSON.stringify(payload, null, 2)}`)
                        switch (payload.notification_type) {
                            case REJECTED_MITRA:
                                if (payload.order_id == props.orders.payload.data.id) {
                                    MySwal.fire(
                                        'Perhatian',
                                        `Mitra ${payload.mitra_name} Menolak Orderan Ini`,
                                        'warning'
                                    )
                                }
                                break;
                            case ACCEPTED_MITRA:
                                if (payload.order_id == props.orders.payload.data.id) {
                                    MySwal.fire(
                                        'Status',
                                        `Mitra ${payload.mitra_name} Menerima Orderan Ini`,
                                        'success'
                                    )
                                    if (token !== '') {
                                        props.getOrderDetail(props.location.state.order_id, token)
                                    }
                                }
                                break;
                            case ORDER_TIMEOUT:
                                if (payload.order_id == props.orders.payload.data.id) {
                                    console.log('Order Otomatis Dibatalkan Sistem Karena Lewat Masa Berlaku')
                                    if (token !== '') {
                                        props.getOrderDetail(props.location.state.order_id, token)
                                    }
                                }
                                break;
                            case NOTIFICATION_ORDER_CANCELED:
                                if (payload.order_id == props.orders.payload.data.id) {
                                    if (token !== '') {
                                        props.getOrderDetail(props.location.state.order_id, token)
                                    }
                                }
                                break;
                            case NOTIFICATION_ORDER_RUNNING:
                                if (payload.order_id === props.orders.payload.data.id) {
                                    if (token !== '') {
                                        props.getOrderDetail(props.location.state.order_id, token)
                                    }
                                }
                                break;
                            case NOTIFICATION_ORDER_FINISH:
                                if (payload.order_id === props.orders.payload.data.id) {
                                    if (token !== '') {
                                        props.getOrderDetail(props.location.state.order_id, token)
                                    }
                                }
                                break;
                        }
                    })
                }
            }
        }
    }, [props.orders])
    useEffect(() => {
        switch (props.orders.payload_update.type) {
            case ORDER_CANCEL_LOADING:
                setUpdateCancel(true)
                break;
            case ORDER_CANCEL_SUCCESSFUL:
                setUpdateCancel(false)
                MySwal.fire(
                    'Perhatian',
                    'Berhasil membatalkan order',
                    'success'
                )
                setModal(false)
                console.log(`Order Status : ${props.orders.payload.data.order_status}`)
                props.orders.payload_update = {}
                if (token !== '') {
                    props.getOrderDetail(props.location.state.order_id, token)
                }
                break;
            case ORDER_CANCEL_FAILED:
                setUpdateCancel(false)
                MySwal.fire(
                    'Perhatian',
                    'Gagal membatalkan order',
                    'error'
                )
                break;
        }
    }, [props.orders.payload_update])
    useEffect(() => {
        switch (props.orders.payload_request_mitra.type) {
            case SELECTED_MITRA_LOADING:
                setSendLoading(true)
                break;
            case SELECTED_MITRA_SUCCESSFUL:
                setSendLoading(false)
                setModalMitra(false)
                setCurrentPageSelectedMitra(1)
                setCurrentPageSelectedMitraSearch(1)
                setMitraSelected([])
                if (props.location.state.order_id !== undefined) {
                    if (token !== '') {
                        props.getOrderDetail(props.location.state.order_id, token)
                    }
                }
                break;
            case SELECTED_MITRA_ORDER_EXPIRED:
                setSendLoading(false)
                Swal.fire(
                    'Perhatian',
                    'Order telah expired',
                    'error'
                )
                history.goBack()
                break;
            case SELECTED_MITRA_NOT_FOUND:
                setSendLoading(false)
                Swal.fire(
                    'Perhatian',
                    'Data Mitra Tak Ditemukan',
                    'warning'
                )
                break;
            case SELECTED_MITRA_FAILED:
                setSendLoading(false)
                break;
        }
    }, [props.orders.payload_request_mitra])
    useEffect(() => {
        console.log(`Mitra Selected : ${JSON.stringify(mitraSelected, null, 2)}`)
        setCanSend(mitraSelected.length > 0)
        setSendText(canSend ? `Kirim Orderan Ke ${mitraSelected.length} Mitra` : 'Harap Pilih Mitra Untuk Dikirim Order')
    }, [mitraSelected])
    useEffect(() => {
        setSendText(canSend ? `Kirim Orderan Ke ${mitraSelected.length} Mitra` : 'Harap Pilih Mitra Untuk Dikirim Order')
    }, [canSend])
    useEffect(() => {
        props.getOrderSelectedMitra(props.location.state.order_id, 5, currentPageSelectedMitra, "", token)
    }, [currentPageSelectedMitra])
    useEffect(() => {
        if (!isFirst) {
            if (validateEmpty(searchMitraText)) {
                props.getOrderSelectedMitra(props.location.state.order_id, 5, currentPageSelectedMitraSearch, searchMitraText, token)
            }
        } else {
            setFirst(false)
        }
    }, [currentPageSelectedMitraSearch])
    const onChangeSearch = (e) => {
        setSearchMitraText(e.target.value)
    }
    useEffect(() => {
        if (!isFirst) {
            const delayDebounceFn = setTimeout(() => {
                if (validateEmpty(searchMitraText)) {
                    setCurrentPageSelectedMitraSearch(1)
                    props.getOrderSelectedMitra(props.location.state.order_id, 5, currentPageSelectedMitra, searchMitraText, token)
                } else {
                    setCurrentPageSelectedMitraSearch(1)
                    props.getOrderSelectedMitra(props.location.state.order_id, 5, currentPageSelectedMitra, "", token)
                }
            }, 800)
            return () => clearTimeout(delayDebounceFn)
        }
    }, [searchMitraText])
    let intervalRef = useRef()
    const decreaseOrderTime = () => setOrderTime((prev) => prev - 1)
    useEffect(() => {
        if (orderTime > 0) {
            intervalRef.current = setInterval(decreaseOrderTime, 1000)
            return () => clearInterval(intervalRef.current)
        }
    }, [orderTime])
    function buildOrderSelectedMitra() {
        switch (props.orders.payload_selected_mitra.type) {
            case ORDER_SELECTED_MITRA_LOADING:
                return (
                    <>
                        <Row>
                            <Col lg="12">
                                <Skeleton style={{ height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12">
                                <Skeleton style={{ marginTop: '25px', height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12">
                                <Skeleton style={{ marginTop: '25px', height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12">
                                <Skeleton style={{ marginTop: '25px', height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12">
                                <Skeleton style={{ marginTop: '25px', height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12">
                                <Skeleton style={{ marginTop: '25px', height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                            </Col>
                        </Row>
                    </>
                )
            case ORDER_SELECTED_MITRA_SUCCESSFUL:
                return (
                    <>
                        <Row>
                            <Col xl="12">
                                <Table className="align-items-center table-bordered" responsive>
                                    <thead className="thead-dark">
                                        <tr>
                                            <th style={{ color: 'white' }} scope="col">Nama Mitra</th>
                                            <th style={{ color: 'white' }} scope="col">Auto Bid ?</th>
                                            <th style={{ color: 'white' }} scope="col">Radius Jarak</th>
                                            <th style={{ color: 'white' }} scope="col">Rating</th>
                                            <th style={{ color: 'white' }} scope="col">Aksi</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            props.orders.payload_selected_mitra.data.rows_data.docs.map((data) => {
                                                return (
                                                    <tr key={data.id}>
                                                        <td>{data.complete_name}</td>
                                                        <td>{data.is_auto_bid === 'yes' ? 'Ya' : 'Tidak'}</td>
                                                        <td>{data.distance >= 1.00 ? `${data.distance.toFixed(2)} KM` : `${(data.distance * 1000).toFixed(1)} Meter`}</td>
                                                        <td>{data.user_rating}</td>
                                                        <td style={{ cursor: sendLoading || data.offer_status !== null ? 'default' : 'pointer', background: sendLoading ? MaterialGray1 : 'white' }} onClick={() => {
                                                            if (!sendLoading && (data.offer_status === 'null' ||
                                                                data.offer_status === null || data.offer_status === '')) {
                                                                if (mitraSelected.indexOf(data.id) !== -1) {
                                                                    setMitraSelected(
                                                                        mitraSelected.filter(mitraData => mitraData !== data.id)
                                                                    )
                                                                } else {
                                                                    if (data.is_auto_bid === 'yes') {
                                                                        if (autoBid) {
                                                                            MySwal.fire({
                                                                                title: 'Perhatian',
                                                                                text: `Sebelumnya sudah ada mitra auto bid yang dipilih, ingin ganti ke mitra auto bid yang ini ?`,
                                                                                showCancelButton: true,
                                                                                confirmButtonText: 'Ya',
                                                                                confirmButtonColor: MaterialBlue3,
                                                                                cancelButtonColor: MaterialGray1,
                                                                                cancelButtonText: 'Batal'
                                                                            }).then(res => {
                                                                                if (res.isConfirmed) {
                                                                                    setMitraSelected([data.id])
                                                                                }
                                                                            })
                                                                        } else {
                                                                            MySwal.fire({
                                                                                title: 'Perhatian',
                                                                                text: `Kalau milih mitra auto bid, yang lain bakal ke hapus dan hanya satu mitra auto bid saja yang bisa dipilih. Ganti dengan auto bid ?`,
                                                                                showCancelButton: true,
                                                                                confirmButtonText: 'Ya',
                                                                                confirmButtonColor: MaterialBlue3,
                                                                                cancelButtonColor: MaterialGray1,
                                                                                cancelButtonText: 'Batal'
                                                                            }).then(result => {
                                                                                if (result.isConfirmed) {
                                                                                    setAutoBid(true)
                                                                                    setMitraSelected([data.id])
                                                                                }
                                                                            })
                                                                        }
                                                                    } else {
                                                                        if (autoBid) {
                                                                            MySwal.fire({
                                                                                title: 'Perhatian',
                                                                                text: `Sebelumnya sudah ada mitra auto bid yang dipilih, ingin menghapus mitra auto bid sebelumnya buat diganti dengan mitra yang lain ?`,
                                                                                showCancelButton: true,
                                                                                confirmButtonText: 'Ya',
                                                                                confirmButtonColor: MaterialBlue3,
                                                                                cancelButtonColor: MaterialGray1,
                                                                                cancelButtonText: 'Batal'
                                                                            }).then(res => {
                                                                                if (res.isConfirmed) {
                                                                                    setAutoBid(false)
                                                                                    setMitraSelected([data.id])
                                                                                }
                                                                            })
                                                                        } else {
                                                                            setMitraSelected([
                                                                                ...mitraSelected,
                                                                                data.id
                                                                            ])
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }}>
                                                            <div style={{
                                                                height: '100%',
                                                                display: 'flex',
                                                                alignItems: 'center'
                                                            }}>
                                                                {
                                                                    data.offer_status === null ?
                                                                        <>
                                                                            <div style={{
                                                                                width: '20px',
                                                                                display: 'flex',
                                                                                alignItems: 'center',
                                                                                height: '20px',
                                                                                marginRight: '8px',
                                                                                border: `1px solid ${sendLoading ? MaterialGray3 : MaterialBlue1}`,
                                                                                borderRadius: '2px'
                                                                            }}>
                                                                                <div style={{
                                                                                    width: '15px',
                                                                                    height: '15px',
                                                                                    borderRadius: '2px',
                                                                                    width: '100%',
                                                                                    height: '80%',
                                                                                    marginLeft: '2px',
                                                                                    marginRight: '2px',
                                                                                    marginTop: '3.5px',
                                                                                    marginBottom: '3.5px',
                                                                                    background: sendLoading ?
                                                                                        mitraSelected.indexOf(data.id) !== -1 ?
                                                                                            MaterialGray3
                                                                                            :
                                                                                            'white'
                                                                                        :
                                                                                        mitraSelected.indexOf(data.id) !== -1
                                                                                            ?
                                                                                            MaterialBlue1
                                                                                            :
                                                                                            'white',
                                                                                }}></div>
                                                                            </div>
                                                                            Pilih Mitra
                                                                        </>
                                                                        :
                                                                        <>
                                                                            Mitra Sudah Dipilih
                                                                        </>
                                                                }
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl="8">
                                {
                                    !sendLoading ?
                                        <button onClick={() => {
                                            if (canSend && props.location.state.order_id !== undefined && mitraSelected.length > 0) {
                                                let payloadRequest = mitraSelected.map(data => {
                                                    return {
                                                        mitra_id: data
                                                    }
                                                })
                                                const payload = {
                                                    selected_mitra: payloadRequest
                                                }
                                                props.requestSelectedMitra(props.location.state.order_id, payload, token)
                                                console.log(`Payload Request : ${JSON.stringify(payloadRequest, null, 2)}`)
                                            }
                                        }} style={{ marginTop: '10px', width: '100%', height: '90%', background: canSend ? MaterialBlue1 : MaterialGray1, border: `1px solid ${canSend ? MaterialBlue1 : MaterialGray1}` }} className="btn btn-primary btn-md" disabled={canSend ? "" : "disabled"}>{sendText}</button>
                                        : <div className="load"></div>
                                }
                            </Col>
                            <Col xl="4">
                                {
                                    !validateEmpty(searchMitraText) ?
                                        <Row style={{ marginRight: '-10px' }}>
                                            {
                                                props.orders.payload_selected_mitra.data.rows_data !== undefined &&
                                                    props.orders.payload_selected_mitra.data.count_total > 5 ?
                                                    <div className="card bg-default-shadow" style={{ width: 'max-content', paddingLeft: '20px', marginRight: '0', marginTop: '10px', paddingTop: '15px', marginLeft: 'auto' }}>
                                                        <Pagination
                                                            className="pagination-bar"
                                                            currentPage={currentPageSelectedMitra}
                                                            totalCount={props.orders.payload_selected_mitra.data.count_total}
                                                            pageSize={5}
                                                            onPageChange={page => {
                                                                setCurrentPageSelectedMitra(page)
                                                            }} />
                                                    </div>
                                                    : <div></div>
                                            }
                                        </Row>
                                        :
                                        <Row style={{ marginRight: '-10px' }}>
                                            {
                                                props.orders.payload_selected_mitra.data.rows_data !== undefined &&
                                                    props.orders.payload_selected_mitra.data.count_total > 5 ?
                                                    <div className="card bg-default-shadow" style={{ width: 'max-content', paddingLeft: '20px', marginRight: '0', marginTop: '10px', paddingTop: '15px', marginLeft: 'auto' }}>
                                                        <Pagination
                                                            className="pagination-bar"
                                                            currentPage={currentPageSelectedMitraSearch}
                                                            totalCount={props.orders.payload_selected_mitra.data.count_total}
                                                            pageSize={5}
                                                            onPageChange={page => {
                                                                setCurrentPageSelectedMitraSearch(page)
                                                            }} />
                                                    </div>
                                                    : <div></div>
                                            }
                                        </Row>
                                }
                            </Col>
                        </Row>
                    </>
                )
            case ORDER_SELECTED_MITRA_NOT_FOUND:
                return (
                    <>
                        <p>Not Found</p>
                    </>
                )
            case ORDER_SELECTED_MITRA_FAILED:
                return (
                    <>
                        <p>Failed</p>
                    </>
                )
        }
    }
    // onMessageListener().then(payload => {
    //     console.log(`Payload Order : ${JSON.stringify(payload, null, 2)}`)
    //     if (payload.data.order_status === 'ON_PROGRESS') {
    //         setOrderStatus(props.orders.payload.data.order_status)
    //     } else if (payload.data.order_status === 'FINISH') {
    //         setOrderStatus(props.orders.payload.data.order_status)
    //     }
    // }).catch(err => console.log(`Failed : ${err}`))
    function buildOrderStatus(order_status) {
        let colorBlob = ''
        let messageBlob = ''
        switch (order_status) {
            case 'OTW':
                colorBlob = MaterialBlue1
                messageBlob = 'Mitra dalam perjalanan'
                break;
            case 'ON_PROGRESS':
                colorBlob = MaterialBlue1
                messageBlob = 'Mitra sedang mengerjakan order'
                break;
            case 'FINISH':
                colorBlob = MaterialGreen1
                messageBlob = 'Orderan selesai'
                break;
            case 'CANCELED':
                colorBlob = MaterialRed1
                messageBlob = 'Orderan dibatalkan'
                break;
            case 'CANCELED_BY_SYSTEM':
                colorBlob = MaterialRed1
                messageBlob = 'Orderan dibatalkan Suberes'
                break;
            case 'CANCELED_VOID':
                colorBlob = MaterialRed1
                messageBlob = 'Orderan dibatalkan dan dana dikembalikan ke customer'
                break;
            case 'CANCELED_LATE_PAYMENT':
                colorBlob = MaterialRed1
                messageBlob = 'Orderan dibatalkan karena customer telat bayar'
                break;
            case 'CANCELED_VOID_BY_SYSTEM':
                colorBlob = MaterialRed1
                messageBlob = 'Orderan dibatalkan Suberes dan dana dikembalikan ke customer'
                break;
            case 'WAIT_SCHEDULE':
                colorBlob = MaterialYellow1
                messageBlob = 'Orderan menunggu jadwal'
                break;
        }
        return (
            <>
                {order_status !== 'CANCELED' ? <div style={{ margin: '0 auto', backgroundColor: colorBlob }} className="blob"></div>
                    : <div style={{ margin: '0 auto', width: '20px', height: '20px', borderRadius: '50%', backgroundColor: colorBlob }}></div>
                }
                {order_status !== 'CANCELED' ? <h5 style={{ marginTop: '20px', color: colorBlob }}>{messageBlob}</h5>
                    : <h5 style={{ marginTop: '20px', color: colorBlob }}>{messageBlob}</h5>
                }
            </>
        )
    }
    function buildOrderType(orderType) {
        let order_type = ''
        if (orderType === 'now') {
            order_type = 'Order Sekarang'
        } else if (orderType === 'coming soon') {
            order_type = 'Order Terjadwal'
        } else if (orderType === 'repeat') {
            order_type = 'Order Berulang'
        }
        return order_type
    }
    function buildOrderTypeColor(orderType) {
        let typeColor = ''
        if (orderType === 'now') {
            typeColor = MaterialGreen1
        } else if (orderType === 'coming soon') {
            typeColor = MaterialYellow1
        } else if (orderType === 'repeat') {
            typeColor = MaterialBlue2
        }
        return typeColor
    }
    function buildOrderTimestamp(order_time) {
        let order_timestamp = order_time.split(" ")
        let monthString = ''
        if (order_timestamp[0].split("-")[1].startsWith('0')) {
            monthString = convertNumberToMonthString(parseInt(order_timestamp[0].split("-")[1].split('0')[1]))
        } else {
            monthString = convertNumberToMonthString(parseInt(order_timestamp[0].split("-")[1]))
        }
        const yearString = order_timestamp[0].split("-")[0]
        const dateString = order_timestamp[0].split("-")[2]

        return `${dateString} ${monthString} ${yearString}`
    }
    function buildMapOrReasonView(order) {
        if (order.mitra !== null && order.mitra.is_busy === 'yes') {
            return (
                <div style={{ height: '170px', width: '60%' }}>
                    <MapContainer />
                </div>
            )
        } else {
            if (order.order_status !== 'CANCELED' && order.order_status !== 'CANCELED_VOID' && order.order_status !== 'CANCELED_REFUND' &&
                order.order_status !== 'CANCELED_LATE_PAYMENT') {
                return (
                    <div style={{ backgroundColor: '#bdbdbd', borderRadius: '10px', height: '170px' }}>
                        <img
                            style={{
                                width: '40px',
                                height: '40px',
                                padding: '5px',
                                borderRadius: '10px',
                                backgroundColor: 'white',
                                position: 'absolute',
                                bottom: 10,
                                right: 25
                            }}
                            src={IcGoogleMap} />
                        <p style={{ position: 'absolute', margin: '0 auto', top: '50%', left: '50%', transform: 'translate(-50% , -50%)', textAlign: 'center', color: 'white' }}>
                            Preview lokasi akan muncul disini jika mitra menjalankan order
                        </p>
                    </div>
                )
            } else {
                return (
                    <>
                        <h5 style={{ textAlign: 'center' }}>Alasan Pembatalan</h5>
                        <div style={{ textAlign: 'center', marginTop: '20px' }}>
                            {
                                order.canceled_reason
                            }
                        </div>
                    </>
                )
            }
        }
    }
    function secondsToTime(timeLeft) {
        const hours = Math.floor(timeLeft / 3600);
        const minutes = Math.floor((timeLeft % 3600) / 60);
        const seconds = timeLeft % 60;
        return (
            <p>
                Time Left: {String(hours).padStart(2, '0')}:{String(minutes).padStart(2, '0')}:{String(seconds).padStart(2, '0')}
            </p>
        )
    }
    function submitCancelOrder() {
        if (reasonCancel !== '' && reasonCancel !== null) {
            const payload = {
                canceled_reason: reasonCancel
            }
            if (props.location.state !== undefined) {
                if (token !== '') {
                    props.requestOrderCancel(props.location.state.order_id, payload, token)
                }
            }
        } else {
            MySwal.fire(
                'Perhatian',
                'Harap isi alasan pembatalan',
                'error'
            )
        }
    }
    function buildOrderDo(data) {
        let orderTimeDo = ''
        if (data.order_type === 'now' || data.order_type === 'coming soon') {
            orderTimeDo = <>
                {data.order_timestamp}
                <br />
                {data.order_time.split(" ")[1]}
            </>
        } else if (data.order_type === 'repeat') {
            orderTimeDo = 'Harap liat jadwal order'
        }
        return orderTimeDo
    }
    function buildOrderDetailPage() {
        switch (props.orders.payload.type) {
            case ORDER_DETAIL_LOADING:
                return (
                    <>
                        <Container className="mt--9" fluid>
                            <Row>
                                <Col className="order-xl-1" xl="12">
                                    <Card style={{ paddingBottom: '30px' }} className="bg-secondary-shadow">
                                        <Row>
                                            <Col className="order-xl-1" xl="12">
                                                <div style={{ padding: '30px', marginTop: '10px' }}>
                                                    <Row>
                                                        <Col style={{ textAlign: 'center' }} md="6">
                                                            <div style={{ width: '100%', textAlign: 'center' }}>
                                                                <Skeleton style={{ width: '25%', backgroundColor: MaterialGray1, height: '20px ' }}></Skeleton>
                                                            </div>
                                                            <div style={{ width: '100%', marginTop: '10px', textAlign: 'center' }}>
                                                                <Skeleton style={{
                                                                    width: '100px',
                                                                    height: '100px',
                                                                    borderRadius: '50%',
                                                                    backgroundColor: MaterialGray1
                                                                }}></Skeleton>
                                                            </div>
                                                            <div style={{ width: '100%', marginTop: '10px', textAlign: 'center' }}>
                                                                <Skeleton style={{ width: '25%', backgroundColor: MaterialGray1, height: '20px' }}></Skeleton>
                                                            </div>
                                                            <div style={{ width: '100%', marginTop: '30px', textAlign: 'center' }}>
                                                                <Skeleton style={{ width: '25%', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                            <div style={{ width: '100%', marginTop: '20px', textAlign: 'center' }}>
                                                                <Skeleton style={{ width: '25%', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                        </Col>
                                                        <Col style={{ textAlign: 'center' }} md="6">
                                                            <div style={{ width: '100%', textAlign: 'center' }}>
                                                                <Skeleton style={{ width: '25%', backgroundColor: MaterialGray1, height: '20px ' }}></Skeleton>
                                                            </div>
                                                            <div style={{ width: '100%', marginTop: '10px', textAlign: 'center' }}>
                                                                <Skeleton style={{
                                                                    width: '100px',
                                                                    height: '100px',
                                                                    borderRadius: '50%',
                                                                    backgroundColor: MaterialGray1
                                                                }}></Skeleton>
                                                            </div>
                                                            <div style={{ width: '100%', marginTop: '10px', textAlign: 'center' }}>
                                                                <Skeleton style={{ width: '25%', backgroundColor: MaterialGray1, height: '20px' }}></Skeleton>
                                                            </div>
                                                            <div style={{ width: '100%', marginTop: '30px', textAlign: 'center' }}>
                                                                <Skeleton style={{ width: '25%', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                            <div style={{ width: '100%', marginTop: '20px', textAlign: 'center' }}>
                                                                <Skeleton style={{ width: '25%', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Skeleton style={{ width: '100%', height: '1px', backgroundColor: MaterialGray1 }}></Skeleton>
                                        <Row>
                                            <Col style={{ textAlign: 'center' }} md="4">
                                                <div style={{ width: '100%', textAlign: 'center' }}>
                                                    <Skeleton style={{ width: '35%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                </div>
                                                <div style={{ width: '100%', textAlign: 'center' }}>
                                                    <Skeleton style={{ width: '35%', height: '100px', marginTop: '25px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                </div>
                                            </Col>
                                            <Col style={{ textAlign: 'center' }} md="8">
                                                <div style={{ width: '100%', textAlign: 'center' }}>
                                                    <Skeleton style={{ width: '35%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                </div>
                                                <div style={{ width: '90%', marginTop: '20px', textAlign: 'center' }}>
                                                    <Skeleton style={{ width: '100%', height: '100px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card>
                                    <Card style={{ marginTop: '10px' }} className="bg-secondary-shadow">
                                        <CardBody>
                                            <Form>
                                                <div className="align-items-center row">
                                                    <div className="col-8">
                                                        <Skeleton style={{ width: '35%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                    </div>
                                                </div>
                                                <div style={{ marginTop: '20px' }}>
                                                    <Row>
                                                        <Col lg="4">
                                                            <div style={{ width: '55%' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                            <div style={{ width: '65%', marginTop: '15px' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                        </Col>

                                                        <Col lg="4">
                                                            <div style={{ width: '55%' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                            <div style={{ width: '65%', marginTop: '15px' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                        </Col>

                                                        <Col lg="4">
                                                            <div style={{ width: '55%' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                            <div style={{ width: '65%', marginTop: '15px' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row style={{ marginTop: "20px" }}>
                                                        <Col lg="4">
                                                            <div style={{ width: '55%' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                            <div style={{ width: '65%', marginTop: '15px' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                        </Col>

                                                        <Col lg="4">
                                                            <div style={{ width: '55%' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                            <div style={{ width: '65%', marginTop: '15px' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                        </Col>

                                                        <Col lg="4">
                                                            <div style={{ width: '55%' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                            <div style={{ width: '65%', marginTop: '15px' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row style={{ marginTop: "20px" }}>
                                                        <Col lg="4">
                                                            <div style={{ width: '55%' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                            <div style={{ width: '65%', marginTop: '15px' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                        </Col>

                                                        <Col lg="4">
                                                            <div style={{ width: '55%' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                            <div style={{ width: '65%', marginTop: '15px' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Form>
                                        </CardBody>
                                    </Card>
                                    <Card style={{ marginTop: '10px' }} className="bg-secondary-shadow">
                                        <CardBody>
                                            <Form>
                                                <div className="align-items-center row">
                                                    <div className="col-8">
                                                        <Skeleton style={{ width: '35%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                    </div>
                                                </div>
                                                <div style={{ marginTop: '20px' }}>
                                                    <Row>
                                                        <Col lg="4">
                                                            <div style={{ width: '55%' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                            <div style={{ width: '65%', marginTop: '15px' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                        </Col>

                                                        <Col lg="4">
                                                            <div style={{ width: '55%' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                            <div style={{ width: '65%', marginTop: '15px' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                        </Col>

                                                        <Col lg="4">
                                                            <div style={{ width: '55%' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                            <div style={{ width: '65%', marginTop: '15px' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row style={{ marginTop: "20px" }}>
                                                        <Col lg="4">
                                                            <div style={{ width: '55%' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                            <div style={{ width: '65%', marginTop: '15px' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                        </Col>

                                                        <Col lg="4">
                                                            <div style={{ width: '55%' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                            <div style={{ width: '65%', marginTop: '15px' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                        </Col>

                                                        <Col lg="4">
                                                            <div style={{ width: '55%' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                            <div style={{ width: '65%', marginTop: '15px' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row style={{ marginTop: "20px" }}>
                                                        <Col lg="4">
                                                            <div style={{ width: '55%' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                            <div style={{ width: '65%', marginTop: '15px' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                        </Col>

                                                        <Col lg="4">
                                                            <div style={{ width: '55%' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                            <div style={{ width: '65%', marginTop: '15px' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Form>
                                        </CardBody>
                                    </Card>
                                    <Card style={{ marginTop: '10px' }} className="bg-secondary-shadow">
                                        <CardBody>
                                            <Form>
                                                <div className="align-items-center row">
                                                    <div className="col-8">
                                                        <Skeleton style={{ width: '35%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                    </div>
                                                </div>
                                                <div style={{ marginTop: '20px' }}>
                                                    <Row>
                                                        <Col lg="4">
                                                            <div style={{ width: '55%' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                            <div style={{ width: '65%', marginTop: '15px' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                        </Col>

                                                        <Col lg="4">
                                                            <div style={{ width: '55%' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                            <div style={{ width: '65%', marginTop: '15px' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                        </Col>

                                                        <Col lg="4">
                                                            <div style={{ width: '55%' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                            <div style={{ width: '65%', marginTop: '15px' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row style={{ marginTop: "20px" }}>


                                                        <Col lg="4">
                                                            <div style={{ width: '55%' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                            <div style={{ width: '65%', marginTop: '15px' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row style={{ marginTop: "20px" }}>
                                                        <Col lg="4">
                                                            <div style={{ width: '55%' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                            <div style={{ width: '65%', marginTop: '15px' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                        </Col>

                                                        <Col lg="4">
                                                            <div style={{ width: '55%' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                            <div style={{ width: '65%', marginTop: '15px' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                        </Col>

                                                        <Col lg="4">
                                                            <div style={{ width: '55%' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                            <div style={{ width: '65%', marginTop: '15px' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row style={{ marginTop: "20px" }}>
                                                        <Col lg="4">
                                                            <div style={{ width: '55%' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                            <div style={{ width: '65%', marginTop: '15px' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                        </Col>

                                                        <Col lg="4">
                                                            <div style={{ width: '55%' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                            <div style={{ width: '65%', marginTop: '15px' }}>
                                                                <Skeleton style={{ width: '100%', height: '20px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Form>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </>
                )
            case ORDER_DETAIL_SUCCESSFUL:
                if (props.orders.payload.data.count_down_order_active > 0) {
                    if (orderTime == 0)
                        setOrderTime(props.orders.payload.data.count_down_order_active)
                }
                return (
                    <Container className="mt--9" fluid>
                        <Row>
                            <Col className="order-xl-1" xl="12">
                                <Card className="bg-secondary-shadow">
                                    <Row>
                                        <Col className="order-xl-1" xl="12">
                                            <div style={{ padding: '30px', marginTop: '10px' }}>
                                                <Row>
                                                    {
                                                        props.orders.payload.data.mitra !== null ?
                                                            <Col style={{ textAlign: 'center' }} md="6">
                                                                <h4>Mitra</h4>
                                                                <img src={`${BASE_URL}/api/images${props.orders.payload.data.mitra.user_profile_image}`} style={{
                                                                    width: '100px',
                                                                    objectFit: 'cover',
                                                                    borderRadius: '50%',
                                                                    height: '100px',
                                                                    display: 'inline-block'
                                                                }} />
                                                                <p style={{ marginTop: '10px' }}>{props.orders.payload.data.mitra.complete_name}</p>
                                                            </Col> : <div></div>
                                                    }
                                                    <Col style={{ textAlign: 'center' }} md="6">
                                                        <h4>Customer</h4>
                                                        <img src={`${BASE_URL}/api/images${props.orders.payload.data.customer.user_profile_image}`} style={{
                                                            width: '100px',
                                                            objectFit: 'cover',
                                                            borderRadius: '50%',
                                                            height: '100px',
                                                            display: 'inline-block'
                                                        }} />
                                                        <p style={{ marginTop: '10px' }}>{props.orders.payload.data.customer.complete_name}</p>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col style={{ height: '150px', position: 'relative' }} md="6">
                                                        <h4 style={{ textAlign: 'center' }}>Total Transaksi</h4>
                                                        <div style={{
                                                            alignItems: 'center',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            width: '100%',
                                                            height: '80px'
                                                        }}>
                                                            <div style={{
                                                                display: 'flex',
                                                                position: 'absolute'
                                                            }}>
                                                                <div
                                                                    style={{
                                                                        backgroundColor: 'transparent',
                                                                        borderRadius: '10px',
                                                                        border: '1px solid white',
                                                                        paddingLeft: '20px',
                                                                        paddingRight: '20px',
                                                                        cursor: 'pointer'
                                                                    }}
                                                                >
                                                                    <p style={{
                                                                        fontSize: '22px',
                                                                        fontWeight: 'bold',
                                                                        position: 'relative',
                                                                        top: '50%',
                                                                        left: '50%',
                                                                        color: MaterialGreen1,
                                                                        transform: 'translate(-50% , -50%)'
                                                                    }}>
                                                                        {
                                                                            formatRupiah(
                                                                                props.orders.payload.data.gross_amount !== null &&
                                                                                    props.orders.payload.data.gross_amount !== '' ?
                                                                                    props.orders.payload.data.gross_amount.toString() :
                                                                                    '0',
                                                                                'Rp. '
                                                                            )
                                                                        }
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col md="6">
                                                        <FormGroup>
                                                            <h4 style={{ textAlign: 'center' }}>ID Transaksi</h4>
                                                            <div style={{
                                                                alignItems: 'center',
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                width: '100%',
                                                                height: '80px'
                                                            }}>
                                                                <div style={{
                                                                    display: 'flex',
                                                                    position: 'absolute'
                                                                }}>
                                                                    <div
                                                                        style={{
                                                                            backgroundColor: 'transparent',
                                                                            borderRadius: '10px',
                                                                            border: '1px solid white',
                                                                            paddingLeft: '20px',
                                                                            paddingRight: '20px',
                                                                            cursor: 'pointer'
                                                                        }}
                                                                    >
                                                                        <p style={{
                                                                            fontSize: '22px',
                                                                            fontWeight: 'bold',
                                                                            position: 'relative',
                                                                            top: '50%',
                                                                            left: '50%',
                                                                            color: MaterialGreen1,
                                                                            transform: 'translate(-50% , -50%)'
                                                                        }}>
                                                                            {
                                                                                props.orders.payload.data.id_transaction
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                {
                                                    props.orders.payload.data.order_status !== 'WAITING_FOR_SELECTED_MITRA' ?
                                                        <>
                                                            <hr className="my-4" />
                                                            <Row>
                                                                <Col style={{ textAlign: 'center' }} md="4">
                                                                    <h5>Status Order</h5>
                                                                    <div style={{ marginTop: '20px' }}>
                                                                        {
                                                                            buildOrderStatus(orderStatus)
                                                                        }
                                                                    </div>
                                                                </Col>
                                                                <Col md="8">
                                                                    {
                                                                        buildMapOrReasonView(props.orders.payload.data)
                                                                    }
                                                                </Col>
                                                            </Row>
                                                        </> : <div></div>
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                </Card>
                                {
                                    props.orders.payload.data.order_status === 'WAITING_FOR_SELECTED_MITRA' ?
                                        <Row>
                                            <Col xl="8">
                                                {
                                                    props.orders.payload.data.order_selected_mitras !== undefined ?
                                                        props.orders.payload.data.order_selected_mitras.length > 0 ?
                                                            <Card style={{ marginTop: '10px' }} className="bg-secondary-shadow">
                                                                <CardBody>
                                                                    <Row>
                                                                        <Col xl="12">Daftar Mitra Yang Sudah Dipilih</Col>
                                                                    </Row>
                                                                    <Row style={{ marginTop: '20px' }}>
                                                                        {
                                                                            props.orders.payload.data.order_selected_mitras.map((item) => {
                                                                                return (
                                                                                    <Col md="3">
                                                                                        {item.user.complete_name}
                                                                                    </Col>
                                                                                )
                                                                            })
                                                                        }
                                                                    </Row>
                                                                </CardBody>
                                                            </Card>
                                                            :
                                                            <Card style={{ marginTop: '10px' }} className="bg-secondary-shadow">
                                                                <CardBody>
                                                                    <div>Belum Ada Mitra Yang Dipilih Oleh Admin</div>
                                                                </CardBody>
                                                            </Card>
                                                        : <div></div>
                                                }
                                            </Col>
                                            <Col xl="4">
                                                <Card style={{ background: 'white', marginTop: '10px' }} className="bg-secondary-shadow">
                                                    <CardBody>
                                                        {secondsToTime(orderTime)}
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row> : <div></div>
                                }
                                <Card style={{ marginTop: '10px' }} className="bg-secondary-shadow">
                                    <CardBody>
                                        <Form>
                                            <div className="align-items-center row">
                                                <div className="col-8">
                                                    <h3 className="mb-0">Informasi Customer</h3>
                                                </div>
                                            </div>
                                            <div style={{ marginTop: '20px' }}>
                                                <Row>
                                                    <Col lg="4">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="customer-complete-name"
                                                            >
                                                                Nama Lengkap
                                                            </label>
                                                            <p id="customer-complete-name">
                                                                {props.orders.payload.data.customer.complete_name}
                                                            </p>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="4">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="customer-email"
                                                            >
                                                                E-Mail
                                                            </label>
                                                            <p id="customer-email">
                                                                {props.orders.payload.data.customer.email !== '' && props.orders.payload.data.customer.email !== null ? props.orders.payload.data.customer.email : 'Tidak ada E-Mail'}
                                                            </p>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="4">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="customer-phone-number"
                                                            >
                                                                Nomor Telepon
                                                            </label>
                                                            <p id="customer-phone-number">
                                                                {props.orders.payload.data.customer.phone_number}
                                                            </p>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg="4">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="customer-address"
                                                            >
                                                                Alamat Customer
                                                            </label>
                                                            <p id="customer-address">
                                                                {props.orders.payload.data.address}
                                                            </p>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="4">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="customer-method-payment"
                                                            >
                                                                Catatan Customer
                                                            </label>
                                                            <p id="customer-method-payment">
                                                                {props.orders.payload.data.order_note !== '' && props.orders.payload.data.order_note !== null ? props.orders.payload.data.order_note : 'Tidak ada catatan'}
                                                            </p>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="4">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="customer-method-payment"
                                                            >
                                                                Metode Pembayaran
                                                            </label>
                                                            <p id="customer-method-payment">
                                                                {props.orders.payload.data.sub_payment.title}
                                                            </p>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg="4">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="customer-gender"
                                                            >
                                                                Gender Customer
                                                            </label>
                                                            <p id="customer-gender">
                                                                {props.orders.payload.data.customer.user_gender === 'male' ? 'Laki Laki' : 'Perempuan'}
                                                            </p>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Form>
                                    </CardBody>
                                </Card>
                                {
                                    props.orders.payload.data.order_status !== 'WAITING_FOR_SELECTED_MITRA' ?
                                        <Card style={{ marginTop: '10px' }} className="bg-secondary-shadow">
                                            <CardBody>
                                                <Form>
                                                    <div className="align-items-center row">
                                                        <div className="col-8">
                                                            <h3 className="mb-0">Informasi Mitra</h3>
                                                        </div>
                                                    </div>
                                                    <div style={{ marginTop: '20px' }}>
                                                        <Row>
                                                            <Col lg="4">
                                                                <FormGroup>
                                                                    <label
                                                                        className="form-control-label"
                                                                        htmlFor="mitra-complete-name"
                                                                    >
                                                                        Nama Lengkap
                                                                    </label>
                                                                    <p id="mitra-complete-name">
                                                                        {props.orders.payload.data.mitra !== null ? props.orders.payload.data.mitra.complete_name : '-'}
                                                                    </p>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col lg="4">
                                                                <FormGroup>
                                                                    <label
                                                                        className="form-control-label"
                                                                        htmlFor="mitra-email"
                                                                    >
                                                                        E-Mail
                                                                    </label>
                                                                    <p id="mitra-email">
                                                                        {props.orders.payload.data.mitra !== null && props.orders.payload.data.mitra.email !== null ? props.orders.payload.data.mitra.email : 'Tidak ada E-Mail'}
                                                                    </p>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col lg="4">
                                                                <FormGroup>
                                                                    <label
                                                                        className="form-control-label"
                                                                        htmlFor="mitra-phone-number"
                                                                    >
                                                                        Nomor Telepon
                                                                    </label>
                                                                    <p id="mitra-phone-number">
                                                                        {props.orders.payload.data.mitra !== null ? props.orders.payload.data.mitra.phone_number : '-'}
                                                                    </p>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg="4">
                                                                <FormGroup>
                                                                    <label
                                                                        className="form-control-label"
                                                                        htmlFor="customer-address"
                                                                    >
                                                                        Rating Mitra
                                                                    </label>
                                                                    <p id="customer-address">
                                                                        {props.orders.payload.data.mitra !== null ? props.orders.payload.data.mitra.user_rating.toFixed(2) : '0.0'}
                                                                    </p>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col lg="4">
                                                                <FormGroup>
                                                                    <label
                                                                        className="form-control-label"
                                                                        htmlFor="mitra-gender"
                                                                    >
                                                                        Gender Mitra
                                                                    </label>
                                                                    <p id="mitra-gender">
                                                                        {props.orders.payload.data.mitra !== null ? props.orders.payload.data.mitra.user_gender === 'male' ? 'Laki Laki' : 'Perempuan' : '-'}
                                                                    </p>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Form>
                                            </CardBody>
                                        </Card> : <div></div>
                                }
                                <Card style={{ marginTop: '10px' }} className="bg-secondary-shadow">
                                    <CardBody>
                                        <Form>
                                            <div className="align-items-center row">
                                                <div className="col-8">
                                                    <h3 className="mb-0">Informasi Order</h3>
                                                </div>
                                            </div>
                                            <div style={{ marginTop: '20px' }}>
                                                <Row>
                                                    <Col lg="6">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="order-timestamp"
                                                            >
                                                                Tanggal Order Dibuat
                                                            </label>
                                                            <p id="order-timestamp">
                                                                {
                                                                    props.orders.payload.data.order_timestamp
                                                                }
                                                            </p>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="6">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="order-type"
                                                            >
                                                                Tipe Order
                                                            </label>
                                                            <p id="order-type">
                                                                <p style={{
                                                                    fontSize: '17px',
                                                                    fontWeight: 'bold',
                                                                    color: buildOrderTypeColor(props.orders.payload.data.order_type),
                                                                }}>
                                                                    {
                                                                        buildOrderType(props.orders.payload.data.order_type)
                                                                    }
                                                                </p>
                                                            </p>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg="4">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="order-timestamp"
                                                            >
                                                                Zona Waktu Order
                                                            </label>
                                                            <p id="order-timestamp">
                                                                {props.orders.payload.data.timezone_code}
                                                            </p>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg="4">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="gross-amount-mitra"
                                                            >
                                                                Pendapatan Mitra
                                                            </label>
                                                            <p
                                                                id="gross-amount-mitra"
                                                                style={{
                                                                    fontSize: '22px',
                                                                    fontWeight: 'bold',
                                                                    color: MaterialRed1
                                                                }}>
                                                                {
                                                                    '-' + formatRupiah(
                                                                        props.orders.payload.data.gross_amount_mitra !== null &&
                                                                            props.orders.payload.data.gross_amount_mitra !== '' ?
                                                                            props.orders.payload.data.gross_amount_mitra.toString() :
                                                                            '0',
                                                                        'Rp. '
                                                                    )
                                                                }
                                                            </p>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="4">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="gross-amount-company"
                                                            >
                                                                Potongan Suberes
                                                            </label>
                                                            <p
                                                                id="gross-amount-company"
                                                                style={{
                                                                    fontSize: '22px',
                                                                    fontWeight: 'bold',
                                                                    color: MaterialGreen1
                                                                }}>
                                                                {
                                                                    formatRupiah(
                                                                        props.orders.payload.data.gross_amount_company !== null &&
                                                                            props.orders.payload.data.gross_amount_company !== '' ?
                                                                            props.orders.payload.data.gross_amount_company.toString() :
                                                                            '0',
                                                                        'Rp. '
                                                                    )
                                                                }
                                                            </p>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="4">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="gross-amount"
                                                            >
                                                                Total Transaksi
                                                            </label>
                                                            <p
                                                                id="gross-amount"
                                                                style={{
                                                                    fontSize: '22px',
                                                                    fontWeight: 'bold',
                                                                    color: MaterialBlue1
                                                                }}>
                                                                {
                                                                    formatRupiah(
                                                                        props.orders.payload.data.gross_amount !== null &&
                                                                            props.orders.payload.data.gross_amount !== '' ?
                                                                            props.orders.payload.data.gross_amount.toString() :
                                                                            '0',
                                                                        'Rp. '
                                                                    )
                                                                }
                                                            </p>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg="4">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="is-additional"
                                                            >
                                                                Ada Tambahan Order?
                                                            </label>
                                                            <p
                                                                id="is-additional"
                                                                style={{
                                                                    fontSize: '17px',
                                                                    fontWeight: 'bold',
                                                                    color: 'black'
                                                                }}
                                                            >
                                                                {
                                                                    props.orders.payload.data.is_additional === '1' ? 'Ada' : 'Tidak Ada'
                                                                }
                                                            </p>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="4">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="is-additional"
                                                            >
                                                                Jumlah Tambahan Order
                                                            </label>
                                                            <p
                                                                id="is-additional"
                                                                style={{
                                                                    fontSize: '17px',
                                                                    fontWeight: 'bold',
                                                                    color: 'black'
                                                                }}
                                                            >
                                                                {
                                                                    `${props.orders.payload.data.order_count_additional} item`
                                                                }
                                                            </p>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="4">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="gross-amount-additional"
                                                            >
                                                                Pendapatan Tambahan
                                                            </label>
                                                            <p
                                                                id="gross-amount-additional"
                                                                style={{
                                                                    fontSize: '22px',
                                                                    fontWeight: 'bold',
                                                                    color: MaterialBlue1
                                                                }}>
                                                                {
                                                                    formatRupiah(
                                                                        props.orders.payload.data.gross_amount_additional !== null &&
                                                                            props.orders.payload.data.gross_amount_additional !== '' ?
                                                                            props.orders.payload.data.gross_amount_additional.toString() :
                                                                            '0',
                                                                        'Rp. '
                                                                    )
                                                                }
                                                            </p>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                {
                                                    !props.orders.payload.data.order_status.toLowerCase().includes('canceled') && props.orders.payload.data.order_status !== 'FINISH' && props.orders.payload.data.order_status !== 'WAITING_FOR_SELECTED_MITRA' ?
                                                        <Row>
                                                            <Col lg="12">
                                                                <a onClick={() => setModal(true)} style={{ backgroundColor: 'white', color: MaterialRed1, paddingTop: '15px', paddingBottom: '15px', width: '100%', marginTop: '10px' }} className="btn btn-danger btn-sm">
                                                                    Batalkan Orderan
                                                                </a>
                                                            </Col>
                                                        </Row>
                                                        : <div></div>
                                                }
                                                {
                                                    props.orders.payload.data.order_status === 'WAITING_FOR_SELECTED_MITRA' ?
                                                        <Row>
                                                            <Col lg="12">
                                                                <a onClick={() => {
                                                                    if (orderTime > 0) {
                                                                        setModalMitra(!modalMitra)
                                                                        if (props.location.state !== undefined) {
                                                                            props.getOrderSelectedMitra(props.location.state.order_id, 5, currentPageSelectedMitra, "", token)
                                                                        }
                                                                    } else {
                                                                        MySwal.fire(
                                                                            'Perhatian',
                                                                            'Order telah expired',
                                                                            'error'
                                                                        )
                                                                        history.goBack()
                                                                    }
                                                                }} style={{ backgroundColor: MaterialBlue1, color: 'white', border: `1px solid ${MaterialBlue1}`, paddingTop: '15px', paddingBottom: '15px', width: '100%', marginTop: '10px' }} className="btn btn-primary btn-sm">
                                                                    Pilih Mitra
                                                                </a>
                                                            </Col>
                                                        </Row> : <div></div>
                                                }
                                            </div>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Modal style={{ maxWidth: '95%', width: '100%' }} isOpen={modalMitra} closable={false} keyboard={false} centered>
                            {
                                isUpdating ?
                                    <div className="progress-bar">
                                        <div className="progress-bar-value"></div>
                                    </div> :
                                    <div style={{ height: '4px' }}></div>
                            }
                            <ModalHeader toggle={handleOpenCloseModalMitra}>
                                <h4>Silahkan pilih mitra yang sesuai dengan order</h4>
                            </ModalHeader>
                            <ModalBody>
                                <Row style={{ marginTop: '-40px' }}>
                                    <Col xl="12">
                                        <Form onSubmit={(e) => {
                                            e.preventDefault()
                                        }}>
                                            <FormGroup>
                                                <InputGroup>
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText style={{ border: `1px solid ${MaterialBlue1}` }}>
                                                            <i class="fa fa-search" aria-hidden="true" style={{ color: MaterialBlue1, fontSize: 15 }}>
                                                            </i>
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input
                                                        id="input-search"
                                                        onChange={onChangeSearch}
                                                        style={{
                                                            border: `1px solid ${MaterialBlue1}`,
                                                            boxShadow: 'none',
                                                            paddingLeft: '10px',
                                                            paddingRight: '10px'
                                                        }}
                                                        defaultValue={searchMitraText}
                                                        placeholder="Ketik nama mitra" />
                                                </InputGroup>
                                            </FormGroup>
                                        </Form>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="12">
                                        {buildOrderSelectedMitra()}
                                    </Col>
                                </Row>
                            </ModalBody>
                        </Modal>
                        <Modal isOpen={modal} toggle={toggle}>
                            {
                                updateCancel ?
                                    <div style={{
                                        borderTopLeftRadius: '5px',
                                        borderTopRightRadius: '5px',
                                        height: '5px'
                                    }} className="progress-bar">
                                        <div className="progress-bar-value"></div>
                                    </div>
                                    : <div style={{
                                        height: '5px'
                                    }}></div>
                            }
                            <ModalHeader className="border-0" toggle={toggle}>
                                Pembatalan Order
                            </ModalHeader>
                            <ModalBody>
                                <Row>
                                    <Col lg="12">
                                        Kamu yakin ingin membatalkan orderan ini?
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="12">
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="input-reasong-cancel"
                                            >
                                                Alasan pembatalan order
                                            </label>
                                            <Input
                                                type="textarea"
                                                id="input-reasong-cancel"
                                                name="place_invited"
                                                defaultValue={reasonCancel}
                                                style={{
                                                    height: '150px',
                                                    padding: '0.4em',
                                                    textAlign: 'left',
                                                    color: 'black'
                                                }}
                                                onChange={(e) => {
                                                    setReasonCancel(e.target.value)
                                                }}
                                                disabled={(updateCancel) ? "disabled" : ""} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="12">
                                        <a onClick={() => submitCancelOrder()} style={{ paddingTop: '15px', paddingBottom: '15px', width: '100%', marginTop: '10px', backgroundColor: 'white', color: MaterialRed1 }} className="btn btn-danger btn-sm">
                                            Batalkan Order
                                        </a>
                                    </Col>
                                </Row>
                            </ModalBody>
                        </Modal>
                    </Container>
                )
            case ORDER_DETAIL_NOT_FOUND:
                return (
                    <>
                        <Container className="mt--7" fluid>
                            <Row>
                                <Col className="order-xl-1" xl="12">
                                    <Card className="bg-secondary-shadow">
                                        <CardBody>
                                            <NotFoundPage
                                                imageSource={IcNotFound}
                                                imageText={props.orders.payload.message} />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </>
                )
            case ORDER_DETAIL_FAILED:
                return (
                    <>
                        <Container className="mt--7" fluid>
                            <Row>
                                <Col className="order-xl-1" xl="12">
                                    <Card className="bg-secondary-shadow">
                                        <CardBody>
                                            <ErrorPage
                                                imageSource={IcError}
                                                imageText={props.orders.payload.message} />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </>
                )
        }
    }
    return (
        <>
            <div className="main-content" ref={mainContent}>
                <AdminNavbar brandText={'Rincian Order'} />
                <div className="header bg-gradient-info pb-8 pt-5 pt-lg-8 d-flex align-items-center">
                    <span className="mask bg-gradient-default opacity-8" />
                </div>
                {buildOrderDetailPage()}
            </div>
        </>
    )
}

OrderDetailPage.propTypes = {
    orders: PropTypes.object.isRequired,
    socket: PropTypes.object.isRequired,
    order_id: PropTypes.number.isRequired,
    getOrderDetail: PropTypes.func.isRequired,
    getOrderSelectedMitra: PropTypes.func.isRequired,
    requestOrderCancel: PropTypes.func.isRequired,
    requestSelectedMitra: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    orders: state.orders,
    socket: state.socket
})

export default connect(mapStateToProps, { getOrderDetail, getOrderSelectedMitra, requestOrderCancel, requestSelectedMitra })(OrderDetailPage)