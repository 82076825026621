import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { getServiceDetail, updateServiceImage, updateService } from "actions/Service/Service";
import { useLocation } from "react-router-dom";
import { MaterialGray1, MaterialGray2, MaterialRed1 } from "components/Utilities/ResourceColors";
import { BASE_URL, getJSONCredential } from "actions/config";
import {
    SERVICE_DETAIL_LOADING,
    SERVICE_DETAIL_SUCCESSFUL,
    SERVICE_DETAIL_NOT_FOUND,
    SERVICE_DETAIL_FAILED
} from 'actions/Service/Types'
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Select from "react-select";
import { useEffect, useRef, useReducer, useState } from "react";
import { Card, CardBody, Col, Container, Form, FormGroup, Input, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import Skeleton from "react-loading-skeleton";
import { MaterialBlue1 } from "components/Utilities/ResourceColors";
import { formatRupiah } from "components/Utilities/Utility";
import { createSubService, updateSubService, removeSubService, resetCrud } from "actions/SubService/SubService";
import { SUB_SERVICE_CREATE_LOADING } from "actions/SubService/Types";
import { SUB_SERVICE_UPDATE_LOADING } from "actions/SubService/Types";
import { SUB_SERVICE_UPDATE_SUCCESSFUL } from "actions/SubService/Types";
import { SUB_SERVICE_UPDATE_NOT_FOUND } from "actions/SubService/Types";
import { SUB_SERVICE_UPDATE_FAILED } from "actions/SubService/Types";
import { MaterialBlue2 } from "components/Utilities/ResourceColors";
import { MaterialGreen1 } from "components/Utilities/ResourceColors";
import { createSubServiceAdditional, updateSubServiceAdditional, removeSubServiceAdditional, resetSubServiceAddCrud } from "actions/SubServiceAdditional/SubServiceAdditional";
import { SUB_SERVICE_ADD_CREATE_LOADING } from "actions/SubServiceAdditional/Types";
import { SUB_SERVICE_ADD_CREATE_SUCCESSFUL } from "actions/SubServiceAdditional/Types";
import { SUB_SERVICE_ADD_CREATE_FAILED } from "actions/SubServiceAdditional/Types";
import { validateEmpty } from "components/Utilities/Utility";
import { SUB_SERVICE_ADD_UPDATE_LOADING } from "actions/Types";
import { SUB_SERVICE_ADD_UPDATE_SUCCESSFUL } from "actions/Types";
import { SUB_SERVICE_ADD_UPDATE_FAILED } from "actions/Types";
import { RED_ERROR_MESSAGE } from "components/Utilities/ResourceColors";
import { SUB_SERVICE_ADD_REMOVE_LOADING } from "actions/SubServiceAdditional/Types";
import { SUB_SERVICE_ADD_REMOVE_SUCCESSFUL } from "actions/SubServiceAdditional/Types";
import { SUB_SERVICE_ADD_REMOVE_UNAUTHORIZED } from "actions/SubServiceAdditional/Types";
import { SUB_SERVICE_ADD_REMOVE_NOT_FOUND } from "actions/SubServiceAdditional/Types";
import { SUB_SERVICE_ADD_REMOVE_FAILED } from "actions/SubServiceAdditional/Types";
import { SUB_SERVICE_CREATE_SUCCESSFUL } from "actions/SubService/Types";
import { SUB_SERVICE_CREATE_FAILED } from "actions/SubService/Types";
import { MaterialGreen2 } from "components/Utilities/ResourceColors";
import { SUB_SERVICE_REMOVE_LOADING } from "actions/SubService/Types";
import { SUB_SERVICE_REMOVE_SUCCESSFUL } from "actions/SubService/Types";
import { SUB_SERVICE_REMOVE_UNAUTHORIZED } from "actions/SubService/Types";
import { SUB_SERVICE_REMOVE_NOT_FOUND } from "actions/SubService/Types";
import { SUB_SERVICE_REMOVE_FAILED } from "actions/SubService/Types";
import { erorrMsgStyle } from "components/Utilities/Utility";
import { SERVICE_UPDATE_LOADING } from "actions/Service/Types";
import { SERVICE_UPDATE_SUCCESSFUL } from "actions/Service/Types";
import { SERVICE_UPDATE_NOT_FOUND } from "actions/Service/Types";
import { SERVICE_UPDATE_FAILED } from "actions/Service/Types";

const MySwal = withReactContent(Swal)

const options_type = [
    {
        value: 'durasi', label: 'Durasi'
    },
    {
        value: 'luas ruangan', label: 'Luas Ruangan'
    },
    {
        value: 'single', label: 'Single'
    }
]

const options_category = [
    {
        value : 'Cleaning' , label : 'Cleaning'
    },
    {
        value : 'Disinfectant' , label : 'Disinfectant'
    },
    {
        value : 'Fogging' , label : 'Fogging'
    },
    {
        value : 'Borongan' , label : 'Borongan'
    },
    {
        value : 'Lainnya' , label : 'Lainnya'
    }
]

const ServiceDetailPage = (props) => {
    const [counter, setCounter] = useState(1)
    const [token, setToken] = useState('')

    const [showModalRemoveSubAdd, setShowModalRemoveSubAdd] = useState(false)
    const handleOpenCloseModalRemove = () => setShowModalRemoveSubAdd(!showModalRemoveSubAdd)

    const [showModalRemoveSubService, setShowModalRemoveSubService] = useState(false)
    const handleOpenCloseModalRemoveSubService = () => {
        if (!isRemoveSubServiceLoading) {
            setShowModalRemoveSubService(!showModalRemoveSubService)
        }
    }

    const fileRef = useRef()
    const fileRefChange = useRef()
    const [isSubmit, setSubmit] = useState(false)
    const [isSubmitCreate, setSubmitCreate] = useState(false)
    const [isSubmitUpdate, setSubmitUpdate] = useState(false)
    const [isSubmitAdd, setSubmitAdd] = useState(false)
    const [isCreateAddHover, setCreateAddHover] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const [isCreateLoading, setCreateLoading] = useState(false)
    const [isUpdateLoading, setUpdateLoading] = useState(false)
    const [isUpdateAddLoading, setUpdateAddLoading] = useState(false)
    const [isRemoveSubServiceLoading, setRemoveSubServiceLoading] = useState(false)
    const [isRemoveAddLoading, setRemoveAddLoading] = useState(false)
    const [isCreateAddLoading, setCreateAddLoading] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [showModalCreate, setShowModalCreate] = useState(false)
    const [showModalSubAdd, setShowModalSubAdd] = useState(false)
    const [showModalCreateSubAdd, setShowModalCreateSubAdd] = useState(false)
    const handleOpenCloseModalCreate = () => {
        if (!isUpdating) {
            setShowModalCreate(!showModalCreate)
        }
    }
    const handleOpenCloseModal = () => setShowModal(!showModal)
    const handleOpenCloseAddModal = () => setShowModalSubAdd(!showModalSubAdd)
    const handleOpenCloseCreateAddModal = () => setShowModalCreateSubAdd(!showModalCreateSubAdd)
    const [selectedFile, setSelectedFile] = useState(null)
    const [imageSelected, setImageSelected] = useState(null)
    const [isUpdating, setUpdating] = useState(false)
    const [selectedIndex, setSelectedIndex] = useState(-1)
    const [lastServiceType, setLastServiceType] = useState({})
    const [serviceType, setServiceType] = useState("")

    const [inputValues, setInputValues] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            service_name: '',
            service_description: '',
            service_image_thumbnail: '',
            service_count: 0,
            service_type_label: '',
            service_category_label: '',
            service_type: {
                label: '',
                value: ''
            },
            service_category: {
                label: '',
                value: ''
            },
            is_residental: '',
            service_status: '',
            service_image: '',
            selectedSubService: {}
        }
    )
    const [inputValuesSub, setInputValuesSub] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            id: -1,
            service_id: -1,
            sub_price_service_title: '',
            sub_price_service: '',
            sub_service_description: '',
            company_percentage: '',
            minutes_sub_services: 0,
            createdAt: '',
            updatedAt: '',
            is_recommended: '0',
            sub_service_additionals: [],

            remove_sub_price_service_title_confirm: '',
            remove_sub_price_service_password_confirm: ''
        }
    )
    const [inputValuesSubAdditional, setInputValuesSubAdditional] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            id: -1,
            sub_service_id: -1,
            title: '',
            amount: '',
            additional_type: {
                value: 'choice',
                label: 'Choice'
            },
            is_choice: '',
            createdAt: '',
            updatedAt: '',
            sub_add_index: -1,

            remove_sub_service_add_id: -1,
            remove_sub_service_add_password_confirm: ''
        }
    )
    const [inputValuesCreate, setInputValuesCreate] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            sub_price_service_title: '',
            sub_price_service: '',
            sub_service_description: '',
            company_percentage: '',
            minutes_sub_services: 0,
            is_recommended: '0'
        }
    )
    const options = [
        {
            value: 'choice', label: 'Choice'
        },
        {
            value: 'cashback', label: 'Cashback'
        },
        {
            value: 'discount', label: 'Discount'
        },
        {
            value: 'free', label: 'Free'
        }
    ]

    const [imgProper, setImgProper] = useState(false)

    useEffect(() => {
        setToken(localStorage.getItem('token'))
    }, [token])

    useEffect(() => {
        if (!showModalRemoveSubAdd) {
            setInputValuesSubAdditional({
                remove_sub_service_add_id: -1,
                remove_sub_service_add_password_confirm: ''
            })
        }
    }, [showModalRemoveSubAdd])

    const onChangeHandler = event => {
        const { name, value } = event.target
        setInputValues({ [name]: value })
    }
    const onChangeImageHandler = event => {
        var img = new Image()
        setImageSelected(URL.createObjectURL(event.target.files[0]))
        img.onload = function () {
            if (img.width == 450 && img.height == 450) {
                setSelectedFile(event.target.files[0])
                setImgProper(true)
            } else {
                setImgProper(false)
            }
        }
        img.src = URL.createObjectURL(event.target.files[0])
    }
    function validateBorderImg() {
        if (isSubmit) {
            if (imageSelected == null || (imageSelected != null && !imgProper)) {
                return `1.5px dashed ${RED_ERROR_MESSAGE}`
            }
        } else {
            if (imageSelected != null && !imgProper) {
                return `1.5px dashed ${RED_ERROR_MESSAGE}`
            }
        }
    }
    function validateTextImg() {
        if (isSubmit) {
            if (imageSelected == null) {
                if (!validateEmpty(inputValues.service_image_thumbnail)) {
                    return (
                        <p style={
                            erorrMsgStyle
                        }>Gambar Paket must be filled</p>
                    )
                }
            } else if (imageSelected != null && !imgProper) {
                return (
                    <p style={
                        erorrMsgStyle
                    }>Gambar Paket must be 450x X 450px</p>
                )
            } else {
                return (<></>)
            }
        } else {
            if (imageSelected != null && !imgProper) {
                return (
                    <p style={
                        erorrMsgStyle
                    }>Gambar Paket must be 450px X 450px</p>
                )
            } else {
                return (<></>)
            }
        }
    }
    const onChangeSubHandler = event => {
        const { name, value } = event.target
        if (name !== 'sub_price_service') {
            setInputValuesSub({ [name]: value })
        } else {
            if (value.length < 4) {
                event.preventDefault()
            } else {
                setInputValuesSub({ ['sub_price_service']: formatRupiah(value.toString(), "") })
            }
        }
    }
    const onChangeSubAdditionalHandler = event => {
        const { name, value } = event.target
        if (name !== 'amount') {
            setInputValuesSubAdditional({ [name]: value })
        } else {
            if (value.length < 4) {
                event.preventDefault()
            } else {
                setInputValuesSubAdditional({ ['amount']: formatRupiah(value.toString(), "") })
            }
        }
    }
    const onChangeCreateHandler = event => {
        const { name, value } = event.target
        setInputValuesCreate({ [name]: value })
    }
    useEffect(() => {
        props.getServiceDetail(props.location.state.service_id)
    }, [])
    useEffect(() => {
        if (props.payload_service.service_detail_payload.data !== undefined) {
            console.log(`Detail : ${JSON.stringify(props.payload_service.service_detail_payload.data, null, 2)}`)
            const payload_data = props.payload_service.service_detail_payload.data
            for (const key in payload_data) {
                if (payload_data.hasOwnProperty(key)) {
                    if (key === 'service_type') {
                        setServiceType(payload_data[key])
                        setInputValues({
                            ['service_type']: {
                                value: payload_data[key],
                                label: payload_data[key]
                            }
                        })
                        setLastServiceType({
                            value: payload_data[key],
                            label: payload_data[key]
                        })
                    } else if (key === 'service_category') {
                        let typeValue = payload_data[key]
                        if (typeValue.split(" ").length > 1) {
                            const split_status = typeValue.split(" ")
                            typeValue = ""
                            for (let i = 0; i < split_status.length; i++) {
                                typeValue += split_status[0].charAt(0).toLowerCase() + split_status[i].slice(1) + (i == split_status.length - 1 ? "" : " ")
                            }
                        } else {
                            typeValue = typeValue.charAt(0).toLowerCase() + typeValue.slice(1)
                        }
                        setInputValues({
                            ['service_category']: {
                                value: typeValue,
                                label: payload_data[key]
                            }
                        })
                        setInputValues({
                            ['service_category_label']: payload_data[key]
                        })
                    } else {
                        setInputValues({ [key]: payload_data[key] })
                    }
                }
            }
        }
    }, [props.payload_service.service_detail_payload])

    useEffect(() => {
        console.log(`Props Update : ${JSON.stringify(props.payload_service.service_update, null, 2)}`)
        switch (props.payload_service.service_update.type) {
            case SERVICE_UPDATE_LOADING: {
                setUpdating(true)
                break;
            }
            case SERVICE_UPDATE_SUCCESSFUL: {
                setUpdating(false)
                setSelectedFile(null)
                setImageSelected(null)
                props.getServiceDetail(props.location.state.service_id)
                break;
            }
            case SERVICE_UPDATE_NOT_FOUND: {
                setUpdating(false)
                MySwal.fire(
                    'Perhatian',
                    'Data Service tak ditemukan',
                    'warning'
                )
                break;
            }
            case SERVICE_UPDATE_FAILED: {
                setUpdating(false)
                MySwal.fire(
                    'Perhatian',
                    'Gagal memperbarui data service',
                    'error'
                )
                break;
            }
        }
    }, [props.payload_service.service_update])

    useEffect(() => {
        switch (props.payload_sub_service.sub_service_create.type) {
            case SUB_SERVICE_CREATE_LOADING: {
                setCreateLoading(true)
                break;
            }
            case SUB_SERVICE_CREATE_SUCCESSFUL: {
                setCreateLoading(false)
                setShowModalCreate(false)
                setSubmitCreate(false)
                setInputValuesCreate({
                    sub_price_service_title: '',
                    sub_price_service: '',
                    sub_service_description: '',
                    company_percentage: '',
                    minutes_sub_services: 0,
                    is_recommended: '0'
                })
                props.payload_service.service_detail_payload.data.sub_services.push(
                    props.payload_sub_service.sub_service_create.message.data
                )
                break;
            }
            case SUB_SERVICE_CREATE_FAILED: {
                setCreateLoading(false)
                break;
            }
        }
    }, [props.payload_sub_service.sub_service_create])

    useEffect(() => {
        switch (props.payload_sub_service.sub_service_remove.type) {
            case SUB_SERVICE_REMOVE_LOADING: {
                setRemoveSubServiceLoading(true)
                break;
            }
            case SUB_SERVICE_REMOVE_SUCCESSFUL: {
                setRemoveSubServiceLoading(false)
                setShowModalRemoveSubService(false)
                setInputValuesSub({
                    remove_sub_price_service_index: -1,
                    remove_sub_price_service_id: -1,
                    remove_sub_price_service_title: '',
                    remove_sub_price_service_title_confirm: '',
                    remove_sub_price_service_password_confirm: ''
                })
                setSubmit(false)
                if (Array.isArray(props.payload_service.service_detail_payload.data.sub_services)) {
                    props.payload_service.service_detail_payload.data.sub_services.splice(inputValuesSub.remove_sub_price_service_index, 1)
                }
                break;
            }
            case SUB_SERVICE_REMOVE_UNAUTHORIZED: {
                setRemoveSubServiceLoading(false)
                MySwal.fire(
                    'Perhatian',
                    'Password salah',
                    'warning'
                )
                break;
            }
            case SUB_SERVICE_REMOVE_NOT_FOUND: {
                setRemoveSubServiceLoading(false)
                MySwal.fire(
                    'Perhatian',
                    'Data unit layanan tidak ada',
                    'error'
                )
                break;
            }
            case SUB_SERVICE_REMOVE_FAILED: {
                setRemoveSubServiceLoading(false)
                MySwal.fire(
                    'Perhatian',
                    'Gagal menghapus data unit layanan',
                    'error'
                )
                break;
            }
        }
    }, [props.payload_sub_service.sub_service_remove])

    useEffect(() => {
        switch (props.payload_sub_service_add.sub_service_add_create.type) {
            case SUB_SERVICE_ADD_CREATE_LOADING: {
                setCreateAddLoading(true)
                break
            }
            case SUB_SERVICE_ADD_CREATE_SUCCESSFUL: {
                setCreateAddLoading(false)
                setShowModalCreateSubAdd(false)
                setSubmitAdd(false)
                setInputValuesSubAdditional({
                    id: -1,
                    sub_service_id: -1,
                    title: '',
                    amount: '',
                    additional_type: {
                        value: 'choice',
                        label: 'Choice'
                    },
                    is_choice: '',
                    createdAt: '',
                    updatedAt: '',
                    sub_add_index: -1,

                    remove_sub_service_add_id: -1,
                    remove_sub_service_add_password_confirm: ''
                })
                inputValuesSub.sub_service_additionals.push(
                    props.payload_sub_service_add.sub_service_add_create.returning_data
                )
                props.resetSubServiceAddCrud()
                break
            }
            case SUB_SERVICE_ADD_CREATE_FAILED: {
                setCreateAddLoading(false)
                MySwal.fire(
                    'Perhatian',
                    'Gagal Membuat Layanan Tambahan',
                    'error'
                )
                break
            }
        }
    }, [props.payload_sub_service_add.sub_service_add_create])

    useEffect(() => {
        switch (props.payload_sub_service.sub_service_update.type) {
            case SUB_SERVICE_UPDATE_LOADING: {
                setUpdateLoading(true)
                break;
            }
            case SUB_SERVICE_UPDATE_SUCCESSFUL: {
                setUpdateLoading(false)
                setShowModal(false)
                setSubmitUpdate(false)
                setInputValuesSub({
                    id: -1,
                    service_id: -1,
                    sub_price_service_title: '',
                    sub_price_service: '',
                    sub_service_description: '',
                    company_percentage: '',
                    minutes_sub_services: 0,
                    createdAt: '',
                    updatedAt: '',
                    is_recommended: '0',
                    sub_service_additionals: [],

                    remove_sub_price_service_title_confirm: '',
                    remove_sub_price_service_password_confirm: ''
                })
                props.payload_service.service_detail_payload.data.sub_services[selectedIndex] = props.payload_sub_service.sub_service_update.returning_data
                MySwal.fire(
                    'Perhatian',
                    'Berhasil Memperbarui Harga',
                    'success'
                )
                props.resetCrud()
                break;
            }
            case SUB_SERVICE_UPDATE_NOT_FOUND: {
                setUpdateLoading(false)
                MySwal.fire(
                    'Perhatian',
                    'Tidak Ada Data Harga',
                    'error'
                )
                props.resetCrud()
                break;
            }
            case SUB_SERVICE_UPDATE_FAILED: {
                setUpdateLoading(false)
                MySwal.fire(
                    'Perhatian',
                    'Gagal Memperbarui Harga',
                    'error'
                )
                break;
            }
        }
    }, [props.payload_sub_service.sub_service_update])

    useEffect(() => {
        switch (props.payload_sub_service_add.sub_service_add_update.type) {
            case SUB_SERVICE_ADD_UPDATE_LOADING: {
                setUpdateAddLoading(true)
                break
            }
            case SUB_SERVICE_ADD_UPDATE_SUCCESSFUL: {
                setUpdateAddLoading(false)
                setShowModalSubAdd(false)
                setSubmitAdd(false)
                inputValuesSub.sub_service_additionals[inputValuesSubAdditional.sub_add_index] = {
                    id: inputValuesSubAdditional.id,
                    sub_service_id: inputValuesSubAdditional.sub_service_id,
                    title: inputValuesSubAdditional.title,
                    amount: inputValuesSubAdditional.amount,
                    additional_type: inputValuesSubAdditional.additional_type.value,
                    is_choice: inputValuesSubAdditional.is_choice
                }
                props.resetSubServiceAddCrud()
                break
            }
            case SUB_SERVICE_ADD_UPDATE_FAILED: {
                setUpdateAddLoading(false)
                MySwal.fire(
                    'Perhatian',
                    'Gagal Memperbarui Layanan Tambahan',
                    'error'
                )
                break
            }
        }
    }, [props.payload_sub_service_add.sub_service_add_update])

    useEffect(() => {
        switch (props.payload_sub_service_add.sub_service_add_remove.type) {
            case SUB_SERVICE_ADD_REMOVE_LOADING: {
                setRemoveAddLoading(true)
                break
            }
            case SUB_SERVICE_ADD_REMOVE_SUCCESSFUL: {
                setRemoveAddLoading(false)
                setShowModalRemoveSubAdd(false)
                if (inputValuesSub.sub_service_additionals.length > 0)
                    inputValuesSub.sub_service_additionals.splice(inputValuesSubAdditional.remove_sub_service_add_index, 1)
                MySwal.fire(
                    'Perhatian',
                    'Berhasil Menghapus Layanan Tambahan',
                    'success'
                )
                props.resetSubServiceAddCrud()
                break
            }
            case SUB_SERVICE_ADD_REMOVE_UNAUTHORIZED: {
                setRemoveAddLoading(false)
                MySwal.fire(
                    'Perhatian',
                    'Password salah',
                    'warning'
                )
                break
            }
            case SUB_SERVICE_ADD_REMOVE_NOT_FOUND: {
                setRemoveAddLoading(false)
                MySwal.fire(
                    'Perhatian',
                    'Data tambahan layanan tidak ada',
                    'error'
                )
                break;
            }
            case SUB_SERVICE_ADD_REMOVE_FAILED: {
                setRemoveAddLoading(false)
                MySwal.fire(
                    'Perhatian',
                    'Gagal menghapus data tambahan layanan',
                    'error'
                )
                break;
            }
        }
    }, [props.payload_sub_service_add.sub_service_add_remove])

    function updateService() {
        setSubmit(true)
        const {
            service_name,
            service_description,
            service_type,
            service_category
        } = inputValues
        if (token !== '' &&
            validateEmpty(props.location.state.service_id) &&
            validateEmpty(service_name) &&
            validateEmpty(service_description) &&
            validateEmpty(service_type.value) &&
            validateEmpty(service_category.value)) {
            if (service_type.value !== lastServiceType.value) {
                MySwal.fire({
                    title: 'Perhatian',
                    text: 'Jika Tipe Service berubah, maka seluruh Unit Layanan akan terhapus, Yakin ingin mengubahnya ?',
                    showCancelButton: true,
                    confirmButtonText: 'Ya',
                    confirmButtonColor: MaterialGray1,
                    cancelButtonColor: RED_ERROR_MESSAGE,
                    cancelButtonText: 'Tidak'
                }).then((result) => {
                    if (result.isConfirmed) {
                        const payloadUpdate = {
                            id: props.location.state.service_id,
                            service_name: service_name,
                            service_type: service_type.label,
                            service_category: service_category.label,
                            service_description: service_description
                        }
                        if (imageSelected != null) {
                            const formData = new FormData()
                            formData.append('json_data', JSON.stringify(payloadUpdate))
                            formData.append('file', selectedFile)
                            props.updateServiceImage(formData, token)
                        } else {
                            props.updateService(payloadUpdate, token)
                        }
                    }
                })
            } else {
                const payloadUpdate = {
                    id: props.location.state.service_id,
                    service_name: service_name,
                    service_type: service_type.label,
                    service_category: service_category.label,
                    service_description: service_description
                }
                if (imageSelected != null) {
                    const formData = new FormData()
                    formData.append('json_data', JSON.stringify(payloadUpdate))
                    formData.append('file', selectedFile)
                    props.updateServiceImage(formData, token)
                } else {
                    props.updateService(payloadUpdate, token)
                }
            }
        }
    }

    function createSubService() {
        setSubmitCreate(true)
        const {
            sub_price_service_title,
            sub_price_service,
            sub_service_description,
            company_percentage,
            minutes_sub_services,
            is_recommended
        } = inputValuesCreate
        if (validateEmpty(props.location.state.service_id) &&
            validateEmpty(sub_price_service_title) &&
            validateEmpty(sub_price_service) &&
            validateEmpty(sub_service_description) &&
            validateEmpty(company_percentage) &&
            validateEmpty(is_recommended)) {
            if(serviceType === 'Durasi'){
                if(validateEmpty(minutes_sub_services) && minutes_sub_services > 0){
                    const payload = {
                        ...inputValuesCreate,
                        sub_price_service: parseInt(sub_price_service.split("Rp. ")[1].split(".").join("")),
                        service_id: props.location.state.service_id
                    }
                    props.createSubService(payload, token)
                }
            } else {
                const payload = {
                    ...inputValuesCreate,
                    sub_price_service: parseInt(sub_price_service.split("Rp. ")[1].split(".").join("")),
                    service_id: props.location.state.service_id
                }
                props.createSubService(payload, token)
            }
        }
    }

    function updateSubService() {
        setSubmitUpdate(true)
        if (validateEmpty(inputValuesSub.sub_price_service_title) &&
            validateEmpty(inputValuesSub.sub_price_service) &&
            validateEmpty(inputValuesSub.company_percentage) &&
            validateEmpty(inputValuesSub.sub_service_description) &&
            validateEmpty(inputValuesSub.is_recommended)) {
            if (serviceType === 'Durasi') {
                if (validateEmpty(inputValuesSub.minutes_sub_services) && inputValuesSub.minutes_sub_services > 0) {
                    props.updateSubService(
                        inputValuesSub.id,
                        {
                            service_id: inputValuesSub.service_id,
                            sub_price_service_title: inputValuesSub.sub_price_service_title,
                            sub_price_service: parseInt(inputValuesSub.sub_price_service.split("Rp. ")[1].split(".").join("")),
                            sub_service_description: inputValuesSub.sub_service_description,
                            company_percentage: inputValuesSub.company_percentage,
                            minutes_sub_services: inputValuesSub.minutes_sub_services,
                            is_recommended: inputValuesSub.is_recommended
                        },
                        token
                    )
                }
            } else {
                props.updateSubService(
                    inputValuesSub.id,
                    {
                        service_id: inputValuesSub.service_id,
                        sub_price_service_title: inputValuesSub.sub_price_service_title,
                        sub_price_service: parseInt(inputValuesSub.sub_price_service.split("Rp. ")[1].split(".").join("")),
                        sub_service_description: inputValuesSub.sub_service_description,
                        company_percentage: inputValuesSub.company_percentage,
                        minutes_sub_services: inputValuesSub.minutes_sub_services,
                        is_recommended: inputValuesSub.is_recommended
                    },
                    token
                )
            }
        }
    }

    function removeSubService() {
        if (token !== '') {
            if (validateEmpty(inputValuesSub.remove_sub_price_service_id) &&
                validateEmpty(inputValuesSub.remove_sub_price_service_title) &&
                validateEmpty(inputValuesSub.remove_sub_price_service_title_confirm) &&
                validateEmpty(inputValuesSub.remove_sub_price_service_password_confirm) &&
                inputValuesSub.remove_sub_price_service_title === inputValuesSub.remove_sub_price_service_title_confirm) {
                setSubmit(true)
                props.removeSubService(inputValuesSub.remove_sub_price_service_id,
                    inputValuesSub.remove_sub_price_service_password_confirm, token)
            }
        }
    }

    function createSubServiceAdd() {
        setSubmitAdd(true)
        const {
            sub_service_id,
            title,
            amount,
            additional_type
        } = inputValuesSubAdditional
        if (validateEmpty(sub_service_id) &&
            validateEmpty(title) &&
            validateEmpty(amount) &&
            validateEmpty(additional_type.value)) {
            const amount_number = amount.split("Rp. ")[1].split(".").join("")
            const payload = {
                sub_service_id,
                title: title,
                amount: parseInt(amount_number),
                additional_type: additional_type.value
            }
            props.createSubServiceAdditional(payload, token)
        }
    }

    function updateSubServiceAdd() {
        setSubmitAdd(true)
        const {
            sub_service_id,
            title,
            amount,
            additional_type
        } = inputValuesSubAdditional
        if (validateEmpty(sub_service_id) &&
            validateEmpty(title) &&
            validateEmpty(amount) &&
            validateEmpty(additional_type)) {
            const amount_number = amount.split("Rp. ")[1].split(".").join("")
            const payload = {
                sub_service_id: sub_service_id,
                title: title,
                amount: parseInt(amount_number),
                additional_type: additional_type.value
            }
            props.updateSubServiceAdditional(inputValuesSubAdditional.id, JSON.stringify(payload), token)
        }
    }

    function removeSubServiceAdd() {
        if (validateEmpty(inputValuesSubAdditional.remove_sub_service_add_id) &&
            validateEmpty(inputValuesSubAdditional.remove_sub_service_add_password_confirm)) {
            setSubmit(true)
            props.removeSubServiceAdditional(inputValuesSubAdditional.remove_sub_service_add_id,
                inputValuesSubAdditional.remove_sub_service_add_password_confirm, token)
        }
    }

    function buildServiceDetail() {
        switch (props.payload_service.service_detail_payload.type) {
            case SERVICE_DETAIL_LOADING: {
                return (
                    <>
                        <Container className="mt--7" fluid>
                            <Row>
                                <Col className="order-xl-1" xl="12">
                                    <Card className="bg-secondary shadow">
                                        <CardBody>
                                            <Form>
                                                <div>
                                                    <Row>
                                                        <Col lg="12">
                                                            <FormGroup>
                                                                <Skeleton style={{ height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col lg="12">
                                                            <Skeleton style={{ height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <Skeleton style={{ marginTop: '30px', height: '200px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col lg="12">
                                                            <FormGroup>
                                                                <Skeleton style={{ marginTop: '30px', height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Form>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </>
                )
            }
            case SERVICE_DETAIL_SUCCESSFUL: {
                return (
                    <>
                        <Container className="mt--7" fluid>
                            <Row>
                                <Col className="order-xl-1" xl="12">
                                    <Card className="bg-secondary-shadow">
                                        {
                                            isUpdating ?
                                                <div style={{
                                                    borderTopLeftRadius: '5px',
                                                    borderTopRightRadius: '5px',
                                                    height: '5px'
                                                }} className="progress-bar">
                                                    <div className="progress-bar-value"></div>
                                                </div>
                                                : <div style={{ height: '5px' }}></div>
                                        }
                                        <CardBody>
                                            <Form>
                                                <div>
                                                    <Row>
                                                        <Col lg="6">
                                                            <Row style={{ marginTop: '-10px' }}>
                                                                <Col lg="12">
                                                                    <label
                                                                        className="form-control-label"
                                                                        htmlFor="input-service-name"
                                                                    >
                                                                        Nama Service
                                                                    </label>
                                                                    <Input
                                                                        className="form-control-alternative"
                                                                        id="input-service-name"
                                                                        type="text"
                                                                        name="service_name"
                                                                        onChange={onChangeHandler}
                                                                        defaultValue={inputValues.service_name}
                                                                        style={
                                                                            isSubmit && inputValues.service_name === '' ?
                                                                                {
                                                                                    color: 'black',
                                                                                    border: '0.2px solid #d32f2f'
                                                                                }
                                                                                :
                                                                                {
                                                                                    color: 'black',
                                                                                    border: 'none'
                                                                                }
                                                                        }
                                                                        disabled={(isUpdating) ? "disabled" : ""} />
                                                                    <p style={{
                                                                        fontSize: '11px',
                                                                        color: '#d32f2f',
                                                                        marginTop: '5px',
                                                                        visibility: isSubmit && inputValues.service_name === '' ? 'visible' : 'hidden'
                                                                    }}>Harap isi Nama Service</p>
                                                                </Col>
                                                            </Row>
                                                            <Row style={{ marginTop: '-20px' }}>
                                                                <Col lg="6">
                                                                    <label
                                                                        className="form-control-label"
                                                                        htmlFor="input-service-type"
                                                                    >
                                                                        Tipe Service
                                                                    </label>
                                                                    <Select
                                                                        id="input-service-type"
                                                                        options={options_type}
                                                                        value={inputValues.service_type}
                                                                        name="service_type"
                                                                        onChange={(selectedOption) => {
                                                                            setInputValues({ ['service_type']: selectedOption })
                                                                        }} isDisabled={isUpdating} />
                                                                </Col>
                                                                <Col lg="6">
                                                                    <label
                                                                        className="form-control-label"
                                                                        htmlFor="input-service-category"
                                                                    >
                                                                        Kategori Service
                                                                    </label>
                                                                    <Select
                                                                        id="input-service-category"
                                                                        options={options_category}
                                                                        value={inputValues.service_category}
                                                                        name="service_category"
                                                                        onChange={(selectedOption) => {
                                                                            setInputValues({ ['service_category']: selectedOption })
                                                                        }} isDisabled={isUpdating} />
                                                                </Col>
                                                            </Row>
                                                            <Row style={{ marginTop: '10px' }}>
                                                                <Col lg="12">
                                                                    <label
                                                                        className="form-control-label"
                                                                    >
                                                                        {
                                                                            `${inputValues.service_type_label}`
                                                                        }
                                                                    </label>
                                                                    <Row>
                                                                        {
                                                                            props.payload_service.service_detail_payload.data.sub_services.map((item, index) => {
                                                                                return (
                                                                                    <>
                                                                                        <Col style={{ marginTop: '10px' }} md="6">
                                                                                            <Card style={{ cursor: 'pointer', backgroundColor: MaterialBlue1 }} className="shadow">
                                                                                                <div style={{
                                                                                                    paddingBottom: '5px'
                                                                                                }}
                                                                                                >
                                                                                                    <p style={{
                                                                                                        color: 'white',
                                                                                                        marginTop: '20px',
                                                                                                        textAlign: 'center',
                                                                                                        borderRadius: '5px',
                                                                                                        marginLeft: '25px',
                                                                                                        marginRight: '25px',
                                                                                                        paddingTop: '5px',
                                                                                                        paddingBottom: '5px'
                                                                                                    }}>
                                                                                                        {item.sub_price_service_title}
                                                                                                    </p>
                                                                                                </div>
                                                                                            </Card>
                                                                                            <div style={{ marginTop: '8px', display: 'flex' }}>
                                                                                                <div onClick={() => {
                                                                                                    if (!isUpdating) {
                                                                                                        let formattedPrice = '';
                                                                                                        if (validateEmpty(item.sub_price_service)) {
                                                                                                            formattedPrice = formatRupiah(item.sub_price_service.toString(), "Rp. ")
                                                                                                        } else {
                                                                                                            formattedPrice = formatRupiah("", "Rp. ")
                                                                                                        }
                                                                                                        const formattedObj = {
                                                                                                            ...item,
                                                                                                            sub_price_service: formattedPrice
                                                                                                        }
                                                                                                        setInputValuesSub(formattedObj)
                                                                                                        setSelectedIndex(index)
                                                                                                        setShowModal(!showModal)
                                                                                                    }
                                                                                                }} style={{ width: '50%', cursor: 'pointer', borderRadius: '10px', marginRight: '5px', paddingTop: '13px', backgroundColor: MaterialBlue2 }}>
                                                                                                    <p style={{ textAlign: 'center', fontSize: '12px', color: 'white', fontWeight: 'bold' }}>Perbarui</p>
                                                                                                </div>
                                                                                                <div onClick={() => {
                                                                                                    if (!isUpdating) {
                                                                                                        setInputValuesSub({
                                                                                                            remove_sub_price_service_index: index,
                                                                                                            remove_sub_price_service_id: item.id,
                                                                                                            remove_sub_price_service_title: item.sub_price_service_title
                                                                                                        })
                                                                                                        setShowModalRemoveSubService(true)
                                                                                                    }
                                                                                                }} style={{ width: '50%', cursor: 'pointer', borderRadius: '10px', marginLeft: '5px', paddingTop: '13px', backgroundColor: MaterialRed1 }}>
                                                                                                    <p style={{ textAlign: 'center', fontSize: '12px', color: 'white', fontWeight: 'bold' }}>Hapus</p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </Col>
                                                                                    </>
                                                                                )
                                                                            })
                                                                        }
                                                                        <Col style={{ marginTop: '10px' }} md="6">
                                                                            <Card onClick={handleOpenCloseModalCreate} style={{ cursor: 'pointer', border: '1px solid black' }} className="shadow">
                                                                                <div>
                                                                                    <p style={{
                                                                                        marginTop: '20px',
                                                                                        textAlign: 'center',
                                                                                        borderRadius: '5px',
                                                                                        marginLeft: '25px',
                                                                                        marginRight: '25px',
                                                                                        fontSize: '25px'
                                                                                    }}>
                                                                                        +
                                                                                    </p>
                                                                                </div>
                                                                            </Card>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col lg="6">
                                                            <div>
                                                                <label
                                                                    className="form-control-label">Gambar Paket</label>
                                                                {
                                                                    inputValues.service_image_thumbnail === '' ?
                                                                        <div id="placeholder_image"
                                                                            style={{
                                                                                width: '100%',
                                                                                height: '260px',
                                                                                borderRadius: '10px',
                                                                                alignItems: 'center',
                                                                                display: 'flex',
                                                                                justifyContent: 'center',
                                                                                textAlign: 'center',
                                                                                backgroundColor: MaterialGray2,
                                                                                border: '1px dashed black',
                                                                                cursor: 'pointer'
                                                                            }}
                                                                            onClick={e => {
                                                                                fileRef.current.click()
                                                                            }}
                                                                        >
                                                                            <input type="file" name="image_picker" ref={fileRef} hidden />
                                                                        </div>
                                                                        :
                                                                        <div style={{
                                                                            alignItems: 'center',
                                                                            display: 'flex',
                                                                            justifyContent: 'center',
                                                                            border: imageSelected === null ? '1px dashed black' : '',
                                                                            borderRadius: '10px'
                                                                        }}>
                                                                            {
                                                                                imageSelected === null ?
                                                                                    <div style={{
                                                                                        alignItems: 'center',
                                                                                        display: 'flex',
                                                                                        justifyContent: 'center'
                                                                                    }}>
                                                                                        <img style={{
                                                                                            width: '100%',
                                                                                            height: '260px',
                                                                                            objectFit: 'cover',
                                                                                            borderRadius: '10px',
                                                                                            transition: '0.45s'
                                                                                        }} src={`${BASE_URL}/api/images/${inputValues.service_image_thumbnail}`} />
                                                                                    </div>
                                                                                    :
                                                                                    <div style={{
                                                                                        width: '100%',
                                                                                        border: validateBorderImg(),
                                                                                        borderRadius: '10px',
                                                                                        alignItems: 'center',
                                                                                        justifyContent: 'center',
                                                                                        textAlign: 'center'
                                                                                    }}>
                                                                                        <div style={{
                                                                                            width: '25px',
                                                                                            height: '25px',
                                                                                            borderRadius: '50%',
                                                                                            backgroundColor: MaterialRed1,
                                                                                            position: 'absolute',
                                                                                            right: 8,
                                                                                            top: 22,
                                                                                            cursor: 'pointer'
                                                                                        }}
                                                                                            onClick={() => {
                                                                                                setSelectedFile(null)
                                                                                                setImageSelected(null)
                                                                                            }}
                                                                                        >
                                                                                            <p style={{ color: 'white' }}>&times;</p>
                                                                                        </div>
                                                                                        <img src={imageSelected} style={{
                                                                                            width: '100%',
                                                                                            objectFit: 'cover',
                                                                                            borderRadius: '10px',
                                                                                            height: '260px',
                                                                                            display: 'inline-block'
                                                                                        }} />
                                                                                    </div>
                                                                            }
                                                                        </div>
                                                                }
                                                                <Row>
                                                                    <Col sm="8">
                                                                        <p style={{ fontSize: '13px', marginTop: '10px' }}>
                                                                            Ukuran Gambar 450px X 450px
                                                                        </p>
                                                                    </Col>
                                                                    <Col sm="4">
                                                                        <div>
                                                                            <input type="file" onChange={onChangeImageHandler} name="image_picker_change" ref={fileRefChange} hidden />
                                                                            <button onClick={(e) => {
                                                                                e.preventDefault()
                                                                                if (!isUpdating) {
                                                                                    fileRefChange.current.click()
                                                                                }
                                                                            }} style={{
                                                                                marginTop: '10px',
                                                                                width: '100%'
                                                                            }} className="btn btn-sm btn-primary">Ganti gambar</button>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col xl="12">
                                                                        {validateTextImg()}
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row style={{ marginTop: '10px' }}>
                                                        <Col lg="12">
                                                            <FormGroup>
                                                                <label
                                                                    className="form-control-label"
                                                                    htmlFor="input-service-description"
                                                                >
                                                                    Deskripsi Service
                                                                </label>
                                                                <Input
                                                                    type="textarea"
                                                                    id="input-service-description"
                                                                    name="service_description"
                                                                    defaultValue={inputValues.service_description}
                                                                    style={
                                                                        isSubmit && inputValues.service_description === '' ?
                                                                            {
                                                                                height: '150px',
                                                                                padding: '0.4em',
                                                                                textAlign: 'left',
                                                                                color: 'black',
                                                                                border: '0.2px solid #d32f2f'
                                                                            }
                                                                            :
                                                                            {
                                                                                height: '150px',
                                                                                padding: '0.4em',
                                                                                textAlign: 'left',
                                                                                color: 'black',
                                                                                border: 'none'
                                                                            }
                                                                    }
                                                                    disabled={(isUpdating) ? "disabled" : ""}
                                                                    onChange={onChangeHandler} />
                                                                <p style={{
                                                                    fontSize: '11px',
                                                                    color: '#d32f2f',
                                                                    marginTop: '5px',
                                                                    visibility: isSubmit && inputValues.service_description === '' ? 'visible' : 'hidden'
                                                                }}>Harap isi Deskripsi Service</p>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col lg="12">
                                                            <a onClick={() => updateService()} style={{ width: '100%', marginTop: '10px' }} className="btn btn-success btn-sm">
                                                                Simpan
                                                            </a>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Form>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Modal isOpen={showModalCreate}
                                closable={false} keyboard={false} centered
                            >
                                {
                                    isCreateLoading ?
                                        <div style={{
                                            borderTopLeftRadius: '5px',
                                            borderTopRightRadius: '5px',
                                            height: '5px'
                                        }} className="progress-bar">
                                            <div className="progress-bar-value"></div>
                                        </div>
                                        : <div style={{ height: '5px' }}></div>
                                }
                                <ModalHeader toggle={handleOpenCloseModalCreate}>
                                    <h4>Atur Harga</h4>
                                </ModalHeader>
                                <ModalBody>
                                    <Row style={{ marginTop: '-30px' }}>
                                        <Col lg="6">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="input-sub-service-title"
                                                >
                                                    Satuan Harga
                                                </label>
                                                <Input
                                                    className="form-control-alternative"
                                                    id="input-sub-service-title"
                                                    type="text"
                                                    name="sub_price_service_title"
                                                    onChange={onChangeCreateHandler}
                                                    defaultValue={inputValuesCreate.sub_price_service_title}
                                                    style={
                                                        isSubmitCreate && inputValuesCreate.sub_price_service_title === '' ?
                                                            {
                                                                color: 'black',
                                                                border: '0.2px solid #d32f2f'
                                                            }
                                                            :
                                                            {
                                                                color: 'black',
                                                                border: 'none'
                                                            }
                                                    }
                                                    disabled={isCreateLoading ? 'disabled' : ''} />
                                                <p style={{
                                                    fontSize: '11px',
                                                    color: '#d32f2f',
                                                    marginTop: '5px',
                                                    visibility: isSubmitCreate && inputValuesCreate.sub_price_service_title === '' ? 'visible' : 'hidden'
                                                }}>Harap isi satuan harga</p>
                                            </FormGroup>
                                        </Col>
                                        <Col lg="6">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="input-sub-price-service"
                                                >
                                                    Harga
                                                </label>
                                                <Input
                                                    className="form-control-alternative"
                                                    id="input-sub-price-service"
                                                    name="sub_price_service"
                                                    onChange={onChangeCreateHandler}
                                                    value={inputValuesCreate.sub_price_service === '' ? 'Rp. ' : formatRupiah(inputValuesCreate.sub_price_service.toString(), "")}
                                                    style={
                                                        isSubmitCreate && inputValuesCreate.sub_price_service === '' ?
                                                            {
                                                                paddingLeft: '15px',
                                                                color: 'black',
                                                                border: '0.2px solid #d32f2f'
                                                            }
                                                            :
                                                            {
                                                                paddingLeft: '15px',
                                                                color: 'black',
                                                                border: 'none'
                                                            }
                                                    }
                                                    disabled={isCreateLoading ? 'disabled' : ''} />
                                                <p style={{
                                                    fontSize: '11px',
                                                    color: '#d32f2f',
                                                    marginTop: '5px',
                                                    visibility: isSubmitCreate && inputValuesCreate.sub_price_service === '' ? 'visible' : 'hidden'
                                                }}>Harap isi harga</p>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    {
                                        serviceType === 'Durasi' ?
                                            <>
                                                <Row style={{ marginTop: '-20px' }}>
                                                    <Col lg="6">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-company-percentage"
                                                            >
                                                                Bagi Hasil Perusahaan
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id="input-company-percentage"
                                                                name="company_percentage"
                                                                type="number"
                                                                step=".01"
                                                                onChange={onChangeCreateHandler}
                                                                defaultValue={inputValuesCreate.company_percentage}
                                                                style={
                                                                    isSubmitCreate && inputValuesCreate.company_percentage === '' ?
                                                                        {
                                                                            color: 'black',
                                                                            border: '0.2px solid #d32f2f'
                                                                        }
                                                                        :
                                                                        {
                                                                            color: 'black',
                                                                            border: 'none'
                                                                        }
                                                                }
                                                                disabled={isCreateLoading ? 'disabled' : ''} />
                                                            <p style={{
                                                                fontSize: '11px',
                                                                color: '#d32f2f',
                                                                marginTop: '5px',
                                                                visibility: isSubmitCreate && inputValuesCreate.company_percentage === '' ? 'visible' : 'hidden'
                                                            }}>Harap isi bagi hasil perusahaan</p>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="6">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-minutes-sub-services"
                                                            >
                                                                Menit Durasi <br />
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id="input-minutes-sub-services"
                                                                name="minutes_sub_services"
                                                                type="number"
                                                                step="1"
                                                                onChange={onChangeCreateHandler}
                                                                defaultValue={inputValuesCreate.minutes_sub_services}
                                                                style={
                                                                    isSubmitCreate && inputValuesCreate.minutes_sub_services <= 0 ?
                                                                        {
                                                                            color: 'black',
                                                                            border: '0.2px solid #d32f2f'
                                                                        }
                                                                        :
                                                                        {
                                                                            color: 'black',
                                                                            border: 'none'
                                                                        }
                                                                }
                                                                disabled={isCreateLoading ? 'disabled' : ''} />
                                                            <p style={{
                                                                fontSize: '11px',
                                                                color: '#d32f2f',
                                                                marginTop: '5px',
                                                                visibility: isSubmitCreate && inputValuesCreate.minutes_sub_services <= 0 ? 'visible' : 'hidden'
                                                            }}>Harap isi menit durasi</p>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row style={{ marginTop: '-20px' }}>
                                                    <Col lg="6">
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-is-recommended"
                                                        >
                                                            Di Rekomendasikan Ke User?
                                                        </label>
                                                        <Row>
                                                            <Col md="5">
                                                                <input
                                                                    id="is_recommended_yes"
                                                                    type="radio"
                                                                    name="is_broadcast_yes"
                                                                    style={{ marginRight: '5px' }}
                                                                    checked={inputValuesCreate.is_recommended === '1'}
                                                                    disabled={(isUpdating) ? "disabled" : ""}
                                                                    onChange={e => {
                                                                        setInputValuesCreate({ ['is_recommended']: '1' })
                                                                    }} />
                                                                <label
                                                                    className="form-control-label"
                                                                    htmlFor="is_recommended_yes" style={{ fontSize: '13px' }}>Yes</label>
                                                            </Col>
                                                            <Col md="5">
                                                                <input
                                                                    id="is_recommended_no"
                                                                    type="radio"
                                                                    name="is_broadcast_no"
                                                                    style={{ marginRight: '5px' }}
                                                                    checked={inputValuesCreate.is_recommended === '0'}
                                                                    disabled={(isUpdating) ? "disabled" : ""}
                                                                    onChange={e => {
                                                                        setInputValuesCreate({ ['is_recommended']: '0' })
                                                                    }} />
                                                                <label
                                                                    className="form-control-label"
                                                                    htmlFor="is_recommended_no" style={{ fontSize: '13px' }}>No</label>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </> :
                                            <>
                                                <Row style={{ marginTop: '-20px' }}>
                                                    <Col lg="6">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-company-percentage"
                                                            >
                                                                Bagi Hasil Perusahaan
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id="input-company-percentage"
                                                                name="company_percentage"
                                                                type="number"
                                                                step=".01"
                                                                onChange={onChangeCreateHandler}
                                                                defaultValue={inputValuesCreate.company_percentage}
                                                                style={
                                                                    isSubmitCreate && inputValuesCreate.company_percentage === '' ?
                                                                        {
                                                                            color: 'black',
                                                                            border: '0.2px solid #d32f2f'
                                                                        }
                                                                        :
                                                                        {
                                                                            color: 'black',
                                                                            border: 'none'
                                                                        }
                                                                }
                                                                disabled={isCreateLoading ? 'disabled' : ''} />
                                                            <p style={{
                                                                fontSize: '11px',
                                                                color: '#d32f2f',
                                                                marginTop: '5px',
                                                                visibility: isSubmitCreate && inputValuesCreate.company_percentage === '' ? 'visible' : 'hidden'
                                                            }}>Harap isi bagi hasil perusahaan</p>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="6">
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-is-recommended"
                                                        >
                                                            Di Rekomendasikan Ke User?
                                                        </label>
                                                        <Row style={{ marginTop: '10px' }}>
                                                            <Col md="5">
                                                                <input
                                                                    id="is_recommended_yes"
                                                                    type="radio"
                                                                    name="is_broadcast_yes"
                                                                    style={{ marginRight: '5px' }}
                                                                    checked={inputValuesCreate.is_recommended === '1'}
                                                                    disabled={(isUpdating) ? "disabled" : ""}
                                                                    onChange={e => {
                                                                        setInputValuesCreate({ ['is_recommended']: '1' })
                                                                    }} />
                                                                <label
                                                                    className="form-control-label"
                                                                    htmlFor="is_recommended_yes" style={{ fontSize: '13px' }}>Yes</label>
                                                            </Col>
                                                            <Col md="5">
                                                                <input
                                                                    id="is_recommended_no"
                                                                    type="radio"
                                                                    name="is_broadcast_no"
                                                                    style={{ marginRight: '5px' }}
                                                                    checked={inputValuesCreate.is_recommended === '0'}
                                                                    disabled={(isUpdating) ? "disabled" : ""}
                                                                    onChange={e => {
                                                                        setInputValuesCreate({ ['is_recommended']: '0' })
                                                                    }} />
                                                                <label
                                                                    className="form-control-label"
                                                                    htmlFor="is_recommended_no" style={{ fontSize: '13px' }}>No</label>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </>
                                    }
                                    <Row style={{ marginTop: serviceType === 'Durasi' ? '20px' : '0px' }}>
                                        <Col lg="12">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="input-sub-service-description"
                                                >
                                                    Deskripsi
                                                </label>
                                                <Input
                                                    id="input-sub-service-description"
                                                    name="sub_service_description"
                                                    type="textarea"
                                                    className="form-control-alternative"
                                                    onChange={onChangeCreateHandler}
                                                    defaultValue={inputValuesCreate.sub_service_description}
                                                    style={
                                                        isSubmitCreate && inputValuesCreate.sub_service_description === '' ?
                                                            {
                                                                height: '100px',
                                                                padding: '0.4em',
                                                                textAlign: 'left',
                                                                color: 'black',
                                                                border: '0.2px solid #d32f2f'
                                                            }
                                                            :
                                                            {
                                                                height: '100px',
                                                                padding: '0.4em',
                                                                textAlign: 'left',
                                                                color: 'black',
                                                                border: 'none'
                                                            }
                                                    }
                                                    disabled={isCreateLoading ? 'disabled' : ''} />
                                                <p style={{
                                                    fontSize: '11px',
                                                    color: '#d32f2f',
                                                    marginTop: '5px',
                                                    visibility: isSubmitCreate && inputValuesCreate.sub_service_description === '' ? 'visible' : 'hidden'
                                                }}>Harap isi deskripsi</p>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row style={{ marginTop: '-30px' }}>
                                        <Col lg="12">
                                            <a onClick={() => createSubService()} style={{ background: isCreateLoading ? MaterialGray1 : MaterialGreen2, width: '100%', marginTop: '10px' }} className="btn btn-success btn-sm"
                                                disabled={isCreateLoading ? 'disabled' : ''}>
                                                Simpan
                                            </a>
                                        </Col>
                                    </Row>
                                </ModalBody>
                            </Modal>
                            <div>
                                <Modal centered isOpen={showModal}
                                    closable={false} keyboard={false}>
                                    {
                                        isUpdateLoading ?
                                            <div style={{
                                                borderTopLeftRadius: '5px',
                                                borderTopRightRadius: '5px',
                                                height: '5px'
                                            }} className="progress-bar">
                                                <div className="progress-bar-value"></div>
                                            </div>
                                            : <div style={{ height: '5px' }}></div>
                                    }
                                    <ModalHeader toggle={handleOpenCloseModal}>
                                        <h4>Atur Harga</h4>
                                    </ModalHeader>
                                    <ModalBody>
                                        <Row style={{ marginTop: '-30px' }}>
                                            <Col lg="6">
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="input-sub-service-title"
                                                >
                                                    Satuan Harga
                                                </label>
                                                <Input
                                                    className="form-control-alternative"
                                                    id="input-sub-service-title"
                                                    type="text"
                                                    name="sub_price_service_title"
                                                    onChange={onChangeSubHandler}
                                                    value={inputValuesSub.sub_price_service_title}
                                                    style={
                                                        isSubmitUpdate && inputValuesSub.sub_price_service_title === '' ?
                                                            {
                                                                color: 'black',
                                                                border: '0.2px solid #d32f2f'
                                                            }
                                                            :
                                                            {
                                                                color: 'black',
                                                                border: 'none'
                                                            }
                                                    } />
                                                <p style={{
                                                    fontSize: '11px',
                                                    color: '#d32f2f',
                                                    marginTop: '5px',
                                                    visibility: isSubmitUpdate && inputValuesSub.sub_price_service_title === '' ? 'visible' : 'hidden'
                                                }}>Harap isi satuan harga</p>
                                            </Col>
                                            <Col lg="6">
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="input-sub-price-service"
                                                >
                                                    Harga
                                                </label>
                                                <Input
                                                    className="form-control-alternative"
                                                    id="input-sub-price-service"
                                                    name="sub_price_service"
                                                    onChange={onChangeSubHandler}
                                                    value={inputValuesSub.sub_price_service === '' ? 'Rp. ' : formatRupiah(inputValuesSub.sub_price_service.toString(), "")}
                                                    style={
                                                        isSubmitUpdate && inputValuesSub.sub_price_service === '' ?
                                                            {
                                                                paddingLeft: '15px',
                                                                color: 'black',
                                                                border: '0.2px solid #d32f2f'
                                                            }
                                                            :
                                                            {
                                                                paddingLeft: '15px',
                                                                color: 'black',
                                                                border: 'none'
                                                            }
                                                    } />
                                                <p style={{
                                                    fontSize: '11px',
                                                    color: '#d32f2f',
                                                    marginTop: '5px',
                                                    visibility: isSubmitUpdate && inputValuesSub.sub_price_service === '' ? 'visible' : 'hidden'
                                                }}>Harap isi harga</p>
                                            </Col>
                                        </Row>
                                        {
                                            // Update Value
                                            serviceType === 'Durasi' ?
                                                <>
                                                    <Row style={{ marginTop: '-20px' }}>
                                                        <Col lg="6">
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-company-percentage"
                                                            >
                                                                Bagi Hasil Perusahaan
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id="input-company-percentage"
                                                                name="company_percentage"
                                                                type="number"
                                                                step=".01"
                                                                onChange={onChangeSubHandler}
                                                                value={inputValuesSub.company_percentage}
                                                                style={
                                                                    isSubmitUpdate && inputValuesSub.company_percentage === '' ?
                                                                        {
                                                                            color: 'black',
                                                                            border: '0.2px solid #d32f2f'
                                                                        }
                                                                        :
                                                                        {
                                                                            color: 'black',
                                                                            border: 'none'
                                                                        }
                                                                } />
                                                            <p style={{
                                                                fontSize: '11px',
                                                                color: '#d32f2f',
                                                                marginTop: '5px',
                                                                visibility: isSubmitUpdate && inputValuesSub.company_percentage === '' || inputValuesSub.company_percentage === 0 ? 'visible' : 'hidden'
                                                            }}>Harap isi bagi hasil perusahaan</p>
                                                        </Col>
                                                        <Col lg="6">
                                                            <FormGroup>
                                                                <label
                                                                    className="form-control-label"
                                                                    htmlFor="input-minutes-sub-services-update"
                                                                >
                                                                    Menit Durasi <br />
                                                                </label>
                                                                <Input
                                                                    className="form-control-alternative"
                                                                    id="input-minutes-sub-services-update"
                                                                    name="minutes_sub_services"
                                                                    type="number"
                                                                    step="1"
                                                                    onChange={onChangeSubHandler}
                                                                    defaultValue={inputValuesSub.minutes_sub_services}
                                                                    style={
                                                                        isSubmitUpdate && inputValuesSub.minutes_sub_services <= 0 ?
                                                                            {
                                                                                color: 'black',
                                                                                border: '0.2px solid #d32f2f'
                                                                            }
                                                                            :
                                                                            {
                                                                                color: 'black',
                                                                                border: 'none'
                                                                            }
                                                                    } />
                                                                <p style={{
                                                                    fontSize: '11px',
                                                                    color: '#d32f2f',
                                                                    marginTop: '5px',
                                                                    visibility: isSubmitUpdate && inputValuesSub.minutes_sub_services <= 0 ? 'visible' : 'hidden'
                                                                }}>Harap isi menit durasi</p>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row style={{ marginTop: '-20px' }}>
                                                        <Col lg="6">
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-is-recommended"
                                                            >
                                                                Di Rekomendasikan Ke User?
                                                            </label>
                                                            <Row style={{ marginTop: '10px' }}>
                                                                <Col md="5">
                                                                    <input
                                                                        id="is_recommended_yes"
                                                                        type="radio"
                                                                        name="is_broadcast_yes"
                                                                        style={{ marginRight: '5px' }}
                                                                        checked={inputValuesSub.is_recommended === '1'}
                                                                        disabled={(isUpdating) ? "disabled" : ""}
                                                                        onChange={e => {
                                                                            setInputValuesSub({ ['is_recommended']: '1' })
                                                                        }} />
                                                                    <label
                                                                        className="form-control-label"
                                                                        htmlFor="is_recommended_yes" style={{ fontSize: '13px' }}>Yes</label>
                                                                </Col>
                                                                <Col md="5">
                                                                    <input
                                                                        id="is_recommended"
                                                                        type="radio"
                                                                        name="is_broadcast_yes"
                                                                        style={{ marginRight: '5px' }}
                                                                        checked={inputValuesSub.is_recommended === '0'}
                                                                        disabled={(isUpdating) ? "disabled" : ""}
                                                                        onChange={e => {
                                                                            setInputValuesSub({ ['is_recommended']: '0' })
                                                                        }} />
                                                                    <label
                                                                        className="form-control-label"
                                                                        htmlFor="is_recommended_no" style={{ fontSize: '13px' }}>No</label>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </> :
                                                <>
                                                    <Row style={{ marginTop: '-20px' }}>
                                                        <Col lg="6">
                                                            <FormGroup>
                                                                <label
                                                                    className="form-control-label"
                                                                    htmlFor="input-company-percentage"
                                                                >
                                                                    Bagi Hasil Perusahaan
                                                                </label>
                                                                <Input
                                                                    className="form-control-alternative"
                                                                    id="input-company-percentage"
                                                                    name="company_percentage"
                                                                    type="number"
                                                                    step=".01"
                                                                    onChange={onChangeSubHandler}
                                                                    defaultValue={inputValuesSub.company_percentage}
                                                                    style={
                                                                        isSubmitUpdate && inputValuesSub.company_percentage === '' ?
                                                                            {
                                                                                color: 'black',
                                                                                border: '0.2px solid #d32f2f'
                                                                            }
                                                                            :
                                                                            {
                                                                                color: 'black',
                                                                                border: 'none'
                                                                            }
                                                                    }
                                                                    disabled={isSubmitUpdate ? 'disabled' : ''} />
                                                                <p style={{
                                                                    fontSize: '11px',
                                                                    color: '#d32f2f',
                                                                    marginTop: '5px',
                                                                    visibility: isSubmitUpdate && inputValuesSub.company_percentage === '' ? 'visible' : 'hidden'
                                                                }}>Harap isi bagi hasil perusahaan</p>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col lg="6">
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-is-recommended"
                                                            >
                                                                Di Rekomendasikan Ke User?
                                                            </label>
                                                            <Row style={{ marginTop: '10px' }}>
                                                                <Col md="5">
                                                                    <input
                                                                        id="is_recommended_yes"
                                                                        type="radio"
                                                                        name="is_broadcast_yes"
                                                                        style={{ marginRight: '5px' }}
                                                                        checked={inputValuesSub.is_recommended === '1'}
                                                                        disabled={(isUpdating) ? "disabled" : ""}
                                                                        onChange={e => {
                                                                            setInputValuesSub({ ['is_recommended']: '1' })
                                                                        }} />
                                                                    <label
                                                                        className="form-control-label"
                                                                        htmlFor="is_recommended_yes" style={{ fontSize: '13px' }}>Yes</label>
                                                                </Col>
                                                                <Col md="5">
                                                                    <input
                                                                        id="is_recommended"
                                                                        type="radio"
                                                                        name="is_broadcast_yes"
                                                                        style={{ marginRight: '5px' }}
                                                                        checked={inputValuesSub.is_recommended === '0'}
                                                                        disabled={(isUpdating) ? "disabled" : ""}
                                                                        onChange={e => {
                                                                            setInputValuesSub({ ['is_recommended']: '0' })
                                                                        }} />
                                                                    <label
                                                                        className="form-control-label"
                                                                        htmlFor="is_recommended_no" style={{ fontSize: '13px' }}>No</label>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </>
                                        }
                                        <Row style={{ marginTop: '10px' }}>
                                            <Col lg="8">
                                                <p style={{ fontSize: '13px', color: 'black ', fontWeight: 'bold' }}>Layanan Tambahan</p>
                                            </Col>
                                            <Col lg="4">
                                                <div
                                                    style={{
                                                        width: '100%',
                                                        border: `0.2px solid ${MaterialGreen1}`,
                                                        borderRadius: '6px',
                                                        textAlign: 'center',
                                                        fontSize: '12px',
                                                        color: isCreateAddHover ? 'white' : MaterialGreen1,
                                                        cursor: 'pointer',
                                                        transition: '0.2s',
                                                        paddingTop: '5px',
                                                        paddingBottom: '5px',
                                                        backgroundColor: isCreateAddHover ? MaterialGreen1 : 'white'
                                                    }}
                                                    onMouseOver={() => {
                                                        setCreateAddHover(true)
                                                    }}
                                                    onMouseLeave={() => {
                                                        setCreateAddHover(false)
                                                    }}
                                                    onClick={() => {
                                                        setInputValuesSubAdditional({
                                                            id: -1,
                                                            sub_service_id: inputValuesSub.id,
                                                            title: '',
                                                            amount: '',
                                                            additional_type: {
                                                                value: '',
                                                                label: ''
                                                            },
                                                            is_choice: '',
                                                            createdAt: '',
                                                            updatedAt: ''
                                                        })
                                                        setShowModalCreateSubAdd(true)
                                                    }}
                                                >
                                                    &#x2b; Tambah
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col style={{ height: '100%' }} lg="12">
                                                <div
                                                    style={{
                                                        marginTop: '5px'
                                                    }}
                                                >
                                                    {
                                                        inputValuesSub.sub_service_additionals.length > 0 ?
                                                            <div style={{
                                                                width: '100%',
                                                                overflow: 'auto',
                                                                whiteSpace: 'nowrap',
                                                                paddingTop: '10px',
                                                                paddingBottom: '10px'
                                                            }}>
                                                                {
                                                                    inputValuesSub.sub_service_additionals.map((items, index) => {
                                                                        return (
                                                                            <div style={{ display: 'inline-block' }}>
                                                                                <Row>
                                                                                    <Col lg="12">
                                                                                        <div style={{
                                                                                            backgroundColor: MaterialBlue1,
                                                                                            borderRadius: '6px',
                                                                                            paddingTop: '5px',
                                                                                            paddingLeft: '10px',
                                                                                            paddingRight: '10px',
                                                                                            marginRight: '10px',
                                                                                            fontSize: '14px',
                                                                                            color: 'white',
                                                                                            paddingBottom: '5px'
                                                                                        }}>
                                                                                            {items.title}
                                                                                        </div>
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row style={{ marginTop: '10px' }}>
                                                                                    <Col lg="12">
                                                                                        <div
                                                                                            style={{
                                                                                                display: 'inline-block',
                                                                                                width: '45.7%',
                                                                                                height: '100%',
                                                                                                borderTopLeftRadius: '6px',
                                                                                                borderBottomLeftRadius: '6px',
                                                                                                backgroundColor: MaterialBlue2,
                                                                                                textAlign: 'center',
                                                                                                color: 'white',
                                                                                                fontSize: '11px',
                                                                                                paddingTop: '5px',
                                                                                                paddingBottom: '5px',
                                                                                                cursor: 'pointer'
                                                                                            }}
                                                                                            onClick={() => {
                                                                                                const subAddPayload = {
                                                                                                    ...items,
                                                                                                    sub_add_index: index,
                                                                                                    amount: formatRupiah(items.amount.toString(), "Rp. ")
                                                                                                }
                                                                                                if (validateEmpty(items.additional_type)) {
                                                                                                    subAddPayload.additional_type = {
                                                                                                        value: items.additional_type,
                                                                                                        label: items.additional_type.charAt(0).toUpperCase() + items.additional_type.slice(1)
                                                                                                    }
                                                                                                }
                                                                                                setInputValuesSubAdditional(subAddPayload)
                                                                                                setShowModalSubAdd(true)
                                                                                            }}
                                                                                        >
                                                                                            Perbarui
                                                                                        </div>

                                                                                        <div
                                                                                            style={{
                                                                                                display: 'inline-block',
                                                                                                width: '45.7%',
                                                                                                height: '100%',
                                                                                                borderTopRightRadius: '6px',
                                                                                                borderBottomRightRadius: '6px',
                                                                                                backgroundColor: MaterialRed1,
                                                                                                textAlign: 'center',
                                                                                                color: 'white',
                                                                                                fontSize: '11px',
                                                                                                paddingTop: '5px',
                                                                                                paddingBottom: '5px',
                                                                                                cursor: 'pointer'
                                                                                            }}
                                                                                            onClick={() => {
                                                                                                setShowModalRemoveSubAdd(true)
                                                                                                setInputValuesSubAdditional({
                                                                                                    remove_sub_service_add_id: items.id,
                                                                                                    remove_sub_service_add_index: index
                                                                                                })
                                                                                            }}
                                                                                        >
                                                                                            Hapus
                                                                                        </div>
                                                                                    </Col>
                                                                                </Row>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                            :
                                                            <div style={{ width: '100%', height: '100%', textAlign: 'center', display: 'inline-block', verticalAlign: 'middle' }}>
                                                                Tidak ada layanan tambahan
                                                            </div>
                                                    }
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row style={{ marginTop: '20px' }}>
                                            <Col lg="12">
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="input-sub-service-description"
                                                >
                                                    Deskripsi
                                                </label>
                                                <Input
                                                    id="input-sub-service-description"
                                                    name="sub_service_description"
                                                    type="textarea"
                                                    onChange={onChangeSubHandler}
                                                    value={inputValuesSub.sub_service_description}
                                                    style={
                                                        isSubmitUpdate && inputValuesSub.sub_service_description === '' ?
                                                            {
                                                                height: '100px',
                                                                padding: '0.4em',
                                                                textAlign: 'left',
                                                                color: 'black',
                                                                border: '0.2px solid #d32f2f'
                                                            }
                                                            :
                                                            {
                                                                height: '100px',
                                                                padding: '0.4em',
                                                                textAlign: 'left',
                                                                color: 'black',
                                                                border: 'none'
                                                            }
                                                    } />
                                                {
                                                    isSubmitUpdate && inputValuesSub.sub_service_description === '' ?
                                                        <p style={{
                                                            fontSize: '11px',
                                                            color: '#d32f2f',
                                                            marginTop: '5px'
                                                        }}>Harap isi deskripsi harga</p>
                                                        : <div></div>
                                                }
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="12">
                                                <a onClick={() => updateSubService()}
                                                    style={{
                                                        pointerEvents: isUpdateLoading ? 'none' : 'visible',
                                                        cursor: isUpdateLoading ? 'not-allowed' : 'pointer',
                                                        width: '100%',
                                                        marginTop: '10px'
                                                    }}
                                                    className="btn btn-success btn-sm">
                                                    Simpan
                                                </a>
                                            </Col>
                                        </Row>
                                    </ModalBody>
                                </Modal>
                                <Modal isOpen={showModalSubAdd} closable={false} keyboard={false} centered>
                                    {
                                        isUpdateAddLoading ?
                                            <div style={{
                                                borderTopLeftRadius: '5px',
                                                borderTopRightRadius: '5px',
                                                height: '5px'
                                            }} className="progress-bar">
                                                <div className="progress-bar-value"></div>
                                            </div>
                                            : <div style={{ height: '5px' }}></div>
                                    }
                                    <ModalHeader toggle={handleOpenCloseAddModal}>
                                        <h4>Perbarui Layanan Tambahan</h4>
                                    </ModalHeader>
                                    <ModalBody>
                                        <Row style={{ marginTop: '-30px' }}>
                                            <Col lg="12">
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="input-sub-service-add-title"
                                                >
                                                    Judul
                                                </label>
                                                <Input
                                                    className="form-control-alternative"
                                                    id="input-sub-service-add-title"
                                                    name="title"
                                                    onChange={onChangeSubAdditionalHandler}
                                                    value={inputValuesSubAdditional.title}
                                                    style={
                                                        isSubmitAdd && inputValuesSubAdditional.title === '' ?
                                                            {
                                                                color: 'black',
                                                                border: '0.2px solid #d32f2f'
                                                            }
                                                            :
                                                            {
                                                                color: 'black',
                                                                border: 'none'
                                                            }
                                                    } />
                                                <p style={{
                                                    fontSize: '11px',
                                                    color: '#d32f2f',
                                                    marginTop: '5px',
                                                    visibility: isSubmitAdd && inputValuesSubAdditional.title === '' ? 'visible' : 'hidden'
                                                }}>Harap isi judul</p>
                                            </Col>
                                        </Row>
                                        <Row style={{ marginTop: '-20px' }}>
                                            <Col lg="12">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-select-type"
                                                    >
                                                        Tipe Layanan Tambahan
                                                    </label>
                                                    <Select
                                                        id="input-select-type"
                                                        options={options}
                                                        value={
                                                            inputValuesSubAdditional.additional_type
                                                        }
                                                        name="additional_type"
                                                        onChange={(selectedOption) => {
                                                            setInputValuesSubAdditional({ ['additional_type']: selectedOption })
                                                        }} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="12">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-sub-price-service"
                                                    >
                                                        Besaran Harga Layanan
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        id="input-sub-price-service"
                                                        name="amount"
                                                        onChange={onChangeSubAdditionalHandler}
                                                        value={inputValuesSubAdditional.amount === '' ? 'Rp. ' : formatRupiah(inputValuesSubAdditional.amount.toString(), "")}
                                                        style={
                                                            isSubmitAdd && inputValuesSubAdditional.amount === '' ?
                                                                {
                                                                    paddingLeft: '15px',
                                                                    color: 'black',
                                                                    border: '0.2px solid #d32f2f'
                                                                }
                                                                :
                                                                {
                                                                    paddingLeft: '15px',
                                                                    color: 'black',
                                                                    border: 'none'
                                                                }
                                                        } />
                                                    <p style={{
                                                        fontSize: '11px',
                                                        color: '#d32f2f',
                                                        marginTop: '5px',
                                                        visibility: isSubmitAdd && inputValuesSubAdditional.amount === '' ? 'visible' : 'hidden'
                                                    }}>Harap isi besaran harga</p>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row style={{ marginTop: '-20px' }}>
                                            <Col lg="12">
                                                <a
                                                    onClick={() => updateSubServiceAdd()}
                                                    style={{
                                                        pointerEvents: isUpdateAddLoading ? 'none' : 'visible',
                                                        cursor: isUpdateAddLoading ? 'not-allowed' : 'pointer',
                                                        width: '100%',
                                                        marginTop: '10px'
                                                    }}
                                                    className="btn btn-success btn-sm"
                                                >
                                                    Simpan
                                                </a>
                                            </Col>
                                        </Row>
                                    </ModalBody>
                                </Modal>
                                <Modal isOpen={showModalCreateSubAdd} closable={false} keyboard={false}
                                    centered
                                >
                                    {
                                        isCreateAddLoading ?
                                            <div style={{
                                                borderTopLeftRadius: '5px',
                                                borderTopRightRadiuis: '5px',
                                                height: '5px'
                                            }} className="progress-bar">
                                                <div className="progress-bar-value"></div>
                                            </div>
                                            : <div style={{ height: '5px' }}></div>
                                    }
                                    <ModalHeader toggle={handleOpenCloseCreateAddModal}>
                                        <h4>Tambah Layanan Tambahan</h4>
                                    </ModalHeader>
                                    <ModalBody>
                                        <Row style={{ marginTop: '-30px' }}>
                                            <Col lg="12">
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="input-sub-service-add-title"
                                                >
                                                    Judul
                                                </label>
                                                <Input
                                                    className="form-control-alternative"
                                                    id="input-sub-service-add-title"
                                                    name="title"
                                                    onChange={onChangeSubAdditionalHandler}
                                                    value={inputValuesSubAdditional.title}
                                                    style={
                                                        isSubmitAdd && inputValuesSubAdditional.title === '' ?
                                                            {
                                                                color: 'black',
                                                                border: '0.2px solid #d32f2f'
                                                            }
                                                            :
                                                            {
                                                                color: 'black',
                                                                border: 'none'
                                                            }
                                                    }
                                                    disabled={(isCreateAddLoading) ? "disabled" : ""} />
                                                <p style={{
                                                    fontSize: '11px',
                                                    color: '#d32f2f',
                                                    marginTop: '5px',
                                                    visibility: isSubmitAdd && inputValuesSubAdditional.title === '' ? 'visible' : 'hidden'
                                                }}>Harap isi judul</p>
                                            </Col>
                                        </Row>
                                        <Row style={{ marginTop: '-20px' }}>
                                            <Col lg="12">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-select-type"
                                                    >
                                                        Tipe Layanan Tambahan
                                                    </label>
                                                    <div style={
                                                        isSubmitAdd && !validateEmpty(inputValuesSubAdditional.additional_type.value) ?
                                                            {
                                                                color: 'black',
                                                                borderRadius: '5px',
                                                                border: '0.2px solid #d32f2f'
                                                            }
                                                            :
                                                            {
                                                                color: 'black',
                                                                borderRadius: '5px',
                                                                border: 'none'
                                                            }
                                                    } id="input-select-type">
                                                        <Select
                                                            options={options}
                                                            value={
                                                                inputValuesSubAdditional.additional_type
                                                            }
                                                            name="additional_type"
                                                            onChange={(selectedOption) => {
                                                                setInputValuesSubAdditional({ ['additional_type']: selectedOption })
                                                            }} />
                                                    </div>
                                                    <p style={{
                                                        fontSize: '11px',
                                                        color: '#d32f2f',
                                                        marginTop: '5px',
                                                        visibility: isSubmitAdd && !validateEmpty(inputValuesSubAdditional.additional_type.value) ? 'visible' : 'hidden'
                                                    }}>Harap isi tipe layanan tambahan</p>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row style={{ marginTop: '-20px' }}>
                                            <Col lg="12">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-sub-price-service"
                                                    >
                                                        Besaran Harga Layanan
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        id="input-sub-price-service"
                                                        name="amount"
                                                        onChange={onChangeSubAdditionalHandler}
                                                        value={!validateEmpty(inputValuesSubAdditional.amount) ? 'Rp. ' : formatRupiah(inputValuesSubAdditional.amount.toString(), "")}
                                                        style={
                                                            isSubmitAdd && !validateEmpty(inputValuesSubAdditional.amount) ?
                                                                {
                                                                    paddingLeft: '15px',
                                                                    color: 'black',
                                                                    border: '0.2px solid #d32f2f'
                                                                }
                                                                :
                                                                {
                                                                    paddingLeft: '15px',
                                                                    color: 'black',
                                                                    border: 'none'
                                                                }
                                                        }
                                                        disabled={(isCreateAddLoading) ? "disabled" : ""} />
                                                    <p style={{
                                                        fontSize: '11px',
                                                        color: '#d32f2f',
                                                        marginTop: '5px',
                                                        visibility: isSubmitAdd && inputValuesSubAdditional.amount === '' ? 'visible' : 'hidden'
                                                    }}>Harap isi besaran harga</p>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="12">
                                                <a
                                                    onClick={() => createSubServiceAdd()}
                                                    style={{
                                                        pointerEvents: isCreateAddLoading ? 'none' : 'visible',
                                                        cursor: isCreateAddLoading ? 'not-allowed' : 'pointer',
                                                        width: '100%',
                                                        marginTop: '10px'
                                                    }}
                                                    className="btn btn-success btn-sm"
                                                >
                                                    Simpan
                                                </a>
                                            </Col>
                                        </Row>
                                    </ModalBody>
                                </Modal>
                                <Modal isOpen={showModalRemoveSubService} closable={false} keyboard={false} centered
                                >
                                    {
                                        isSubmit ? isRemoveSubServiceLoading ?
                                            <div className="progress-bar">
                                                <div className="progress-bar-value"></div>
                                            </div> :
                                            <div style={{ height: '4px' }}></div> : <div></div>
                                    }
                                    <ModalHeader toggle={handleOpenCloseModalRemoveSubService}>
                                        <h4>Hapus Unit Layanan ?</h4>
                                    </ModalHeader>
                                    <ModalBody>
                                        <p style={{ marginTop: '-30px' }}>Kamu yakin ingin menghapus layanan <strong>{inputValuesSub.remove_sub_price_service_title} ?</strong>, ketik nama unit layanan yang ingin di hapus dan masukan kata sandi untuk konfirmasi</p>
                                        <Form onSubmit={(e) => {
                                            e.preventDefault()
                                            removeSubService()
                                        }}>
                                            <Row>
                                                <Col xl="12">
                                                    <Input
                                                        className="form-control-alternative"
                                                        type="text"
                                                        name="remove_sub_price_service_title_confirm"
                                                        placeholder="Ketik nama layanan di sini"
                                                        onChange={onChangeSubHandler}
                                                        value={inputValuesSub.remove_sub_price_service_title_confirm}
                                                        style={{
                                                            border: inputValuesSub.remove_sub_price_service_title_confirm === inputValuesSub.remove_sub_price_service_title ? 'none' : `1px solid ${RED_ERROR_MESSAGE}`
                                                        }}
                                                        disabled={isRemoveSubServiceLoading ? 'disabled' : ''} />
                                                </Col>
                                            </Row>
                                            <Row style={{ marginTop: '8px' }}>
                                                <Col xl="12">
                                                    <Input
                                                        className="form-control-alternative"
                                                        type="password"
                                                        name="remove_sub_price_service_password_confirm"
                                                        onChange={onChangeSubHandler}
                                                        placeholder="Ketik kata sandi di sini"
                                                        value={inputValuesSub.remove_sub_price_service_password_confirm}
                                                        style={{
                                                            border: validateEmpty(inputValuesSub.remove_sub_price_service_password_confirm) ? 'none' : `1px solid ${RED_ERROR_MESSAGE}`
                                                        }}
                                                        disabled={isRemoveSubServiceLoading ? 'disabled' : ''} />
                                                </Col>
                                            </Row>
                                            <Row style={{ marginTop: '8px' }}>
                                                <Col xl="12">
                                                    <button style={{
                                                        width: '100%',
                                                        backgroundColor: ((inputValuesSub.remove_sub_price_service_title === inputValuesSub.remove_sub_price_service_title_confirm) && validateEmpty(inputValuesSub.remove_sub_price_service_password_confirm)) ?
                                                            MaterialRed1 : MaterialGray1,
                                                        border: 'none'
                                                    }} className="btn btn-md btn-danger" disabled={(inputValuesSub.remove_sub_price_service_title === inputValuesSub.remove_sub_price_service_title_confirm) && validateEmpty(inputValuesSub.remove_sub_price_service_password_confirm) ? isRemoveSubServiceLoading ? 'disabled' : '' : 'disabled'}
                                                    >Hapus Unit Service</button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </ModalBody>
                                </Modal>
                                <Modal isOpen={showModalRemoveSubAdd} closable={false} keyboard={false} centered
                                >
                                    {
                                        isSubmit ? isRemoveAddLoading ?
                                            <div className="progress-bar">
                                                <div className="progress-bar-value"></div>
                                            </div> :
                                            <div style={{ height: '4px' }}></div> : <div></div>
                                    }
                                    <ModalHeader toggle={handleOpenCloseModalRemove}>
                                        <h4>Hapus Tambahan Layanan</h4>
                                    </ModalHeader>
                                    <ModalBody>
                                        <p style={{ marginTop: '-30px' }}>Kamu yakin ingin menghapus layanan ini ? Masukan kata sandi untuk konfirmasi</p>
                                        <Form onSubmit={(e) => {
                                            e.preventDefault()
                                            removeSubServiceAdd()
                                        }}>
                                            <Row style={{ marginTop: '8px' }}>
                                                <Col xl="12">
                                                    <Input
                                                        className="form-control-alternative"
                                                        type="password"
                                                        name="remove_sub_service_add_password_confirm"
                                                        onChange={onChangeSubAdditionalHandler}
                                                        placeholder="Ketik kata sandi di sini"
                                                        value={inputValues.remove_sub_service_add_password_confirm}
                                                        style={{
                                                            border: validateEmpty(inputValues.remove_sub_service_add_password_confirm) ? 'none' : `1px solid ${RED_ERROR_MESSAGE}`
                                                        }}
                                                        disabled={isRemoveAddLoading ? 'disabled' : ''} />
                                                </Col>
                                            </Row>
                                            <Row style={{ marginTop: '8px' }}>
                                                <Col xl="12">
                                                    <button style={{
                                                        width: '100%',
                                                        backgroundColor: validateEmpty(inputValuesSubAdditional.remove_sub_service_add_password_confirm) ?
                                                            MaterialRed1 : MaterialGray1,
                                                        border: 'none'
                                                    }} className="btn btn-md btn-danger" disabled={validateEmpty(inputValuesSubAdditional.remove_sub_service_add_password_confirm) ? isRemoveAddLoading ? 'disabled' : '' : 'disabled'}
                                                    >Hapus Tambahan Layanan</button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </ModalBody>
                                </Modal>
                            </div>
                        </Container>
                    </>
                )
            }
            case SERVICE_DETAIL_NOT_FOUND: {
                return (
                    <>

                    </>
                )
            }
            case SERVICE_DETAIL_FAILED: {
                return (
                    <>

                    </>
                )
            }
        }
    }

    return (
        <>
            <div>
                <div className="header bg-gradient-info pb-8 pt-5 pt-lg-7 d-flex align-items-center">
                    <span className="mask bg-gradient-default opacity-8" />
                </div>
                {buildServiceDetail()}
            </div>
        </>
    )
}

ServiceDetailPage.propTypes = {
    payload_service: PropTypes.object.isRequired,
    payload_sub_service: PropTypes.object.isRequired,
    payload_sub_service_add: PropTypes.object.isRequired,
    getServiceDetail: PropTypes.func.isRequired,
    updateServiceImage: PropTypes.func.isRequired,
    updateService: PropTypes.func.isRequired,
    createSubService: PropTypes.func.isRequired,
    updateSubService: PropTypes.func.isRequired,
    removeSubService: PropTypes.func.isRequired,
    createSubServiceAdditional: PropTypes.func.isRequired,
    updateSubServiceAdditional: PropTypes.func.isRequired,
    removeSubServiceAdditional: PropTypes.func.isRequired,
    resetCrud: PropTypes.func.isRequired,
    resetSubServiceAddCrud: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    payload_service: state.payload_service,
    payload_sub_service: state.payload_sub_service,
    payload_sub_service_add: state.payload_sub_service_add
})

export default connect(mapStateToProps, { getServiceDetail, updateServiceImage, updateService, createSubService, updateSubService, removeSubService, createSubServiceAdditional, updateSubServiceAdditional, removeSubServiceAdditional, resetCrud, resetSubServiceAddCrud })(ServiceDetailPage)