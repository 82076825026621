import { BASE_URL } from "actions/config";
import axios from 'axios';
import { passingHeaderToken } from "components/Utilities/Utility";

import {
    TOOLS_LIST_LOADING,
    TOOLS_LIST_SUCCESSFUL,
    TOOLS_LIST_NOT_FOUND,
    TOOLS_LIST_FAILED,

    TOOLS_MITRA_LOADING,
    TOOLS_MITRA_SUCCESSFUL,
    TOOLS_MITRA_NOT_FOUND,
    TOOLS_MITRA_FAILED,

    TOOLS_DETAIL_LOADING,
    TOOLS_DETAIL_SUCCESSFUL,
    TOOLS_DETAIL_NOT_FOUND,
    TOOLS_DETAIL_FAILED,

    TOOLS_CREATE_LOADING,
    TOOLS_CREATE_SUCCESSFUL,
    TOOLS_CREATE_FAILED,

    TOOLS_UPDATE_LOADING,
    TOOLS_UPDATE_SUCCESSFUL,
    TOOLS_UPDATE_NOT_FOUND,
    TOOLS_UPDATE_FAILED,

    TOOLS_REMOVE_LOADING,
    TOOLS_REMOVE_SUCCESSFUL,
    TOOLS_REMOVE_NOT_FOUND,
    TOOLS_REMOVE_FAILED,
    TOOLS_RESET_CRUD,

    SUBMIT_TOOLS_LOADING,
    SUBMIT_TOOLS_SUCCESSFUL,
    SUBMIT_TOOLS_FAILED
} from './Types';

export const getTools = (page , limit , token) => async dispatch => {
    try {
        dispatch({
            type : TOOLS_LIST_LOADING,
            message : 'Memuat daftar alat'
        })
        const response = await axios.get(`${BASE_URL}/api/tools/index?page=${page}&limit=${limit}` , passingHeaderToken(token))
        if(response.data != null && response.data.rows_data.docs.length > 0){
            dispatch({
                type : TOOLS_LIST_SUCCESSFUL,
                data : response.data
            })
        } else {
            dispatch({
                type : TOOLS_LIST_NOT_FOUND,
                message : 'Daftar alat tidak ada'
            })
        }
    } catch (err) {
        if(err.response !== undefined && err.response.status === 404){
            dispatch({
                type : TOOLS_LIST_NOT_FOUND,
                message : 'Daftar alat tidak'
            })
        } else {
            dispatch({
                type : TOOLS_LIST_FAILED,
                message : 'Gagal memuat daftar alat'
            })
        }
    }
}

export const getToolsByMitra = (mitra_id , page , limit , search , token ) => async dispatch => {
    console.log(`Mitra ID : ${mitra_id}`)
    console.log(`Token : ${token}`)
    console.log('URL : ' + `${BASE_URL}/api/tools/index_by_mitra/${mitra_id}?page=${page}&limit=${limit}&search=${search}`)
    try {
        dispatch({
            type : TOOLS_MITRA_LOADING,
            message : 'Memuat daftar alat'
        })
        const response = await axios.get(`${BASE_URL}/api/tools/index_by_mitra/${mitra_id}?page=${page}&limit=${limit}&search=${search}` , passingHeaderToken(token))
        console.log(`Resp : ${JSON.stringify(response.data, null , 2)}`)
        if(response.data != null && response.data.rows_data.docs.length > 0){
            dispatch({
                type : TOOLS_MITRA_SUCCESSFUL,
                data : response.data
            })
        } else {
            dispatch({
                type : TOOLS_MITRA_NOT_FOUND,
                message : 'Daftar alat tidak ada'
            })
        }
    } catch (err) {
        if(err.response !== undefined && err.response.status === 404){
            dispatch({
                type : TOOLS_MITRA_NOT_FOUND,
                message : 'Daftar alat tidak'
            })
        } else {
            dispatch({
                type : TOOLS_MITRA_FAILED,
                message : 'Gagal memuat daftar alat'
            })
        }
    }
}

export const submitMitraTools = (payload , token) => async dispatch => {
    try {
        dispatch({
            type : SUBMIT_TOOLS_LOADING,
            message : 'Memasukan data alat'
        })
        await axios.post(`${BASE_URL}/api/tools/submit_mitra_tools` , payload , passingHeaderToken(token))
        dispatch({
            type : SUBMIT_TOOLS_SUCCESSFUL,
            message : 'Berhasil memasukan data alat'
        })
    } catch (err) {
        dispatch({
            type : SUBMIT_TOOLS_FAILED,
            message : 'Gagal memasukan data alat'
        })
    }
}

export const createTools = (payload , token) => async dispatch => {
    try {
        dispatch({
            type : TOOLS_CREATE_LOADING,
            message : 'Membuat data alat'
        })
        await axios.post(`${BASE_URL}/api/tools/create` , payload , passingHeaderToken(token))
        dispatch({
            type : TOOLS_CREATE_SUCCESSFUL,
            message : 'Berhasil membuat data alat'
        })
    } catch (err) {
        dispatch({
            type : TOOLS_CREATE_FAILED,
            message : 'Gagal membuat data alat'
        })
    }
}

export const updateTools = (id , payload , token) => async dispatch => {
    try {
        dispatch({
            type : TOOLS_UPDATE_LOADING,
            message : 'Memperbarui data alat'
        })
        await axios.put(`${BASE_URL}/api/tools/update/${id}` , payload , passingHeaderToken(token))
        dispatch({
            type : TOOLS_UPDATE_SUCCESSFUL,
            message : 'Berhasil memperbarui data alat'
        })
    } catch (err) {
        if(err.response !== undefined && err.response.status == 404){
            dispatch({
                type : TOOLS_UPDATE_NOT_FOUND,
                message : 'Data alat tidak ada'
            })
        } else {
            dispatch({
                type : TOOLS_UPDATE_FAILED,
                message : 'Gagal memperbarui data alat'
            })
        }
    }
}

export const removeTools = (id , token) => async dispatch => {
    try {
        dispatch({
            type : TOOLS_REMOVE_LOADING,
            message : 'Menghapus data alat'
        })
        await axios.delete(`${BASE_URL}/api/tools/remove/${id}` , passingHeaderToken(token))
        dispatch({
            type : TOOLS_REMOVE_SUCCESSFUL,
            message : 'Berhasil menghapus data bantuan'
        })
    } catch (err) {
        if(err.response !== undefined && err.response.status == 404){
            dispatch({
                type : TOOLS_REMOVE_NOT_FOUND , 
                message : 'Tidak ada data alat'
            })
        } else {
            dispatch({
                type : TOOLS_REMOVE_FAILED,
                message : 'Gagal menghapus data alat'
            })
        }
    }
}

export const resetCrud = () => async dispatch => {
    dispatch({
        type : TOOLS_RESET_CRUD,
        message : ''
    })
}