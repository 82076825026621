import { BASE_URL } from 'actions/config';
import axios from 'axios';
import { 
    BANNER_LIST_LOADING , 
    BANNER_LIST_SUCCESSFUL , 
    BANNER_LIST_NOT_FOUND , 
    BANNER_LIST_FAILED, 
    BANNER_DETAIL_LOADING, 
    BANNER_DETAIL_SUCCESSFUL, 
    BANNER_DETAIL_NOT_FOUND, 
    BANNER_DETAIL_FAILED, 
    UPDATE_BANNER_LOADING, 
    UPDATE_BANNER_SUCCESSFUL, 
    UPDATE_BANNER_NOT_FOUND, 
    UPDATE_BANNER_FAILED, 
    CLEAR_BANNER_CREATE_UPDATE_STATE, 
    CREATE_BANNER_LOADING, 
    CREATE_BANNER_SUCCESSFUL, 
    CREATE_BANNER_FAILED ,
    BANNER_RESET_CRUD,

    REMOVE_BANNER_LOADING,
    REMOVE_BANNER_SUCCESSFUL,
    REMOVE_BANNER_NOT_FOUND,
    REMOVE_BANNER_FAILED
} from './Types';
import { passingHeaderToken } from 'components/Utilities/Utility';
import { passingHeaderTokenFormData } from 'components/Utilities/Utility';

export const getBannerList = (page , limit , token) => async dispatch => {
    try {
        dispatch({
            type : BANNER_LIST_LOADING,
            payload : {
                type : BANNER_LIST_LOADING,
                message : 'Memuat Daftar Banner'
            }
        })
        const response = await axios.get(`${BASE_URL}/api/banners/index?page=${page}&limit=${limit}` , passingHeaderToken(token))
        if(response.data.rows_data.docs.length > 0){
            setTimeout(() => {
                dispatch({
                    type : BANNER_LIST_SUCCESSFUL,
                    payload : {
                        type : BANNER_LIST_SUCCESSFUL,
                        data : response.data
                    }
                })
            } , 300)
        } else {
            setTimeout(() => {
                dispatch({
                    type : BANNER_LIST_NOT_FOUND,
                    payload : {
                        type : BANNER_LIST_NOT_FOUND,
                        message : 'Data banner tidak ada'
                    }
                })
            })
        }
    } catch (err) {
        console.log(err)
        if(err.response.status == 404){
            dispatch({
                type : BANNER_LIST_NOT_FOUND,
                payload : {
                    type : BANNER_LIST_NOT_FOUND,
                    message : 'Tidak Ada Data Banner'
                }
            })
        } else {
            dispatch({
                type : BANNER_LIST_FAILED,
                payload : {
                    type : BANNER_LIST_FAILED,
                    message : 'Gagal Memuat Data Banner'
                }
            })
        }
    }
}

export const getBannerDetail = (banner_id , token) => async dispatch => {
    try {
        dispatch({
            type : BANNER_DETAIL_LOADING,
            payload : {
                type : BANNER_DETAIL_LOADING,
                message : 'Memuat Detail Banner'
            }
        })
        const response = await axios.get(`${BASE_URL}/api/banners/get/${banner_id}` , passingHeaderToken(token))
        console.log(response)
        setTimeout(() => {
            dispatch({
                type : BANNER_DETAIL_SUCCESSFUL,
                payload : {
                    type : BANNER_DETAIL_SUCCESSFUL,
                    data : response.data
                }
            })
        } , 250)
        
    } catch (err) {
        if(err.response.status == 404){
            dispatch({
                type : BANNER_DETAIL_NOT_FOUND,
                payload : {
                    type : BANNER_DETAIL_NOT_FOUND,
                    message : 'Data Banner Tidak Ditemukan'
                }
            })
        } else {
            dispatch({
                type : BANNER_DETAIL_FAILED,
                payload : {
                    type : BANNER_DETAIL_FAILED,
                    message : 'Gagal Memuat Data Banner'
                }
            })
        }
    }
}


export const createBanner = (form_data , token) => async dispatch => {
    try {
        dispatch({
            type : CREATE_BANNER_LOADING,
            payload : {
                type : CREATE_BANNER_LOADING,
                message : 'Membuat Data Banner'
            }
        })
        const response = await axios.post(`${BASE_URL}/api/banners/create` , form_data , passingHeaderTokenFormData(token))
        dispatch({
            type : CREATE_BANNER_SUCCESSFUL,
            payload : {
                type : CREATE_BANNER_SUCCESSFUL,
                message : 'Berhasil Membuat Banner'
            }
        })
    } catch (err) {
        dispatch({
            type : CREATE_BANNER_FAILED,
            payload : {
                type : CREATE_BANNER_FAILED,
                message : 'Gagal Membuat Banner'
            }
        })
    }
}

export const updateBannerDetailImage = (banner_id , form_data , token) => async dispatch => {
    try {
        dispatch({
            type : UPDATE_BANNER_LOADING,
            payload : {
                type : UPDATE_BANNER_LOADING,
                message : 'Memperbarui Data Banner'
            }
        })
        const response = await axios.put(`${BASE_URL}/api/banners/update/image/${banner_id}` , form_data , passingHeaderTokenFormData(token))
        console.log(response)
        dispatch({
            type : UPDATE_BANNER_SUCCESSFUL,
            payload : {
                type : UPDATE_BANNER_SUCCESSFUL,
                message : 'Berhasil Memperbarui Banner'
            }
        })
    } catch (err) {
        if(err.response.code == 404){
            dispatch({
                type : UPDATE_BANNER_NOT_FOUND,
                payload : {
                    type : UPDATE_BANNER_NOT_FOUND,
                    message : 'Data Banner Tidak Ada'
                }
            })
        } else {
            dispatch({
                type : UPDATE_BANNER_FAILED,
                payload : {
                    type : UPDATE_BANNER_FAILED,
                    message : 'Gagal Memperbarui Data Banner'
                }
            })
        }
    }
}

export const updateBannerDetail = (banner_id , data , token) => async dispatch => {
    try {
        dispatch({
            type : UPDATE_BANNER_LOADING,
            payload : {
                type : UPDATE_BANNER_LOADING,
                message : 'Memperbarui Data Banner'
            }
        })
        const response = await axios.put(`${BASE_URL}/api/banners/update/${banner_id}` , data, passingHeaderToken(token))
        console.log(response)
        dispatch({
            type : UPDATE_BANNER_SUCCESSFUL,
            payload : {
                type : UPDATE_BANNER_SUCCESSFUL,
                message : 'Berhasil Memperbarui Data Banner'
            }
        })
    } catch (err) {
        if(err.response.code == 404){
            dispatch({
                type : UPDATE_BANNER_NOT_FOUND,
                payload : {
                    type : UPDATE_BANNER_NOT_FOUND,
                    message : 'Data Banner Tidak Ada'
                }
            })
        } else {
            dispatch({
                type : UPDATE_BANNER_FAILED,
                payload : {
                    type : UPDATE_BANNER_FAILED,
                    message : 'Gagal Memperbarui Data Banner'
                }
            })
        }
    }
}

export const removeBanner = (id , token) => async dispatch => {
    try {
        dispatch({
            type : REMOVE_BANNER_LOADING,
            message : 'Menghapus banner'
        })
        await axios.delete(`${BASE_URL}/api/banners/remove/${id}` , passingHeaderToken(token))
        dispatch({
            type : REMOVE_BANNER_SUCCESSFUL,
            message : 'Berhasil menghapus banner'
        })
    } catch (err) {
        if(err.response !== undefined && err.response.status === 404){
            dispatch({
                type : REMOVE_BANNER_NOT_FOUND,
                message : 'Data banner tidak ada'
            })
        } else {
            dispatch({
                type : REMOVE_BANNER_FAILED,
                message : 'Gagal menghapus data banner'
            })
        }
    }
}

export const setBannerEmpty = () => async dispatch => {
    dispatch({
        type : BANNER_LIST_NOT_FOUND,
        message : 'Data banner kosong'
    })
}

export const resetCrud = () => async dispatch => {
    dispatch({
        type : BANNER_RESET_CRUD,
        message : 'Reset crud'
    })
}