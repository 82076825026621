import { RED_ERROR_MESSAGE } from "./ResourceColors";

export const formatRupiah = (rupiah, prefix) => {
    var number_string = rupiah.replace(/[^,\d]/g, '').toString(),
        split = number_string.split(','),
        sisa = split[0].length % 3,
        rupiah = split[0].substr(0, sisa),
        ribuan = split[0].substr(sisa).match(/\d{3}/gi)
    let separator = ""
    if (ribuan) {
        separator = sisa ? '.' : '';
        rupiah += separator + ribuan.join('.')
    }
    rupiah = split[1] !== undefined ? rupiah + ',' + split[1] : rupiah;
    return prefix === undefined ? rupiah : (rupiah ? 'Rp. ' + rupiah : '')
}


export const validateErrorMessage = (valid) => {
    return valid ? {
        border: `0.2px solid ${RED_ERROR_MESSAGE}`
    } : {
        border: 'none'
    }
}

export const validateEmpty = (data) => {
    return data !== '' && data != null && data !== undefined
}

export const passingHeaderToken = (token) => {
    return {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        }
    }
}

export const passingHeaderTokenFormData = (token) => {
    return {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Accept': 'application/json',
            'Authorization': token
        }
    }
}

export let erorrMsgStyle = {
    fontSize: '11px',
    color: RED_ERROR_MESSAGE
}

const monthMappingLetters = {
    1: {
        month: 'Januari'
    },
    2: {
        month: 'Feberuari'
    },
    3: {
        month: 'Maret'
    },
    4: {
        month: 'April'
    },
    5: {
        month: 'Mei'
    },
    6: {
        month: 'Juni'
    },
    7: {
        month: 'Juli'
    },
    8: {
        month: 'Agustus'
    },
    9: {
        month: 'September'
    },
    10: {
        month: 'Oktober'
    },
    11: {
        month: 'November'
    },
    12: {
        month: 'Desember'
    }
}

export const convertNumberToMonthString = (number) => {
    for (let key in monthMappingLetters) {
        if (monthMappingLetters.hasOwnProperty(key)) {
            if (parseInt(number) == parseInt(key)) {
                return monthMappingLetters[key].month
            }
        }
    }
}

export const validateYMDFormat = (dateString) => {
    var regEx = /^\d{4}-\d{2}-\d{2}$/;
    return dateString.match(regEx) != null;
}

export const timeFormat = /^(0[9]|1[0-6]):[0-5]\d$/