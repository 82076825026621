export const SERVICE_LIST_LOADING = 'SERVICE_LIST_LOADING';
export const SERVICE_LIST_SUCCESSFUL = 'SERVICE_LIST_SUCCESSFUL';
export const SERVICE_LIST_NOT_FOUND = 'SERVICE_LIST_NOT_FOUND';
export const SERVICE_LIST_FAILED = 'SERVICE_LIST_FAILED';

export const SERVICE_DETAIL_LOADING = 'SERVICE_DETAIL_LOADING';
export const SERVICE_DETAIL_SUCCESSFUL = 'SERVICE_DETAIL_SUCCESSFUL';
export const SERVICE_DETAIL_NOT_FOUND = 'SERVICE_DETAIL_NOT_FOUND';
export const SERVICE_DETAIL_FAILED = 'SERVICE_DETAIL_FAILED';

export const SERVICE_CREATE_LOADING = 'SERVICE_CREATE_LOADING';
export const SERVICE_CREATE_SUCCESSFUL = 'SERVICE_CREATE_SUCCESSFUL';
export const SERVICE_CREATE_FAILED = 'SERVICE_CREATE_FAILED';

export const SERVICE_UPDATE_LOADING = 'SERVICE_UPDATE_LOADING';
export const SERVICE_UPDATE_SUCCESSFUL = 'SERVICE_UPDATE_SUCCESSFUL';
export const SERVICE_UPDATE_NOT_FOUND = 'SERVICE_UPDATE_NOT_FOUND';
export const SERVICE_UPDATE_FAILED = 'SERVICE_UPDATE_FAILED';

export const SERVICE_REMOVE_LOADING = 'SERVICE_REMOVE_LOADING';
export const SERVICE_REMOVE_SUCCESSFUL = 'SERVICE_REMOVE_SUCCESSFUL';
export const SERVICE_REMOVE_UNAUTHORIZED = 'SERVICE_REMOVE_UNAUTHORIZED';
export const SERVICE_REMOVE_NOT_FOUND = 'SERVICE_REMOVE_NOT_FOUND';
export const SERVICE_REMOVE_FAILED = 'SERVICE_REMOVE_FAILED';