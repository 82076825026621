import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
    Container,
    Card,
    Row,
    Col,
    CardBody,
    Form,
    FormGroup,
    Input
} from 'reactstrap';
import { useEffect, useRef, useReducer, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { updateNews, updateNewsImage, getDetailNews, resetCrud } from 'actions/News/NewsService';
import {
    NEWS_DETAIL_LOADING,
    NEWS_DETAIL_SUCCESSFUL,
    NEWS_DETAIL_NOT_FOUND,
    NEWS_DETAIL_FAILED,

    NEWS_UPDATE_LOADING,
    NEWS_UPDATE_SUCCESSFUL,
    NEWS_UPDATE_NOT_FOUND,
    NEWS_UPDATE_FAILED
} from 'actions/News/Types';
import '../../../assets/css/utilities.css';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import withReactContent from 'sweetalert2-react-content';
import { MaterialGray1, MaterialGray2 } from 'components/Utilities/ResourceColors';
import { MaterialRed1 } from 'components/Utilities/ResourceColors';
import Select from 'react-select';
import IcNotFound from 'assets/img/ic_not_found.svg';
import IcError from 'assets/img/ic_error.svg';
import { RED_ERROR_MESSAGE } from 'components/Utilities/ResourceColors';
import { erorrMsgStyle } from 'components/Utilities/Utility';
import Skeleton from 'react-loading-skeleton';
import NotFoundPage from 'components/NotFound/NotFoundPage';
import ErrorPage from 'components/ErrorComponent/ErrorPage';
import { validateEmpty } from 'components/Utilities/Utility';
import { BASE_URL } from 'actions/config';
import { MaterialBlue3 } from 'components/Utilities/ResourceColors';
import CKEditor from "react-ckeditor-component";

const MySwal = withReactContent(Swal)

const NewsDetailPage = (props) => {
    const [token, setToken] = useState('')
    const [convertedContent, setConvertedContent] = useState(null);
    const options = [
        {
            value: 'Suberes Update', label: 'Suberes Update'
        },
        {
            value: 'News', label: 'News'
        }
    ]
    const mainContent = useRef(null)
    const location = useLocation()
    const history = useHistory()
    const fileRef = useRef()
    const [isBroadcast, setBroadcast] = useState(false)
    const [isSubmit, setSubmit] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const [selectedFile, setSelectedFile] = useState(null)
    const [newsImage, setNewsImage] = useState(null)
    const [imgProper, setImgProper] = useState(false)
    const [inputValues, setInputValues] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            news_title: '',
            news_body: '',
            news_body_has_text: false,
            news_type: {
                label: 'Suberes Update',
                value: 'Suberes Update'
            },
            creator_name : '',
            narasumber: '',
            news_image: '',
        }
    )
    useEffect(() => {
        setToken(localStorage.getItem('token'))
        if (token !== '') {
            props.getDetailNews(props.location.state.news_id, token)
        }
    }, [token])
    const onChangeHandler = event => {
        const { name, value } = event.target
        setInputValues({ [name]: value })
    }
    useEffect(() => {
        console.log(JSON.stringify(props.news.payload.data, null, 2))
        if (props.news.payload.data !== undefined) {
            for (const key in props.news.payload.data) {
                if (props.news.payload.data.hasOwnProperty(key)) {
                    if (key === 'news_type') {
                        setInputValues({
                            ['news_type']: {
                                value: props.news.payload.data[key],
                                label: props.news.payload.data[key]
                            }
                        })
                    } else if (key === 'news_body') {
                        setInputValues({ [key]: props.news.payload.data[key] })
                        setInputValues({ ['news_body_has_text']: validateEmpty(props.news.payload.data[key]) })
                    } else if (key === 'is_broadcast') {
                        setBroadcast(validateEmpty(props.news.payload.data[key]) ? props.news.payload.data[key] === '1' ? true : false : false)
                    } else {
                        setInputValues({ [key]: props.news.payload.data[key] })
                    }
                }
            }
        }
        buildNewsDetailPage()
    }, [props.news.payload])
    const onBlur = (evt) => {
        console.log('onBlur')
    }
    const afterPaste = (evt) => {
        console.log('after paste')
    }
    const onChange = (evt) => {
        setInputValues({ ['news_body']: evt.editor.getData() })
    }
    const onChangeImageHandler = event => {
        var img = new Image()
        setNewsImage(URL.createObjectURL(event.target.files[0]))
        img.onload = function () {
            if (img.width == 1280 && img.height == 720) {
                setSelectedFile(event.target.files[0])
                setImgProper(true)
            } else {
                setImgProper(false)
            }
        }
        img.src = URL.createObjectURL(event.target.files[0])
    }
    function validateBorderImg() {
        if (isSubmit) {
            if (newsImage == null || (newsImage != null && !imgProper)) {
                return `1px dashed ${RED_ERROR_MESSAGE}`
            }
        } else {
            if (newsImage != null && !imgProper) {
                return `1px dashed ${RED_ERROR_MESSAGE}`
            }
        }
    }
    function validateTextImg() {
        if (isSubmit) {
            if (newsImage == null) {
                if (!validateEmpty(inputValues.news_image)) {
                    return (
                        <p style={
                            erorrMsgStyle
                        }>Gambar Berita must be filled</p>
                    )
                }
            } else if (newsImage != null && !imgProper) {
                return (
                    <p style={
                        erorrMsgStyle
                    }>Gambar Berita must be 1280px X 720px</p>
                )
            } else {
                return (<></>)
            }
        } else {
            if (newsImage != null && !imgProper) {
                return (
                    <p style={
                        erorrMsgStyle
                    }>Gambar Berita must be 1280px X 720px</p>
                )
            } else {
                return (<></>)
            }
        }
    }
    useEffect(() => {
        if (!localStorage.getItem('token')) {
            history.push('/auth/login')
        }
    }, [location])
    useEffect(() => {
        switch (props.news.payload_crud.type) {
            case NEWS_UPDATE_LOADING:
                setLoading(true)
                break;
            case NEWS_UPDATE_SUCCESSFUL:
                setLoading(false)
                Swal.fire(
                    'Status',
                    'Berhasil memperbarui data berita',
                    'success'
                )
                setSelectedFile(null)
                setNewsImage(null)
                if (props.location.state !== undefined && token !== '')
                    props.getDetailNews(props.location.state.news_id, token)
                props.resetCrud()
                break;
            case NEWS_UPDATE_NOT_FOUND:
                setLoading(false)
                Swal.fire(
                    'Perhatian',
                    'Tidak ada data berita',
                    'warning'
                )
                break;
            case NEWS_UPDATE_FAILED:
                setLoading(false)
                Swal.fire(
                    'Perhatian',
                    'Gagal memperbarui berita',
                    'error'
                )
                break;
        }
    }, [props.news.payload_crud])

    function updateNews() {
        setSubmit(true)
        const {
            news_title,
            news_body,
            narasumber
        } = inputValues
        const news_type = inputValues.news_type.value
        if (token !== '' && props.location.state !== undefined &&
            props.location.state.news_id !== undefined &&
            news_title !== '' &&
            narasumber !== '' &&
            inputValues.news_body_has_text && news_type !== '') {
            let banner_type_selected = inputValues.news_type.value
            const payload_json = {
                news_title: news_title,
                news_body: news_body,
                news_type: banner_type_selected,
                narasumber: narasumber,
                is_broadcast: isBroadcast ? '1' : '0',
                timezone_code: Intl.DateTimeFormat().resolvedOptions().timeZone
            }
            if (selectedFile != null) {
                const formData = new FormData()
                formData.append('json_data', JSON.stringify(payload_json))
                formData.append('file', selectedFile)
                if (token !== '') {
                    props.updateNewsImage(props.location.state.news_id, formData, token)
                }
            } else {
                props.updateNews(props.location.state.news_id, payload_json, token)
            }
        }
    }

    useEffect(() => {
        console.log(`Val : ${JSON.stringify(inputValues.news_body, null, 2)}`)
    }, [inputValues])

    function buildNewsDetailPage() {
        switch (props.news.payload.type) {
            case NEWS_DETAIL_LOADING:
                return (
                    <>
                        <Container className="mt--7" fluid>
                            <Row>
                                <Col className="order-xl-1" xl="12">
                                    <Card className="bg-secondary shadow">
                                        <CardBody>
                                            <Form>
                                                <div>
                                                    <Row>
                                                        <Col lg="12">
                                                            <FormGroup>
                                                                <Skeleton style={{ height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col lg="12">
                                                            <Skeleton style={{ height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <Skeleton style={{ marginTop: '30px', height: '200px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col lg="12">
                                                            <FormGroup>
                                                                <Skeleton style={{ marginTop: '30px', height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Form>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </>
                )
            case NEWS_DETAIL_SUCCESSFUL:
                return (
                    <>
                        <Container className="mt--7" fluid>
                            <Row>
                                <Col className="order-xl-1" xl="12">
                                    <Card className="bg-secondary-shadow">
                                        {
                                            isLoading ?
                                                <div style={{
                                                    borderTopLeftRadius: '5px',
                                                    borderTopRightRadius: '5px',
                                                    height: '5px'
                                                }} className="progress-bar">
                                                    <div className="progress-bar-value"></div>
                                                </div>
                                                : <div style={{
                                                    height: '5px'
                                                }}></div>
                                        }
                                        <CardBody>
                                            <Form>
                                                <div>
                                                    <Row>
                                                        <Col md="6">
                                                            <Row>
                                                                <Col lg="12">
                                                                    <FormGroup>
                                                                        <label
                                                                            className="form-control-label"
                                                                            htmlFor="input-title">
                                                                            Judul Berita
                                                                        </label>
                                                                        <Input
                                                                            className="form-control-alternative"
                                                                            id="input-title"
                                                                            type="text"
                                                                            name="news_title"
                                                                            onChange={onChangeHandler}
                                                                            value={inputValues.news_title}
                                                                            style={
                                                                                isSubmit && inputValues.news_title === '' ?
                                                                                    {
                                                                                        color: 'black',
                                                                                        border: '0.2px solid #d32f2f'
                                                                                    }
                                                                                    :
                                                                                    {
                                                                                        color: 'black',
                                                                                        border: 'none'
                                                                                    }
                                                                            }
                                                                            disabled={isLoading && validateEmpty(inputValues.news_title) ? true : false} />
                                                                        <p style={{
                                                                            color: MaterialRed1,
                                                                            fontSize: '11px',
                                                                            marginTop: '5px',
                                                                            visibility: isSubmit && inputValues.news_title === '' ? 'visible' : 'hidden'
                                                                        }}>Judul Berita must be filled</p>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row style={{ marginTop: '-30px' }}>
                                                                <Col lg="6">
                                                                    <FormGroup>
                                                                        <label
                                                                            className="form-control-label"
                                                                            htmlFor="input-select-type"
                                                                        >
                                                                            Tipe Berita
                                                                        </label>
                                                                        <Select
                                                                            id="input-select-type"
                                                                            options={options}
                                                                            value={
                                                                                inputValues.news_type
                                                                            }
                                                                            name="news_type"
                                                                            onChange={(selectedOption) => {
                                                                                setInputValues({ ['news_type']: selectedOption })
                                                                            }}
                                                                            disabled={isLoading && validateEmpty(inputValues.news_type.value) ? true : false} />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col lg="6">
                                                                    <label
                                                                        className="form-control-label"
                                                                    >
                                                                        Blast Berita?
                                                                    </label>
                                                                    <Row>
                                                                        <Col md="5">
                                                                            <input
                                                                                id="is_broadcast"
                                                                                type="radio"
                                                                                name="is_broadcast_yes"
                                                                                style={{ marginRight: '5px' }}
                                                                                checked={isBroadcast === true}
                                                                                onChange={e => {
                                                                                    setBroadcast(true)
                                                                                }} />
                                                                            <label
                                                                                className="form-control-label"
                                                                                htmlFor="is_broadcast" style={{ fontSize: '13px' }}>Yes</label>
                                                                        </Col>
                                                                        <Col md="5">
                                                                            <input
                                                                                id="is_broadcast_no"
                                                                                type="radio"
                                                                                name="is_broadcast_no"
                                                                                style={{ marginRight: '5px' }}
                                                                                checked={isBroadcast === false}
                                                                                onChange={e => {
                                                                                    setBroadcast(false)
                                                                                }} />
                                                                            <label
                                                                                className="form-control-label"
                                                                                htmlFor="is_broadcast_no" style={{ fontSize: '13px' }}>No</label>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col lg="12">
                                                                    <FormGroup>
                                                                        <label
                                                                            className="form-control-label"
                                                                            htmlFor="input-narasumber"
                                                                        >
                                                                            Pembuat Berita
                                                                        </label>
                                                                        <Input
                                                                            type="text"
                                                                            id="input-creator-name"
                                                                            name="creator_name"
                                                                            defaultValue={inputValues.creator_name}
                                                                            style={{
                                                                                textAlign: 'left',
                                                                                color: 'black'
                                                                            }} disabled="disabled" />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col lg="12">
                                                                    <FormGroup>
                                                                        <label
                                                                            className="form-control-label"
                                                                            htmlFor="input-narasumber"
                                                                        >
                                                                            Narasumber Berita
                                                                        </label>
                                                                        <Input
                                                                            type="text"
                                                                            id="input-narasumber"
                                                                            name="narasumber"
                                                                            defaultValue={inputValues.narasumber}
                                                                            style={{
                                                                                textAlign: 'left',
                                                                                color: 'black'
                                                                            }}
                                                                            onChange={onChangeHandler}
                                                                            disabled={isLoading && validateEmpty(inputValues.narasumber) ? true : false} />
                                                                        <p style={{
                                                                            color: MaterialRed1,
                                                                            fontSize: '11px',
                                                                            marginTop: '5px',
                                                                            visibility: isSubmit && inputValues.narasumber === '' ? 'visible' : 'hidden'
                                                                        }}>Narasumber must be filled</p>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col lg="6">
                                                            <div>
                                                                <label
                                                                    className="form-control-label">Gambar Berita</label>
                                                                {
                                                                    <div style={{
                                                                        alignItems: 'center',
                                                                        display: 'flex',
                                                                        justifyContent: 'center',
                                                                        border: newsImage === null ? '1px dashed black' : '1px solid transparent',
                                                                        borderRadius: '10px'
                                                                    }}>
                                                                        {
                                                                            newsImage === null ?
                                                                                <div style={{
                                                                                    alignItems: 'center',
                                                                                    display: 'flex',
                                                                                    width: '100%',
                                                                                    height: '215px',
                                                                                    border: validateBorderImg(),
                                                                                    borderRadius: '10px',
                                                                                    justifyContent: 'center'
                                                                                }}>
                                                                                    <img src={`${BASE_URL}/api/images/${inputValues.news_image}`} style={{ textAlign: 'center', width: '100%', height: '100%', borderRadius: '10px' }} />
                                                                                </div>
                                                                                :
                                                                                <div style={{
                                                                                    width: '100%',
                                                                                    height: '215px',
                                                                                    border: validateBorderImg(),
                                                                                    borderRadius: '10px',
                                                                                    alignItems: 'center',
                                                                                    justifyContent: 'center',
                                                                                    textAlign: 'center'
                                                                                }}>
                                                                                    <div style={{
                                                                                        width: '25px',
                                                                                        height: '25px',
                                                                                        borderRadius: '50%',
                                                                                        backgroundColor: MaterialRed1,
                                                                                        position: 'absolute',
                                                                                        right: 8,
                                                                                        top: 22,
                                                                                        cursor: 'pointer'
                                                                                    }}
                                                                                        onClick={() => {
                                                                                            setSelectedFile(null)
                                                                                            setNewsImage(null)
                                                                                        }}
                                                                                    >
                                                                                        <p style={{ color: 'white' }}>&times;</p>
                                                                                    </div>
                                                                                    <img src={newsImage} style={{
                                                                                        width: '100%',
                                                                                        objectFit: 'cover',
                                                                                        borderRadius: '10px',
                                                                                        height: '215px',
                                                                                        display: 'inline-block'
                                                                                    }} />
                                                                                </div>
                                                                        }
                                                                    </div>
                                                                }
                                                                <Row>
                                                                    <Col sm="8">
                                                                        <p style={{ fontSize: '13px', marginTop: '10px' }}>
                                                                            Ukuran Gambar 1280px X 720px
                                                                        </p>
                                                                    </Col>
                                                                    <Col sm="4">
                                                                        <div>
                                                                            <input type="file" onChange={onChangeImageHandler} name="image_picker_change" ref={fileRef} hidden />
                                                                            <button onClick={(e) => {
                                                                                e.preventDefault()
                                                                                if (!isLoading) {
                                                                                    fileRef.current.click()
                                                                                }
                                                                            }} style={{
                                                                                marginTop: '10px',
                                                                                width: '100%',
                                                                                pointerEvents: isLoading ? 'none' : 'auto',
                                                                                backgroundColor: isLoading ? MaterialGray1 : MaterialBlue3,
                                                                                borderColor: isLoading ? MaterialGray1 : MaterialBlue3
                                                                            }} className="btn btn-sm btn-primary">Ambil gambar</button>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col xl="12">
                                                                        {validateTextImg()}
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </Col>
                                                        <Col lg="12">
                                                            <Row style={{ marginTop: '15px' }}>
                                                                <Col style={{ height: '400px' }} lg="12">
                                                                    <FormGroup>
                                                                        <label
                                                                            className="form-control-label"
                                                                            htmlFor="input-news-description"
                                                                        >
                                                                            Isi Berita
                                                                        </label>
                                                                        <div style={{
                                                                            height: '328px',
                                                                            border: isSubmit && !inputValues.news_body_has_text ? `1px solid ${RED_ERROR_MESSAGE}` : '1px solid black',
                                                                            borderRadius: '10px',
                                                                        }}>
                                                                            <CKEditor
                                                                                content={inputValues.news_body}
                                                                                events={{
                                                                                    blur: onBlur,
                                                                                    afterPaste: afterPaste,
                                                                                    change: onChange
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <p style={{
                                                                            color: MaterialRed1,
                                                                            fontSize: '11px',
                                                                            marginTop: '5px',
                                                                            visibility: isSubmit && !inputValues.news_body_has_text ? 'visible' : 'hidden'
                                                                        }}>Isi Berita must be filled</p>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col lg="12">
                                                            <a onClick={() => updateNews()} style={{ width: '100%' }} className="btn btn-success btn-sm">
                                                                Simpan
                                                            </a>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Form>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </>
                )
            case NEWS_DETAIL_NOT_FOUND:
                return (
                    <>
                        <Container className="mt--7" fluid>
                            <Row>
                                <Col className="order-xl-1" xl="12">
                                    <Card className="bg-secondary-shadow">
                                        <CardBody>
                                            <NotFoundPage
                                                imageSource={IcNotFound}
                                                imageText={props.news.payload.message} />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </>
                )
            case NEWS_DETAIL_FAILED:
                return (
                    <>
                        <Container className="mt--7" fluid>
                            <Row>
                                <Col className="order-xl-1" xl="12">
                                    <Card className="bg-secondary-shadow">
                                        <CardBody>
                                            <ErrorPage
                                                imageSource={IcError}
                                                imageText={props.news.payload.message} />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </>
                )
        }
    }

    return (
        <>
            <div className="main-content" ref={mainContent}>
                <div className="header bg-gradient-info pb-8 pt-5 pt-lg-7 d-flex align-items-center">
                    <span className="mask bg-gradient-default opacity-8" />
                </div>
                {buildNewsDetailPage()}
            </div>
        </>
    )
}

NewsDetailPage.propTypes = {
    news: PropTypes.object.isRequired,
    updateNews: PropTypes.func.isRequired,
    updateNewsImage: PropTypes.func.isRequired,
    getDetailNews: PropTypes.func.isRequired,
    resetCrud: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    news: state.news
})

export default connect(mapStateToProps, { updateNews, updateNewsImage, getDetailNews, resetCrud })(NewsDetailPage)