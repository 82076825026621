import {
    HELP_LIST_LOADING,
    HELP_LIST_SUCCESSFUL,
    HELP_LIST_NOT_FOUND,
    HELP_LIST_FAILED,

    HELP_DETAIL_LOADING,
    HELP_DETAIL_SUCCESSFUL,
    HELP_DETAIL_NOT_FOUND,
    HELP_DETAIL_FAILED,

    HELP_CREATE_LOADING,
    HELP_CREATE_SUCCESSFUL,
    HELP_CREATE_FAILED,

    HELP_UPDATE_LOADING,
    HELP_UPDATE_SUCCESSFUL,
    HELP_UPDATE_NOT_FOUND,
    HELP_UPDATE_FAILED,

    HELP_REMOVE_LOADING,
    HELP_REMOVE_SUCCESSFUL,
    HELP_REMOVE_NOT_FOUND,
    HELP_REMOVE_FAILED,
    
    HELP_RESET_CRUD
} from 'actions/Help/Types';

const initState = {
    payload : {
        type : null,
        message : ''
    },
    payload_crud : {
        type : null,
        message : ''
    }
}
export default function(state = initState , action){
    switch(action.type){
        case HELP_LIST_LOADING:
            return {
                ...state,
                type: action.type,
                payload: {
                    type: action.type,
                    message: action.message
                }
            }
        case HELP_LIST_SUCCESSFUL:
            return {
                ...state,
                type: action.type,
                payload: {
                    type: action.type,
                    data: action.data
                }
            }
        case HELP_LIST_NOT_FOUND:
            return {
                ...state,
                type: action.type,
                payload: {
                    type: action.type,
                    message: action.message
                }
            }
        case HELP_LIST_FAILED:
            return {
                ...state,
                type: action.type,
                payload: {
                    type: action.type,
                    message: action.message
                }
            }
        case HELP_DETAIL_LOADING:
            return {
                ...state,
                type: action.type,
                payload: {
                    type: action.type,
                    message: action.message
                }
            }
        case HELP_DETAIL_SUCCESSFUL:
            return {
                ...state,
                type: action.type,
                payload: {
                    type: action.type,
                    data: action.data
                }
            }
        case HELP_DETAIL_NOT_FOUND:
            return {
                ...state,
                type: action.type,
                payload: {
                    type: action.type,
                    message: action.message
                }
            }
        case HELP_DETAIL_FAILED:
            return {
                ...state,
                type: action.type,
                payload: {
                    type: action.type,
                    message: action.message
                }
            }

        case HELP_CREATE_LOADING:
            return {
                ...state,
                payload_crud: {
                    type : action.type,
                    message : action.message
                }
            }
        case HELP_CREATE_SUCCESSFUL:
            return {
                ...state,
                payload_crud: {
                    type : action.type,
                    message : action.message
                }
            }
        case HELP_CREATE_FAILED:
            return {
                ...state,
                payload_crud: {
                    type : action.type,
                    message : action.message
                }
            }

        case HELP_UPDATE_LOADING:
            return {
                ...state,
                payload_crud: {
                    type : action.type,
                    message : action.message
                }
            }
        case HELP_UPDATE_SUCCESSFUL:
            return {
                ...state,
                payload_crud: {
                    type : action.type,
                    message : action.message
                }
            }
        case HELP_UPDATE_NOT_FOUND:
            return {
                ...state,
                payload_crud: {
                    type : action.type,
                    message : action.message
                }
            }
        case HELP_UPDATE_FAILED:
            return {
                ...state,
                payload_crud: {
                    type : action.type,
                    message : action.message
                }
            }

        case HELP_REMOVE_LOADING:
            return {
                ...state,
                payload_crud: {
                    type : action.type,
                    message : action.message
                }
            }
        case HELP_REMOVE_SUCCESSFUL:
            return {
                ...state,
                payload_crud: {
                    type : action.type,
                    message : action.message
                }
            }
        case HELP_REMOVE_NOT_FOUND:
            return {
                ...state,
                payload_crud: {
                    type : action.type,
                    message : action.message
                }
            }
        case HELP_REMOVE_FAILED:
            return {
                ...state,
                payload_crud: {
                    type : action.type,
                    message : action.message
                }
            }
        case HELP_RESET_CRUD:
            return {
                ...state,
                payload_crud : {
                    type : null,
                    message : ''
                }
            }
        default:
            return state
    }
}