import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { Card, CardBody, Col, Container, Row, Table } from "reactstrap";
import React, { useEffect, useState } from "react";
import NotFoundPage from "components/NotFound/NotFoundPage";
import ErrorPage from "components/ErrorComponent/ErrorPage";
import IcNotFound from 'assets/img/ic_not_found.svg';
import IcError from 'assets/img/ic_error.svg';
import { getBannerList , removeBanner , resetCrud , setBannerEmpty } from "actions/Banners/BannerService";
import { useHistory } from "react-router-dom";
import { BANNER_LIST_LOADING } from "actions/Banners/Types";
import { BANNER_LIST_SUCCESSFUL } from "actions/Banners/Types";
import { BANNER_LIST_NOT_FOUND } from "actions/Banners/Types";
import Pagination from "components/Pagination/Pagination";
import Skeleton from "react-loading-skeleton";
import { MaterialGray1 } from "components/Utilities/ResourceColors";
import { BANNER_LIST_FAILED } from "actions/Banners/Types";
import { REMOVE_BANNER_LOADING } from "actions/Banners/Types";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { REMOVE_BANNER_SUCCESSFUL } from "actions/Banners/Types";
import { REMOVE_BANNER_NOT_FOUND } from "actions/Banners/Types";
import { REMOVE_BANNER_FAILED } from "actions/Banners/Types";
import { MaterialBlue3 } from "components/Utilities/ResourceColors";

const MySwal = withReactContent(Swal)

const BannerPage = (props) => {
    const [currentPage, setCurrentPage] = useState(1)
    const mainContent = React.useRef(null)
    const history = useHistory()
    const [lastRemovePosition , setLastRemovePosition] = useState(-1)
    const [token, setToken] = useState('')

    useEffect(() => {
        setToken(localStorage.getItem('token'))
        if (token !== '') {
            props.getBannerList(currentPage, 5, token)
        }
    }, [token])

    useEffect(() => {
        buildBannerList()
    }, [props.banner])
    useEffect(() => {
        if(token !== ''){
            props.getBannerList(currentPage, 5, token)
        }
    }, [currentPage])

    useEffect(() => {
        switch(props.banner.payload_banner.type){
            case REMOVE_BANNER_LOADING:
                MySwal.hideLoading()
                break;
            case REMOVE_BANNER_SUCCESSFUL:
                MySwal.hideLoading();
                if(props.banner.payload.data.rows_data.docs.length === 1){
                    if(props.banner.payload.data.previous_page_number !== null){
                        setCurrentPage(props.banner.payload.data.previous_page_number)
                    }
                }
                if (token !== '') {
                    props.getBannerList(currentPage, 5, token)
                }
                props.resetCrud()
                MySwal.fire(
                    'Perhatian',
                    'Berhasil menghapus data banner',
                    'success'
                )
                break;
            case REMOVE_BANNER_NOT_FOUND:
                MySwal.hideLoading()
                MySwal.fire(
                    'Perhatian',
                    'Data banner tidak ditemukan',
                    'warning'
                )
                break;
            case REMOVE_BANNER_FAILED:
                MySwal.hideLoading()
                MySwal.fire(
                    'Perhatian',
                    'Gagal menghapus data banner',
                    'error'
                )
                break;
        }
    } , [props.banner.payload_banner])

    function buildBannerList() {
        if (props.banner.payload.type === BANNER_LIST_LOADING) {
            return (
                <>
                    <div style={{ marginTop: '15px' }}>
                        <Row>
                            <Col lg="12">
                                <Skeleton style={{ height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12">
                                <Skeleton style={{ marginTop: '25px', height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12">
                                <Skeleton style={{ marginTop: '25px', height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12">
                                <Skeleton style={{ marginTop: '25px', height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12">
                                <Skeleton style={{ marginTop: '25px', height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12">
                                <Skeleton style={{ marginTop: '25px', height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                            </Col>
                        </Row>
                    </div>
                </>
            )
        } else if (props.banner.payload.type === BANNER_LIST_SUCCESSFUL) {
            if (props.banner.payload.data !== undefined) {
                return (
                    <Row>
                        <Col xl="12">
                            <Row style={{ marginTop: '15px' }}>
                                <Col xl="12">
                                    <Table className="table-bordered" responsive>
                                        <thead className="thead-dark">
                                            <tr>
                                                <th style={{ color: 'white' }} scope="col">Pembuat Banner</th>
                                                <th style={{ color: 'white' }} scope="col">Judul Banner</th>
                                                <th style={{ color: 'white' }} scope="col">Isi Banner</th>
                                                <th colSpan={2} style={{ color: 'white' }} scope="col">Aksi</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                props.banner.payload.data.rows_data.docs.map((data , index) => {
                                                    return (
                                                        <tr key={data.id}>
                                                            <td>{data.creator_name}</td>
                                                            <td>{data.banner_title}</td>
                                                            <td style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '55ch' }}>{data.banner_body}</td>
                                                            <td>
                                                                <button onClick={() => {
                                                                    history.push({
                                                                        pathname: '/admin/banner_detail_page',
                                                                        state: {
                                                                            banner_id: data.id
                                                                        }
                                                                    })
                                                                }} className="btn btn-sm btn-primary"><i className="fas fa-eye"></i> Lihat</button>
                                                                <button onClick={() => {
                                                                    setLastRemovePosition(index)
                                                                    MySwal.fire({
                                                                        title: 'Perhatian',
                                                                        text: `Yakin ingin menghapus banner ${data.banner_title}`,
                                                                        showCancelButton: true,
                                                                        confirmButtonText: 'Ya',
                                                                        confirmButtonColor: MaterialBlue3,
                                                                        cancelButtonColor: MaterialGray1,
                                                                        cancelButtonText: 'Batal'
                                                                    }).then(result => {
                                                                        if (result.isConfirmed) {
                                                                            MySwal.fire({
                                                                                title: 'Harap Tunggu',
                                                                                html : `Sedang menghapus data <strong>${data.banner_title}</strong>`,
                                                                                allowOutsideClick : false,
                                                                                showConfirmButton : false,
                                                                                didOpen: () => {
                                                                                    MySwal.showLoading()
                                                                                }
                                                                            })
                                                                            if(token !== '')
                                                                                props.removeBanner(data.id , token)
                                                                        }
                                                                    })
                                                                }} className="btn btn-sm btn-danger"><i className="fas fa-remove"></i> Hapus</button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                            <Row>
                                {
                                    props.banner.payload.data.count_total > 5 ?
                                        <Card className="bg-default-shadow" style={{ width: 'max-content', paddingLeft: '20px', marginRight: '0', marginTop: '10px', paddingTop: '15px', marginLeft: 'auto' }}>
                                            <Pagination
                                                className="pagination-bar"
                                                currentPage={currentPage}
                                                totalCount={props.banner.payload.data.count_total}
                                                pageSize={5}
                                                onPageChange={page => {
                                                    setCurrentPage(page)
                                                }} />
                                        </Card>
                                        : <div></div>
                                }
                            </Row>
                        </Col>
                    </Row>
                )
            }
        } else if (props.banner.payload.type === BANNER_LIST_NOT_FOUND) {
            return (
                <NotFoundPage imageSource={IcNotFound} imageText={props.banner.payload.message} />
            )
        } else {
            return (
                <ErrorPage imageSource={IcError} imageText={props.banner.payload.message} />
            )
        }
    }
    return (
        <>
            <div className="main-content">
                <div className="header bg-gradient-info pb-8 pt-5 pt-lg-7 d-flex align-items-center">
                    <span className="mask bg-gradient-default opacity-8" />
                </div>
                <Container className="mt--7" fluid>
                    <Card className="bg-secondary shadow">
                        <CardBody>
                            {
                                props.banner.payload.type !== BANNER_LIST_LOADING && props.banner.payload.type !== BANNER_LIST_FAILED ?
                                    <Row>
                                        <Col xl="10"></Col>
                                        <Col xl="2">
                                            <button onClick={() => {
                                                history.push({
                                                    pathname: '/admin/banner_create_page'
                                                })
                                            }} className="btn btn-sm btn-primary">+ Tambah Banner</button>
                                        </Col>
                                    </Row> : <div></div>
                            }
                            {buildBannerList()}
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </>
    )
}

BannerPage.propTypes = {
    getBannerList: PropTypes.func.isRequired,
    banner: PropTypes.object.isRequired,
    removeBanner : PropTypes.func.isRequired,
    resetCrud : PropTypes.func.isRequired,
    setBannerEmpty : PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    banner: state.banner
})

export default connect(mapStateToProps, { getBannerList , removeBanner , resetCrud , setBannerEmpty })(BannerPage)