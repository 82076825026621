import { LOGOUT_SUCCESSFUL } from "actions/Auth/Types";
import { LOGOUT_FAILED } from "actions/Auth/Types";
import { LOGIN_LOADING } from "actions/Auth/Types";
import { LOGIN_SUCCESSFUL , LOGIN_NOT_FOUND , LOGIN_FAILED , LOGIN_EXIST } from "actions/Auth/Types";

const initState = {
    isAuthenticated : false ,
    payload : {
        type : null,
        message : ''
    }
}

export default function(state = initState , action){
    switch(action.type){
        case LOGIN_LOADING:
            return {
                ...state,
                isAuthenticated : false,
                payload : action.payload
            }
        case LOGIN_SUCCESSFUL:
            return {
                ...state,
                isAuthenticated : true,
                payload : action.payload
            }
        case LOGIN_NOT_FOUND:
            return {
                ...state,
                isAuthenticated : false ,
                payload : action.payload
            }
        case LOGIN_FAILED:
            return {
                ...state,
                isAuthenticated : false,
                payload : action.payload
            }
        case LOGOUT_SUCCESSFUL:
            return {
                ...state,
                isAuthenticated : false,
                payload : action.payload
            }
        case LOGIN_EXIST:
            return {
                ...state,
                isAuthenticated : false,
                payload : action.payload
            }
        case LOGOUT_FAILED:
            return {
                ...state,
                isAuthenticated : false,
                payload : action.payload
            }
        default:
            return state
    }
}