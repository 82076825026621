import { CREATE_PARENT_SUCCESSFUL } from "actions/ParentService/Types";
import { PARENT_UPDATE_LOADING } from "actions/ParentService/Types";
import { PARENT_UPDATE_NOT_FOUND } from "actions/ParentService/Types";
import { PARENT_DETAIL_LOADING } from "actions/ParentService/Types";
import { PARENT_DETAIL_FAILED } from "actions/ParentService/Types";
import { PARENT_DETAIL_NOT_FOUND } from "actions/ParentService/Types";
import { PARENT_DETAIL_SUCCESSFUL } from "actions/ParentService/Types";
import { PARENT_UPDATE_FAILED } from "actions/ParentService/Types";
import { PARENT_UPDATE_SUCCESSFUL } from "actions/ParentService/Types";
import { CREATE_PARENT_FAILED } from "actions/ParentService/Types";
import { CREATE_PARENT_LOADING , PARENT_RESET_CRUD } from "actions/ParentService/Types";

const initState = {
    parent_detail_payload : {
        type : null,
        message : ''
    },
    parent_create : {
        payload : {
            type : null,
            message : ''
        }
    },
    parent_update : {
        payload : {
            type : null,
            message : ''
        }
    },
    parent_remove : {
        payload : {
            type : null , 
            message : ''
        }
    }
}

export default function(state = initState , action){
    switch(action.type){
        case CREATE_PARENT_LOADING:
            return {
                ...state ,
                parent_create : {
                    payload : {
                        type : action.type,
                        message : action.payload.message
                    }
                }
            }
        case CREATE_PARENT_SUCCESSFUL:
            return {
                ...state ,
                parent_create : {
                    payload : {
                        type : action.type,
                        message : action.payload.message
                    }
                }
            }
        case CREATE_PARENT_FAILED:
            return {
                ...state ,
                parent_create : {
                    payload : {
                        type : action.type,
                        message : action.payload.message
                    }
                }
            }
        case PARENT_DETAIL_LOADING:
            return {
                ...state ,
                parent_detail_payload : {
                    type : action.type,
                    message : 'Memuat Data Paket'
                }
            }
        case PARENT_DETAIL_SUCCESSFUL:
            return {
                ...state ,
                parent_detail_payload : {
                    type : action.type ,
                    data : action.payload.data
                }
            }
        case PARENT_DETAIL_NOT_FOUND:
            return {
                ...state ,
                parent_detail_payload : {
                    type : action.type ,
                    message : 'Tidak Ada Data Paket'
                }
            }
        case PARENT_DETAIL_FAILED:
            return {
                ...state ,
                parent_detail_payload : {
                    type : action.type ,
                    message : 'Gagal Memuat Data Paket'
                }
            }
        case PARENT_UPDATE_LOADING:
            return {
                ...state ,
                parent_update : {
                    payload : {
                        type : action.type ,
                        message : action.message
                    }
                }
            }
        case PARENT_UPDATE_SUCCESSFUL:
            return {
                ...state,
                parent_update : {
                    payload : {
                        type : action.type,
                        message : action.message
                    }
                }
            }
        case PARENT_UPDATE_NOT_FOUND:
            return {
                ...state ,
                parent_update : {
                    payload : {
                        type : action.type ,
                        message : action.message
                    }
                }
            }
        case PARENT_UPDATE_FAILED:
            return {
                ...state ,
                parent_update : {
                    payload : {
                        type : action.type ,
                        message : action.message
                    }
                }
            }
        case PARENT_RESET_CRUD:
            return {
                ...state,
                parent_create : {
                    payload : {
                        type : null,
                        message : ''
                    }
                },
                parent_update : {
                    payload : {
                        type : null,
                        message : ''
                    }
                },
                parent_remove : {
                    payload : {
                        type : null , 
                        message : ''
                    }
                }
            }
        default:
            return state
    }
}