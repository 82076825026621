import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
    Container,
    Card,
    Row,
    Col,
    CardBody,
    Form,
    FormGroup,
    Input
} from 'reactstrap';
import Sidebar from 'components/Sidebar/Sidebar';
import AdminNavbar from 'components/Navbars/AdminNavbar';
import routes from '../../../routes';
import { useEffect, useRef, useReducer, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getBannerDetail , updateBannerDetailImage , updateBannerDetail } from 'actions/Banners/BannerService';
import { 
    BANNER_DETAIL_LOADING,
    BANNER_DETAIL_SUCCESSFUL,
    BANNER_DETAIL_NOT_FOUND,
    BANNER_DETAIL_FAILED,

    UPDATE_BANNER_LOADING,
    UPDATE_BANNER_SUCCESSFUL,
    UPDATE_BANNER_NOT_FOUND,
    UPDATE_BANNER_FAILED
 } from 'actions/Banners/Types';
import '../../../assets/css/utilities.css';
import Swal from 'sweetalert2';
import IcImage from '../../../assets/img/ic_image.svg';
import { useHistory } from 'react-router-dom';
import { MaterialGray2 } from 'components/Utilities/ResourceColors';
import withReactContent from 'sweetalert2-react-content';
import { MaterialGray1 } from 'components/Utilities/ResourceColors';
import { MaterialRed1 } from 'components/Utilities/ResourceColors';
import { getJSONCredential } from 'actions/config';
import Select from 'react-select';
import { RED_ERROR_MESSAGE } from 'components/Utilities/ResourceColors';
import { erorrMsgStyle } from 'components/Utilities/Utility';
import { validateEmpty } from 'components/Utilities/Utility';
import Skeleton from 'react-loading-skeleton';
import ErrorPage from "components/ErrorComponent/ErrorPage";
import NotFoundPage from "components/NotFound/NotFoundPage";
import IcNotFound from 'assets/img/ic_not_found.svg';
import IcError from 'assets/img/ic_error.svg';
import { BASE_URL } from 'actions/config';

const MySwal = withReactContent(Swal)

const BannerDetailPage = (props) => {
    const options = [
        {
            value: 'promo', label: 'Promo'
        },
        {
            value: 'coupon', label: 'Coupon'
        },
        {
            value: 'visi misi', label: 'Visi Misi'
        }
    ]
    const mainContent = useRef(null)
    const location = useLocation()
    const history = useHistory()
    const fileRef = useRef()
    const [isUpdating , setUpdating] = useState(false)
    const [isBroadcast, setBroadcast] = useState(false)
    const [isSubmit, setSubmit] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const [selectedFile, setSelectedFile] = useState(null)
    const [bannerImage, setBannerImage] = useState(null)
    const [imgProper, setImgProper] = useState(false)
    const [token , setToken] = useState('')
    const [inputValues, setInputValues] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            banner_title: '',
            banner_body: '',
            banner_type: {
                label: 'Promo',
                value: 'promo'
            },
            banner_image: ''
        }
    )
    const onChangeHandler = event => {
        const { name, value } = event.target
        setInputValues({ [name]: value })
    }
    const onChangeImageHandler = event => {
        var img = new Image()
        setBannerImage(URL.createObjectURL(event.target.files[0]))
        img.onload = function () {
            console.log(`Width : ${img.width} Height : ${img.height}`)
            if (img.width == 1280 && img.height == 720) {
                setSelectedFile(event.target.files[0])
                setImgProper(true)
            } else {
                setImgProper(false)
            }
        }
        img.src = URL.createObjectURL(event.target.files[0])
    }
    function validateBorderImg() {
        if (isSubmit) {
            if (bannerImage == null || (bannerImage != null && !imgProper)) {
                return `1px dashed ${RED_ERROR_MESSAGE}`
            }
        } else {
            if (bannerImage != null && !imgProper) {
                return `1px dashed ${RED_ERROR_MESSAGE}`
            }
        }
    }
    function validateTextImg() {
        if (isSubmit) {
            if (bannerImage == null) {
                if (!validateEmpty(inputValues.banner_image)) {
                    return (
                        <p style={
                            erorrMsgStyle
                        }>Gambar Paket must be filled</p>
                    )
                }
            } else if (bannerImage != null && !imgProper) {
                return (
                    <p style={
                        erorrMsgStyle
                    }>Gambar Paket must be 1280px X 720px</p>
                )
            } else {
                return (<></>)
            }
        } else {
            if (bannerImage != null && !imgProper) {
                return (
                    <p style={
                        erorrMsgStyle
                    }>Gambar Paket must be 1280px X 720px</p>
                )
            } else {
                return (<></>)
            }
        }
    }
    useEffect(() => {
        setToken(localStorage.getItem('token'))
        if(token !== ''){
            props.getBannerDetail(props.location.state.banner_id , token)
        }
    }, [token])
    useEffect(() => {
        if (!localStorage.getItem('token')) {
            history.push('/auth/login')
        }
    }, [location])
    useEffect(() => {
        if (props.banner.payload.data !== undefined) {
            for (const key in props.banner.payload.data) {
                if (props.banner.payload.data.hasOwnProperty(key)) {
                    if (key === 'banner_type') {
                        let typeValue = props.banner.payload.data[key]
                        if (typeValue.split(" ").length > 1) {
                            const split_status = typeValue.split(" ")
                            typeValue = ""
                            for (let i = 0; i < split_status.length; i++) {
                                typeValue += split_status[i].charAt(0).toUpperCase() + split_status[i].slice(1) + (i == split_status.length - 1 ? "" : " ")
                            }
                        } else {
                            typeValue = typeValue.charAt(0).toUpperCase() + typeValue.slice(1)
                        }
                        setInputValues({
                            ['banner_type']: {
                                value: props.banner.payload.data[key],
                                label: typeValue
                            }
                        })
                    } else if (key === 'is_broadcast') {
                        setBroadcast(props.banner.payload.data[key] === '1' ? true : false)
                    } else {
                        setInputValues({ [key]: props.banner.payload.data[key] })
                    }
                }
            }
        }
        buildBannerDetail()
    }, [props.banner.payload])

    useEffect(() => {
        switch (props.banner.payload_banner.type) {
            case UPDATE_BANNER_LOADING:
                setUpdating(true)
                break;
            case UPDATE_BANNER_SUCCESSFUL:
                setUpdating(false)
                setBannerImage(null)
                setSelectedFile(null)
                setSubmit(false)
                MySwal.fire(
                    'Status',
                    'Berhasil Memperbarui Banner',
                    'success'
                )
                if(token !== ''){
                    props.getBannerDetail(props.location.state.banner_id , token)
                }
                break;
            case UPDATE_BANNER_NOT_FOUND:
                setUpdating(false)
                MySwal.fire(
                    'Status',
                    'Banner Tidak Ada',
                    'error'
                )
                break;
            case UPDATE_BANNER_FAILED:
                setUpdating(false)
                MySwal.fire(
                    'Status',
                    'Gagal Memperbarui Banner',
                    'error'
                )
                break;
        }
    }, [props.banner.payload_banner])

    function editUpdate() {
        setSubmit(true)
        const {
            banner_title,
            banner_body
        } = inputValues
        const banner_type = inputValues.banner_type.value
        if (banner_title !== '' &&
            banner_body !== '' &&
            banner_type !== '') {
            const json_data = getJSONCredential()['data']
            setUpdating(true)
            // let banner_type_selected = banner_type
            // if (banner_type_selected.split(" ").length > 1) {
            //     const split_banner_type = banner_type_selected.split(" ")
            //     banner_type_selected = ""
            //     for (let i = 0; i < split_banner_type.length; i++) {
            //         banner_type_selected += split_banner_type[i].charAt(0).toLowerCase() + split_banner_type[i].slice(1) + (i == split_banner_type.length - 1 ? "" : " ")
            //     }
            // } else {
            //     banner_type_selected = banner_type_selected.charAt(0).toLowerCase() + banner_type_selected.slice(1)
            // }
            if (selectedFile !== null) {
                const formDataRequest = new FormData()
                const json_payload = {
                    creator_id: json_data['id'],
                    creator_name: json_data['complete_name'],
                    banner_title: inputValues.banner_title,
                    banner_body: inputValues.banner_body,
                    banner_type: banner_type,
                    is_broadcast: isBroadcast ? '1' : '0'
                }
                formDataRequest.append('json_data', JSON.stringify(json_payload))
                formDataRequest.append('file', selectedFile)
                props.updateBannerDetailImage(props.location.state.banner_id, formDataRequest , token)
            } else {
                const json_payload = {
                    creator_id: json_data['id'],
                    creator_name: json_data['complete_name'],
                    banner_title: inputValues.banner_title,
                    banner_body: inputValues.banner_body,
                    banner_type: banner_type,
                    is_broadcast: isBroadcast ? '1' : '0'
                }
                props.updateBannerDetail(props.location.state.banner_id, JSON.stringify(json_payload) , token)
            }
        }
    }

    function buildBannerDetail() {
        switch (props.banner.payload.type) {
            case BANNER_DETAIL_LOADING:
                return (
                    <Container className="mt--7" fluid>
                        <Row>
                            <Col className="order-xl-1" xl="12">
                                <Card className="bg-secondary shadow">
                                    <CardBody>
                                        <Form>
                                            <div>
                                                <Row>
                                                    <Col lg="12">
                                                        <FormGroup>
                                                            <Skeleton style={{ height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg="12">
                                                        <Skeleton style={{ height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Skeleton style={{ marginTop: '30px', height: '200px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg="12">
                                                        <FormGroup>
                                                            <Skeleton style={{ marginTop: '30px', height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                )
            case BANNER_DETAIL_SUCCESSFUL:
                return (
                    <Container className="mt--7" fluid>
                        <Row>
                            <Col className="order-xl-1" xl="12">
                                <Card className="bg-secondary-shadow">
                                    {
                                        isUpdating ?
                                            <div style={{
                                                borderTopLeftRadius: '5px',
                                                borderTopRightRadius: '5px',
                                                height: '5px'
                                            }} className="progress-bar">
                                                <div className="progress-bar-value"></div>
                                            </div>
                                            : <div style={{
                                                height: '5px'
                                            }}></div>
                                    }
                                    <CardBody>
                                        <Form>
                                            <div>
                                                <Row>
                                                    <Col md="6">
                                                        <Row>
                                                            <Col lg="12">
                                                                <FormGroup>
                                                                    <label
                                                                        className="form-control-label"
                                                                        htmlFor="input-title">
                                                                        Banner Title
                                                                    </label>
                                                                    <Input
                                                                        className="form-control-alternative"
                                                                        id="input-title"
                                                                        type="text"
                                                                        name="banner_title"
                                                                        onChange={onChangeHandler}
                                                                        defaultValue={inputValues.banner_title}
                                                                        style={
                                                                            isSubmit && inputValues.banner_title === '' ?
                                                                                {
                                                                                    color: 'black',
                                                                                    border: '0.2px solid #d32f2f'
                                                                                }
                                                                                :
                                                                                {
                                                                                    color: 'black',
                                                                                    border: 'none'
                                                                                }
                                                                        }
                                                                        disabled={(isUpdating) ? "disabled" : ""} />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg="6">
                                                                <FormGroup>
                                                                    <label
                                                                        className="form-control-label"
                                                                        htmlFor="input-select-type"
                                                                    >
                                                                        Banner Type
                                                                    </label>
                                                                    <Select
                                                                        id="input-select-type"
                                                                        options={options}
                                                                        value={
                                                                            inputValues.banner_type
                                                                        }
                                                                        name="banner_type"
                                                                        onChange={(selectedOption) => {
                                                                            setInputValues({ ['banner_type']: selectedOption })
                                                                        }} />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col lg="6">
                                                                <label
                                                                    className="form-control-label"
                                                                >
                                                                    Blast Banner?
                                                                </label>
                                                                <Row>
                                                                    <Col md="5">
                                                                        <input
                                                                            id="is_broadcast"
                                                                            type="radio"
                                                                            name="is_broadcast_yes"
                                                                            style={{ marginRight: '5px' }}
                                                                            checked={isBroadcast === true}
                                                                            disabled={(isUpdating) ? "disabled" : ""}
                                                                            onChange={e => {
                                                                                setBroadcast(true)
                                                                            }} />
                                                                        <label
                                                                            className="form-control-label"
                                                                            htmlFor="is_broadcast" style={{ fontSize: '13px' }}>Yes</label>
                                                                    </Col>
                                                                    <Col md="5">
                                                                        <input
                                                                            id="is_broadcast_no"
                                                                            type="radio"
                                                                            name="is_broadcast_no"
                                                                            style={{ marginRight: '5px' }}
                                                                            checked={isBroadcast === false}
                                                                            disabled={(isUpdating) ? "disabled" : ""}
                                                                            onChange={e => {
                                                                                setBroadcast(false)
                                                                            }} />
                                                                        <label
                                                                            className="form-control-label"
                                                                            htmlFor="is_broadcast_no" style={{ fontSize: '13px' }}>No</label>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg="12">
                                                                <FormGroup>
                                                                    <label
                                                                        className="form-control-label"
                                                                        htmlFor="input-banner-description"
                                                                    >
                                                                        Banner Description
                                                                    </label>
                                                                    <Input
                                                                        type="textarea"
                                                                        id="input-banner-description"
                                                                        name="banner_body"
                                                                        defaultValue={inputValues.banner_body}
                                                                        style={{
                                                                            height: '150px',
                                                                            padding: '0.4em',
                                                                            textAlign: 'left',
                                                                            color: 'black'
                                                                        }}
                                                                        disabled={(isUpdating) ? "disabled" : ""}
                                                                        onChange={onChangeHandler} />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col lg="6">
                                                        <div>
                                                            <label
                                                                className="form-control-label">Banner Image</label>
                                                            {
                                                                <div style={{
                                                                    alignItems: 'center',
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    border: bannerImage === null ? '1px dashed black' : '1px solid transparent',
                                                                    borderRadius: '10px'
                                                                }}>
                                                                    {
                                                                        bannerImage === null ?
                                                                            <div style={{
                                                                                alignItems: 'center',
                                                                                display: 'flex',
                                                                                width: '100%',
                                                                                height: '260px',
                                                                                border: validateBorderImg(),
                                                                                borderRadius: '10px',
                                                                                justifyContent: 'center'
                                                                            }}>
                                                                                <img src={`${BASE_URL}/api/images/${inputValues.banner_image}`} style={{ textAlign: 'center' , width : '100%' , height : '100%' , borderRadius : '10px' }} />
                                                                            </div>
                                                                            :
                                                                            <div style={{
                                                                                width: '100%',
                                                                                height: '260px',
                                                                                border: validateBorderImg(),
                                                                                borderRadius: '10px',
                                                                                alignItems: 'center',
                                                                                justifyContent: 'center',
                                                                                textAlign: 'center'
                                                                            }}>
                                                                                <div style={{
                                                                                    width: '25px',
                                                                                    height: '25px',
                                                                                    borderRadius: '50%',
                                                                                    backgroundColor: MaterialRed1,
                                                                                    position: 'absolute',
                                                                                    right: 8,
                                                                                    top: 22,
                                                                                    cursor: 'pointer'
                                                                                }}
                                                                                    onClick={() => {
                                                                                        setSelectedFile(null)
                                                                                        setBannerImage(null)
                                                                                    }}
                                                                                >
                                                                                    <p style={{ color: 'white' }}>&times;</p>
                                                                                </div>
                                                                                <img src={bannerImage} style={{
                                                                                    width: '100%',
                                                                                    objectFit: 'cover',
                                                                                    borderRadius: '10px',
                                                                                    height: '260px',
                                                                                    display: 'inline-block'
                                                                                }} />
                                                                            </div>
                                                                    }
                                                                </div>
                                                            }
                                                            <Row>
                                                                <Col sm="8">
                                                                    <p style={{ fontSize: '13px', marginTop: '10px' }}>
                                                                        Ukuran Gambar 1280px X 720px
                                                                    </p>
                                                                </Col>
                                                                <Col sm="4">
                                                                    <div>
                                                                        <input type="file" onChange={onChangeImageHandler} name="image_picker_change" ref={fileRef} hidden />
                                                                        <button onClick={(e) => {
                                                                            e.preventDefault()
                                                                            if (!isLoading) {
                                                                                fileRef.current.click()
                                                                            }
                                                                        }} style={{
                                                                            marginTop: '10px',
                                                                            width: '100%'
                                                                        }} className="btn btn-sm btn-primary">Ambil gambar</button>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xl="12">
                                                                    {validateTextImg()}
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg="12">
                                                        <a onClick={() => editUpdate()} style={{ width: '100%', marginTop: '10px' }} className="btn btn-success btn-sm">
                                                            Simpan
                                                        </a>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                )
            case BANNER_DETAIL_NOT_FOUND:
                return (
                    <Container className="mt--7" fluid>
                        <Row>
                            <Col className="order-xl-1" xl="12">
                                <Card className="bg-secondary-shadow">
                                    <CardBody>
                                        <NotFoundPage
                                            imageSource={IcNotFound}
                                            imageText={props.banner.payload.message} />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                )
            case BANNER_DETAIL_FAILED:
                return (
                    <Container className="mt--7" fluid>
                        <Row>
                            <Col className="order-xl-1" xl="12">
                                <Card className="bg-secondary-shadow">
                                    <CardBody>
                                        <ErrorPage
                                            imageSource={IcError}
                                            imageText={props.banner.payload.message} />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                )
            default:
                return (
                    <Container className="mt--7" fluid>
                        <Row>
                            <Col className="order-xl-1" xl="12">
                                <Card className="bg-secondary shadow">
                                    <CardBody>
                                        <Form>
                                            <div>
                                                <Row>
                                                    <Col lg="12">
                                                        <FormGroup>
                                                            <Skeleton style={{ height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg="12">
                                                        <Skeleton style={{ height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Skeleton style={{ marginTop: '30px', height: '200px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg="12">
                                                        <FormGroup>
                                                            <Skeleton style={{ marginTop: '30px', height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                )
        }
    }

    return (
        <>
            <div className="main-content" ref={mainContent}>
                <div className="header bg-gradient-info pb-8 pt-5 pt-lg-7 d-flex align-items-center">
                    <span className="mask bg-gradient-default opacity-8" />
                </div>
                {buildBannerDetail()}
            </div>
        </>
    )
}

BannerDetailPage.propTypes = {
    banner: PropTypes.object.isRequired,
    getBannerDetail: PropTypes.func.isRequired,
    updateBannerDetail : PropTypes.func.isRequired,
    updateBannerDetailImage : PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    banner: state.banner
})

export default connect(mapStateToProps, { getBannerDetail , updateBannerDetail , updateBannerDetailImage })(BannerDetailPage)