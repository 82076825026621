import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Row, Table } from "reactstrap";
import React, { useEffect, useState } from "react";
import { getComplain, removeComplain, resetCrud } from "actions/Complain/ComplainService";
import Skeleton from "react-loading-skeleton";
import Pagination from "components/Pagination/Pagination";
import NotFoundPage from "components/NotFound/NotFoundPage";
import ErrorPage from "components/ErrorComponent/ErrorPage";
import IcNotFound from 'assets/img/ic_not_found.svg';
import IcError from 'assets/img/ic_error.svg';
import { useHistory } from 'react-router-dom';

import {
    COMPLAIN_LIST_LOADING,
    COMPLAIN_LIST_SUCCESSFUL,
    COMPLAIN_LIST_NOT_FOUND,
    COMPLAIN_LIST_FAILED,

    COMPLAIN_REMOVE_LOADING,
    COMPLAIN_REMOVE_SUCCESSFUL,
    COMPLAIN_REMOVE_NOT_FOUND,
    COMPLAIN_REMOVE_FAILED
} from 'actions/Complain/Types'
import { MaterialGray1 } from "components/Utilities/ResourceColors";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { MaterialBlue3 } from "components/Utilities/ResourceColors";
import { MaterialBlue1 } from "components/Utilities/ResourceColors";
import { validateEmpty } from "components/Utilities/Utility";
const MySwal = withReactContent(Swal)

const ComplainPage = (props) => {
    const [token, setToken] = useState('')
    const [currentPage, setCurrentPage] = useState(1)
    const [searchText , setSearchText] = useState('')
    const [currentPageSearch , setCurrentPageSearch] = useState('')
    const [isFirst , setFirst] = useState(true)
    const mainContent = React.useRef(null)
    const history = useHistory()

    const onChangeSearch = (e) => {
        setSearchText(e.target.value)
    }

    useEffect(() => {
        setToken(localStorage.getItem('token'))
        if (token !== '') {
            props.getComplain(currentPage, 5, token , "")
        }
    }, [token])
    useEffect(() => {
        buildComplainPage()
    }, [props.complain])
    useEffect(() => {
        if (token !== '') {
            props.getComplain(currentPage, 5, token , "")
        }
    }, [currentPage])

    useEffect(() => {
        switch (props.complain.payload_crud.type) {
            case COMPLAIN_REMOVE_LOADING:
                MySwal.hideLoading()
                break;
            case COMPLAIN_REMOVE_SUCCESSFUL:
                MySwal.hideLoading();
                if (props.complain.payload.data.rows_data.docs.length === 1) {
                    if (props.complain.payload.data.previous_page_number !== null) {
                        setCurrentPage(props.complain.payload.data.previous_page_number)
                    }
                }
                if (token !== '') {
                    props.getComplain(currentPage, 5, token , searchText)
                }
                props.resetCrud()
                MySwal.fire(
                    'Perhatian',
                    'Berhasil menghapus data complain',
                    'success'
                )
                break;
            case COMPLAIN_REMOVE_NOT_FOUND:
                MySwal.hideLoading()
                MySwal.fire(
                    'Perhatian',
                    'Data complain tidak ditemukan',
                    'warning'
                )
                break;
            case COMPLAIN_REMOVE_FAILED:
                MySwal.hideLoading()
                MySwal.fire(
                    'Perhatian',
                    'Gagal menghapus data complain',
                    'error'
                )
                break;
        }
    }, [props.complain.payload_crud])

    useEffect(() => {
        if(!isFirst){
            if(validateEmpty(searchText)){
                props.getComplain(currentPageSearch , 5 , searchText)
            }
        } else {
            setFirst(false)
        }
    } , [currentPageSearch])

    useEffect(() => {
        if(!isFirst){
            const delayDebounceFn = setTimeout(() => {
                if(validateEmpty(searchText)){
                    setCurrentPageSearch(1)
                    props.getComplain(currentPageSearch , 5 , searchText)
                } else {
                    setCurrentPageSearch(1)
                    props.getComplain(currentPage , 5 , "")
                }
            } , 800)
            return () => clearTimeout(delayDebounceFn)
        } else {
            setFirst(false)
        }
    } , [searchText])

    function buildComplainPage() {
        if (props.complain.payload.type === COMPLAIN_LIST_LOADING) {
            return (
                <>
                    <div style={{ marginTop: '15px' }}>
                        <Row>
                            <Col lg="12">
                                <Skeleton style={{ height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12">
                                <Skeleton style={{ marginTop: '25px', height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12">
                                <Skeleton style={{ marginTop: '25px', height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12">
                                <Skeleton style={{ marginTop: '25px', height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12">
                                <Skeleton style={{ marginTop: '25px', height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12">
                                <Skeleton style={{ marginTop: '25px', height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                            </Col>
                        </Row>
                    </div>
                </>
            )
        } else if (props.complain.payload.type === COMPLAIN_LIST_SUCCESSFUL) {
            if (props.complain.payload.data !== undefined) {
                return (
                    <>
                        <Row>
                            <Col xl="12">
                                <Row style={{ marginTop: '15px' }}>
                                    <Col xl="12">
                                        <Table className="table-bordered" responsive>
                                            <thead className="thead-dark">
                                                <tr>
                                                    <th style={{ color: 'white' }} scope="col">Kode Komplain</th>
                                                    <th style={{ color: 'white' }} scope="col">Judul</th>
                                                    <th style={{ color: 'white' }} scope="col">Customer</th>
                                                    <th style={{ color: 'white' }} scope="col">Status</th>
                                                    <th colSpan={2} style={{ color: 'white' }}>Aksi</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    props.complain.payload.data.rows_data.docs.map((data, index) => {
                                                        return (
                                                            <tr key={data.id}>
                                                                <td>{data.complain_code}</td>
                                                                <td>{data.title_problem}</td>
                                                                <td>{data.user.complete_name}</td>
                                                                <td>{data.status === null ? 'SENT' : data.status}</td>
                                                                <td style={{ float: 'left', marginRight: '5px' }}>
                                                                    <button onClick={() => {
                                                                        history.push({
                                                                            pathname: '/admin/complain_detail_page',
                                                                            state: {
                                                                                complain_id: data.id
                                                                            }
                                                                        })
                                                                    }} className="btn btn-sm btn-primary"><i className="fas fa-eye"></i> Lihat</button>
                                                                    <button onClick={() => {
                                                                        MySwal.fire({
                                                                            title: 'Perhatian',
                                                                            text: `Yakin ingin menghapus complain ${data.title_problem}`,
                                                                            showCancelButton: true,
                                                                            confirmButtonText: 'Ya',
                                                                            confirmButtonColor: MaterialBlue3,
                                                                            cancelButtonColor: MaterialGray1,
                                                                            cancelButtonText: 'Batal'
                                                                        }).then(result => {
                                                                            if (result.isConfirmed) {
                                                                                MySwal.fire({
                                                                                    title: 'Harap Tunggu',
                                                                                    html: `Sedang menghapus data <strong>${data.title_problem}</strong>`,
                                                                                    allowOutsideClick: false,
                                                                                    showConfirmButton: false,
                                                                                    didOpen: () => {
                                                                                        MySwal.showLoading()
                                                                                    }
                                                                                })
                                                                                if (token !== '')
                                                                                    props.removeComplain(data.id, token)
                                                                            }
                                                                        })
                                                                    }} className="btn btn-sm btn-danger"><i className="fas fa-remove"></i> Hapus</button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                                <Row>
                                    {
                                        props.complain.payload.data.count_total > 5 ?
                                            <Card className="bg-default-shadow" style={{ width: 'max-content', paddingLeft: '20px', marginRight: '0', marginTop: '10px', paddingTop: '15px', marginLeft: 'auto' }}>
                                                <Pagination
                                                    className="pagination-bar"
                                                    currentPage={currentPage}
                                                    totalCount={props.complain.payload.data.count_total}
                                                    pageSize={5}
                                                    onPageChange={page => {
                                                        setCurrentPage(page)
                                                    }} />
                                            </Card>
                                            : <div></div>
                                    }
                                </Row>
                            </Col>
                        </Row>
                    </>
                )
            }
        } else if (props.complain.payload.type === COMPLAIN_LIST_NOT_FOUND) {
            return (
                <NotFoundPage imageSource={IcNotFound} imageText={props.complain.payload.message} />
            )
        } else if (props.complain.payload.type === COMPLAIN_LIST_FAILED) {
            return (
                <ErrorPage imageSource={IcError} imageText={props.complain.payload.message} />
            )
        }
    }
    return (
        <>
            <div className="main-content" ref={mainContent}>
                <div className="header bg-gradient-info pb-8 pt-5 pt-lg-7 d-flex align-items-center">
                    <span className="mask bg-gradient-default opacity-8" />
                </div>
                <Container className="mt--7" fluid>
                    <Row>
                        <Col xl="12">
                            <Form onSubmit={(e) => {
                                e.preventDefault()
                            }}>
                                <FormGroup>
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i class="fa fa-search" aria-hidden="true" style={{ color: MaterialBlue1, fontSize: 15 }}>
                                                </i>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            id="input-search"
                                            onChange={onChangeSearch}
                                            style={{
                                                border: 0,
                                                boxShadow: 'none'
                                            }}
                                            defaultValue={searchText}
                                            placeholder="Ketika disini untuk mencari komplain berdasarkan Kode" />
                                    </InputGroup>
                                </FormGroup>
                            </Form>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl="12">
                            <Card className="bg-secondary shadow">
                                <CardBody>
                                    {buildComplainPage()}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

ComplainPage.propTypes = {
    complain: PropTypes.object.isRequired,
    getComplain: PropTypes.func.isRequired,
    removeComplain: PropTypes.func.isRequired,
    resetCrud: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    complain: state.complain
})

export default connect(mapStateToProps, { getComplain, removeComplain, resetCrud })(ComplainPage)