import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
import React , { Component } from 'react';

export class MapContainer extends Component {
  render() {
    return (
      <Map style={{ borderRadius : '5px', marginRight : '20px' }} center={{
        lat : -6.180867,
        lng : 107.062876
      }} google={this.props.google} zoom={14}></Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyBwmuv8PudqbqQ4yb3f2zF72AVUefe8Sc8'
})(MapContainer)