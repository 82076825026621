export const SUB_SERVICE_CREATE_LOADING = 'SUB_SERVICE_CREATE_LOADING';
export const SUB_SERVICE_CREATE_SUCCESSFUL = 'SUB_SERVICE_CREATE_SUCCESSFUL';
export const SUB_SERVICE_CREATE_FAILED = 'SUB_SERVICE_CREATE_FAILED';

export const SUB_SERVICE_UPDATE_LOADING = 'SUB_SERVICE_UPDATE_LOADING';
export const SUB_SERVICE_UPDATE_SUCCESSFUL = 'SUB_SERVICE_UPDATE_SUCCESSFUL';
export const SUB_SERVICE_UPDATE_NOT_FOUND = 'SUB_SERVICE_UPDATE_NOT_FOUND';
export const SUB_SERVICE_UPDATE_FAILED = 'SUB_SERVICE_UPDATE_FAILED';

export const SUB_SERVICE_REMOVE_LOADING = 'SUB_SERVICE_REMOVE_LOADING';
export const SUB_SERVICE_REMOVE_SUCCESSFUL = 'SUB_SERVICE_REMOVE_SUCCESSFUL';
export const SUB_SERVICE_REMOVE_UNAUTHORIZED = 'SUB_SERVICE_REMOVE_UNAUTHORIZED';
export const SUB_SERVICE_REMOVE_NOT_FOUND = 'SUB_SERVICE_REMOVE_NOT_FOUND';
export const SUB_SERVICE_REMOVE_FAILED = 'SUB_SERVICE_REMOVE_fAILED';

export const SUB_SERVICE_RESET_CRUD = 'SUB_SERVICE_RESET_CRUD'