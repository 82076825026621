export const LAYANAN_LIST_LOADING = 'LAYANAN_LIST_LOADING';
export const LAYANAN_LIST_SUCCESSFUL = 'LAYANAN_LIST_SUCCESSFUL';
export const LAYANAN_LIST_FAILED = 'LAYANAN_LIST_FAILED';
export const LAYANAN_LIST_NOT_FOUND = 'LAYANAN_LIST_NOT_FOUND'

export const LAYANAN_DETAIL_LOADING = 'LAYANAN_DETAIL_LOADING';
export const LAYANAN_DETAIL_SUCCESSFUL = 'LAYANAN_DETAIL_SUCCESSFUL';
export const LAYANAN_DETAIL_NOT_FOUND = 'LAYANAN_DETAIL_NOT_FOUND';
export const LAYANAN_DETAIL_FAILED = 'LAYANAN_DETAIL_FAILED';

export const LAYANAN_CREATE_LOADING = 'LAYANAN_CREATE_LOADING';
export const LAYANAN_CREATE_SUCCESSFUL = 'LAYANAN_CREATE_SUCCESSFUL';
export const LAYANAN_CREATE_EXIST = 'LAYANAN_CREATE_EXIST'
export const LAYANAN_CREATE_FAILED = 'LAYANAN_CREATE_FAILED';

export const LAYANAN_UPDATE_LOADING = 'LAYANAN_UPDATE_LOADING'
export const LAYANAN_UPDATE_SUCCESSFUL = 'LAYANAN_UPDATE_SUCCESSFUL';
export const LAYANAN_UPDATE_NOT_FOUND = 'LAYANAN_UPDATE_NOT_FOUND';
export const LAYANAN_UPDATE_FAILED = 'LAYANAN_UPDATE_FAILED';

export const LAYANAN_REMOVE_LOADING = 'LAYANAN_REMOVE_LOADING';
export const LAYANAN_REMOVE_SUCCESSFUL = 'LAYANAN_REMOVE_SUCCESSFUL';
export const LAYANAN_REMOVE_NOT_FOUND = 'LAYANAN_REMOVE_NOT_FOUND';
export const LAYANAN_REMOVE_UNAUTHORIZED = 'LAYANAN_REMOVE_UNAUTHORIZED';
export const LAYANAN_REMOVE_FAILED = 'LAYANAN_REMOVE_FAILED';

export const PARENT_CREATE_LOADING = 'PARENT_CREATE_LOADING';
export const PARENT_CREATE_SUCCESSFUL = 'PARENT_CREATE_SUCCESSFUL';
export const PARENT_CREATE_FAILED = 'PARENT_CREATE_FAILED';

export const PARENT_DETAIL_LOADING = 'PARENT_DETAIL_LOADING';
export const PARENT_DETAIL_SUCCESSFUL = 'PARENT_DETAIL_SUCCESSFUL';
export const PARENT_DETAIL_NOT_FOUND = 'PARENT_DETAIL_NOT_FOUND';
export const PARENT_DETAIL_FAILED = 'PARENT_DETAIL_FAILED';

export const PARENT_UPDATE_LOADING = 'PARENT_UPDATE_LOADING';
export const PARENT_UPDATE_SUCCESSFUL = 'PARENT_UPDATE_SUCCESSFUL';
export const PARENT_UPDATE_NOT_FOUND = 'PARENT_UPDATE_NOT_FOUND';
export const PARENT_UPDATE_FAILED = 'PARENT_UPDATE_FAILED';

export const PARENT_REMOVE_LOADING = 'PARENT_REMOVE_LOADING';
export const PARENT_REMOVE_SUCCESSFUL = 'PARENT_REMOVE_SUCCESSFUL';
export const PARENT_REMOVE_UNAUTHORIZED = 'PARENT_REMOVE_UNAUTHORIZED';
export const PARENT_REMOVE_NOT_FOUND = 'PARENT_REMOVE_NOT_FOUND';
export const PARENT_REMOVE_FAILED = 'PARENT_REMOVE_fAILED';

export const LAYANAN_RESET_CRUD = 'LAYANAN_RESET_CRUD'