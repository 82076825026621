import { useEffect, useReducer, useRef, useState } from "react";
import PropTypes from 'prop-types';
import {
    Card,
    CardBody,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Modal,
    ModalBody,
    Row,
    Table
} from 'reactstrap';
import NotFoundPage from "components/NotFound/NotFoundPage";
import ErrorPage from "components/ErrorComponent/ErrorPage";
import IcNotFound from 'assets/img/ic_not_found.svg';
import IcError from 'assets/img/ic_error.svg';
import { getLayananList, createLayanan, updateLayananImage, updateLayanan, removeLayanan, resetCrud } from "actions/LayananService/LayananService";
import { useHistory } from "react-router-dom";
import {
    LAYANAN_LIST_LOADING,
    LAYANAN_LIST_SUCCESSFUL,
    LAYANAN_LIST_NOT_FOUND,
    LAYANAN_LIST_FAILED,

    LAYANAN_CREATE_LOADING,
    LAYANAN_CREATE_SUCCESSFUL,
    LAYANAN_CREATE_EXIST,
    LAYANAN_CREATE_FAILED,

    LAYANAN_UPDATE_LOADING,
    LAYANAN_UPDATE_SUCCESSFUL,
    LAYANAN_UPDATE_NOT_FOUND,
    LAYANAN_UPDATE_FAILED,

    LAYANAN_REMOVE_LOADING,
    LAYANAN_REMOVE_SUCCESSFUL,
    LAYANAN_REMOVE_NOT_FOUND,
    LAYANAN_REMOVE_UNAUTHORIZED,
    LAYANAN_REMOVE_FAILED
} from "actions/LayananService/Types";
import Pagination from "components/Pagination/Pagination";
import Skeleton from "react-loading-skeleton";
import { MaterialGray1 } from "components/Utilities/ResourceColors";
import { connect } from "react-redux";
import IcImage from 'assets/img/ic_image.svg';
import { BASE_URL } from "actions/config";
import '../../../assets/css/utilities.css';
import { MaterialGray2 } from "components/Utilities/ResourceColors";
import { validateErrorMessage } from "components/Utilities/Utility";
import { MaterialRed1 } from "components/Utilities/ResourceColors";
import { RED_ERROR_MESSAGE } from "components/Utilities/ResourceColors";
import { JSON_CREDENTIALS } from "actions/config";
import Swal from "sweetalert2";
import { validateEmpty } from "components/Utilities/Utility";
import { erorrMsgStyle } from "components/Utilities/Utility";

const LayananPage = (props) => {
    const [currentPage, setCurrentPage] = useState(1)
    const [token, setToken] = useState('')

    const [showModalCreate, setShowModalCreate] = useState(false)
    const [showModalUpdate, setShowModalUpdate] = useState(false)
    const [showModalRemove, setShowModalRemove] = useState(false)

    const [submitUpdate, setSubmitUpdate] = useState(false)

    const [isSubmit, setSubmit] = useState(false)
    const [selectedFile, setSelectedFile] = useState(null)
    const [layananImage, setLayananImage] = useState(null)
    const [isLoading, setLoading] = useState(false)

    const [imgProper, setImgProper] = useState(false)

    const fileRef = useRef()
    const fileRefUpdate = useRef()

    const [inputValues, setInputValues] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            create_layanan_name: '',
            create_layanan_description: '',
            create_is_active: '',

            update_layanan_name: '',
            update_layanan_description: '',
            update_is_active: '',
            update_layanan_image: '',

            remove_layanan_id: -1,
            remove_layanan_name: '',
            remove_layanan_name_confirm: '',
            remove_layanan_password_confirm: ''
        }
    )

    const onChangeHandler = event => {
        const { name, value } = event.target
        setInputValues({ [name]: value })
    }

    const onChangeImageHandler = event => {
        var img = new Image()
        setLayananImage(URL.createObjectURL(event.target.files[0]))
        img.onload = function () {
            if (img.width == 71 && img.height == 71) {
                setSelectedFile(event.target.files[0])
                setImgProper(true)
            } else {
                setImgProper(false)
            }
        }
        img.src = URL.createObjectURL(event.target.files[0])
    }

    const handleOpenCloseModalCreate = () => setShowModalCreate(!showModalCreate)
    const handleOpenCloseModalUpdate = () => setShowModalUpdate(!showModalUpdate)
    const handleOpenCloseModalRemove = () => setShowModalRemove(!showModalRemove)

    const history = useHistory()

    useEffect(() => {
        setToken(localStorage.getItem('token'))
        if(token !== ''){
            props.getLayananList(currentPage, 5 , token)
        }
    }, [token])

    useEffect(() => {
        if(token !== ''){
            props.getLayananList(currentPage, 5 , token)
        }
    }, [currentPage])

    useEffect(() => {
        if (!showModalRemove) {
            setInputValues({
                remove_layanan_name_confirm: '',
                remove_layanan_password_confirm: ''
            })
        }
    }, [showModalRemove])

    function createLayanan() {
        const data = JSON.parse(localStorage.getItem(JSON_CREDENTIALS))
        if (token !== '' && data !== {} && data !== undefined && data !== null &&
            validateEmpty(inputValues.create_layanan_name) && validateEmpty(inputValues.create_layanan_description) &&
            validateEmpty(inputValues.create_is_active) && layananImage !== null) {
            const payload = {
                creator_id: data.data.id,
                creator_name: data.data.complete_name,
                layanan_title: inputValues.create_layanan_name,
                layanan_description: inputValues.create_layanan_description,
                is_active: inputValues.create_is_active
            }
            const formData = new FormData()
            formData.append('json_data', JSON.stringify(payload))
            formData.append('file', selectedFile)
            props.createLayanan(formData, token)
        }
    }

    function updateLayanan() {
        const data = JSON.parse(localStorage.getItem(JSON_CREDENTIALS))
        if (token !== '' && data !== {} && data !== undefined && data != null &&
            validateEmpty(inputValues.update_layanan_id) && validateEmpty(inputValues.update_layanan_name) &&
            validateEmpty(inputValues.update_layanan_description) && validateEmpty(inputValues.update_is_active)) {
            const payloadUpdate = {
                creator_id: data.data.id,
                creator_name: data.data.complete_name,
                layanan_title: inputValues.update_layanan_name,
                layanan_description: inputValues.update_layanan_description,
                is_active: inputValues.update_is_active
            }
            if (selectedFile != null) {
                const formData = new FormData()
                formData.append('json_data', JSON.stringify(payloadUpdate))
                formData.append('file', selectedFile)
                props.updateLayananImage(inputValues.update_layanan_id, formData, token)
            } else {
                props.updateLayanan(inputValues.update_layanan_id, payloadUpdate, token)
            }
        }
    }

    function removeLayanan() {
        if (token !== '') {
            if (validateEmpty(inputValues.remove_layanan_id) &&
                validateEmpty(inputValues.remove_layanan_name) &&
                validateEmpty(inputValues.remove_layanan_name_confirm) &&
                validateEmpty(inputValues.remove_layanan_password_confirm) &&
                inputValues.remove_layanan_name === inputValues.remove_layanan_name_confirm) {
                props.removeLayanan(inputValues.remove_layanan_id,
                    inputValues.remove_layanan_password_confirm, token)
            }
        }
    }

    useEffect(() => {
        switch (props.layanan.payload_crud.type) {
            case LAYANAN_CREATE_LOADING:
                setLoading(true)
                break;
            case LAYANAN_CREATE_SUCCESSFUL:
                setLoading(false)
                if (token != '') {
                    setCurrentPage(1)
                    setShowModalCreate(false)
                    setSubmit(false)
                    setSelectedFile(null)
                    setLayananImage(null)
                    setInputValues({
                        create_layanan_name: '',
                        create_layanan_description: '',
                        create_is_active: ''
                    })
                    props.resetCrud()
                    if(token !== ''){
                        props.getLayananList(currentPage, 5 , token)
                    }
                }
                break;
            case LAYANAN_CREATE_EXIST:
                setLoading(false)
                props.resetCrud()
                Swal.fire(
                    'Perhatian',
                    'Layanan sudah ada',
                    'warning'
                )
                break;
            case LAYANAN_CREATE_FAILED:
                setLoading(false)
                props.resetCrud()
                Swal.fire(
                    'Perhatian',
                    'Gagal membuat layanan',
                    'error'
                )
                break;

            case LAYANAN_UPDATE_LOADING:
                setLoading(true)
                break;
            case LAYANAN_UPDATE_SUCCESSFUL:
                setLoading(false)
                if (token != '') {
                    setShowModalUpdate(false)
                    setSubmit(false)
                    setSubmitUpdate(false)
                    setSelectedFile(null)
                    setLayananImage(null)
                    setInputValues({
                        update_layanan_name: '',
                        update_layanan_description: '',
                        update_is_active: ''
                    })
                    props.resetCrud()
                    if(token !== ''){
                        props.getLayananList(currentPage, 5 , token)
                    }
                }
                break;
            case LAYANAN_UPDATE_NOT_FOUND:
                setLoading(false)
                props.resetCrud()
                Swal.fire(
                    'Perhatian',
                    'Layanan tidak ada',
                    'warning'
                )
                break;

            case LAYANAN_UPDATE_FAILED:
                setLoading(false)
                props.resetCrud()
                Swal.fire(
                    'Perhatian',
                    'Gagal memperbarui layanan',
                    'error'
                )
                break;

            case LAYANAN_REMOVE_LOADING:
                setLoading(true)
                break;
            case LAYANAN_REMOVE_SUCCESSFUL:
                setLoading(false)
                setShowModalRemove(false)
                setInputValues({
                    remove_layanan_id: -1,
                    remove_layanan_name: '',
                    remove_layanan_name_confirm: '',
                    remove_layanan_password_confirm: ''
                })
                props.resetCrud()
                if (token != '') {
                    if (props.layanan.payload.data.rows_data.docs.length == 1) {
                        setCurrentPage(1)
                    }
                    props.getLayananList(currentPage, 5 , token)
                }
                break;
            case LAYANAN_REMOVE_NOT_FOUND:
                setLoading(false)
                props.resetCrud()
                Swal.fire(
                    'Perhatian',
                    'Layanan tidak ada',
                    'warning'
                )
                break;
            case LAYANAN_REMOVE_UNAUTHORIZED:
                setLoading(false)
                props.resetCrud()
                Swal.fire(
                    'Perhatian',
                    'Kata Sandi salah',
                    'warning'
                )
                break;
            case LAYANAN_REMOVE_FAILED:
                setLoading(false)
                props.resetCrud()
                Swal.fire(
                    'Perhatian',
                    'Gagal menghapus layanan',
                    'error'
                )
                break;
        }
    }, [props.layanan])

    function validateBorderImg() {
        if (isSubmit) {
            if (layananImage == null || (layananImage != null && !imgProper)) {
                return `1px dashed ${RED_ERROR_MESSAGE}`
            } else {
                return '1px dashed black'
            }
        } else {
            if (layananImage != null && !imgProper) {
                return `1px dashed ${RED_ERROR_MESSAGE}`
            } else {
                return '1px dashed black'
            }
        }
    }

    function validateTextImg() {
        if (isSubmit) {
            if (layananImage == null) {
                return (
                    <p style={
                        erorrMsgStyle
                    }>Icon Layanan must be filled</p>
                )
            } else if (layananImage != null && !imgProper) {
                return (
                    <p style={
                        erorrMsgStyle
                    }>Icon Layanan must be 71px x 71px</p>
                )
            } else {
                return (<></>)
            }
        } else {
            if (layananImage != null && !imgProper) {
                return (
                    <p style={
                        erorrMsgStyle
                    }>Icon Layanan must be 71px x 71px</p>
                )
            } else {
                return (<></>)
            }
        }
    }

    function buildLayananPage() {
        switch (props.layanan.payload.type) {
            case LAYANAN_LIST_LOADING: {
                return (
                    <>
                        <div style={{ marginTop: '15px' }}>
                            <Row>
                                <Col lg="12">
                                    <Skeleton style={{ height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="12">
                                    <Skeleton style={{ marginTop: '25px', height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="12">
                                    <Skeleton style={{ marginTop: '25px', height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="12">
                                    <Skeleton style={{ marginTop: '25px', height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                                </Col>
                            </Row>
                        </div>
                    </>
                )
            }
            case LAYANAN_LIST_SUCCESSFUL: {
                return (
                    <>
                        <Row style={{ marginTop: '15px' }}>
                            <Col xl="12">
                                <Table className="align-items-center table-bordered table-flush" responsive>
                                    <thead className="thead-dark">
                                        <tr>
                                            <th style={{ color: 'white' }} scope="col">Nama Layanan</th>
                                            <th style={{ color: 'white' }} scope="col">Icon Layanan</th>
                                            <th colSpan={3} style={{ color: 'white' }} scope="col">Aksi</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            props.layanan.payload.data.rows_data.docs.map((data) => {
                                                return (
                                                    <tr key={data.id}>
                                                        <td style={{ fontWeight: 'bold' }}>{data.layanan_title}</td>
                                                        <td>
                                                            <img style={{
                                                                width: '25px',
                                                                height: '25px',
                                                                objectFit: 'cover'
                                                            }}
                                                                src={`${BASE_URL}/api/images${data.layanan_image}`} />
                                                        </td>
                                                        <td style={{ float: 'left', marginRight: '5px' }}>
                                                            <button onClick={() => {
                                                                history.push({
                                                                    pathname: '/admin/layanan_detail',
                                                                    state: {
                                                                        layanan_id: data.id,
                                                                        layanan_title: data.layanan_title
                                                                    }
                                                                })
                                                            }} className="btn btn-sm btn-primary"><i className="fas fa-eye"></i> Lihat</button>
                                                            <button onClick={() => {
                                                                setInputValues({
                                                                    update_layanan_id: data.id,
                                                                    update_layanan_name: data.layanan_title,
                                                                    update_layanan_description: data.layanan_description,
                                                                    update_is_active: data.is_active,
                                                                    update_layanan_image: data.layanan_image
                                                                })
                                                                console.log(`insert data : ${JSON.stringify(inputValues, null, 2)}`)
                                                                setShowModalUpdate(true)
                                                            }} className="btn btn-sm btn-primary"><i className="fas fa-edit"></i> Perbarui</button>
                                                            <button onClick={() => {
                                                                setInputValues({
                                                                    remove_layanan_id: data.id,
                                                                    remove_layanan_name: data.layanan_title
                                                                })
                                                                setShowModalRemove(true)
                                                            }} className="btn btn-sm btn-danger"><i className="fas fa-trash"></i> Hapus</button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                        <Row>
                            {
                                props.layanan.payload.data.count_total > 5 ?
                                    <Card className="bg-default-shadow" style={{ width: 'max-content', paddingLeft: '20px', marginRight: '0', marginTop: '10px', paddingTop: '15px', marginLeft: 'auto' }}>
                                        <Pagination
                                            className="pagination-bar"
                                            currentPage={currentPage}
                                            totalCount={props.layanan.payload.data.count_total}
                                            pageSize={5}
                                            onPageChange={page => {
                                                setCurrentPage(page)
                                            }} />
                                    </Card>
                                    : <div></div>
                            }
                        </Row>
                    </>
                )
            }
            case LAYANAN_LIST_NOT_FOUND: {
                return (
                    <NotFoundPage imageSource={IcNotFound} imageText={props.layanan.payload.message} />
                )
            }
            case LAYANAN_LIST_FAILED: {
                return (
                    <ErrorPage imageSource={IcError} imageText={props.layanan.payload.message} />
                )
            }
        }
    }
    return (
        <>
            <div className="main-content">
                <div className="header bg-gradient-info pb-8 pt-5 pt-lg-7 d-flex align-items-center">
                    <span className="mask bg-gradient-default opacity-8" />
                </div>
                <Container className="mt--7" fluid>
                    <Card className="bg-secondary shadow">
                        <CardBody>
                            {
                                props.layanan.payload.type !== LAYANAN_LIST_LOADING && props.layanan.payload.type !== LAYANAN_LIST_FAILED ?
                                    <Row>
                                        <Col xl="10">
                                            <p>Daftar Layanan</p>
                                        </Col>
                                        <Col xl="2">
                                            <button style={{ fontSize: '11px' }} onClick={() => {
                                                setShowModalCreate(true)
                                            }} className="btn btn-sm btn-primary">+ Tambah Layanan</button>
                                        </Col>
                                    </Row> : <div></div>
                            }
                            {buildLayananPage()}
                        </CardBody>
                    </Card>
                    <Modal
                        isOpen={showModalCreate}
                        toggle={handleOpenCloseModalCreate} closable={false} keyboard={false} centered
                    >
                        {
                            isSubmit ? isLoading ?
                                <div className="progress-bar">
                                    <div className="progress-bar-value"></div>
                                </div> :
                                <div style={{ height: '4px' }}></div> : <div></div>
                        }
                        <ModalBody>
                            <h4>Buat Layanan Baru</h4>
                            <Form onSubmit={(e) => {
                                e.preventDefault()
                                setSubmit(true)
                                createLayanan()
                            }}>
                                <Row>
                                    <Col md="6">
                                        <Row>
                                            <Col lg="12">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-title">
                                                        Nama Layanan
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        id="input-title"
                                                        type="text"
                                                        name="create_layanan_name"
                                                        onChange={onChangeHandler}
                                                        value={inputValues.create_layanan_name}
                                                        style={
                                                            validateErrorMessage(isSubmit && inputValues.create_layanan_name === '' || inputValues.create_layanan_name == null)
                                                        }
                                                    />
                                                    <p style={{
                                                        color: MaterialRed1,
                                                        fontSize: '11px',
                                                        visibility: isSubmit && inputValues.create_layanan_name === '' || inputValues.create_layanan_name == null ? 'visible' : 'hidden'
                                                    }}>Layanan Name must be filled</p>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row style={{ marginTop: '-25px' }}>
                                            <Col lg="12">
                                                <label
                                                    className="form-control-label"
                                                >
                                                    Aktifkan Layanan?
                                                </label>
                                                <div style={{
                                                    border: isSubmit && inputValues.create_is_active === '' || inputValues.create_is_active == null ?
                                                        `0.2px solid ${RED_ERROR_MESSAGE}` : 'none',
                                                    borderRadius: '6px'
                                                }}>
                                                    <Row>
                                                        <Col md="5">
                                                            <input
                                                                id="active"
                                                                type="radio"
                                                                style={{
                                                                    cursor: 'pointer'
                                                                }}
                                                                checked={inputValues.create_is_active === '1'}
                                                                onChange={e => {
                                                                    setInputValues({
                                                                        create_is_active: '1'
                                                                    })
                                                                }} />
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="active" style={{ fontSize: '13px' }}>Yes</label>
                                                        </Col>
                                                        <Col md="5">
                                                            <input
                                                                id="no_active"
                                                                type="radio"
                                                                style={{ cursor: 'pointer', marginRight: '5px' }}
                                                                checked={inputValues.create_is_active === '0'}
                                                                onChange={e => {
                                                                    setInputValues({
                                                                        create_is_active: '0'
                                                                    })
                                                                }} />
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="no_active" style={{ fontSize: '13px' }}>No</label>
                                                        </Col>
                                                    </Row>
                                                </div>
                                                <p style={{
                                                    color: MaterialRed1,
                                                    fontSize: '11px',
                                                    visibility: isSubmit && inputValues.create_is_active === '' ? 'visible' : 'hidden'
                                                }}>Aktifkan Layanan must be select</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="12">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-layanan-description"
                                                    >
                                                        Deskripsi Layanan
                                                    </label>
                                                    <Input
                                                        type="textarea"
                                                        id="input-layanan-description"
                                                        name="create_layanan_description"
                                                        style={
                                                            validateErrorMessage(isSubmit && inputValues.create_layanan_description === '' || inputValues.create_layanan_description == null)
                                                        }
                                                        value={inputValues.create_layanan_description}
                                                        onChange={onChangeHandler} />
                                                    <p style={{
                                                        color: MaterialRed1,
                                                        fontSize: '11px',
                                                        visibility: isSubmit && inputValues.create_layanan_description === '' || inputValues.create_layanan_description == null ? 'visible' : 'hidden'
                                                    }}>Deskripsi Layanan must be filled</p>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col lg="6">
                                        <label
                                            className="form-control-label">Icon Layanan</label>
                                        <div id="placeholder_image"
                                            style={{
                                                width: '100%',
                                                height: '260px',
                                                borderRadius: '10px',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                display: 'flex',
                                                backgroundColor: MaterialGray2,
                                                border: validateBorderImg()
                                            }}
                                        >
                                            <input type="file" name="image_picker" onChange={onChangeImageHandler} ref={fileRef} hidden />
                                            {
                                                layananImage && imgProper ?
                                                    <div style={{
                                                        width: '100%'
                                                    }}>
                                                        <img style={{ width: '100%', height: '258px', borderRadius: '10px' }} src={layananImage} />
                                                        <div
                                                            style={{
                                                                width: '25px',
                                                                height: '25px',
                                                                borderRadius: '50%',
                                                                backgroundColor: MaterialRed1,
                                                                position: 'absolute',
                                                                right: 8,
                                                                top: 22,
                                                                cursor: 'pointer'
                                                            }}
                                                            onClick={() => {
                                                                setSelectedFile(null)
                                                                setLayananImage(null)
                                                            }}
                                                        >
                                                            <p style={{ textAlign: 'center', color: 'white' }}>&times;</p>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div
                                                        style={{
                                                            flexDirection: 'column',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            display: 'flex',
                                                            cursor: 'pointer',
                                                            paddingLeft: '20px',
                                                            paddingTop: '20px',
                                                            paddingRight: '20px',
                                                            paddingBottom: '20px'
                                                        }}
                                                        onClick={() => {
                                                            fileRef.current.click()
                                                        }}
                                                    >
                                                        <img src={IcImage} style={{ textAlign: 'center', width: '50px', height: '50px' }} />
                                                        <p style={{ marginTop: '5px', display: 'inline-block', color: MaterialGray1, textAlign: 'center' }}>Choose image<br />71px<br />X<br />71px</p>
                                                    </div>
                                            }
                                        </div>
                                        {validateTextImg()}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="12">
                                        <button style={{ width: '100%' }} type="submit" className="btn btn-sm btn-success">Simpan</button>
                                    </Col>
                                </Row>
                            </Form>
                        </ModalBody>
                    </Modal>

                    <Modal isOpen={showModalUpdate}
                        toggle={handleOpenCloseModalUpdate} closable={false} keyboard={false} centered
                    >
                        {
                            isSubmit ? isLoading ?
                                <div className="progress-bar">
                                    <div className="progress-bar-value"></div>
                                </div> :
                                <div style={{ height: '4px' }}></div> : <div></div>
                        }
                        <ModalBody>
                            <h4>Perbarui Layanan</h4>
                            <Form onSubmit={(e) => {
                                e.preventDefault()
                                if (submitUpdate) {
                                    setSubmit(true)
                                    updateLayanan()
                                }
                            }}>
                                <Row>
                                    <Col md="6">
                                        <Row>
                                            <Col lg="12">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-update-layanan-name">
                                                        Nama Layanan
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        id="input-update-layanan-name"
                                                        type="text"
                                                        name="update_layanan_name"
                                                        onChange={onChangeHandler}
                                                        value={inputValues.update_layanan_name}
                                                        style={
                                                            validateErrorMessage(isSubmit && inputValues.update_layanan_name === '' || inputValues.update_layanan_name == null)
                                                        }
                                                    />
                                                    <p style={{
                                                        color: MaterialRed1,
                                                        fontSize: '11px',
                                                        visibility: isSubmit && inputValues.update_layanan_name === '' || inputValues.update_layanan_name == null ? 'visible' : 'hidden'
                                                    }}>Layanan Name must be filled</p>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row style={{ marginTop: '-25px' }}>
                                            <Col lg="12">
                                                <label
                                                    className="form-control-label"
                                                >
                                                    Aktifkan Layanan?
                                                </label>
                                                <div style={{
                                                    border: isSubmit && inputValues.update_is_active === '' || inputValues.update_is_active == null ?
                                                        `0.2px solid ${RED_ERROR_MESSAGE}` : 'none',
                                                    borderRadius: '6px'
                                                }}>
                                                    <Row>
                                                        <Col md="5">
                                                            <input
                                                                id="update_active_yes"
                                                                type="radio"
                                                                style={{
                                                                    cursor: 'pointer'
                                                                }}
                                                                checked={inputValues.update_is_active === '1'}
                                                                onChange={e => {
                                                                    setInputValues({
                                                                        update_is_active: '1'
                                                                    })
                                                                }} />
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="update_active_yes" style={{ fontSize: '13px' }}>Yes</label>
                                                        </Col>
                                                        <Col md="5">
                                                            <input
                                                                id="update_no_active"
                                                                type="radio"
                                                                style={{ cursor: 'pointer', marginRight: '5px' }}
                                                                checked={inputValues.update_is_active === '0'}
                                                                onChange={e => {
                                                                    setInputValues({
                                                                        update_is_active: '0'
                                                                    })
                                                                }} />
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="update_no_active" style={{ fontSize: '13px' }}>No</label>
                                                        </Col>
                                                    </Row>
                                                </div>
                                                <p style={{
                                                    color: MaterialRed1,
                                                    fontSize: '11px',
                                                    visibility: isSubmit && inputValues.update_is_active === '' ? 'visible' : 'hidden'
                                                }}>Aktifkan Layanan must be select</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="12">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-update-layanan-description"
                                                    >
                                                        Deskripsi Layanan
                                                    </label>
                                                    <Input
                                                        type="textarea"
                                                        id="input-update-layanan-description"
                                                        name="update_layanan_description"
                                                        style={
                                                            validateErrorMessage(isSubmit && inputValues.update_layanan_description === '' || inputValues.update_layanan_description == null)
                                                        }
                                                        value={inputValues.update_layanan_description}
                                                        onChange={onChangeHandler} />
                                                    <p style={{
                                                        color: MaterialRed1,
                                                        fontSize: '11px',
                                                        visibility: isSubmit && inputValues.update_layanan_description === '' || inputValues.update_layanan_description == null ? 'visible' : 'hidden'
                                                    }}>Deskripsi Layanan must be filled</p>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col lg="6">
                                        <Row>
                                            <Col md="12">
                                                <label
                                                    className="form-control-label">Icon Layanan</label>
                                                <div id="placeholder_image"
                                                    style={{
                                                        width: '100%',
                                                        height: '260px',
                                                        borderRadius: '10px',
                                                        flexDirection: 'column',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        display: 'flex',
                                                        backgroundColor: MaterialGray2,
                                                        border: validateBorderImg()
                                                    }}
                                                >
                                                    <input type="file" name="image_picker" onChange={onChangeImageHandler} ref={fileRefUpdate} hidden />
                                                    {
                                                        layananImage && imgProper ?
                                                            <div style={{
                                                                width: '100%'
                                                            }}>
                                                                <img style={{ width: '100%', height: '258px', borderRadius: '10px' }} src={layananImage} />
                                                                <div
                                                                    style={{
                                                                        width: '25px',
                                                                        height: '25px',
                                                                        borderRadius: '50%',
                                                                        backgroundColor: MaterialRed1,
                                                                        position: 'absolute',
                                                                        right: 8,
                                                                        top: 22,
                                                                        cursor: 'pointer'
                                                                    }}
                                                                    onClick={() => {
                                                                        setSelectedFile(null)
                                                                        setLayananImage(null)
                                                                    }}
                                                                >
                                                                    <p style={{ textAlign: 'center', color: 'white' }}>&times;</p>
                                                                </div>
                                                            </div>
                                                            :
                                                            <div
                                                                style={{
                                                                    flexDirection: 'column',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    display: 'flex',
                                                                    cursor: 'pointer',
                                                                    paddingLeft: '20px',
                                                                    paddingTop: '20px',
                                                                    paddingRight: '20px',
                                                                    paddingBottom: '20px'
                                                                }}
                                                            >
                                                                <img src={
                                                                    `${BASE_URL}/api/images/${inputValues.update_layanan_image}`
                                                                } style={{ textAlign: 'center', width: '50px', height: '50px' }} />
                                                            </div>
                                                    }
                                                </div>
                                                {validateTextImg()}
                                            </Col>
                                        </Row>
                                        <Row style={{ marginTop: '5px' }}>
                                            <Col sm="6">
                                                <button onClick={() => {
                                                    fileRefUpdate.current.click()
                                                }} style={{
                                                    fontSize: '11px'
                                                }} className="btn btn-sm btn-primary">Pilih Gambar</button>
                                            </Col>
                                            <Col sm="6">
                                                <button onClick={() => {
                                                    setSelectedFile(null)
                                                    setLayananImage(null)
                                                }} style={
                                                    layananImage != null ? {
                                                        fontSize: '10px',
                                                        pointerEvents: 'auto',
                                                        color: 'white',
                                                        backgroundColor: MaterialRed1
                                                    } : {
                                                        fontSize: '10px',
                                                        pointerEvents: 'auto',
                                                        color: 'white',
                                                        backgroundColor: MaterialGray1
                                                    }
                                                } className="btn btn-sm btn-secondary" disabled={layananImage == null ? 'disabled' : ''}>Hapus Gambar</button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '5px' }}>
                                    <Col lg="12">
                                        <button onClick={() => {
                                            setSubmitUpdate(true)
                                        }} style={{ width: '100%' }} type="submit" className="btn btn-sm btn-success">Perbarui</button>
                                    </Col>
                                </Row>
                            </Form>
                        </ModalBody>
                    </Modal>

                    <Modal isOpen={showModalRemove}
                        toggle={handleOpenCloseModalRemove} closable={false} keyboard={false} centered
                    >
                        {
                            isSubmit ? isLoading ?
                                <div className="progress-bar">
                                    <div className="progress-bar-value"></div>
                                </div> :
                                <div style={{ height: '4px' }}></div> : <div></div>
                        }
                        <ModalBody>
                            <h4>Hapus Layanan</h4>
                            <p>Kamu yakin ingin menghapus layanan <strong>{inputValues.remove_layanan_name}?</strong>, ketik nama layanan yang ingin di hapus dan masukan kata sandi untuk konfirmasi</p>
                            <Form onSubmit={(e) => {
                                e.preventDefault()
                                removeLayanan()
                            }}>
                                <Row>
                                    <Col xl="12">
                                        <Input
                                            className="form-control-alternative"
                                            type="text"
                                            name="remove_layanan_name_confirm"
                                            placeholder="Ketik nama layanan di sini"
                                            onChange={onChangeHandler}
                                            value={inputValues.remove_layanan_name_confirm}
                                            style={{
                                                border: inputValues.remove_layanan_name_confirm === inputValues.remove_layanan_name ? 'none' : `1px solid ${RED_ERROR_MESSAGE}`
                                            }}
                                            disabled={isLoading ? 'disabled' : ''} />
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '8px' }}>
                                    <Col xl="12">
                                        <Input
                                            className="form-control-alternative"
                                            type="password"
                                            name="remove_layanan_password_confirm"
                                            onChange={onChangeHandler}
                                            placeholder="Ketik kata sandi di sini"
                                            value={inputValues.remove_layanan_password_confirm}
                                            style={{
                                                border: validateEmpty(inputValues.remove_layanan_password_confirm) ? 'none' : `1px solid ${RED_ERROR_MESSAGE}`
                                            }}
                                            disabled={isLoading ? 'disabled' : ''} />
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '8px' }}>
                                    <Col xl="12">
                                        <button style={{
                                            width: '100%',
                                            backgroundColor: ((inputValues.remove_layanan_name === inputValues.remove_layanan_name_confirm) && validateEmpty(inputValues.remove_layanan_password_confirm)) ?
                                                MaterialRed1 : MaterialGray1,
                                            border: 'none'
                                        }} className="btn btn-md btn-danger" disabled={(inputValues.remove_layanan_name === inputValues.remove_layanan_name_confirm) && validateEmpty(inputValues.remove_layanan_password_confirm) ? isLoading ? 'disabled' : '' : 'disabled'}
                                        >Hapus Layanan</button>
                                    </Col>
                                </Row>
                            </Form>
                        </ModalBody>
                    </Modal>
                </Container>
            </div>
        </>
    )
}

LayananPage.propTypes = {
    layanan: PropTypes.object.isRequired,
    getLayananList: PropTypes.func.isRequired,
    createLayanan: PropTypes.func.isRequired,
    updateLayanan: PropTypes.func.isRequired,
    updateLayananImage: PropTypes.func.isRequired,
    removeLayanan: PropTypes.func.isRequired,
    resetCrud: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    layanan: state.layanan
})

export default connect(mapStateToProps, { getLayananList, createLayanan, updateLayanan, updateLayananImage, removeLayanan, resetCrud })(LayananPage)