import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
    Container,
    Card,
    Row,
    Col,
    CardBody,
    Form,
    FormGroup,
    Input
} from 'reactstrap';
import Sidebar from 'components/Sidebar/Sidebar';
import AdminNavbar from 'components/Navbars/AdminNavbar';
import routes from '../../../routes';
import { useEffect, useRef, useReducer, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { createBanner } from 'actions/Banners/BannerService';
import { CREATE_BANNER_LOADING, CREATE_BANNER_SUCCESSFUL, CREATE_BANNER_FAILED } from 'actions/Banners/Types';
import '../../../assets/css/utilities.css';
import Swal from 'sweetalert2';
import IcImage from '../../../assets/img/ic_image.svg';
import { useHistory } from 'react-router-dom';
import { MaterialGray2 } from 'components/Utilities/ResourceColors';
import withReactContent from 'sweetalert2-react-content';
import { MaterialGray1 } from 'components/Utilities/ResourceColors';
import { MaterialRed1 } from 'components/Utilities/ResourceColors';
import { getJSONCredential } from 'actions/config';
import Select from 'react-select';
import { RED_ERROR_MESSAGE } from 'components/Utilities/ResourceColors';
import { erorrMsgStyle } from 'components/Utilities/Utility';

const MySwal = withReactContent(Swal)

const BannerCreatePage = (props) => {
    const options = [
        {
            value: 'promo', label: 'Promo'
        },
        {
            value: 'coupon', label: 'Coupon'
        },
        {
            value: 'visi misi', label: 'Visi Misi'
        }
    ]
    const mainContent = useRef(null)
    const location = useLocation()
    const history = useHistory()
    const fileRef = useRef()
    const [isBroadcast, setBroadcast] = useState(false)
    const [isSubmit, setSubmit] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const [selectedFile, setSelectedFile] = useState(null)
    const [bannerImage, setBannerImage] = useState(null)
    const [imgProper , setImgProper] = useState(false)
    const [token , setToken] = useState('')
    const [inputValues, setInputValues] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            banner_title: '',
            banner_body: '',
            banner_type: {
                label: 'Promo',
                value: 'promo'
            },
            banner_image: ''
        }
    )
    const onChangeHandler = event => {
        const { name, value } = event.target
        setInputValues({ [name]: value })
    }
    const onChangeImageHandler = event => {
        var img = new Image()
        setBannerImage(URL.createObjectURL(event.target.files[0]))
        img.onload = function () {
            console.log(`Width : ${img.width} Height : ${img.height}`)
            if (img.width == 1280 && img.height == 720) {
                setSelectedFile(event.target.files[0])
                setImgProper(true)
            } else {
                setImgProper(false)
            }
        }
        img.src = URL.createObjectURL(event.target.files[0])
    }
    function validateBorderImg() {
        if (isSubmit) {
            if (bannerImage == null || (bannerImage != null && !imgProper)) {
                return `1px dashed ${RED_ERROR_MESSAGE}`
            }
        } else {
            if (bannerImage != null && !imgProper) {
                return `1px dashed ${RED_ERROR_MESSAGE}`
            }
        }
    }
    function validateTextImg() {
        if (isSubmit) {
            if (bannerImage == null) {
                return (
                    <p style={
                        erorrMsgStyle
                    }>Gambar Paket must be filled</p>
                )
            } else if (bannerImage != null && !imgProper) {
                return (
                    <p style={
                        erorrMsgStyle
                    }>Gambar Paket must be 1280px X 720px</p>
                )
            } else {
                return (<></>)
            }
        } else {
            if (bannerImage != null && !imgProper) {
                return (
                    <p style={
                        erorrMsgStyle
                    }>Gambar Paket must be 1280px X 720px</p>
                )
            } else {
                return (<></>)
            }
        }
    }
    useEffect(() => {
        setToken(localStorage.getItem('token'))
    } , [token])
    useEffect(() => {
        if (!localStorage.getItem('token')) {
            history.push('/auth/login')
        }
    }, [location])
    useEffect(() => {
        switch (props.banner.payload_banner.type) {
            case CREATE_BANNER_LOADING:
                setLoading(true)
                break;
            case CREATE_BANNER_SUCCESSFUL:
                setLoading(false)
                history.goBack()
                MySwal.fire(
                    'Perhatian',
                    'Berhasil Membuat Banner',
                    'success'
                )
                break;
            case CREATE_BANNER_FAILED:
                setLoading(false)
                MySwal.fire(
                    'Perhatian',
                    'Gagal Membuat Banner',
                    'error'
                )
                break;
        }
    }, [props.banner.payload_banner])

    function submitCreateBanner() {
        setSubmit(true)
        const {
            banner_title,
            banner_body
        } = inputValues
        const banner_type = inputValues.banner_type.value
        if (selectedFile != null && banner_title !== '' && banner_body !== '' && banner_type !== '' &&
            bannerImage !== null) {
            const formData = new FormData()
            const credential = getJSONCredential()['data']
            let banner_type_selected = inputValues.banner_type.value
            const payload_json = {
                creator_id: credential['id'],
                creator_name: credential['complete_name'],
                banner_title: banner_title,
                banner_body: banner_body,
                banner_type: banner_type_selected,
                is_broadcast: isBroadcast ? '1' : '0'
            }
            formData.append('json_data', JSON.stringify(payload_json))
            formData.append('file', selectedFile)
            props.createBanner(formData , token)
        }
    }

    return (
        <>
            <div className="main-content" ref={mainContent}>
                <div className="header bg-gradient-info pb-8 pt-5 pt-lg-7 d-flex align-items-center">
                    <span className="mask bg-gradient-default opacity-8" />
                </div>
                <Container className="mt--7" fluid>
                    <Row>
                        <Col className="order-xl-1" xl="12">
                            <Card className="bg-secondary-shadow">
                                {
                                    isLoading ?
                                        <div style={{
                                            borderTopLeftRadius: '5px',
                                            borderTopRightRadius: '5px',
                                            height: '5px'
                                        }} className="progress-bar">
                                            <div className="progress-bar-value"></div>
                                        </div>
                                        : <div style={{
                                            height: '5px'
                                        }}></div>
                                }
                                <CardBody>
                                    <Form>
                                        <div>
                                            <Row>
                                                <Col md="6">
                                                    <Row>
                                                        <Col lg="12">
                                                            <FormGroup>
                                                                <label
                                                                    className="form-control-label"
                                                                    htmlFor="input-title">
                                                                    Banner Title
                                                                </label>
                                                                <Input
                                                                    className="form-control-alternative"
                                                                    id="input-title"
                                                                    type="text"
                                                                    name="banner_title"
                                                                    onChange={onChangeHandler}
                                                                    value={inputValues.banner_title}
                                                                    style={
                                                                        isSubmit && inputValues.banner_title === '' ?
                                                                            {
                                                                                color: 'black',
                                                                                border: '0.2px solid #d32f2f'
                                                                            }
                                                                            :
                                                                            {
                                                                                color: 'black',
                                                                                border: 'none'
                                                                            }
                                                                    } />
                                                                <p style={{
                                                                    color: MaterialRed1,
                                                                    fontSize: '11px',
                                                                    marginTop: '5px',
                                                                    visibility: isSubmit && inputValues.banner_title === '' ? 'visible' : 'hidden'
                                                                }}>Banner Title must be filled</p>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col lg="6">
                                                            <FormGroup>
                                                                <label
                                                                    className="form-control-label"
                                                                    htmlFor="input-select-type"
                                                                >
                                                                    Banner Type
                                                                </label>
                                                                <Select
                                                                    id="input-select-type"
                                                                    options={options}
                                                                    value={
                                                                        inputValues.banner_type
                                                                    }
                                                                    name="banner_type"
                                                                    onChange={(selectedOption) => {
                                                                        setInputValues({ ['banner_type']: selectedOption })
                                                                    }} />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col lg="6">
                                                            <label
                                                                className="form-control-label"
                                                            >
                                                                Blast Banner?
                                                            </label>
                                                            <Row>
                                                                <Col md="5">
                                                                    <input
                                                                        id="is_broadcast"
                                                                        type="radio"
                                                                        name="is_broadcast_yes"
                                                                        style={{ marginRight: '5px' }}
                                                                        checked={isBroadcast === true}
                                                                        onChange={e => {
                                                                            setBroadcast(true)
                                                                        }} />
                                                                    <label
                                                                        className="form-control-label"
                                                                        htmlFor="is_broadcast" style={{ fontSize: '13px' }}>Yes</label>
                                                                </Col>
                                                                <Col md="5">
                                                                    <input
                                                                        id="is_broadcast_no"
                                                                        type="radio"
                                                                        name="is_broadcast_no"
                                                                        style={{ marginRight: '5px' }}
                                                                        checked={isBroadcast === false}
                                                                        onChange={e => {
                                                                            setBroadcast(false)
                                                                        }} />
                                                                    <label
                                                                        className="form-control-label"
                                                                        htmlFor="is_broadcast_no" style={{ fontSize: '13px' }}>No</label>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col lg="12">
                                                            <FormGroup>
                                                                <label
                                                                    className="form-control-label"
                                                                    htmlFor="input-banner-description"
                                                                >
                                                                    Banner Description
                                                                </label>
                                                                <Input
                                                                    type="textarea"
                                                                    id="input-banner-description"
                                                                    name="banner_body"
                                                                    defaultValue={inputValues.banner_body}
                                                                    style={{
                                                                        height: '150px',
                                                                        padding: '0.4em',
                                                                        textAlign: 'left',
                                                                        color: 'black'
                                                                    }}
                                                                    onChange={onChangeHandler} />
                                                                <p style={{
                                                                    color: MaterialRed1,
                                                                    fontSize: '11px',
                                                                    marginTop: '5px',
                                                                    visibility: isSubmit && inputValues.banner_body === '' ? 'visible' : 'hidden'
                                                                }}>Banner Description must be filled</p>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col lg="6">
                                                    <div>
                                                        <label
                                                            className="form-control-label">Banner Image</label>
                                                        {
                                                            <div style={{
                                                                alignItems: 'center',
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                border: bannerImage === null ? '1px dashed black' : '1px solid transparent',
                                                                borderRadius: '10px'
                                                            }}>
                                                                {
                                                                    bannerImage === null ?
                                                                        <div style={{
                                                                            alignItems: 'center',
                                                                            display: 'flex',
                                                                            width: '100%',
                                                                            height: '260px',
                                                                            border: validateBorderImg(),
                                                                            borderRadius: '10px',
                                                                            justifyContent: 'center'
                                                                        }}>
                                                                            <img src={IcImage} style={{ textAlign: 'center', width: '50px', height: '50px' }} />
                                                                        </div>
                                                                        :
                                                                        <div style={{
                                                                            width: '100%',
                                                                            height: '260px',
                                                                            border: validateBorderImg(),
                                                                            borderRadius: '10px',
                                                                            alignItems: 'center',
                                                                            justifyContent: 'center',
                                                                            textAlign: 'center'
                                                                        }}>
                                                                            <div style={{
                                                                                width: '25px',
                                                                                height: '25px',
                                                                                borderRadius: '50%',
                                                                                backgroundColor: MaterialRed1,
                                                                                position: 'absolute',
                                                                                right: 8,
                                                                                top: 22,
                                                                                cursor: 'pointer'
                                                                            }}
                                                                                onClick={() => {
                                                                                    setSelectedFile(null)
                                                                                    setBannerImage(null)
                                                                                }}
                                                                            >
                                                                                <p style={{ color: 'white' }}>&times;</p>
                                                                            </div>
                                                                            <img src={bannerImage} style={{
                                                                                width: '100%',
                                                                                objectFit: 'cover',
                                                                                borderRadius: '10px',
                                                                                height: '260px',
                                                                                display: 'inline-block'
                                                                            }} />
                                                                        </div>
                                                                }
                                                            </div>
                                                        }
                                                        <Row>
                                                            <Col sm="8">
                                                                <p style={{ fontSize: '13px', marginTop: '10px' }}>
                                                                    Ukuran Gambar 1280px X 720px
                                                                </p>
                                                            </Col>
                                                            <Col sm="4">
                                                                <div>
                                                                    <input type="file" onChange={onChangeImageHandler} name="image_picker_change" ref={fileRef} hidden />
                                                                    <button onClick={(e) => {
                                                                        e.preventDefault()
                                                                        if (!isLoading) {
                                                                            fileRef.current.click()
                                                                        }
                                                                    }} style={{
                                                                        marginTop: '10px',
                                                                        width: '100%'
                                                                    }} className="btn btn-sm btn-primary">Ambil gambar</button>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xl="12">
                                                                {validateTextImg()}
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="12">
                                                    <a onClick={() => submitCreateBanner()} style={{ width: '100%' }} className="btn btn-success btn-sm">
                                                        Simpan
                                                    </a>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

BannerCreatePage.propTypes = {
    banner: PropTypes.object.isRequired,
    createBanner: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    banner: state.banner
})

export default connect(mapStateToProps, { createBanner })(BannerCreatePage)