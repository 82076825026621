import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
    Container,
    Card,
    Row,
    Col,
    CardBody,
    Form,
    FormGroup,
    Input
} from 'reactstrap';
import 'assets/css/editor.css';
import { useEffect, useRef, useReducer, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { createHelp, resetCrud } from 'actions/Help/HelpService';
import { 
    HELP_CREATE_LOADING, 
    HELP_CREATE_SUCCESSFUL, 
    HELP_CREATE_FAILED 
} from 'actions/Help/Types';
import '../../../assets/css/utilities.css';
import Swal from 'sweetalert2';
import IcImage from '../../../assets/img/ic_image.svg';
import { useHistory } from 'react-router-dom';
import withReactContent from 'sweetalert2-react-content';
import { MaterialRed1 } from 'components/Utilities/ResourceColors';
import Select from 'react-select';
import { RED_ERROR_MESSAGE } from 'components/Utilities/ResourceColors';
import { erorrMsgStyle } from 'components/Utilities/Utility';
import CKEditor from "react-ckeditor-component";
import { validateEmpty } from 'components/Utilities/Utility';

const MySwal = withReactContent(Swal)

const HelpCreatePage = (props) => {
    const [token, setToken] = useState('')
    const options = [
        {
            value: 'customer', label: 'Customer'
        },
        {
            value: 'mitra', label: 'Mitra'
        }
    ]
    const mainContent = useRef(null)
    const location = useLocation()
    const history = useHistory()
    const [isBroadcast, setBroadcast] = useState(false)
    const [isSubmit, setSubmit] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const [selectedFile, setSelectedFile] = useState(null)
    const [inputValues, setInputValues] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            title: '',
            description: '',
            help_type: {
                label: 'Customer',
                value: 'customer'
            },
        }
    )
    const onBlur = (evt) => {
        console.log('onBlur')
    }
    const afterPaste = (evt) => {
        console.log('after paste')
    }
    const onChange = (evt) => {
        setInputValues({['description'] : evt.editor.getData()})
    }
    useEffect(() => {
        setToken(localStorage.getItem('token'))
    }, [token])
    const onChangeHandler = event => {
        const { name, value } = event.target
        setInputValues({ [name]: value })
    }
    useEffect(() => {
        if (!localStorage.getItem('token')) {
            history.push('/auth/login')
        }
    }, [location])
    useEffect(() => {
        switch (props.help.payload_crud.type) {
            case HELP_CREATE_LOADING:
                setLoading(true)
                break;
            case HELP_CREATE_SUCCESSFUL:
                setLoading(false)
                history.goBack()
                MySwal.fire(
                    'Perhatian',
                    'Berhasil Membuat Bantuan',
                    'success'
                )
                props.resetCrud()
                break;
            case HELP_CREATE_FAILED:
                setLoading(false)
                MySwal.fire(
                    'Perhatian',
                    'Gagal Membuat Bantuan',
                    'error'
                )
                break;
        }
    }, [props.help.payload_crud])

    function submitCreateHelp() {
        setSubmit(true)
        const {
            title,
            description
        } = inputValues
        const help_type = inputValues.help_type.value
        if (title !== '' && description !== '<p></p>' && help_type !== '') {
            let help_type_selected = inputValues.help_type.value
            const payload_json = {
                title: title,
                description: description,
                help_type: help_type_selected,
            }
            props.createHelp(payload_json , token)
        }
    }

    return (
        <>
            <div className="main-content" ref={mainContent}>
                <div className="header bg-gradient-info pb-8 pt-5 pt-lg-7 d-flex align-items-center">
                    <span className="mask bg-gradient-default opacity-8" />
                </div>
                <Container className="mt--7" fluid>
                    <Row>
                        <Col className="order-xl-1" xl="12">
                            <Card className="bg-secondary-shadow">
                                {
                                    isLoading ?
                                        <div style={{
                                            borderTopLeftRadius: '5px',
                                            borderTopRightRadius: '5px',
                                            height: '5px'
                                        }} className="progress-bar">
                                            <div className="progress-bar-value"></div>
                                        </div>
                                        : <div style={{
                                            height: '5px'
                                        }}></div>
                                }
                                <CardBody>
                                    <Form>
                                        <div>
                                            <Row>
                                                <Col lg="12">
                                                    <Row>
                                                        <Col lg="6">
                                                            <FormGroup>
                                                                <label
                                                                    className="form-control-label"
                                                                    htmlFor="input-title">
                                                                    Judul Bantuan
                                                                </label>
                                                                <Input
                                                                    className="form-control-alternative"
                                                                    id="input-title"
                                                                    type="text"
                                                                    name="title"
                                                                    onChange={onChangeHandler}
                                                                    value={inputValues.title}
                                                                    style={
                                                                        isSubmit && inputValues.title === '' ?
                                                                            {
                                                                                color: 'black',
                                                                                border: '0.2px solid #d32f2f'
                                                                            }
                                                                            :
                                                                            {
                                                                                color: 'black',
                                                                                border: 'none'
                                                                            }
                                                                    } />
                                                                <p style={{
                                                                    color: MaterialRed1,
                                                                    fontSize: '11px',
                                                                    marginTop: '5px',
                                                                    visibility: isSubmit && inputValues.title === '' ? 'visible' : 'hidden'
                                                                }}>Judul Bantuan must be filled</p>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col lg="6">
                                                            <FormGroup>
                                                                <label
                                                                    className="form-control-label"
                                                                    htmlFor="input-select-type"
                                                                >
                                                                    Tipe Bantuan
                                                                </label>
                                                                <Select
                                                                    id="input-select-type"
                                                                    options={options}
                                                                    value={
                                                                        inputValues.help_type
                                                                    }
                                                                    name="help_type"
                                                                    onChange={(selectedOption) => {
                                                                        setInputValues({ ['help_type']: selectedOption })
                                                                    }} />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col lg="12">
                                                    <Row style={{ marginTop : '-20px' }}>
                                                        <Col style={{ height: '400px' }} lg="12">
                                                            <FormGroup>
                                                                <label
                                                                    className="form-control-label"
                                                                    htmlFor="input-help-description"
                                                                >
                                                                    Isi Bantuan
                                                                </label>
                                                                <div style={{
                                                                    height: '328px',
                                                                    border: isSubmit && !validateEmpty(inputValues.description) ? `1px solid ${RED_ERROR_MESSAGE}` : '1px solid black',
                                                                    borderRadius: '2px'
                                                                }}>
                                                                    <CKEditor
                                                                        content={inputValues.description}
                                                                        events={{
                                                                            blur: onBlur,
                                                                            afterPaste: afterPaste,
                                                                            change: onChange
                                                                        }}
                                                                    />
                                                                </div>
                                                                <p style={{
                                                                    color: MaterialRed1,
                                                                    fontSize: '11px',
                                                                    marginTop: '5px',
                                                                    visibility: isSubmit && !validateEmpty(inputValues.description) ? 'visible' : 'hidden'
                                                                }}>Isi Bantuan must be filled</p>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="12">
                                                    <a onClick={() => submitCreateHelp()} style={{ width: '100%' }} className="btn btn-success btn-sm">
                                                        Simpan
                                                    </a>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

HelpCreatePage.propTypes = {
    help: PropTypes.object.isRequired,
    createHelp: PropTypes.func.isRequired,
    resetCrud: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    help: state.help
})

export default connect(mapStateToProps, { createHelp, resetCrud })(HelpCreatePage)