import { SUB_SERVICE_UPDATE_SUCCESSFUL } from 'actions/SubService/Types';
import {
    SUB_SERVICE_CREATE_LOADING ,
    SUB_SERVICE_CREATE_SUCCESSFUL ,
    SUB_SERVICE_CREATE_FAILED ,
    SUB_SERVICE_UPDATE_LOADING , 
    SUB_SERVICE_UPDATE_NOT_FOUND , 
    SUB_SERVICE_UPDATE_FAILED,
    SUB_SERVICE_REMOVE_LOADING,
    SUB_SERVICE_REMOVE_SUCCESSFUL,
    SUB_SERVICE_REMOVE_UNAUTHORIZED,
    SUB_SERVICE_REMOVE_NOT_FOUND,
    SUB_SERVICE_REMOVE_FAILED,

    SUB_SERVICE_RESET_CRUD
} from 'actions/SubService/Types';

const initState = {
    sub_service_create : {
        type : null ,
        message : ''
    },
    sub_service_update : {
        type : null , 
        message : ''
    },
    sub_service_remove : {
        type : null,
        message : ''
    }
}

export default function(state = initState , action){
    switch(action.type){
        case SUB_SERVICE_CREATE_LOADING : {
            return {
                ...state ,
                sub_service_create : {
                    type : action.type,
                    message : action.message
                }
            }
        }
        case SUB_SERVICE_CREATE_SUCCESSFUL : {
            return {
                ...state ,
                sub_service_create : {
                    type : action.type,
                    message : action.message
                }
            }
        }
        case SUB_SERVICE_CREATE_FAILED : {
            return {
                ...state ,
                sub_service_create : {
                    type : action.type,
                    message : action.message
                }
            }
        }
        case SUB_SERVICE_UPDATE_LOADING : {
            return {
                ...state ,
                sub_service_update : {
                    type : action.type,
                    message : action.message
                }
            }
        }
        case SUB_SERVICE_UPDATE_SUCCESSFUL : {
            return {
                ...state ,
                sub_service_update : {
                    type : action.type,
                    message : action.message,
                    returning_data : action.returning_data
                }
            }
        }
        case SUB_SERVICE_UPDATE_NOT_FOUND : {
            return {
                ...state ,
                sub_service_update : {
                    type : action.type,
                    message : action.message
                }
            }
        }
        case SUB_SERVICE_UPDATE_FAILED : {
            return {
                ...state ,
                sub_service_update : {
                    type : action.type,
                    message : action.message
                }
            }
        }
        case SUB_SERVICE_REMOVE_LOADING:
            return {
                ...state,
                sub_service_remove : {
                    type : action.type,
                    message : action.message
                }
            }
        case SUB_SERVICE_REMOVE_SUCCESSFUL:
            return {
                ...state,
                sub_service_remove : {
                    type : action.type,
                    message : action.message
                }
            }
        case SUB_SERVICE_REMOVE_UNAUTHORIZED:
            return {
                ...state,
                sub_service_remove : {
                    type : action.type,
                    message : action.message
                }
            }
        case SUB_SERVICE_REMOVE_NOT_FOUND:
            return {
                ...state,
                sub_service_remove : {
                    type : action.type,
                    message : action.message
                }
            }
        case SUB_SERVICE_REMOVE_FAILED:
            return {
                ...state,
                sub_service_remove : {
                    type : action.type,
                    message : action.message
                }
            }
        case SUB_SERVICE_RESET_CRUD:
            return {
                ...state,
                sub_service_create : {
                    type : null ,
                    message : ''
                },
                sub_service_update : {
                    type : null , 
                    message : ''
                },
                sub_service_remove : {
                    type : null,
                    message : ''
                }
            }
        default:
            return state
    }
}