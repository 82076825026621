import { SOCKET_SET , SOCKET_REMOVE } from "actions/Socket/Types"

const initState = {
    socket : null
}

export default function(state = initState , action){
    switch(action.type){
        case SOCKET_SET:
            return {
                ...state,
                socket : action.payload.socket
            }
        default:
            return state
    }
}