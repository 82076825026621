import { Table, Card, CardBody, Container, Row, Col, FormGroup, Input, Form, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import {
    TRANSACTION_LIST_LOADING,
    TRANSACTION_LIST_SUCCESSFUL,
    TRANSACTION_LIST_NOT_FOUND,

    TRANSACTION_DETAIL_LOADING,
    TRANSACTION_DETAIL_SUCCESSFUL,
    TRANSACTION_DETAIL_NOT_FOUND,
    TRANSACTION_DETAIL_FAILED
} from 'actions/Transactions/Types';
import NotFoundPage from 'components/NotFound/NotFoundPage';
import ErrorPage from 'components/ErrorComponent/ErrorPage';
import IcNotFound from 'assets/img/ic_not_found.svg';
import IcError from 'assets/img/ic_error.svg';
import Pagination from 'components/Utilities/Pagination';
import { useHistory } from 'react-router-dom';
import 'assets/css/table_animation.css';
import { getTransactions, getTransactionDetail } from 'actions/Transactions/TransactionService';
import { MaterialGray1 } from 'components/Utilities/ResourceColors';
import { MaterialBlue1 } from 'components/Utilities/ResourceColors';
import { validateEmpty } from 'components/Utilities/Utility';
import Skeleton from 'react-loading-skeleton';
import { formatRupiah } from 'components/Utilities/Utility';
import { TRANSACTION_LIST_FAILED } from 'actions/Transactions/Types';

const TransactionPage = (props) => {
    const [currentPage, setCurrentPage] = useState(1)
    const [currentPageSearch, setCurrentPageSearch] = useState(1)
    const [isFirst, setFirst] = useState(true)
    const [token, setToken] = useState('')

    const [searchText, setSearchText] = useState('')
    const onChangeSearch = (e) => {
        setSearchText(e.target.value)
    }
    const history = useHistory()
    useEffect(() => {
        setToken(localStorage.getItem('token'))
        if (validateEmpty(token)) {
            props.getTransactions(currentPage, 5, "", token)
        }
    }, [token])

    useEffect(() => {
        if (validateEmpty(token)) {
            props.getTransactions(currentPage, 5, "", token)
        }
    }, [currentPage])

    useEffect(() => {
        if (!isFirst) {
            if (validateEmpty(searchText)) {
                if (validateEmpty(token)) {
                    props.getTransactions(currentPageSearch, 5, searchText, token)
                }
            }
        } else {
            setFirst(false)
        }
    }, [currentPageSearch])

    useEffect(() => {
        if (!isFirst) {
            const delayDebounceFn = setTimeout(() => {
                if (validateEmpty(searchText)) {
                    setCurrentPageSearch(1)
                    if (validateEmpty(token)) {
                        props.getTransactions(currentPageSearch, 5, searchText, token)
                    }
                } else {
                    setCurrentPageSearch(1)
                    if (validateEmpty(token)) {
                        props.getTransactions(currentPage, 5, "", token)
                    }
                }
            }, 800)
            return () => clearTimeout(delayDebounceFn)
        } else {
            setFirst(false)
        }
    }, [searchText])

    function buildTransactionId(data) {
        switch (data.transaction_for) {
            case 'order':
                return data.order_id_transaction
            case 'disbursement':
                return data.disbursement_id
            case 'topup':
                return data.topup_id
            default:
                return '-'
        }
    }

    function buildTransactionStatus(status) {
        if (validateEmpty(status)) {
            switch (status) {
                case 'pending':
                    return (
                        <span className="text-warning mr-2">
                            Pending
                        </span>
                    )
                case 'success':
                    return (
                        <span className="text-success mr-2">
                            Success
                        </span>
                    )
                case 'failed':
                    return (
                        <span className="text-danger mr-2">
                            Failed
                        </span>
                    )
            }
        } else {
            return (
                <>
                    -
                </>
            )
        }
    }

    function buildTransactionList() {
        if (props.transaction.payload !== undefined) {
            if (props.transaction.payload.type === TRANSACTION_LIST_LOADING) {
                return (
                    <>
                        <Row>
                            <Col lg="12">
                                <Skeleton style={{ height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12">
                                <Skeleton style={{ marginTop: '25px', height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12">
                                <Skeleton style={{ marginTop: '25px', height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12">
                                <Skeleton style={{ marginTop: '25px', height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                            </Col>
                        </Row>
                    </>
                )
            } else if (props.transaction.payload.type === TRANSACTION_LIST_SUCCESSFUL) {
                console.log(`Trans : ${JSON.stringify(props.transaction.payload.data, null, 2)}`)
                if (props.transaction.payload.data !== undefined) {
                    return (
                        <>
                            <Row>
                                <Col xl="12">
                                    <Table className="align-items-center table-bordered" responsive>
                                        <thead className="thead-dark">
                                            <tr>
                                                <th style={{ color: 'white' }} scope="col">Tipe Pengguna</th>
                                                <th style={{ color: 'white' }} scope="col">ID Transaksi</th>
                                                <th style={{ color: 'white' }} scope="col">Tipe Transaksi</th>
                                                <th style={{ color: 'white' }} scope="col">Jenis Transaksi</th>
                                                <th style={{ color: 'white' }} scope="col">Total Transaksi</th>
                                                <th style={{ color: 'white' }} scope="col">Fee Transaksi</th>
                                                <th style={{ color: 'white' }} scope="col">Pembayaran</th>
                                                <th style={{ color: 'white' }} scope="col">Saldo Suberes Terakhir</th>
                                                <th style={{ color: 'white' }} scope="col">Status Transaksi</th>
                                                <th style={{ color: 'white' }} scope="col">Tanggal Dibuat</th>
                                                <th style={{ color: 'white' }} scope="col">Aksi</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                props.transaction.payload.data.rows_data.docs.map((data) => {
                                                    return (
                                                        <tr key={data.id}>
                                                            <td>{data.user_type !== null ? data.user_type.charAt(0).toUpperCase() + data.user_type.slice(1) : '-'}</td>
                                                            <td>
                                                                {
                                                                    buildTransactionId(data)
                                                                }
                                                            </td>
                                                            <td>{validateEmpty(data.transaction_for) ? data.transaction_for.charAt(0).toUpperCase() + data.transaction_for.slice(1) : '-'}</td>
                                                            <td>
                                                                {
                                                                    data.transaction_type === 'transaction_in' ?
                                                                        <>
                                                                            <span className="text-success mr-2">
                                                                                <i className="fas fa-arrow-down" />
                                                                            </span>
                                                                            Transaksi Masuk
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <span className="text-danger mr-2">
                                                                                <i className="fas fa-arrow-up" />
                                                                            </span>
                                                                            Transaksi Keluar
                                                                        </>
                                                                }
                                                            </td>
                                                            <td>{validateEmpty(data.transaction_amount) ? formatRupiah(data.transaction_amount.toString(), 'Rp. ') : 'Rp. -'}</td>
                                                            <td>{validateEmpty(data.transaction_fee) ? formatRupiah(data.transaction_fee.toString(), 'Rp. ') : 'Rp. -'}</td>
                                                            <td>{validateEmpty(data.payment_name) ? data.payment_name : 'Tidak Diketahui'}</td>
                                                            <td>{formatRupiah(data.last_amount.toString(), 'Rp ')}</td>
                                                            <td>{buildTransactionStatus(data.transaction_status)}</td>
                                                            <td>{data.transaction_date}</td>
                                                            <td>
                                                                <button onClick={() => {
                                                                    history.push({
                                                                        pathname: '/admin/transaction_detail',
                                                                        state: {
                                                                            transaction_id: data.id
                                                                        }
                                                                    })
                                                                }} className="btn btn-sm btn-primary"><i className="fas fa-eye"></i> Lihat</button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                            {
                                !validateEmpty(searchText) ?
                                    <Row>
                                        {
                                            props.transaction.payload.data.rows_data !== undefined &&
                                                props.transaction.payload.data.count_total > 5 ?
                                                <div className="card bg-default-shadow" style={{ width: 'max-content', paddingLeft: '20px', marginRight: '0', marginTop: '10px', paddingTop: '15px', marginLeft: 'auto' }}>
                                                    <Pagination
                                                        className="pagination-bar"
                                                        currentPage={currentPage}
                                                        totalCount={props.transaction.payload.data.count_total}
                                                        pageSize={5}
                                                        onPageChange={page => {
                                                            setCurrentPage(page)
                                                        }} />
                                                </div>
                                                : <div></div>
                                        }
                                    </Row>
                                    :
                                    <Row>
                                        {
                                            props.transaction.payload.data.rows_data !== undefined &&
                                                props.transaction.payload.data.count_total > 5 ?
                                                <div className="card bg-default-shadow" style={{ width: 'max-content', paddingLeft: '20px', marginRight: '0', marginTop: '10px', paddingTop: '15px', marginLeft: 'auto' }}>
                                                    <Pagination
                                                        className="pagination-bar"
                                                        currentPage={currentPageSearch}
                                                        totalCount={props.transaction.payload.data.count_total}
                                                        pageSize={5}
                                                        onPageChange={page => {
                                                            setCurrentPageSearch(page)
                                                        }} />
                                                </div>
                                                : <div></div>
                                        }
                                    </Row>
                            }
                        </>
                    )
                }
            } else if (props.transaction.payload.type === TRANSACTION_LIST_NOT_FOUND) {
                return (
                    <NotFoundPage
                        imageSource={IcNotFound}
                        imageText={props.transaction.payload.message} />
                )
            } else if(props.transaction.payload.type === TRANSACTION_LIST_FAILED) {
                return (
                    <ErrorPage
                        imageSource={IcError}
                        imageText={props.transaction.payload.message} />
                )
            } else {
                return (
                    <>
                        <Row>
                            <Col lg="12">
                                <Skeleton style={{ height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12">
                                <Skeleton style={{ marginTop: '25px', height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12">
                                <Skeleton style={{ marginTop: '25px', height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12">
                                <Skeleton style={{ marginTop: '25px', height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                            </Col>
                        </Row>
                    </>
                )
            }
        }

    }
    return (
        <>
            <div className="main-content">
                <div className="header bg-gradient-info pb-8 pt-5 pt-lg-7 d-flex align-items-center">
                    <span className="mask bg-gradient-default opacity-8" />
                </div>
                <Container className="mt--7" fluid>
                    <Row>
                        <Col xl="12">
                            <Form onSubmit={(e) => {
                                e.preventDefault()
                            }}>
                                <FormGroup>
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="fa fa-search" aria-hidden="true" style={{ color: MaterialBlue1, fontSize: 15 }}>
                                                </i>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            id="input-search"
                                            onChange={onChangeSearch}
                                            style={{
                                                border: 0,
                                                boxShadow: 'none'
                                            }}
                                            defaultValue={searchText}
                                            placeholder="Ketika disini untuk mencari transaksi berdasarkan ID Disbursement atau ID TopUp" />
                                    </InputGroup>
                                </FormGroup>
                            </Form>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl="12">
                            <Card className="bg-secondary shadow">
                                <CardBody>
                                    {buildTransactionList()}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

TransactionPage.propTypes = {
    transaction: PropTypes.object.isRequired,
    getTransactions: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    transaction: state.transaction
})

export default connect(mapStateToProps, { getTransactions })(TransactionPage)