import { BASE_URL } from 'actions/config';
import axios from 'axios';
import { passingHeaderTokenFormData , passingHeaderToken } from 'components/Utilities/Utility';
import {
    NEWS_LIST_LOADING,
    NEWS_LIST_SUCCESSFUL,
    NEWS_LIST_NOT_FOUND,
    NEWS_LIST_FAILED,

    NEWS_DETAIL_LOADING,
    NEWS_DETAIL_SUCCESSFUL,
    NEWS_DETAIL_NOT_FOUND,
    NEWS_DETAIL_FAILED,

    NEWS_CREATE_LOADING,
    NEWS_CREATE_SUCCESSFUL,
    NEWS_CREATE_EXIST,
    NEWS_CREATE_FAILED,

    NEWS_UPDATE_LOADING,
    NEWS_UPDATE_SUCCESSFUL,
    NEWS_UPDATE_NOT_FOUND,
    NEWS_UPDATE_FAILED,

    NEWS_REMOVE_LOADING,
    NEWS_REMOVE_SUCCESSFUL,
    NEWS_REMOVE_NOT_FOUND,
    NEWS_REMOVE_UNAUTHORIZED,
    NEWS_REMOVE_FAILED,

    NEWS_RESET_CRUD
} from './Types';

export const getNews = (page , limit , token) => async dispatch => {
    try {
        dispatch({
            type : NEWS_LIST_LOADING,
            message : 'Memuat data berita'
        })
        const response = await axios.get(`${BASE_URL}/api/news/index?page=${page}&limit=${limit}` , passingHeaderToken(token))
        console.log(`Data : ${JSON.stringify(response.data , null , 2)}`)
        setTimeout(() => {
            if(response.data != null && response.data.rows_data.docs.length > 0){
                dispatch({
                    type : NEWS_LIST_SUCCESSFUL,
                    data : response.data
                })
            } else {
                dispatch({
                    type : NEWS_LIST_NOT_FOUND,
                    message : 'Tidak ada data berita'
                })
            }
        } , 300)
    } catch (err) {
        setTimeout(() => {
            if(err.response !== undefined && err.response.status === 404){
                dispatch({
                    type : NEWS_LIST_NOT_FOUND,
                    message : 'Tidak ada data berita'
                })
            } else {
                dispatch({
                    type : NEWS_LIST_FAILED,
                    message : 'Gagal memuat data berita'
                })
            }
        } , 300)
    }
}

export const getDetailNews = (id , token) => async dispatch => {
    try {
        dispatch({
            type : NEWS_DETAIL_LOADING,
            message : 'Memuat data berita'
        })
        const response = await axios.get(`${BASE_URL}/api/news/detail/${id}` , passingHeaderToken(token))
        setTimeout(() => {
            dispatch({
                type : NEWS_DETAIL_SUCCESSFUL,
                data : response.data
            })
        } , 300)
    } catch (err) {
        if(err.response !== undefined && err.response.status === 404){
            setTimeout(() => {
                dispatch({
                    type : NEWS_DETAIL_NOT_FOUND,
                    message : 'Tidak ada data berita'
                })
            } , 300)
        } else {
            setTimeout(() => {
                dispatch({
                    type : NEWS_DETAIL_FAILED,
                    message : 'Gagal memuat data berita'
                })
            } , 300)
        }
    }
}

export const createNews = (form_data , token) => async dispatch => {
    try {
        dispatch({
            type : NEWS_CREATE_LOADING,
            message : 'Membuat data berita'
        })
        await axios.post(`${BASE_URL}/api/news/create` , form_data , passingHeaderTokenFormData(token))
        dispatch({
            type : NEWS_CREATE_SUCCESSFUL,
            message : 'Data berita terbuat'
        })
    } catch (err) {
        if(err.response !== undefined && err.response.status == 409){
            dispatch({
                type : NEWS_CREATE_EXIST,
                message : 'Data berita sudah ada'
            })
        } else {
            dispatch({
                type : NEWS_CREATE_FAILED,
                message : 'Gagal membuat data berita'
            })
        }
    }
}

export const updateNewsImage = (id , form_data , token) => async dispatch => {
    try {
        dispatch({
            type : NEWS_UPDATE_LOADING,
            message : 'Memperbarui data berita'
        })
        await axios.put(`${BASE_URL}/api/news/update/image/${id}` , form_data , passingHeaderTokenFormData(token))
        setTimeout(() => {
            dispatch({
                type : NEWS_UPDATE_SUCCESSFUL,
                message : 'Berhasil memperbarui data berita'
            })
        } , 300)
    } catch (err) {
        setTimeout(() => {
            if(err.response !== undefined && err.response.status === 404){
                dispatch({
                    type : NEWS_UPDATE_NOT_FOUND,
                    message : 'Tidak ada data berita'
                })
            } else {
                dispatch({
                    type : NEWS_UPDATE_FAILED,
                    message : 'Gagal memperbarui data berita'
                })
            }
        } , 300)
    }
}

export const updateNews = (id , payload , token) => async dispatch => {
    try {
        dispatch({
            type : NEWS_UPDATE_LOADING,
            message : 'Memperbarui data berita'
        })
        await axios.put(`${BASE_URL}/api/news/update/${id}` , payload , passingHeaderToken(token))
        setTimeout(() => {
            dispatch({
                type : NEWS_UPDATE_SUCCESSFUL,
                message : 'Berhasil memperbarui data berita'
            })
        } , 300)
    } catch (err) {
        setTimeout(() => {
            if(err.response !== undefined && err.response.status === 404){
                dispatch({
                    type : NEWS_UPDATE_NOT_FOUND,
                    message : 'Tidak ada data berita'
                })
            } else {
                dispatch({
                    type : NEWS_UPDATE_FAILED,
                    message : 'Gagal memperbarui data berita'
                })
            }
        } , 300)
    }
}

export const removeNews = (id , token) => async dispatch => {
    try {
        dispatch({
            type : NEWS_REMOVE_LOADING,
            message : 'Menghapus data berita'
        })
        await axios.delete(`${BASE_URL}/api/news/delete/${id}` , passingHeaderToken(token))
        dispatch({
            type : NEWS_REMOVE_SUCCESSFUL,
            message : 'Berhasil menghapus data berita'
        })
    } catch (err) {
        if(err.response !== undefined && err.response.status === 404){
            dispatch({
                type : NEWS_REMOVE_NOT_FOUND,
                message : 'Tidak ada data berita'
            })
        } else if(err.response !== undefined && err.response.status === 401){
            dispatch({
                type : NEWS_REMOVE_UNAUTHORIZED,
                message : 'Password salah'
            })
        } else {
            dispatch({
                type : NEWS_REMOVE_FAILED,
                message : 'Gagal menghapus data berita'
            })
        }
    }
}

export const setNewsEmpty = () => async dispatch => {
    dispatch({
        type : NEWS_LIST_NOT_FOUND,
        message : 'Data berita tidak ada'
    })
}

export const resetCrud = () => async dispatch => {
    dispatch({
        type : NEWS_RESET_CRUD,
        message : ''
    })
}