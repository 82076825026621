// import { JSON_CREDENTIALS } from 'actions/config';
// import { initializeApp } from 'firebase/app';
// import { getMessaging, getToken, onMessage } from "firebase/messaging";
// import axios from 'axios'
// import { headerRequest } from 'components/Utilities/Utility';
// import { BASE_URL } from 'actions/config';
// const firebaseConfig = {
//     apiKey: "AIzaSyANj4TjDdv6zf4DyX4nHrB8fIwNsBrAZ9k",
//     authDomain: "suberes-dashboard.firebaseapp.com",
//     projectId: "suberes-dashboard",
//     storageBucket: "suberes-dashboard.appspot.com",
//     messagingSenderId: "898951326509",
//     appId: "1:898951326509:web:1146dfdbc7aa0d742fc1a4",
//     measurementId: "G-5DFSVHB9SG"
// };

// const firebaseApp = initializeApp(firebaseConfig);
// const messaging = getMessaging(firebaseApp);

// export const fetchToken = async (setTokenFound) => {
//     // return getToken(messaging, {vapidKey: 'BF-Ym-BKNQ0xQGQbyCeTBZK9Gk2nMJnEiPphfd6LN_vgMkV666IHU3u9OrMDZoCSnsEfXFc84QyCinN8XmRmhSI'}).then((currentToken) => {
//     //   if (currentToken) {
//     //     const data = JSON.parse(localStorage.getItem(JSON_CREDENTIALS))
//     //     updateFirebaseToken(data.data.id , currentToken)
//     //     setTokenFound(true);
//     //     // Track the token -> client mapping, by sending to backend server
//     //     // show on the UI that permission is secured
//     //   } else {
//     //     console.log('No registration token available. Request permission to generate one.');
//     //     setTokenFound(false);
//     //     // shows on the UI that permission is required 
//     //   }
//     // }).catch((err) => {
//     //     console.log(err)
//     // });
//     const resultToken = await getToken(messaging, { vapidKey: 'BKgxnfHYtfGXAydAgarmDJH-RQ6Wc3RzywZWGSCD4zXGTt8Xft5hZlGX-tqKvb8A4AXDzJu0dNGz97R8TDqm2KY' })
//     console.log(`Result Token : ${resultToken}`)
//     if (resultToken) {
//         const data = JSON.parse(localStorage.getItem(JSON_CREDENTIALS))
//         setTokenFound(true)
//         try {
//             await axios.put(`${BASE_URL}/api/admin/update_firebase_token/${data.data.id}/${resultToken}`, headerRequest)
//             console.log('UPDATE TOKEN SUCCESSFULLY')
//         } catch (err) {
//             console.log(err)
//         }
//     } else {
//         console.log(`No registration token found`)
//         setTokenFound(false)
//     }
// }

// export const onMessageListener = () =>
//     new Promise((resolve) => {
//         onMessage(messaging, (payload) => {
//             resolve(payload);
//         });
//     });