import {
    TRANSACTION_LIST_LOADING,
    TRANSACTION_LIST_SUCCESSFUL,
    TRANSACTION_LIST_NOT_FOUND,
    TRANSACTION_LIST_FAILED,

    TRANSACTION_DETAIL_LOADING,
    TRANSACTION_DETAIL_SUCCESSFUL,
    TRANSACTION_DETAIL_NOT_FOUND,
    TRANSACTION_DETAIL_FAILED
} from 'actions/Transactions/Types';

const initState = {
    payload : {
        type : null,
        message : ''
    }
}

export default function(state = initState , action){
    switch(action.type){
        case TRANSACTION_LIST_LOADING:
            return {
                ...state,
                payload : {
                    type : action.type,
                    message : action.message
                }
            }
        case TRANSACTION_LIST_SUCCESSFUL:
            return {
                ...state,
                payload : {
                    type : action.type,
                    data : action.data
                }
            }
        case TRANSACTION_LIST_NOT_FOUND:
            return {
                ...state,
                payload : {
                    type : action.type,
                    message : action.message
                }
            }
        case TRANSACTION_LIST_FAILED:
            return {
                ...state,
                payload : {
                    type : action.type,
                    message : action.message
                }
            }
        
        case TRANSACTION_DETAIL_LOADING:
            return {
                ...state,
                payload : {
                    type : action.type,
                    message : action.message
                }
            }
        case TRANSACTION_DETAIL_SUCCESSFUL:
            return {
                ...state,
                payload : {
                    type : action.type,
                    data : action.data
                }
            }
        case TRANSACTION_DETAIL_NOT_FOUND:
            return {
                ...state,
                payload : {
                    type : action.type,
                    message : action.message
                }
            }
        case TRANSACTION_DETAIL_FAILED:
            return {
                ...state,
                payload : {
                    type : action.type,
                    message : action.message
                }
            }
        default:
            return state
    }
}