import { connect } from "react-redux"
import {
    Container,
    Row,
    Col,
    Input,
    Card,
    CardHeader,
    CardBody,
    Form,
    FormGroup,
    Label,
    Modal,
    ModalHeader,
    ModalBody,
    Table,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
} from 'reactstrap';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useReducer, useState } from "react";
import { BASE_URL } from "actions/config";
import Pagination from 'components/Utilities/Pagination';
import Skeleton from "react-loading-skeleton";
import { MaterialGray1 } from "components/Utilities/ResourceColors";
import { useLocation } from "react-router-dom";
import { getMitraDetail, inviteMitra, updateTrainingStatus, acceptMitra, resetUpdate } from "actions/Mitra/MitraService";
import { getScheduleForMitra } from "actions/Schedule/ScheduleService";
import { getToolsByMitra, submitMitraTools, resetCrud } from "actions/Tools/ToolsService";
import { MITRA_DETAIL_LOADING, MITRA_DETAIL_SUCCESSFUL } from "actions/Mitra/Types";
import { MaterialGray2 } from "components/Utilities/ResourceColors";
import { useHistory } from "react-router-dom";
import IntlTelInput from "react-intl-tel-input";
import { DatePicker } from 'reactstrap-date-picker'
import { MaterialRed1 } from "components/Utilities/ResourceColors";
import { updateMitraCandidateDetail } from "actions/Mitra/MitraService";
import { UPDATE_MITRA_CANDIDATE_LOADING } from "actions/Mitra/Types";
import { UPDATE_MITRA_CANDIDATE_SUCCESSFUL } from "actions/Mitra/Types";
import { UPDATE_MITRA_CANDIDATE_FAILED } from "actions/Mitra/Types";
import { INVITE_TRAINING_LOADING } from "actions/Mitra/Types";
import { INVITE_TRAINING_SUCCESSFUL } from "actions/Mitra/Types";
import { INVITE_TRAINING_FAILED } from "actions/Mitra/Types";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { UPDATE_TRAINING_LOADING } from "actions/Mitra/Types";
import { UPDATE_TRAINING_SUCCESSFUL } from "actions/Mitra/Types";
import { UPDATE_TRAINING_FAILED } from "actions/Mitra/Types";
import { MaterialBlue1 } from "components/Utilities/ResourceColors";
import { ACCEPT_MITRA_LOADING } from "actions/Mitra/Types";
import { ACCEPT_MITRA_SUCCESSFUL } from "actions/Mitra/Types";
import { ACCEPT_MITRA_FAILED } from "actions/Mitra/Types";
import { SCHEDULE_LOADING } from "actions/Schedule/Types";
import { SCHEDULE_SUCCESSFUL } from "actions/Schedule/Types";
import { SCHEDULE_NOT_FOUND } from "actions/Schedule/Types";
import { SCHEDULE_FAILED } from "actions/Schedule/Types";
import { validateEmpty } from "components/Utilities/Utility";
import { MaterialBlue3 } from "components/Utilities/ResourceColors";
import {
    TOOLS_MITRA_LOADING,
    TOOLS_MITRA_SUCCESSFUL,
    TOOLS_MITRA_NOT_FOUND,
    TOOLS_MITRA_FAILED,

    SUBMIT_TOOLS_LOADING,
    SUBMIT_TOOLS_SUCCESSFUL,
    SUBMIT_TOOLS_FAILED
} from 'actions/Tools/Types';
import { MaterialGray3 } from "components/Utilities/ResourceColors";
import { formatRupiah } from "components/Utilities/Utility";
import { MaterialGreen1 } from "components/Utilities/ResourceColors";
import NotFoundPage from 'components/NotFound/NotFoundPage';
import ErrorPage from 'components/ErrorComponent/ErrorPage';
import IcNotFound from 'assets/img/ic_not_found.svg';
import IcError from 'assets/img/ic_error.svg';

const MySwal = withReactContent(Swal)

const MitraDetailCandidate = (props) => {
    const [token, setToken] = useState('')
    const [searchText, setSearchText] = useState('')
    const mainContent = React.useRef(null)
    const location = useLocation()

    const [toolsSelectedPrice, setToolsSelectedPrice] = useState(0)
    const [toolsSelectedPriceDebt, setToolsSelectedPriceDebt] = useState(0)

    const [toolsSelected, setToolsSelected] = useState([])

    const [toolsSelectedCount, setToolsSelectedCount] = useState(0)
    const [toolsReleaseCount, setToolsReleaseCount] = useState(0)
    // Dont forget to call setToolsSelected after selected tools for mitra
    const [toolsLoading, setToolsLoading] = useState(false)

    const [currentPageSelectedTools, setCurrentPageSelectedTools] = useState(1)
    const [currentPageSelectedToolsSearch, setCurrentPageSelectedToolsSearch] = useState(1)

    const [toolsSearchText, setToolsSearchText] = useState('')

    const [canSubmitTools, setCanSubmitTools] = useState(false)

    const [currentPageSchedule, setCurrentPageSchedule] = useState(1)
    const [currentPageSearchSchedule, setCurrentPageSearchSchedule] = useState(1)
    const [isFirst, setFirst] = useState(false)

    const [sendToolsText, setSendToolsText] = useState('Harap Pilih Alat Untuk Ditambahkan')

    const fileRefProfileImage = useRef()
    const fileRefProfileImageChange = useRef()

    const fileRefKtp = useRef()
    const fileRefKtpChange = useRef()

    const fileRefKk = useRef()
    const fileRefKkChange = useRef()

    const [isEditing, setEditing] = useState(false)
    const [isSubmit, setSubmit] = useState(false)
    const [modal, setModal] = useState(false)
    const [inviteMitra, setInviteMitra] = useState(false)
    const [dialogTrainStatus, setDialogTrainStatus] = useState(false)
    const [dialogUserTools, setDialogUserTools] = useState(false)
    const [dialogAcceptStatus, setDialogAcceptStatus] = useState(false)
    const toggle = () => setModal(!modal)
    const toggleInviteMitra = () => {
        if (!inviteUpdate) {
            setInputValues({
                selected_index: -1,
                selected_schedule_name: ''
            })
            setInviteMitra(!inviteMitra)
        }
    }

    const toggleDialogTrainStatus = () => setDialogTrainStatus(!dialogTrainStatus)
    const toggleDialogUserTools = () => {
        if (!toolsLoading) {
            setToolsSelected([])
            setToolsSelectedCount(0)
            setToolsReleaseCount(0)
            setDialogUserTools(!dialogUserTools)
        }
    }
    const toggleDialogAcceptStatus = () => setDialogAcceptStatus(!dialogAcceptStatus)

    const [modalImageUrl, setModalImageUrl] = useState(null)
    const [countChangeValue, setCountChangeValue] = useState(0)
    const [isUpdating, setUpdating] = useState(false)

    const [selectedFileCover, setSelectedFileCover] = useState(null)
    const [coverImageSelected, setCoverImageSelected] = useState(null)

    const [selectedFileKtp, setSelectedFileKtp] = useState(null)
    const [ktpImageSelected, setKtpImageSelected] = useState(null)

    const [selectedFileKk, setSelectedFileKk] = useState(null)
    const [kkImageSelected, setKKImageSelected] = useState(null)

    const [selectedFileProfileImage, setSelectedFileProfileImage] = useState(null)
    const [profileImageSelected, setProfileImageSelected] = useState(null)

    const [isCoverHover, setCoverHover] = useState(false)
    const [isKtpHover, setKtpHover] = useState(false)
    const [isKkHover, setKkHover] = useState(false)

    const [inviteTime, setInviteTime] = useState(new Date())
    const [placeInvited, setPlaceInvited] = useState('')

    const [inviteUpdate, setInviteUpdate] = useState(false)

    const [trainingUpdate, setTrainingUpdate] = useState(false)
    const [trainingStatus, setTrainingStatus] = useState('')

    const [acceptStatus, setAcceptStatus] = useState('')
    const [acceptUpdate, setAcceptUpdate] = useState(false)

    const [userToolsLoading, setUserToolsLoading] = useState(false)

    const history = useHistory()
    const MySwal = withReactContent(Swal)

    const [inputValues, setInputValues] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            email: '',
            country_code: '',
            phone_number: '',
            user_gender: '',
            domisili_address: '',
            ktp_number: '',
            rt_rw: '',
            province: '',
            address: '',
            district: '',
            sub_district: '',
            kind_of_mitra: '',
            city: '',
            place_of_birth: '',
            date_of_birth: '',
            postal_code: '',
            is_ex_golife: '',
            work_experience_duration: '',
            emergency_contact_name: '',
            emergency_contact_relation: '',
            emergency_contact_country_code: '',
            emergency_contact_phone: '',

            selected_index: -1,
            selected_schedule_id: -1,
            selected_schedule_name: ''
        }
    )
    const [existingValues, setExistingValues] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            email: {
                value: '',
                hasChanged: false
            },
            country_code: {
                value: '',
                hasChanged: false
            },
            phone_number: {
                value: '',
                hasChanged: false
            },
            user_gender: {
                value: '',
                hasChanged: false
            },
            domisili_address: {
                value: '',
                hasChanged: false
            },
            ktp_number: {
                value: '',
                hasChanged: false
            },
            rt_rw: {
                value: '',
                hasChanged: false
            },
            province: {
                value: '',
                hasChanged: false
            },
            address: {
                value: '',
                hasChanged: false
            },
            district: {
                value: '',
                hasChanged: false
            },
            sub_district: {
                value: '',
                hasChanged: false
            },
            kind_of_mitra: {
                value: '',
                hasChanged: false
            },
            city: {
                value: '',
                hasChanged: false
            },
            place_of_birth: {
                value: '',
                hasChanged: false
            },
            date_of_birth: {
                value: '',
                hasChanged: false
            },
            postal_code: {
                value: '',
                hasChanged: false
            },
            is_ex_golife: {
                value: '',
                hasChanged: false
            },
            user_profile_image: {
                value: '',
                hasChanged: false
            },
            cover_savings_book: {
                value: '',
                hasChanged: false
            },
            ktp_image: {
                value: '',
                hasChanged: false
            },
            kk_image: {
                value: '',
                hasChanged: false
            },
            work_experience_duration: {
                value: '',
                hasChanged: false
            },
            emergency_contact_name: {
                value: '',
                hasChanged: false
            },
            emergency_contact_relation: {
                value: '',
                hasChanged: false
            },
            emergency_contact_country_code: {
                value: '',
                hasChanged: false
            },
            emergency_contact_phone: {
                value: '',
                hasChanged: false
            }
        }
    )
    const onChangeHandler = event => {
        const { name, value } = event.target
        if (existingValues[name].value === value) {
            if (existingValues[name].hasChanged) {
                if (countChangeValue > 0) {
                    existingValues[name].hasChanged = false
                    setCountChangeValue(countChangeValue - 1)
                }
            }
        } else {
            if (!existingValues[name].hasChanged) {
                existingValues[name].hasChanged = true
                setCountChangeValue(countChangeValue + 1)
            }
        }
        setInputValues({ [name]: value })
    }
    const onChangeProfileImageHandler = event => {
        setSelectedFileProfileImage(event.target.files[0])
        setProfileImageSelected(URL.createObjectURL(event.target.files[0]))
    }
    const onChangeCoverImageHandler = event => {
        setSelectedFileCover(event.target.files[0])
        setCoverImageSelected(URL.createObjectURL(event.target.files[0]))
    }
    const onChangeKtpImageHandler = event => {
        setSelectedFileKtp(event.target.files[0])
        setKtpImageSelected(URL.createObjectURL(event.target.files[0]))
    }
    const onChangeKkImageHandler = event => {
        setSelectedFileKk(event.target.files[0])
        setKKImageSelected(URL.createObjectURL(event.target.files[0]))
    }
    const onSetPlaceInvitedHandler = event => {
        setPlaceInvited(event.target.value)
    }
    useEffect(() => {
        setToken(localStorage.getItem('token'))
    }, [token])
    useEffect(() => {
        if (selectedFileProfileImage == null) {
            if (existingValues['user_profile_image'].hasChanged) {
                if (countChangeValue > 0) {
                    existingValues['user_profile_image'].hasChanged = false
                    setCountChangeValue(countChangeValue - 1)
                }
            }
        } else {
            if (!existingValues['user_profile_image'].hasChanged) {
                existingValues['user_profile_image'].hasChanged = true
                setCountChangeValue(countChangeValue + 1)
            }
        }
    }, [selectedFileProfileImage])
    useEffect(() => {
        if (selectedFileCover == null) {
            if (existingValues['cover_savings_book'].hasChanged) {
                if (countChangeValue > 0) {
                    existingValues['cover_savings_book'].hasChanged = false
                    setCountChangeValue(countChangeValue - 1)
                }
            }
        } else {
            if (!existingValues['cover_savings_book'].hasChanged) {
                existingValues['cover_savings_book'].hasChanged = true
                setCountChangeValue(countChangeValue + 1)
            }
        }
    }, [selectedFileCover])

    useEffect(() => {
        if (selectedFileKk == null) {
            if (existingValues['kk_image'].hasChanged) {
                if (countChangeValue > 0) {
                    existingValues['kk_image'].hasChanged = false
                    setCountChangeValue(countChangeValue - 1)
                }
            }
        } else {
            if (!existingValues['kk_image'].hasChanged) {
                existingValues['kk_image'].hasChanged = true
                setCountChangeValue(countChangeValue + 1)
            }
        }
    }, [selectedFileKk])

    useEffect(() => {
        if (selectedFileKtp == null) {
            if (existingValues['ktp_image'].hasChanged) {
                if (countChangeValue > 0) {
                    existingValues['ktp_image'].hasChanged = false
                    setCountChangeValue(countChangeValue - 1)
                }
            }
        } else {
            if (!existingValues['ktp_image'].hasChanged) {
                existingValues['ktp_image'].hasChanged = true
                setCountChangeValue(countChangeValue + 1)
            }
        }
    }, [selectedFileKtp])
    React.useEffect(() => {
        document.documentElement.scrollTop = 0
        document.scrollingElement.scrollTop = 0
    }, [location])
    useEffect(() => {
        props.getMitraDetail(props.location.state.mitra_id)
    }, [])
    useEffect(() => {
        if (props.mitra.payload.data !== undefined) {
            for (var key in props.mitra.payload.data) {
                if (props.mitra.payload.data.hasOwnProperty(key)) {
                    if (key === 'phone_number') {
                        setInputValues({ [key]: props.mitra.payload.data[key].split("-").join("") })
                        setExistingValues({
                            [key]: {
                                value: props.mitra.payload.data[key].split("-").join(""),
                                hasChanged: false
                            }
                        })
                    } else if (key === 'emergency_contact_phone') {
                        setInputValues({ [key]: props.mitra.payload.data[key].split("-").join("") })
                        setExistingValues({
                            [key]: {
                                value: props.mitra.payload.data[key].split("-").join(""),
                                hasChanged: false
                            }
                        })
                    } else {
                        setInputValues({ [key]: props.mitra.payload.data[key] })
                        setExistingValues({
                            [key]: {
                                value: props.mitra.payload.data[key],
                                hasChanged: false
                            }
                        })
                    }
                }
            }
        }
    }, [props.mitra.payload])
    useEffect(() => {
        switch (props.mitra.payload_update.type) {
            case UPDATE_MITRA_CANDIDATE_LOADING:
                setUpdating(true)
                break;
            case UPDATE_MITRA_CANDIDATE_SUCCESSFUL:
                setUpdating(false)
                setProfileImageSelected(null)
                setSelectedFileCover(null)
                setCoverImageSelected(null)
                setSelectedFileKk(null)
                setKKImageSelected(null)
                setSelectedFileKtp(null)
                setKtpImageSelected(null)
                setCountChangeValue(0)
                props.resetUpdate()
                props.getMitraDetail(props.location.state.mitra_id)
                break;
            case UPDATE_MITRA_CANDIDATE_FAILED:
                setUpdating(false)
                props.resetUpdate()
                break;

            case INVITE_TRAINING_LOADING:
                setInviteUpdate(true)
                break;
            case INVITE_TRAINING_SUCCESSFUL:
                setInputValues({
                    selected_index: -1,
                    selected_schedule_name: ''
                })
                setInviteUpdate(false)
                setInviteMitra(false)
                MySwal.fire(
                    'Perhatian',
                    'Berhasil mengundang mitra training',
                    'success'
                )
                props.resetUpdate()
                props.getMitraDetail(props.location.state.mitra_id)
                break;
            case INVITE_TRAINING_FAILED:
                setInviteUpdate(false)
                MySwal.fire(
                    'Perhatian',
                    'Gagal mengundang mitra training',
                    'error'
                )
                props.resetUpdate()
                break;

            case UPDATE_TRAINING_LOADING:
                setTrainingUpdate(true)
                break;
            case UPDATE_TRAINING_SUCCESSFUL:
                setTrainingUpdate(false)
                setDialogTrainStatus(false)
                MySwal.fire(
                    'Perhatian',
                    'Berhasil memperbarui status training',
                    'success'
                )
                props.resetUpdate()
                props.getMitraDetail(props.location.state.mitra_id)
                break;
            case UPDATE_TRAINING_FAILED:
                setTrainingUpdate(false)
                setDialogTrainStatus(false)
                MySwal.fire(
                    'Perhatian',
                    'Gagal memperbarui status training',
                    'error'
                )
                props.resetUpdate()
                break;

            case ACCEPT_MITRA_LOADING:
                setAcceptUpdate(true)
                break;
            case ACCEPT_MITRA_SUCCESSFUL:
                setAcceptUpdate(false)
                setDialogAcceptStatus(false)
                MySwal.fire(
                    'Perhatian',
                    'Berhasil memperbarui status mitra',
                    'success'
                )
                props.resetUpdate()
                history.goBack()
                break
            case ACCEPT_MITRA_FAILED:
                setAcceptUpdate(false)
                setDialogAcceptStatus(false)
                MySwal.fire(
                    'Perhatian',
                    'Gagal memperbarui status mitra',
                    'error'
                )
                props.resetUpdate()
                break;
        }
    }, [props.mitra.payload_update])

    useEffect(() => {
        const toolsSize = toolsSelected.filter(item => item.tools_credits === null || item.tools_credits === undefined || item.tools_credits === [] || item.tools_credits.length == 0).length
        const toolsReleaseSize = toolsSelected.filter(item => item.tools_credits !== null && item.tools_credits !== undefined & item.tools_credits !== [] && item.tools_credits.length > 0).length
        if (props.tools.payload.data !== undefined && props.tools.payload.data.tools_selected_mitra_objs !== undefined) {
            setToolsSelectedCount(toolsSize)
            if (Array.isArray(props.tools.payload.data.tools_selected_mitra_objs)) {
                setToolsReleaseCount(props.tools.payload.data.tools_selected_mitra_objs.length - toolsReleaseSize)
            }
        }
    }, [toolsSelected])

    useEffect(() => {
        if (toolsSelectedCount > 0 || toolsReleaseCount > 0) {
            setCanSubmitTools(true)
            if (toolsSelectedCount > 0 && toolsReleaseCount > 0) {
                setSendToolsText(`Pilih ${toolsSelectedCount} Alat Dan Lepas ${toolsReleaseCount} Alat`)
            } else if (toolsSelectedCount > 0 && toolsReleaseCount == 0) {
                setSendToolsText(`Pilih ${toolsSelectedCount} Alat`)
            } else if (toolsSelectedCount == 0 && toolsReleaseCount > 0) {
                setSendToolsText(`Lepas ${toolsReleaseCount} Alat`)
            }
        } else {
            setCanSubmitTools(false)
            setSendToolsText('Harap Pilih Alat Untuk Ditambahkan')
        }
    }, [toolsSelectedCount, toolsReleaseCount])

    useEffect(() => {
        props.getToolsByMitra(props.location.state.mitra_id, currentPageSelectedTools, 5, "", token)
    }, [currentPageSelectedTools])
    useEffect(() => {
        if (!isFirst) {
            if (validateEmpty(toolsSearchText)) {
                props.getToolsByMitra(props.location.state.mitra_id, currentPageSelectedToolsSearch, 5, toolsSearchText, token)
            }
        } else {
            setFirst(false)
        }
    }, [currentPageSelectedToolsSearch])
    const onChangeSearch = (e) => {
        setToolsSearchText(e.target.value)
    }
    useEffect(() => {
        if (!isFirst) {
            const delayDebounceFn = setTimeout(() => {
                if (validateEmpty(toolsSearchText)) {
                    setCurrentPageSelectedToolsSearch(1)
                    props.getToolsByMitra(props.location.state.mitra_id, currentPageSelectedTools, 5, toolsSearchText, token)
                } else {
                    setCurrentPageSelectedToolsSearch(1)
                    props.getToolsByMitra(props.location.state.mitra_id, currentPageSelectedTools, 5, "", token)
                }
            }, 800)
            return () => clearTimeout(delayDebounceFn)
        }
    }, [toolsSearchText])

    function submitInviteMitra() {
        if (token !== '' && inputValues.selected_schedule_id != -1 && inputValues.selected_schedule_name !== '') {
            const payload_invite = {
                schedule_id: inputValues.selected_schedule_id
            }
            props.inviteMitra(props.location.state.mitra_id, payload_invite, token)
        }
    }

    function submitMitraTools() {
        if (validateEmpty(token) && canSubmitTools && props.location.state.mitra_id !== undefined) {
            let payloadRequest = toolsSelected.filter(item => item.tools_credits === null || item.tools_credits === [] || item.tools_credits === undefined || item.tools_credits.length == 0)
                .map(item => {
                    return {
                        tool_id: item.id,
                        mitra_id: item.mitra_id
                    }
                });

            const payload = {
                selected_tools: payloadRequest,
                deleted_tools: []
            }
            if (props.tools.payload.data.tools_selected_mitra_objs !== undefined && props.tools.payload.data.tools_selected_mitra_objs !== null) {
                let payloadDelete = []
                if (toolsReleaseCount > 0) {
                    payloadDelete = props.tools.payload.data.tools_selected_mitra_objs.filter(item => item.tools_credits !== null && item.tools_credits !== [] && item.tools_credits !== undefined && item.tools_credits.length != 0 && !toolsSelected.some(d => d.id == item.id))
                        .map(item => {
                            return item.tools_credits[0].id
                        });
                }
                payload.deleted_tools = payloadDelete
            }
            props.submitMitraTools(payload, token)
        }
    }

    function updateTrainingStatus() {
        props.updateTrainingStatus(props.location.state.mitra_id, trainingStatus, token)
    }

    function acceptStatusMitra() {
        props.acceptMitra(props.location.state.mitra_id, acceptStatus, token)
    }

    function editUpdate() {
        setSubmit(true)
        if (isEditing) {
            const {
                email,
                country_code,
                phone_number,
                user_gender,
                domisili_address,
                ktp_number,
                province,
                rt_rw,
                address,
                district,
                sub_district,
                kind_of_mitra,
                city,
                place_of_birth,
                date_of_birth,
                postal_code,
                is_ex_golife,
                work_experience_duration,
                emergency_contact_name,
                emergency_contact_relation,
                emergency_contact_country_code,
                emergency_contact_phone
            } = inputValues

            const payload = {
                email,
                country_code,
                phone_number,
                user_gender,
                domisili_address,
                address,
                district,
                sub_district,
                kind_of_mitra,
                ktp_number,
                province,
                rt_rw,
                city,
                place_of_birth,
                date_of_birth,
                postal_code,
                is_ex_golife,
                work_experience_duration,
                emergency_contact_name,
                emergency_contact_relation,
                emergency_contact_country_code,
                emergency_contact_phone
            }
            if (email !== '' && country_code !== '' && phone_number !== '' &&
                user_gender !== '' && domisili_address !== '' && address !== '' &&
                district !== '' && sub_district !== '' && kind_of_mitra !== '' &&
                city !== '' && place_of_birth !== '' && date_of_birth !== '' &&
                postal_code !== '' && is_ex_golife !== '' &&
                emergency_contact_name !== '' && emergency_contact_relation !== '' &&
                emergency_contact_country_code !== '' && emergency_contact_phone !== '') {
                const formData = new FormData()
                formData.append('json_data', JSON.stringify(payload, null, 2))
                if (selectedFileCover != null)
                    formData.append('cover_saving_book', selectedFileCover)
                if (selectedFileKtp != null)
                    formData.append('ktp', selectedFileKtp)
                if (selectedFileKk != null)
                    formData.append('kk', selectedFileKk)
                if (selectedFileProfileImage != null)
                    formData.append('profile_image', selectedFileProfileImage)
                props.updateMitraCandidateDetail(props.location.state.mitra_id, formData, token)
                setEditing(false)
            }
        } else {
            setEditing(true)
        }
    }
    useEffect(() => {
        switch (props.tools.payload_crud.type) {
            case SUBMIT_TOOLS_LOADING:
                setToolsLoading(true)
                break;
            case SUBMIT_TOOLS_SUCCESSFUL:
                setToolsLoading(false)
                setDialogUserTools(false)
                props.resetCrud()
                Swal.fire(
                    'Status',
                    'Berhasil menetapkan alat untuk mitra',
                    'success'
                )
                break;
            case SUBMIT_TOOLS_FAILED:
                setToolsLoading(false)
                Swal.fire(
                    'Perhatian',
                    'Gagal menetapkan alat untuk mitra',
                    'error'
                )
                break;
        }
    }, [props.tools.payload_crud])
    useEffect(() => {
        if (props.tools.payload.type == TOOLS_MITRA_SUCCESSFUL) {
            setToolsSelectedPrice(parseInt(props.tools.payload.data.tools_selected_mitra.total_tool_price))
            setToolsSelectedPriceDebt(parseInt(props.tools.payload.data.tools_selected_mitra.total_tool_debt))
            setToolsSelected(props.tools.payload.data.tools_selected_mitra_objs)
        }
    }, [props.tools])
    function buildTools() {
        switch (props.tools.payload.type) {
            case TOOLS_MITRA_LOADING:
                return (
                    <>
                        <Row>
                            <Col lg="12">
                                <Skeleton style={{ height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12">
                                <Skeleton style={{ marginTop: '25px', height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12">
                                <Skeleton style={{ marginTop: '25px', height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12">
                                <Skeleton style={{ marginTop: '25px', height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12">
                                <Skeleton style={{ marginTop: '25px', height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12">
                                <Skeleton style={{ marginTop: '25px', height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                            </Col>
                        </Row>
                    </>
                )
            case TOOLS_MITRA_SUCCESSFUL:
                return (
                    <>
                        <Row>
                            <Col xl="12">
                                <Table className="align-items-center table-bordered" responsive>
                                    <thead className="thead-dark">
                                        <tr>
                                            <th style={{ color: 'white' }} scope="col">Nama Alat</th>
                                            <th style={{ color: 'white' }} scope="col">Jumlah Alat</th>
                                            <th style={{ color: 'white' }} scope="col">Harga Alat</th>
                                            <th style={{ color: 'white' }} scope="col">Cicilan Per Minggu</th>
                                            <th style={{ color: 'white' }} scope="col">Ketersediaan</th>
                                            <th style={{ color: 'white' }} scope="col">Periode Cicilan</th>
                                            <th style={{ color: 'white' }} scope="col">Aksi</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            props.tools.payload.data.rows_data.docs.map((data) => {
                                                return (
                                                    <tr key={data.id}>
                                                        <td>{data.tool_name}</td>
                                                        <td>{data.tool_count}</td>
                                                        <td>{data.tool_price !== null ? formatRupiah(data.tool_price.toString(), "Rp. ") : 'Rp. 0'}</td>
                                                        <td>{data.debt_per_week !== null ? formatRupiah(data.debt_per_week.toString(), "Rp. ") : 'Rp. 0'}</td>
                                                        <td>{data.is_out_of_stock === '0' ? 'Habis' : 'Ada'}</td>
                                                        <td>{data.installment_period} x</td>
                                                        <td style={{ cursor: toolsLoading || data.tools_credits !== null || data.tools_credits.length > 0 ? 'default' : 'pointer', background: toolsLoading ? MaterialGray1 : 'white' }} onClick={() => {
                                                            if (!toolsLoading && data.debt_per_week !== undefined && data.debt_per_week !== null &&
                                                                data.tool_price !== undefined && data.tool_price !== null) {
                                                                if (data.tools_credits !== null && data.tools_credits !== undefined && Array.isArray(data.tools_credits) &&
                                                                    data.tools_credits.length > 0) {
                                                                    if (toolsSelected.filter(item => item.id == data.id).length > 0) {
                                                                        setToolsSelected(
                                                                            toolsSelected.filter(toolsData => toolsData.id != data.id)
                                                                        )

                                                                        if (toolsSelectedPriceDebt > 0 && data.debt_per_week !== undefined && data.debt_per_week !== null) {
                                                                            setToolsSelectedPriceDebt(toolsSelectedPriceDebt - data.debt_per_week)
                                                                        }

                                                                        if (toolsSelectedPrice > 0 && data.tool_price !== undefined && data.tool_price !== null) {
                                                                            setToolsSelectedPrice(toolsSelectedPrice - data.tool_price)
                                                                        }
                                                                    } else {
                                                                        setToolsSelected([
                                                                            ...toolsSelected,
                                                                            {
                                                                                id: data.id,
                                                                                mitra_id: props.location.state.mitra_id,
                                                                                tools_credits: data.tools_credits
                                                                            }
                                                                        ])

                                                                        if (data.debt_per_week !== undefined && data.debt_per_week !== null) {
                                                                            setToolsSelectedPriceDebt(toolsSelectedPriceDebt + data.debt_per_week)
                                                                        }

                                                                        if (data.tool_price !== undefined && data.tool_price !== null) {
                                                                            setToolsSelectedPrice(toolsSelectedPrice + data.tool_price)
                                                                        }
                                                                    }
                                                                } else {
                                                                    if (toolsSelected.filter(item => item.id == data.id).length > 0) {
                                                                        setToolsSelected(
                                                                            toolsSelected.filter(toolsData => toolsData.id != data.id)
                                                                        )

                                                                        if (toolsSelectedPriceDebt > 0 && data.debt_per_week !== undefined && data.debt_per_week !== null) {
                                                                            setToolsSelectedPriceDebt(toolsSelectedPriceDebt - data.debt_per_week)
                                                                        }

                                                                        if (toolsSelectedPrice > 0 && data.tool_price !== undefined && data.tool_price !== null) {
                                                                            setToolsSelectedPrice(toolsSelectedPrice - data.tool_price)
                                                                        }
                                                                    } else {
                                                                        setToolsSelected([
                                                                            ...toolsSelected,
                                                                            {
                                                                                id: data.id,
                                                                                mitra_id: props.location.state.mitra_id,
                                                                                tools_credits: data.tools_credits
                                                                            }
                                                                        ])

                                                                        if (data.debt_per_week !== undefined && data.debt_per_week !== null) {
                                                                            setToolsSelectedPriceDebt(toolsSelectedPriceDebt + data.debt_per_week)
                                                                        }

                                                                        if (data.tool_price !== undefined && data.tool_price !== null) {
                                                                            setToolsSelectedPrice(toolsSelectedPrice + data.tool_price)
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }}>
                                                            <div style={{
                                                                height: '100%',
                                                                display: 'flex',
                                                                alignItems: 'center'
                                                            }}>
                                                                {
                                                                    data.tools_credits === null || data.tools_credits.length === 0 ?
                                                                        <>
                                                                            <div style={{
                                                                                width: '20px',
                                                                                display: 'flex',
                                                                                alignItems: 'center',
                                                                                height: '20px',
                                                                                marginRight: '8px',
                                                                                border: `1px solid ${toolsLoading ? MaterialGray3 : MaterialBlue1}`,
                                                                                borderRadius: '2px'
                                                                            }}>
                                                                                <div style={{
                                                                                    width: '15px',
                                                                                    height: '15px',
                                                                                    borderRadius: '2px',
                                                                                    width: '100%',
                                                                                    height: '80%',
                                                                                    marginLeft: '2px',
                                                                                    marginRight: '2px',
                                                                                    marginTop: '3.5px',
                                                                                    marginBottom: '3.5px',
                                                                                    background: toolsLoading ?
                                                                                        toolsSelected.filter(item => item.id == data.id).length > 0 ?
                                                                                            MaterialGray3
                                                                                            :
                                                                                            'white'
                                                                                        :
                                                                                        toolsSelected.filter(item => item.id == data.id).length > 0
                                                                                            ?
                                                                                            MaterialBlue1
                                                                                            :
                                                                                            'white',
                                                                                }}></div>
                                                                            </div>
                                                                            Pilih Alat
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <div style={{
                                                                                width: '20px',
                                                                                display: 'flex',
                                                                                alignItems: 'center',
                                                                                height: '20px',
                                                                                marginRight: '8px',
                                                                                border: `1px solid ${toolsLoading ? MaterialGray3 : MaterialGreen1}`,
                                                                                borderRadius: '2px'
                                                                            }}>
                                                                                <div style={{
                                                                                    width: '15px',
                                                                                    height: '15px',
                                                                                    borderRadius: '2px',
                                                                                    width: '100%',
                                                                                    height: '80%',
                                                                                    marginLeft: '2px',
                                                                                    marginRight: '2px',
                                                                                    marginTop: '3.5px',
                                                                                    marginBottom: '3.5px',
                                                                                    background: toolsLoading ?
                                                                                        toolsSelected.filter(item => item.id == data.id).length > 0 ?
                                                                                            MaterialGray3
                                                                                            :
                                                                                            'white'
                                                                                        :
                                                                                        toolsSelected.filter(item => item.id == data.id).length > 0
                                                                                            ?
                                                                                            MaterialGreen1
                                                                                            :
                                                                                            'white',
                                                                                }}></div>
                                                                            </div>
                                                                            Alat Sudah Dipilih
                                                                        </>
                                                                }
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl="6">
                                {
                                    !validateEmpty(toolsSearchText) ?
                                        <Row style={{ marginRight: '-10px' }}>
                                            {
                                                props.tools.payload.data.rows_data !== undefined &&
                                                    props.tools.payload.data.count_total > 5 ?
                                                    <div className="card bg-default-shadow" style={{ width: 'max-content', paddingLeft: '20px', marginRight: '0', marginTop: '10px', paddingTop: '15px', marginLeft: 'auto' }}>
                                                        <Pagination
                                                            className="pagination-bar"
                                                            currentPage={currentPageSelectedTools}
                                                            totalCount={props.tools.payload.data.count_total}
                                                            pageSize={5}
                                                            onPageChange={page => {
                                                                setCurrentPageSelectedTools(page)
                                                            }} />
                                                    </div>
                                                    : <div></div>
                                            }
                                        </Row>
                                        :
                                        <Row style={{ marginRight: '-10px' }}>
                                            {
                                                props.tools.payload.data.rows_data !== undefined &&
                                                    props.tools.payload.data.count_total > 5 ?
                                                    <div className="card bg-default-shadow" style={{ width: 'max-content', paddingLeft: '20px', marginRight: '0', marginTop: '10px', paddingTop: '15px', marginLeft: 'auto' }}>
                                                        <Pagination
                                                            className="pagination-bar"
                                                            currentPage={currentPageSelectedToolsSearch}
                                                            totalCount={props.tools.payload.data.count_total}
                                                            pageSize={5}
                                                            onPageChange={page => {
                                                                setCurrentPageSelectedToolsSearch(page)
                                                            }} />
                                                    </div>
                                                    : <div></div>
                                            }
                                        </Row>
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{ marginTop: '10px' }} md="4">
                                <div style={{ float: 'left', fontWeight: 'bold', fontSize: '13px' }}>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>Total Cicilan Per Minggu</td>
                                                <td>  : {`${formatRupiah(toolsSelectedPriceDebt.toString(), 'Rp. ')}`}</td>
                                            </tr>
                                            <tr>
                                                <td>Total Harga Tunai</td>
                                                <td>  : {`${formatRupiah(toolsSelectedPrice.toString(), 'Rp. ')}`}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </Col>
                            <Col style={{ fontWeight: 'bold', color: MaterialBlue1, fontSize: '17px', textAlign: 'center', alignItems: 'center', marginTop: '10px' }} md="2">
                                {
                                    `${toolsSelected.filter(item => item.tools_credits === null || item.tools_credits === undefined || item.tools_credits === [] || item.tools_credits.length == 0).length}`
                                }
                                <br />
                                Alat Ditetapkan
                            </Col>
                            <Col style={{ fontWeight: 'bold', color: MaterialRed1, fontSize: '17px', textAlign: 'center', alignItems: 'center', marginTop: '10px' }} md="2">
                                {
                                    `${toolsReleaseCount}`
                                }
                                <br />
                                Alat Dilepas
                            </Col>
                            <Col style={{ marginTop: '10px', float: 'right' }} md="4">
                                <table>
                                    <tr>
                                        <td>
                                            <div style={{
                                                width: '20px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                height: '20px',
                                                marginRight: '8px',
                                                border: `1px solid ${MaterialBlue1}`,
                                                borderRadius: '2px'
                                            }}>
                                                <div style={{
                                                    width: '15px',
                                                    height: '15px',
                                                    borderRadius: '2px',
                                                    width: '100%',
                                                    height: '80%',
                                                    marginLeft: '2px',
                                                    marginRight: '2px',
                                                    marginTop: '3.5px',
                                                    marginBottom: '3.5px',
                                                    background: MaterialBlue1
                                                }}></div>
                                            </div>
                                        </td>
                                        <td style={{ fontSize: '12px' }}>
                                            Alat Dipilih
                                        </td>
                                        <td>
                                            <div style={{
                                                width: '20px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                height: '20px',
                                                marginRight: '8px',
                                                border: `1px solid ${MaterialBlue1}`,
                                                borderRadius: '2px'
                                            }}>
                                                <div style={{
                                                    width: '15px',
                                                    height: '15px',
                                                    borderRadius: '2px',
                                                    width: '100%',
                                                    height: '80%',
                                                    marginLeft: '2px',
                                                    marginRight: '2px',
                                                    marginTop: '3.5px',
                                                    marginBottom: '3.5px',
                                                    background: 'white'
                                                }}></div>
                                            </div>
                                        </td>
                                        <td style={{ fontSize: '12px' }}>
                                            Alat Belum Dipilih
                                        </td>
                                    </tr>
                                    <tr style={{ marginTop: '10px' }}>
                                        <td>
                                            <div style={{
                                                width: '20px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                height: '20px',
                                                marginRight: '8px',
                                                border: `1px solid ${MaterialGreen1}`,
                                                borderRadius: '2px'
                                            }}>
                                                <div style={{
                                                    width: '15px',
                                                    height: '15px',
                                                    borderRadius: '2px',
                                                    width: '100%',
                                                    height: '80%',
                                                    marginLeft: '2px',
                                                    marginRight: '2px',
                                                    marginTop: '3.5px',
                                                    marginBottom: '3.5px',
                                                    background: MaterialGreen1
                                                }}></div>
                                            </div>
                                        </td>
                                        <td style={{ fontSize: '12px' }}>
                                            Alat Sudah Ditetapkan
                                        </td>
                                    </tr>
                                </table>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12">
                                {
                                    !toolsLoading ?
                                        <button onClick={() => {
                                            submitMitraTools()
                                        }} style={{ marginTop: '10px', width: '100%', height: '90%', background: canSubmitTools ? MaterialBlue1 : MaterialGray1, border: `1px solid ${canSubmitTools ? MaterialBlue1 : MaterialGray1}` }} className="btn btn-primary btn-md"
                                            disabled={
                                                canSubmitTools ? '' : 'disabled'
                                            }>{sendToolsText}</button>
                                        : <div className="load"></div>
                                }
                            </Col>
                        </Row>
                    </>
                )
            case TOOLS_MITRA_NOT_FOUND:
                return (
                    <>
                        <p>Not Found</p>
                    </>
                )
            case TOOLS_MITRA_FAILED:
                return (
                    <>
                        <p>Failed</p>
                    </>
                )
        }
    }
    function buildMitraDetail() {
        switch (props.mitra.payload.type) {
            case MITRA_DETAIL_LOADING:
                return (
                    <Container className="mt--7" fluid>
                        <Row>
                            <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
                                <Card style={{ marginTop: '20px' }} className="card-profile-shadow">
                                    <Row className="justify-content-center">
                                        <Col className="order-lg-2" lg="3">
                                            <div className="card-profile-image">
                                                <Skeleton
                                                    className="shimmer_image"
                                                    style={{
                                                        transform: 'translate(-50% , -40%)',
                                                        position: 'absolute',
                                                        left: '50%',
                                                        borderRadius: '50%',
                                                        backgroundColor: MaterialGray1,
                                                        width: '150px',
                                                        height: '150px'
                                                    }} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <CardHeader className="text-center border-0 pt-8 pt-md-0 pb-0 pb-md-0">
                                    </CardHeader>
                                    <CardBody className="pt-0 pt-md-4">
                                        <Row>
                                            <div className="col">
                                                <div className="card-profile-stats d-flex justify-content-center mt-md-6">
                                                    <div>
                                                        <Skeleton style={{ color: MaterialGray1, backgroundColor: MaterialGray1, borderRadius: '10px' }} className="heading"></Skeleton>
                                                        <span style={{ color: 'transparent' }} className="description">-------</span>
                                                    </div>
                                                    <div>
                                                        <Skeleton style={{ color: MaterialGray1, backgroundColor: MaterialGray1, borderRadius: '10px' }} className="heading"></Skeleton>
                                                        <span style={{ color: 'transparent' }} className="description">-------</span>
                                                    </div>
                                                    <div>
                                                        <Skeleton style={{ color: MaterialGray1, backgroundColor: MaterialGray1, borderRadius: '10px' }} className="heading"></Skeleton>
                                                        <span style={{ color: 'transparent' }} className="description">-------</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Row>
                                        <div style={{ textAlign: 'center' }} className="text-center">
                                            <Skeleton style={{ color: MaterialGray1, backgroundColor: MaterialGray1 }}>
                                            </Skeleton>
                                            <Skeleton style={{ color: MaterialGray1, backgroundColor: MaterialGray1 }}>
                                            </Skeleton>
                                            <Skeleton style={{ color: MaterialGray1, backgroundColor: MaterialGray1 }}>
                                            </Skeleton>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col style={{ marginTop: '20px' }} className="order-xl-1" xl="8">
                                <Card className="bg-secondary shadow">
                                    <CardBody>
                                        <Form>
                                            <Skeleton style={{ width: '150px', backgroundColor: MaterialGray1 }} className="heading-small text-muted mb-4">
                                            </Skeleton>
                                            <div>
                                                <Row>
                                                    <Col lg="6">
                                                        <FormGroup>
                                                            <Skeleton style={{ height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="6">
                                                        <Skeleton style={{ height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg="6">
                                                        <Skeleton style={{ height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                    </Col>
                                                    <Col lg="6">
                                                        <Skeleton style={{ height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                    </Col>
                                                </Row>
                                                <hr className="my-4" />
                                                <Skeleton style={{ width: '150px', backgroundColor: MaterialGray1 }} className="heading-small text-muted mb-4">
                                                </Skeleton>
                                                <Row>
                                                    <Col>
                                                        <Skeleton style={{ height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                    </Col>
                                                </Row>
                                                <Row style={{ marginTop: '20px' }}>
                                                    <Col lg="4">
                                                        <Skeleton style={{ height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                    </Col>
                                                    <Col lg="4">
                                                        <Skeleton style={{ height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                    </Col>
                                                    <Col lg="4">
                                                        <Skeleton style={{ height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                )
            case MITRA_DETAIL_SUCCESSFUL:
                const data = props.mitra.payload.data
                return (
                    <Container className="mt--7" fluid>
                        <Row>
                            <Col className="order-xl-2 mb-6 mb-xl-0" xl="4">
                                <Card className="card-profile shadow">
                                    <Row className="justify-content-center">
                                        <Col className="order-lg-2" lg="3">
                                            <div className="card-profile-image">
                                                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                                    <div>
                                                        {
                                                            profileImageSelected === null ?
                                                                <div style={{
                                                                    alignItems: 'center',
                                                                    display: 'flex',
                                                                    justifyContent: 'center'
                                                                }}>
                                                                    <img src={`${BASE_URL}/api/images${data.user_profile_image}`} style={{
                                                                        width: '150px',
                                                                        objectFit: 'cover',
                                                                        borderRadius: '50%',
                                                                        height: '150px',
                                                                        transform: 'translate(-50% , 0%)',
                                                                        display: 'inline-block'
                                                                    }} />
                                                                </div>
                                                                :
                                                                <div style={{
                                                                    alignItems: 'center',
                                                                    display: 'flex',
                                                                    justifyContent: 'center'
                                                                }}>
                                                                    <img src={profileImageSelected} style={{
                                                                        width: '150px',
                                                                        objectFit: 'cover',
                                                                        borderRadius: '50%',
                                                                        height: '150px',
                                                                        transform: 'translate(-50% , 0%)',
                                                                        display: 'inline-block'
                                                                    }} />
                                                                </div>
                                                        }
                                                    </div>
                                                </a>
                                            </div>
                                        </Col>
                                    </Row>
                                    <CardHeader style={{ borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }} className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-0">
                                    </CardHeader>
                                    <CardBody style={{ marginTop: '50px' }} className="pt-0 pt-md-4">
                                        {
                                            data.is_mitra_invited === '0' ?
                                                <Row>
                                                    <Col md="6">
                                                        <input type="file" onChange={onChangeProfileImageHandler} name="image_picker_profile" ref={fileRefProfileImageChange} hidden />
                                                        <div
                                                            onClick={() => {
                                                                fileRefProfileImageChange.current.click()
                                                            }}
                                                            style={{
                                                                width: '100%',
                                                                height: '30px',
                                                                textAlign: 'center',
                                                                color: 'white',
                                                                fontSize: '12px',
                                                                borderRadius: '10px',
                                                                pointerEvents: isEditing ? 'visible' : 'none',
                                                                cursor: isEditing ? 'pointer' : 'not-allowed',
                                                                backgroundColor: isEditing ? '#2DCE89' : '#E9ECEF'
                                                            }}
                                                        >
                                                            <p style={{
                                                                fontSize: '11px',
                                                                position: 'relative',
                                                                top: '50%',
                                                                left: '50%',
                                                                fontWeight: 'bold',
                                                                color: 'white',
                                                                transform: 'translate(-50% , -50%)'
                                                            }}>
                                                                Ganti Foto
                                                            </p>
                                                        </div>
                                                    </Col>
                                                    <Col md="6">
                                                        <div
                                                            onClick={() => {
                                                                setSelectedFileProfileImage(null)
                                                                setProfileImageSelected(null)
                                                            }}
                                                            style={{
                                                                width: '100%',
                                                                height: '30px',
                                                                textAlign: 'center',
                                                                color: 'white',
                                                                fontSize: '12px',
                                                                borderRadius: '10px',
                                                                cursor: profileImageSelected !== null && isUpdating == false ? 'pointer' : 'not-allowed',
                                                                pointerEvents: profileImageSelected !== null && isUpdating == false ? 'visible' : 'none',
                                                                backgroundColor: profileImageSelected !== null && isUpdating == false ? '#F5365C' : '#E9ECEF'
                                                            }}
                                                        >
                                                            <p style={{
                                                                fontSize: '11px',
                                                                position: 'relative',
                                                                top: '50%',
                                                                left: '50%',
                                                                color: 'white',
                                                                fontWeight: 'bold',
                                                                transform: 'translate(-50% , -50%)'
                                                            }}>
                                                                Hapus Foto
                                                            </p>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                : <div></div>
                                        }
                                        {
                                            data.is_mitra_activated === '0' ?
                                                <Row>
                                                    <Col>
                                                        <div style={{ textAlign: 'center' }}>
                                                            <div style={{
                                                                paddingLeft: '8px',
                                                                paddingRight: '8px',
                                                                backgroundColor: '#fbc02d',
                                                                borderRadius: '10px'
                                                            }}>
                                                                <p style={{
                                                                    color: 'white',
                                                                    marginTop: '10px',
                                                                    fontSize: '14px',
                                                                    marginBottom: '10px'
                                                                }}>Mitra Belum Aktif</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                : <div></div>
                                        }
                                        <Row>
                                            <Col>
                                                {
                                                    data !== null && data.is_mitra_invited === '1' ?
                                                        data.is_mitra_rejected === '1' ?
                                                            <p style={{ backgroundColor: MaterialRed1, textAlign: 'center', paddingTop: '15px', paddingBottom: '15px', paddingLeft: '10px', paddingRight: '10px', borderRadius: '8px', color: 'white', fontSize: '13px', fontWeight: 'bold' }}>
                                                                Mitra Ditolak
                                                            </p>
                                                            :
                                                            data.is_mitra_accepted === '1' ?
                                                                <p style={{ backgroundColor: MaterialBlue1, textAlign: 'center', paddingTop: '15px', paddingBottom: '15px', paddingLeft: '10px', paddingRight: '10px', borderRadius: '8px', color: 'white', fontSize: '13px', fontWeight: 'bold' }}>
                                                                    Training Mitra Berhasil
                                                                </p>
                                                                :
                                                                <p style={{ backgroundColor: MaterialGray2, textAlign: 'center', paddingTop: '15px', paddingBottom: '15px', paddingLeft: '10px', paddingRight: '10px', borderRadius: '8px', color: MaterialGray1, fontSize: '13px', fontWeight: 'bold' }}>
                                                                    Sedang Training dan Pengecekan Data
                                                                </p>
                                                        : <Form>
                                                            <button
                                                                type="button"
                                                                onClick={() => {
                                                                    if (token !== '') {
                                                                        props.getScheduleForMitra(currentPageSchedule, 5, "", token)
                                                                        setInviteMitra(true)
                                                                    }
                                                                }}
                                                                style={{ width: '100%' }} className="btn btn-primary btn-md">
                                                                Undang Training
                                                            </button>
                                                        </Form>
                                                }
                                            </Col>
                                        </Row>
                                        {
                                            data.is_mitra_accepted === '0' && data.is_mitra_invited === '1' ?
                                                <>
                                                    <Row>
                                                        <Col md="6">
                                                            <Form>
                                                                <button
                                                                    type="button"
                                                                    onClick={() => {
                                                                        setTrainingStatus('successful')
                                                                        setDialogTrainStatus(true)
                                                                    }}
                                                                    style={{ width: '100%' }} className="btn btn-success btn-md">
                                                                    Training Selesai
                                                                </button>
                                                            </Form>
                                                        </Col>
                                                        <Col md="6">
                                                            <Form>
                                                                <button
                                                                    type="button"
                                                                    onClick={() => {
                                                                        setTrainingStatus('failed')
                                                                        setDialogTrainStatus(true)
                                                                    }}
                                                                    style={{ width: '100%' }} className="btn btn-danger btn-md">
                                                                    Training Gagal
                                                                </button>
                                                            </Form>
                                                        </Col>
                                                    </Row>
                                                </>
                                                : <div></div>
                                        }
                                        {
                                            data.kind_of_mitra === 'Tanpa Alat' ?
                                                <Row style={{ marginTop: '15px' }}>
                                                    <Col md="12">
                                                        <Form>
                                                            <button
                                                                type="button"
                                                                onClick={() => {
                                                                    if (data.kind_of_mitra === 'Tanpa Alat') {
                                                                        setDialogUserTools(true)
                                                                        if (props.location.state !== undefined && props.location.state.mitra_id !== undefined) {
                                                                            props.getToolsByMitra(props.location.state.mitra_id, currentPageSelectedTools, 5, "", token)
                                                                        }
                                                                    }
                                                                }}
                                                                style={{ width: '100%' }} className="btn btn-primary btn-md">
                                                                Tetapkan Alat
                                                            </button>
                                                        </Form>
                                                    </Col>
                                                </Row>
                                                : <div></div>
                                        }
                                        {
                                            data.is_mitra_invited === '1' && data.is_mitra_accepted === '1' && data.is_mitra_rejected === '0' ?
                                                <Row style={{ marginTop: data.is_mitra_invited === '0' ? '20px' : '0px' }}>
                                                    <Col md="6">
                                                        <Form>
                                                            <button type="button" onClick={() => {
                                                                setAcceptStatus('successful')
                                                                setDialogAcceptStatus(true)
                                                            }} style={{ width: '100%' }} className="btn btn-success btn-md">
                                                                Aktifkan Mitra
                                                            </button>
                                                        </Form>
                                                    </Col>
                                                    <Col md="6">
                                                        <Form>
                                                            <button type="button" onClick={() => {
                                                                setAcceptStatus('failed')
                                                                setDialogAcceptStatus(true)
                                                            }} style={{ width: '100%' }} className="btn btn-danger btn-md">
                                                                Tolak Mitra
                                                            </button>
                                                        </Form>
                                                    </Col>
                                                </Row>
                                                : <div></div>
                                        }
                                        <Row style={{ marginTop: '20px' }}>
                                            <Col md="6">
                                                <div style={{ textAlign: 'center' }}>
                                                    <span className="heading">{data !== null ? data.total_order : 0}</span><br />
                                                    <span className="description">Orderan</span>
                                                </div>
                                            </Col>
                                            <Col md="6">
                                                <div style={{ textAlign: 'center' }}>
                                                    <span className="heading">{data !== null ? parseFloat(data.user_rating).toFixed(2) : 0.0}</span><br />
                                                    <span className="description">Rating</span>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row style={{ marginTop: '20px' }}>
                                            <Col style={{ textAlign: 'center' }}>
                                                <h3>
                                                    {data !== null ? data.complete_name : ''}
                                                </h3>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col style={{ textAlign: 'center' }}>
                                                {
                                                    data.age !== null ?
                                                        data.age.toLowerCase().includes("tahun") ?
                                                            data.age : `${data.age} Tahun`
                                                        :
                                                        '0 Tahun'
                                                }
                                            </Col>
                                        </Row>
                                        <Row style={{ marginTop : '10px' }}>
                                            <Col style={{ textAlign : 'center' }}>
                                                Calon Mitra Suberes
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col className="order-xl-1" xl="8">
                                <Card className="bg-secondary shadow">
                                    {
                                        isUpdating ?
                                            <div style={{
                                                borderTopLeftRadius: '5px',
                                                borderTopRightRadius: '5px',
                                                height: '5px'
                                            }} className="progress-bar">
                                                <div className="progress-bar-value"></div>
                                            </div>
                                            : <div style={{
                                                height: '5px'
                                            }}></div>
                                    }
                                    <CardBody>
                                        <Form>
                                            <div className="align-items-center row">
                                                <div className="col-8">
                                                    <h3 className="mb-0">Informasi Mitra</h3>
                                                </div>
                                                <div className="text-right col-4">
                                                    {
                                                        !isEditing ?
                                                            <a onClick={(e) => editUpdate()} className="btn btn-primary btn-sm">
                                                                Ubah
                                                            </a> :
                                                            countChangeValue > 0 ?
                                                                <a onClick={(e) => {
                                                                    editUpdate()
                                                                }} className="btn btn-success btn-sm">
                                                                    Simpan
                                                                </a>
                                                                :
                                                                <a onClick={(e) => setEditing(false)} className="btn btn-primary btn-sm">
                                                                    Tutup
                                                                </a>
                                                    }
                                                </div>
                                            </div>
                                            <div style={{ marginTop: '20px' }}>
                                                <Row>
                                                    <Col lg="6">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-email"
                                                            >
                                                                Email address
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id="input-email"
                                                                placeholder="Email"
                                                                type="email"
                                                                name="email"
                                                                onChange={onChangeHandler}
                                                                style={
                                                                    isSubmit && inputValues.email === '' ?
                                                                        {
                                                                            border: '0.2px solid #d32f2f'
                                                                        }
                                                                        :
                                                                        {
                                                                            border: 'none'
                                                                        }
                                                                }
                                                                value={inputValues.email}
                                                                disabled={(isEditing) ? "" : "disabled"}
                                                            />
                                                            {
                                                                isSubmit && inputValues.email === '' ?
                                                                    <p style={{
                                                                        color: '#d32f2f',
                                                                        fontSize: '11px',
                                                                        marginTop: '5px',
                                                                        marginLeft: '1px'
                                                                    }}>Email belum terisi</p>
                                                                    : <div></div>
                                                            }
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="6">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-email"
                                                            >
                                                                Nomor Telepon
                                                            </label>
                                                            <IntlTelInput
                                                                preferredCountries={['id']}
                                                                value={inputValues.phone_number}
                                                                containerClassName="intl-tel-input"
                                                                name="phone_number"
                                                                inputClassName="form-control"
                                                                style={
                                                                    isSubmit && inputValues.phone_number === '' ?
                                                                        {
                                                                            width: '100%',
                                                                            borderRadius: '6px',
                                                                            border: '0.2px solid #d32f2f'
                                                                        } :
                                                                        {
                                                                            width: '100%',
                                                                            borderRadius: '6px',
                                                                            border: 'none'
                                                                        }
                                                                }
                                                                onPhoneNumberChange={(status, value, countryData, number, id) => {
                                                                    if (existingValues['phone_number'].value === number.split("-").join("").split(" ").join("")) {
                                                                        if (existingValues['phone_number'].hasChanged) {
                                                                            if (countChangeValue > 0) {
                                                                                existingValues['phone_number'].hasChanged = false
                                                                                setCountChangeValue(countChangeValue - 1)
                                                                            }
                                                                        }
                                                                    } else {
                                                                        if (!existingValues['phone_number'].hasChanged) {
                                                                            existingValues['phone_number'].hasChanged = true
                                                                            setCountChangeValue(countChangeValue + 1)
                                                                        }
                                                                    }
                                                                    setInputValues({
                                                                        phone_number: number.split("-").join("").split(" ").join("")
                                                                    })
                                                                }}
                                                                onSelectFlag={(num, country) => {
                                                                    setInputValues({ country_code: `+${country.dialCode}` })
                                                                }}
                                                                disabled={(isEditing) ? "" : "disabled"} />
                                                            {
                                                                isSubmit && inputValues.phone_number === '' ?
                                                                    <p style={{
                                                                        color: '#d32f2f',
                                                                        fontSize: '11px',
                                                                        marginTop: '5px',
                                                                        marginLeft: '1px'
                                                                    }}>Nomor Telepon belum terisi</p>
                                                                    : <div></div>
                                                            }
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg="6">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-first-name"
                                                            >
                                                                Jenis Kelamin
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id="input-gender"
                                                                placeholder="Jenis Kelamin"
                                                                type="select"
                                                                onChange={onChangeHandler}
                                                                name="user_gender"
                                                                value={inputValues.user_gender}
                                                                disabled={(isEditing) ? "" : "disabled"}
                                                            >
                                                                <option>Pria</option>
                                                                <option>Wanita</option>
                                                            </Input>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="6">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-last-name"
                                                            >
                                                                Dengan Alat / Tanpa Alat
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                onChange={onChangeHandler}
                                                                id="input-region"
                                                                placeholder="Tipe Mitra"
                                                                name="kind_of_mitra"
                                                                value={inputValues.kind_of_mitra}
                                                                type="select"
                                                                disabled={(isEditing) ? "" : "disabled"}
                                                            >
                                                                <option>Dengan Alat</option>
                                                                <option>Tanpa Alat</option>
                                                            </Input>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg="6">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="place_of_birth"
                                                            >
                                                                Tempat Lahir
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                value={inputValues.place_of_birth}
                                                                id="place_of_birth"
                                                                name="place_of_birth"
                                                                onChange={onChangeHandler}
                                                                type="text"
                                                                disabled={(isEditing) ? "" : "disabled"}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="6">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="date_of_birth"
                                                            >
                                                                Tanggal Lahir
                                                            </label>
                                                            <DatePicker
                                                                id="date_of_birth"
                                                                value={inputValues.date_of_birth}
                                                                name="date_of_birth"
                                                                dateFormat="DD/MM/YYYY"
                                                                onChange={(v, f) => {
                                                                    const split_date = f.split("/")
                                                                    const formattedDate = `${split_date[2]}-${split_date[1]}-${split_date[0]}`
                                                                    if (existingValues['date_of_birth'].value === formattedDate) {
                                                                        if (existingValues['date_of_birth'].hasChanged) {
                                                                            if (countChangeValue > 0) {
                                                                                existingValues['date_of_birth'].hasChanged = false
                                                                                setCountChangeValue(countChangeValue - 1)
                                                                            }
                                                                        }
                                                                    } else {
                                                                        if (!existingValues['date_of_birth'].hasChanged) {
                                                                            existingValues['date_of_birth'].hasChanged = true
                                                                            setCountChangeValue(countChangeValue + 1)
                                                                        }
                                                                    }
                                                                    setInputValues({ ['date_of_birth']: formattedDate })
                                                                }}
                                                                disabled={(isEditing) ? "" : "disabled"} />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg="6">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="ktp_number"
                                                            >
                                                                Nomor KTP
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                value={inputValues.ktp_number}
                                                                id="ktp_number"
                                                                name="ktp_number"
                                                                onChange={onChangeHandler}
                                                                type="text"
                                                                disabled={(isEditing) ? "" : "disabled"}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <hr className="my-4" />
                                            {/* Address */}
                                            <h3 className="mb-0">Alamat Calon Mitra (Domisili & KTP)</h3>
                                            <div style={{ marginTop: '20px' }}>
                                                <Row>
                                                    <Col md="12">
                                                        <div>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-domisili-address"
                                                            >
                                                                Alamat Domisili
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id="input-domisili-address"
                                                                placeholder="Alamat Domisili"
                                                                name="domisili_address"
                                                                value={inputValues.domisili_address}
                                                                type="text"
                                                                disabled={isEditing ? "" : "disabled"} />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <div>
                                                <Row>
                                                    <Col md="12">
                                                        <div style={{ marginTop: '10px' }}>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-address"
                                                            >
                                                                Alamat KTP
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id="input-address"
                                                                placeholder="Alamat"
                                                                name="address"
                                                                value={inputValues.address}
                                                                onChange={onChangeHandler}
                                                                type="text"
                                                                disabled={(isEditing) ? "" : "disabled"}
                                                            />
                                                            <Row style={{ marginTop: '10px' }}>
                                                                <Col>
                                                                    <label
                                                                        className="form-control-label"
                                                                        htmlFor="input-kelurahan"
                                                                    >
                                                                        Nama Kelurahan
                                                                    </label>
                                                                    <Input
                                                                        className="form-control-alternative"
                                                                        id="input-kelurahan"
                                                                        placeholder="Kelurahan"
                                                                        name="sub_district"
                                                                        onChange={onChangeHandler}
                                                                        value={inputValues.sub_district}
                                                                        type="text"
                                                                        disabled={(isEditing) ? "" : "disabled"} />
                                                                </Col>
                                                                <Col>
                                                                    <label
                                                                        className="form-control-label"
                                                                        htmlFor="input-kecamatan"
                                                                    >
                                                                        Nama Kecamatan
                                                                    </label>
                                                                    <Input
                                                                        className="form-control-alternative"
                                                                        id="input-kecamatan"
                                                                        placeholder="Kecamatan"
                                                                        name="district"
                                                                        onChange={onChangeHandler}
                                                                        value={inputValues.district}
                                                                        type="text"
                                                                        disabled={(isEditing) ? "" : "disabled"} />
                                                                </Col>
                                                            </Row>
                                                            <Row style={{ marginTop: '10px' }}>
                                                                <Col>
                                                                    <label
                                                                        className="form-control-label"
                                                                        htmlFor="input-postal-code"
                                                                    >
                                                                        Kode POS
                                                                    </label>
                                                                    <Input
                                                                        className="form-control-alternative"
                                                                        id="input-postal-code"
                                                                        placeholder="Kode Pos"
                                                                        name="postal_code"
                                                                        onChange={onChangeHandler}
                                                                        value={inputValues.postal_code}
                                                                        type="text"
                                                                        disabled={(isEditing) ? "" : "disabled"} />
                                                                </Col>
                                                                <Col>
                                                                    <label
                                                                        className="form-control-label"
                                                                        htmlFor="input-rt-rw"
                                                                    >
                                                                        RT/RW
                                                                    </label>
                                                                    <Input
                                                                        className="form-control-alternative"
                                                                        id="input-rt-rw"
                                                                        placeholder="RT/RW"
                                                                        name="rt_rw"
                                                                        onChange={onChangeHandler}
                                                                        value={inputValues.rt_rw}
                                                                        type="text"
                                                                        disabled={(isEditing) ? "" : "disabled"} />
                                                                </Col>
                                                            </Row>
                                                            <Row style={{ marginTop: '10px' }}>
                                                                <Col>
                                                                    <label
                                                                        className="form-control-label"
                                                                        htmlFor="input-city"
                                                                    >
                                                                        Kota
                                                                    </label>
                                                                    <Input
                                                                        className="form-control-alternative"
                                                                        id="input-city"
                                                                        placeholder="Kota/Kabupaten"
                                                                        name="city"
                                                                        onChange={onChangeHandler}
                                                                        value={inputValues.city}
                                                                        type="text"
                                                                        disabled={(isEditing) ? "" : "disabled"} />
                                                                </Col>
                                                                <Col>
                                                                    <label
                                                                        className="form-control-label"
                                                                        htmlFor="input-province"
                                                                    >
                                                                        Provinsi
                                                                    </label>
                                                                    <Input
                                                                        className="form-control-alternative"
                                                                        id="input-province"
                                                                        placeholder="RT/RW"
                                                                        name="province"
                                                                        onChange={onChangeHandler}
                                                                        value={inputValues.province}
                                                                        type="text"
                                                                        disabled={(isEditing) ? "" : "disabled"} />
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <hr className="my-4" />
                                            <h3 className="mb-0">Foto Identitas</h3>
                                            <div style={{
                                                marginTop: '20px'
                                            }}>
                                                <Row>
                                                    <Col lg="6">
                                                        <label
                                                            className="form-control-label">Foto KK</label>
                                                        {
                                                            inputValues.kk_image === '' ?
                                                                <div id="placeholder_image"
                                                                    style={{
                                                                        width: '100%',
                                                                        height: '160px',
                                                                        borderRadius: '10px',
                                                                        alignItems: 'center',
                                                                        display: 'flex',
                                                                        justifyContent: 'center',
                                                                        textAlign: 'center',
                                                                        backgroundColor: MaterialGray2,
                                                                        border: '1px dashed black',
                                                                        cursor: 'pointer'
                                                                    }}
                                                                    onClick={e => {
                                                                        fileRefKk.current.click()
                                                                    }}
                                                                >
                                                                    <input type="file" name="image_picker" ref={fileRefKk} hidden />
                                                                </div>
                                                                :
                                                                <div style={{
                                                                }}>
                                                                    {
                                                                        kkImageSelected === null ?
                                                                            <div style={{
                                                                                alignItems: 'center',
                                                                                display: 'flex',
                                                                                justifyContent: 'center'
                                                                            }}>
                                                                                <img style={{
                                                                                    width: '100%',
                                                                                    height: '160px',
                                                                                    objectFit: 'cover',
                                                                                    borderRadius: '10px',
                                                                                    transition: '0.45s'
                                                                                }} src={`${BASE_URL}/api/images${inputValues.kk_image}`} />
                                                                                <div style={{
                                                                                    display: 'flex',
                                                                                    position: 'absolute'
                                                                                }}>
                                                                                    <div
                                                                                        onClick={() => {
                                                                                            setModalImageUrl(inputValues.kk_image)
                                                                                            setModal(true)
                                                                                        }}
                                                                                        style={{
                                                                                            backgroundColor: 'transparent',
                                                                                            borderRadius: '10px',
                                                                                            border: '1px solid white',
                                                                                            paddingLeft: '20px',
                                                                                            paddingRight: '20px',
                                                                                            cursor: 'pointer'
                                                                                        }}
                                                                                    >
                                                                                        <p style={{
                                                                                            fontSize: '11px',
                                                                                            position: 'relative',
                                                                                            top: '50%',
                                                                                            left: '50%',
                                                                                            color: 'white',
                                                                                            transform: 'translate(-50% , -50%)'
                                                                                        }}>
                                                                                            See Image
                                                                                        </p>
                                                                                    </div>
                                                                                    {
                                                                                        isEditing ?
                                                                                            <div
                                                                                                style={{
                                                                                                    backgroundColor: isKkHover ? '#000000' : 'transparent',
                                                                                                    borderRadius: '10px',
                                                                                                    border: '1px solid white',
                                                                                                    paddingLeft: '20px',
                                                                                                    marginLeft: '10px',
                                                                                                    paddingRight: '20px',
                                                                                                    transition: '0.45s',
                                                                                                    cursor: 'pointer'
                                                                                                }}
                                                                                                onMouseOver={() => {
                                                                                                    setKkHover(true)
                                                                                                }}
                                                                                                onMouseLeave={() => {
                                                                                                    setKkHover(false)
                                                                                                }} setKkHover
                                                                                                onClick={() => {
                                                                                                    fileRefKkChange.current.click()
                                                                                                }}
                                                                                            >
                                                                                                <input type="file" onChange={onChangeKkImageHandler} name="image_picker_change" ref={fileRefKkChange} hidden />
                                                                                                <p
                                                                                                    style={{
                                                                                                        fontSize: '11px',
                                                                                                        position: 'relative',
                                                                                                        top: '50%',
                                                                                                        left: '50%',
                                                                                                        color: 'white',
                                                                                                        transform: 'translate(-50% , -50%)'
                                                                                                    }}
                                                                                                >
                                                                                                    Change Image
                                                                                                </p>
                                                                                            </div>
                                                                                            :
                                                                                            <div></div>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            <div style={{
                                                                                width: '100%',
                                                                                border: '1px dashed black',
                                                                                borderRadius: '10px',
                                                                                alignItems: 'center',
                                                                                justifyContent: 'center',
                                                                                textAlign: 'center'
                                                                            }}>
                                                                                <div style={{
                                                                                    width: '25px',
                                                                                    height: '25px',
                                                                                    borderRadius: '50%',
                                                                                    backgroundColor: MaterialRed1,
                                                                                    position: 'absolute',
                                                                                    right: 8,
                                                                                    top: 22,
                                                                                    cursor: 'pointer'
                                                                                }}
                                                                                    onClick={() => {
                                                                                        setSelectedFileKk(null)
                                                                                        setKKImageSelected(null)
                                                                                    }}
                                                                                >
                                                                                    <p style={{ color: 'white' }}>&times;</p>
                                                                                </div>
                                                                                <img src={kkImageSelected} style={{
                                                                                    width: '100%',
                                                                                    objectFit: 'cover',
                                                                                    borderRadius: '10px',
                                                                                    height: '300px',
                                                                                    display: 'inline-block'
                                                                                }} />
                                                                            </div>
                                                                    }
                                                                </div>
                                                        }
                                                    </Col>
                                                    <Col lg="6">
                                                        <label
                                                            className="form-control-label">Foto KTP</label>
                                                        {
                                                            inputValues.ktp_image === '' ?
                                                                <div id="placeholder_image"
                                                                    style={{
                                                                        width: '100%',
                                                                        height: '160px',
                                                                        borderRadius: '10px',
                                                                        alignItems: 'center',
                                                                        display: 'flex',
                                                                        justifyContent: 'center',
                                                                        textAlign: 'center',
                                                                        backgroundColor: MaterialGray2,
                                                                        border: '1px dashed black',
                                                                        cursor: 'pointer'
                                                                    }}
                                                                    onClick={e => {
                                                                        fileRefKtp.current.click()
                                                                    }}
                                                                >
                                                                    <input type="file" name="image_picker" ref={fileRefKtp} hidden />
                                                                </div>
                                                                :
                                                                <div style={{
                                                                }}>
                                                                    {
                                                                        ktpImageSelected === null ?
                                                                            <div style={{
                                                                                alignItems: 'center',
                                                                                display: 'flex',
                                                                                justifyContent: 'center'
                                                                            }}>
                                                                                <img style={{
                                                                                    width: '100%',
                                                                                    height: '160px',
                                                                                    objectFit: 'cover',
                                                                                    borderRadius: '10px',
                                                                                    transition: '0.45s'
                                                                                }} src={`${BASE_URL}/api/images${inputValues.ktp_image}`} />
                                                                                <div style={{
                                                                                    display: 'flex',
                                                                                    position: 'absolute'
                                                                                }}>
                                                                                    <div
                                                                                        onClick={() => {
                                                                                            setModalImageUrl(inputValues.ktp_image)
                                                                                            setModal(true)
                                                                                        }}
                                                                                        style={{
                                                                                            backgroundColor: 'transparent',
                                                                                            borderRadius: '10px',
                                                                                            border: '1px solid white',
                                                                                            paddingLeft: '20px',
                                                                                            paddingRight: '20px',
                                                                                            cursor: 'pointer'
                                                                                        }}
                                                                                    >
                                                                                        <p style={{
                                                                                            fontSize: '11px',
                                                                                            position: 'relative',
                                                                                            top: '50%',
                                                                                            left: '50%',
                                                                                            color: 'white',
                                                                                            transform: 'translate(-50% , -50%)'
                                                                                        }}>
                                                                                            See Image
                                                                                        </p>
                                                                                    </div>
                                                                                    {
                                                                                        isEditing ?
                                                                                            <div
                                                                                                style={{
                                                                                                    backgroundColor: isKtpHover ? '#000000' : 'transparent',
                                                                                                    borderRadius: '10px',
                                                                                                    border: '1px solid white',
                                                                                                    paddingLeft: '20px',
                                                                                                    marginLeft: '10px',
                                                                                                    paddingRight: '20px',
                                                                                                    transition: '0.45s',
                                                                                                    cursor: 'pointer'
                                                                                                }}
                                                                                                onMouseOver={() => {
                                                                                                    setKtpHover(true)
                                                                                                }}
                                                                                                onMouseLeave={() => {
                                                                                                    setKtpHover(false)
                                                                                                }}
                                                                                                onClick={() => {
                                                                                                    fileRefKtpChange.current.click()
                                                                                                }}
                                                                                            >
                                                                                                <input type="file" onChange={onChangeKtpImageHandler} name="image_picker_change" ref={fileRefKtpChange} hidden />
                                                                                                <p
                                                                                                    style={{
                                                                                                        fontSize: '11px',
                                                                                                        position: 'relative',
                                                                                                        top: '50%',
                                                                                                        left: '50%',
                                                                                                        color: 'white',
                                                                                                        transform: 'translate(-50% , -50%)'
                                                                                                    }}
                                                                                                >
                                                                                                    Change Image
                                                                                                </p>
                                                                                            </div>
                                                                                            :
                                                                                            <div></div>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            <div style={{
                                                                                width: '100%',
                                                                                border: '1px dashed black',
                                                                                borderRadius: '10px',
                                                                                alignItems: 'center',
                                                                                justifyContent: 'center',
                                                                                textAlign: 'center'
                                                                            }}>
                                                                                <div style={{
                                                                                    width: '25px',
                                                                                    height: '25px',
                                                                                    borderRadius: '50%',
                                                                                    backgroundColor: MaterialRed1,
                                                                                    position: 'absolute',
                                                                                    right: 8,
                                                                                    top: 22,
                                                                                    cursor: 'pointer'
                                                                                }}
                                                                                    onClick={() => {
                                                                                        setSelectedFileKtp(null)
                                                                                        setKtpImageSelected(null)
                                                                                    }}
                                                                                >
                                                                                    <p style={{ color: 'white' }}>&times;</p>
                                                                                </div>
                                                                                <img src={ktpImageSelected} style={{
                                                                                    width: '100%',
                                                                                    objectFit: 'cover',
                                                                                    borderRadius: '10px',
                                                                                    height: '160px',
                                                                                    display: 'inline-block'
                                                                                }} />
                                                                            </div>
                                                                    }
                                                                </div>
                                                        }
                                                    </Col>
                                                </Row>
                                            </div>
                                            <hr className="my-4" />
                                            <h3 className="mb-0">Pengalaman Kerja &#38; Kontak Darurat</h3>
                                            <div style={{
                                                marginTop: '20px'
                                            }}>
                                                <Row>
                                                    <Col md="6">
                                                        <fieldset className="border p-2">
                                                            <legend style={{ fontSize: '13px' }} className="w-auto">Pengalaman Kerja</legend>
                                                            <div>
                                                                <FormGroup>
                                                                    <label
                                                                        className="form-control-label"
                                                                        htmlFor="is_ex_golife">Mantan GoLife?</label>
                                                                    <Input
                                                                        className="form-control-alternative"
                                                                        id="is_ex_golife"
                                                                        placeholder="Mantan GoLife?"
                                                                        name="is_ex_golife"
                                                                        onChange={onChangeHandler}
                                                                        value={inputValues.is_ex_golife}
                                                                        type="select"
                                                                        disabled={(isEditing) ? "" : "disabled"}
                                                                    >
                                                                        <option>Ya</option>
                                                                        <option>Tidak</option>
                                                                    </Input>
                                                                </FormGroup>
                                                            </div>
                                                            <div>
                                                                <FormGroup>
                                                                    <label
                                                                        className="form-control-label"
                                                                        htmlFor="work_experience">Lama Pengalaman</label>
                                                                    <Input
                                                                        className="form-control-alternative"
                                                                        id="work_experience"
                                                                        placeholder="Lama Pengalaman"
                                                                        name="work_experience_duration"
                                                                        onChange={onChangeHandler}
                                                                        value={inputValues.work_experience_duration}
                                                                        type="text"
                                                                        disabled={(isEditing) ? "" : "disabled"} />
                                                                </FormGroup>
                                                            </div>
                                                        </fieldset>
                                                    </Col>
                                                    <Col md="6">
                                                        <fieldset className="border p-2">
                                                            <legend style={{ fontSize: '13px' }} className="w-auto">Kontak Darurat</legend>
                                                            <div>
                                                                <FormGroup>
                                                                    <label
                                                                        className="form-control-label"
                                                                        htmlFor="emergency_contact_name">Nama Kontak</label>
                                                                    <Input
                                                                        className="form-control-alternative"
                                                                        id="emergency_contact_name"
                                                                        placeholder="Nama Kontak"
                                                                        value={inputValues.emergency_contact_name}
                                                                        onChange={onChangeHandler}
                                                                        name="emergency_contact_name"
                                                                        type="text"
                                                                        disabled={(isEditing) ? "" : "disabled"} />
                                                                </FormGroup>
                                                            </div>
                                                            <div>
                                                                <FormGroup>
                                                                    <label
                                                                        className="form-control-label"
                                                                        htmlFor="emergency_contact_relation">Hubungan Dengan Kontak</label>
                                                                    <Input
                                                                        className="form-control-alternative"
                                                                        id="emergency_contact_relation"
                                                                        placeholder="Hubungan Kontak"
                                                                        name="emergency_contact_relation"
                                                                        onChange={onChangeHandler}
                                                                        value={inputValues.emergency_contact_relation}
                                                                        type="text"
                                                                        disabled={(isEditing) ? "" : "disabled"} />
                                                                </FormGroup>
                                                            </div>
                                                            <div>
                                                                <FormGroup>
                                                                    <label
                                                                        className="form-control-label"
                                                                        htmlFor="emergency_contact_phone">No Telepon Darurat</label>
                                                                    <IntlTelInput
                                                                        preferredCountries={['id']}
                                                                        value={inputValues.emergency_contact_phone}
                                                                        containerClassName="intl-tel-input"
                                                                        name="emergency_contact_phone"
                                                                        inputClassName="form-control"
                                                                        style={
                                                                            isSubmit && inputValues.emergency_contact_phone === '' ?
                                                                                {
                                                                                    width: '100%',
                                                                                    borderRadius: '6px',
                                                                                    border: '0.2px solid #d32f2f'
                                                                                } :
                                                                                {
                                                                                    width: '100%',
                                                                                    borderRadius: '6px',
                                                                                    border: 'none'
                                                                                }
                                                                        }
                                                                        onPhoneNumberChange={(status, value, countryData, number, id) => {
                                                                            if (existingValues['emergency_contact_phone'].value === number.split("-").join("").split(" ").join("")) {
                                                                                if (existingValues['emergency_contact_phone'].hasChanged) {
                                                                                    if (countChangeValue > 0) {
                                                                                        existingValues['emergency_contact_phone'].hasChanged = false
                                                                                        setCountChangeValue(countChangeValue - 1)
                                                                                    }
                                                                                }
                                                                            } else {
                                                                                if (!existingValues['emergency_contact_phone'].hasChanged) {
                                                                                    existingValues['emergency_contact_phone'].hasChanged = true
                                                                                    setCountChangeValue(countChangeValue + 1)
                                                                                }
                                                                            }
                                                                            setInputValues({
                                                                                emergency_contact_phone: number.split("-").join("").split(" ").join("")
                                                                            })
                                                                        }}
                                                                        onSelectFlag={(num, country) => {
                                                                            setInputValues({ emergency_contact_country_code: `+${country.dialCode}` })
                                                                        }}
                                                                        disabled={(isEditing) ? "" : "disabled"} />
                                                                    {
                                                                        isSubmit && inputValues.emergency_contact_phone === '' ?
                                                                            <p style={{
                                                                                color: '#d32f2f',
                                                                                fontSize: '11px',
                                                                                marginTop: '5px',
                                                                                marginLeft: '1px'
                                                                            }}>Nomor Telepon Darurat belum terisi</p>
                                                                            : <div></div>
                                                                    }
                                                                </FormGroup>
                                                            </div>
                                                        </fieldset>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Modal isOpen={modal} toggle={toggle}>
                            <ModalHeader className="border-0" toggle={toggle} >
                                Foto Buku Tabungan
                            </ModalHeader>
                            <ModalBody style={{ marginTop: '-20px' }} className="text-left border-0">
                                <Row>
                                    <Col lg="12">
                                        <div>
                                            <img
                                                style={{ width: '450px', height: '300px' }}
                                                src={`${BASE_URL}/api/images${modalImageUrl}`} />
                                        </div>
                                    </Col>
                                </Row>
                            </ModalBody>
                        </Modal>
                        <Modal style={{ width: '80vw', maxWidth: '80vw' }} isOpen={dialogUserTools} toggle={toggleDialogUserTools}>
                            {
                                userToolsLoading ?
                                    <div style={{
                                        borderTopLeftRadius: '5px',
                                        borderTopRightRadius: '5px',
                                        height: '5px'
                                    }} className="progress-bar">
                                        <div className="progress-bar-value"></div>
                                    </div>
                                    : <div style={{
                                        height: '5px'
                                    }}></div>
                            }
                            <ModalHeader className="border-0" toggle={toggleDialogUserTools}>
                                Harap tentukan peralatan untuk mitra bekerja
                            </ModalHeader>
                            <ModalBody style={{ marginTop: '-20px' }} className="text-left border-0">
                                <Row style={{ marginTop: '-10px' }}>
                                    <Col xl="12">
                                        <Form onSubmit={(e) => {
                                            e.preventDefault()
                                        }}>
                                            <FormGroup>
                                                <InputGroup>
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText style={{ border: `1px solid ${MaterialBlue1}` }}>
                                                            <i class="fa fa-search" aria-hidden="true" style={{ color: MaterialBlue1, fontSize: 15 }}>
                                                            </i>
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input
                                                        id="input-search"
                                                        onChange={onChangeSearch}
                                                        style={{
                                                            border: `1px solid ${MaterialBlue1}`,
                                                            boxShadow: 'none',
                                                            paddingLeft: '10px',
                                                            paddingRight: '10px'
                                                        }}
                                                        defaultValue={toolsSearchText}
                                                        placeholder="Ketik nama alat" />
                                                </InputGroup>
                                            </FormGroup>
                                        </Form>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="12">
                                        {buildTools()}
                                    </Col>
                                </Row>
                            </ModalBody>
                        </Modal>
                        <Modal isOpen={dialogAcceptStatus} toggle={toggleDialogAcceptStatus}>
                            {
                                acceptUpdate ?
                                    <div style={{
                                        borderTopLeftRadius: '5px',
                                        borderTopRightRadius: '5px',
                                        height: '5px'
                                    }} className="progress-bar">
                                        <div className="progress-bar-value"></div>
                                    </div>
                                    : <div style={{
                                        height: '5px'
                                    }}></div>
                            }
                            <ModalHeader className="border-0" toggle={toggleDialogTrainStatus}>
                                Yakin ingin memperbarui status mitra ?
                            </ModalHeader>
                            <ModalBody style={{ marginTop: '-20px' }} className="text-left border-0">
                                <Row>
                                    <Col md="6">
                                        <a onClick={() => acceptStatusMitra()}
                                            style={
                                                acceptUpdate ?
                                                    { cursor: 'not-allowed', width: '100%', marginTop: '10px' }
                                                    :
                                                    { cursor: 'pointer', width: '100%', marginTop: '10px' }
                                            }
                                            className="btn btn-success btn-sm">
                                            Ya
                                        </a>
                                    </Col>
                                    <Col md="6">
                                        <a onClick={() => setDialogAcceptStatus(false)} style={{ width: '100%', marginTop: '10px' }} className="btn btn-danger btn-sm" disabled>
                                            Tidak
                                        </a>
                                    </Col>
                                </Row>
                            </ModalBody>
                        </Modal>
                        <Modal isOpen={dialogTrainStatus} toggle={toggleDialogTrainStatus}>
                            {
                                trainingUpdate ?
                                    <div style={{
                                        borderTopLeftRadius: '5px',
                                        borderTopRightRadius: '5px',
                                        height: '5px'
                                    }} className="progress-bar">
                                        <div className="progress-bar-value"></div>
                                    </div>
                                    : <div style={{
                                        height: '5px'
                                    }}></div>
                            }
                            <ModalHeader className="border-0" toggle={toggleDialogTrainStatus}>
                                Yakin ingin memperbarui status training ?
                            </ModalHeader>
                            <ModalBody style={{ marginTop: '-20px' }} className="text-left border-0">
                                <Row>
                                    <Col md="6">
                                        <a onClick={() => updateTrainingStatus()}
                                            style={
                                                trainingUpdate ?
                                                    { cursor: 'not-allowed', width: '100%', marginTop: '10px' }
                                                    :
                                                    { cursor: 'pointer', width: '100%', marginTop: '10px' }
                                            } className="btn btn-success btn-sm">
                                            Ya
                                        </a>
                                    </Col>
                                    <Col md="6">
                                        <a onClick={() => setDialogTrainStatus(false)} style={{ width: '100%', marginTop: '10px' }} className="btn btn-danger btn-sm" disabled>
                                            Tidak
                                        </a>
                                    </Col>
                                </Row>
                            </ModalBody>
                        </Modal>
                        <Modal isOpen={inviteMitra} closable={false} keyboard={false}>
                            {
                                inviteUpdate ?
                                    <div style={{
                                        borderTopLeftRadius: '5px',
                                        borderTopRightRadius: '5px',
                                        height: '5px'
                                    }} className="progress-bar">
                                        <div className="progress-bar-value"></div>
                                    </div>
                                    : <div style={{
                                        height: '5px'
                                    }}></div>
                            }
                            <ModalHeader className="border-0" toggle={toggleInviteMitra}>
                                Undang Mitra Untuk Training
                            </ModalHeader>
                            <ModalBody style={{ marginTop: '-20px' }} className="text-left border-0">
                                {buildScheduleList()}
                            </ModalBody>
                        </Modal >
                    </Container >
                )
            default:
                return (
                    <div></div>
                )
        }
    }
    function buildScheduleList() {
        switch (props.schedule.payload.type) {
            case SCHEDULE_LOADING:
                return (
                    <>
                        <Row>
                            <Col lg="12">
                                <Skeleton style={{ height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12">
                                <Skeleton style={{ marginTop: '25px', height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12">
                                <Skeleton style={{ marginTop: '25px', height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12">
                                <Skeleton style={{ marginTop: '25px', height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                            </Col>
                        </Row>
                    </>
                )
            case SCHEDULE_SUCCESSFUL:
                return (
                    <>
                        <Row style={{ marginTop: '-20px' }}>
                            <Col xl="12">
                                {
                                    props.schedule.payload.data.rows_data.docs.map((data, index) => {
                                        return (
                                            <>
                                                <div style={{ cursor: 'pointer' }} className="row no-gutters">
                                                    <div className="col-12 mt-3">
                                                        <div onClick={() => {
                                                            setInputValues({
                                                                selected_index: index,
                                                                selected_schedule_id: data.id,
                                                                selected_schedule_name: data.schedule_name
                                                            })
                                                        }} style={{ background: inputValues.selected_index === index ? MaterialBlue1 : 'white', borderRadius: '8px', border: inputValues.selected_index === index ? '1px solid white' : '1px solid black', paddingTop: '15px', paddingBottom: '2px', paddingLeft: '14px' }} className="row no-gutters">
                                                            <div className="col-xl-12">
                                                                <p style={{ color: inputValues.selected_index === index ? 'white' : 'black', fontSize: '14.5px' }}>Jadwal : {data.schedule_name}</p>
                                                                <p style={{ color: inputValues.selected_index === index ? 'white' : 'black', fontSize: '14.5px' }}>Tanggal : {data.schedule_date_time}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row no-gutters" style={{ marginTop: '20px' }}>
                                                    <Col xl="10"></Col>
                                                    <Col xl="2">
                                                        <button onClick={() => {
                                                            submitInviteMitra()
                                                        }} style={{
                                                            fontSize: '11px',
                                                            border: 'none',
                                                            background: !validateEmpty(inputValues.selected_schedule_name) || inviteUpdate ? MaterialGray1 : MaterialBlue3
                                                        }} className="btn btn-sm btn-primary" disabled={!validateEmpty(inputValues.selected_schedule_name) || inviteUpdate ? 'disabled' : ''}>Pilih Jadwal</button>
                                                    </Col>
                                                </div>
                                            </>
                                        )
                                    })
                                }
                            </Col>
                        </Row>
                        {
                            !validateEmpty(searchText) ?
                                <Row>
                                    {
                                        props.schedule.payload.data.rows_data !== undefined &&
                                            props.schedule.payload.data.count_total > 5 ?
                                            <div className="card bg-default-shadow" style={{ width: 'max-content', paddingLeft: '20px', marginRight: '0', marginTop: '10px', paddingTop: '15px', marginLeft: 'auto' }}>
                                                <Pagination
                                                    className="pagination-bar"
                                                    currentPage={currentPageSchedule}
                                                    totalCount={props.schedule.payload.data.count_total}
                                                    pageSize={5}
                                                    onPageChange={page => {
                                                        setCurrentPageSchedule(page)
                                                    }} />
                                            </div>
                                            : <div></div>
                                    }
                                </Row>
                                :
                                <Row>
                                    {
                                        props.schedule.payload.data.rows_data !== undefined &&
                                            props.schedule.payload.data.count_total > 5 ?
                                            <div className="card bg-default-shadow" style={{ width: 'max-content', paddingLeft: '20px', marginRight: '0', marginTop: '10px', paddingTop: '15px', marginLeft: 'auto' }}>
                                                <Pagination
                                                    className="pagination-bar"
                                                    currentPage={currentPageSearchSchedule}
                                                    totalCount={props.schedule.payload.data.count_total}
                                                    pageSize={5}
                                                    onPageChange={page => {
                                                        setCurrentPageSearchSchedule(page)
                                                    }} />
                                            </div>
                                            : <div></div>
                                    }
                                </Row>
                        }
                    </>
                )
            case SCHEDULE_NOT_FOUND:
                return (
                    <NotFoundPage
                        imageSource={IcNotFound}
                        imageText={props.schedule.payload.message} />
                )
            case SCHEDULE_FAILED:
                return (
                    <ErrorPage
                        imageSource={IcError}
                        imageText={props.schedule.payload.message} />
                )
        }
    }
    return (
        <>
            <div className="main-content" ref={mainContent}>
                <div className="header bg-gradient-info pb-8 pt-5 pt-lg-8 d-flex align-items-center">
                    <span className="mask bg-gradient-default opacity-8" />
                </div>
                {buildMitraDetail()}
            </div>
        </>
    )
}

MitraDetailCandidate.propTypes = {
    mitra_id: PropTypes.number.isRequired,
    mitra: PropTypes.object.isRequired,
    schedule: PropTypes.object.isRequired,
    getMitraDetail: PropTypes.func.isRequired,
    updateMitraCandidateDetail: PropTypes.func.isRequired,
    getScheduleForMitra: PropTypes.func.isRequired,
    getToolsByMitra: PropTypes.func.isRequired,
    submitMitraTools: PropTypes.func.isRequired,
    resetCrud: PropTypes.func.isRequired,
    resetUpdate: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    mitra: state.mitra,
    schedule: state.schedule,
    tools: state.tools
})

export default connect(mapStateToProps, { getMitraDetail, updateMitraCandidateDetail, inviteMitra, updateTrainingStatus, acceptMitra, getScheduleForMitra, getToolsByMitra, submitMitraTools, resetCrud, resetUpdate })(MitraDetailCandidate)