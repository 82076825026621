export const GUIDE_LIST_LOADING = 'GUIDE_LIST_LOADING';
export const GUIDE_LIST_SUCCESSFUL = 'GUIDE_LIST_SUCCESSFUL';
export const GUIDE_LIST_FAILED = 'GUIDE_LIST_FAILED';
export const GUIDE_LIST_NOT_FOUND = 'GUIDE_LIST_NOT_FOUND'

export const GUIDE_DETAIL_LOADING = 'GUIDE_DETAIL_LOADING';
export const GUIDE_DETAIL_SUCCESSFUL = 'GUIDE_DETAIL_SUCCESSFUL';
export const GUIDE_DETAIL_NOT_FOUND = 'GUIDE_DETAIL_NOT_FOUND';
export const GUIDE_DETAIL_FAILED = 'GUIDE_DETAIL_FAILED';

export const GUIDE_CREATE_LOADING = 'GUIDE_CREATE_LOADING';
export const GUIDE_CREATE_SUCCESSFUL = 'GUIDE_CREATE_SUCCESSFUL';
export const GUIDE_CREATE_FAILED = 'GUIDE_CREATE_FAILED';

export const GUIDE_UPDATE_LOADING = 'GUIDE_UPDATE_LOADING'
export const GUIDE_UPDATE_SUCCESSFUL = 'GUIDE_UPDATE_SUCCESSFUL';
export const GUIDE_UPDATE_NOT_FOUND = 'GUIDE_UPDATE_NOT_FOUND';
export const GUIDE_UPDATE_FAILED = 'GUIDE_UPDATE_FAILED';

export const GUIDE_REMOVE_LOADING = 'GUIDE_REMOVE_LOADING';
export const GUIDE_REMOVE_SUCCESSFUL = 'GUIDE_REMOVE_SUCCESSFUL';
export const GUIDE_REMOVE_NOT_FOUND = 'GUIDE_REMOVE_NOT_FOUND';
export const GUIDE_REMOVE_FAILED = 'GUIDE_REMOVE_FAILED';

export const GUIDE_RESET_CRUD = 'GUIDE_RESET_CRUD'