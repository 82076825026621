import { BASE_URL } from "actions/config";
import axios from 'axios';
import { passingHeaderToken } from "components/Utilities/Utility";

import {
    HELP_LIST_LOADING,
    HELP_LIST_SUCCESSFUL,
    HELP_LIST_NOT_FOUND,
    HELP_LIST_FAILED,

    HELP_DETAIL_LOADING,
    HELP_DETAIL_SUCCESSFUL,
    HELP_DETAIL_NOT_FOUND,
    HELP_DETAIL_FAILED,

    HELP_CREATE_LOADING,
    HELP_CREATE_SUCCESSFUL,
    HELP_CREATE_FAILED,

    HELP_UPDATE_LOADING,
    HELP_UPDATE_SUCCESSFUL,
    HELP_UPDATE_NOT_FOUND,
    HELP_UPDATE_FAILED,

    HELP_REMOVE_LOADING,
    HELP_REMOVE_SUCCESSFUL,
    HELP_REMOVE_NOT_FOUND,
    HELP_REMOVE_FAILED,
    HELP_RESET_CRUD
} from './Types';

export const getHelp = (page , limit , token) => async dispatch => {
    try {
        dispatch({
            type : HELP_LIST_LOADING,
            message : 'memuat data bantuan'
        })
        const response = await axios.get(`${BASE_URL}/api/help/index/admin?page=${page}&limit=${limit}` , passingHeaderToken(token))
        setTimeout(() => {
            if(response.data != null && response.data.rows_data.docs.length > 0){
                dispatch({
                    type : HELP_LIST_SUCCESSFUL,
                    data : response.data
                })
            } else {
                dispatch({
                    type : HELP_LIST_NOT_FOUND,
                    message : 'Tidak ada data bantuan'
                })
            }
        } , 300)
    } catch (err) {
        setTimeout(() => {
            if(err.response !== undefined && err.response.status == 404){
                dispatch({
                    type : HELP_LIST_NOT_FOUND,
                    message : 'Tidak ada data bantuan'
                })
            } else {
                dispatch({
                    type : HELP_LIST_FAILED,
                    message : 'Gagal memuat data bantuan'
                })
            }
        } , 300)
    }
}

export const getDetailHelp = (id, token) => async dispatch => {
    try {
        dispatch({
            type : HELP_DETAIL_LOADING,
            message : 'Memuat data bantuan'
        })
        const response = await axios.get(`${BASE_URL}/api/help/index/detail/${id}` , passingHeaderToken(token))
        setTimeout(() => {
            dispatch({
                type : HELP_DETAIL_SUCCESSFUL,
                data : response.data
            })
        } , 300)
    } catch (err) {
        setTimeout(() => {
            if(err.response !== undefined && err.response.status == 404){
                dispatch({
                    type : HELP_DETAIL_NOT_FOUND,
                    message : 'Tidak ada data bantuan'
                })
            } else {
                dispatch({
                    type : HELP_DETAIL_FAILED,
                    message : 'Gagal memuat data bantuan'
                })
            }
        } , 300)
    }
}

export const createHelp = (payload , token) => async dispatch => {
    try {
        dispatch({
            type : HELP_CREATE_LOADING,
            message : 'Membuat data bantuan'
        })
        await axios.post(`${BASE_URL}/api/help/create` , payload , passingHeaderToken(token))
        dispatch({
            type : HELP_CREATE_SUCCESSFUL,
            message : 'Berhasil membuat data bantuan'
        })
    } catch (err) {
        dispatch({
            type : HELP_CREATE_FAILED,
            message : 'Gagal membuat data bantuan'
        })
    }
}

export const updateHelp = (id , payload , token) => async dispatch => {
    try {
        dispatch({
            type : HELP_UPDATE_LOADING,
            message : 'Memperbarui data bantuan'
        })
        await axios.put(`${BASE_URL}/api/help/update/${id}` , payload , passingHeaderToken(token))
        dispatch({
            type : HELP_UPDATE_SUCCESSFUL,
            message : 'Berhasil memperbarui data bantuan'
        })
    } catch (err) {
        if(err.response !== undefined && err.response.status == 404){
            dispatch({
                type : HELP_UPDATE_NOT_FOUND,
                message : 'Data bantuan tidak ada'
            })
        } else {
            dispatch({
                type : HELP_UPDATE_FAILED,
                message : 'Gagal memperbarui data bantuan'
            })
        }
    }
}

export const removeHelp = (id , token) => async dispatch => {
    try {
        dispatch({
            type : HELP_REMOVE_LOADING,
            message : 'Menghapus data bantuan'
        })
        await axios.delete(`${BASE_URL}/api/help/remove/${id}` , passingHeaderToken(token))
        dispatch({
            type : HELP_REMOVE_SUCCESSFUL,
            message : 'Berhasil menghapus data bantuan'
        })
    } catch (err) {
        if(err.response !== undefined && err.response.status == 404){
            dispatch({
                type : HELP_REMOVE_NOT_FOUND , 
                message : 'Tidak ada data bantuan'
            })
        } else {
            dispatch({
                type : HELP_REMOVE_FAILED,
                message : 'Gagal menghapus data bantuan'
            })
        }
    }
}

export const resetCrud = () => async dispatch => {
    dispatch({
        type : HELP_RESET_CRUD,
        message : ''
    })
}