import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { useState } from "react";
import { getOrderList } from "actions/Orders/OrderService";
import { useEffect } from "react";
import { ORDER_LIST_LOADING } from "actions/Orders/Types";
import { ORDER_LIST_SUCCESSFUL } from "actions/Orders/Types";
import { ORDER_LIST_FAILED } from "actions/Orders/Types";
import { ORDER_LIST_NOT_FOUND } from "actions/Orders/Types";
import { Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Row, Table } from "reactstrap";
import '../../../assets/css/utilities.css';
import { formatRupiah } from "components/Utilities/Utility";
import Pagination from "components/Utilities/Pagination";
import ErrorPage from "components/ErrorComponent/ErrorPage";
import NotFoundPage from 'components/NotFound/NotFoundPage';
import IcNotFound from 'assets/img/ic_not_found.svg';
import IcError from 'assets/img/ic_error.svg';
import { useHistory } from "react-router-dom";
import { MaterialBlue1, MaterialGray1 } from "components/Utilities/ResourceColors";
import AdminNavbar from "components/Navbars/AdminNavbar";
import Skeleton from "react-loading-skeleton";
import { validateEmpty } from "components/Utilities/Utility";
import { MESSAGE_SOCKET_ADMIN } from "actions/Socket/Types";
import { NOTIFICATION_ORDER_CANCELED } from "actions/Socket/Types";
import { NOTIFICATION_ORDER_WAITING_FOR_SELECTED } from "actions/Socket/Types";

const OrderListPage = (props) => {
    const [currentPage, setCurrentPage] = useState(1)
    const [searchText, setSearchText] = useState('')
    const [currentPageSearch, setCurrentPageSearch] = useState(1)
    const [isFirst, setFirst] = useState(true)
    const [token , setToken] = useState('')

    const history = useHistory()

    const onChangeSearch = (e) => {
        setSearchText(e.target.value)
    }

    useEffect(() => {
        setToken(localStorage.getItem('token'))
        if(token !== ''){
            props.getOrderList(currentPage, 5, props.location.state.orderTypeText, "" , token)
        }
    }, [token])

    useEffect(() => {
        if(token !== ''){
            props.getOrderList(currentPage, 5, props.location.state.orderTypeText, "" , token)
        }
    }, [currentPage])

    useEffect(() => {
        if (!isFirst) {
            if (validateEmpty(searchText)) {
                if(token !== ''){
                    props.getOrderList(1, 5, props.location.state.orderTypeText, searchText , token)
                }
            }
        } else {
            setFirst(false)
        }
    }, [currentPageSearch])

    useEffect(() => {
        if (props.orders.payload.type !== ORDER_LIST_NOT_FOUND || props.orders.payload.type !== ORDER_LIST_FAILED) {
            if (!isFirst) {
                const delayDebounceFn = setTimeout(() => {
                    if (validateEmpty(searchText)) {
                        setCurrentPageSearch(1)
                        if(token !== ''){
                            props.getOrderList(currentPageSearch, 5, props.location.state.orderTypeText, searchText , token)
                        }
                    } else {
                        setCurrentPageSearch(1)
                        if(token !== ''){
                            props.getOrderList(currentPage, 5, props.location.state.orderTypeText, "" , token)
                        }
                    }
                }, 800)
                return () => clearTimeout(delayDebounceFn)
            } else {
                setFirst(false)
            }
        }
        if (props.socket !== null && props.socket.socket !== null) {
            props.socket.socket.on(MESSAGE_SOCKET_ADMIN, (payload) => {
                console.log(`Message Socket Payload : ${JSON.stringify(payload, null, 2)}`)
                switch (payload.notification_type) {
                    case NOTIFICATION_ORDER_WAITING_FOR_SELECTED:
                        console.log(`Pay Type : ${props.orders.payload.type}`)
                        if (props.orders.payload.type === ORDER_LIST_NOT_FOUND) {
                            setCurrentPageSearch(1)
                            props.getOrderList(currentPage, 5, props.location.state.orderTypeText, "")
                        }
                        break;
                }
            })
        }
    }, [searchText])

    function buildOrderList() {
        switch (props.orders.payload.type) {
            case ORDER_LIST_LOADING:
                return (
                    <Row>
                        <Col className="order-xl-1" xl="12">
                            <Card className="bg-secondary shadow">
                                <CardBody>
                                    <Row>
                                        <Col lg="12">
                                            <Skeleton style={{ height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="12">
                                            <Skeleton style={{ marginTop: '25px', height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="12">
                                            <Skeleton style={{ marginTop: '25px', height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="12">
                                            <Skeleton style={{ marginTop: '25px', height: '40px', backgroundColor: MaterialGray1 }}></Skeleton>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                )
            case ORDER_LIST_SUCCESSFUL:
                // let logoIcon = ''
                // let orderStatus = ''
                // switch (props.location.state.orderTypeText) {
                //     case 'RUNNING':
                //         orderStatus = 'Order Berjalan'
                //         logoIcon = <IcRunningIcon style={{ width: '30px', height: '30px' }} />
                //         break;
                //     case 'WAIT_SCHEDULE':
                //         orderStatus = 'Order Terjadwal'
                //         logoIcon = <IcComingSoon style={{ width: '30px', height: '30px' }} />
                //         break;
                //     case 'FINISH':
                //         orderStatus = 'Order Selesai'
                //         logoIcon = <IcDoneIcon style={{ width: '30px', height: '30px' }} />
                //         break;
                //     case 'REPEAT':
                //         orderStatus = 'Order Berulang'
                //         logoIcon = <IcRepeatIcon style={{ width: '30px', height: '30px' }} />
                //         break;
                //     case 'CANCELED':
                //         orderStatus = 'Order Dibatalkan'
                //         logoIcon = <IcFailedIcon style={{ width: '30px', height: '30px' }} />
                //         break;
                //     case 'FINDING_MITRA':
                //         orderStatus = 'Pencarian Order'
                //         logoIcon = <div style={{ width: '30px', height: '30px', backgroundColor: MaterialBlue1 }} className="blob"></div>
                //         break;
                // }
                return (
                    <>
                        <Row>
                            <Col xl="12">
                                <Card style={{ marginTop: "10px" }} className="bg-default-shadow">
                                    <Table className="align-items-center table-flush" responsive>
                                        <thead>
                                            <tr>
                                                <th scope="col">Order ID</th>
                                                <th scope="col">Nama Mitra</th>
                                                <th scope="col">Nama Customer</th>
                                                <th scope="col">Paket Order</th>
                                                <th scope="col">Tipe Order</th>
                                                <th scope="col">Total Transaksi</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                props.orders.payload.data.rows_data.docs.map((data) => {
                                                    let order_type = ''
                                                    if (data.order_type === 'now')
                                                        order_type = 'Order Sekarang'
                                                    else if (data.order_type === 'coming soon')
                                                        order_type = 'Order Terjadwal'
                                                    return (
                                                        <tr onClick={e => {
                                                            history.push({
                                                                pathname: '/admin/order_detail_page',
                                                                state: {
                                                                    order_id: data.id,
                                                                    customer_id: data.customer_id,
                                                                    mitra_id: data.mitra_id
                                                                }
                                                            })
                                                        }} style={{ cursor: 'pointer ' }} key={data.id}>
                                                            <td>{data.id_transaction}</td>
                                                            <td>{props.location.state.orderTypeText === 'WAITING_FOR_SELECTED_MITRA' ? <div className="btn btn-primary btn-sm">+ Pilih Mitra</div> : data.mitra != null ? data.mitra.complete_name : '-'}</td>
                                                            <td>{data.customer.complete_name}</td>
                                                            <td>{`${data.service.service_name} | ${data.sub_service.sub_price_service_title}`}</td>
                                                            <td>{order_type}</td>
                                                            <td>{formatRupiah(data.gross_amount !== null ? data.gross_amount.toString() : '0', 'Rp. ')}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                </Card>
                                <Row>
                                    {
                                        props.orders.payload.data.next_page !== null || props.orders.payload.data.is_last === '1' ?
                                            <Card style={{ marginLeft: 'auto', marginRight: '0', marginTop: '10px', paddingTop: '15px' }} className="bg-default-shadow">
                                                <Pagination
                                                    className="pagination-bar"
                                                    currentPage={currentPage}
                                                    totalCount={props.orders.payload.data.count_total}
                                                    pageSize={5}
                                                    onPageChange={page => setPagination(page)} />
                                            </Card>
                                            :
                                            <div></div>
                                    }
                                </Row>
                            </Col>
                        </Row>
                    </>
                )
            case ORDER_LIST_NOT_FOUND:
                return (
                    <NotFoundPage
                        imageSource={IcNotFound}
                        imageText={props.orders.payload.message} />
                )
            case ORDER_LIST_FAILED:
                return (
                    <ErrorPage
                        imageSource={IcError}
                        imageText={props.orders.payload.message} />
                )
        }
    }
    function setPagination(currentPage) {
        setCurrentPage(currentPage)
        if(token !== ''){
            props.getOrderList(currentPage, 5, props.location.state.orderTypeText, searchText , token)
        }
    }
    return (
        <>
            <div className="main-content">
                <AdminNavbar brandText={'Daftar Order'} />
                <div className="header bg-gradient-info pb-8 pt-5 pt-lg-7 d-flex align-items-center">
                    <span className="mask bg-gradient-default opacity-8" />
                </div>
                <Container className="mt--7" fluid>
                    <Row>
                        <Col xl="12">
                            <Form onSubmit={(e) => {
                                e.preventDefault()
                            }}>
                                <FormGroup>
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText >
                                                <i class="fa fa-search" aria-hidden="true" style={{ color: MaterialBlue1, fontSize: 15 }}>
                                                </i>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            id="input-search"
                                            onChange={onChangeSearch}
                                            style={{
                                                border: 0,
                                                boxShadow: 'none'
                                            }}
                                            defaultValue={searchText}
                                            placeholder="Ketik ORDER ID di sini untuk mencari order" />
                                    </InputGroup>
                                </FormGroup>
                            </Form>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl="12">
                            <Card className="bg-secondary shadow">
                                <CardBody>
                                    {buildOrderList()}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

OrderListPage.propTypes = {
    orders: PropTypes.object.isRequired,
    socket: PropTypes.object.isRequired,
    getOrderList: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    orders: state.orders,
    socket: state.socket
})

export default connect(mapStateToProps, { getOrderList })(OrderListPage)